import InputType from "@/services/Input/InputType.js";
import Catalog from "../../Receive/ReceiveCatalog";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.ReceiveNumber.Label + " atau " +
      Catalog.PONumber.Label,
    Type: InputType.Text,
    MaxLength: 50
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  }
}