var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{staticClass:"mt-5",attrs:{"items-mode":""}},[_c('kst-table',{attrs:{"view-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"sortable":"","field":"Name","label":_vm.DeliveryItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Packaging","label":_vm.DeliveryItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Quantity","label":_vm.DeliveryCatalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.DeliveryCatalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Weight","label":_vm.DeliveryItemCatalog.Weight.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }