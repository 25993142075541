<template>
  <kst-print class="kst-receive-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData" />
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column">
          <Receive :data="data" />
        </div>
        <div class="column">
          <Vendor :data="data" />
        </div>
      </div>
    </template>
    <Items class="mt-4" :items="data.Items"/>
    <Total class="mt-4" :data="data" :items="data.Items" />
  </kst-print>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Model from "../ReceiveModel.js";
import Services from "../ReceiveServices.js"
import Info from "../ReceiveInfo.js";

import Company from "./ReceivePrintDetails_Company";
import Receive from "./ReceivePrintDetails_Receive";
import Vendor from "./ReceivePrintDetails_Vendor";
import Items from "./ReceivePrintDetails_Items";
import Total from "./ReceivePrintDetails_Total";
export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Receive,
    Vendor,
    Items,
    Total
  },
  data: () => ({
    data: {},
    companyData: {},
    isLoading: true,
    error: null
  }),
  computed: {
    title() {
      return Model.Module.FullName;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadCompany();
    },
    loadData_Error(message) {
      this.error = message;
      this.isLoading = false;
    },
    loadCompany() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompany_Success, this.loadCompany_Error);
    },
    loadCompany_Success(data) {
      this.companyData = data.data;
      this.loadData();
    },
    loadCompany_Error(message) {
      this.error = message;
      this.isLoading = false;
    },
    loadData() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.error = null;
      if(!id) {
        this.error = Info.Message.Details_ParamIncomplete;
        return;
      }
      this.data = {}
      const options = { withpackagings: 1 };
      const config = Services.getReceiveDetails(id, options);
      this.runAxios(config, null, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data) {
      this.data = data;
      for (const item of this.data.Items) {
        Model.updateItemByPackaging(item);
      }
      this.isLoading = false;
    }
  }
}
</script>
