<template>
  <div>
    <kst-output field="DraftNumber" :data="data" :catalog="Catalog"/>
    <kst-output field="Date" :data="data" :catalog="Catalog"/>

    <template v-if="data.ClientID">
      <kst-output field="ClientID" :data="data" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="ClientAlias" :data="data" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="ClientName" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output control-off field="ClientID" :data="data" :catalog="Catalog">
        <kst-output addons expanded floating-label field="ClientName" :data="data" :catalog="Catalog"/>
      </kst-output>
    </template>

    <kst-output field="ClientAddress" :data="data" :catalog="Catalog"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
    <kst-output field="PONumber" :data="data" :catalog="Catalog"/>

    <kst-output field="DiscType" :label="Catalog.DiscValue.Label" :data="data" :catalog="Catalog">
      <template v-if="data.DiscValue !== null">
        <kst-output field-only field="DiscValue" :data="data" :catalog="Catalog"/>
      </template>
      <template v-else-if="data.DiscPercent !== null">
        <kst-output field-only field="DiscPercent" :data="data" :catalog="Catalog"/>
      </template>
    </kst-output>

    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-output field="StatusName" :data="data" :catalog="Catalog"/>

    <kst-output control-off field="CreatedBy" :data="data" :catalog="Catalog">
      <template #default>
        <kst-output expanded floating-label
          class="pr-2"
          field="CreatedByID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #right>
        <kst-output addons expanded floating-label
          field="CreatedDate" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>
    <kst-output control-off field="LastUpdatedBy" :data="data" :catalog="Catalog">
      <template #default>
        <kst-output expanded floating-label
          class="pr-2"
          field="LastUpdatedByID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #right>
        <kst-output addons expanded floating-label
          field="LastUpdatedDate" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>
  </div>
</template>

<script>
import Catalog from "../SalesCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>