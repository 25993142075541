<template>
  <div>
    <kst-output print-mode field="NormalPrice" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="SpecialPrice" :data="data" :catalog="Catalog"/>
    <table>
      <template v-for="(item, index) in items">
        <tr :key="index">
          <td class="name">
            {{item.Name}}
          </td>
          <td >
            <kst-value field="Total" :data="item" :catalog="Catalog"/>
          </td>
        </tr>
      </template>
    </table>
  </div> 
</template>

<script>
import Catalog from "../OmzetCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>