<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>
    <kst-input field="Date" :data="data" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="data" :catalog="Catalog"
      :options="warehouseOptions" @input="handleInput"
      :disabled="userItems.length > 0"
    />
    <kst-input load-on-focus field="IsAutoNumber" :data="data" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!data.IsAutoNumber"
          field="ClosingNumber" :data="data" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>
    <kst-input field="Comment" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../StockClosingCatalog.js";
import Info from "../StockClosingInfo.js";
import Model from "../StockClosingModel.js";

export default {
  props: {
    data: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [
        Info.Message.New_Warehouse,
        Info.Message.New_Date
      ];
    },
    numberValidations() {
      return Model.validationNumber(this.data);
    }
  },
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>