import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./StockReductionCatalog.js";
import StockReductionItemCatalog from "./StockReductionItemCatalog.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.StockReduction,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportDetails: AppModuleType.ExportDetails,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/

  StockReductionItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  SpecialPrice: {
    Module: AppModule.SpecialPrice,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },

  setItemByDispatchSpecialPrice(item, dspData) {
    this.helper.setItemByDispatchSpecialPrice(item, dspData);
  },
  setItemByStock(item, stockData) {
    this.helper.setItemByStock(item, stockData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  setItemsByDispatchSpecialPrice(items, rows) {
    return this.helper.setItemsByDispatchSpecialPrice(items, rows);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },

  getInvalidItems(details) {
    return this.helper.getInvalidItems(details);
  },

  updateDetails(data, items) {
    this.helper.updateDetails(data, items);
  },
  updateDetailsByDetailsData(data, newData) {
    this.helper.updateDetailsByDetailsData(data, newData);
  },
  updateDetailsByNewData(data, newData) {
    this.helper.updateDetailsByNewData(data, newData);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },

  updateItemsByDispatchSpecialPrice(items, dspList) {
    this.helper.updateItemsByDispatchSpecialPrice(items, dspList);
  },
  updateItemsByStock(items, stockList) {
    this.helper.updateItemsByStock(items, stockList);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  validateItem(item) {
    this.helper.validateItem(item);
  },

  validateAddItemByDispatchSpecialPrice(items, dspData) {
    return this.helper.validateAddItemByDispatchSpecialPrice(items, dspData);
  },
  validateAddItemByStock(items, stockData) {
    return this.helper.validateAddItemByStock(items, stockData);
  },
  validateItemsByStock(data, items) {
    this.helper.validateItemsByStock(data, items);
  },

  /*** external ***/

  getSpecialPrice_ExcludeIdList(items) {
    return this.helper.getSpecialPrice_ExcludeIdList(items);
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails() {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        WarehouseID: null,
        ReductionNumber: "",
        IsAutoNumber: true,
        Comment: "",
        // defined by system
        Date: App.In.getDateToday(),
        Total: 0,
        CreatedByID: App.Session.getUserID(),
        SpecialPriceCount: 0,
        // UI validation
        InvalidItems_vsStock: null,
        StockMissingQty: 0
      };
    },
    createItem() {
      return {
        ID: null,
        // user input
        DispatchID: null,
        DispatchName: "",
        Qty: "",
        SellPrice: "0",
        // defined by system
        DispatchSellPrice: 0,
        PackagingName: "",
        PackagingValue: 1,
        TotalQty: 0,
        SpecialPriceID: null,
        SpecialPriceName: "",
        SpecialPriceDiscPercent: 0,
        SellPriceNetto: 0,
        Total: 0,
        Packaging: "",
        Stock: 0,
        SKU: "",
        SpecialPriceQty: 0,
        // error
        Errors: [],
        ErrorsColl: {},
        // UI validation
        Qty_Valid: true,
        Qty_vsSpecialPrice: true
      }
    },

    setItemByDispatchSpecialPrice(item, dspData) {
      item.DispatchID = dspData.DispatchID;
      item.Stock = dspData.Stock;

      item.DispatchName = dspData.FullName;
      item.SKU = dspData.SKU;
      item.SellPrice = dspData.SellPrice;
      item.DispatchSellPrice = dspData.SellPrice;
      item.PackagingName = dspData.PackagingPcs;

      item.SpecialPriceID = dspData.SpecialPriceID;
      item.SpecialPriceName = dspData.SpecialPriceName;
      item.SpecialPriceDiscPercent = dspData.DiscPercent;
      item.SellPriceNetto = dspData.SellPriceNetto;
      item.SpecialPriceQty = dspData.Quantity;
    },
    setItemByStock(item, stockData) {
      item.DispatchID = stockData.DispatchID;
      item.Stock = stockData.Quantity;

      item.DispatchName = stockData.FullName;
      item.SKU = stockData.SKU;
      item.SellPrice = stockData.SellPrice;
      item.SellPriceNetto = stockData.SellPrice;
      item.DispatchSellPrice = stockData.SellPrice;
      item.PackagingName = stockData.PackagingPcs;
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }
      // custom error message
      if (!item.Qty_Valid) {
        if (!item.Qty_vsSpecialPrice) {
          newErrors.push("Maximum nilai " +
            StockReductionItemCatalog.Qty.Label + " adalah " +
            App.Value.getValue("SpecialPriceQty", item, StockReductionItemCatalog) + " " +
            "(" + StockReductionItemCatalog.SpecialPriceQty.Label + ")"
          );
        }
      }

      item.Errors = newErrors;
    },
    setItemsByDispatchSpecialPrice(items, rows) {
      let count = 0;
      let oldItems = items.slice();

      for (const row of rows) {
        if (this.validateAddItemByDispatchSpecialPrice(oldItems, row)) {
          let item = this.createItem();
          this.setItemsByDispatchSpecialPrice_Item(item, row);
          this.updateItem(item);
          items.push(item);

          count++;
        }
      }

      return count;
    },
    setItemsByDispatchSpecialPrice_Item(item, row) {
      item.DispatchID = row.DispatchID;
      item.DispatchName = row.FullName;
      item.Qty = row.Quantity;
      item.SellPrice = row.SellPrice;
      item.DispatchSellPrice = row.SellPrice;
      item.PackagingName = row.PackagingPcs;
      item.SpecialPriceID = row.SpecialPriceID;
      item.SpecialPriceName = row.SpecialPriceName;
      item.SpecialPriceDiscPercent = row.DiscPercent;
      item.SellPriceNetto = row.SellPriceNetto;
      item.Stock = row.Stock;
      item.SKU = row.SKU;
      item.SpecialPriceQty = row.Quantity;
    },

    getItemInfo(item) {
      let infoList = [];

      // SKU
      infoList.push(StockReductionItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, StockReductionItemCatalog)
      );

      // Stock
      infoList.push(StockReductionItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, StockReductionItemCatalog) + " " + item.PackagingName
      );

      if (item.SpecialPriceID !== null) {
        // SpecialPriceQty
        infoList.push(StockReductionItemCatalog.SpecialPriceQty.Label + ": " +
          App.Value.getValue("SpecialPriceQty", item, StockReductionItemCatalog) + " " + item.PackagingName
        );
      }

      return infoList;
    },
    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(StockReductionItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, StockReductionItemCatalog)
      );

      return infoList;
    },

    getInvalidItems(details) {
      let items = [];

      if (details.InvalidItems_vsStock) {
        for (const invalidItem of details.InvalidItems_vsStock) {
          items.push(invalidItem.DispatchName + " memiliki kekurangan " +
            Catalog.StockMissingQty.Label + ": " +
            App.Value.getValue("MissingQty", invalidItem, StockReductionItemCatalog)
          );
        }
      }

      return items;
    },

    updateDetails(data, items) {
      data.Total = 0;

      for (const item of items) {
        data.Total += item.Total;
      }
    },
    updateDetailsByDetailsData(data, newData) {
      data.SpecialPriceCount = newData.SpecialPriceCount;
    },
    updateDetailsByNewData(data, newData) {
      data.SpecialPriceCount = newData.SpecialPriceCount;
    },

    updateItem(item) {
      this.updateItemByPackaging(item);
      item.TotalQty = App.Data.getTotalQty(item.Qty, item.PackagingValue);
      item.Total = App.Data.getTotal(item.Qty, item.PackagingValue, item.SellPriceNetto);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },

    updateItemsByDispatchSpecialPrice(items, dspList) {
      for (const item of items) {
        // update: Stock
        for (const dsp of dspList) {
          if (dsp.DispatchID === item.DispatchID) {
            item.Stock = dsp.Stock;
            break;
          }
        }

        if (item.SpecialPriceID !== null) {
          // update: SpecialPriceQty
          for (const dsp of dspList) {
            if (dsp.DispatchID === item.DispatchID && dsp.SpecialPriceID === item.SpecialPriceID) {
              item.SpecialPriceQty = dsp.Quantity;
              break;
            }
          }
        }
      }
    },
    updateItemsByStock(items, stockList) {
      for (const item of items) {
        for (const stock of stockList) {
          if (stock.DispatchID === item.DispatchID) {
            item.Stock = stock.Quantity;
            break;
          }
        }
      }
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        ReductionNumber: data.IsAutoNumber ? null : App.Out.getString(data.ReductionNumber),
        Comment: App.Out.getString(data.Comment),
        Date: App.Out.getDate(data.Date),
        CreatedByID: data.CreatedByID
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          DispatchID: item.DispatchID,
          DispatchName: App.Out.getString(item.DispatchName),
          Qty: App.Out.getInteger(item.Qty),
          SellPrice: App.Out.getInteger(item.SellPrice),
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          SpecialPriceID: App.Out.getInteger(item.SpecialPriceID),
          SellPriceNetto: App.Out.getInteger(item.SellPriceNetto)
        });
      }

      return resultItems;
    },

    validateItem(item) {
      item.Qty_Valid = true;
      item.Qty_vsSpecialPrice = true;

      if (item.SpecialPriceID !== null) {
        const qty = App.Out.getInteger(item.Qty);

        if (qty !== null) {
          if (qty > item.SpecialPriceQty) {
            item.Qty_Valid = false;
            item.Qty_vsSpecialPrice = false;
          }
        }
      }
    },

    validateAddItemByDispatchSpecialPrice(items, dspData) {
      for (const item of items) {
        if (item.DispatchID === dspData.DispatchID &&
          item.SpecialPriceID === dspData.SpecialPriceID
        ) {
          return false;
        }
      }

      return true;
    },
    validateAddItemByStock(items, stockData) {
      for (const item of items) {
        if (item.DispatchID === stockData.DispatchID) {
          return false;
        }
      }

      return true;
    },
    validateItemsByStock(data, items) {
      // reset indicator
      data.StockMissingQty = 0;

      data.InvalidItems_vsStock = null;
      let invalidItems = [];

      const pivotItems = App.Pivot.create(items, "DispatchID", [
        { field: "TotalQty", type: App.Pivot.Type.SUM },
        { field: "Stock", type: App.Pivot.Type.FIRST },
        { field: "DispatchName", type: App.Pivot.Type.FIRST }
      ]);

      for (const pivotItem of pivotItems) {
        if (pivotItem.TotalQty > pivotItem.Stock) {
          data.StockMissingQty = 1;
          invalidItems.push({
            DispatchName: pivotItem.DispatchName,
            MissingQty: (pivotItem.TotalQty - pivotItem.Stock)
          });
        }
      }

      if (invalidItems.length > 0) {
        data.InvalidItems_vsStock = invalidItems;
      }
    },

    /*** external ***/

    getSpecialPrice_ExcludeIdList(items) {
      let excludeIdList = [], item;

      for (item of items) {
        if (!excludeIdList.includes(item.SpecialPriceID)) {
          excludeIdList.push(item.SpecialPriceID);
        }
      }

      return excludeIdList;
    }
  }
}