<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
    <kst-input field="ReceiveNumber" :data="userData" :catalog="Catalog"/>
    <kst-input field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from '../ReceiveCatalog.js';
import Info from "../ReceiveInfo.js";

export default {
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data:() => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.New_Date];
    }
  }
}
</script>