import App from "@/services/App/App.js";
// import AppModule from "@/services/App/AppModule.js";
// import AppCatalog from "@/services/App/AppCatalog.js";
// import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./TransactionModel.js";

export default {
  Export: {
    List: {
      Excel: {
        //file
        FileName: App.Xlsx.getFileName(Model.Module),
        SheetName: {
          List: Model.ModuleType.List.Name
        }
      }
    }
  },
}