var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.userAddresses},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.ClientAddress.Module.FullName}},[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})],1)]}},{key:"empty-body",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"userAddresses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kst-error',{attrs:{"array-blank-mode":!!errors}})]}}])})]},proxy:true}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-client-userAddress","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-client-edit-Name","field":"Name","label":_vm.ClientAddressCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-client-edit-Address","field":"Address","label":_vm.ClientAddressCatalog.Address.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-client-edit-ContactName","field":"ContactName","label":_vm.ClientAddressCatalog.ContactName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-client-edit-ContactMobile","field":"ContactMobile","label":_vm.ClientAddressCatalog.ContactMobile.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-client-edit-ContactPhone","field":"ContactPhone","label":_vm.ClientAddressCatalog.ContactPhone.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-client-edit-ContactEmail","field":"ContactEmail","label":_vm.ClientAddressCatalog.ContactEmail.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }