import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Valid: true
  },
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 255
  },
  RequestedQuantity: {
    Label: "Qty",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true,
    Valid: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Select,
    Required: true
  },
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    PrintLabel: "Harga/Unit",
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  Price: {
    Label: "Sub Total",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency,
    ReadOnly: true
  },

  // support
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  SalesDraftItemQty: {
    Label: "Qty SO",
    Type: InputType.Integer,
    ReadOnly: true
  },
  QtyInvoice: {
    Label: "Qty Penjualan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  DeliveryQuantity: {
    Label: "Qty Pengiriman",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Stock: {
    Label: "Stok",
    Type: InputType.Integer,
    ReadOnly: true
  },
  StockSpecialPrice: {
    Label: "Stok Harga Special",
    Type: InputType.Integer,
    ReadOnly: true
  },
  PackagingValue: {
    Label: "Jumlah Kemasan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  DispatchImageSrc: {
    Label: "Gambar",
    Type: InputType.Text,
    ReadOnly: true
  },
  QtyAvailable: {
    Label: "Qty Terjual",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Client: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 500,
    Highlight: true
  },
  SpecialPriceID: {
    Label: "Diskon",
    Type: InputType.Select
  },
  SpecialPriceName: {
    Label: "Diskon",
    Type: InputType.Text,
    MaxLength: 50
  },
  DiscountedValue: {
    Label: "Harga Spesial",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  SellPriceNetto: {
    Label: "Harga Spesial",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  SpecialPriceQty: {
    Label: "Qty Harga Spesial",
    Type: InputType.Integer,
    ReadOnly: true
  }
}