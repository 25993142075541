var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Action',{attrs:{"row":row}})]}}])},[_c('b-table-column',{attrs:{"sortable":"","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Address","label":_vm.Catalog.Address.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"PIC","label":_vm.Catalog.PIC.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"NoPIC","label":_vm.Catalog.NoPIC.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","cell-class":"ks-col-status","field":"IsActive","label":_vm.Catalog.IsActive.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-status',{attrs:{"value":row.IsActive,"statusEnum":_vm.Model.Status}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }