var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"field":"DispatchID","label":_vm.StockReductionItemCatalog.DispatchName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"Qty","label":_vm.StockReductionItemCatalog.Qty.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"multi-value":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}},[_c('kst-value',{attrs:{"multi-value":"","field":"PackagingName","data":row,"catalog":_vm.StockReductionItemCatalog}})],1)]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPrice","label":_vm.StockReductionItemCatalog.SellPrice.PrintLabel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])}),(_vm.data.SpecialPriceCount > 0)?[_c('b-table-column',{attrs:{"cell-class":"ks-col-price","field":"SpecialPriceID","label":_vm.StockReductionItemCatalog.SpecialPriceID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}],null,false,363947281)}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPriceNetto","label":_vm.StockReductionItemCatalog.SellPriceNetto.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}],null,false,363947281)})]:_vm._e(),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Total","label":_vm.StockReductionItemCatalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }