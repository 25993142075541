<template>
  <kst-container class="kst-specialpriceadj-list">
    <kst-form
      :failureInit="failureInit"
      :loading="isLoading"
      :title="title"
    >

      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>
      
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        />
      </kst-section>

      <kst-section :loading="isListLoading" search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Export"
            @click="handleExport"
          />
        </template>

        <List
          :failureList="failureList"
          :items="data"
          :isListLoading="isListLoading"
        />
      </kst-section>
    </kst-form>
  </kst-container>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../SpecialPriceAdjModel.js";
import Catalog from "../SpecialPriceAdjCatalog.js";
import Services from "../SpecialPriceAdjServices.js";
import List from "./SpecialPriceAdjList_List";
import Search from "./SpecialPriceAdjList_Search";

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    data: [],
    searchData: {},
    warehouseOptions: {},
    isLoading: false,
    isListLoading: false,
    failureList: null,
    failureInit: null,
    loadCounter: 0
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      }
    },
    initLoad() {
      this.loadDataWarehouse();
    },
    loadDataWarehouse() {
      const options = { active: null }
      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadDataWarehouse_Success, this.loadDataWarehouse_Error);
    },
    loadDataWarehouse_Success(data) {
      this.isLoading = false;
      this.warehouseOptions = Model.getWarehouseOptions(data, Catalog.WarehouseID.Label);
      this.loadData();
    },
    loadDataWarehouse_Error(message) {
      this.isLoading = false;
      this.failureInit = message;
    },
    loadData() {
      const options = Model.populateSearchParam(this.searchData);
      const config = Services.getSpecialPriceAdjList(options);
      this.runAxios(config, null, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data) {
      this.isListLoading = false;
      this.data = data;
    },
    loadData_Error(message) {
      this.isListLoading = false;
      this.failureList = message;
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadData();
    },
    handleSearch_Submit() {
      this.loadCounter++;
      this.loadData();
    },
    handleExport() {
      // do something
    }
  }
}
</script>