import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";
import InvoiceCatalog from "@/components/Invoice/InvoiceCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ReturnNumber: {
    Label: "Nomor Retur",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang Pengembalian",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang Pengembalian",
    Type: InputType.Text,
    ReadOnly: true
  },
  ClientID: {
    Label: InvoiceCatalog.ClientID.Label,
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client
  },
  ClientName: {
    Label: InvoiceCatalog.Client.Label,
    Type: InputType.Text,
    ReadOnly: true,
    Highlight: true
  },
  ClientAlias: {
    Label: InvoiceCatalog.ClientAlias.Label,
    Type: InputType.Text,
    ReadOnly: true
  },
  ClientAddress: {
    Label: InvoiceCatalog.ClientAddress.Label,
    Type: InputType.Text,
    ReadOnly: true
  },
  DiscType: {
    Label: "Pilihan Diskon",
    Type: InputType.Select
  },
  DiscValue: {
    Label: "Diskon",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  DiscPercent: {
    Label: "Diskon",
    Type: InputType.Percent,
    MinValue: 0,
    MaxValue: 100,
    Required: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 1000
  },
  TotalBruto: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalDisc: {
    Label: "Total Diskon",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },

  // support
  SalesOrderID: {
    Label: InvoiceCatalog.SONumber.Label,
    Type: InputType.ID,
    Required: true,
    RelatedValue: "SONumber",
    Input: InputType.Select,
    RelatedModule: AppModule.Invoice
  },
  SONumber: {
    Label: InvoiceCatalog.SONumber.Label,
    Type: InputType.Text,
    Required: true,
    MaxLength: 50,
    Highlight: true
  },
  InvoiceDate: {
    Label: InvoiceCatalog.InvoiceDate.Label,
    Type: InputType.Date,
    ReadOnly: true
  },
  IsAutoNumber: {
    Label: "Nomor Retur",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}