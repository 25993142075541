// keyboard enum
export default {
  KeyProp: 'keyCode',
  KeyEvent: 'keydown',
  Key: {
    F1: 112,
    F2: 113,
    F3: 114
  }

  /**
   * How to set key capture in component:
   * 
   * mounted() {
   *  window.addEventListener(Utility.keyEvent, this.handleKeyDown);
   * },
   * destroyed() {
   *  window.removeEventListener(Utility.keyEvent, this.handleKeyDown);
   * }
   * 
   * handleKeyDown(event) {
   *  // TODO: handle value of event[Utility.keyProp]
   * }
   */
}