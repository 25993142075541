import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Valid: true
  },
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  DispatchName: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 255
  },
  Quantity: {
    Label: "Qty",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Select,
    Required: true
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },

  // support
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  InvoiceItemQty: {
    Label: "Qty Terjual",
    Type: InputType.Integer,
    ReadOnly: true
  },
  SalesOrderItemQty: {
    Label: "Qty Terjual",
    Type: InputType.Integer,
    ReadOnly: true
  },

  // validation
  QtyMissing: {
    Label: "Qty",
    Type: InputType.Integer,
    MaxValue: 0
  }
}