var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","view-mode":"","data":_vm.userVariantValues},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.ItemVariantValue.Module.FullName}},[(!row.IsDefault)?[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})]:_vm._e()],2)]}},{key:"empty-body",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"userVariantValues","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kst-error',{attrs:{"array-blank-mode":!!errors}})]}}])})]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"Value","label":_vm.VariantValueCatalog.Value.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.VariantValueCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }