<template>
  <span/>
</template>

<script>
  import AxiosMixin from "@/mixins/AxiosMixin.js";
  import LoadingMixin from "@/mixins/LoadingMixin.js";
  import XlsxMixin from "@/mixins/XlsxMixin.js";
  import Catalog from "../ClientCatalog.js";
  import AddressCatalog from "../ClientAddressCatalog.js";
  import Info from "../ClientInfo.js";
  import Services from "../ClientServices.js";

  export default {
    mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getClientDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);

      if (this.searchData.WithAddress) {
        this.addDetails_Address(data.Address);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Alias);
      this.addColumnHeader(Catalog.Mobile);
      this.addColumnHeader(Catalog.Phone);
      this.addColumnHeader(Catalog.Email);
      this.addColumnHeader(Catalog.Comment);

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("Name", data, Catalog);
      this.addCellValue("Alias", data, Catalog);
      this.addCellValue("Mobile", data, Catalog);
      this.addCellValue("Phone", data, Catalog);
      this.addCellValue("Email", data, Catalog);
      this.addCellValue("Comment", data, Catalog);
    },
    addDetails_Address(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ClientAddress);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(AddressCatalog.Name);
      this.addColumnHeader(AddressCatalog.Address);
      this.addColumnHeader(AddressCatalog.ContactName);
      this.addColumnHeader(AddressCatalog.ContactMobile);
      this.addColumnHeader(AddressCatalog.ContactPhone);
      this.addColumnHeader(AddressCatalog.ContactEmail);

      // value
      for ( const row of items ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("Name", row, AddressCatalog);
      this.addCellValue("Address", row, AddressCatalog);
      this.addCellValue("ContactName", row, AddressCatalog);
      this.addCellValue("ContactMobile", row, AddressCatalog);
      this.addCellValue("ContactPhone", row, AddressCatalog);
      this.addCellValue("ContactEmail", row, AddressCatalog);
      }
    }
  }
}
</script>