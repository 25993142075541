import InputType from "@/services/Input/InputType.js";
import Catalog from "../DailyReportCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: InputType.ID,
    Input: InputType.Select
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true,
    RequiredHide: true
  }
}