import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import ItemModel_Body from "./ItemModel_Body.js";
import DispatchCatalog from "./DispatchCatalog.js";
import ItemCatalog from "./ItemCatalog.js";
import PackagingCatalog from "./PackagingCatalog.js";
import VariantCatalog from "./VariantCatalog.js";
import PurchaseModel from "@/components/Purchase/PurchaseModel.js";

export default {
  // required in model
  Module: AppModule.Item,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    Select: AppModuleType.Select
  },

  /*** related ***/

  ItemDispatch: {
    Module: AppModule.Dispatch,
    ModuleType: {
      Edit: AppModuleType.Edit,
      List: AppModuleType.List
    }
  },
  ItemPackaging: {
    Module: AppModule.Packaging,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },
  ItemVariant: {
    Module: AppModule.Variant,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },
  ItemVariantValue: {
    Module: AppModule.VariantValue,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },

  Stock: {
    Module: AppModule.Stock,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  ItemSelect : {
    Title: "Cari Barang"
  },

  /*** property ***/

  Search: {
    Fields: ["Name"]
  },

  Type: {
    Operational: {
      ID: 0,
      Label: App.Enum.Type.Operational.Label
    },
    Sellable: {
      ID: 1,
      Label: App.Enum.Type.Sellable.Label
    }
  },

  DefaultPackaging: {
    Name: "pcs",
    Amount: 1
  },

  Variant : {
    Max: 2
  },

  /*** property - related ***/

  NewItem: PurchaseModel.NewItem,

  /*** method ***/

  /* details */

  createDetails() {
    return this.helper.createDetails();
  },
  createTypeOptions(optionAllText) {
    return this.helper.createTypeOptions(optionAllText, this.Type);
  },

  setDetailsByData(details, detailsData) {
    this.helper.setDetailsByData(details, detailsData);
  },

  /* id - object */

  createIdObject() {
    return this.helper.createIdObject();
  },

  /* dispatch */

  createDispatch() {
    return this.helper.createDispatch();
  },

  setDispatchErrors(item, field, errors) {
    this.helper.setDispatchErrors(item, field, errors);
  },
  setDispatchByData(dispatch, dispatchData) {
    this.helper.setDispatchByData(dispatch, dispatchData);
  },
  setDispatchesByData(dispatches, rows) {
    this.helper.setDispatchesByData(dispatches, rows);
  },

  updateDispatches(dispatches, variantList, itemData) {
    return ItemModel_Body.updateDispatches(dispatches, variantList, itemData);
  },
  updateDispatchesName(dispatches, itemName) {
    this.helper.updateDispatchesName(dispatches, itemName);
  },
  updateDispatchesByEditData(dispatches, dispatchData) {
    this.helper.updateDispatchesByEditData(dispatches, dispatchData);
  },

  /* dispatch variant */

  createDispatchVariant() {
    return this.helper.createDispatchVariant();
  },

  setDispatchVariantByData(dispatchVariant, dispatchVariantData) {
    this.helper.setDispatchVariantByData(dispatchVariant, dispatchVariantData);
  },

  getDispatchVariants(dispatch) {
    return this.helper.getDispatchVariants(dispatch);
  },

  /* packaging */

  createPackaging() {
    return this.helper.createPackaging();
  },

  setPackagingByData(packaging, packagingData) {
    this.helper.setPackagingByData(packaging, packagingData);
  },
  setPackagingByDefault(packaging) {
    this.helper.setPackagingByDefault(packaging, this.DefaultPackaging);
  },
  setPackagingErrors(item, field, errors) {
    this.helper.setPackagingErrors(item, field, errors);
  },
  setPackagingsByData(packagings, rows) {
    this.helper.setPackagingsByData(packagings, rows, this.DefaultPackaging);
  },

  getPackagingOptions(items) {
    return this.helper.getPackagingOptions(items);
  },
  getPackagingValue(items, packagingName) {
    return this.helper.getPackagingValue(items, packagingName);
  },

  updatePackagingsErrors(data, packagings) {
    this.helper.updatePackagingsErrors(data, packagings);
  },

  populateData(userData, variantList, userPackagings, userVariations) {
    let data = this.helper.populateDetails(userData);
    data.Variants = this.helper.populateVariants(variantList);
    data.Packaging = this.helper.populatePackagings(userPackagings);
    data.Variations = this.helper.populateVariations(userVariations);

    return data;
  },

  /* variant */

  createVariant(idObj) {
    return this.helper.createVariant(idObj);
  },

  setVariantByData(variant, variantData, idObj) {
    this.helper.setVariantByData(variant, variantData, idObj);
  },
  setVariantsByData(variants, rows, idObj) {
    this.helper.setVariantsByData(variants, rows, idObj);
  },

  updateVariantsErrors(data, variants) {
    this.helper.updateVariantsErrors(data, variants);
  },

  /* variant value */
  createVariantValue(idObj) {
    return this.helper.createVariantValue(idObj);
  },
  setVariantValueByData(variantValue, variantValueData) {
    this.helper.setVariantValueByData(variantValue, variantValueData);
  },
  setVariantValueByValue(variantValue, variantValueData) {
    this.helper.setVariantValueByValue(variantValue, variantValueData);
  },

  /*** external ***/

  createAccess() {
    return AppCatalog.Access.getDefault();
  },

  helper: {
    /* details */
  
    createDetails() {
      return {
        ID: null,
        Type: null,
        Name: "",
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID(),
        // validation
        ActivePackaging: 0,
        // error
        PackagingsErrors: [],
        VariantsErrors: []
      };
    },
    createTypeOptions(optionAllText, typeEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: typeEnum.Operational.ID, [rowLabel]: typeEnum.Operational.Label },
        { [rowId]: typeEnum.Sellable.ID, [rowLabel]: typeEnum.Sellable.Label },
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByData(details, detailsData) {
      details.ID = detailsData.ID;
      details.Type = detailsData.Type;
      details.Name = detailsData.Name;
    },

    /* id - object */

    createIdObject() {
      return {
        Variant: -1,
        VariantValue: -1
      };
    },

    /* dispatch & dispatch variant */

    createDispatch() {
      // reminder: update also ItemModel_Body.createDispatch
      return {
        DispatchID: null,
        FullName: "",
        SKU: "",
        SellPrice: "",
        Weight: "",
        BuyPrice: "",
        Barcode: "",
        Vendor: "",
        Description: "",
        ImageSrc: "",
        Image64: "",
        ImageIsLocal: true,
        Variants: null,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    setDispatchErrors(item, field, errors) {
      let newErrors = App.Vee.getRowErrors(item, field, errors);

      const buyPrice = App.Out.getInteger(item.BuyPrice);
      const sellPrice = App.Out.getInteger(item.SellPrice);
      if (buyPrice > sellPrice) {
        newErrors.push("Minimum nilai " + DispatchCatalog.SellPrice.Label + " adalah " +
          App.Value.getValue("BuyPrice", item, DispatchCatalog) + " " +
          "(" + DispatchCatalog.BuyPrice.Label + ")"
        );
      }

      item.Errors = newErrors;
    },
    setDispatchByData(dispatch, dispatchData) {
      let variants = [];
      for (const row of dispatchData.Variants) {
        let variant = this.createDispatchVariant();
        this.setDispatchVariantByData(variant, row);
        variants.push(variant);
      }

      dispatch.DispatchID = dispatchData.DispatchID;
      dispatch.FullName = dispatchData.FullName;
      dispatch.SKU = dispatchData.SKU;
      dispatch.SellPrice = dispatchData.SellPrice;
      dispatch.Weight = dispatchData.Weight;
      dispatch.BuyPrice = dispatchData.BuyPrice;
      dispatch.Barcode = dispatchData.Barcode;
      dispatch.Vendor = dispatchData.Vendor;
      dispatch.Description = dispatchData.Description;
      dispatch.ImageSrc = dispatchData.ImageSrc;
      dispatch.ImageIsLocal = false;
      dispatch.HppPerPcs = dispatchData.HppPerPcs;
      dispatch.HppTotal = dispatchData.HppTotal;
      dispatch.Quantity = dispatchData.Quantity;
      dispatch.Variants = variants;
    },
    setDispatchesByData(dispatches, rows) {
      App.Array.truncate(dispatches);

      for (const row of rows) {
        let dispatch = this.createDispatch();
        this.setDispatchByData(dispatch, row);
        dispatches.push(dispatch);
      }
    },

    updateDispatchesName(dispatches, itemName) {
      for (let dispatch of dispatches) {
        dispatch.FullName = ItemModel_Body.createDispatchName(itemName, dispatch.Variants);
      }
    },
    updateDispatchesByEditData(dispatches, dispatchData) {
      const valueSKU = App.Out.getString(dispatchData.SKU);
      const valueSellPrice = App.Out.getString(dispatchData.SellPrice);
      const valueWeight = App.Out.getString(dispatchData.Weight);
      const valueBuyPrice = App.Out.getString(dispatchData.BuyPrice);
      const valueBarcode = App.Out.getString(dispatchData.Barcode);
      const valueVendor = App.Out.getString(dispatchData.Vendor);
      const valueDescription = App.Out.getString(dispatchData.Description);
      const valueHppPerPcs = App.Out.getString(dispatchData.HppPerPcs);
      const valueHppTotal = App.Out.getString(dispatchData.HppTotal);
      const valueQuantity = App.Out.getString(dispatchData.Quantity);

      for (const item of dispatches) {
        if (valueSKU) {
          item.SKU = valueSKU;
        }
        if (valueSellPrice) {
          item.SellPrice = valueSellPrice;
        }
        if (valueWeight) {
          item.Weight = valueWeight;
        }
        if (valueBuyPrice) {
          item.BuyPrice = valueBuyPrice;
        }
        if (valueBarcode) {
          item.Barcode = valueBarcode;
        }
        if (valueVendor) {
          item.Vendor = valueVendor;
        }
        if (valueDescription) {
          item.Description = valueDescription;
        }
        if (valueHppPerPcs) {
          item.valueHppPerPcs = valueHppPerPcs;
        }
        if (valueHppTotal) {
          item.valueHppTotal = valueHppTotal;
        }
        if (valueQuantity) {
          item.valueQuantity = valueQuantity;
        }
      }
    },

    /* dispatch variant */
  
    createDispatchVariant() {
      return {
        VariantID: null,    // Variants.ID
        VariantValue: null, // Variants.Value.ValueID
        VariantName: "",
        VariantValueName: ""
      }
    },

    setDispatchVariantByData(dispatchVariant, dispatchVariantData) {
      dispatchVariant.VariantID = dispatchVariantData.VariantID;
      dispatchVariant.VariantValue = dispatchVariantData.VariantValue;
      dispatchVariant.VariantName = dispatchVariantData.VariantName;
      dispatchVariant.VariantValueName = dispatchVariantData.VariantValueName;
    },

    getDispatchVariants(dispatch) {
      if (dispatch.Variants.length === 0) {
        return 'default';
      }

      let variantText = '';
      for (const variant of dispatch.Variants) {
        variantText += (variantText === '' ? '' : ' | ') + variant.VariantValueName;
      }
      return variantText;
    },

    /* packaging */

    createPackaging() {
      return {
        UnitID: null,
        ItemUnitName: "",
        ItemUnitAmount: "",
        IsActive: true,
        IsDefault: false,
        // error
        Errors: [],
        ErrorsColl: {},
        // UI validation
        ItemUnitName_Valid: true
      };
    },

    setPackagingByData(packaging, packagingData) {
      packaging.UnitID = packagingData.UnitID;
      packaging.ItemUnitName = App.In.getString(packagingData.ItemUnitName);
      packaging.ItemUnitAmount = App.In.getInteger(packagingData.ItemUnitAmount);
      packaging.IsActive = App.In.getBoolean(packagingData.IsActive);
    },
    setPackagingByDefault(packaging, defaultPackaging) {
      packaging.ItemUnitName = defaultPackaging.Name;
      packaging.ItemUnitAmount = App.In.getInteger(defaultPackaging.Amount);
      packaging.IsDefault = true;
    },
    setPackagingErrors(item, field, errors) {
      item.Errors = App.Vee.getRowErrors(item, field, errors);
    },
    setPackagingsByData(packagings, rows, defaultPackaging) {
      App.Array.truncate(packagings);

      let hasSinglePackaging = false;

      for (const row of rows) {
        if (row.ItemUnitAmount === defaultPackaging.Amount) {
          hasSinglePackaging = true;
        }

        let packaging = this.createPackaging();
        this.setPackagingByData(packaging, row);
        packagings.push(packaging);
      }

      if (!hasSinglePackaging) {
        let packaging = this.createPackaging();
        this.setPackagingByDefault(packaging, defaultPackaging);
        packagings.push(packaging);
      }
    },

    getPackagingOptions(items) {
      const packagingList = [];

      for (const item of items) {
        if (item.IsActive === 1) {
          item.Name = App.Data.getPackaging(item.ItemUnitName, item.ItemUnitAmount);
          packagingList.push(item);
        }
      }

      return App.In.getSelectOptions("ItemUnitName", "Name", packagingList);
    },
    getPackagingValue(items, packagingName) {
      return App.Array.searchValue(items, "ItemUnitName", packagingName, "ItemUnitAmount");
    },

    updatePackagingsErrors(data, packagings) {
      let errors = [];
      let packagingNames = [];
      let activePackaging = 0;

      for (let packaging of packagings) {
        // validate: ItemUnitName
        packaging.ItemUnitName_Valid = true;

        if (packagingNames.includes(packaging.ItemUnitName)) {
          // show error message once for all
          if (errors.length === 0) {
            errors.push(PackagingCatalog.ItemUnitName.Label + " telah dipakai");
          }

          packaging.ItemUnitName_Valid = false;
        }
        else {
          packagingNames.push(packaging.ItemUnitName);
        }

        // validate: ItemUnitName
        if (packaging.IsActive) {
          activePackaging++;
        }
      }

      data.ActivePackaging = activePackaging;

      // validate: ItemUnitName
      if (activePackaging === 0) {
        errors.push("Minimum " + ItemCatalog.ActivePackaging.Label + " adalah " +
          ItemCatalog.ActivePackaging.MinValue
        );
      }

      data.PackagingsErrors = errors;
    },

    /* variant */

    createVariant(idObj) {
      const id = idObj.Variant;
      idObj.Variant--;

      return {
        ID: id,
        Name: "",
        Value: [],
        // UI validation
        Name_Valid: true
      };
    },

    setVariantByData(variant, variantData, idObj) {
      // variant value
      let variantValues = [];

      for (const row of variantData.Value) {
        let variantValue = this.createVariantValue(idObj);
        this.setVariantValueByData(variantValue, row);
        variantValues.push(variantValue);
      }

      // variant
      variant.ID = variantData.ID;
      variant.Name = App.In.getString(variantData.Name);
      variant.Value = variantValues;
    },
    setVariantsByData(variants, rows, idObj) {
      App.Array.truncate(variants);
      
      for (const row of rows) {
        let variant = this.createVariant(idObj);
        this.setVariantByData(variant, row, idObj);
        variants.push(variant);
      }
    },

    updateVariantsErrors(data, variants) {
      let errors = [];
      let variantNames = [];

      for (let variant of variants) {
        variant.Name_Valid = true;

        if (variantNames.includes(variant.Name)) {
          // show error message once for all
          if (variant.Name_Valid) {
            errors.push(VariantCatalog.Name.Label + " telah dipakai");
          }

          variant.Name_Valid = false;
        }
        else {
          variantNames.push(variant.Name);
        }
      }

      data.VariantsErrors = errors;
    },

    /* variant value */
    createVariantValue(idObj) {
      let id = null;
      if (idObj !== undefined) {
        id = idObj.VariantValue;
        idObj.VariantValue--;
      }

      return {
        ValueID: id,
        Value: ""
      };
    },

    setVariantValueByData(variantValue, variantValueData) {
      variantValue.ValueID = variantValueData.ValueID;
      variantValue.Value = App.In.getString(variantValueData.Value);
    },
    setVariantValueByValue(variantValue, variantValueData) {
      variantValue.Value = variantValueData.Value;
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: App.Session.getCompanyID(),
        Type: data.Type,
        Name: data.Name,
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID
      };
    },
    populateVariants(variants) {
      if (variants.length === 0) {
        return [{
          ID: -1,
          Name: "default",
          Value: [{
            ValueID: -1,
            Value: "default"
          }]
        }];
      }

      let result = [];
      for (const item of variants) {
        result.push(this.populateVariant(item));
      }

      return result;
    },
    populateVariant(variant) {
      return {
        ID: variant.ID,
        Name: variant.Name,
        Value: this.populateVariantValues(variant.Value)
      };
    },
    populateVariantValues(variantValues) {
      let result = [];
      for (const item of variantValues) {
        result.push({
          ValueID: item.ValueID,
          Value: item.Value
        });
      }

      return result;
    },
    populatePackagings(packagings) {
      let result = [];
      for (const item of packagings) {
        result.push(this.populatePackaging(item));
      }

      return result;
    },
    populatePackaging(packaging) {
      return {
        UnitID: packaging.UnitID,
        ItemUnitName: packaging.ItemUnitName.trim(),
        ItemUnitAmount: parseInt(packaging.ItemUnitAmount),
        IsActive: (packaging.IsActive ? 1 : 0)
      };
    },
    populateVariations(variations) {
      let result = [];
      for (const item of variations) {
        result.push(this.populateVariation(item));
      }

      return result;
    },
    populateVariation(variation) {
      // Image64: null means no change
      return {
        DispatchID: variation.DispatchID,
        FullName: variation.FullName,
        SKU: variation.SKU,
        SellPrice: parseInt(variation.SellPrice),
        Weight: parseInt(variation.Weight),
        BuyPrice: parseInt(variation.BuyPrice),
        Barcode: variation.Barcode,
        Vendor: variation.Vendor,
        Description: variation.Description,
        Image64: (variation.ImageIsLocal ? variation.Image64 : null) ,
        Variants: this.populateVariationVariants(variation.Variants)
      };
    },
    populateVariationVariants(variationVariants) {
      if (variationVariants.length === 0) {
        return [{
          VariantID: -1,
          VariantValue: -1
        }];
      }

      let result = [];
      for (const item of variationVariants) {
        result.push({
          VariantID: item.VariantID,
          VariantValue: item.VariantValue
        });
      }

      return result;
    },
  }
}