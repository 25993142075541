import Catalog from "./StockClosingCatalog";
import Model from "./StockClosingModel";

export default {
  Message: {
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.SalesItem.Module.FullName + ".",
    New_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label,
    New_ConfirmResetItems:
      "Yakin ingin me-reset data " + Model.Module.FullName + "?",
    ApplyDiscountMessage:
      "Terapkan diskon untuk semua produk di bawah ini."
  }
}