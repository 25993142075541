<template>
  <kst-container class="kst-stockavailability-list">
    <kst-form
      :failureInit="failureInit"
      :loading="isLoading"
      :title="title"
    >
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          :statusOptions="statusOptions"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import List from './StockAvailabilityList_List';
import Search from './StockAvailabilityList_Search';
import Model from '../StockAvailabilityModel.js';
import Services from '../StockAvailabilityServices.js';
import SearchCatalog from './StockAvailabilitySearchCatalog.js';

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    searchData: {},
    warehouseOptions: {},
    statusOptions: {},
    items: [],
    isLoading: true,
    isListLoading: false,
    loadCounter: 0,
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch()
      this.initLoad();
    },
    initSearch() {
      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      }
    },
    initLoad() {
      this.loadWarehouse();
    },
    loadWarehouse() {
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data, SearchCatalog.WarehouseID.Label);
      this.isLoading = false;
      this.loadData();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadData() {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];
      const params = Model.populateSearchData(this.searchData);
      const config = Services.getStockAvailability(params);
      this.runAxios(config, null, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data) {
      this.items = data;
      this.isListLoading = false;
    },
    loadData_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },
    handleSearch_Submit() {
      this.loadCounter++;
      this.loadData();
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadData();
    }
  }
}
</script>