<template>
  <kst-section items-mode class="mt-5">
    <StockSearch
      ref="mdlStockSearch"
      @submit="handleItem_Submit"
    />
    <PriceSearch ref="mdlPriceSearch" @submit="handleInvoice_Submit"/>

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.SalesItem.Module.FullName"
          :moduleType="Model.SalesItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.SalesItem.Module.FullName"
            :moduleType="Model.SalesItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </template>
      </template>
    </template>
    
    <kst-warning class="mb-4" :items="itemsWarning"/>

    <template v-if="userData.WarehouseID !== null">
      <kst-failure class="mb-4" data-mode :message="failureItems"/>
      <List :userData="userData" :userItems="userItems" @callback="handleList_Callback"/>
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Info from "../SalesInfo.js";
import Model from "../SalesModel.js";
import Services from "../SalesServices.js";

import StockSearch from "@/components/Stock/Search/StockSearch";
import PriceSearch from "@/components/Invoice/PriceSearch/InvoicePriceSearch";
import List from "./SalesEdit_Items_List";

export default {
  mixins: [AxiosMixin],
  components: {
    StockSearch,
    PriceSearch,
    List
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    failureItems: null,
    rowIndex: null
  }),
  computed: {
    itemsWarning() {
      if (this.userData.WarehouseID === null) {
        return [Info.Message.Edit_Items];
      }
      else {
        if (this.userData.InvoiceCount === 0) {
          return [
            Info.Message.Edit_DisabledNamePrice,
            Info.Message.Edit_DisabledIsClosed
          ];
        }
        else {
          return [
            Info.Message.Edit_EditPackaging,
            Info.Message.Edit_DisabledNamePrice,
            Info.Message.Edit_DisabledIsClosed,
            Info.Message.Edit_DeleteItem
          ];
        }
      }
    }
  },
  methods: {
    loadDispatchDetails(dispatchId, item) {
      this.failureItems = null;
      const config = Services.getDispatchDetails(dispatchId);
      this.runAxios(config, item, this.loadDispatchDetails_Success, this.loadDispatchDetails_Error);
    },
    loadDispatchDetails_Success(data, item) {
      Model.setItemByDispatchDetails(item, data);
      Model.setItemByPackaging(item);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    loadDispatchDetails_Error(message) {
      this.failureItems = message;
    },

    handleAdd_Item() {
      this.failureItems = null;
      this.$refs.mdlStockSearch.show(this.userData.WarehouseID);
    },

    handleItem_Submit(row) {
      let item = Model.createItem();
      Model.setItemByStock(item, row);
      this.loadDispatchDetails(row.DispatchID, item);
    },

    handleInvoice_Submit(row) {
      Model.setItemByPrice(this.userItems[this.rowIndex], row);
    },
    handleList_Callback(params) {
      const { isPrice, index } = params;

      if (isPrice) {
        this.rowIndex = index;
        const item = this.userItems[index];
        this.$refs.mdlPriceSearch.show(
          // sellPrice, clientId, clientName, dispatchId, invoiceId
          item.DispatchSellPrice,
          this.userData.ClientID,
          this.userData.ClientName,
          item.DispatchID,
          null
        );
      }
    }
  }
}
</script>