<template>
  <kst-print class="kst-rptomzet-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData"/>
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column">
          <Details :data="data"/>
        </div>
        <div class="column">
          <Details2 :data="data" :items="paymentTypeList"/>
        </div>
      </div>
    </template>
    <Items class="mt-4" :items="data.Details"/>
  </kst-print>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../OmzetInfo.js";
import Model from "../OmzetModel.js";
import Services from "../OmzetServices.js";

import Company from "./OmzetPrint_Company";
import Details from "./OmzetPrint_Details";
import Details2 from "./OmzetPrint_Details2";
import Items from "./OmzetPrint_Items";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Details,
    Details2,
    Items,
  },
  data: () => ({
    data: {},
    paymentTypeList: [],
    companyData: {},
    isLoading: true,
    // error
    error: null
  }),
  computed: {
    timestamps() {
      return "Dicetak pada: " + this.$kst.View.getTimestamp();
    },
    title() {
      return Model.Module.FullName;
    }
  },
  created(){
    this.init();
  },
  methods: {
    init() {
      let searchData = {
        Date: [
          this.getUrlParam(this.$kst.Enum.Param.StartDate),
          this.getUrlParam(this.$kst.Enum.Param.EndDate)
        ],
        WarehouseID: this.getUrlParam(this.$kst.Enum.Param.WarehouseID),
      };

      this.error = null;

      if (!searchData) {
        this.error = Info.Message.PrintDetails_ParamIncomplete;
        return;
      }

      this.data = {};
      this.companyData = {};

      this.initLoad(searchData);
    },

    initLoad_End() {
      this.isLoading = false;
    },
    initLoad(searchData) {
      let options = {};
      if (searchData) {
        options.warehouse = searchData.WarehouseID;
        options.startDate = searchData.Date[0];
        options.endDate = searchData.Date[1];
        options.withreturn = 1;
      }

      const config = Services.getTurnoverSales(options);
      this.runAxios(config, null, this.initLoad_Success, this.initLoad_Error, this.initLoad_Invalid);
    },
    initLoad_Success(data) {
      this.processData(data);
      this.loadPaymentType();
      this.loadCompanyDetails();
    },
    initLoad_Error(message) {
      this.error = message;
      this.initLoad_End();
    },
    initLoad_Invalid(message) {
      this.error = message;
      this.initLoad_End();
    },

    loadPaymentType() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getPaymentTypeList();
      this.runAxios(config, null, this.loadPaymentType_Success, this.loadPaymentType_Error);
    },

    loadPaymentType_Success(data) {
      this.paymentTypeList = data
      Model.getPaymentType(this.data.Details, this.paymentTypeList);
    },
    loadPaymentType_Error(message) {
      this.error = message;
      this.initLoad_End();
    },

    processData(data) {
      let omzetTotal = 0;
      let totalBruto = 0;
      let totalDisc = 0;
      let totalRetur = 0;
      let totalTransfer = 0;
      let totalCash = 0;
      let normalPrice = 0;
      let specialPrice = 0;
      
      let omzet = {};
      let items = [];

      for(let item of data.InvoiceList){
        if (item.PaymentTypeID === null) {
          item.PaymentTypeID = 1
        }
        totalBruto += item.TotalBruto;
        totalDisc += item.TotalDisc;

        for (let item2 of this.paymentTypeList) {
          if (item.PaymentTypeID == item2.ID) {
            item.PaymentTypeName = item2.Name;
            item.IsActive = item2.IsActive
          }
        }

        const itemInvoice = {
          Total: item.Total,
          TotalBruto: item.TotalBruto,
          Date: item.InvoiceDate,
          Type: "Invoice",
          PaymentTypeID: item.PaymentTypeID,
        }
        items.push(itemInvoice);
      }

      if(data.ReturnList){
        for(let item of data.ReturnList) {
          totalRetur += item.Total;
          const itemRetur = {
            Date: item.Date,
            Type: "Retur",
            Total: item.Total
          }
          items.push(itemRetur);
        }
      }

      for(let item of data.InvoiceItemList) {
        if (item.SpecialPriceID === null) {
          normalPrice += (item.SellPrice * item.TotalRequestedQuantity);
        } else {
          specialPrice += item.Price;
        }
      }

      items.sort((a,b) => { return new Date(b.Date) - new Date(a.Date); });

      omzetTotal = totalBruto - totalDisc - totalRetur;

      omzet.Details = items;
      omzet.Total = omzetTotal;
      omzet.TotalBruto = totalBruto;
      omzet.TotalDisc = totalDisc;
      omzet.TotalRetur = totalRetur;
      omzet.TotalTransfer = totalTransfer;
      omzet.TotalCash = totalCash;
      omzet.NormalPrice = normalPrice;
      omzet.SpecialPrice = specialPrice;

      this.data = omzet;
    },

    loadCompanyDetails() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompanyDetails_Success, this.loadCompanyDetails_Error, this.loadCompanyDetails_Invalid);
    },
    loadCompanyDetails_Success(data) {
      this.companyData = data.data;
      this.initLoad_End();
    },
    loadCompanyDetails_Error(message) {
      this.errors = message;
      this.initLoad_End();
    },
    loadCompanyDetails_Invalid(message) {
      this.errors = message;
      this.initLoad_End();
    },

  }
}
</script>

<style lang="css" src="./_layout.css"/>