<template>
  <div class="columns my-0">
    <DispatchSearch ref="mdlDispatchSearch"
      :title="Model.ItemReport.Title" 
      @submit="handleSubmit_Item"
    />

    <div class="column is-narrow">
      <kst-input addons floating-label
        field="itemName"
        :data="searchData"
        :catalog="SearchCatalog"
      >
      <template #right>
        <div class="control">
          <template v-if="searchData.DispatchID === null">
            <kst-tooltip search-mode hover-only :content="SearchCatalog.DispatchID.Label">
              <kst-button search-mode @click="handleSearch_Item()"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="SearchCatalog.DispatchID.Label">
              <kst-button clear-mode @click="handleSearch_Clear"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
      </kst-input>
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="WarehouseID" :data="searchData" :catalog="SearchCatalog"
        :options="warehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="Date" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>

    <div class="column is-narrow">
      <kst-button reset-mode @click="handleReset"/>
    </div>
  </div>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Model from "../RptStockCardModel.js";
import SearchCatalog from "./RptStockCardSearchCatalog.js";

import DispatchSearch from "@/components/Dispatch/Search/DispatchSearch";

export default {
  mixins: [DialogMixin],
  components: {
    DispatchSearch
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog,
    Model: Model
  }),
  methods: {
    handleSearch_Item() {
      this.$refs.mdlDispatchSearch.show();
    },
    handleSubmit_Item(row) {
      this.searchData.itemName = row.FullName;
      this.searchData.DispatchID = row.DispatchID;
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch_Clear() {
      this.$emit(this.$kst.Enum.Event.Clear);
    }
  }
}
</script>