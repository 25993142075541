<template>
  <div class="kst-print-table">
    <template v-if="data">
      <b-table
        class="ks-border-y-black"
        :data="data"
      >
        <b-table-column
          v-slot="{ index }"
          cell-class="ks-col-sequence"
          width="10"
          :label="theSequenceLabel"
        >
          <div>
            {{ index + 1 }}
          </div>
        </b-table-column>

        <slot/>

        <template #empty>
          <slot name="empty">
            <kst-table-empty>
              <slot name="empty-body"/>
            </kst-table-empty>
          </slot>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    data: Array
  },
  computed: {
    theSequenceLabel() {
      return AppCatalog.Info.Sequence;
    }
  }
}
</script>