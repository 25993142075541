<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="ReturnNumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>
    <kst-output field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from '../RtnInvoiceCatalog.js';
import Info from "../RtnInvoiceInfo.js";
import Model from "../RtnInvoiceModel.js";

export default {
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.New_Date];
    },
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  }
}
</script>