<template>
  <kst-table paginated view-mode
    :data="items"
    :selected.sync="userData.selected"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="FullName"
      :label="DispatchCatalog.FullName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="DispatchCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-typename"
      field="TypeName"
      :label="Catalog.TypeName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="DispatchCatalog.SKU.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="DispatchCatalog"/>
    </b-table-column>
    
    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="DispatchCatalog.SellPrice.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="DispatchCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-weight-full"
      field="Weight"
      :label="DispatchCatalog.Weight.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="DispatchCatalog"/>
    </b-table-column>

  </kst-table>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import DispatchCatalog from "../DispatchCatalog.js";

export default {
  props: {
    items: Array,
    isListLoading: Boolean,
    userData: Object,
    failureList: String
  },
  data: () => ({
    Catalog: Catalog,
    DispatchCatalog: DispatchCatalog
  })
}
</script>