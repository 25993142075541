<template>
  <kst-table paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="kst-stock-search-FullName"
      field="FullName"
      :label="Catalog.FullName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty has-text-weight-bold"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="Catalog.SellPrice.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="showHpp">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpp"
        field="HppPerPcs"
        :label="Catalog.HppPerPcs.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>
    <template v-else>
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-weight"
        field="Weight"
        :label="Catalog.Weight.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../StockCatalog.js";

export default {
  props: {
    failureList: String,
    isListLoading: Boolean,
    items: Array,
    userData: Object,
    showHpp: Boolean
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>