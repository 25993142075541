<template>
  <kst-container class="kst-report-stockadj">
    <kst-form :loading="isLoading" :title="title">
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>
      <kst-skeleton :active="isListLoading"/>
      <Result v-if="data" :isListLoading="isListLoading" :data="data" :searchData="searchData"
        :chartData="chartData" :summaryList="summaryList" 
        :detailsList="detailsList"
       />
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Moment from 'moment';
import Catalog from "./StockAdjCatalog.js";
import Model from "./StockAdjModel";
import Info from "./StockAdjInfo";

import Search from "./StockAdj_Search";
import Result from "./StockAdj_Result";
import Services from "./StockAdjServices.js";
import App from "@/services/App/App.js";

const PARAM_StartDate = "startdate";
const PARAM_EndDate = "enddate";


export default {
  mixins: [AxiosMixin],
  components: {
    Search,
    Result
  },
  data: () => ({
    Model: Model,
    searchData: {},
    chartData: null,
    filter: {},
    warehouseOptions: {},
    subCategoryList: [],
    labelList: null,
    dataStockAdj: null,
    detailsList: [],
    summaryList: [],
    data: null,
    isLoading: true,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
    },
    initSearch() {
      const start = this.$route.query[PARAM_StartDate];
      const end = this.$route.query[PARAM_EndDate];

      if(start){
        let dateRange = [];
        dateRange.push(new Date(start));
        dateRange.push(new Date(end));
        
        this.searchData = {
          Date: dateRange,
          WarehouseID: this.$kst.Search.OptionAll
        }

        this.filter = {
          startDate: new Date(start),
          endDate: new Date(end),
          byMonth: 0
        }
      }else{
        this.searchData = {
          Date: this.$kst.In.getDefaultDateRange(),
          WarehouseID: this.$kst.Search.OptionAll
        }

        this.filter = {
          startDate: this.searchData.Date[0],
          endDate: this.searchData.Date[1],
          byMonth: 0
        }
      }

      this.loadWarehouse();
    },
    loadWarehouse() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },

    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, Catalog.WarehouseID.Label
      );
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
      this.isLoading = false;
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    loadReport(counter, searchData) {
      this.data = null;

      if (searchData) {
        if(searchData.WarehouseID === this.$kst.Search.OptionAll){
          this.filter.warehouseId = null;
        }

        let diff = Math.abs(searchData.Date[1] - searchData.Date[0]);
        const diffday = Math.ceil(diff / (1000 * 60 * 60 * 24));
        this.filter.byMonth = diffday > 30 ? 1 : 0;
      }

      const config = Services.getProfitLoss(this.filter);
      this.runAxios(config, counter, this.loadReport_Success, this.loadReport_Error);

      this.loadCategory();
    },

    loadReport_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.data = data;
      this.summarizeExpense();
    },
    loadReport_Error(message) {
      this.failureList = message;
      this.isLoading = false;
    },

    loadCategory() {
      const config = Services.getSubCatList();
      this.runAxios(config, null, this.loadCategory_Success, this.loadCategory_Error);
    },

    loadCategory_Success(data) {
      this.subCategoryList = data;
    },
    loadCategory_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    summarizeExpense() {
      let subCategoryObj = {};
      let expenseTotal = 0;
      let subCatId, total;
      for (const expense of this.data.Expenses) {
        subCatId = expense.SubCategoryID;
        total = App.Out.getInteger(expense.Total);
        expenseTotal += total;
        
        if (Object.prototype.hasOwnProperty.call(subCategoryObj, subCatId)) {
          subCategoryObj[subCatId] += total;
        }
        else {
          subCategoryObj[subCatId] = total;
        }
      }

      for (const subCategory of this.subCategoryList) {
        subCategory.Total = 0;
        if (Object.prototype.hasOwnProperty.call(subCategoryObj, subCategory.ID)) {
          subCategory.Total = subCategoryObj[subCategory.ID];
        }
      }

      this.data.ExpenseSummary = this.subCategoryList;
      this.data.Summary.All.TotalExpense = expenseTotal;
      this.data.Summary.All.TotalNetProfit = this.data.Summary.All.TotalProfit - expenseTotal;
      this.processData();
    },

    processData() {
      this.chartData = null;
      this.summaryList = this.data.Summary.Unit.map(item => {
        item.FormattedDate = this.filter.byMonth === 1 ? 
        Moment(item.Date, "MM-YYYY").locale("id").format('MMMM YYYY') : 
        this.$kst.Value.getValue("Date", item, Catalog);
        return item;
      });

      let items = [];
      for(let item of this.data.Details){
        if(item.TransactionName == "StockAdj"){
          items.push(item);
        }
      }
      items.sort((a,b) => { return new Date(b.Date) - new Date(a.Date); });
      this.detailsList = items;
      this.chartData = this.processChartData();
    },

    processChartData() {
      this.labelList = null;
      this.labelList = this.createChartLabel();

      this.dataStockAdj = this.labelList.map(item => {
        const summaryIdx = this.summaryList.findIndex(unit => unit.FormattedDate === item)
        const hppChanges = summaryIdx === -1 
          ? 0 : this.summaryList[summaryIdx].StockAdjustment
          ? this.summaryList[summaryIdx].StockAdjustment.HppChanges : 0 
        return hppChanges;
      });

      this.isLoading = false;
      this.isListLoading = false;

      return this.createChart();
    },

    createChart() {
      return {
        type: 'bar',
        data: {
          labels: this.labelList,
          datasets: [
            {
              label: Info.StockLabelDetail.Label,
              type: 'bar',
              data: this.dataStockAdj,
              backgroundColor: Info.StockLabelDetail.BackgroundColor,
              borderColor: Info.StockLabelDetail.BackgroundColor,
              borderWidth: 3
            }
          ]
        },
      }
    },

    createChartLabel() {
      let labelList = [];
      if(this.filter.byMonth === 1){
        // months
        let diff = Moment(this.searchData.Date[1]).diff(this.searchData.Date[0], 'months');
        let now = Moment(this.searchData.Date[0])
        for(let i = 0; i <= diff; i++) {          
          if(i === 0) {           
            labelList.push(now.locale("id").format('MMMM YYYY'));
          } else {
            let next = Moment(now).add(1, 'month');
            labelList.push(next.locale("id").format('MMMM YYYY'));
            now = next;
          }          
        }
      }else{
        // days
        let diff = Moment(this.searchData.Date[1]).diff(this.searchData.Date[0], 'days');
        let now = Moment(this.searchData.Date[0])
        for(let i = 0; i <= diff; i++) {          
          if(i === 0) {           
            labelList.push(now.locale("id").format('DD/MM/YYYY'));
          } else {
            let next = Moment(now).add(1, 'day');
            labelList.push(next.locale("id").format('DD/MM/YYYY'));
            now = next;
          }          
        }
      }
      return labelList;
    },

    handleSearchSubmit(filter) {
      this.isLoading = true;
      this.filter = filter;
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
    }
  }
}
</script>

<style lang="css" src="./_layout.css"/>