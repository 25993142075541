<template>
  <footer class="footer ks-border-t-light ks-border-r-light py-5 kst-layout-sidebar-about kst-layout-sidebar-width">
    <div class="mb-2">
      <div>
        version {{ version }}
        <!--
        <span class="icon pt-0 has-text-primary ks-cursor-pointer" @click="handleVersion">
          <i class="mdi mdi-help-circle-outline"></i>
        </span>
        -->
      </div>
    </div>
    <div class="">
      <div class="mb-1 has-text-weight-semibold">
        {{ Info.Copyright }}
      </div>
      <div class="ks-color-grey">
        {{ Info.Description }}
      </div>
    </div>
  </footer>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "./LayoutInfo.js";
import VersionRouter from "@/components/Version/VersionRouter.js";

export default {
  mixins: [RouterMixin],
  data: () => ({
    Info: Info
  }),
  computed: {
    version() {
      return this.$kst.View.getVersion();
    }
  },
  methods: {
    handleVersion() {
      this.openTab(VersionRouter.Path.Details);
    }
  }
}
</script>