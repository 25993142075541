var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"selected":_vm.userData.selected},on:{"update:selected":function($event){return _vm.$set(_vm.userData, "selected", $event)}}},[_c('b-table-column',{attrs:{"cell-class":"ks-col-image","field":"DispatchImageSrc","label":_vm.InvoiceItemCatalog.DispatchImageSrc.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-image',{attrs:{"read-only":"","thumbnail":"","src":row[column.field]}})]}}])}),_c('b-table-column',{attrs:{"field":"DispatchID","label":_vm.InvoiceItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-sku","field":"SKU","label":_vm.InvoiceItemCatalog.SKU.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-qty","field":"QtyAvailable","label":_vm.InvoiceItemCatalog.QtyAvailable.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"Packaging","label":_vm.InvoiceItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPrice","label":_vm.InvoiceItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }