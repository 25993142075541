import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.BestSellingItems),
  },

  getRoutes() {
    return [];
  }
}