<template>
  <div>
    <kst-section info-mode :title="salesSectionTitle">
      <template v-if="data.SalesDraftID !== null">
        <kst-output field="SalesDraftID" :data="data" :catalog="Catalog"/>
        <kst-output field="DraftDate" :data="data" :catalog="Catalog"/>
      </template>
      <template v-else>
        <kst-warning :items="salesWarning"/>
      </template>
    </kst-section>

    <kst-output field="SONumber" :data="data" :catalog="Catalog"/>
    <kst-output field="InvoiceDate" :data="data" :catalog="Catalog"/>

    <template v-if="data.ClientID">
      <kst-output field="ClientID" :data="data" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="ClientAlias" :data="data" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="Client" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output control-off field="ClientID" :data="data" :catalog="Catalog">
        <kst-output addons expanded floating-label field="Client" :data="data" :catalog="Catalog"/>
      </kst-output>
    </template>

    <kst-output field="ClientAddress" :data="data" :catalog="Catalog"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
    <kst-output field="PONumber" :data="data" :catalog="Catalog"/>

    <kst-output field="DiscType" :label="Catalog.DiscValue.Label" :data="data" :catalog="Catalog">
      <template v-if="data.DiscValue !== null">
        <kst-output field-only field="DiscValue" :data="data" :catalog="Catalog"/>
      </template>
      <template v-else-if="data.DiscPercent !== null">
        <kst-output field-only field="DiscPercent" :data="data" :catalog="Catalog"/>
      </template>
    </kst-output>

    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-output field="StatusName" :data="data" :catalog="Catalog"/>

    <kst-output control-off field="CreatedBy" :data="data" :catalog="Catalog">
      <template #default>
        <kst-output expanded floating-label
          class="pr-2"
          field="CreatedByID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #right>
        <kst-output addons expanded floating-label
          field="CreatedDate" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>

    <kst-section :title="deliverySectionTitle" class="mt-5">
      <kst-output field="IsNeedDelivery" :data="data" :catalog="Catalog"/>
      <template v-if="data.IsNeedDelivery">
        <kst-output field="DeliveryDate" :data="data" :catalog="Catalog"/>
      </template>
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    deliverySectionTitle() {
      return Info.Section.Delivery.Title;
    },
    salesSectionTitle() {
      return Info.Section.Sales.Title;
    },
    salesWarning() {
      return [Info.Message.Details_NoSales];
    }
  }
}
</script>