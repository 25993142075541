<template>
  <kst-container class="kst-receive-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :loading="isLoading"
        :title="title"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Invoice :userData="userData"/>
        <Details
          :discTypeOptions="discTypeOptions"
          :userData="userData"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
        />
        <Items
          :invoiceItemsGrouped="invoiceItemsGrouped"
          :userData="userData"
          :userItems="userItems"
        />
        <Total :userData="userData" />
        <Details2
          class="mt-5"
          :userData="userData"
          :userItems="userItems"
          :discTypeOptions="discTypeOptions"
        />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../RtnInvoiceInfo.js";
import Model from "../RtnInvoiceModel.js";
import Router from "../RtnInvoiceRouter.js";
import Services from "../RtnInvoiceServices.js";

import Details from "./RtnInvoiceEdit_Details";
import Details2 from "./RtnInvoiceEdit_Details2";
import Invoice from "./RtnInvoiceEdit_Invoice";
import Items from "./RtnInvoiceEdit_Items";
import Total from "./RtnInvoiceEdit_Total";

export default {
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  components: {
    Details,
    Details2,
    Invoice,
    Items,
    Total
  },
  data: () => ({
    isLoading: false,
    userData: {},
    userItems: [],
    invoiceData: {},
    invoiceItemsGrouped: {},
    warehouseOptions: {},
    discTypeOptions: [],
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.routeLeaveEnabled = false;
        this.formWarnings = [Info.Message.Edit_ParamIncomplete];
        return;
      }

      this.userData = Model.createDetails();
      this.userData.IsAutoNumber = false;
      this.userItems = [];
      this.invoiceItemsGrouped = {};
      this.invoiceData = null;
      this.discTypeOptions = Model.createDiscTypeOptions();

      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
      this.loadEditData(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      this.failureInit = null;
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadEditData(id) {
      const config = Services.getRtnInvoiceEditData(id);
      this.runAxios(config, null,
        this.loadEditData_Success,
        this.loadEditData_Error,
        this.loadEditData_Invalid
      );
    },
    loadEditData_Success(data) {
      this.updateByData(data);
      this.initLoad_End();
    },
    loadEditData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadEditData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.putRtnInvoice(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },

    updateByData(data) {
      const returnDetails = data.Details;

      this.invoiceData = data.InvoiceDetails;
      this.invoiceItemsGrouped = data.InvoiceDetails.ItemsWithReturn;

      // invoice items
      for (let invoiceItem of this.invoiceItemsGrouped) {
        Model.updateInvoiceItem(invoiceItem);
      }

      // return invoice
      Model.setItemsByData(this.userItems, returnDetails.Items);
      Model.setDetailsByData(this.userData, returnDetails);
      Model.updateDetails(this.userData, this.userItems);
    }
  }
}
</script>