import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import HppAdjItemCatalog from "./HppAdjItemCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.HppAdj,
  ModuleType: {
    Details: AppModuleType.Details,
    New: AppModuleType.New,
    List: AppModuleType.List,
    PrintDetails: AppModuleType.PrintDetails
  },
  
  /*** related ***/

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Search: {
    Fields: ["AdjNumber"]
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },

  setItemByItemDetails(item, itemData) {
    this.helper.setItemByItemDetails(item, itemData);
  },
  setItemByStock(item, stockData) {
    this.helper.setItemByStock(item, stockData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },
  setItemsByStock(items, stockData) {
    this.helper.setItemsByStock(items, stockData);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },

  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },

  setDefaultPackaging(item) {
    this.helper.setDefaultPackaging(item);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validateItem(row, items) {
    let result = this.helper.validateItem(row, items);
    return result;
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails() {
      return {
        CompanyID: App.Session.getCompanyID(),
        // user input
        WarehouseID: null,
        AdjNumber: "",
        IsAutoNumber: true,
        Comment: "",
        // defined by system
        WarehouseName: "",
        CreatedByID: App.Session.getUserID(),
      };
    },
    createItem() {
      return {
        // user input
        DispatchID: null,
        Qty: "",
        PackagingName: "",
        // defined by system
        Name: "",
        SKU: "",
        Price: "",
        Stock: 0,
        HppPerPcs: 0,
        HppTotal: 0,
        TotalQty: 0,
        Total: 0,
        Packaging: "",
        PackagingValue: 1,
        PackagingOptions: undefined,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    setItemByItemDetails(item, itemData) {
      item.PackagingOptions = ItemModel.getPackagingOptions(itemData.Packaging);
    },
    setItemByStock(item, stockData) {
      // user input
      item.DispatchID = stockData.DispatchID;
      item.Name = stockData.FullName;
      item.DispatchName = stockData.FullName;
      item.Price = App.In.getInteger(stockData.SellPrice);
      // defined by system
      item.SKU = stockData.SKU;
      item.Stock = stockData.Quantity;
      // item.HppPerPcs = App.In.getDecimal(stockData.HppPerPcs);
      item.HppPerPcs = App.Value.getValue("HppPerPcs", stockData, HppAdjItemCatalog);
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        item.ErrorsColl[field].push(error);
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      item.Errors = newErrors;
    },
    setItemsByStock(items, stockData) {
      for (const item of items) {
        if (item.DispatchID === stockData.DispatchID) {
          item.Stock = stockData.Qty;
        }
      }
    },

    getItemInfo(item) {
      let infoList = [];

      // SKU
      infoList.push(HppAdjItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, HppAdjItemCatalog)
      );

      // Price
      infoList.push(HppAdjItemCatalog.Price.Label + ": " +
        App.Value.getValue("Price", item, HppAdjItemCatalog)
      );

      // Packaging
      infoList.push(HppAdjItemCatalog.PackagingName.Label + ": " + item.PackagingName + " (" +
        App.Value.getValue("PackagingValue", item, HppAdjItemCatalog) + ")"
      );

      return infoList;
    },

    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(HppAdjItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, HppAdjItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(HppAdjItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, HppAdjItemCatalog)
      );
    
      // Packaging
      infoList.push(HppAdjItemCatalog.PackagingName.Label + ": " + item.PackagingName + " (" +
        App.Value.getValue("PackagingValue", item, HppAdjItemCatalog) + ")"
      );

      return infoList;
    },

    updateItem(item) {
      item.HppTotal = item.Stock * item.HppPerPcs;
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },

    setDefaultPackaging(item) {
      let result;
      for(let packaging of item.PackagingOptions.rows) {
        if(packaging.ItemUnitAmount === 1) {
          result = packaging;
        }else{
          result = item.PackagingOptions.rows[0];
        }
      }

      item.PackagingName = result.ItemUnitName;
      item.PackagingValue = result.ItemUnitAmount;
    },

    populateDetails(data) {
      return {
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        AdjNumber: data.IsAutoNumber ? null : App.Out.getString(data.AdjNumber),
        Comment: data.Comment,
        CreatedByID: data.CreatedByID,
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          DispatchID: item.DispatchID,
          DispatchName: item.DispatchName,
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          HppPerPcs: parseFloat(item.HppPerPcs),
        });
      }

      return resultItems;
    },

    validateItem(row, items) {
      let validate = true;
      items.map(item => {
        if(item.DispatchID === row.DispatchID) {
          validate = false;
        }
      });
      return validate;
    }
  }
}