<template>
  <div class="kst-image-container kst-image-size kst-image-border is-relative ks-flex-center">
    <template v-if="!readOnly">
      <kst-tooltip delete-image-mode class="kst-image-delete">
        <kst-button clear-mode is-round
          class="has-text-danger ks-borderless"
          @click="handleDelete"
        />
      </kst-tooltip>
    </template>

    <img class="kst-image-preview" :src="src" @click="handleClick"/>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    readOnly: Boolean
  },
  methods: {
    handleDelete() {
      this.$emit(this.$kst.Enum.Event.Delete);
    },
    handleClick() {
      this.$emit(this.$kst.Enum.Event.Click);
    }
  }
}
</script>