<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ row }"
      field="Type"
      :label="Catalog.Type.Label"
    >
      {{ getType(row) }}
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :class="getClass(row)" :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../OmzetDetailsCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    getType(item) {
      if(item.Type === "Invoice"){
        return "Penjualan";
      }else{
        return "Retur";
      }
    },
    getClass(item) {
      return (parseFloat(item.Total) >= 0 ? '' : 'has-text-danger');
    }
  }
}
</script>