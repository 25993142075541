<template>
  <div>
    <template>
      <div class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column pb-0 is-narrow">
          <div class="title is-5">
            {{ Catalog.TotalOpen.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right">
            <kst-value class="title is-5" field="TotalOpen" :data="data" :catalog="Catalog"/>
          </div>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5 is-flex">
            {{ Catalog.TotalClosed.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5" field="TotalClosed" :data="data" :catalog="Catalog"/>
          </div>
        </div>
      </div>
    </template>

    <div class="columns is-mobile">
      <div class="column is-8 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.GrandTotal.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5" field="GrandTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../ClientCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>