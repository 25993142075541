<template>
  <kst-table action input-mode :loading="isListLoading" :failure="failureList" :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="PurchaseItemCatalog.Name.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="PurchaseItemCatalog.PackagingName.Label"
    >
      <template v-if="row.DispatchID === Model.NewItem.DispatchID">
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="PurchaseItemCatalog"
        />
      </template>
      <template v-else>
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
          :index="index" :options="row.PackagingOptions"
          @input="handlePackaging(row)" @error="handleRowErrors"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="PurchaseItemCatalog.Quantity.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="BuyPrice"
      :label="PurchaseItemCatalog.BuyPrice.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        :index="index" @input="handleBuyPrice(row)" @error="handleRowErrors"
      >
        <template #right>
          <div class="control">
            <kst-tooltip search-mode hover-only
              position="is-bottom"
              :content="PurchaseItemCatalog.BuyPrice.Label"
            >
              <kst-button search-mode @click="handleSearch_Price(index)"/>
            </kst-tooltip>
          </div>
        </template>
      </kst-input>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ExpenseAmount"
      :label="PurchaseItemCatalog.ExpenseAmount.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.PurchaseItem.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Catalog from "../PurchaseCatalog.js";
import Info from "../PurchaseInfo.js";
import PurchaseItemCatalog from "../PurchaseItemCatalog.js";
import Model from "../PurchaseModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userItems: Array,
    isListLoading: Boolean,
    failureList: String
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    PurchaseItemCatalog: PurchaseItemCatalog,
    selectedIndex: null
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleQuantity(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleBuyPrice(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userItems.splice(this.selectedIndex, 1);
      Model.updateDetails(this.userData, this.userItems);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    },
    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    },

    handleSearch_Price(index) {
      this.$emit(this.$kst.Enum.Event.Callback, {
        isPrice: true,
        index: index
      });
    }
  }
}
</script>