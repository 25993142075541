<template>
  <kst-container class="kst-client-edit">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :failure="failure"
        :failureInit="failureInit"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details :loading="isLoading" :userData="userData" :categoryOption="categoryOption" />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";

import Router from "../ExpenseRouter.js";
import Model from "../ExpenseModel.js";
import Info from "../ExpenseInfo.js";

import Details from "./ExpenseEdit_Details";
import Services from "../ExpenseServices.js";

export default {
  components: {
    Details
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, SnackbarMixin, RouterMixin],
  data: () => ({
    userData: {},
    categoryOption: {},
    isLoading: false,
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }
      this.userData = {};

      this.loadDetails(id);
      this.loadCategory();
    },

    loadCategory() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getExpenseSubList();
      this.runAxios(config, null, this.loadCategory_Success, this.loadCategory_Error);
    },

    loadCategory_Success(data) {
      this.categoryOption = Model.createOptions(data);
      this.isLoading = false;
    },
    loadCategory_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;

      const config = Services.getExpenseDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },

    loadDetails_Success(data) {
      this.userData = Model.createDetails();
      this.userData.IsAutoNumber = false;
      Model.setDetailsByData(this.userData, data);
      this.isLoading = false;
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let data = Model.populateData(this.userData);
          this.saveData(data);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData_End() {
      this.closeLoading(this.saveLoading);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.putExpenseEdit(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },

    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;
      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
        
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>