import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.ProfitLoss,
  ModuleType: {
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    Print: AppModuleType.Print
  },

  /*** related ***/

  StockOpname: {
    Module: AppModule.StockOpname,
  },

  /*** property ***/

  IsActive: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    },
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    }
  },

  Tooltip: {
    Label: "Lihat Ringkasan Pengeluaran Biaya"
  },

  Expense: {
    Title: "Ringkasan Pengeluaran Biaya"
  },

  /*** external ***/

  createAccess() {
    return AppCatalog.Access.getDefault();
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  mapDataExpense(data) {
    data = data || []
    data = data
      .filter(item => item.Total > 0)
      .sort((prev, next) => next.IsActive - prev.IsActive)
    return {
      TotalActive: this.getAmountSummary(data.filter(item => item.IsActive == 1)),
      TotalNotActive: this.getAmountSummary(data.filter(item => item.IsActive == 0)),
      GrandTotal: this.getAmountSummary(data),
      Items: data
    }
  },

  getAmountSummary(data) {
    let result = 0;
    data = data || [];
    if(data.length > 0) {
      result = data
        .map(item => parseInt(item.Total || 0))
        .reduce((next, prev) => next + prev)
    }
    return result;
  }
}