<template>
  <kst-print class="kst-invoice-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData"/>
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column">
          <Header :data="data"/>
        </div>
      </div>
    </template>

    <Items class="mt-4" 
      :items="data.Items"
      :isListLoading="isListLoading"
      :failureList="failureList"
      />
    <Total class="mt-4" :data="data" />
  </kst-print>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Company from "./StockClosingPrintDetail_Company";
import Header from "./StockClosingPrintDetail_Header";
import Items from "./StockClosingPrintDetail_Items";
import Total from "./StockClosingPrintDetail_Total";
import Model from "../StockClosingModel.js";
import Services from "../StockClosingServices.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Header,
    Items,
    Total
  },
  data: () => ({
    data: {},
    companyData: {},
    error: null,
    isLoading: true,
    isListLoading: true,
    failureList: null
  }),
  computed: {
    title() {
      return Model.Module.FullName;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadCompanyData();
    },
    loadCompanyData() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompanyData_Success, this.loadCompanyData_Error);
    },
    loadCompanyData_Success(data) {
      this.companyData = data.data;
      this.isLoading = false;
      this.loadData();
    },
    loadCompanyData_Error(message) {
      this.isLoading = false;
      this.error = message;
    },
    loadData() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      const config = Services.getStockClosingDetail(id);
      this.runAxios(config, null, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data) {
      this.isListLoading = false;
      this.data = data;
      this.data.Items = this.data.Items.map(item => Model.mapDataItemDetail(item));
    },
    loadData_Error(message) {
      this.isListLoading = false;
      this.failureList = message;
    }
  }
}
</script>
