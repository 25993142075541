<template>
  <div class="kst-skeleton">
    <div v-show="active" class="mb-4">{{ AppCatalog.Message.Wait }}</div>

    <template v-if="!simpleMode">
      <b-skeleton v-for="index in total" :key="index" size="is-large" :active="active"/>
    </template>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    active: Boolean,
    total: {
      type: Number,
      default: 3
    },
    simpleMode: Boolean
  },
  data: () => ({
    AppCatalog: AppCatalog
  })
}
</script>