import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";
import PaymentTypeModel from "@/components/Settings/PaymentType/PaymentTypeModel.js";
import ItemCatalog from "./PosItemCatalog.js";

export default {
  Module: AppModule.Pos,

  Item: {
    Module: AppModule.Item,
  },

  Search: {
    Fields: ["FullName", "SKU", "Barcode"]
  },

  DiscType: {
    None: {
      ID: 0,
      Label: "Tanpa Diskon",
    },
    Value: {
      ID: 1,
      Label: "Diskon (Rp)",
    },
    Percent: {
      ID: 2,
      Label: "Diskon (%)",
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails(this.DiscType);
  },

  createItem() {
    return this.helper.createItem();
  },

  createDiscTypeOptions(optionAllText) {
    return this.helper.createDiscTypeOptions(optionAllText, this.DiscType);
  },

  clearDetailsByClient(details) {
    this.helper.clearDetailsByClient(details);
  },

  setItemByData(item, itemData, qty) {
    return this.helper.setItemByData(item, itemData, qty);
  },

  updateDetails(data, items) {
    return this.helper.updateDetails(data, items, this.DiscType);
  },
  updateItem(item) {
    return this.helper.updateItem(item);
  },

  setDetailsByClient(details, clientData) {
    this.helper.setDetailsByClient(details, clientData);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data, this.DiscType);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  getPaymentOptions(paymentList, optionAllText) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText);
  },

  populateItemsWithSpecialPrice(normalItem, specialPriceItem, userData) {
    return this.helper.populateItemsWithSpecialPrice(normalItem, specialPriceItem, userData);
  },

  isNotvalidSpecialQty(items) {
    return this.helper.isNotvalidSpecialQty(items);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },

  helper : {
    createDetails(discEnum) {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        WarehouseID: null,
        ClientID: null,
        DiscType: discEnum.None.ID,
        DiscValue: null,
        DiscPercent: null,
        Comment: null,
        PaymentType: null,
        PayValue: 0,
        PayReturn: 0,
        // (non) user input
        SalesDraftID: null,
        SONumber: '',
        PONumber: '',
        Client: "POS",
        ClientAddress: null,
        IsNeedDelivery: false,
        DeliveryDate: null,
        InvoiceDate: App.In.getDateToday(),
        // defined by system
        WarehouseName: '',
        ClientAlias: null,
        DraftNumber: '',
        DraftDate: null,
        TotalQty: 0,
        TotalBruto: 0,
        TotalDisc: 0,
        Total: 0,
        InvoiceList: null,
        InvoiceCount: 0,
        CreatedByID: App.Session.getUserID(),
      }
    },
    createItem() {
      return {
        // user input
        DispatchID: null,
        RequestedQuantity: 0,
        SellPrice: "0",
        UseSpvPassword: false,
        // (non) user input
        SalesDraftItemID: null,
        SalesDraftItemQty: null,
        PackagingName: null,
        Name: null,
        // defined by system
        PackagingValue: 1,
        TotalRequestedQuantity: 0,
        Price: 0,
        SpecialPrice: 0,
        SellPriceNetto: 0,
        QuantityLeft: 0,
        Stock: 0,
        SKU: "",
        Barcode: "",
        DispatchFullName: "",
        DispatchSellPrice: 0,
        DispatchIsActive: true,
        Packaging: "",
        PackagingOptions: null,
        DeliveryQuantity: 0
      }
    },
    createDiscTypeOptions(optionAllText, discTypeEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
        { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
        { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
  
    clearDetailsByClient(details) {
      details.ClientID = null;
      details.Client = "POS";
      details.ClientAlias = "";
      details.ClientAddress = "";
    },

    setItemByData(item, itemData) {
      item.DispatchID = itemData.DispatchID;
      item.OriginDispatchID = itemData.OriginDispatchID;
      item.Name = itemData.FullName;
      item.PackagingName = itemData.PackagingName;
      item.SKU = itemData.SKU;
      item.SellPrice = itemData.SellPrice;
      item.SpecialPrice = itemData.SpecialPrice;
      item.SellPriceNetto = itemData.SpecialPrice || itemData.SellPrice;
      item.Barcode = itemData.Barcode;
      item.SpecialPriceID = itemData.SpecialPriceID || null;
      item.SpecialPriceName = itemData.SpecialPriceName || null;
      item.SpecialPriceDiscPercent = itemData.SpecialPriceDiscPercent || null;
      if(itemData.MaxQuantity) {
        item.MaxQuantity = parseInt(itemData.MaxQuantity)
      }
    },

    updateDetails(data, items, discTypeEnum) {
      let total = 0, totalQty = 0;

      // total without disc
      for (let item of items) {
        total += item.Price;
        totalQty += parseInt(item.RequestedQuantity);
      }

      data.TotalBruto = total;
      data.TotalQty = totalQty;

      // TotalDisc
      if (data.DiscType === discTypeEnum.Value.ID) {
        data.TotalDisc = App.JS.parseInt(data.DiscValue);
        total -= data.TotalDisc;
      }
      else if (data.DiscType === discTypeEnum.Percent.ID) {
        data.TotalDisc = App.Data.getDiscPercent_Value(total, data.DiscPercent);
        total -= data.TotalDisc;
      }

      data.Total = total;

      if (data.PayValue !== 0) { 
        data.PayReturn = data.PayValue - data.Total;
      }
    },

    updateItem(item) {
      item.SellPriceNetto = item.SpecialPrice || item.SellPrice;
      item.TotalRequestedQuantity = App.Data.getTotalQty(item.RequestedQuantity, item.PackagingValue);
      item.Price = App.Data.getTotal(item.RequestedQuantity, item.PackagingValue, (item.SpecialPrice || item.SellPrice));
    },

    setDetailsByClient(details, clientData) {
      // user input
      details.ClientID = clientData.ClientID;
      details.Client = App.In.getString(clientData.ClientName);
      details.ClientAddress = App.In.getString(clientData.Address);
      // defined by system
      details.ClientAlias = clientData.Alias;
    },

    populateDetails(data, discTypeEnum) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        ClientID: data.ClientID,
        DiscValue: (data.DiscType === discTypeEnum.Value.ID ? parseInt(data.DiscValue) : null),
        DiscPercent: (data.DiscType === discTypeEnum.Percent.ID ? parseFloat(data.DiscPercent) : null),
        Comment: App.Out.getString(data.Comment),
        PaymentType: data.PaymentType,
        PayValue: parseInt(data.PayValue),
        SalesDraftID: data.SalesDraftID,
        SONumber: App.Out.getString(data.SONumber),
        PONumber: App.Out.getString(data.PONumber),
        Client: App.Out.getString(data.Client),
        ClientAddress: App.Out.getString(data.ClientAddress),
        IsNeedDelivery: App.Out.getBoolean(data.IsNeedDelivery),
        DeliveryDate: (data.IsNeedDelivery ? data.DeliveryDate : null),
        InvoiceDate: App.Out.getDate(data.InvoiceDate),
        ClientAlias: App.Out.getString(data.ClientAlias),
        CreatedByID: data.CreatedByID
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          DispatchID: item.OriginDispatchID || item.DispatchID,
          RequestedQuantity: App.Out.getInteger(item.RequestedQuantity),
          SellPrice: App.Out.getInteger(item.SellPrice),
          SalesDraftItemID: item.SalesDraftItemID,
          PackagingName: item.PackagingName,
          Name: App.Out.getString(item.Name),
          PackagingValue: item.PackagingValue,
          TotalRequestedQuantity: App.Out.getInteger(item.TotalRequestedQuantity),
          Price: App.Out.getInteger(item.Price),
          SpecialPriceID: item.SpecialPriceID,
          SpecialPriceName: item.SpecialPriceName,
          SpecialPriceDiscPercent: item.SpecialPriceDiscPercent,
          SellPriceNetto: item.SellPriceNetto,
          DispatchSellPrice: item.DispatchSellPrice,
          UseSpvPassword: item.UseSpvPassword
        });
      }

      return resultItems;
    },
    getDiscountedValue(price, discPercent) {
      return App.Data.getDiscPercent_Value(price, discPercent);
    },
    /*
    getDiscountedValue(price, margin) {
      if(price && margin) {
        return Math.round(price * (margin / 100));
      }
      return 0;
    },
    */
    populateItemsWithSpecialPrice(normalItems, specialPriceItem, userData) {
      let items = [];
      let specialItem = {};
      const warehouse = userData.WarehouseID;
      for(let item of normalItems) {
        items.push(item);
        const specialPriceItems = specialPriceItem.filter(row => {
          return row.DispatchID == item.DispatchID && row.WarehouseID == warehouse
        }) || [];
        for(let specialPrice of specialPriceItems) {
          specialItem = Object.assign({}, item);
          const SellPrice                 = specialPrice.SellPrice;
          const DiscPercent               = specialPrice.DiscPercent;
          const TotalDisc                 = this.getDiscountedValue(SellPrice, DiscPercent);
          const DiscountedValue           = SellPrice - TotalDisc;
          const MaxQuantity               = parseInt(specialPrice.Quantity);
          specialItem.DispatchID          = item.DispatchID + "_" + specialPrice.SpecialPriceID;
          specialItem.OriginDispatchID    = item.DispatchID;
          specialItem.SpecialPrice        = DiscountedValue;
          specialItem.SellPrice           = item.SellPrice;
          specialItem.MaxQuantity         = MaxQuantity;
          specialItem.SpecialPriceName    = specialPrice.SpecialPriceName;
          specialItem.SpecialPriceID      = specialPrice.SpecialPriceID;
          specialItem.SpecialPriceDiscPercent  = specialPrice.DiscPercent;
          items.push(specialItem);
        }
      }
      return items;
    },
    isNotvalidSpecialQty(items) {
      const filter = items.filter(item => {
        return item.MaxQuantity !== undefined
      })
      if(filter) {
        const find = filter.find(item => {
          return parseInt(item.RequestedQuantity) > parseInt(item.MaxQuantity);
        })
        if(find) {
          return "Stok " + find.Name +  " tidak mencukupi " + "(hanya tersedia " + (parseInt(find.MaxQuantity)) + " " + find.PackagingName + ")";
        }
      }
      return false;
    },

    getItemInfo(item) {
      let infoList = [];
      infoList.push(ItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, ItemCatalog)
      );
      infoList.push(ItemCatalog.Barcode.Label + ": " +
        App.Value.getValue("Barcode", item, ItemCatalog)
      );
      return infoList;
    },
  }
}