var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"field":"ItemID","label":_vm.HppAdjItemCatalog.DispatchName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.HppAdjItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty-print","field":"Qty","label":_vm.HppAdjItemCatalog.Qty.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"multi-value":"","field":column.field,"data":row,"catalog":_vm.HppAdjItemCatalog}},[_c('kst-value',{attrs:{"multi-value":"","field":"PackagingName","data":row,"catalog":_vm.HppAdjItemCatalog}})],1)]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-hpptotal","field":"HppTotal","label":_vm.HppAdjItemCatalog.HppTotal.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.HppAdjItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }