<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="InvoiceDate"
      :label="SalesRecapPrintCatalog.InvoiceDate.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="SalesRecapPrintCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row}"
      field="SONumber"
      :label="SalesRecapPrintCatalog.SONumber.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="SalesRecapPrintCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row}"
      field="ClientID"
      :label="SalesRecapPrintCatalog.ClientID.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="SalesRecapPrintCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="PaymentTypeID"
      :label="SalesRecapPrintCatalog.PaymentTypeID.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="SalesRecapPrintCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="SalesRecapPrintCatalog.Total.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="SalesRecapPrintCatalog"
      />
    </b-table-column>
  </kst-print-table>
</template>

<script>
import SalesRecapPrintCatalog from "./SalesRecapPrintDetailsCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    SalesRecapPrintCatalog: SalesRecapPrintCatalog
  })
}
</script>