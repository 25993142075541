<template>
  <kst-section :title="sectionTitle" class="mt-5">
    <kst-table :data="items">
      <b-table-column
        v-slot="{ row }"
        field="Variants"
        :label="DispatchCatalog.Variants.Label"
      >
        <div class="k-control-py">{{ getVariants(row) }}</div>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-image"
        field="ImageSrc"
        :label="DispatchCatalog.ImageSrc.Label"
      >
        <kst-image read-only thumbnail :src="row[column.field]"/>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-item-details-dispatch-FullName"
        field="FullName"
        :label="DispatchCatalog.FullName.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DispatchCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-item-details-dispatch-SKU"
        field="SKU"
        :label="DispatchCatalog.SKU.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DispatchCatalog"
        />
      </b-table-column>

            <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-item-details-dispatch-Barcode"
        field="Barcode"
        :label="DispatchCatalog.Barcode.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DispatchCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-item-details-dispatch-SellPrice"
        field="SellPrice"
        :label="DispatchCatalog.SellPrice.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DispatchCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-item-details-dispatch-Weight"
        field="Weight"
        :label="DispatchCatalog.Weight.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DispatchCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-item-details-dispatch-Description"
        field="Description"
        :label="DispatchCatalog.Description.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DispatchCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import DispatchCatalog from "../DispatchCatalog.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    DispatchCatalog: DispatchCatalog,
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Dispatch.Title;
    }
  },
  methods: {
    getVariants(row) {
      return Model.getDispatchVariants(row);
    }
  }
}
</script>