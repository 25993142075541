<template>
  <kst-section items-mode class="mt-5">
    <InvoiceItemSearch
      ref="mdlInvoiceItemSearch"
      :items="invoiceItems" 
      @submit="handleInvoiceItem_Submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Info.Button.InvoiceItem.Label"
        :moduleType="Model.ModuleType.New"
        @click="handleAdd_InvoiceItem"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0" >
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Info.Button.InvoiceItem.Label"
          :moduleType="Model.ModuleType.New"
          @click="handleAdd_InvoiceItem"
        />
      </template>
    </template>

    <kst-warning class="mb-4" :items="itemsWarning"/>

    <List :userData="userData" :userItems="userItems"/>
  </kst-section>
</template>

<script>
import Info from "../RtnInvoiceInfo.js";
import Model from "../RtnInvoiceModel.js";

import InvoiceItemSearch from "@/components/Invoice/ItemSearch/InvoiceItemSearch";
import List from "./RtnInvoiceEdit_Items_List";

export default {
  components: {
    InvoiceItemSearch,
    List
  },
  props: {
    invoiceData: Object,
    invoiceItemsGrouped: Array,
    userData: Object,
    userItems: Array,
  },
  data: () => ({
    Info: Info,
    Model: Model,
    invoiceItems: []
  }),
  computed: {
    itemsWarning() {
      return [
        Info.Message.Edit_InvoiceItem,
        Info.Message.Edit_DeletedItem
      ];
    }
  },
  methods: {
    handleInvoiceItem_Submit(row) {
      let item = Model.createItem();
      Model.setItemByInvoiceItem(item, row);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleAdd_InvoiceItem() {
      this.invoiceItems = Model.getInvoiceItems_ForSelection(this.userItems, this.invoiceItemsGrouped);
      this.$refs.mdlInvoiceItemSearch.show();
    }
  }
}
</script>