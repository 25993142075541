import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  Total: {
    Label: "Total",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.ActiveInactive
  },
  TotalActive: {
    Label: "Total Aktif",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalNotActive: {
    Label: "Total Tidak Aktif",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  GrandTotal: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  }
}