<template>
  <kst-action>
    <kst-action-item>
      <kst-button action-mode
        :module="Model.Module"
        :moduleType="Model.ModuleType.Details"
        :urlParams="urlParams"
      />
    </kst-action-item>
    <kst-action-item>
      <kst-button action-mode
        :module="Model.Module"
        :moduleType="Model.ModuleType.Edit"
        :urlParams="urlParams"
      />
    </kst-action-item>
    <kst-action-item>
      <kst-button action-mode
        :module="Model.Module"
        :moduleType="Model.ModuleType.Print"
        :urlParams="urlParams"
      />
    </kst-action-item>
    <kst-action-item>
      <kst-button action-mode
        :module="Model.Module"
        :moduleType="Model.ModuleType.ExportDetails"
        :urlParams="urlParams"
      />
    </kst-action-item>
  </kst-action>
</template>

<script>
import Model from "../PurchaseModel.js";

export default {
  props: {
    row: Object
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: this.row.ID
      });
    }
  }
}
</script>