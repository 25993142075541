import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import InvoiceSettingEdit from "./Edit/InvoiceSettingEdit";
import InvoiceSetting from "./Setting/InvoiceSetting";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.InvoiceSettings),
    Edit: RouterModel.getPath(AppModule.InvoiceSettings, AppModuleType.Edit),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.InvoiceSettings),
        name: RouterModel.getName(AppModule.InvoiceSettings),
        component: InvoiceSetting,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.InvoiceSettings),
          moduleName: RouterModel.getModule(AppModule.InvoiceSettings),
        }
      },
      {
        path: RouterModel.getPath(AppModule.InvoiceSettings, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.InvoiceSettings, AppModuleType.Edit),
        component: InvoiceSettingEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.InvoiceSettings, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.InvoiceSettings),
        }
      },
    ];
  },

  getRootRoutes() {
    return [];
  }
}