import App from "@/services/App/App.js";
import Model from "./ReceiveModel.js";
import AppModule from "@/services/App/AppModule.js";
import Catalog from "./ReceiveCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
        },
        Section: {
          ReceiveItem: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          ReceiveItem: AppModuleType.List.Name + " " + Model.ReceiveItem.Module.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name
        }
      }
    }
  },
  Message: {
    Edit_ConfirmDeleteItem:
      "Yakin ingin menghapus " + AppModule.Item.FullName + "?",
    Edit_Details:
      "Tidak diperkenankan mengubah " + Catalog.VendorID.Label + ", " +
      "saat sudah memilih " + Catalog.PONumber.Label + ".",
    Edit_EditVendor:
      "Tidak diperkenankan mengubah " + AppModule.Vendor.FullName + ", " +
      "saat sudah memiliki " + AppModule.Invoice.FullName + ".",
    Edit_DeleteItem:
      "Tidak diperkenankan menghapus " + AppModule.Item.FullName + ", " +
      "bila sudah terpakai di " + AppModule.Invoice.FullName + ".",
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ".",
    New_StatusClosed:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin ingin menghapus " + AppModule.Item.FullName + "?",
    New_Details:
      "Anda dapat mengabaikan atau mengosongkan PO",
    New_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.Module.FullName + "."
  },
  PriceSearch: {
    BuyPrice: {
      High: {
        Icon: "mdi mdi-arrow-up-bold",
        IconClass: "has-text-danger",
        TooltipClass: "is-danger",
        TooltipText: "Harga Beli lebih mahal dari normal"
      },
      Low: {
        Icon: "mdi mdi-arrow-down-bold",
        IconClass: "has-text-success",
        TooltipClass: "is-success",
        TooltipText: "Harga Beli lebih murah dari normal"
      }
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  },
  Section: {
    PO: {
      Title: "Info Pembelian Stok (PO)",
      AddItem: {
        Title: "Daftar Barang Pembelian Stok (PO)"
      }
    },
    InfoPO: {
      Title: "Info Penerimaan"
    }
  },
  Tooltip: {
    Active: "Barang dari Pemesanan Pembelian",
    InActive: "Barang Bukan dari Pemesanan Pembelian",
  },
  Scanner: {
    Title: "Barcode Scanner"
  }
}