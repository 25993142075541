import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama Barang",
    Type: InputType.Text,
    ReadOnly: true,
  },
  RequestedQuantity: {
    Label: "Qty Terjual",
    Type: InputType.Qty
  },
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    Required: true
  },
}