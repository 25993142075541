<template>
  <kst-modal
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <ValidationObserver ref="formDetails">
      <kst-input floating-label load-on-focus
        field="Value"
        :custom-validations="customValidations"
        :data="userVariantValue"
        :catalog="VariantValueCatalog"
      />
    </ValidationObserver>
  </kst-modal>
</template>

<script>
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel";
import VariantValueCatalog from "../VariantValueCatalog.js";

export default {
  mixins: [SnackbarMixin],
  props: {
    existingVariantValues: Array
  },
  data: () => ({
    VariantValueCatalog: VariantValueCatalog,
    userVariantValue: {},
    items: [],
    active: false
  }),
  computed: {
    customValidations() {
      return {
        unique: this.existingVariantValues
      };
    },
    title() {
      return Info.Modal.VariantValue.Title;
    }
  },
  methods: {
    init() {
      this.userVariantValue = Model.createVariantValue();
    },

    show() {
      this.init();
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      this.addVariantValue();
    },

    addVariantValue() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.addVariantValue_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    addVariantValue_Success() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userVariantValue);
    }
  }
}
</script>