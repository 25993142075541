var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{staticClass:"mt-5",attrs:{"title":_vm.sectionTitle}},[_c('kst-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"cell-class":"kst-vendor-details-Name","field":"Name","label":_vm.VendorAddressCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-vendor-details-Address","field":"Address","label":_vm.VendorAddressCatalog.Address.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-vendor-details-ContactName","field":"ContactName","label":_vm.VendorAddressCatalog.ContactName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-vendor-details-ContactMobile","field":"ContactMobile","label":_vm.VendorAddressCatalog.ContactMobile.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-vendor-details-ContactPhone","field":"ContactPhone","label":_vm.VendorAddressCatalog.ContactPhone.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-vendor-details-ContactEmail","field":"ContactEmail","label":_vm.VendorAddressCatalog.ContactEmail.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }