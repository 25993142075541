import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.sales;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "sales", "warehouse", "status", "field",
      "startDate", "endDate"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesdraft/list" + urlParams
    };
  },
  getDetails(id, params) {
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["withpackagings", "withstock", "withinvoice", "withinvoicecount"];
    const urlParams = App.Out.getParams(options, attributes);

    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesdraft/" + cid + "/" + id + urlParams
    };
  },
  postSales(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/salesdraft",
      data: data
    };
  },
  putSales(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/salesdraft",
      data: data
    };
  }
}