import InputType from "@/services/Input/InputType.js";
import DispatchCatalog from "../DispatchCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      DispatchCatalog.FullName.Label + ", atau " +
      DispatchCatalog.SKU.Label,
    Type: InputType.Text,
    MaxLength: 50
  }
}