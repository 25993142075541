var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"paginated":"","view-mode":"","data":_vm.items,"failure":_vm.failureList,"loading":_vm.isListLoading,"selected":_vm.userData.selected},on:{"update:selected":function($event){return _vm.$set(_vm.userData, "selected", $event)}}},[_c('b-table-column',{attrs:{"field":"ClientName","label":_vm.Catalog.ClientName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-alias","field":"Alias","label":_vm.Catalog.Alias.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Address","label":_vm.ClientAddressCatalog.Address.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ContactMobile","label":_vm.ClientAddressCatalog.ContactMobile.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ContactPhone","label":_vm.ClientAddressCatalog.ContactPhone.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ContactEmail","label":_vm.ClientAddressCatalog.ContactEmail.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ClientAddressCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }