<template>
  <kst-table action input-mode
    :data="userItems"
    :failure="failureList"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-output field-only box-off
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-qty kst-pos-result-item-qty"
      field="RequestedQuantity"
      :label="Catalog.RequestedQuantity.Label"
    >
      <kst-input field-only @input="handleQty(row)" 
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="PackagingName"
      :label="Catalog.PackagingName.Label"
    >
      <kst-output field-only box-off
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>
    
    <b-table-column numeric
      v-slot="{ column, row }"
      field="SellPrice"
      :label="Catalog.SellPrice.Label"
    >
      <template v-if="row.SpecialPrice > 0">
        <div class="has-text-danger">
          <kst-output field-only box-off field="SpecialPrice" :data="row" :catalog="Catalog" />
        </div>
        <div class="has-text-grey ks-text-line-through">
          <kst-value class="is-size-7" field="SellPrice" :data="row" :catalog="Catalog" />
        </div>
      </template>
      <template v-else>
        <kst-output field-only box-off :field="column.field" :data="row" :catalog="Catalog" />
      </template>
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ index }">
        <kst-tooltip edit-mode hover-only :content="Catalog.SellPrice.Label">
          <kst-button edit-mode is-round @click="handleEdit_SellPrice(index)"/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="Price"
      :label="Catalog.Price.Label"
    >
      <kst-output field-only box-off
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.Item.Module.FullName">
        <kst-button delete-mode is-round
          @click="handleRowDelete(index)"
        />
      </kst-tooltip>
    </template>

  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Catalog from "./PosItemCatalog.js";
import Info from "./PosInfo.js";
import Model from "./PosModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userItems: Array,
    failureList: String,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    selectedIndex: null,
  }),
  methods: {
    handleQty(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleEdit_SellPrice(index) {
      this.$emit(this.$kst.Enum.Event.Edit, index);
    },

    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userItems.splice(this.selectedIndex, 1);
      Model.updateDetails(this.userData, this.userItems);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    }
  }
}
</script>