<template>
  <kst-print class="kst-invoice-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData"/>
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column">
          <SalesRecap :data="data"/>
        </div>
        <div class="column">
          <Cashier :data="data"/>
        </div>
      </div>
    </template>
    <Invoices class="mt-4" :items="InvoiceList"/>
    <Total class="mt-4" :data="data" :items="data" />
  </kst-print>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../SalesRecapInfo.js";
import Model from "../SalesRecapModel.js";
import Services from "../SalesRecapServices.js";

import Company from "./SalesRecapPrintDetails_Company";
import SalesRecap from "./SalesRecapPrintDetails_SalesRecap";
import Cashier from "./SalesRecapPrintDetails_Cashier";
import Invoices from "./SalesRecapPrintDetails_Invoices";
import Total from "./SalesRecapPrintDetails_Total";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    SalesRecap,
    Cashier,
    Invoices,
    Total
  },
  data: () => ({
    data: {},
    companyData: {},
    InvoiceList: [],
    isLoading: false,
    // error
    error: null
  }),
  computed: {
    timestamps() {
      return "Dicetak pada: " + this.$kst.View.getTimestamp();
    },
    title() {
      return Model.Module.FullName;
    }
  },
  created(){
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.error = null;

      if (!id) {
        this.error = Info.Message.PrintDetails_ParamIncomplete;
        return;
      }

      this.data = {};
      this.companyData = {};
      this.InvoiceList = [];

      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.error = null;

      this.loadDetails(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails(id) {
      const config = Services.getSalesRecapDetails(id, { withinvoice: 1 });
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error, this.loadDetails_Invalid);
    },
    loadDetails_Success(data) {
      Model.setDetails(data)
      this.data = data;
      this.InvoiceList = data.InvoiceList;
      // Model.setInvoiceList(this.InvoiceList, data.InvoiceList);
      Model.updateDetails(this.data, this.InvoiceList);
      this.loadCompanyDetails();
    },
    loadDetails_Error(message) {
      this.error = message;
      this.initLoad_End();
    },
    loadDetails_Invalid(message) {
      this.error = message;
      this.initLoad_End();
    },

    loadCompanyDetails() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompanyDetails_Success, this.loadCompanyDetails_Error, this.loadCompanyDetails_Invalid);
    },
    loadCompanyDetails_Success(data) {
      this.companyData = data.data;
      this.initLoad_End();
    },
    loadCompanyDetails_Error(message) {
      this.errors = message;
      this.initLoad_End();
    },
    loadCompanyDetails_Invalid(message) {
      this.errors = message;
      this.initLoad_End();
    },
  }
}
</script>