import AppArray from "./App_Array.js";
import AppBuefy from "./App_Buefy.js";
import AppConverter from "./App_Converter.js";
import AppData from "./App_Data.js";
import AppEnum from "./App_Enum.js";
import AppFormatter from "./App_Formatter.js";
import AppInput from "./App_Input.js";
import AppJavaScript from "./App_JavaScript.js";
import AppKey from "./App_Key.js";
import AppOutput from "./App_Output.js";
import AppPivot from "./App_Pivot.js";
import AppRoute from "./App_Route.js";
import AppSearch from "./App_Search.js";
import AppSession from "./App_Session.js";
import AppType from "./App_Type.js";
import AppValue from "./App_Value.js";
import AppValidation from "./App_Validation.js";
import AppVeeValidate from "./App_VeeValidate.js";
import AppView from "./App_View.js";
import AppXlsx from "./App_Xlsx.js";

/**
 * WARNING !!!
 * Please kindly DO NOT change key (name) or structure.
 * - Add DEPRECATED note for unused function.
 * - Create new key for new/updated function, when:
 *   * have different parameters:
 *     > number of parameters
 *     > data-type of parameter
 *     > data-type of result
 *   * have different calculation / data processing method
 * 
 * When you change active function, you may break others !!
 * ~ Thank you ~
 */

export default {
  Array: {
    searchItem: AppArray.searchItem, // (items, key, value, options)
    searchValue: AppArray.searchValue, // (items, itemKey, value, valueKey)
    truncate: AppArray.truncate // (items)
  },

  Buefy: {
    Position: AppBuefy.Position,
    Table: AppBuefy.Table,
    Type: AppBuefy.Type
  },

  Convert: {
    arrayToString: AppConverter.Array.toString // (value, separator)
  },

  Data: {
    getDiscPercent_Value: AppData.getDiscPercent_Value, // (value, percent)
    getPackaging: AppData.getPackaging, // (packagingName, packagingValue)
    getStatusClass: AppData.getStatusClass, // (value, statusEnum, statusClassEnum)
    getStatusLabel: AppData.getStatusLabel, // (value, statusEnum, statusLabelEnum)
    getQtyByPackaging: AppData.getQtyByPackaging, // (qty, packagingValue)
    getTotal: AppData.getTotal, // (qty, packagingValue, price)
    getTotalQty: AppData.getTotalQty, // (qty, packagingValue)
    getTotalWeight: AppData.getTotalWeight, // (qty, packagingValue, weight)
    isTrue: AppData.isTrue, // (value)
    getActivityType : AppData.getActivityType // (ActivityTypeID)
  },

  Enum: {
    ActivityType: AppEnum.ActivityType,
    Axios: AppEnum.Axios,
    Breakline: AppEnum.Breakline,
    Callback: AppEnum.Callback,
    Event: AppEnum.Event,
    Param: AppEnum.Param,
    Status: AppEnum.Status,
    Type: AppEnum.Type
  },

  Format: {
    // boolean
    getActiveInactive: AppFormatter.getActiveInactive, // (value)
    getAutoManual: AppFormatter.getAutoManual, // (value)
    getBoolean: AppFormatter.getBoolean, // (value)
    getOpenClosed: AppFormatter.getOpenClosed, // (value)
    getYesNo: AppFormatter.getYesNo, // (value)
    // date
    getShortDate: AppFormatter.getShortDate, // (value)
    getRangeShortDate: AppFormatter.getRangeShortDate, // (value)
    getMediumDate: AppFormatter.getMediumDate, // (value)
    getLongDate: AppFormatter.getLongDate, // (value)
    getLongMonthly: AppFormatter.getLongMonthly, // (value)
    getShortDateTime: AppFormatter.getShortDateTime, // (value)
    getMediumDateTime: AppFormatter.getMediumDateTime, // (value)
    getLongDateTime: AppFormatter.getLongDateTime, // (value)
    // number
    getAccounting: AppFormatter.getAccounting, // (value)
    getAccounting2D: AppFormatter.getAccounting2D, // (value)
    getCurrency: AppFormatter.getCurrency, // (value)
    getDecimal: AppFormatter.getDecimal, // (value)
    getDecimal2D: AppFormatter.getDecimal2D, // (value)
    getInteger: AppFormatter.getInteger, // (value)
    getPercent: AppFormatter.getPercent, // (value),
    // url
    getExternalUrl: AppFormatter.getExternalUrl // (value)
  },

  In: {
    getDate: AppInput.getDate, // (value)
    getDateFirstDay: AppInput.getDateFirstDay, // ()
    getDateFirstDayLastMonth: AppInput.getDateFirstDayLastMonth, // ()
    getDateLast30Day: AppInput.getDateLast30Day, // ()
    getDateLastDay: AppInput.getDateLastDay, // ()
    getDateLastDayLastMonth: AppInput.getDateLastDayLastMonth, // ()
    getDateLastWeek: AppInput.getDateLastWeek, // ()
    getDateToday: AppInput.getDateToday, // ()
    getDateYesterday: AppInput.getDateYesterday, // ()

    getDefaultDateRange: AppInput.getDefaultDateRange, // ()
    getLastMonthDay: AppInput.getLastMonthDay, // ()
    getThreeMonthDay: AppInput.getThreeMonthDay, // ()

    getBoolean: AppInput.getBoolean, // (value)
    getDecimal: AppInput.getDecimal, // (value)
    getInteger: AppInput.getInteger, // (value)
    getSelectOptions: AppInput.getSelectOptions, // (valueKey, labelKey, items)
    getString: AppInput.getString // (value)
  },

  JS: {
    parseInt: AppJavaScript.parseInt, // (value, defaultValue)
    parseFloat: AppJavaScript.parseFloat // (value, defaultValue)
  },

  Key: {
    KeyProp: AppKey.KeyProp,
    KeyEvent: AppKey.KeyEvent,
    Key: AppKey.Key
  },

  Out: {
    getBoolean: AppOutput.getBoolean, // (value)
    getDate: AppOutput.getDate, // (value)
    getDecimal: AppOutput.getDecimal, // (value)
    getInteger: AppOutput.getInteger, // (value)
    getString: AppOutput.getString, // (value),
    getParams: AppOutput.getParams // (options, paramNameList)
  },

  Pivot: {
    Type: AppPivot.Type,
    create: AppPivot.create // (items, fieldKeys, fieldValues)
  },

  Route: {
    getParams: AppRoute.getParams // (params, withoutQueryMark)
  },

  Search: {
    OptionAll: AppSearch.OptionAll,
    OptionNone: AppSearch.OptionNone,
    LabelAll: AppSearch.LabelAll,
    LabelNone: AppSearch.LabelNone,

    createList: AppSearch.createList, // (items, options)
    getValue: AppSearch.getValue // (value, defaultValue, OPTION_ALL)
  },

  Type: {
    isBoolean: AppType.isBoolean, // (value)
    isDate: AppType.isDate, // (value)
    isDecimal: AppType.isDecimal, // (value)
    isInteger: AppType.isInteger, // (value)
    isNumber: AppType.isNumber, // (value)
    isString: AppType.isString // (value)
  },

  Session: {
    getCompanyID: AppSession.getCompanyID, // ()
    getCompanyName: AppSession.getCompanyName, // ()
    getCompanyImageSrc: AppSession.getCompanyImageSrc, // ()
    getName: AppSession.getName, // ()
    getUserID: AppSession.getUserID, // ()
    getAccessID: AppSession.getAccessID, // ()
    getDefaultMenu: AppSession.getDefaultMenu, // ()
    getMenu: AppSession.getMenu, // ()
    getToken: AppSession.getToken, // ()
    setCompanyID: AppSession.setCompanyID, // (value)
    setCompanyName: AppSession.setCompanyName, // (value)
    setCompanyImageSrc: AppSession.setCompanyImageSrc, // (value)
    setName: AppSession.setName, // (value)
    setUserID: AppSession.setUserID, // (value)
    setAccessID: AppSession.setAccessID, // (value)
    setDefaultMenu: AppSession.setDefaultMenu, // (value)
    setMenu: AppSession.setMenu, // (value)
    clear: AppSession.clear // ()
  },

  Value: {
    getType: AppValue.getType, // (field, catalog)
    getValue: AppValue.getValue, // (field, data, catalog)
    isUseNullText: AppValue.isUseNullText // (field, data, catalog)
  },

  Validate: {
    numeric: AppValidation.numeric // (value, fieldName, options, index)
  },

  Vee: {
    ValidRule: AppVeeValidate.ValidRule,
    getFieldTarget: AppVeeValidate.getFieldTarget, // (field, rule)
    getRowErrors: AppVeeValidate.getRowErrors, // (item, field, errors)
    getValidatorId: AppVeeValidate.getValidatorId // (field, index, group)
  },

  View: {
    getTimestamp: AppView.getTimestamp, // ()
    getVersion: AppView.getVersion // ()
  },

  Xlsx: {
    getFileName: AppXlsx.getFileName, // (module, moduleType)
    getStatus: AppXlsx.getStatus, // (value, statusEnum)
    getValue: AppXlsx.getValue // (field, data, catalog)
  }
}