var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"field":"DispatchID","label":_vm.InvoiceItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"RequestedQuantity","label":_vm.InvoiceItemCatalog.RequestedQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"multi-value":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}},[_c('kst-value',{attrs:{"multi-value":"","field":"PackagingName","data":row,"catalog":_vm.InvoiceItemCatalog}})],1)]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPrice","label":_vm.InvoiceItemCatalog.SellPrice.PrintLabel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),(_vm.data.SpecialPriceCount > 0)?[_c('b-table-column',{attrs:{"cell-class":"ks-col-price","field":"SpecialPriceName","label":_vm.InvoiceItemCatalog.SpecialPriceName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}],null,false,2565481817)}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"DiscountedValue","label":_vm.InvoiceItemCatalog.DiscountedValue.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}],null,false,2565481817)})]:_vm._e(),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Price","label":_vm.InvoiceItemCatalog.Price.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }