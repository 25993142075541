<template>
  <kst-table action paginated view-mode
    :data="items"
    :loading="isListLoading"
    :failure="failureList"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="DepartureDate"
      :label="Catalog.DepartureDate.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="LogisticsNumber"
      :label="Catalog.LogisticsNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

     <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="TotalWeight"
      :label="Catalog.TotalWeight.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

     <b-table-column sortable
      v-slot="{ column, row }"
      field="Destination"
      :label="Catalog.Destination.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VehicleIDNumber"
      :label="Catalog.VehicleIDNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-status :value="row.Status" :statusEnum="Model.Status"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :row="row"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DeliveryCatalog.js";
import Model from "../DeliveryModel.js";

import Action from "./DeliveryList_List_Action";

export default {
  components: {
    Action
  },
  props: {
    items: Array,
    isListLoading: Boolean,
    failureList: String
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>