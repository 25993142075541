import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: "Date",
    Type: InputType.Date,
    ReadOnly: true,
    isRange: false,
    Output: OutputType.Date.ShortDate
  },
  FormattedDate: {
    Label: "Tanggal/Bulan",
    Type: InputType.Text,
    ReadOnly: true
  },
  Month: {
    Label: "Bulan",
    Type: InputType.Text,
    Output: OutputType.Date.LongMonthly,
    MaxLength: 20
  }
}