import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: InputType.Text,
    MaxLength: 12,
    Required: true,
    Highlight: true
  },
  DiscPercent: {
    Label: "Diskon (%)",
    Type: InputType.Percent,
    MinValue: 0,
    MaxValue: 100,
    Required: true
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.ActiveInactive
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "CreatedByName",
    RelatedModule: AppModule.User
  },
  CreatedByName: {
    Label: AppCatalog.Field.Name.Label + " " + AppCatalog.Field.CreatedByID.Label,
    Type: InputType.Text,
    ReadOnly: true,
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: InputType.TextArea,
    MaxLength: 100
  }
}