import { UserManager, WebStorageStateStore } from "oidc-client";
import apiConfig from "../ApiURL.json";
import config from "./config.js";
import CallbackRouter from "@/components/Callback/CallbackRouter.js";

var URL = process.env.VUE_APP_BASE_URL || apiConfig.urlredirect;
var AUTH =  process.env.VUE_APP_AUTH_URL || config.domain;

var mgr = new UserManager({
  authority: AUTH,
  client_id: process.env.VUE_APP_CLIENT_ID || config.id,
  client_secret: process.env.VUE_APP_CLIENT_SECRET || config.secret,
  redirect_uri: URL + CallbackRouter.Path.Default,
  response_type: "code",
  scope: config.audience,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export default mgr;