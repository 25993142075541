<template>
  <div>
    <kst-input field="CashReceived" :data="userData" :catalog="Catalog" />

    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SalesRecapCatalog.js";

export default {
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
  }),
}
</script>