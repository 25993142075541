import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./RtnInvoiceCatalog.js"
import Model from "./RtnInvoiceModel.js";
import RtnInvoiceItemCatalog from "./RtnInvoiceItemCatalog.js";

export default {
  Button: {
    InvoiceItem: {
      Label: AppModule.Item.FullName + " " + Model.Invoice.Module.FullName
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ".",
    Edit_DeletedItem:
      "Tidak diperkenankan menggunakan " + AppCatalog.Info.DeletedItem + ".",
    Edit_InvoiceFields:
      "Tidak diperkenankan mengubah " +
      Catalog.SalesOrderID.Label + ", " +
      Catalog.InvoiceDate.Label + ", " +
      Catalog.ClientID.Label + " dan " +
      Catalog.ClientAddress.Label + ".",
    Edit_InvoiceItem:
      "Tidak diperkenankan mengubah " + RtnInvoiceItemCatalog.DispatchName.Label + ", " +
      RtnInvoiceItemCatalog.PackagingName.Label + " dan " +
      RtnInvoiceItemCatalog.SellPrice.Label + ".",
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.Item.Module.FullName + "?",
    New_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ".",
    New_DeletedItem:
      "Tidak diperkenankan menggunakan " + AppCatalog.Info.DeletedItem + ".",
    New_Invoice:
      "Memilih / menghapus " + Model.Invoice.Module.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + ".",
    New_InvoiceBlank:
      "Harap memilih " + Catalog.SONumber.Label + " terlebih dahulu.",
    New_InvoiceFields:
      "Tidak diperkenankan mengubah " + Catalog.InvoiceDate.Label + ", " +
      Catalog.ClientID.Label + " dan " + Catalog.ClientAddress.Label + ".",
    New_InvoiceItem:
      "Tidak diperkenankan mengubah " + RtnInvoiceItemCatalog.DispatchName.Label + ", " +
      RtnInvoiceItemCatalog.PackagingName.Label + " dan " +
      RtnInvoiceItemCatalog.SellPrice.Label + ".",
    Related_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Section: {
    Invoice: {
      Title: Model.Invoice.Module.FullName,
      AddItem: {
        Title: "Tambah Barang dari Nota Penjualan"
      }
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  }
}