import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import Omzet from "./Omzet/Omzet";
import OmzetPrint from "./PrintList/OmzetPrint";
import OmzetExport from "./Export/OmzetExport";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.Omzet),
    Print: RouterModel.getPath(AppModule.Omzet, AppModuleType.Print),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Omzet),
        name: RouterModel.getName(AppModule.Omzet),
        component: Omzet,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Omzet),
          moduleName: RouterModel.getModule(AppModule.Omzet)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Omzet, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Omzet, AppModuleType.ExportList),
        component: OmzetExport,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Omzet, AppModuleType.ExportList),
          moduleName: RouterModel.getModule(AppModule.Omzet)
        }
      },
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Omzet, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Omzet, AppModuleType.Print),
        component: OmzetPrint,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Omzet, AppModuleType.Print),
          moduleName: RouterModel.getModule(AppModule.Omzet)
        }
      }
    ];
  }
}