<template>
  <kst-container class="kst-item-list">
    <kst-form
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :loading="isLoading"
      :title="title"
      :warnings="formWarnings"
    >
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
        <kst-button action-mode full-label
          class="is-primary ml-4"
          :module="Model.Stock.Module"
          :moduleType="Model.Stock.ModuleType.List"
        />
      </template>

      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-section :loading="isListLoading" search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
            :urlParams="urlParams"
          />
        </template>

        <List
          :access="access"
          :failureList="failureList"
          :items="items"
          :isListLoading="isListLoading"
        />
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../ItemModel.js";
import Services from "../ItemServices.js";

import Search from './ItemList_Search';
import List from './ItemList_List';

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    searchData: {},
    access: {},
    items: [],
    isLoading: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.Search]: this.searchData.Search
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.formWarnings = null;
      this.access = Model.createAccess();
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      this.searchData = {
        Search: ""
      };
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadListData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadListData() {
      const config = Services.getItemListData();
      this.runAxios(config, null,
        this.loadListData_Success,
        this.loadListData_Error,
        this.loadListData_Invalid
      );
    },
    loadListData_Success(data) {
      this.access = data.Access;
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
      this.initLoad_End();
    },
    loadListData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadListData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
      }

      const config = Services.getItemList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data, counter) {
       // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }
      this.items = data;
      this.isListLoading = false;
    },
    loadList_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },

    handleSearchSubmit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>