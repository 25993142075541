<template>
  <form novalidate class="field has-addons" @submit.prevent="debounceSearch">
    <div class="control">
      <kst-input floating-label rounded
        class="kst-layout-search mb-0"
        field="Search"
        :data="userData"
        :catalog="Catalog"
      />
    </div>
    <div class="control">
      <kst-button rounded search-mode @click="debounceSearch"/>
    </div>
  </form>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin.js";
import Catalog from "./LayoutCatalog.js";

export default {
  mixins: [SearchMixin],
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      if (this.isValid()) {
        this.$emit(this.$kst.Enum.Event.Submit);
      }
    },

    isValid() {
      const search = this.$kst.Out.getString(this.userData.Search);

      return search !== null;
    }
  }
}
</script>