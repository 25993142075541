<template>
  <kst-print class="kst-stock-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData"/>
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>
      <div class="column has-text-right">{{ timestamps }}, {{ username }}</div>
    </template>
    <Items class="mt-4" :items="items" :access="access" />
  </kst-print>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Company from "./StockPrintList_Company";
import Items from "./StockPrintList_Items";
import Model from "../StockModel.js";
import Services from "../StockServices.js";
export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Items
  },
  data: () => ({
    companyData: {},
    searchData: {},
    access: {},
    items: [],
    isLoading: true,
    error: null
  }),
  computed: {
    title() {
      return Model.Module.FullName;
    },
    timestamps() {
      return Model.getPrintedAt() + this.$kst.View.getTimestamp();
    },
    username() {
      return Model.getPrintedBy() + this.$kst.Session.getName();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = Model.createAccess();
      this.iniParam();
      this.loadCompany();
    },
    iniParam() {
      this.paramData = {
        Search: "",
        WarehouseID: this.$kst.Search.OptionAll,
        Stock: this.$kst.Search.OptionAll
      }
      Object.keys(this.paramData).forEach(field => {
        const value = this.getUrlParam(field);
        this.paramData[field] = value == this.$kst.Search.OptionAll ? null : value;
      })
    },
    loadCompany() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompany_Success, this.loadCompany_Error);
    },
    loadCompany_Success(data) {
      this.companyData = data.data;
      this.loadListData();
    },
    loadCompany_Error(message) {
      this.isLoading = false;
      this.error = message;
    },
    loadListData() {
      const config = Services.getStockListData();
      this.runAxios(config, null, this.loadListData_Success, this.loadListData_Error)
    },
    loadListData_Success(data) {
      this.access = data.Access;
      this.loadData();
    },
    loadListData_Error(message) {
      this.isLoading = false;
      this.error = message;
    },
    loadData() {
      const options = {
        name: this.$kst.Out.getString(this.paramData.Search),
        availstock: this.$kst.Search.getValue(this.paramData.Stock),
        field: Model.Search.Fields,
        warehouse: this.$kst.Search.getValue(this.paramData.WarehouseID)
      }
      const config = Services.getStockList(options);
      this.runAxios(config, null, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data) {
      this.isLoading = false;
      this.items = data;
    },
    loadData_Error(message) {
      this.isLoading = false;
      this.error = message;
    }
  }
}
</script>