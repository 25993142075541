<template>
  <kst-table paginated action input-mode 
    :data="userItems" 
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="ItemID"
      :label="StockClosingItemCatalog.ItemID.Label"
    >
      <kst-output field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo_Details(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="StockClosingItemCatalog.SellPrice.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="SpecialPriceName"
      :label="StockClosingItemCatalog.SpecialPriceName.Label"
    >
      <kst-input field-only
        :field="column.field" :data="row" 
        :catalog="StockClosingItemCatalog"
        :index="index"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="DiscountedValue"
      :label="StockClosingItemCatalog.DiscountedValue.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column 
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="StockClosingItemCatalog.Quantity.Label"
    >
      <kst-input field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
        :index="index"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="StockClosingItemCatalog.Total.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import StockClosingItemCatalog from "./StockClosingDetailItemCatalog.js";
import Model from "../StockClosingModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userItems: Array,
    isListLoading: Boolean,
    failureList: null
  },
  data: () => ({
    Model: Model,
    StockClosingItemCatalog: StockClosingItemCatalog
  })
}
</script>