import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

export default {
  ClosingNumber: {
    Label: "Nomor Pengeluaran",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true,
    ReadOnly: true
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    MaxLength: 50,
    ReadOnly: true
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  Total: {
    Label: "Total Harga",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100,
    ReadOnly: true
  }
}