<template>
  <span/>
</template>

<script>
  import AxiosMixin from "@/mixins/AxiosMixin.js";
  import LoadingMixin from "@/mixins/LoadingMixin.js";
  import XlsxMixin from "@/mixins/XlsxMixin.js";
  import Catalog from "../SalesRecapCatalog.js";
  import Info from "../SalesRecapInfo.js";
  import InvoiceCatalog from "@/components/Invoice/InvoiceCatalog.js";
  import Model from "../SalesRecapModel.js";
  import Services from "../SalesRecapServices.js";

  export default {
    mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      console.log(data)
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      if (this.searchData.WithInvoice) {
        this.createSheet();
        this.addInvoice(data.InvoiceList);
      }
      console.log(data.InvoiceList)

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getSalesRecapDetails(id, {withinvoice: 1});
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.RecapNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.CashierName);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.CashReceived);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.CreatedByName);

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("RecapNumber", data, Catalog);
      this.addCellValue("Date", data, Catalog);
      this.addCellValue("WarehouseName", data, Catalog);
      this.addCellValue("CashierName", data, Catalog);
      this.addCellValue("Total", data, Catalog);
      this.addCellValue("CashReceived", data, Catalog);
      this.addCellValue("Comment", data, Catalog);
      this.addCellValue("CreatedByName", data, Catalog);
    },
  
    addInvoice(items) {
      this.addInvoice_Details(items)

      this.addSheet(Info.Export.Details.Excel.SheetName.Invoice);
    },
    addInvoice_Details(items) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(InvoiceCatalog.InvoiceDate);
      this.addColumnHeader(InvoiceCatalog.SONumber);
      this.addColumnHeader(InvoiceCatalog.WarehouseName);
      this.addColumnHeader(InvoiceCatalog.Client);
      this.addColumnHeader(InvoiceCatalog.PaymentTypeName);
      this.addColumnHeader(InvoiceCatalog.Total);
      this.addColumnHeader(InvoiceCatalog.Status);

      // value
      for ( const row of items ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("InvoiceDate", row, InvoiceCatalog);
      this.addCellValue("SONumber", row, InvoiceCatalog);
      this.addCellValue("WarehouseName", row, InvoiceCatalog);
      this.addCellValue("Client", row, InvoiceCatalog);
      this.addCellValue("PaymentTypeName", row, InvoiceCatalog);
      this.addCellValue("Total", row, InvoiceCatalog);
      this.addCellStatus(row.Status, Model.Status);
      }
    }
  }
}
</script>