var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"failure":_vm.failureList,"loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Action',{attrs:{"row":row}})]}}])},[_c('b-table-column',{attrs:{"centered":"","sortable":"","cell-class":"ks-col-shortdate","field":"InvoiceDate","label":_vm.Catalog.InvoiceDate.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"SONumber","label":_vm.Catalog.SONumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"WarehouseName","label":_vm.Catalog.WarehouseName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"Client","label":_vm.Catalog.ClientID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"PaymentTypeID","label":_vm.Catalog.PaymentTypeID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","numeric":"","sortable":"","cell-class":"ks-col-price has-text-weight-bold","field":"Total","label":_vm.Catalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","cell-class":"ks-col-status","field":"Status","label":_vm.Catalog.Status.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-status',{attrs:{"value":row.Status,"statusEnum":_vm.Model.Status}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }