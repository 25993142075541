<template>
  <kst-section items-mode class="mt-5">
    <DispatchSearch new-item-enabled
      ref="mdlDispatchSearch"
      @newitem="handleItem_NewItem"
      @submit="handleItem_Submit"
    />
    <PriceSearch ref="mdlPriceSearch" 
    :items="priceList" 
    :userData="userData"
    @submit="handleReceive_Submit" 
    @callback="handlePrice_Callback" />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Model.PurchaseItem.Module.FullName"
        :moduleType="Model.PurchaseItem.ModuleType.New"
        @click="handleAdd_Item"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.PurchaseItem.Module.FullName"
          :moduleType="Model.PurchaseItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <List :isListLoading="isListLoading" :failureList="failureList" :userData="userData" :userItems="userItems" @callback="handleList_Callback"/>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../PurchaseModel.js";

import DispatchSearch from "@/components/Dispatch/Search/DispatchSearch";
import PriceSearch from "@/components/Receive/PriceSearch/ReceivePriceSearch";
import List from "./PurchaseNew_Items_List";

import Services from "../PurchaseServices.js";

export default {
  mixins: [AxiosMixin],
  components: {
    DispatchSearch,
    PriceSearch,
    List
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    isListLoading: false,
    rowIndex: null,
    priceList: [],
    // error
    failureList: null,
    // bounce
    loadPriceCounter: 0
  }),
  methods: {
    loadItemDetails_End() {
      this.isListLoading = false;
    },
    loadItemDetails(dispatchId) {
      const config = Services.getDispatchDetails(dispatchId);
      this.runAxios(config, null, this.loadItemDetails_Success, this.loadItemDetails_Error);      
    },
    loadItemDetails_Success(data) {
      let item = Model.createItem();
      Model.setItemByAPI(item, data);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);

      this.loadItemDetails_End();
    },
    loadItemDetails_Error(message) {
      this.failureList = message;
      this.loadItemDetails_End();
    },

    loadPrice(counter, filter) {
      this.priceList = [];
      const item = this.userItems[this.rowIndex];

      let options = { item: item.DispatchID };

      if (filter) {
        options.name = this.$kst.Out.getString(filter.search);
        options.vendor = this.$kst.Out.getString(filter.vendor);
        options.startDate = this.$kst.Out.getDate(filter.date[0]);
        options.endDate = this.$kst.Out.getDate(filter.date[1]);
      }

      const config = Services.getPriceList(options);
      this.runAxios(config, counter, this.loadPrice_Success, this.loadPrice_Error);
    },

    loadPrice_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadPriceCounter) {
        return;
      }

      this.priceList = data;
    },
    loadPrice_Error(message) {
      this.failureList = message;
    },

    handlePrice_Callback(filter) {
      this.loadPriceCounter++;
      this.loadPrice(this.loadPriceCounter, filter);
    },

    handleAdd_Item() {
      this.$refs.mdlDispatchSearch.show();
    },

    handleItem_NewItem(itemName) {
      let newItem = Model.createItem();
      Model.setItemByNew(newItem, itemName);
      Model.updateItem(newItem);
      this.userItems.push(newItem);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleItem_Submit(row) {
      this.loadItemDetails(row.DispatchID);
    },

    handleReceive_Submit(row) {
      Model.setItemByPrice(this.userItems[this.rowIndex], row);
    },
    handleList_Callback(params) {
      const { isPrice, index } = params;
      
      if (isPrice) {
        this.rowIndex = index;
        this.loadPrice(this.loadPriceCounter, { 
          search: "",
          vendor: this.userData.Vendor,
          date: this.$kst.In.getThreeMonthDay()
        });
        this.$refs.mdlPriceSearch.show(
          this.userItems[index].DispatchBuyPrice, this.userData.Vendor
        );
      }
    }
  }
}
</script>