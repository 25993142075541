import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Dispatch,
  ModuleType: {
    New: AppModuleType.New,
    List: AppModuleType.List,
    Search: AppModuleType.Search
  },

  /*** related ***/

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      Search: AppModuleType.Search
    }
  },

  /*** property ***/
  Search: {
    Fields: ["FullName", "SKU"]
  }
}