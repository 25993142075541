import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.Omzet,
  ModuleType: {
    // Export: AppModuleType.Export,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    Print: AppModuleType.Print
  },

  Tooltip: {
    Label: "Lihat Ringkasan Penjualan"
  },

  Expense: {
    Title: "Ringkasan Penjualan"
  },

  IsActive: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    },
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    }
  },

  /*** method ***/

  /*** external ***/
  createAccess() {
    return AppCatalog.Access.getDefault();
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  getPaymentType(items, paymentList) {
    return this.helper.getPaymentType(items, paymentList)
  },

  helper: {
    getPaymentType(items, paymentList) {
      let subPaymentObj = {};
      // let paymentTotal = 0;
      let subPaymentId, total;
      for (const item of items) {
        subPaymentId = item.PaymentTypeID;
        total = App.Out.getInteger(item.TotalBruto);
        // paymentTotal += total;
        
        if (Object.prototype.hasOwnProperty.call(subPaymentObj, subPaymentId)) {
          subPaymentObj[subPaymentId] += total;
        }
        else {
          subPaymentObj[subPaymentId] = total;
        }
      }

      for (const subPayment of paymentList) {
        subPayment.Total = 0;
        if (Object.prototype.hasOwnProperty.call(subPaymentObj, subPayment.ID)) {
          subPayment.Total = subPaymentObj[subPayment.ID];
        }
      }
    }
  }
}