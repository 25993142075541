import App from "../../services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";
import ItemCatalog from "./StockClosingItemCatalog";

export default {
  // required in model
  Module: AppModule.StockClosing,
  ModuleType: {
    New           : AppModuleType.New,
    Edit          : AppModuleType.Edit,
    Details       : AppModuleType.Details,
    Export        : AppModuleType.Export,
    ExportDetails : AppModuleType.ExportDetails,
    Print         : AppModuleType.Print,
    PrintDetails  : AppModuleType.PrintDetails,
    List          : AppModuleType.List
  },

  /*** related ***/

  SalesItem: {
    Module: AppModule.Item,
    ModuleType: {
      New   : AppModuleType.New,
      List  : AppModuleType.List,
    }
  },

  /*** property ***/

  Search: {
    Fields: ["ClosingNumber"]
  },
  Data: {
    DefaultSpecialPrice : { SpecialPriceValue: App.Search.OptionNone },
    DefaultGrandTotal   : { GrandTotal: 0 },
    DefaultPackaging    : "pcs",
    SpecialPriceOptions : []
  },

  /*** method ***/

  createItem() {
    return this.helper.createItem();
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  populateSearchParam(param) {
    let options = {};
    if (param) {
      options.field       = this.Search.Fields,
      options.name        = App.Out.getString(param.Search),
      options.warehouseid = App.Search.getValue(param.WarehouseID);
      options.startdate   = App.Out.getDate(param.Date[0]);
      options.enddate     = App.Out.getDate(param.Date[1]);
    }
    return options;
  },
  getSpecialPriceOptions(items, optionNon) {
    const rowId       = "ID";
    const rowLabel    = "Name";
    let listOptions   = { id: rowId, label: rowLabel }
    if (optionNon) {
      listOptions.nonActive = true;
      listOptions.nonText = optionNon;
    }
    const selectItems = App.Search.createList(items, listOptions);
    const options     = App.In.getSelectOptions(rowId, rowLabel, selectItems);
    this.Data.SpecialPriceOptions = options;
    return options;
  },
  populateDataItem(data, specialPriceData) {
    let result = [];
    if (data) {
      result = data.map(item => {
        const sellPrice           = App.Out.getInteger(item.SellPrice);
        const Quantity            = App.Out.getInteger(item.Quantity);
        item.Quantity             = Quantity;
        item.SellPrice            = sellPrice;
        item.SpecialPrice         = App.Search.OptionNone;
        item.SpecialPriceValue    = 0;
        item.DiscountedValue      = sellPrice;
        item.Total                = sellPrice * Quantity;
        item.MaxQuantity          = Quantity;
        item.PackagingName        = this.Data.DefaultPackaging;
        item.Errors               = [];
        item.ErrorsColl           = {};
        item.Quantity_Valid       = true;
        item.SpecialPriceOptions  = this.Data.SpecialPriceOptions;

        this.mapDataItem(item, specialPriceData);
        return item;
      });
    }
    return result;
  },
  populatePostData(data, items) {
    const result = {
      CreatedByID   : App.Session.getUserID(),
      CreatedByName : App.Session.getName(),
      CompanyID     : App.Session.getCompanyID(),
      WarehouseID   : data.WarehouseID,
      ClosingNumber : data.IsAutoNumber ? null : data.ClosingNumber,
      Date          : App.Out.getDate(data.Date),
      Comment       : data.Comment,
      Items         : items.map(item => {
        return {
          DispatchID      : item.DispatchID,
          DispatchName    : item.FullName,
          Qty             : item.Quantity,
          PackagingName   : item.PackagingName,
          PackagingValue  : 1,
          SellPrice       : item.SellPrice,
          SpecialPriceID  : item.SpecialPrice == -2 ? null : item.SpecialPrice
        }
      })
    }
    return result;
  },
  mapDataItem(item, specialPriceData) {
    const SpecialPriceValue = specialPriceData !== undefined 
      ? specialPriceData.SpecialPriceValue
      : item.SpecialPrice;
    const specialPrice      = item.SpecialPriceOptions.rows.find(row => row.ID == SpecialPriceValue) || {}
    const quantity          = App.Out.getInteger(item.Quantity || 0);
    const discPercent       = App.Out.getInteger(specialPrice.DiscPercent || 0);
    const sellPrice         = App.Out.getInteger(item.SellPrice || 0);
    const discountValue     = specialPrice.ID;
    const discountedValue   = sellPrice - App.Data.getDiscPercent_Value(sellPrice, discPercent);
    item.SpecialPriceValue  = discPercent;
    item.SpecialPrice       = discountValue;
    item.DiscountedValue    = discountedValue;
    item.Total              = quantity * discountedValue;
    return item;
  },
  mapDataItemDetail(item) {
    const quantity          = App.Out.getInteger(item.Qty || 0);
    const sellPrice         = App.Out.getInteger(item.SellPrice || 0);
    const discPercent       = App.Out.getInteger(item.SpecialPriceDiscPercent || 0);
    const discountedValue   = sellPrice - App.Data.getDiscPercent_Value(sellPrice, discPercent);
    const defaultSpecialPrice = App.Search.LabelNone + ItemCatalog.SpecialPriceName.Label;
    const result = {
      DispatchID: item.DispatchID,
      FullName: item.DispatchName,
      Quantity: item.Qty,
      SellPrice: item.SellPrice,
      SpecialPriceName: item.SpecialPriceName || defaultSpecialPrice,
      DiscountedValue: discountedValue,
      Total: quantity * discountedValue,
      ItemID: item.ItemID,
      ItemName: item.ItemName,
      SKU: item.SKU,
      DispatchIsActive: item.DispatchIsActive
    };
    return result;
  },
  mapDataDetail(data) {
    data.Date = App.In.getDate(data.Date);
    return data;
  },
  getDefaultDetail() {
    return {
      Date: App.In.getDateToday(),
      WarehouseID: null,
      ClosingNumber: "",
      IsAutoNumber: true,
      Comment: ""
    }
  },
  getSubtotalItems(item) {
    let total = 0;
    const DiscountedValue = App.Out.getInteger(item.DiscountedValue || 0);
    const Quantity        = App.Out.getInteger(item.Quantity || 0);
    total = DiscountedValue * Quantity;
    return total;
  },
  getDefaultSpecialPrice() {
    return {
      SpecialPriceValue: this.Data.DefaultSpecialPrice.SpecialPriceValue
    };
  },
  getDefaultGrandTotal() {
    return {
      GrandTotal: this.Data.DefaultGrandTotal.GrandTotal
    };
  },
  getGrandTotal(items) {
    let total = 0;
    items = items || [];
    if (items) {
      total = items.map(item => item.Total).reduce((prev, next) => prev + next);
    }
    return total;
  },
  getItemInfo_Details(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    // SKU
    infoList.push(ItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", item, ItemCatalog)
    );

    return infoList;
  },
  setItemErrors(item, field, errors) {
    item.ErrorsColl[field] = [];
    for (const error of errors) {
      if (error !== App.Vee.ValidRule) {
        item.ErrorsColl[field].push(error);
      }
    }
    let fieldErrors, newErrors = [];
    for (const fieldName in item.ErrorsColl) {
      fieldErrors = item.ErrorsColl[fieldName];
      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }
    const Quantity    = App.Out.getInteger(item.Quantity || 0);
    const MaxQuantity = App.Out.getInteger(item.MaxQuantity || 0);
    if (Quantity > MaxQuantity) {
      newErrors.push("Maximum quantity adalah " + MaxQuantity);
    }
    item.Errors = newErrors;
  },
  validateItemQty(item) {
    const Quantity    = App.Out.getInteger(item.Quantity || 0);
    const MaxQuantity = App.Out.getInteger(item.MaxQuantity || 0);
    if (Quantity > MaxQuantity) {
      return false;
    }
    return true;
  },
  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  }
}