<template>
  <kst-container class="kst-specialprice-details">
    <kst-form
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :loading="isLoading"
      :title="title"
      :warnings="formWarnings"
    >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <Details :data="data"/>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../SpecialPriceInfo.js";
import Services from "../SpecialPriceServices.js";

import Action from "./SpecialPriceDetails_Action";
import Details from "./SpecialPriceDetails_Details";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details
  },
  data: () => ({
    data: {},
    isLoading: false,
     // error
    formWarnings: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }

      this.initLoad(id);
    },

    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetails(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails(id) {
      const config = Services.getSpecialPriceDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.data = data;
      this.initLoad_End();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    }
  }
}
</script>