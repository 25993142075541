import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import DailyReport from "./DailyReport/DailyReport";
import DailyReportPrint from "./PrintList/DailyReportPrint";
import DailyReportExport from "./Export/DailyReportExport";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.DailyReport),
    Print: RouterModel.getPath(AppModule.DailyReport, AppModuleType.Print),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.DailyReport),
        name: RouterModel.getName(AppModule.DailyReport),
        component: DailyReport,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DailyReport),
          moduleName: RouterModel.getModule(AppModule.DailyReport)
        }
      },
      {
        path: RouterModel.getPath(AppModule.DailyReport, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.DailyReport, AppModuleType.ExportList),
        component: DailyReportExport,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DailyReport, AppModuleType.ExportList),
          moduleName: RouterModel.getModule(AppModule.DailyReport)
        }
      },
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.DailyReport, AppModuleType.Print),
        name: RouterModel.getName(AppModule.DailyReport, AppModuleType.Print),
        component: DailyReportPrint,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DailyReport, AppModuleType.Print),
          moduleName: RouterModel.getModule(AppModule.DailyReport)
        }
      }
    ];
  }
}