<template>
  <div>
    <kst-output print-mode field="AdjNumber" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="Date" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="WarehouseID" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../HppAdjCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>