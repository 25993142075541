import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.StockCard,
  ModuleType: {
    Export: AppModuleType.Export,
    List: AppModuleType.List,
    Print: AppModuleType.Print
  },

  /*** related ***/

  StockOpname: {
    Module: AppModule.StockOpname
  },
  StockReduction: {
    Module: AppModule.StockReduction
  },

  /*** property ***/

  DispatchID: {
    Label: 'Barang'
  },

  Warehouse: {
    WarehouseName: 'Semua Gudang'
  },

  ItemReport: {
    Title: "Daftar Barang",
    TitleItem: "Data Barang",
    TitleReport: "Data Laporan",
    TitleTransaction: "Transaksi",
    TitleListTransaction: "Daftar Transaksi",
    TitleSearch: "Rincian"
  },

  Status: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    },
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}