<template>
  <b-field>
    <b-checkbox v-model="checked" :disabled="disabled" @input="handleInput">
      <slot>
        {{ label }}
      </slot>
    </b-checkbox>
  </b-field>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    value: Boolean,
    label: String
  },
  data: () => ({
    checked: false
  }),
  watch: {
    value() {
      this.checked = this.value;
    }
  },
  created() {
    this.checked = this.value;
  },
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input, this.checked);
    }
  }
}
</script>