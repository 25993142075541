<template>
  <kst-container class="kst-receive-edit">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details :userData="userData" :warehouseOptions="warehouseOptions" />

        <kst-section :title="sectionInfo" class="mt-5" v-if="userItems">
          <Received :userData="userData" :warehouseOptions="warehouseOptions" />
        </kst-section>

        <Items :isListLoading="isListLoading" :userData="userData" :userItems="userItems"/>
        <Total :data="userData" />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Catalog from "../ReceiveCatalog.js";
import Info from "../ReceiveInfo.js";
import Model from "../ReceiveModel.js";
import Router from "../ReceiveRouter.js";
import Services from "../ReceiveServices.js";

import Details from "./ReceiveEdit_Details";
import Items from "./ReceiveEdit_Items";
import Total from "./ReceiveEdit_Total";
import Received from "./ReceiveEdit_Received";

export default {
  components: {
    Details,
    Items,
    Total,
    Received
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    isLoading: false,
    isListLoading: true,
    Catalog: Catalog,
    warehouseOptions: {},
    userData: {},
    userItems: [],
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    formWarnings: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    sectionInfo() {
      return Info.Section.InfoPO.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.failureInit = null;

      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Edit_ParamIncomplete];
        return;
      }

      this.userItems = [];
      this.userData = Model.createDetails();
      this.userData.IsAutoNumber = false;
      this.loadWarehouse();
      this.initLoad(id);
    },

    loadWarehouse() {
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
    },

    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetails(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },
    initLoadList_End() {
      this.isListLoading = false;
    },

    loadDetails(id) {
      const options = { withpackagings: 1 };
      const config = Services.getReceiveDetails(id, options);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      for (const row of data.Items) {
        const item = Model.createItem();
        Model.setItemByData(item, row);
        Model.updateItemByPackaging(item);
        this.userItems.push(item);
      }

      Model.setDetailsByData(this.userData, data);
      Model.updateDetails(this.userData, this.userItems);

      this.initLoad_End();
      this.initLoadList_End();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
      this.initLoadList_End();
    },

    handleFormSubmit() {
      this.saveLoading = null;
      
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.openFullLoading(true, this.handleFormSubmit_Success);
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.putReceive(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>