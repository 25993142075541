<template>
  <kst-table action detailed view-mode
    :data="items"
    :loading="isLoading"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value ellipsis
        class="has-text-weight-bold" :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-status :value="row.Status" :statusEnum="Model.Status"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :row="row"/>
    </template>

    <template #detail="{ row }">
      <kst-container class="p-2">
        <Items :items="row.Items"/>
        <Total :data="row"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Model from "../InvoiceModel.js";

import Action from "./InvoiceRelated_Row_Action";
import Items from "./InvoiceRelated_Row_Items";
import Total from "./InvoiceRelated_Row_Total";

export default {
  components: {
    Action,
    Items,
    Total
  },
  props: {
    items: Array,
    isLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>