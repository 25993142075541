import moment from "moment";
import AppCatalog from "./AppCatalog.js";
import packageJson from "../../../package.json";

/**
 * Convert:
 * - from: data (source: any)
 * - to: value (target: UI, user), usually used as read-only information
 */
export default {
  getTimestamp() {
    return moment().format(AppCatalog.Format.ShortDateTime);
  },
  getVersion() {
    return packageJson.version;
  }
}