import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import PurchaseList from "./List/PurchaseList";
import PurchaseNew from "./New/PurchaseNew";
import PurchaseDetails from "./Details/PurchaseDetails";
import PurchaseEdit from "./Edit/PurchaseEdit";
import PurchaseExportDetails from "./ExportDetails/PurchaseExportDetails"

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Purchase, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Purchase, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Purchase, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Purchase, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Purchase, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.Details),
        component: PurchaseDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Purchase)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.Edit),
        component: PurchaseEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Purchase)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.ExportDetails),
        component: PurchaseExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Purchase)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.List),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.List),
        component: PurchaseList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Purchase)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.New),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.New),
        component: PurchaseNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Purchase)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}