<template>
  <kst-table view-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchName"
      :label="StockReductionItemCatalog.DispatchName.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="StockReductionItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging-small"
      field="Packaging"
      :label="StockReductionItemCatalog.Packaging.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="StockReductionItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="StockReductionItemCatalog.Qty.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="StockReductionItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="HppTotal"
      :label="StockReductionItemCatalog.HppTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import StockReductionItemCatalog from "../StockReductionItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    StockReductionItemCatalog: StockReductionItemCatalog
  })
}
</script>