import InvoiceServices from "@/services/InvoiceServices.js";
import RtnInvoiceServices from "@/services/RtnInvoiceServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";

export default {
  getRtnInvoiceDetails: RtnInvoiceServices.getDetails,
  getRtnInvoiceEditData: RtnInvoiceServices.getEditData,
  getRtnInvoiceList: RtnInvoiceServices.getList,
  postRtnInvoice: RtnInvoiceServices.postRtnInvoice,
  putRtnInvoice: RtnInvoiceServices.putRtnInvoice,
  // invoice
  getInvoiceDetails: InvoiceServices.getDetails,
  // warehouse
  getWarehouseList: WarehouseServices.getList
}