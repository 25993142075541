<template>
  <kst-container class="kst-delivery-edit-status">
  <ValidationObserver ref="formDetails">
    <kst-form
      :cancelUrl="cancelUrl"
      :loading="isLoading"
      :title="title"
      :submit="isSubmit"
      :errors="errors"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :failure="failure"
      :failureInit="failureInit"
      :warnings="formWarnings"
      @submit="handleFormSubmit"
      >
        <Details :data="userData"/>
        <Status :statusOptions="statusOptions" :title="titleStatus" :data="userData" @submit="handleisSubmit"/>
        <Items :data="userData" :items="userItems"/>
    </kst-form>
  </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../DeliveryInfo.js";
import Router from "../DeliveryRouter.js";
import Model from "../DeliveryModel.js";

import Details from "./DeliveryEditStatus_Details";
import Items from "./DeliveryEditStatus_Items";
import Status from "./DeliveryEditStatus_Status";

import Services from "../DeliveryServices.js";

export default {
  components: {
    Details,
    Items,
    Status
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    userItems: [],
    statusOptions: null,
    isLoading: false,
    isSubmit: true,
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    titleStatus() {
      return Info.Section.Status.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Edit_ParamIncomplete];
        return;
      }

      this.userData = {};
      this.loadDetails(id);
    },

    handleisSubmit() {
      if(parseInt(this.userData.StatusChanged) === Model.Status.Returned.ID) {
        this.isSubmit = false;
      }else{
        this.isSubmit = true;
      }
    },

    initLoad_End() {
      this.isLoading = false;
    },
    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;

      const config = Services.getDeliveryDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.userData = Model.createDetails();

      for (const row of data.Items) {
        let item = Model.createItem();
        Model.setItemByData(item, row);
        Model.updateItemByPackaging(item);
        this.userItems.push(item);
      }

      Model.setDetailsByData(this.userData, data);
      Model.setStatusChanged(this.userData, parseInt(this.userData.Status)+1);

      if(parseInt(this.userData.Status) === Model.Status.Delivered.ID) {
        this.isSubmit = false;
      }

      this.statusOptions = Model.createStatusOptions('', parseInt(this.userData.Status));
      this.loadDetails_SO(data.SOID);
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadDetails_SO(id) {
      const config = Services.getInvoiceDetails(id);
      this.runAxios(config, null, this.loadDetails_SO_Success, this.loadDetails_SO_Error);
    },
    loadDetails_SO_Success(data) {
      Model.updateDetailsBySO(this.userData, data);
      this.initLoad_End();
    },
    loadDetails_SO_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          if(this.userData.ArrivalDate > this.userData.CompletedDate) {
            this.errors = ["Tanggal Selesai harus melebihi atau sama dengan Tanggal Terkirim."];
          } else {
            let data = Model.populateData(this.userData, this.userItems);
            this.saveData(data);
          }
          
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData_End() {
      this.closeLoading(this.saveLoading);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.patchDelivery(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },

    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;
      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>