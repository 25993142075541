<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row, index }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>

        <kst-input box-off field-only class="is-hidden"
          field="DispatchID" :data="row" :catalog="InvoiceItemCatalog"
          :index="index" @error="handleRowErrors"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="InvoiceItemCatalog.Name.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo_Edit(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="InvoiceItemCatalog.PackagingName.Label"
    >
      <template v-if="row.SalesDraftItemID === null">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
          :index="index" :options="row.PackagingOptions"
          @input="handlePackaging(row)" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="InvoiceItemCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="RequestedQuantity"
      :label="InvoiceItemCatalog.RequestedQuantity.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="InvoiceItemCatalog.SellPrice.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
        :index="index" @input="handleSellPrice(row)" @error="handleRowErrors"
      >
        <template #right>
          <div class="control">
            <kst-tooltip search-mode hover-only
              position="is-bottom"
              :content="InvoiceItemCatalog.SellPrice.Label"
            >
              <kst-button search-mode @click="handleSearch_Price(index)"/>
            </kst-tooltip>
          </div>
        </template>
      </kst-input>
    </b-table-column>

    <template v-if="userData.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row, index }"
        cell-class="ks-col-price-button"
        field="SpecialPriceID"
        :label="InvoiceItemCatalog.SpecialPriceID.Label"
      >
        <kst-input error-off field-only
          :field="column.field" :data="row" 
          :options="row.SpecialPriceOptions"
          :catalog="InvoiceItemCatalog"
          :index="index"
          @input="handleSpecialPrice(row)"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPriceNetto"
        :label="InvoiceItemCatalog.SellPriceNetto.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
        />
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Price"
      :label="InvoiceItemCatalog.Price.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.InvoiceItem.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import InvoiceItemCatalog from "../InvoiceItemCatalog.js";
import Model from "../InvoiceModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userItems: Array,
    deliveryList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    InvoiceItemCatalog: InvoiceItemCatalog,
    selectedIndex: null
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.validateItemsByDelivery(this.userData, this.userItems, this.deliveryList);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleQuantity(item) {
      Model.validateItem(item);
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.validateItemsByDelivery(this.userData, this.userItems, this.deliveryList);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleSellPrice(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.Edit_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userItems.splice(this.selectedIndex, 1);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.validateItemsByDelivery(this.userData, this.userItems, this.deliveryList);
      Model.updateDetails(this.userData, this.userItems);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    },
    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    },

    handleSearch_Price(index) {
      this.$emit(this.$kst.Enum.Event.Callback, {
        isPrice: true,
        index: index
      });
    },
    handleSpecialPrice(item) {
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    }
  }
}
</script>