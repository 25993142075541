<template>
  <img :src="source"/>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    src: String
  },
  computed: {
    source() {
      return AppCatalog.Folder.Public.Images + this.src;
    }
  }
}
</script>