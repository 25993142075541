export default {
  isActive(value) {
    return value === true ? true : false;
  },
  isExist(value) {
    return value === true ? true : false;
  },
  isStatic(value) {
    return value === true ? true : false;
  },
  isValid(value) {
    return value === true ? true : false;
  }
}