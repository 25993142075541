<template>
  <kst-section items-mode class="mt-5" :loading="isListLoading">
    <StockSearch
      ref="mdlStockSearch"
      @submit="handleItem_Submit"
    />
    <template #top-right>
      <template v-if="dataStock.length > 0">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.Item.Module.FullName"
          :moduleType="Model.Item.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.Item.Module.FullName"
          :moduleType="Model.Item.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <kst-warning class="mb-4" :items="itemsWarning"/>

    <template v-if="dataStock.length > 0">
      <kst-failure class="mb-4" data-mode :message="failureItems"/>
      <List 
        :userItems="userItems" 
        :isListLoading="isListLoading"
        @callback="handleCallbackItems"
      />
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import DialogMixin from "@/mixins/DialogMixin.js";
import Catalog from "../SpecialPriceAdjItemCatalog.js";
import Info from "../SpecialPriceAdjInfo.js";
import Model from "../SpecialPriceAdjModel.js";
import List from "./SpecialPriceAdjNew_Items_List";
import StockSearch from "@/components/Stock/Search/StockSearch";

export default {
  mixins: [AxiosMixin,DialogMixin],
  components: {
    StockSearch,
    List
  },
  props: {
    data: Object,
    dataStock: Array,
    userItems: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Model: Model,
    Catalog: Catalog,
    failureItems: null,
    rowIndex: null
  }),
  computed: {
    itemsWarning() {
      return this.dataStock.length == 0 ? [Info.Message.New_Items] : null;
    }
  },
  methods: {
    handleCallbackItems() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleItem_Submit(item) {
      const data = Object.assign({}, item);
      this.$emit(this.$kst.Enum.Event.Callback, data.DispatchID, data);
    },
    handleAdd_Item() {
      this.failureItems = null;
      this.$refs.mdlStockSearch.show(this.data.WarehouseID);
    }
  }
}
</script>