import { render, staticRenderFns } from "./Omzet_Result_Chart_Retur.vue?vue&type=template&id=60667598&"
import script from "./Omzet_Result_Chart_Retur.vue?vue&type=script&lang=js&"
export * from "./Omzet_Result_Chart_Retur.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports