import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Type: {
    Label: "Tipe",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 1000,
    Required: true,
    Highlight: true
  },
  TypeName: {
    Label: "Tipe",
    Type: InputType.Text,
    ReadOnly: true
  },
  TotalQuantity: {
    Label: "Stok",
    Type: InputType.Integer,
    ReadOnly: true
  },
  HppPerPcs: {
    Label: "Hpp Per Pcs",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  HppTotal: {
    Label: "Nilai Stok",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency,
    IsRange: true,
    RangeMin: "min",
    RangeMax: "max"
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency,
    IsRange: true,
    RangeMin: "min",
    RangeMax: "max"
  },
  ActivePackaging: {
    Label: App.Enum.Status.Active.Label + " " + AppModule.Packaging.FullName,
    Type: InputType.Integer,
    MinValue: 1
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },

  // support
  SellPriceMin: {
    Label: "Harga Jual Terendah",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  SellPriceMax: {
    Label: "Harga Jual Tertinggi",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  BuyPriceMin: {
    Label: "Harga Beli Terendah",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  BuyPriceMax: {
    Label: "Harga Beli Tertinggi",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  }
}