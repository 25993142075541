import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Variants: {
    Label: "Variant",
    Type: InputType.Text,
    ReadOnly: true
  },
  FullName: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 255,
    Required: true,
    Highlight: true
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    MaxLength: 45,
    Required: true,
    Highlight: true
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  Weight: {
    Label: "Berat (gram)",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true
  },
  Barcode: {
    Label: "Barcode",
    Type: InputType.Text,
    MaxLength: 50
  },
  Vendor: {
    Label: "Vendor",
    Type: InputType.Text,
    MaxLength: 50
  },
  Description: {
    Label: "Keterangan",
    Type: InputType.Text,
    MaxLength: 1000
  },
  ImageSrc: {
    Label: "Gambar",
    Type: InputType.Text,
    MaxLength: 200
  },
  // support
  HppTotal: {
    Label: "Nilai Stok",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  HppPerPcs: {
    Label: "Hpp Per Pcs",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D
  }
}