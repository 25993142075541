import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RequiredHide: true,
    RelatedValue: "WarehouseName"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  DispatchID: {
    Label: "Produk",
    ExportLabel: "Produk Id",
    Type: InputType.ID,
    RelatedValue: "DispatchName",
  },
  DispatchName: {
    Label: "Nama Produk",
    Type: InputType.Text
  },
  QtyBeginning: {
    Label: "Awal",
    Type: InputType.Integer
  },
  QtyReceive: {
    Label: "Terima",
    Type: InputType.Integer
  },
  QtyTrf: {
    Label: "Transfer",
    Type: InputType.Integer
  },
  QtyAdj: {
    Label: "Opname",
    Type: InputType.Integer
  },
  QtyReduction: {
    Label: "Pengurangan",
    Type: InputType.Integer
  },
  QtySales: {
    Label: "Jual",
    Type: InputType.Integer
  },
  QtyLast: {
    Label: "Akhir",
    Type: InputType.Integer
  },
  QtyClosing: {
    Label: "Tutup",
    Type: InputType.Integer
  },
  QtyBeginningTotal: {
    Label: "Total Awal",
    Type: InputType.Integer
  },
  QtyReceiveTotal: {
    Label: "Total Penerimaan",
    Type: InputType.Integer
  },
  QtyTrfTotal: {
    Label: "Total Transfer",
    Type: InputType.Integer
  },
  QtyAdjTotal: {
    Label: "Total Opname",
    Type: InputType.Integer,
  },
  QtyReductionTotal: {
    Label: "Total Pengurangan",
    Type: InputType.Integer
  },
  QtySalesTotal: {
    Label: "Total jual",
    Type: InputType.Integer
  },
  QtyLastTotal: {
    Label: "Total Akhir",
    Type: InputType.Integer
  },
  QtyClosingTotal: {
    Label: "Total Tutup",
    Type: InputType.Integer
  },
  Total: {
    Label: "Total",
    Type: InputType.Text
  }
}