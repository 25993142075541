<template>
  <div>
    <kst-output field="LogisticsNumber" :data="data" :catalog="Catalog"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
    <kst-output field="SOID" :data="data" :catalog="Catalog"/>
    <kst-output field="ClientID" :data="data" :catalog="Catalog">
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="data" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-output addons expanded floating-label
          field="Client" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>
    <kst-output field="DepartureDate" :data="data" :catalog="Catalog"/>
    <kst-output field="ArrivalDate" :data="data" :catalog="Catalog"/>
    <kst-output field="CompletedDate" :data="data" :catalog="Catalog"/>
    <kst-output field="ReturnedDate" :data="data" :catalog="Catalog"/>
    <kst-output field="VehicleIDNumber" :data="data" :catalog="Catalog"/>
    <kst-output field="Destination" :data="data" :catalog="Catalog"/>
    <kst-output field="ShipmentFee" :data="data" :catalog="Catalog"/>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-output field="ReturnReason" :data="data" :catalog="Catalog"/>
    <kst-output field="StatusName" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../DeliveryCatalog.js";
import Info from "../DeliveryInfo.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Delivery.Title;
    }
  }
}
</script>