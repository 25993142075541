import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 45,
    Required: true
  },
  PIC: {
    Label: "PIC",
    Type: InputType.Text,
    MaxLength: 100
  },
  Address: {
    Label: "Alamat",
    Type: InputType.Text,
    MaxLength: 200
  },
  NoPIC: {
    Label: "No PIC",
    Type: InputType.Phone,
    MaxLength: 15
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.ActiveInactive
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  }
}