import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama Alamat",
    Type: InputType.Text,
    MaxLength: 100,
    Required: true
  },
  Address: {
    Label: "Alamat",
    Type: InputType.Text,
    MaxLength: 200,
    Required: true,
    Highlight: true
  },
  ContactName: {
    Label: "PIC",
    Type: InputType.Text,
    MaxLength: 100
  },
  ContactMobile: {
    Label: "HP",
    Type: InputType.Phone,
    MaxLength: 15,
    Highlight: true
  },
  ContactPhone: {
    Label: "Telp",
    Type: InputType.Phone,
    MaxLength: 15,
    Highlight: true
  },
  ContactEmail: {
    Label: "Email",
    Type: InputType.Email,
    MaxLength: 50,
    Highlight: true
  }
}