import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true
  },
  WarehouseID: {
    Label: "Warehouse",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RequiredHide: true,
    RelatedValue: "WarehouseName"
  },
  WarehouseName: {
    Label: "Warehouse",
    Type: InputType.Text,
    ReadOnly: true
  },
  TotalBruto: {
    Label: "Total Bruto",
    PrintLabel: "Penjualan",
    ExportLabel: "Penjualan",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalDisc: {
    Label: "Total Diskon",
    PrintLabel: "Diskon Penjualan",
    ExportLabel: "Diskon Penjualan",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalRetur: {
    Label: "Total Retur",
    PrintLabel: "Retur",
    ExportLabel: "Retur",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  Total: {
    Label: "Total",
    PrintLabel: "Penjualan Bersih",
    ExportLabel: "Penjualan Bersih",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalCash: {
    Label: "Total Tunai",
    PrintLabel: "Total Tunai",
    ExportLabel: "Total Tunai",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalTransfer: {
    Label: "Total Transfer",
    PrintLabel: "Total Transfer",
    ExportLabel: "Total Transfer",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  NormalPrice: {
    Label: "Harga Jual Normal",
    PrintLabel: "Harga Normal",
    ExportLabel: "Harga Normal",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  SpecialPrice: {
    Label: "Harga Jual Spesial",
    PrintLabel: "Harga Spesial",
    ExportLabel: "Harga Spesial",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.ActiveInactive
  },
}