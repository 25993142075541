<template>
  <div>
    <kst-section search-mode class="pb-0">
      <Search 
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @reset="handleSearch_Reset"
        @submit="handleSearch_Submit"
      />
    </kst-section>

    <kst-section search-result-mode>
      <List 
        :failureList="failureList" 
        :isListLoading="isListLoading" 
        :items="dataReceive.Items"
        :access="accessData"
      />
    </kst-section>
    <Total :data="dataReceive" />
  </div>
</template>
<script>
import Search from "./VendorDetails_Receive_Search";
import List from "./VendorDetails_Receive_List";
import Total from "./VendorDetails_Receive_Total";

export default {
  components: {
    Search,
    List,
    Total
  },
  props: {
    accessData: Object,
    searchData: Object,
    warehouseOptions: Object,
    dataReceive: Object,
    failureList: String,
    isListLoading: Boolean
  },
  methods: {
    handleSearch_Reset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch_Submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>
