<template>
  <kst-container class="kst-stock-list">
    <kst-form :title="title">
      <ListCatalog />
      <kst-divider />

      <Mixin />
      <kst-divider />

      <Formatted />
      <kst-divider />

      <Simple />
    </kst-form>
  </kst-container>
</template>

<script>
import ListCatalog from "./TestExcel_ListCatalog";
import Formatted from "./TestExcel_Formatted";
import Mixin from "./TestExcel_Mixin";
import Simple from "./TestExcel_Simple";

export default {
  components: {
    Formatted,
    ListCatalog,
    Mixin,
    Simple
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
}
</script>