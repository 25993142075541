import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Active: true
  },
  FullName: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 255,
    Required: true,
    ReadOnly: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true,
    MaxLength: 45
  },
  Quantity: {
    Label: "Qty (pcs)",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true,
    Valid: true
  },
  SpecialPrice: {
    Label: "Diskon",
    Type: InputType.Select,
    MaxLength: 10,
    Required: true
  },
  SpecialPriceValue: {
    Label: "Diskon",
    Type: InputType.Select
  },
  SpecialPriceName: {
    Label: "Diskon",
    Type: InputType.Text,
    MaxLength: 50
  },
  SellPrice: {
    Label: "Harga Normal",
    Type: InputType.Integer,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  DiscountedValue: {
    Label: "Harga Spesial",
    Type: InputType.Decimal,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    ReadOnly: true,
    Output: OutputType.Decimal.Currency
  },
  Total: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  GrandTotal: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  }
}