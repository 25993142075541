var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"paginated":"","view-mode":"","data":_vm.items,"loading":_vm.isListLoading,"selected":_vm.userData.selected},on:{"update:selected":function($event){return _vm.$set(_vm.userData, "selected", $event)}}},[_c('b-table-column',{attrs:{"cell-class":"ks-col-image","field":"ImageSrc","label":_vm.ItemCatalog.ImageSrc.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-image',{attrs:{"read-only":"","thumbnail":"","src":row[column.field]}})]}}])}),_c('b-table-column',{attrs:{"field":"Name","label":_vm.ItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"SKU","label":_vm.ItemCatalog.SKU.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Packaging","label":_vm.ItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"AvailableQuantity","label":_vm.ItemCatalog.AvailableQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Price","label":_vm.ItemCatalog.Price.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }