<template>
  <div class="kst-pos has-background-grey-lighter p-4">
    <EditSellPrice ref="mdlEditSellPrice" @submit="handleEditSellPrice_Submit"/>
    <VerifySpvPass ref="mdlVerifySpvPass" @submit="handlePassSpv_Submit"/>

    <ValidationObserver ref="formDetails">
      <span class="is-hidden">
        <kst-icon add-cart-mode class="kst-pos-cart-icon"/>
      </span>

      <Header
        :userData="userData"
        :warehouseOptions="warehouseOptions"
        @callback="handleHeader_Callback"
      />

      <kst-container class="p-4">
        <div class="columns">
          <div class="column is-left">
            <ItemList ref="searchItem" :items="items" :searchData="searchData" :userData="userData" 
              :userItems="userItems" :itemsScanner="itemsScanner" :isListLoading="isListLoading"
              :specialPriceItems="specialPriceItems"
              @input="handleItem_Input" @submit="handleSearch_Submit" 
            />
          </div>
          <div class="column kst-pos-table">
            <ItemResult :userItems="userItems" :userData="userData" @edit="handleItem_Edit"/>
          </div>
          <div class="column kst-pos-details is-right">
            <Result :userItems="userItems" :userData="userData" :searchData="searchData" 
              :discOptions="discOptions" :paymentOptions="paymentOptions"
              @submit="handleFormSubmit" @cancel="handleFormCancel"
            />
          </div>
        </div>
      </kst-container>
    </ValidationObserver>
  </div>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";

import Model from "./PosModel.js";
import Catalog from "./PosCatalog.js";
import Router from "./PosRouter.js";
import PrintThermalRouter from "@/components/Thermal/PrintThermalRouter.js";
import Services from "./PosServices.js";

import Header from "./Pos_Header";
import EditSellPrice from "./Pos_EditSellPrice";
import ItemList from "./Pos_Search";
import ItemResult from "./Pos_Result_Items";
import Result from "./Pos_Result";
import VerifySpvPass from "@/components/Settings/Company/VerSpvPass/CompanyVerSpvPass";

export default {
  components: {
    Header,
    EditSellPrice,
    ItemList,
    ItemResult,
    Result,
    VerifySpvPass
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    Catalog: Catalog,
    items: [],
    itemsScanner: [],
    searchData: {},
    userItems: [],
    userData: {},
    specialPriceItems: [],
    editItemIndex: null,
    warehouseOptions: {},
    discOptions: {},
    paymentOptions: {},
    isLoading: null,
    isListLoading: true,
    saveLoading: null,
    // support debounce
    loadCounter: 0,
    //error
    failure: null,
    failureList: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let warehouseId = this.userData.WarehouseID;

      this.isLoading = this.openLoading();
      this.userData = Model.createDetails();

      if (warehouseId !== undefined) {
        this.userData.WarehouseID = warehouseId;
      }

      this.userItems = [];
      this.itemsScanner = null;

      this.initSearch();
      this.loadWarehouse();
    },
    initSearch() {
      this.searchData = {
        Search: "",
        Qty: 1,
        Barcode: null
      }

      this.discOptions = Model.createDiscTypeOptions();
    },
    initLoad_End() {
      this.closeLoading(this.isLoading);
    },

    loadWarehouse() {
      this.failureInit = null;

      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      if(this.userData.WarehouseID === null) {
        this.userData.WarehouseID = data[0].ID;
      }

      this.loadCounter++;
      this.loadPayment();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadPayment() {
      this.failureInit = null;
      let options = {}
      options.active = 1;

      const config = Services.getPaymentTypeList(options);
      this.runAxios(config, null, this.loadPayment_Success, this.loadPayment_Error);
    },
    loadPayment_Success(data) {
      this.paymentOptions = Model.getPaymentOptions(data);
      if(this.userData.PaymentTypeID === null) {
        this.userData.PaymentTypeID = data[0].ID;
      }

      this.loadCounter++;
      this.loadSpecialPrice();
    },
    loadPayment_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadItems(counter, searchData) {
      this.isListLoading = true;

      let options = {};
      options.name = this.$kst.Out.getString(searchData.Search);
      options.field = Model.Search.Fields;
      options.warehouse = this.userData.WarehouseID;

      const config = Services.getBarcodeList(options);
      this.runAxios(config, counter, this.loadItems_Success, this.loadItems_Error);
    },
    loadItems_End() {
      this.isListLoading = false;
    },
    loadItems_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = Model.populateItemsWithSpecialPrice(data, this.specialPriceItems, this.userData);
      this.itemsScanner = this.items;
      this.initLoad_End();
      this.loadItems_End();
    },
    loadItems_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
      this.loadItems_End();
    },
    loadSpecialPrice() {
      const options = { availstock: 1 }
      const config = Services.getSpecialPriceDispatchList(options);
      this.runAxios(config, null, this.loadSpecialPrice_Success, this.loadSpecialPrice_Error);
    },
    loadSpecialPrice_Success(data) {
      this.specialPriceItems = data;
      this.loadItems(this.loadCounter, this.searchData);
    },
    loadSpecialPrice_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
      this.loadItems_End();
    },
    handleClear_Client() {
      Model.clearDetailsByClient(this.userData);
    },

    handleItem_Edit(index) {
      this.editItemIndex = index;
      this.$refs.mdlVerifySpvPass.show();
    },
    handleEditSellPrice_Submit(sellPrice) {
      let item = this.userItems[this.editItemIndex];
      item.UseSpvPassword = true;
      if(item.SpecialPrice > 0) {
        item.SpecialPrice = this.$kst.Out.getInteger(sellPrice);
      } else {
        item.SellPrice = this.$kst.Out.getInteger(sellPrice);
      }
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
      this.editItemIndex = null;
    },
    handlePassSpv_Submit() {
      this.$refs.mdlEditSellPrice.show();
    },

    handleItem_Input(elementItem, userItemIndex) {
      let imgtodrag = elementItem;
      let cartIcon = this.$jquery(".kst-pos-cart-icon");
      let resultItem = this.$jquery(".kst-pos-result-item-qty").eq(userItemIndex);
      let imgclone = cartIcon.clone()
        .removeClass("kst-pos-cart-icon")
        .offset({
          top: imgtodrag.offset().top + (imgtodrag.height() / 2),
          left: imgtodrag.offset().left + (imgtodrag.width() / 2)
        })
        .css({
          "position": "absolute",
          "z-index": "100"
        })
        .appendTo(this.$jquery("body"))
        .animate({
            top: resultItem.offset().top + (resultItem.height() / 2),
            left: resultItem.offset().left + (resultItem.width() / 2),
          },
          1000,
          "easeInOutExpo"
        )
      ;

      imgclone.animate(
        {
          "width": 0,
          "height": 0
        },
        () => {
          this.$jquery(imgclone).detach();
        }
      );
    },
    handleSearch_Submit() {
      this.loadCounter++;
      this.loadItems(this.loadCounter, this.searchData);
    },

    handleFormCancel() {
      this.redirectTo(Router.Dashboard.Path);
    },

    handleFormSubmit() {
      this.saveLoading = null;

      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let msg = "";
          const isNotvalidSpecialQty = Model.isNotvalidSpecialQty(this.userItems);
          if (this.userData.PayReturn < 0) {
            msg = "Jumlah Bayar harus lebih besar atau sama dengan Total Harga.";
            this.notifyFormError(true, msg);
          }
          else if(isNotvalidSpecialQty) {
            msg = isNotvalidSpecialQty;
            this.notifyFormError(true, msg);
          }
          else if (this.userData.DiscTotal > this.userData.TotalBruto) {
            this.notifyFormError(true);
          }
          else {
            let data = Model.populateData(this.userData, this.userItems);
            this.saveData(data);
          }
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData_End(){
      this.closeLoading(this.saveLoading);
    },

    saveData(data) {
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postInvoice(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },

    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        //this.openTab(Router.PrintThermal.Path + urlParams);
        this.openTab(PrintThermalRouter.Path.PrintThermal + urlParams);
      });

      this.init();
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.saveData_End();
      this.notifyFormError(true, message);
    },
    handleHeader_Callback() {
      this.loadItems(this.loadCounter, this.searchData);
      this.$refs.searchItem.resetScanResult();
    }
  }
}
</script> 

<style scoped lang="css" src="./_layout.css"/>