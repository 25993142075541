<template>
    <kst-container class="px-5 py-5 kst-report-netprofit">
    <div class="pb-4 has-text-weight-semibold kst-dashboard-list-title">
      {{ Info.Profit.Title }}
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
          Penjualan Bersih
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalNetSales" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
          Harga Modal
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalHpp" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
          {{ Model.StockOpname.Module.FullName }}
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalStockAdj" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
          {{ Catalog.TotalStockReduction.Label }}
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalStockReduction" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>

    <div class="divider"></div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title">
          Laba Kotor
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalProfit" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    </kst-container>
</template>

<script>
import Info from '../ProfitlossInfo.js';
import Model from '../ProfitlossModel.js';
import Catalog from '../ProfitlossCatalog.js';

export default {
  props: {
    data: Object
  },
  data: () => ({
    Info: Info,
    Model: Model,
    Catalog: Catalog
  })
}
</script>