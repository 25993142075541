<template>
  <div>
    <kst-output field="RecapNumber" :data="data" :catalog="Catalog"/>
    <kst-output field="Date" :data="data" :catalog="Catalog"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
    <kst-output field="CashierID" :data="data" :catalog="Catalog"/>
    <kst-output field="Total" :data="data" :catalog="Catalog"/>
    <kst-output field="CashReceived" :data="data" :catalog="Catalog"/>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-output field="CreatedByID" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SalesRecapCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>