<template>
  <table>
    <tr>
      <td>&nbsp;</td>
    </tr>

    <template v-if="data.DiscValue !== null || data.DiscPercent !== null">
      <tr>
        <td>&nbsp;</td>
        <td class="my-thermal-caption">
          Sub Total
        </td>
        <td class="my-thermal-value has-text-right">
         <kst-value field="TotalBruto" :data="data" :catalog="Catalog"/>
        </td>
      </tr>

      <tr>
        <td>&nbsp;</td>
        <td class="my-thermal-caption">
          <template v-if="data.DiscValue !== null">
            Diskon
          </template>
          <template v-if="data.DiscPercent !== null">
            Diskon ({{ data.DiscPercent }}%)
          </template>
        </td>
        <td class="my-thermal-value has-text-right">
          <kst-value field="TotalDisc" :data="data" :catalog="Catalog"/>
        </td>
      </tr>

      <tr>
        <td colspan="2">
        <td>
          <hr class="thermal-separator-total"/>
        </td>
      </tr>
    </template>

    <tr>
      <td class="my-thermal-indent pb-10">&nbsp;</td>
      <td class="my-thermal-caption my-thermal-total">
        Total
      </td>
      <td class="my-thermal-value my-thermal-total has-text-right">
        <kst-value field="Total" :data="data" :catalog="Catalog"/>
      </td>
    </tr>

    <tr>
      <td>&nbsp;</td>
      <td class="my-thermal-caption">
        Total Barang
      </td>
      <td class="my-thermal-value has-text-right">
        {{ totalItems }} item
      </td>
    </tr>

    <tr>
      <td colspan="2">
      <td>
        <hr class="thermal-separator-total"/>
      </td>
    </tr>

    <tr>
      <td class="pb-10">&nbsp;</td>
      <td class="my-thermal-caption">
        {{data.PaymentTypeName}}
      </td>
      <td class="my-thermal-value has-text-right">
        <kst-value field="PaymentValue" :data="data" :catalog="Catalog"/>
      </td>
    </tr>

    <tr>
      <td>&nbsp;</td>
      <td class="my-thermal-caption">
        Kembalian
      </td>
      <td class="my-thermal-value has-text-right">
        <kst-value field="PayReturn" :data="data" :catalog="Catalog"/>
      </td>
    </tr>
  </table>
</template>

<script>
import Model from "./PrintThermalModel.js";
import Catalog from "./PrintThermalCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    discTypeEnum: Model.DiscType,
    Catalog: Catalog
  }),
  computed: {
    totalItems() {
      let totalItems = 0;

      for (const item of this.items) {
        totalItems += item.TotalRequestedQuantity;
      }

      return totalItems;
    }
  },
}
</script>