import InputType from "@/services/Input/InputType.js";
import Catalog from "../StockReductionCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.ReductionNumber.Label,
    Type: InputType.Text,
    MaxLength: 50
  },
  Date: {
    Label: Catalog.Date.Label,
    Type: Catalog.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: Catalog.WarehouseID.Type,
    Input: Catalog.WarehouseID.Input
  }
}