export default {
  // returns value based on component initialization
  methods: {
    /*** class attribute ***/

    // pass value from :class="class_variable"
    getComp_Class() {
      return this.$vnode.data.class === undefined ? "" : this.$vnode.data.class;
    },
    // pass value from class="class_string"
    getComp_StaticClass() {
      return this.$vnode.data.staticClass === undefined ? "" : this.$vnode.data.staticClass;
    },
    getComp_Classes() {
      let classes = this.getComp_Class() + " " + this.getComp_StaticClass();
      return classes.trim();
    }
  }
}