<template>
  <kst-section items-mode class="mt-5">
    <SpecialPriceSearch
      ref="mdlSpecialPriceSearch"
      @submit="handleSpecialPrice_Submit"
    />
    <StockSearch
      ref="mdlStockSearch"
      @load="handleItem_Load"
      @submit="handleItem_Submit"
    />
    <DispatchSpecialPriceSearch
      ref="mdlDispatchSpecialPriceSearch"
      @load="handleDispatch_Load"
      @submit="handleDispatch_Submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <div class="mr-4">
          <kst-button reset-mode @click="handleReset"/>
        </div>

        <template v-if="userData.SpecialPriceCount > 0">
          <kst-button action-mode
            class="is-primary mr-4"
            tag="button"
            :label="Info.Button.SpecialPrice.Label"
            :moduleType="Model.SpecialPrice.ModuleType.New"
            @click="handleAdd_SpecialPrice"
          />
        </template>

        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.StockReductionItem.Module.FullName"
          :moduleType="Model.StockReductionItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <div class="mt-4">
          <template v-if="userData.SpecialPriceCount > 0">
            <kst-button action-mode
              class="is-primary mr-4"
              tag="button"
              :label="Info.Button.SpecialPrice.Label"
              :moduleType="Model.SpecialPrice.ModuleType.New"
              @click="handleAdd_SpecialPrice"
            />
          </template>

          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.StockReductionItem.Module.FullName"
            :moduleType="Model.StockReductionItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </div>
      </template>
    </template>

    <kst-warning class="mb-4" :items="itemsWarning"/>

    <template v-if="userData.WarehouseID !== null">
      <kst-failure class="mb-4" data-mode :message="failureItems"/>
      <List :userData="userData" :userItems="userItems"/>

      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="mt-4" :items="invalidItems"/>
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import DialogMixin from "@/mixins/DialogMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Catalog from "../StockReductionCatalog.js";
import Info from "../StockReductionInfo.js";
import Model from "../StockReductionModel.js";
import Services from "../StockReductionServices.js";

import DispatchSpecialPriceSearch from "@/components/DispatchSpecialPrice/Search/DSPSearch";
import List from "./StockReductionNew_Items_List";
import SpecialPriceSearch from "@/components/SpecialPrice/Search/SpecialPriceSearch";
import StockSearch from "@/components/Stock/Search/StockSearch";

export default {
  mixins: [AxiosMixin, DialogMixin, LoadingMixin, SnackbarMixin],
  components: {
    DispatchSpecialPriceSearch,
    List,
    SpecialPriceSearch,
    StockSearch
  },
  props: {
    specialPriceList: Array,
    specialPriceObj: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model,
    loadLoading: null,
    failureItems: null,
    rowIndex: null
  }),
  computed: {
    invalidItems() {
      return Model.getInvalidItems(this.userData);
    },
    itemsWarning() {
      if (this.userData.WarehouseID === null) {
        return [Info.Message.New_Items];
      }

      return null;
    }
  },
  methods: {
    loadDispatchSpecialPrice(specialPriceId) {
      this.loadLoading = this.openLoading();
      this.failureItems = null;

      const options = {
        warehouse: this.userData.WarehouseID,
        specialprice: specialPriceId,
        availstock: 1
      };
      const config = Services.getDispatchSpecialPriceList(options);
      this.runAxios(config, null,
        this.loadDispatchSpecialPrice_Success,
        this.loadDispatchSpecialPrice_Error
      );
    },
    loadDispatchSpecialPrice_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    loadDispatchSpecialPrice_Success(data) {
      const count = Model.setItemsByDispatchSpecialPrice(this.userItems, data);
      Model.updateDetails(this.userData, this.userItems);

      // handle immediate closed message when select All option
      this.$nextTick(() => {
        this.openSnackbar({
          message: count + Info.Message.New_InsertedItems,
          infoType: true
        });
      });

      this.loadDispatchSpecialPrice_End();
    },
    loadDispatchSpecialPrice_Error(message) {
      this.failureItems = message;
      this.loadDispatchSpecialPrice_End();
    },

    handleAdd_Item() {
      this.failureItems = null;

      if (this.userData.SpecialPriceCount === 0) {
        this.$refs.mdlStockSearch.show(this.userData.WarehouseID);
      }
      else {
        this.$refs.mdlDispatchSpecialPriceSearch.show(this.userData.WarehouseID);
      }
    },
    handleItem_Load(rows) {
      Model.updateItemsByStock(this.userItems, rows);
      Model.validateItemsByStock(this.userData, this.userItems);
    },
    handleItem_Submit(row) {
      if (Model.validateAddItemByStock(this.userItems, row)) {
        let item = Model.createItem();
        Model.setItemByStock(item, row);
        Model.updateItem(item);
        this.userItems.push(item);
        Model.updateDetails(this.userData, this.userItems);
      }
      else {
        this.openSnackbar({
          message: Info.Message.New_DuplicatedItem,
          errorType: true
        });
      }
    },
    handleDispatch_Load(rows) {
      Model.updateItemsByDispatchSpecialPrice(this.userItems, rows);
      Model.validateItemsByStock(this.userData, this.userItems);
    },
    handleDispatch_Submit(row) {
      if (Model.validateAddItemByDispatchSpecialPrice(this.userItems, row)) {
        let item = Model.createItem();
        Model.setItemByDispatchSpecialPrice(item, row);
        Model.updateItem(item);
        this.userItems.push(item);
        Model.updateDetails(this.userData, this.userItems);
      }
      else {
        this.openSnackbar({
          message: Info.Message.New_DuplicatedDispatchSpecialPrice,
          errorType: true
        });
      }
    },

    handleAdd_SpecialPrice() {
      this.failureItems = null;
      const excludeIdList = Model.getSpecialPrice_ExcludeIdList(this.userItems);
      this.$refs.mdlSpecialPriceSearch.show(excludeIdList, true);
    },
    handleSpecialPrice_Submit(row) {
      this.loadDispatchSpecialPrice(this.$kst.Search.getValue(row.ID));
    },

    handleReset() {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItems,
        this.handleReset_Confirm
      );
    },
    handleReset_Confirm() {
      this.$kst.Array.truncate(this.userItems);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },
  }
}
</script>