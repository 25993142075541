import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";

import Callback from "./Callback";

export default {
  Path: {
    Default: RouterModel.getPath(AppModule.Callback)
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Callback),
        name: RouterModel.getName(AppModule.Callback),
        component: Callback,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Callback),
          moduleName: RouterModel.getModule(AppModule.Callback)
        }
      }
    ];
  }
}