import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import ItemDetails from "./Details/ItemDetails";
import ItemEdit from "./Edit/ItemEdit";
import ItemList from "./List/ItemList";
import ItemNew from "./New/ItemNew";
import ItemExportDetails from "./ExportDetails/ItemExportDetails";
import ItemExportList from "./ExportList/ItemExportList";
import ItemSelect from "./Select/ItemSelect";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Item, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Item, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Item, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Item, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Item, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Item, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Item, AppModuleType.Print),
    Select: RouterModel.getPath(AppModule.Item, AppModuleType.Select),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Details),
        component: ItemDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Edit),
        component: ItemEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Item, AppModuleType.ExportDetails),
        component: ItemExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Item, AppModuleType.ExportList),
        component: ItemExportList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.ExportList),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.List),
        name: RouterModel.getName(AppModule.Item, AppModuleType.List),
        component: ItemList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.New),
        name: RouterModel.getName(AppModule.Item, AppModuleType.New),
        component: ItemNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Select),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Select),
        component: ItemSelect,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Select),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      /*
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Print),
        component: ItemPrint,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Print),
          moduleName: RouterModel.getModule(AppModule.Item)
        }
      }
      */
    ];
  }
}