const OPTION_ALL = -1;
const OPTION_NONE = -2;
const LABEL_ALL = "Semua ";
const LABEL_NONE = "Tanpa ";

export default {
  OptionAll: OPTION_ALL,
  OptionNone: OPTION_NONE,
  LabelAll: LABEL_ALL,
  LabelNone: LABEL_NONE,

  getValue(value, defaultValue) {
    return (value === OPTION_ALL || value === OPTION_NONE ? defaultValue : value);
  },

  /**
   * Create options list for a select field.
   * @param {Array} items
   * @param {Object} options
   * options keys:
   * (required)
   *  {String} id - key in items which will be used as value of selected item
   *  {String} label - key in items which is used as label in selection
   * (optional)
   *  {Boolean} allActive - set true to add ALL option as first option in selection
   *  {String} allText - label of ALL option (required if you set allActive as true)
   */
  createList(items, options) {
    if (options === undefined) {
      options = {};
    }

    const { id, label, allActive, allText, nonActive, nonText } = options;
    let filterItems = [];

    if (allActive) {
      filterItems.push({
        [id]: OPTION_ALL,
        [label]: LABEL_ALL + allText
      });
    }
    if (nonActive) {
      filterItems.push({
        [id]: OPTION_NONE,
        [label]: LABEL_NONE + nonText
      });
    }

    return filterItems.concat(items);
  }
}