import AppModule from "@/services/App/AppModule.js";
import Catalog from "./SpecialPriceAdjCatalog.js";
import Model from "./SpecialPriceAdjModel.js";

export default {
  Message: {
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.Item.Module.FullName + ".",
    New_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label,
    New_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.Item.Module.FullName + ".",
    New_ConfirmDeleteItem:
      "Yakin ingin menghapus " + AppModule.Item.FullName + "?",
    Invalid_Barcode:
      "Barcode tidak sesuai"
  },
  Scanner: {
    Title: "Barcode Scanner"
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}