import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  TitleItem: {
    Label: "Data Barang",
    Type: InputType.Text
  },
  TitleReport: {
    Label: "Data Penjualan",
    Type: InputType.Text
  },
  TitleTransaction: {
    Label: "Transaksi",
    Type: InputType.Text
  },
  TitleListTransaction: {
    Label: "Daftar Transaksi",
    Type: InputType.Text
  },
  Invoice: {
    Label: "Invoice",
    ExportLabel: "Total Qty Jual",
    Type: InputType.Integer
  },
  Purchase: {
    Label: "Pembelian",
    ExportLabel: "Total Qty Terima",
    Type: InputType.Integer
  },
  StockAdj: {
    Label: "Stok Opname",
    ExportLabel: "Total Qty Stok Opname",
    Type: InputType.Integer
  },
  StockRed: {
    Label: "Pengeluaran Stock",
    ExportLabel: "Total Qty Pengeluaran Stock",
    Type: InputType.Integer
  },
  Name: {
    Label: "Nama Barang",
    Type: InputType.Text,
    ReadOnly: true,
  },
  Quantity: {
    Label: "Total Stok",
    Type: InputType.Integer,
    Required: true,
  },
  Stock: {
    Label: "Total Stok",
    Type: InputType.Integer,
    Required: true,
  },
  Date: {
    Label: "Periode Ringkasan",
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    RelatedValue: "WarehouseName",
    RelatedIsActive: "IsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    Active: true
  },
  TotalHpp: {
    Label: "Nilai Stok",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  HppTotal: {
    Label: "Nilai Stok",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  }
}