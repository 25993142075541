import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";

import Pos from "./Pos";

export default {
  Path: {
    New: RouterModel.getPath(AppModule.Pos),
  },

  Dashboard: {
    Path: RouterModel.getPath(AppModule.Dashboard)
  },

  PrintThermal: {
    Path: RouterModel.getPath(AppModule.PrintThermal)
  },

  getRoutes() {
    return [];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Pos),
        name: RouterModel.getName(AppModule.Pos),
        component: Pos,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Pos),
          moduleName: RouterModel.getModule(AppModule.Pos)
        }
      }
    ];
  }
}