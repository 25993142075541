import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";

export default {
  SOID: {
    Label: "ID",
    Type: InputType.Integer,
    ReadOnly: true
  },
  OrderDate: {
    Label: "Tanggal",
    PrintLabel: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  SONumber: {
    Label: "Nomor SO",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Client.FullName,
    PrintLabel: "Nama " + AppModule.Client.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Pelanggan, atau ketik manual.",
      "Pelanggan tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  ClientName: {
    Label: "Pelanggan",
    Type: InputType.Text,
    MaxLength: 500,
    Required: true,
    Highlight: true
  },
  DispatchName: {
    Label: "Nama Barang",
    Type: InputType.Text,
    MaxLength: 255
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Text,
    MaxLength: 45
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  QtySO: {
    Label: "Qty SO",
    Type: InputType.Integer,
    MaxValue: 0
  },
  Qty: {
    Label: "Stok",
    Type: InputType.Integer,
    MaxValue: 0
  },
  QtyDiff: {
    Label: "Kekurangan Stok",
    Type: InputType.Integer,
    MaxValue: 0
  }
}