<template>
  <div v-if="theVisible" class="kst-failure" :class="theClass">
    <b-message has-icon type="is-warning">
      <div class="title is-5 has-text-warning-dark mb-1">
        {{ theTitle }}
      </div>
      <template v-if="!simpleMode">
        <div>
          {{ AppCatalog.Failure.Hint }}
        </div>
        <kst-divider failure-mode/>
      </template>

      <div class="content">
        {{ AppCatalog.Failure.MessageTitle }}
        <span class="has-text-black">
          {{ theMessage }}
        </span>
      </div>
    </b-message>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    message: String,
    // mode: ONLY select 1 (one)
    dataMode: Boolean,
    initMode: Boolean,
    listMode: Boolean,
    saveMode: Boolean,
    // additional mode
    simpleMode: Boolean
  },
  data: () => ({
    AppCatalog: AppCatalog
  }),
  computed: {
    theClass() {
      return this.simpleMode ? "kst-failure-simple " : undefined;
    },
    theMessage() {
      return this.getMessage();
    },
    theTitle() {
      return this.getTitle();
    },
    theVisible() {
      return this.getVisible();
    }
  },
  methods: {
    getMessage() {
      return this.message;
    },
    getTitle() {
      if (this.dataMode) {
        return AppCatalog.Failure.TitleData;
      }
      else if (this.initMode) {
        return AppCatalog.Failure.TitleInit;
      }
      else if (this.listMode) {
        return AppCatalog.Failure.TitleList;
      }

      // default: use saveMode
      return AppCatalog.Failure.TitleSave;
    },
    getVisible() {
      if (this.message === undefined) {
        return false;
      }
      else if (this.message === null) {
        return false;
      }
      else if (this.message.trim() === "") {
        return false;
      }

      return true;
    }
  }
}
</script>