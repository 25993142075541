import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true,
    isRange: false,
    Output: OutputType.Date.ShortDate
  },
  TransactionName: {
    Label: "Jenis",
    Type: InputType.Text,
    ReadOnly: true
  },
  Profit: {
    Label: "Total",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
}