<template>
  <div class="kst-report-result">
    <div class="columns" v-if="isListLoading">
      <div class="column">
        <kst-skeleton :active="isListLoading"/>
      </div>
    </div>
    <div class="columns" v-if="!isListLoading">
      <div class="column kst-report-item">
        <Summary
          :data="data"
          :icon="Info.Stock.Icon"
          :iconStyle="Info.Stock.IconStyle"
          :iconBgStyle="Info.Stock.IconBgStyle"
          :title="Info.Stock.TitleSection"
        />
      </div>
      <div class="column kst-report-item">
        <ResultChart :chartData="chartData"/>
      </div>
    </div>

    <kst-section :title="Info.Tabs.Details.Label" v-if="!isListLoading">
      <template #top-right>
        <kst-button action-mode
          class="is-primary mr-4"
          :module="Model.Module"
          :moduleType="Model.ModuleType.Print"
        />
        <kst-button action-mode
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.Export"
        />
      </template>
      <ResultDetails :items="detailsList"/>
    </kst-section>
  </div>
</template>

<script>
import 'moment/locale/id';
import ResultChart from "./StockAdj_Result_Chart";
import Summary from "./StockAdj_Summary";
import ResultDetails from "./StockAdj_Result_Details";
import Info from "./StockAdjInfo";
import Model from "./StockAdjModel";

export default {
  components: {
    ResultChart,
    Summary,
    ResultDetails
  },
  props: {
    data: Object,
    searchData: Object,
    summaryList: Array,
    chartData: Object,
    detailsList: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Info: Info,
    isDaily: false,
    isViewChart: true,
    Model:Model
  })
}
</script>