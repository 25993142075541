<template>
  <div class="kst-image-container is-relative ks-flex-center" :class="theClass">
    <template v-if="!readOnly">
      <kst-tooltip delete-image-mode class="kst-image-delete">
        <kst-button clear-mode is-round
          class="has-text-danger ks-borderless"
          @click="handleDelete"
        />
      </kst-tooltip>
    </template>

    <ik-image class="kst-image-preview" :path="thePath" @click.native="handleClick"/>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    borderOff: Boolean,
    readOnly: Boolean,
    sizeAuto: Boolean,
    thumbnail: Boolean
  },
  computed: {
    theClass() {
      let className = "";

      if (!this.borderOff) {
        className += "kst-image-border ";
      }
      if (!this.sizeAuto) {
        className += "kst-image-size ";
      }

      return className;
    },
    thePath() {
      return this.thumbnail ? "tr:n-media_library_thumbnail/" + this.src : this.src;
    }
  },
  methods: {
    handleDelete() {
      this.$emit(this.$kst.Enum.Event.Delete);
    },
    handleClick() {
      this.$emit(this.$kst.Enum.Event.Click);
    }
  }
}
</script>