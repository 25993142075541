<template>
  <List
    :title="title"
    :titleButton="'Lihat Laporan'"
    :url="Router.Path.Main"
    :urlParams="urlParams"
  >
    <kst-table view-mode
        :data="data"
        :failure="failureList"
        :loading="isListLoading"
      >
      <b-table-column sortable
        v-slot="{ column, row }"
        field="Name"
        :label="Catalog.Name.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>

      <b-table-column sortable
        v-slot="{ column, row }"
        field="RequestedQuantity"
        :label="Catalog.RequestedQuantity.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>

      <b-table-column sortable
        v-slot="{ column, row }"
        field="Packaging"
        :label="Catalog.Packaging.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </kst-table>
  </List>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Catalog from "../RptBestSellingItemsCatalog.js";
import Model from "../RptBestSellingItemsModel.js";
import Services from "../RptBestSellingItemsServices.js";
import Router from "../RptBestSellingItemsRouter.js";

import List from "@/components/Dashboard/Dashboard_List";

export default {
  mixins: [AxiosMixin],
  components: {
    List
  },
  props: {
    datePeriod: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    Router: Router,
    data: [],
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return Model.Module.FullName;
    },
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.StartDate]: this.$kst.Out.getDate(this.datePeriod[0]),
        [this.$kst.Enum.Param.EndDate]: this.$kst.Out.getDate(this.datePeriod[1])
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {

      this.loadCounter++;
      this.loadBestSellingItems(this.loadCounter);
    },

    loadBestSellingItems(counter) {
      const options = {
        StartDate: this.datePeriod[0],
        EndDate: this.datePeriod[1],
        Limit: Model.Dashboard.Limit
      }

      const config = Services.getBestSellingItems(options);
      this.runAxios(config, counter, this.loadBestSellingItems_Success, this.loadBestSellingItems_Error);
    },

    loadBestSellingItems_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.data = data;

      for (const item of this.data) {
        Model.updateItemByPackaging(item);
      }

      this.isListLoading = false;
    },
    loadBestSellingItems_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },

  }
}
</script>