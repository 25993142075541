<template>
  <div class="ml-2 is-block">
    <div class="is-size-7 mb-1 ks-line-height ks-color-grey has-text-right">
      {{ Info.Welcome }}
    </div>
    <div class="is-size-6	has-text-weight-semibold ks-line-height has-text-right">
      <button
        class="button p-0 has-text-weight-bold ks-borderless ks-height-auto"
        @click="handleLogout"
      >
        {{ userName }}
      </button>
    </div>
    <div class="is-size-6	ks-line-height has-text-right">
      {{ companyName }}
    </div>
  </div>
</template>

<script>
import Info from "./LayoutInfo.js";

export default {
  data: () => ({
    Info: Info
  }),
  computed: {
    companyName() {
      return this.$kst.Session.getCompanyName();
    },
    userName() {
      return this.$kst.Session.getName();
    }
  },
  methods: {
    handleLogout() {
      this.$router.app.signOut();
    }
  }
}
</script>

<style>

</style>