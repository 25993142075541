import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Active: true
  },
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    Required: true,
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  Quantity: {
    Label: "Qty Terima",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true,
    Valid: true
  },
  PurchasingItemQty: {
    Label: "Qty Pesan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Select,
    Active: true,
    Exist: true,
    Unique: true,
    Static: true,
    Required: true
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    MinValue: 1,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  ExpenseAmount: {
    Label: "Sub Total",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  PackagingValue: {
    Label: "Jumlah Kemasan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  VendorID: {
    Label: "Vendor",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor,
    RelatedIsActive: "VendorIsActive"
  },
  VendorName: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 200
  },
  ImageSrc: {
    Label: "Gambar",
    Type: InputType.Text,
    ReadOnly: true
  },
  Barcode: {
    Label: "Barcode [F1]",
    Type: InputType.Text,
    MaxLength: 50
  },
  Qty: {
    Label: "Qty Terima [F2]",
    Type: InputType.Qty
  }
}