import App from "../../../services/App/App.js";
import AppModule from "../../../services/App/AppModule";
import AppModuleType from "@/services/App/AppModuleType";
// import Catalog from "./ExpenseSubCategoryCatalog";

export default {
  Module: AppModule.ExpenseCategory,
  ModuleType: {
    New: AppModuleType.New,
    Edit: AppModuleType.Edit,
    PrintList: AppModuleType.PrintList,
    ExportList: AppModuleType.ExportList
  },

  ExpenseSubCategory: {
    TitleExpenseSubCategory: AppModule.ExpenseCategory.Name
  },

  IsActive: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    },
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    }
  },

  Search: {
    Fields: ["Name"]
  },
  createDetails() {
    return this.helper.createDetails(this.IsActive);
  },
  createOptions(items, optionAllText) {
    return this.helper.createOptions(items, optionAllText);
  },
  createStatusOptions(optionAllText) {
    return this.helper.createStatusOptions(optionAllText, this.IsActive);
  },
  createCategoryOptions(items, optionAllText) {
    return this.helper.createOptions(items, optionAllText);
  },
  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },

  helper: {
    createDetails(statusEnum) {
      return {
        CompanyID: App.Session.getCompanyID(),
        CategoryID: null,
        Name: "",
        IsActive: App.In.getBoolean(statusEnum.Active.ID)
      };
    },
    createOptions(items, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";
  
      let listOptions = { id: rowId, label: rowLabel }
  
      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }
  
      // create: select items
      const selectItems = App.Search.createList(items, listOptions);
  
      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
    createStatusOptions(optionAllText, statusEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: statusEnum.Active.ID, [rowLabel]: statusEnum.Active.Label },
        { [rowId]: statusEnum.InActive.ID, [rowLabel]: statusEnum.InActive.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.CategoryID = App.In.getInteger(details.CategoryID);
      data.Name = App.In.getString(details.Name);
      data.IsActive = App.In.getBoolean(details.IsActive);
    }
  },
}