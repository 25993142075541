import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import StockOpnameDetails from "./Details/StockOpnameDetails";
import StockOpnameList from "./List/StockOpnameList";
import StockOpnameNew from "./New/StockOpnameNew";
import StockOpnamePrintDetails from "./PrintDetails/StockOpnamePrintDetails";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockOpname, AppModuleType.Details),
    List: RouterModel.getPath(AppModule.StockOpname, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockOpname, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.Details),
        component: StockOpnameDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.StockOpname)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.List),
        component: StockOpnameList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.StockOpname)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.New),
        component: StockOpnameNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.StockOpname)
        }
      },
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.PrintDetails),
        component: StockOpnamePrintDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.PrintDetails),
          moduleName: RouterModel.getModule(AppModule.StockOpname)
        }
      }
    ];
  }
}