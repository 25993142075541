<template>
  <kst-container class="kst-item-export-list">
    <kst-form
      :failure="failureList"
      :title="title"
    >
      <Export
        ref="refExport"
        :searchData="searchData"
        @error="handleError"
      />

      <kst-section export-mode>
        <kst-check disabled v-model="searchData.WithList" :label="labelOptList"/>
        <kst-check v-model="searchData.WithReport" :label="labelOptReport"/>
        <kst-check v-model="searchData.WithListTransaction" :label="labelOptListTransaction"/>
      </kst-section>
      
      <template #bottom-left>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.Module"
          :moduleType="Model.ModuleType.Export"
          @click="handleExport"
        />
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../RptStockCardInfo.js";
import Model from "../RptStockCardModel.js";

import Export from "./RptStockCardExport_Export";

export default {
  mixins: [RouterMixin],
  components: {
    Export
  },
  data: () => ({
    Model: Model,
    searchData: {},
    // error
    failureList: null,
  }),
  computed: {
    labelOptList() {
      return Info.Export.List.Excel.Option.List;
    },
    labelOptReport() {
      return Info.Export.List.Excel.Option.Report;
    },
    labelOptListTransaction() {
      return Info.Export.List.Excel.Option.ListTransaction;
    },
    title() {
      return Info.Export.List.Excel.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
    },
    initSearch() {
      this.searchData = {
        Date: [
          this.getUrlParam(this.$kst.Enum.Param.StartDate),
          this.getUrlParam(this.$kst.Enum.Param.EndDate)
        ],
        WarehouseID: this.getUrlParam(this.$kst.Enum.Param.WarehouseID),
        DispatchID: this.getUrlParam(this.$kst.Enum.Param.DispatchID),
        WithList: true,
        WithReport: true,
        WithListTransaction: true,
      }
    },
  
    handleError(message) {
      this.failureList = message;
    },
    handleExport() {
      this.failureList = null;
      this.$refs.refExport.exportExcel();
    }
  }
}
</script>