<template>
  <div>
    <kst-output field="format" :data="data.type" :catalog="Catalog"/>
    <template v-if="isFieldVisible('prefix')">
      <kst-output field="prefix" :data="data" :catalog="Catalog"/>
    </template>
    <template v-if="isFieldVisible('suffix')">
      <kst-output field="suffix" :data="data" :catalog="Catalog"/>
    </template>
    <kst-output field="year" :data="data" :catalog="Catalog"/>
    <kst-output field="month" :data="data" :catalog="Catalog"/>
    <kst-output field="counter" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvoiceSettingCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    isFieldVisible(fieldName) {
      return (this.data.type.format.includes(fieldName) ? true : false);
    }
  }
}
</script>