export default {
  StockLabelDetail: {
    Label: "Pergerakan Stok",
    BackgroundColor: "#42b983"
  },
  Stock: {
    Title: "Total Stok Opname",
    TitleSection: "Total Stok Opname",
    Icon: "mdi mdi-36px mdi-poll ",
    IconStyle: "kst-dashboard-sales ",
    IconBgStyle: "kst-dashboard-sales-bg ",
    Field: "Total",
    Help: {
      Icon: "mdi mdi-calendar-clock",
      Title: "Last 24 Hours",
      Style: "kst-dashboard-sales-help "
    }
  },
  Tabs: {
    Details: {
        Label: "Rincian"
    }
  },
}