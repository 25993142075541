import StockOpnameServices from "@/services/StockOpnameServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import ItemServices from "@/services/ItemServices";

export default {
  getStockOpnameDetailsData: StockOpnameServices.getDetailsData,
  getStockOpnameList: StockOpnameServices.getList,
  getStockOpnameListData: StockOpnameServices.getListData,
  postStockOpname: StockOpnameServices.postData,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // load packaging
  getPackagingList: ItemServices.loadPackaging,
}