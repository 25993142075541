<template>
  <kst-table action paginated view-mode
    :data="items"
    :loading="isListLoading"
    :failure="failureList"
  >
    <b-table-column centered sortable
      cell-class="ks-col-shortdate"
      v-slot="{ column, row }"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
    
    <b-table-column sortable
      v-slot="{ column, row }"
      field="AdjNumber"
      :label="Catalog.AdjNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :row="row"/>
    </template>
  </kst-table>
</template>
<script>
import Catalog from "../SpecialPriceAdjCatalog.js";
import Action from "./SpecialPriceAdjList_Action";

export default {
  components: {
    Action
  },
  props: {
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>