<template>
  <kst-print class="kst-report-dailyreport-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData"/>
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>

    </template>
    <Items class="mt-4" :items="data.dispatchStockList"/>
    <Total class="my-2" :data="data" />
  </kst-print>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../DailyReportInfo.js";
import Model from "../DailyReportModel.js";
import Services from "../DailyReportServices.js";

import Company from "./DailyReportPrint_Company";
import Items from "./DailyReportPrint_Items";
import Total from "./DailyReportPrint_Total";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Items,
    Total,
  },
  data: () => ({
    data: {},
    companyData: {},
    isLoading: true,
    // error
    error: null
  }),
  computed: {
    timestamps() {
      return "Dicetak pada: " + this.$kst.View.getTimestamp();
    },
    title() {
      return Model.Module.FullName;
    }
  },
  created(){
    this.init();
  },
  methods: {
    init() {
      let searchData = {
        WarehouseID: this.getUrlParam(this.$kst.Enum.Param.WarehouseID),
        Date: this.getUrlParam(this.$kst.Enum.Param.Date),
      };

      this.error = null;

      if (!searchData) {
        this.error = Info.Message.PrintDetails_ParamIncomplete;
        return;
      }

      this.data = {};
      this.companyData = {};

      this.initLoad(searchData);
    },

    initLoad_End() {
      this.isLoading = false;
    },
    initLoad(searchData) {
      let options = {};
      if (searchData) {
        options.warehouse = searchData.WarehouseID;
        options.date = searchData.Date;
      }

      const config = Services.getDailyReport(options);
      this.runAxios(config, null, this.initLoad_Success, this.initLoad_Error, this.initLoad_Invalid);
    },
    initLoad_Success(data) {
      this.data = Model.getProcessData(data);
      this.loadCompanyDetails();
    },
    initLoad_Error(message) {
      this.error = message;
      this.initLoad_End();
    },
    initLoad_Invalid(message) {
      this.error = message;
      this.initLoad_End();
    },

    loadCompanyDetails() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompanyDetails_Success, this.loadCompanyDetails_Error, this.loadCompanyDetails_Invalid);
    },
    loadCompanyDetails_Success(data) {
      this.companyData = data.data;
      this.initLoad_End();
    },
    loadCompanyDetails_Error(message) {
      this.errors = message;
      this.initLoad_End();
    },
    loadCompanyDetails_Invalid(message) {
      this.errors = message;
      this.initLoad_End();
    },

  }
}
</script>

<style lang="css" src="./_layout.css"/>