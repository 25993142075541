import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.receive;
import App from "./App/App.js";


export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "receive", "purchase", "warehouse", "field", "startDate", "endDate", "vendor"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/receiving/list" + urlParams
    };
  },
  getListData() {
    let options = {
      cid: App.Session.getCompanyID(),
      uid: App.Session.getUserID()
    };
    const attributes = ["cid", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/receiving/listdata" + urlParams
    };
  },
  getDetails(id, params) {
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["withpackagings"];
    const urlParams = App.Out.getParams(options, attributes);

    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/receiving/" + cid + "/" + id + urlParams
    };
  },
  postReceive(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/receiving",
      data: data
    };
  },
  putReceive(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/receiving",
      data: data
    };
  },
  getListByPurchasing(purchaseId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/receiving/purchase/" + purchaseId
    };
  },

  /* buy price */

  
  getPriceList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "item", "vendorid", "vendor", "name", "excludeid", "startDate", "endDate"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/receiving/price" + urlParams
    };
  },
  
}