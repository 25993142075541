<template>
  <div>
    <kst-section :title="sectionTitle" class="mt-5">
      <kst-warning v-if="!data.PurchasingID" :items="dataWarning"/>

      <kst-output v-if="data.PurchasingID" field="PurchasingID" :data="data" :catalog="Catalog"/>
      <kst-output v-if="data.PurchasingID" field="PurchasingDate" :data="data" :catalog="Catalog"/>
    </kst-section>

    <template v-if="data.VendorID">
      <kst-output field="VendorID" :data="data" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="VendorAlias" :data="data" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="VendorName" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output field="VendorName" :data="data" :catalog="Catalog"/>
    </template>

    <kst-output field="VendorAddress" :disabled="data.VendorID !== null" :data="data" :catalog="Catalog"/>

    <kst-section :title="sectionInfo" class="mt-5">
      <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
      <kst-output field="ReceiveNumber" :data="data" :catalog="Catalog"/>
      <kst-output field="Date" :data="data" :catalog="Catalog"/>
      <kst-output field="Comment" :data="data" :catalog="Catalog"/>

      <kst-output control-off field="CreatedBy" :data="data" :catalog="Catalog">
        <template #default>
          <kst-output expanded floating-label
            class="pr-2"
            field="CreatedByID" :data="data" :catalog="Catalog"
          />
        </template>
        <template #right>
          <kst-output addons expanded floating-label
            field="CreatedDate" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
      <kst-output control-off field="LastUpdatedBy" :data="data" :catalog="Catalog">
        <template #default>
          <kst-output expanded floating-label
            class="pr-2"
            field="LastUpdatedByID" :data="data" :catalog="Catalog"
          />
        </template>
        <template #right>
          <kst-output addons expanded floating-label
            field="LastUpdatedDate" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../ReceiveCatalog.js";
import ReceiveItemCatalog from "../ReceiveItemCatalog.js";
import Info from "../ReceiveInfo.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog,
    ReceiveItemCatalog: ReceiveItemCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.PO.Title;
    },
    sectionInfo() {
      return Info.Section.InfoPO.Title;
    },
    dataWarning() {
      return ["Penerimaan Stok ini tidak menggunakan Pembelian Stok (PO)"];
    }
  }
}
</script>