import axios from "axios";
import mgr from "./AuthService.js";
import App from "../App/App.js";
import AccessServices from "../AccessServices.js";

var apiEndpoint = process.env.VUE_APP_TOKO_API;

export default {
  async getUserSub(component) {
    await mgr.getUser().then(
      async(user) => {
        /** original code
        App.Session.setCompanyID(user.profile.CompanyID);
        App.Session.setName(user.profile.Name);
        App.Session.setUserID(user.profile.UserID);

        const options = { withmodules: 1 };
        await AccessServices.getUserAccess(component, options).then(response => {
          App.Session.setAccessID(response.data.AccessID);
          App.Session.setDefaultMenu(response.data.DefaultModule);
          App.Session.setMenu(response.data.ModuleList);
        });
        **/

        if (user === undefined || user === null) {
          App.Session.clear();
        }
        else {
          App.Session.setCompanyID(user.profile.CompanyID);
          App.Session.setName(user.profile.Name);
          App.Session.setUserID(user.profile.UserID);

          const options = { withmodules: 1 };
          await AccessServices.getUserAccess(component, options).then(response => {
            App.Session.setCompanyName(response.data.CompanyName);
            App.Session.setCompanyImageSrc(response.data.CompanyImageSrc);
            App.Session.setAccessID(response.data.AccessID);
            App.Session.setDefaultMenu(response.data.DefaultModule);
            App.Session.setMenu(response.data.ModuleList);
          });
        }
      }
    )
  },
  getUserDetails() {
    this.getUserSub();
    const endpoint = apiEndpoint + "/api/user/" + App.Session.getCompanyID();
    const options = { params: {} };
    var configPost = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + App.Session.getToken()
      }
    };

    var result = axios.get(endpoint, options, configPost);

    return result;
  },
  isAuthenticated(router, to) {
    let app = router.app.$data || { isAuthenticated: false };

    const accessID = App.Session.getAccessID();
    let isRequiresAuth = false;

    if (to.matched.some(record => record.meta.requiresAuth)) {
      isRequiresAuth = true;
    }

    if (!app.isAuthenticated) {
      if (isRequiresAuth) {
        return false;
      }
    }

    if (accessID === null) {
      if (isRequiresAuth) {
        return false;
      }
    }

    return true;
  }
}