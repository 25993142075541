import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 100,
    Required: true
  },
  Mobile: {
    Label: "HP",
    Type: InputType.Phone,
    MaxLength: 15
  },
  Phone: {
    Label: "Telp",
    Type: InputType.Phone,
    MaxLength: 15
  },
  Email: {
    Label: "Email",
    Type: InputType.Email,
    MaxLength: 50
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  // support
  Alias: {
    Label: "Alias",
    Type: InputType.Text,
    MaxLength: 20
  },
  ClientName: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true,
    Highlight: true
  },
  TotalOpen: {
    Label: "Total Open",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalClosed: {
    Label: "Total Closed",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  GrandTotal: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  }
}