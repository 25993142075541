import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import RouterModel from "@/router/RouterModel.js";
import Model from "./LayoutModel.js";

export default {
  getList() {
    let result = [
      {
        ID: 1,
        Code: AppModule.Dashboard.Code,
        Label: Model.getMenuLabel(AppModule.Dashboard),
        Icon: "mdi mdi-view-dashboard ",
        Path: RouterModel.getPath(AppModule.Dashboard)
      },
      {
        ID: 2,
        Label: Model.getMenuLabel(AppModule.Report),
        Icon: "mdi mdi-file-document ",
        Items: [
          {
            ID: 21,
            Code: AppModule.StockCard.Code,
            Label: Model.getMenuLabel(AppModule.StockCard),
            Icon: "mdi mdi-file-document ",
            Path: RouterModel.getPath(AppModule.StockCard)
          },
          {
            ID: 22,
            Code: AppModule.ProfitLoss.Code,
            Label: Model.getMenuLabel(AppModule.ProfitLoss),
            Icon: "mdi mdi-file-document ",
            Path: RouterModel.getPath(AppModule.ProfitLoss)
          },
          {
            ID: 23,
            Code: AppModule.Omzet.Code,
            Label: Model.getMenuLabel(AppModule.Omzet),
            Icon: "mdi mdi-file-document ",
            Path: RouterModel.getPath(AppModule.Omzet)
          },
          {
            ID: 25,
            Code: AppModule.Transaction.Code,
            Label: Model.getMenuLabel(AppModule.Transaction),
            Icon: "mdi mdi-file-document ",
            Path: RouterModel.getPath(AppModule.Transaction)
          },
          {
            ID: 26,
            Code: AppModule.DailyReport.Code,
            Label: Model.getMenuLabel(AppModule.DailyReport),
            Icon: "mdi mdi-file-document ",
            Path: RouterModel.getPath(AppModule.DailyReport)
          }
        ]
      },
      {
        ID: 9,
        Label: Model.getMenuLabel(AppModule.Pos),
        Icon: "mdi mdi-view-dashboard",
        Path: RouterModel.getPath(AppModule.Pos)
      }, 
      {
        ID: 7,
        Label: Model.getMenuLabel(AppModule.Finance),
        Icon: "mdi mdi-view-dashboard ",
        Items: [
          {
            ID: 71,
            Code: AppModule.Expense.Code,
            Label: Model.getMenuLabel(AppModule.Expense),
            Icon: "mdi mdi-view-dashboard ",
            Path: RouterModel.getPath(AppModule.Expense)
          }
        ]
      },
      {
        ID: 3,
        Label: Model.getMenuLabel(AppModule.Inventaris),
        Icon: "mdi mdi-view-dashboard ",
        Items: [
          {
            ID: 31,
            Code: AppModule.Item.Code,
            Label: Model.getMenuLabel(AppModule.Item),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.Item)
          },
          {
            ID: 32,
            Code: AppModule.StockOpname.Code,
            Label: Model.getMenuLabel(AppModule.StockOpname),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.StockOpname)
          },
          {
            ID: 38,
            Code: AppModule.StockReduction.Code,
            Label: Model.getMenuLabel(AppModule.StockReduction),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.StockReduction)
          },
          {
            ID: 33,
            Code: AppModule.StockTrf.Code,
            Label: Model.getMenuLabel(AppModule.StockTrf),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.StockTrf)
          },
          {
            ID: 35,
            Code: AppModule.StockClosing.Code,
            Label: Model.getMenuLabel(AppModule.StockClosing),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List)
          },
          {
            ID: 34,
            Code: AppModule.HppAdj.Code,
            Label: Model.getMenuLabel(AppModule.HppAdj),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.HppAdj, AppModuleType.List)
          },
          {
            ID: 36,
            Code: AppModule.StockAvailability.Code,
            Label: Model.getMenuLabel(AppModule.StockAvailability),
            Icon: "mdi mdi-file-document ",
            Path: RouterModel.getPath(AppModule.StockAvailability)
          },
          {
            ID: 37,
            Code: AppModule.SpecialPriceAdj.Code,
            Label: Model.getMenuLabel(AppModule.SpecialPriceAdj),
            Icon: "mdi mdi-cube ",
            Path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List)
          }
        ]
      },
      {
        ID: 4,
        Label: Model.getMenuLabel(AppModule.Buy),
        Icon: "mdi mdi-view-dashboard ",
        Items: [
          {
            ID: 41,
            Code: AppModule.Purchase.Code,
            Label: Model.getMenuLabel(AppModule.Purchase),
            Icon: "mdi mdi-tag-text ",
            Path: RouterModel.getPath(AppModule.Purchase)
          },
          {
            ID: 42,
            Code: AppModule.Receive.Code,
            Label: Model.getMenuLabel(AppModule.Receive),
            Icon: "mdi mdi-view-dashboard ",
            Path: RouterModel.getPath(AppModule.Receive)
          }
        ]
      },
      {
        ID: 5,
        Label: Model.getMenuLabel(AppModule.Sell),
        Icon: "mdi mdi-view-dashboard ",
        Items: [
          {
            ID: 51,
            Code: AppModule.Sales.Code,
            Label: Model.getMenuLabel(AppModule.Sales),
            Icon: "mdi mdi-view-dashboard ",
            Path: RouterModel.getPath(AppModule.Sales)
          },
          {
            ID: 52,
            Code: AppModule.Invoice.Code,
            Label: Model.getMenuLabel(AppModule.Invoice),
            Icon: "mdi mdi-script-text ",
            Path: RouterModel.getPath(AppModule.Invoice)
          },
          {
            ID: 53,
            Code: AppModule.RtnInvoice.Code,
            Label: Model.getMenuLabel(AppModule.RtnInvoice),
            Icon: "mdi mdi-view-dashboard ",
            Path: RouterModel.getPath(AppModule.RtnInvoice)
          },
          {
            ID: 54,
            Code: AppModule.Delivery.Code,
            Label: Model.getMenuLabel(AppModule.Delivery),
            Icon: "mdi mdi-clipboard-check ",
            Path: RouterModel.getPath(AppModule.Delivery)
          },
          {
            ID: 55,
            Code: AppModule.SalesRecap.Code,
            Label: Model.getMenuLabel(AppModule.SalesRecap),
            Icon: "mdi mdi-clipboard-check ",
            Path: RouterModel.getPath(AppModule.SalesRecap)
          },
        ]
      },
      {
        ID: 6,
        Label: Model.getMenuLabel(AppModule.Contact),
        Icon: "mdi mdi-view-dashboard ",
        Items: [
          {
            ID: 61,
            Code: AppModule.Vendor.Code,
            Label: Model.getMenuLabel(AppModule.Vendor),
            Icon: "mdi mdi-view-dashboard ",
            Path: RouterModel.getPath(AppModule.Vendor)
          },
          {
            ID: 62,
            Code: AppModule.Client.Code,
            Label: Model.getMenuLabel(AppModule.Client),
            Icon: "mdi mdi-view-dashboard ",
            Path: RouterModel.getPath(AppModule.Client)
          },
          {
            ID: 63,
            Code: AppModule.Warehouse.Code,
            Label: Model.getMenuLabel(AppModule.Warehouse),
            Icon: "mdi mdi-domain ",
            Path: RouterModel.getPath(AppModule.Warehouse)
          },
        ]
      },
      {
        ID: 8,
        Code: AppModule.Settings.Code,
        Label: Model.getMenuLabel(AppModule.Settings),
        Icon: "mdi mdi-view-dashboard",
        Items: [
          {
            ID: 81,
            Code: AppModule.CompanyProfile.Code,
            Label: Model.getMenuLabel(AppModule.CompanyProfile),
            Icon: "mdi mdi-domain ",
            Path: RouterModel.getPath(AppModule.CompanyProfile)
          },
          {
            ID: 82,
            Code: AppModule.AutoNumber.Code,
            Label: Model.getMenuLabel(AppModule.AutoNumber),
            Icon: "mdi mdi-domain ",
            Path: RouterModel.getPath(AppModule.AutoNumber)
          },
          {
            ID: 83,
            Code: AppModule.ExpenseCategory.Code,
            Label: Model.getMenuLabel(AppModule.ExpenseCategory),
            Icon: "mdi mdi-domain ",
            Path: RouterModel.getPath(AppModule.ExpenseCategory)
          },
          {
            ID: 84,
            Code: AppModule.SpecialPrice.Code,
            Label: Model.getMenuLabel(AppModule.SpecialPrice),
            Icon: "mdi mdi-domain ",
            Path: RouterModel.getPath(AppModule.SpecialPrice)
          },
          {
            ID: 85,
            Code: AppModule.PaymentType.Code,
            Label: Model.getMenuLabel(AppModule.PaymentType),
            Icon: "mdi mdi-domain ",
            Path: RouterModel.getPath(AppModule.PaymentType)
          }
        ]
      }
    ];

    if (App.Session.getCompanyID() === 11) {
      result[1].Items.push({
        ID: 24,
        Code: AppModule.ReportStockAdj.Code,
        Label: Model.getMenuLabel(AppModule.ReportStockAdj),
        Icon: "mdi mdi-file-document ",
        Path: RouterModel.getPath(AppModule.ReportStockAdj)
      });
    }

    return result;
  }
}