<template>
  <kst-print-table input-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="ReceiveItemCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ReceiveItemCatalog" />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="ReceiveItemCatalog.Packaging.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ReceiveItemCatalog" />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="ReceiveItemCatalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ReceiveItemCatalog" />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="BuyPrice"
      :label="ReceiveItemCatalog.BuyPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ReceiveItemCatalog" />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="ExpenseAmount"
      :label="ReceiveItemCatalog.ExpenseAmount.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ReceiveItemCatalog" />
    </b-table-column>
    
  </kst-print-table>
</template>

<script>
import ReceiveItemCatalog from "../ReceiveItemCatalog.js";
import Model from "../ReceiveModel.js";
import Info from "../ReceiveInfo.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    ReceiveItemCatalog: ReceiveItemCatalog,
    Model: Model,
    Info: Info
  })
}
</script>
