import App from "@/services/App/App.js";
// import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
// import Catalog from "./SalesRecapCatalog.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.SalesRecap,
  ModuleType: {
    Details: AppModuleType.Details,
    Export: AppModuleType.Export,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,    
    Print: AppModuleType.Print,
    PrintDetails: AppModuleType.PrintDetails,
    Search: AppModuleType.Search
  },

  /*** related ***/
  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      List: AppModuleType.List,
      New: AppModuleType.New
    }
  },

  /*** property ***/

  PaymentType: {
    Cash: {
      ID: 1,
      Label: "Tunai"
    },
    BankTransfer: {
      ID: 2,
      Label: "Bank Transfer"
    }
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label,
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },

  setDetails(data) {
    return this.helper.setDetails(data)
  },

  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  updateInvoiceDetails(data, items) {
    return this.helper.updateInvoiceDetails(data, items);
  },

  updateDetails(data, items) {
    return this.helper.updateDetails(data, items);
  },
  updateDetailsByReset(data, items) {
    return this.helper.updateDetailsByReset(data, items);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.InvoiceList = this.helper.populateInvoices(items);
    return result;
  },

  /*** external ***/
  
  WarehouseStatus: WarehouseModel.Status,

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  getCashierOptions(cashierList, optionAllText) {
    return this.helper.createCashierOptions(cashierList, optionAllText)
  },

  helper: {
    createDetails(){
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        WarehouseID: null,
        CashierID: null,
        Date: App.In.getDateToday(),
        InvoiceDate: App.In.getDateToday(),
        RecapNumber: "",
        IsAutoNumber: true,
        CashReceived: "",
        Comment: "",
        // by system
        TotalCash: 0,
        TotalTransfer: 0,
        Total: 0,
        CreatedByID: App.Session.getUserID(),
      }
    },

    setDetails(data) {
      for (let item of data.PaymentTypeList) {
        if (item.PaymentTypeID === null) {
          item.PaymentTypeID = 1;
          item.PaymentTypeName = "Tunai"
        }
      }
    },

    createCashierOptions(items, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    updateDetails(data, items) {
      let total = 0

      for (let item of items) {
        if (item.PaymentTypeID === null) {
          item.PaymentTypeID = 1;
          item.PaymentTypeName = "Tunai"
        }
        total += item.Total;
      }
      data.Total = total;
    },
    updateDetailsByReset(data, items) {
      data.WarehouseID = null;
      data.CashierID = null;
      data.InvoiceDate = App.In.getDateToday();
      App.Array.truncate(items);

      this.updateDetails(data, items);
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        CashierID: data.CashierID,
        Date: App.Out.getDate(data.Date),
        CreatedByID: App.Out.getInteger(data.CreatedByID),
        RecapNumber: data.IsAutoNumber ? null : App.Out.getString(data.RecapNumber),
        TotalCash: App.Out.getInteger(data.TotalCash),
        TotalTransfer: App.Out.getInteger(data.TotalTransfer),
        CashReceived: App.Out.getInteger(data.CashReceived),
        Total: App.Out.getInteger(data.Total),
        Comment: App.Out.getString(data.Comment)
      }
    },
    populateInvoices(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          ID: item.ID,
          Total: App.Out.getInteger(item.Total),
        });
      }

      return resultItems;
    }
  },
  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  }
}