var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.items),function(warehouse,index){return [_c('div',{key:warehouse.WarehouseID,staticClass:"has-text-weight-semibold px-3 py-3"},[_vm._v(" Gudang: "+_vm._s(warehouse.WarehouseName)+" ")]),_c('kst-table',{key:warehouse.WarehouseID + '-' + index,attrs:{"action":"","view-mode":"","data":warehouse.Items,"loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [(row.TransactionID)?_c('kst-tooltip',{attrs:{"search-mode":"","hover-only":"","content":_vm.Model.ItemReport.TitleSearch}},[_c('kst-button',{attrs:{"search-mode":"","is-round":""},on:{"click":function($event){return _vm.handleSearchItem(row.TransactionID, row.ActivityTypeID)}}})],1):_vm._e()]}}],null,true)},[_c('b-table-column',{attrs:{"sortable":"","field":"Date","label":_vm.Catalog.Date.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getType(row.ActivityTypeID))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"Changes","label":_vm.Catalog.Changes.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Changes === 0)?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(row.Changes)+" "+_vm._s(_vm.UnitName)+" ")]]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","numeric":"","field":"HppChanges","label":_vm.Catalog.HppChanges.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","field":"QuantityAfter","label":_vm.Catalog.QuantityAfter.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.QuantityAfter === 0)?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(row.QuantityAfter)+" "+_vm._s(_vm.UnitName)+" ")]]}}],null,true)}),_c('b-table-column',{attrs:{"sortable":"","numeric":"","field":"HppAfter","label":_vm.Catalog.HppAfter.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,true)})],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }