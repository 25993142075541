import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./ExpenseModel.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name + " " + AppModule.Expense.FullName,
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: AppModuleType.List.Name + " " + AppModule.Expense.FullName
        }
      }
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
  }
}