export default {
  isBoolean(value) {
    return (value === true || value === false);
  },
  /**
   * WARNING !!
   * DO NOT use date instanceof Date
   * will return true for invalid dates too, i.e. value = new Date("random_string")
   */
  isDate(value) {
    return (Object.prototype.toString.call(value) === "[object Date]");
  },
  isDecimal(value) {
    return (Number(value) === value && value % 1 !== 0);
  },
  isInteger(value) {
    return (value === parseInt(value));
  },
  isNumber(value) {
    return (isNaN(value) === false);
  },
  isString(value) {
    return (typeof value === "string" || value instanceof String);
  }
}