var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{staticClass:"mt-5",attrs:{"title":_vm.sectionTitle}},[_c('kst-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"field":"Variants","label":_vm.DispatchCatalog.Variants.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"k-control-py"},[_vm._v(_vm._s(_vm.getVariants(row)))])]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-image","field":"ImageSrc","label":_vm.DispatchCatalog.ImageSrc.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-image',{attrs:{"read-only":"","thumbnail":"","src":row[column.field]}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-details-dispatch-FullName","field":"FullName","label":_vm.DispatchCatalog.FullName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-details-dispatch-SKU","field":"SKU","label":_vm.DispatchCatalog.SKU.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-details-dispatch-Barcode","field":"Barcode","label":_vm.DispatchCatalog.Barcode.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-details-dispatch-SellPrice","field":"SellPrice","label":_vm.DispatchCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-details-dispatch-Weight","field":"Weight","label":_vm.DispatchCatalog.Weight.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-details-dispatch-Description","field":"Description","label":_vm.DispatchCatalog.Description.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }