<template>
  <div class="spinner">
    <img src="../../assets/loading.svg" alt="Loading">
    <h4>Mohon menuggu</h4>
  </div>
</template>

<script>
import AppModule from "@/services/App/AppModule.js";
import Authentication from "@/services/Auth/Authentication.js";
//import InvoiceRouter from "@/components/Invoice/InvoiceRouter.js";
import Info from "./CallbackInfo.js";

export default {
  data: () => ({
  }),
  async created() {
    try {
      /** original code
      let result = await this.$root.mgr.signinRedirectCallback();
      await Authentication.getUserSub(this);

      const shopAssistant = 2;
      const shopAssistantMutiaraBali = 3;
      const accessID = this.$kst.Session.getAccessID();
      let returnPath = accessID === shopAssistant || accessID === shopAssistantMutiaraBali ?
        InvoiceRouter.Path.List : "/";

      if (result.state !== undefined) {
        returnPath = result.state;
      }
      this.$router.push({ path: returnPath });
      **/

      await this.$root.mgr.signinRedirectCallback();
      await Authentication.getUserSub(this);

      const defaultMenu = this.$kst.Session.getDefaultMenu();
      const defaultPath = (defaultMenu === undefined || defaultMenu === null) ?
        "/" : AppModule[defaultMenu].Path;
      this.$router.push({ path: defaultPath });
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log("callback error");
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(e));

      // handle expired session when calling: this.$root.mgr.signinRedirectCallback()
      if (e.name === Info.Error.ExpiredSession.Name && e.error === Info.Error.ExpiredSession.Error) {
        this.$kst.Session.clear();
        this.$router.app.signOut();
      }

      this.$router.push({ name: 'Unauthorized' });
    }
  }
}
</script>

<style>
@import "./_layout.css";
</style>
