import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./StockCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.Stock,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    List: AppModuleType.List
  },

  /*** related ***/

  Item: {
    Module: ItemModel.Module,
    ModuleType: {
      Edit: AppModuleType.Edit,
      Search: AppModuleType.Search
    }
  },

  /*** property ***/

  Stock: {
    Available: {
      ID: 1,
      Label: Catalog.Quantity.Label + " Tersedia"
    }
  },

  Search: {
    Fields: ["FullName", "SKU"]
  },

  /*** method ***/
  createStockOptions(optionAllText) {
    return this.helper.createStockOptions(optionAllText, this.Stock);
  },
  getPrintedAt() {
    return AppCatalog.Print.PrintedAt;
  },
  getPrintedBy() {
    return AppCatalog.Print.PrintedBy;
  },

  /*** external ***/
  createAccess() {
    return AppCatalog.Access.getDefault();
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createStockOptions(optionAllText, stockEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: stockEnum.Available.ID, [rowLabel]: stockEnum.Available.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    }
  }
}