import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
    WarehouseID: {
      Label: "Gudang",
      Type: InputType.ID,
      Input: InputType.Select,
      Required: true,
      RequiredHide: true,
      RelatedValue: "WarehouseName"
    },
    WarehouseName: {
      Label: "Warehouse",
      Type: InputType.Text,
      ReadOnly: true
    },
    TransID: {
      Label: "Transaksi",
      Type: InputType.ID,
      Input: InputType.Select,
      RelatedValue: "TransactionName"
    },
    TransactionName: {
      Label: "Transaksi",
      Type: InputType.Text,
      ReadOnly: true
    }
}