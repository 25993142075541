import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import Dashboard from "./Dashboard";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.Dashboard),
    Invoice: RouterModel.getPath(AppModule.Invoice, AppModuleType.List),
    ProfitLoss: RouterModel.getPath(AppModule.ProfitLoss)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Dashboard),
        name: RouterModel.getName(AppModule.Dashboard),
        component: Dashboard,
        meta: {
          navTitle: RouterModel.getName(AppModule.Dashboard),
          moduleName: RouterModel.getModule(AppModule.Dashboard),
        }
      }
    ];
  }
}