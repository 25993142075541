<template>
  <kst-container class="kst-report-dailyreport">
    <kst-form :loading="isLoading" :title="title">
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
            :urlParams="urlParams"
          />
          <kst-button action-mode
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
            :urlParams="urlParams"
          />
        </template>

        <List :failureList="failureList" :data="data" :isListLoading="isListLoading"/>
        <Total :data="data" :isListLoading="isListLoading" />
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Catalog from "../DailyReportCatalog.js";
import Model from "../DailyReportModel";
import Services from "../DailyReportServices.js";

import Search from "./DailyReport_Search";
import List from "./DailyReport_List";
import Total from "./DailyReport_Total";

export default {
  mixins: [AxiosMixin],
  components: {
    Search,
    List,
    Total
  },
  data: () => ({
    Model: Model,
    searchData: {},
    warehouseOptions: {},
    data: {},
    isLoading: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.WarehouseID]: this.$kst.Search.getValue(this.searchData.WarehouseID, ""),
        [this.$kst.Enum.Param.Date]: this.$kst.Out.getDate(this.searchData.Date),
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
    },
    initSearch() {
      this.searchData = {
        WarehouseID: this.$kst.Search.OptionAll,
        Date: this.$kst.In.getDateToday()
      }

      this.loadWarehouse();
    },
    loadWarehouse() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },

    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, Catalog.WarehouseID.Label
      );
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
      this.isLoading = false;
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    loadReport(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;

      let options = {};

      if (searchData) {
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.date = this.$kst.Out.getDate(this.searchData.Date);
      }

      const config = Services.getDailyReport(options);
      this.runAxios(config, counter, this.loadReport_Success, this.loadReport_Error);
    },

    loadReport_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.data = Model.getProcessData(data);
      this.isListLoading = false;
    },
    loadReport_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },

    handleSearchSubmit() {
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>