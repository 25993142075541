<template>
  <label class="label is-size-5">
    <slot>{{ text }}</slot>
  </label>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>