<template>
  <div>
    <VendorSearch ref="mdlVendorSearch" @submit="handleVendor_Submit"/>

    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="PONumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>
    <kst-input field="Date" :data="userData" :catalog="Catalog"/>

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="Vendor"
          :disabled="userData.VendorID !== null"
          :data="userData"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <div class="control">
          <template v-if="userData.VendorID === null">
            <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button search-mode @click="handleSearch_Vendor"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button clear-mode @click="handleClear_Vendor"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions" @input="handleWarehouse"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
    
    <kst-section class="mt-5" :title="sectionTitle">
      <kst-input field="DeliveryAddress" :data="userData" :catalog="Catalog"/>
      <kst-input field="DeliveryDate" :data="userData" :catalog="Catalog"/>
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../PurchaseCatalog.js";
import Info from "../PurchaseInfo.js";
import Model from "../PurchaseModel.js";

import VendorSearch from "@/components/Vendor/Search/VendorSearch";

export default {
  components: {
    VendorSearch
  },
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Delivery.Title;
    },
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  },
  methods: {
    handleSearch_Vendor() {
      this.$refs.mdlVendorSearch.show();
    },
    handleWarehouse() {
      Model.setDetailsByWarehouse(this.userData, this.warehouseOptions);
    },
    handleClear_Vendor() {
      Model.clearDetailsByVendor(this.userData);
    },
    handleVendor_Submit(row) {
      Model.setDetailsByVendor(this.userData, row);
    }
  }
}
</script>