<template>
  <kst-table view-mode :data="items" :loading="isListLoading">
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="kst-dispatch-related-FullName"
      field="FullName"
      :label="Catalog.FullName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="Barcode"
      :label="Catalog.Barcode.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="Catalog.SellPrice.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Field.HppPerPcs !== false">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpp"
        field="HppPerPcs"
        :label="Catalog.HppPerPcs.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template v-if="access.Field.HppTotal !== false">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="Catalog.HppTotal.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column
      v-slot="{ column, row }"
      field="Description"
      :label="Catalog.Description.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../DispatchCatalog.js";

export default {
  props: {
    access: Object,
    isListLoading: Boolean,
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>