import DispatchServices from "@/services/DispatchServices";
import PurchaseServices from "@/services/PurchaseServices.js";
import ReceiveServices from "@/services/ReceiveServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import ItemServices from "@/services/ItemServices.js";
import CompanyServices from "@/services/CompanyServices.js";

export default {
  getReceiveList: ReceiveServices.getList,
  getReceiveListData: ReceiveServices.getListData,
  getReceiveDetails: ReceiveServices.getDetails,
  postReceive: ReceiveServices.postReceive,
  putReceive: ReceiveServices.putReceive,
  // dispatch
  getDispatchDetails: DispatchServices.getDetails,
  getDispatchList: DispatchServices.getList,
  // purchase
  getPurchaseDetails: PurchaseServices.getDetails,
  // receiving
  getReceivePurchase: ReceiveServices.getListByPurchasing,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // price list
  getPriceList: ReceiveServices.getPriceList,
  //items
  getBarcodeList: ItemServices.getBarcodeList,
  // company
  getCompanyDetails: CompanyServices.getDetails
}