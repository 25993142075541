import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import StockAvailabilityList from "./List/StockAvailabilityList";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.StockAvailability, AppModuleType.List)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockAvailability, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockAvailability, AppModuleType.List),
        component: StockAvailabilityList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockAvailability, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.StockAvailability)
        }
      }
    ];
  }
}