import InputType from "@/services/Input/InputType.js";
import Catalog from "../ClientCatalog.js";
import ClientAddressCatalog from "../ClientAddressCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.ClientName.Label + ", atau " +
      ClientAddressCatalog.Address.Label + ", atau " +
      ClientAddressCatalog.ContactMobile.Label + ", atau " +
      ClientAddressCatalog.ContactPhone.Label + ", atau " +
      ClientAddressCatalog.ContactEmail.Label,
    Type: InputType.Text,
    MaxLength: 100
  }
}