import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import CompanyProfileEdit from "./Edit/CompanyProfileEdit";
import CompanyProfile from "./Profile/CompanyProfile";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.CompanyProfile),
    Edit: RouterModel.getPath(AppModule.CompanyProfile, AppModuleType.Edit)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.CompanyProfile),
        name: RouterModel.getName(AppModule.CompanyProfile),
        component: CompanyProfile,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.CompanyProfile),
          moduleName: RouterModel.getModule(AppModule.CompanyProfile),
        }
      },
      {
        path: RouterModel.getPath(AppModule.CompanyProfile, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.CompanyProfile, AppModuleType.Edit),
        component: CompanyProfileEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.CompanyProfile, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.CompanyProfile),
        }
      },
    ];
  },

  getRootRoutes() {
    return [];
  }
}