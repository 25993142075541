<template>
  <div :class="theClass">
    <nav class="level is-mobile mb-4 pb-0">
      <div class="level-left is-block">
        <kst-title>{{ theTitle }}</kst-title>
        <kst-subtitle v-if="theHelp">{{ theHelp }}</kst-subtitle>
      </div>
      <div class="level-right">
        <template v-if="!loading">
          <slot name="top-right"/>
        </template>
      </div>
    </nav>
    <slot/>
    <div class="level-item">
      <template v-if="!loading">
        <slot name="bottom"/>
      </template>
    </div>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    help: String,
    loading: Boolean,
    title: String,
    // mode: ONLY select 1 (one)
    exportMode: Boolean,
    infoMode: Boolean,
    itemsMode: Boolean,
    searchMode: Boolean,
    searchResultMode: Boolean,
    // help
    selectOneHelp: Boolean
  },
  computed: {
    theClass() {
      return this.getClass();
    },
    theHelp() {
      return this.getHelp();
    },
    theTitle() {
      return this.getTitle();
    }
  },
  methods: {
    getClass() {
      if (this.exportMode) {
        return "block kst-section";
      }
      else if (this.searchMode) {
        return "block pt-4 px-4 ks-border-dark k-radius-large kst-section";
      }
      else {
        return "block p-4 ks-border-dark k-radius-large kst-section";
      }
    },
    getHelp() {
      if (this.selectOneHelp) {
        return AppCatalog.Section.SelectOne;
      }
      return this.help;
    },
    getTitle() {
      if (this.exportMode) {
        return AppCatalog.Section.Export;
      }
      else if (this.infoMode) {
        return AppCatalog.Section.Info + this.title;
      }
      else if (this.itemsMode) {
        return AppCatalog.Section.Items;
      }
      else if (this.searchMode) {
        return AppCatalog.Section.Search;
      }
      else if (this.searchResultMode) {
        return AppCatalog.Section.SearchResult;
      }

      return this.title;
    }
  }
}
</script>