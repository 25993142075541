import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import InvoiceItemCatalog from "@/components/Invoice/InvoiceItemCatalog.js";

export default {
  // required in model
  Module: AppModule.Invoice,
  ModuleType: {
    Print: AppModuleType.Print,
    PrintThermal: AppModuleType.PrintThermal
  },

  /*** property ***/

  DiscType: {
    None: 0,
    Value: 1,
    Percent: 2
  },

  PaymentType: {
    Cash: {
      ID: 1,
      Label: "Tunai"
    },
    BankTransfer: {
      ID: 2,
      Label: "Bank Transfer"
    }
  },

  /*** method ***/

  updateData(data) {
    return this.helper.updateData(data);
  },
  updateItemSpecialPrice(item) {
    this.helper.updateItemSpecialPrice(item);
  },

  helper: {
    updateData(data) {
      data.PayReturn = data.PaymentValue - data.Total;
    },
    updateItemSpecialPrice(item) {
      item.SpecialPriceID = item.SpecialPriceID || null;
      item.SpecialPriceName = item.SpecialPriceName || App.Search.LabelNone + InvoiceItemCatalog.SpecialPriceName.Label;   
      item.DiscountedValue = (item.SellPrice - item.SellPriceNetto) * item.TotalRequestedQuantity;
      item.BasicTotalPrice = item.SellPrice * item.TotalRequestedQuantity;
      item.SavingPrice = item.BasicTotalPrice - item.DiscountedValue;
    },
  }
}