import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./InvoiceCatalog.js";
import InvoiceItemCatalog from "./InvoiceItemCatalog.js";
import Model from "./InvoiceModel.js";

export default {
  Button: {
    SalesItem: {
      Label: AppModule.Item.FullName + " " + Model.Sales.Module.FullName
    }
  },
  Dialog: {
    ItemSearch: {
      Title: AppModuleType.Search.Name + " " + Model.InvoiceItem.Module.FullName +
        " dari " + Model.Module.FullName
    }
  },
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          Delivery: Model.Delivery.Module.FullName,
          Profit: AppCatalog.Info.Profit,
          RtnInvoice: Model.RtnInvoice.Module.FullName,
          SalesRecap: Model.SalesRecap.Module.FullName
        },
        Section: {
          Items: AppModuleType.List.Name + " " + Model.InvoiceItem.Module.FullName + ":",
          Summary: AppCatalog.Info.Summary + " " + Model.InvoiceItem.Module.FullName + ":",
          ProfitInvoice: AppModuleType.List.Name + " " + AppModule.Item.FullName + " di " +
            Model.Module.FullName,
          ProfitRtnInvoice: Model.RtnInvoice.ModuleType.List.Name + " " +
            Model.RtnInvoice.Module.FullName,
          SalesRecapInvoice: Model.SalesRecap.ModuleType.List.Name + " " +
            Model.SalesRecap.Module.FullName,
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Delivery: AppModuleType.List.Name + " " + Model.Delivery.Module.FullName,
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          Profit: AppCatalog.Info.Profit,
          RtnInvoice: AppModuleType.List.Name + " " + Model.RtnInvoice.Module.FullName,
          SalesRecap: Model.SalesRecap.Module.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name
        }
      }
    }
  },
  Message: {
    Details_NoDelivery:
      Model.Module.FullName + " ini tidak memerlukan " + Model.Delivery.Module.FullName + ".",
    Details_NoSales:
      Model.Module.FullName + " ini tidak menggunakan " + Model.Sales.Module.FullName + ".",
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_Client:
      "Tidak diperkenankan mengubah " + Catalog.ClientID.Label + ", " +
      "saat menggunakan " + Model.Sales.Module.FullName + ".",
    Edit_ConfirmDeleteItem:
      "Yakin menghapus " + Model.InvoiceItem.Module.FullName + "?",
    Edit_Delivery:
      "Tidak diperkenankan mengubah " + Catalog.IsNeedDelivery.Label + ", " +
      "saat sudah memiliki " + Model.Delivery.Module.FullName + ".",
    Edit_InvoiceDate:
      "Tidak diperkenankan mengubah " + Catalog.InvoiceDate.Label + ".",
    Edit_InvoiceStatus:
      "Bila tanpa " + Catalog.IsNeedDelivery.Label + ", " +
      "maka " + Catalog.Status.Label + " dari " + Model.Module.FullName + " " +
      "akan menjadi " + Model.Status.Closed.Label + ".",
    Edit_IsNeedDelivery:
      "Bila tanpa " + Catalog.IsNeedDelivery.Label + ", " +
      "maka tidak dapat membuat " + Model.Delivery.Module.FullName + ".",
    Edit_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.InvoiceItem.Module.FullName + ".",
    Edit_NoSales:
      Model.Module.FullName + " ini tidak menggunakan " + Model.Sales.Module.FullName + ".",
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_Sales:
      "Tidak diperkenankan mengubah " + Model.Sales.Module.FullName + ".",
    Edit_SONumber:
      "Tidak diperkenankan mengubah " + Catalog.SONumber.Label + ".",
    Edit_StatusClosed:
      "Tidak diperkenankan mengubah " + Model.Module.FullName + ", " +
      "saat status " + Model.Status.Closed.Label + ".",
    Edit_IsRecap:
      "Tidak diperkenankan mengubah " + Model.Module.FullName + ", " +
      "saat sudah direkap",
    Edit_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ".",
    New_Client:
      "Tidak diperkenankan mengubah " + Catalog.ClientID.Label + ", " +
      "saat menggunakan " + Model.Sales.Module.FullName + ".",
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.InvoiceItem.Module.FullName + "?",
    New_SONumber:
      Catalog.SONumber.Label + " berupa " + App.Format.getAutoManual(true) + ", " +
      " akan diisi setelah disimpan.",
    New_DeletedItem:
      "Tidak diperkenankan menggunakan " + AppCatalog.Info.DeletedItem + ".",
    New_InvoiceDate:
      "Tidak diperkenankan mengubah " + Catalog.InvoiceDate.Label + ".",
    New_InvoiceStatus:
      "Bila tanpa " + Catalog.IsNeedDelivery.Label + ", " +
      "maka " + Catalog.Status.Label + " dari " + Model.Module.FullName + " " +
      "akan menjadi " + Model.Status.Closed.Label + ".",
    New_IsNeedDelivery:
      "Bila tanpa " + Catalog.IsNeedDelivery.Label + ", " +
      "maka tidak dapat membuat " + Model.Delivery.Module.FullName + ".",
    New_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.InvoiceItem.Module.FullName + ".",
    New_Sales:
      "Memilih / menghapus " + Model.Sales.Module.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + ".",
    New_SalesItem:
      "Tidak diperkenankan mengubah " + InvoiceItemCatalog.PackagingName.Label + ", " +
      "saat menggunakan " + Model.Sales.Module.FullName + ".",
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.InvoiceItem.Module.FullName + ".",
    PrintDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Related_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  PriceSearch: {
    SellPrice: {
      High: {
        Icon: "mdi mdi-arrow-up-bold",
        IconClass: "has-text-success",
        TooltipClass: "is-success",
        TooltipText: InvoiceItemCatalog.SellPrice.Label + " lebih mahal dari normal"
      },
      Low: {
        Icon: "mdi mdi-arrow-down-bold",
        IconClass: "has-text-danger",
        TooltipClass: "is-danger",
        TooltipText: InvoiceItemCatalog.SellPrice.Label + " lebih murah dari normal"
      }
    }
  },
  Section: {
    Delivery: {
      Title: Model.Delivery.Module.FullName
    },
    ItemSearch: {
      Title: AppCatalog.Section.SelectOne
    },
    ProfitInvoice: {
      Title: AppModuleType.List.Name + " " + AppModule.Item.FullName + " di " +
        Model.Module.FullName
    },
    ProfitRtnInvoice: {
      Title: Model.RtnInvoice.ModuleType.List.Name + " " + Model.RtnInvoice.Module.FullName
    },
    Sales: {
      Title: Model.Sales.Module.FullName
    },
    Summary: {
      Title: AppCatalog.Info.Summary + " " + Model.InvoiceItem.Module.FullName
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    DeliveryList: Model.Delivery.ModuleType.List.Name + " " + Model.Delivery.Module.FullName,
    RtnInvoiceList: Model.RtnInvoice.ModuleType.List.Name + " " + Model.RtnInvoice.Module.FullName,
    Profit: AppCatalog.Info.Profit,
    SalesRecapList: AppModule.SalesRecap.FullName
  }
}