<template>
  <b-modal
    :active="isActive"
    @cancel="handleModal_Cancel"
  >
    <v-zoomer style="height: 200px; width: 300px">
      <template v-if="isLocal">
        <img :src="src"/>
      </template>
      <template v-else>
        <ik-image :path="src"/>
      </template>
    </v-zoomer>
  </b-modal>
</template>

<script>
export default {
  props: {
    src: String,
    isLocal: Boolean
  },
  data: () => ({
    isActive: false
  }),
  computed: {
    title() {
      return "a";
    }
  },
  methods: {
    // public functions
    show() {
      this.isActive = true;
    },

    // private functions
    handleModal_Cancel() {
      this.isActive = false;
    },
    handleModal_Submit() {}
  }
}
</script>