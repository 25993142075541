import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import RptStockCard from "./RptStockCard/RptStockCard";
import RptStockCardExport from "./Export/RptStockCardExport";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.StockCard),
    Export: RouterModel.getPath(AppModule.Item, AppModuleType.Export)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockCard),
        name: RouterModel.getName(AppModule.StockCard),
        component: RptStockCard,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockCard),
          moduleName: RouterModel.getModule(AppModule.StockCard)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockCard, AppModuleType.Export),
        name: RouterModel.getName(AppModule.StockCard, AppModuleType.Export),
        component: RptStockCardExport,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockCard, AppModuleType.Export),
          moduleName: RouterModel.getModule(AppModule.StockCard)
        }
      },
    ];
  }
}