<template>
  <kst-container class="kst-item-detail-report-related">
    <kst-section>
      <kst-table view-mode :data="items.Quantities" :loading="isListLoading">
        <b-table-column sortable
          v-slot="{ column, row }"
          field="WarehouseID"
          :label="Catalog.WarehouseID.Label"
        >
          <kst-output field-only
            :field="column.field" :data="row" :catalog="Catalog"
          />
        </b-table-column>

        <b-table-column sortable
          v-slot="{ row }"
          field="Quantity"
          :label="Catalog.Quantity.Label"
        >
          {{ row.Quantity }} {{ items.UnitName }}
        </b-table-column>

        <b-table-column sortable numeric
          v-slot="{ column, row }"
          field="HppTotal"
          :label="Catalog.HppTotal.Label"
        >
          <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
        </b-table-column>
      </kst-table>
    </kst-section>
  </kst-container>
</template>

<script>
import Catalog from "../RptStockCardDetailsCatalog.js";
import Model from "../RptStockCardModel.js";

export default {
  props: {
    ItemId: Number,
    items: Object
  },
  data: () => ({
    isListLoading: false,
    Catalog: Catalog,
    Model: Model
  })
}
</script>