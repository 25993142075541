import AppModule from "./AppModule.js";
import AppModuleType from "./AppModuleType.js";
import InputType from "../Input/InputType.js";
import OutputType from "../Input/OutputType.js";

/**
 * Store static values (constantas) for the App, which are used in certain place.
 * If the values are use in wide-spread components, store them at App.js
 */
export default {
  /*** main catalog ***/

  Title: "Dirigo",
  Copyright: "© PT KST Indonesia 2020",
  Description: "We’re provider of quality IT solutions and reliable service in software dev.",
  CurrencySign: "Rp",
  PercentSign: "%",

  IntlFormat: {
    Currency: "IDR",
    Number: "id-ID"
  },

  Folder: {
    Public: {
      Images: "/images/"
    }
  },

  /*** mixin ***/

  Search: {
    Debounce: {
      Delay: 1000 // in miliseconds
    }
  },
  Snackbar: {
    ActionText: "OK",
    Duration: 5000 // in miliseconds
  },

  /*** shared component ***/

  Action: {
    Icon: {
      Default: "menu-down",
      New: "plus-thick"
    },
    Mode: {
      Button: "is-primary",
      Normal: "is-info"
    }
  },
  Button: {
    Action: {
      Class: "is-white",
      // Icon: handled by developer
      // Label: handled by developer
    },
    Cancel: {
      Class: "ks-button",
      Icon: "mdi mdi-arrow-left-circle mdi-18px",
      Label: "Batal"
    },
    Clear: {
      Class: "is-danger",
      Icon: "mdi mdi-close-circle mdi-18px",
      Label: undefined
    },
    Close: {
      Class: "",
      Icon: "mdi mdi-close-circle mdi-18px",
      Label: "Batal"
    },
    Confirm: {
      Class: "is-primary",
      Icon: "mdi mdi-check-circle mdi-18px",
      Label: "OK"
    },
    Delete: {
      Class: "is-danger",
      Icon: "mdi mdi-delete mdi-18px",
      Label: undefined
    },
    Edit: {
      Class: "is-link",
      Icon: "mdi mdi-pencil mdi-18px",
      Label: undefined
    },
    Mail: {
      Class: "is-link",
      Icon: "mdi mdi-email-outline mdi-18px",
      Label: undefined
    },
    Phone: {
      Class: "is-link",
      Icon: "mdi mdi-phone mdi-18px",
      Label: undefined
    },
    Reset: {
      Class: "is-danger",
      Icon: "mdi mdi-undo mdi-18px",
      Label: "Reset"
    },
    Search: {
      Class: "is-link",
      Icon: "mdi mdi-magnify mdi-18px",
      Label: undefined
    },
    Submit: {
      Class: "is-primary ks-button",
      Icon: "mdi mdi-content-save mdi-18px",
      Label: "Simpan"
    },
    Url: {
      Class: "is-link",
      Icon: "mdi mdi-open-in-new mdi-18px",
      Label: undefined
    }
  },

  Failure: {
    MessageTitle: "Pesan:",
    Hint: "Mohon coba kembali, atau hubungi administrator anda",
    TitleData: "Gagal mengambil data",
    TitleInit: "Gagal mengambil data",
    TitleList: "Gagal mengambil data",
    TitleSave: "Gagal menyimpan data"
  },

  Icon: {
    AddCart: {
      Class: "has-text-success",
      Icon: "mdi mdi-cart-plus mdi-24px",
      Label: undefined
    },
    ItemActive: {
      Active: {
        Class: "has-text-success",
        Icon: "mdi mdi-toggle-switch mdi-24px",
        Label: undefined
      },
      InActive: {
        Class: "has-text-danger",
        Icon: "mdi mdi-toggle-switch-off mdi-24px",
        Label: undefined
      }
    },
    ItemStatus: {
      Open: {
        Class: "has-text-success",
        Icon: "mdi mdi-toggle-switch-off mdi-24px",
        Label: undefined
      },
      Closed: {
        Class: "has-text-danger",
        Icon: "mdi mdi-toggle-switch mdi-24px",
        Label: undefined
      }
    },
    Error: {
      Class: "has-text-danger",
      Icon: "mdi mdi-alert-circle mdi-24px",
      Label: undefined
    },
    Info: {
      Class: "has-text-info",
      Icon: "mdi mdi-information mdi-24px",
      Label: undefined
    },
    NewItem: {
      Class: "has-text-info",
      Icon: "mdi mdi-new-box mdi-24px",
      Label: undefined
    },
    Status: {
      Active: {
        Class: "has-text-success",
        Icon: "mdi mdi-circle",
        Label: undefined
      },
      InActive: {
        Class: "has-text-danger",
        Icon: "mdi mdi-circle",
        Label: undefined
      }
    },
    Warning: {
      Class: "has-text-warning",
      Icon: "mdi mdi-alert mdi-24px",
      Label: undefined
    }
  },

  Image: {
    Accept: "image/*",
    AddText: "Tambah Gambar",
    Icon: "mdi mdi-plus-thick mdi-48px",
    NoText: "Tanpa Gambar",

    Source: {
      Brand: "brand_logo.png",
      CompanyDefault: "company_default_logo.png",
      Invalid: "invalid.png" // not available yet
    }
  },

  Print: {
    PrintedAt: "Dicetak pada: ", // +timestamp
    PrintedBy: "oleh: ", // +username
    Error: {
      Help: "Pesan:", // +error_message
      Info: "Tidak dapat menampilkan data."
    }
  },

  Output: {
    Related: {
      Class: "is-link"
    },
    TextArea: {
      Rows: 5
    }
  },

  Router: {
    Back: {
      Class: "is-link",
      Icon: "mdi mdi-arrow-left-circle mdi-18px",
      Label: "Kembali"
    },
    Forward: {
      Class: "is-link",
      Icon: "mdi mdi-arrow-right-circle mdi-18px",
      Label: "Lanjut"
    }
  },

  Section: {
    // help
    SelectOne: "Pilih salah satu",

    // mode
    Export: "Pilihan Export:", // similar to Export.Name in "@/services/App/AppModuleType.js"
    Info: "Info ", // prefix
    Items: AppModuleType.List.Name + " " + AppModule.Item.FullName,
    Search: "Pencarian",
    SearchResult: "Hasil Pencarian"
  },

  Table: {
    PerPage: 20,
    DefaultSortDirection: "Desc",
    SortIcon: "menu-up",
    SortIconSize: "is-small"
  },

  Tooltip: {
    Clear: {
      Content: "Menghapus ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-danger"
    },
    Delete: {
      Content: "Menghapus ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-danger"
    },
    DeleteImage: {
      Content: "Menghapus gambar",
      Position: "is-bottom",
      Size: undefined,
      Type: "is-danger"
    },
    Details: {
      Content: "Rincian ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-info"
    },
    Edit: {
      Content: "Edit ", // prefix
      Position: "is-bottom",
      Size: "is-medium",
      Type: "is-info"
    },
    Error: {
      Content: undefined,
      Position: "is-bottom",
      Size: "is-medium",
      Type: "is-danger"
    },
    Info: {
      Content: undefined,
      Position: "is-bottom",
      Size: "is-medium",
      Type: "is-info"
    },
    Mail: {
      Content: "Mengirim email",
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    },
    NewItem: {
      Content: "Barang baru (belum terdaftar)",
      Position: "is-bottom",
      Size: undefined,
      Type: "is-info"
    },
    Phone: {
      Content: "Melakukan panggilan",
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    },
    Related: {
      Content: "Melihat " + AppModuleType.Details.Name + " ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    },
    Search: {
      Content: "Mencari ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-info"
    },
    Url: {
      Content: "Membuka tautan",
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    }
  },

  /*** alphabetical order ***/

  Access: {
    getDefault() {
      return { Field: {}, UI: {} };
    }
  },

  // cleave
  Cleave: {
    // source: https://github.com/nosir/cleave.js/blob/master/doc/options.md#numeraldecimalscale
    DefaultDecimalScale: 2,
    DecimalMark: ",",
    ThousandMark: "."
  },

  // compressorjs package
  Compressorjs: {
    MaxWidth: 1000 // in pixel
  },

  // duration
  Duration: {
    // in miliseconds
    Loading: 3000
  },

  Field: {
    Comment: {
      Label: "Catatan",
      Type: InputType.TextArea
      // MaxLength is varies
    },
    CreatedBy: {
      Label: "Pembuat",
      Type: InputType.Text
    },
    CreatedByID: {
      Label: "Nama",
      Type: InputType.ID,
      RelatedValue: "CreatedByName",
      RelatedModule: AppModule.User
    },
    CreatedDate: {
      Label: "Waktu",
      Type: InputType.DateTime,
      ReadOnly: true, // absolute
      Output: OutputType.DateTime.ShortDateTime
    },
    LastUpdatedBy: {
      Label: "Diperbarui",
      Type: InputType.Text
    },
    LastUpdatedByID: {
      Label: "Nama",
      Type: InputType.ID,
      RelatedValue: "LastUpdatedByName",
      RelatedModule: AppModule.User
    },
    LastUpdatedDate: {
      Label: "Waktu",
      Type: InputType.DateTime,
      ReadOnly: true, // absolute
      Output: OutputType.DateTime.ShortDateTime
    },
    Date: {
      Label: "Tanggal",
      Type: InputType.Date
    },
    DispatchID: {
      Label: AppModule.Item.FullName,
      Type: InputType.ID,
      RelatedValue: "DispatchName",
      RelatedModule: AppModule.Item,
      RelatedIsActive: "DispatchIsActive"
    },
    DispatchName: {
      Label: "Nama",
      Type: InputType.Text,
      MaxLength: 255
    },
    FullName: { // by dispatch
      Label: "Nama",
      Type: InputType.Text,
      ReadOnly: true, // absolute
      Highlight: true
    },
    HppTotal: {
      Label: "HPP",
      Type: InputType.Integer,
      ReadOnly: true, // absolute
      Output: OutputType.Integer.Currency
    },
    ImageSrc: {
      Label: AppModule.Image.FullName,
      Type: InputType.Text // should use Image type when supported
    },
    ItemID: {
      Label: AppModule.Item.FullName,
      Type: InputType.ID,
      ReadOnly: true, // absolute
      RelatedValue: "DispatchName",
      RelatedModule: AppModule.Item,
      RelatedIsActive: "DispatchIsActive"
    },
    IsAutoNumber: {
      Label: "", // per module
      Type: InputType.Boolean,
      Output: OutputType.Boolean.AutoManual
    },
    Name: {
      Label: "Nama"
    },
    Number: {
      Label: "Nomor",
      Type: InputType.Text,
      Highlight: true
    },
    PackagingName: {
      Label: AppModule.ItemUnit.FullName,
      Type: InputType.Select,
      Required: true
    },
    Qty: {
      Label: "Qty",
      Type: InputType.Integer,
      MinValue: 1,
      MaxLength: 11,
      Required: true
    },
    QtyStock: {
      Label: "Qty Stok",
      Type: InputType.Integer
    },
    Search: {
      Type: InputType.Text,
      MaxLength: 50
    },
    SellPrice: {
      Label: "Harga Jual",
      Type: InputType.Integer,
      PrintLabel: "Harga/Unit",
      MinValue: 0,
      MaxLength: 11,
      Output: OutputType.Integer.Currency
    },
    SellPriceNetto: {
      Label: "Harga Spesial",
      Type: InputType.Integer,
      MinValue: 0,
      MaxLength: 11,
      Output: OutputType.Integer.Currency
    },
    SKU: {
      Label: "SKU",
      Type: InputType.Text,
      Highlight: true
    },
    SpecialPriceID: {
      Label: "Diskon",
      Type: InputType.ID,
      Input: InputType.Select,
      RelatedValue: "SpecialPriceName",
      RelatedModule: AppModule.SpecialPrice,
      RelatedIsActive: "SpecialPriceIsActive",
    },
    SpecialPriceQty: {
      Label: "Qty " + AppModule.SpecialPrice.FullName,
      Type: InputType.Integer,
      ReadOnly: true // absolute
    },
    Stock: {
      Label: "Stok",
      Type: InputType.Integer,
      ReadOnly: true // absolute
    },
    StockID: {
      Label: "Stok",
      Type: InputType.Select
    },
    SubTotal: {
      Label: "Sub Total",
      Type: InputType.Integer,
      ReadOnly: true, // absolute
      Output: OutputType.Integer.Currency
    },
    Total: {
      Label: "Total",
      Type: InputType.Integer,
      ReadOnly: true, // absolute
      Output: OutputType.Integer.Currency
    },
    WarehouseID: {
      Label: AppModule.Warehouse.FullName,
      Type: InputType.ID,
      Input: InputType.Select,
      RelatedValue: "WarehouseName",
      RelatedModule: AppModule.Warehouse,
      RelatedIsActive: "WarehouseIsActive"
    },
    WarehouseName: {
      Label: AppModule.Warehouse.FullName,
      Type: InputType.Text,
      ReadOnly: true
    },
    Weight: {
      Label: "Berat (gram)",
      Type: InputType.Integer,
      MaxLength: 11,
      MinValue: 0
    }
  },

  Format: {
    DateSeparator: "/",
    DateRangeSeparator: "-",
    ValueRangeSeparator: "-",

    // date
    ShortDate: "DD/MM/YYYY",

    // datetime
    ShortDateTime: "DD/MM/YYYY HH:mm",
    MediumDateTime: "DD MMM YYYY HH:mm",
    LongDateTime: "dddd, DD MMM YYYY HH:mm",

    // credential
    PasswordMask: "***"
  },

  // used in: catalog, model
  Info: {
    ID: "ID",
    DeletedItem: AppModule.Item.FullName + " terhapus",
    GrossProfit: "Laba Kotor",
    HPP: "HPP",
    NewItem: AppModule.Item.FullName + " baru (belum terdaftar)",
    NettProfit: "Laba Bersih",
    Profit: "Laba",
    ProfitMargin: "Laba Margin",
    Sequence: "#",
    Summary: "Ringkasan",
    Today: "hari ini"
  },

  Input: {
    Required: {
      Text: "Wajib",
      Type: "is-danger is-light"
    }
  },

  // message
  Message: {
    Error_ArrayBlank: "Wajib diisi",
    Error_SelectOne: "Wajib memilih salah satu",
    ErrorOnForm: "Mohon perbaiki kesalahan",
    InfoEmpty: "Tidak ada info",
    InvoiceItem: "Barang dari " + AppModule.Invoice.FullName,
    LeavePage: [
      "Yakin meninggalkan halaman ini?",
      "Anda akan kehilangan data yang sudah diinput."
    ],
    ListEmpty: "Tidak ada data",
    ParamIncomplete: "Data tidak sesuai",
    PurchaseItem: "Barang dari " + AppModule.Purchase.FullName,
    SalesItem: "Barang dari " + AppModule.Sales.FullName,
    SuccessOnForm: "Data berhasil disimpan",
    TableEmpty: "Tidak ada data",
    Wait: "Mohon menunggu...",
    WarningOnForm: "Harap diperhatikan"
  },

  MonthNames: [
    "Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"
  ],
  MonthLongNames: [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli",
    "Agustus", "September", "Oktober", "November", "Desember"
  ],

  VeeValidate: {
    Provider: {
      /**
       * {Boolean} - Bails
       * > true: return first-error found, or
       *   false: return all-errors found
       * {String} - Class - class name for ValidationProvider
       * {Boolean} - Immediate
       * > true: validate field when loaded, or
       *   false: validate on user action or manually validate
       * {String} - Tag - tag name for ValidationProvider
       */
      // 
      Bails: false,
      Class: "ks-is-fullwidth",
      Immediate: false,
      Tag: "div"
    }
  },

  Xlsx: {
    Default: {
      Extension: ".xlsx",
      Password: "***",
      Sequence: "#"
    },
    Format: {
      // keys based on: "@/services/Input/OutputTypeEnum.js"
      // date & time
      ShortDate: "dd/mm/yyyy",
      MediumDate: "dd mmm yyyy",
      LongDate: "dd mmmm yyyy",
      ShortMonthly: "mmm yyyy",
      LongMonthly: "mmmm yyyy",
      // number
      Accounting: "_(\"Rp\" * #,##0_);_((\"Rp\" * #,##0);_(\"Rp\" * "-"_);_(@_)",
      Accounting2D: "_(\"Rp\" * #,##0.00_);_((\"Rp\" * #,##0.00);_(\"Rp\" * \"-\"??_);_(@_)",
      Currency: "\"Rp\" #,##0",
      Decimal2D: "#,##0.00",
      Integer: "#,##0",
      Percent: "0.00%",
    },
    Key: {
      Column: "c",
      Format: "z",
      Formula: "f",
      Style: "s",
      Row: "r",
      Type: "t",
      Value: "v"
    },
    Type: {
      Boolean: "b",
      Error: "e",
      Number: "n",
      Date: "d",
      Text: "s",
      Stub: "z"
    }
  }
}