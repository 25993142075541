<template>
  <kst-table action paginated view-mode
    :data="items"
    :loading="isListLoading"
    :failure="failureList"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchName"
      :label="Catalog.DispatchName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      field="Changes"
      :label="Catalog.Changes.Label"
    >
      {{ row.Changes }} {{ row.DispatchUnitName }}
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      field="QuantityAfter"
      :label="Catalog.QuantityAfter.Label"
    >
      {{ row.QuantityAfter }} {{ row.DispatchUnitName }}
    </b-table-column>

    <template #action="{ row }">
      <kst-tooltip search-mode hover-only :content="Model.Report.TitleSearch" v-if="row.TransactionID">
          <kst-button search-mode is-round @click="handleSearchItem(row.TransactionID, row.ActivityTypeID)"/>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";
import ReceiveRouter from "@/components/Receive/ReceiveRouter.js";
import InvoiceRouter from "@/components/Invoice/InvoiceRouter.js";
import RtnInvoiceRouter from "@/components/RtnInvoice/RtnInvoiceRouter.js";
import StockReductionRouter from "@/components/StockReduction/StockReductionRouter.js";
import Catalog from "../TransactionItemCatalog.js";
import Model from "../TransactionModel.js";
import HppAdjRouter from "@/components/HppAdj/HppAdjRouter.js";

export default {
  mixins: [RouterMixin],
  props: {
    items: Array,
    isListLoading: Boolean,
    failureList: String
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  methods: {
    handleSearchItem(transactionId,activityTypeId) {
      const urlParam = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: transactionId
      });

      switch(activityTypeId) {
        case 2: 
          this.openTab(ReceiveRouter.Path.Details + urlParam);
          break;
        case 3:
          this.openTab(InvoiceRouter.Path.Details + urlParam);
          break;
        case 7:
          this.openTab(RtnInvoiceRouter.Path.Details + urlParam);
          break;
        case 8:
        this.openTab(HppAdjRouter.Path.Details + urlParam);
          break;
        case 9:
          this.openTab(StockReductionRouter.Path.Details + urlParam);
          break;
      }
    }
  }
}
</script>