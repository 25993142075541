export default {
  methods: {
    /**
     * @returns VNode | undefined
     * source: https://vuejs.org/v2/api/#vm-slots
     **/
    getSlotContent(name) {
      if (this.slotHasContent(name)) {
        return this.$scopedSlots[name]();
      }
      else {
        return this.$scopedSlots[name];
      }
    },
    slotHasContent(name) {
      return !!this.$scopedSlots[name];
    }
  }
}