import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./ItemModel.js";
import PackagingCatalog from "./PackagingCatalog.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          Stock: Model.Stock.Module.FullName
        },
        Section: {
          Dispatch: AppModuleType.List.Name + " " + Model.ItemDispatch.Module.FullName + ":",
          Packaging: AppModuleType.List.Name + " " + Model.ItemPackaging.Module.FullName + ":",
          Stock: AppModuleType.List.Name + " " + Model.Stock.Module.FullName + ":",
          Variant: AppModuleType.List.Name + " " + Model.ItemVariant.Module.FullName + ":",
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Item: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          Dispatch: AppModuleType.List.Name + " " + Model.ItemDispatch.Module.FullName,
          Stock: AppModuleType.List.Name + " " + Model.Stock.Module.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.List.Name + " " +
          Model.Module.FullName,
        Option: {
          Item: Model.ModuleType.List.Name + " " + Model.Module.FullName,
          Dispatch: AppModuleType.List.Name + " " + Model.ItemDispatch.Module.FullName
        }
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_RegisteredNewItem:
      Model.Module.FullName + " telah didaftarkan.",
    Select_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,

    // packaging
    Edit_ConfirmDeletePackaging:
      "Yakin menghapus " + Model.ItemPackaging.Module.FullName + "?",
    Edit_ExistingPackaging:
      "Tidak diperkenankan mengubah/menghapus " + Model.ItemPackaging.Module.FullName + " " +
      "yang pernah dibuat.",
    New_ConfirmDeletePackaging:
      "Yakin menghapus " + Model.ItemPackaging.Module.FullName + "?",
    New_DefaultPackaging:
      "Tidak diperkenankan mengubah " + PackagingCatalog.ItemUnitAmount.Label + ", " +
      "untuk " + Model.ItemPackaging.Module.FullName + " pertama.",
    New_DeleteDefaultPackaging:
      "Tidak diperkenankan menghapus " + Model.ItemPackaging.Module.FullName + ", " +
      "untuk " + Model.ItemPackaging.Module.FullName + " pertama.",

    // variant
    Edit_ConfirmDeleteVariant: [
      "Yakin menghapus " + Model.ItemVariant.Module.FullName + "?",
      "Termasuk akan menghapus " + Model.ItemVariantValue.Module.FullName + " yang dimiliki."
    ],
    Edit_MaxVariant:
      "Maximum " + Model.ItemVariantValue.Module.FullName + " adalah " + Model.Variant.Max + ".",
    New_ConfirmDeleteVariant: [
      "Yakin menghapus " + Model.ItemVariant.Module.FullName + "?",
      "Termasuk akan menghapus " + Model.ItemVariantValue.Module.FullName + " yang dimiliki."
    ],
    New_MaxVariant:
      "Maximum " + Model.ItemVariantValue.Module.FullName + " adalah " + Model.Variant.Max + ".",

    // variant value
    Edit_ConfirmDeleteVariantValue: [
      "Yakin menghapus " + Model.ItemVariantValue.Module.FullName + "?"
    ],
    New_ConfirmDeleteVariantValue: [
      "Yakin menghapus " + Model.ItemVariantValue.Module.FullName + "?"
    ]
  },
  Modal: {
    VariantValue: {
      Title: Model.ItemVariantValue.ModuleType.New.Name + " " +
        Model.ItemVariantValue.Module.FullName
    }
  },
  Section: {
    Dispatch: {
      Title: Model.ItemDispatch.ModuleType.List.Name + " " +
        Model.ItemDispatch.Module.FullName
    },
    Packaging: {
      Title: Model.ItemPackaging.ModuleType.List.Name + " " +
        Model.ItemPackaging.Module.FullName
    },
    Variant: {
      Title: Model.ItemVariant.ModuleType.List.Name + " " +
        Model.ItemVariant.Module.FullName
    },
    VariantValue: {
      Title: Model.ItemVariantValue.ModuleType.List.Name + " " +
        Model.ItemVariantValue.Module.FullName
    },
    DispatchEdit: {
      Title: Model.ItemDispatch.ModuleType.Edit.Name + " " +
        Model.ItemDispatch.Module.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  }
}