var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","view-mode":"","data":_vm.dataCategories,"failure":_vm.failure}},[_c('b-table-column',{attrs:{"sortable":"","field":"CategoryName","label":_vm.Catalog.CategoryName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","centered":"","cell-class":"ks-col-status","field":"IsActive","label":_vm.Catalog.IsActive.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-status',{attrs:{"value":row.IsActive,"statusEnum":_vm.Model.IsActive}})]}}])}),_c('b-table-column',{attrs:{"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-button',{staticClass:"is-primary",attrs:{"action-mode":"","is-round":"","target":"","module":_vm.Model.Module,"moduleType":_vm.Model.ModuleType.Edit,"urlParams":_vm.getParams(row)}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }