<template>
  <kst-section items-mode class="mt-5">
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="ItemID"
        :label="ReceiveItemCatalog.Name.Label"
      >
       <kst-output field-only
          :field="column.field" :data="row" :catalog="ReceiveItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="ReceiveItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="ReceiveItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Quantity"
        :label="ReceiveItemCatalog.Quantity.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="ReceiveItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="BuyPrice"
        :label="ReceiveItemCatalog.BuyPrice.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="ReceiveItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="ExpenseAmount"
        :label="ReceiveItemCatalog.ExpenseAmount.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="ReceiveItemCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import ReceiveItemCatalog from "../ReceiveItemCatalog.js";
import Model from "../ReceiveModel.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    ReceiveItemCatalog: ReceiveItemCatalog,
    Model: Model
  })
}
</script>
