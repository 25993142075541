<template>
  <div class="kst-pos-items">
    <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Scanner">
        <SearchScanner ref="itemScanner" :itemsScanner="itemsScanner" :userData="userData"
          :searchData="searchData" :userItems="userItems" :specialPriceItems="specialPriceItems"
          @input="handleItem_Input" />
      </b-tab-item>
      <b-tab-item :label="tabs.Search">
        <SearchItems :items="items" :userData="userData" :searchData="searchData" 
          :userItems="userItems" :isListLoading="isListLoading" @submit="submit"
          @input="handleItem_Input" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin.js";
import Info from "./PosInfo.js";

import SearchScanner from "./Pos_Search_Scanner";
import SearchItems from "./Pos_Search_Items";

export default {
  mixins: [SearchMixin],
  components: {
    SearchScanner,
    SearchItems
  },
  props: {
    isListLoading: Boolean,
    items: Array,
    itemsScanner: Array,
    userData: Object,
    searchData: Object,
    userItems: Array,
    specialPriceItems: Array
  },
  data: () => ({
    // tabs
    activeTab: 0,
    tabs: {
      Scanner: Info.Tabs.Scanner,
      Search: Info.Tabs.Search,
    }
  }),
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleItem_Input(elementItem, userItemIndex) {
      this.$emit(this.$kst.Enum.Event.Input, elementItem, userItemIndex);
    },
    resetScanResult() {
      this.$refs.itemScanner.resetScanResult();
    }
  }
}
</script>