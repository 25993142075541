<template>
  <kst-table action view-mode :data="userVariantValues">
    <b-table-column
      v-slot="{ column, row }"
      field="Value"
      :label="VariantValueCatalog.Value.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VariantValueCatalog"/>
    </b-table-column>

    <template #action="{ row, index }">
      <kst-tooltip delete-mode hover-only :content="Model.ItemVariantValue.Module.FullName">
        <template v-if="!row.IsDefault">
          <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
        </template>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userVariantValues" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import VariantValueCatalog from "../VariantValueCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userDispatches: Array,
    userVariantValues: Array,
    userVariants: Array
  },
  data: () => ({
    Model: Model,
    VariantValueCatalog: VariantValueCatalog,
    selectedIndex: null
  }),
  methods: {
    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_ConfirmDeleteVariantValue,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userVariantValues.splice(this.selectedIndex, 1);
      Model.updateDispatches(this.userDispatches, this.userVariants, this.userData);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    }
  }
}
</script>