var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"data":_vm.userItems,"failure":_vm.failureList,"loading":_vm.isListLoading}},[_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"InvoiceDate","label":_vm.SalesRecapInvoiceCatalog.InvoiceDate.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.SalesRecapInvoiceCatalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","field":"SONumber","label":_vm.SalesRecapInvoiceCatalog.SONumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.SalesRecapInvoiceCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","centered":"","sortable":"","field":"PaymentTypeID","label":_vm.SalesRecapInvoiceCatalog.PaymentTypeID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.SalesRecapInvoiceCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","centered":"","sortable":"","cell-class":"ks-col-price has-text-weight-bold","field":"Total","label":_vm.SalesRecapInvoiceCatalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.SalesRecapInvoiceCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }