<template>
  <kst-table view-mode 
    :data="data.dispatchStockList"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column 
      v-slot="{ column, row }"
      field="DispatchID"
      cell-class="kst-report-dailyreport-dispatch"
      :label="Catalog.DispatchID.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyBeginning"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyBeginning.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyReceive"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyReceive.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyTrf"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyTrf.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyAdj"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyAdj.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyReduction"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyReduction.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtySales"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtySales.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyLast"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyLast.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      field="QtyClosing"
      cell-class="kst-report-dailyreport-qty"
      :label="Catalog.QtyClosing.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../DailyReportCatalog.js";
import Model from "../DailyReportModel.js";

export default {
  props: {
    data: Object,
    failureList: String,
    isListLoading: Boolean,
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>