<template>
<kst-modal
    class="kst-po-search"
    :width="900"
    :active="active"
    :title="Model.Purchase.PO.Title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @submit="handleSearch_Submit"
        @reset="handleSearch_Reset"
      />
    </kst-section>

    <kst-section search-result-mode select-one-help>
      <List :failureList="failureList" :items="items" :isListLoading="isListLoading" :userData="userData" />
    </kst-section>
</kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../PurchaseModel.js";
import Router from "../PurchaseRouter.js";
import Services from "../PurchaseServices.js";
import SearchCatalog from "./PurchaseSearchCatalog.js";

import Search from "./PurchaseSearch_Search";
import List from "./PurchaseSearch_List";

export default {
  mixins: [AxiosMixin, SnackbarMixin],
  components: {
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: {},
    warehouseOptions: {},
    items: [],
    userData: {},
    active: false,
    isLoading: false,
    isListLoading: true,
    cancelUrl: Router.Path.List,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userData = {
        selected: null
      };

      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      };
    },

    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, SearchCatalog.WarehouseID.Label
      );
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadList_End() {
      this.isListLoading = false;
    },
    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];
      
      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
        options.status = 1;
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.startDate = this.$kst.Out.getDate(this.searchData.Date[0]);
        options.endDate = this.$kst.Out.getDate(this.searchData.Date[1]);
      }

      const config = Services.getPurchaseList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }
      this.items = data;
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.init();
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    show() {
      this.active = true;
    },
    hide() {
      this.active = false;
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    }
  
  }
}
</script>