<template>
  <kst-table paginated view-mode
    :data="items"
    :loading="isListLoading"
    :selected.sync="userData.selected"
    :failure="failureList"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="kst-dispatch-search-FullName"
      field="FullName"
      :label="Catalog.FullName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template slot="empty">
      <kst-table-empty>
        <kst-button action-mode
          v-if="newItemEnabled"
          class="is-primary mt-4"
          tag="button"
          :label="Info.Button.NewItem"
          :moduleType="Model.ModuleType.New"
          @click="handleAdd_NewItem"
        />
      </kst-table-empty>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DispatchCatalog.js";
import Info from "../DispatchInfo.js";
import Model from "../DispatchModel.js";

export default {
  props: {
    isListLoading: Boolean,
    items: Array,
    newItemEnabled: Boolean,
    userData: Object,
    failureList: String
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  methods: {
    handleAdd_NewItem() {
      this.$emit(this.$kst.Enum.Event.NewItem);
    }
  }
}
</script>