<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../DailyReportCatalog.js";
import Info from "../DailyReportInfo.js";
import Services from "../DailyReportServices.js";
import Model from "../DailyReportModel.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object,
  },
  data: () => ({
    loadLoading: null,
  }),
  methods: {
    exportExcel() {
      this.loadLoading = this.openLoading();

      this.loadList(this.searchData);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      const result = Model.getProcessData(data);
      this.createBook();
      this.createSheet();

      this.addList(result);

      this.addSheet(Info.Export.List.Excel.SheetName.List);

      this.download(Info.Export.List.Excel.FileName);
      this.saveData_End();
    },

    loadList(searchData) {
      let options = {};
      if (searchData) {
        options.warehouse = searchData.WarehouseID;
        options.date = searchData.Date;
      }

      const config = Services.getDailyReport(options);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addList(items) {
      this.addCellSection(Info.Export.List.Excel.Section.List);
      this.addRow();
      
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.DispatchID);
      this.addColumnHeader(Catalog.DispatchName);
      this.addColumnHeader(Catalog.QtyBeginning);
      this.addColumnHeader(Catalog.QtyReceive);
      this.addColumnHeader(Catalog.QtyTrf);
      this.addColumnHeader(Catalog.QtyAdj);
      this.addColumnHeader(Catalog.QtyReduction);
      this.addColumnHeader(Catalog.QtySales);
      this.addColumnHeader(Catalog.QtyLast);
      this.addColumnHeader(Catalog.QtyClosing);

      // value
      for (const row of items.dispatchStockList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("DispatchID", row, Catalog);
        this.addCellValue("DispatchName", row, Catalog);
        this.addCellValue("QtyBeginning", row, Catalog);
        this.addCellValue("QtyReceive", row, Catalog);
        this.addCellValue("QtyTrf", row, Catalog);
        this.addCellValue("QtyAdj", row, Catalog);
        this.addCellValue("QtyReduction", row, Catalog);
        this.addCellValue("QtySales", row, Catalog);
        this.addCellValue("QtyLast", row, Catalog);
        this.addCellValue("QtyClosing", row, Catalog);
      }
      
      //Total
      this.addRow();
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader("");
      this.addColumnHeader("");
      this.addCellValue("QtyBeginningTotal", items, Catalog);
      this.addCellValue("QtyReceiveTotal", items, Catalog);
      this.addCellValue("QtyTrfTotal", items, Catalog);
      this.addCellValue("QtyAdjTotal", items, Catalog);
      this.addCellValue("QtyReductionTotal", items, Catalog);
      this.addCellValue("QtySalesTotal", items, Catalog);
      this.addCellValue("QtyLastTotal", items, Catalog);
      this.addCellValue("QtyClosingTotal", items, Catalog);
    },
  }
}
</script>