import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import ImageKit from "imagekitio-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import VueZoomer from "vue-zoomer";
import xlsx from "xlsx";

Vue.use(Buefy);
Vue.use(ImageKit, {
  urlEndpoint: "https://ik.imagekit.io/ksttoko",
  publicKey: "public_e3eD7OPixRi238qredXPaI1R4dg="
});
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VueZoomer);
Object.defineProperty(Vue.prototype, "$xlsx", { value: xlsx });
Vue.config.productionTip = false;

/** utils **/

import ValidationUtils from "./utils/ValidationUtils";
import Cleave from "vue-cleave-component";

Vue.use(ValidationUtils);
Vue.use(Cleave);

/** plugin */

import KstApp from "./services/App/App.js";
import JQuery from "jquery";
import JQueryEasing from "jquery.easing";

const plugin = {
  install () {
    Vue.prototype.$kst = KstApp;
    Vue.prototype.$jquery = JQuery;
  }
};
Vue.use(plugin);
Vue.use(JQueryEasing);

/** additional lib **/

import "moment/locale/id";

import "./style/css/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import "@mdi/font/css/materialdesignicons.css";

/** shared components **/

import Action from "@/components/_shared/Action/Action";
import ActionItem from "@/components/_shared/Action/ActionItem";
import Button from "@/components/_shared/Button/Button";
import Check from "@/components/_shared/Check/Check";
import Container from "@/components/_shared/Container/Container";
import DialogConfirm from "@/components/_shared/DialogConfirm/DialogConfirm";
import Divider from "@/components/_shared/Divider/Divider";
import Error from "@/components/_shared/Error/Error";
import Errors from "@/components/_shared/Error/Errors";
import Failure from "@/components/_shared/Failure/Failure";
import Form from "@/components/_shared/Form/Form";
import HeaderBlank from "@/components/_shared/Table/HeaderBlank";
import Icon from "@/components/_shared/Icon/Icon";
import Image from "@/components/_shared/Image/Image";
import Input from "@/components/_shared/Input/Input";
import ItemActive from "@/components/_shared/Status/ItemActive";
import ItemStatus from "@/components/_shared/Status/ItemStatus";
import List from "@/components/_shared/List/List";
import Menu from "@/components/_shared/Menu/Menu";
import Modal from "@/components/_shared/Modal/Modal";
import Output from "@/components/_shared/Output/Output";
import Page from "@/components/_shared/Page/Page";
import Print from "@/components/_shared/Print/Print";
import PrintTable from "@/components/_shared/Print/PrintTable";
import RouterBack from "@/components/_shared/Router/RouterBack";
import RouterForward from "@/components/_shared/Router/RouterForward";
import Section from "@/components/_shared/Section/Section";
import Skeleton from "@/components/_shared/Skeleton/Skeleton";
import Status from "@/components/_shared/Status/Status";
import SubTitle from "@/components/_shared/Title/SubTitle";
import Table from "@/components/_shared/Table/Table";
import TableEmpty from "@/components/_shared/Table/TableEmpty";
import Title from "@/components/_shared/Title/Title";
import Tooltip from "@/components/_shared/Tooltip/Tooltip";
import Value from "@/components/_shared/Value/Value";
import Wait from "@/components/_shared/Wait/Wait";
import Warning from "@/components/_shared/Warning/Warning";

Vue.component("kst-action", Action);
Vue.component("kst-action-item", ActionItem);
Vue.component("kst-button", Button);
Vue.component("kst-check", Check);
Vue.component("kst-container", Container);
Vue.component("kst-confirm", DialogConfirm);
Vue.component("kst-divider", Divider);
Vue.component("kst-error", Error);
Vue.component("kst-errors", Errors);
Vue.component("kst-failure", Failure);
Vue.component("kst-form", Form);
Vue.component("kst-header-blank", HeaderBlank);
Vue.component("kst-icon", Icon);
Vue.component("kst-image", Image);
Vue.component("kst-input", Input);
Vue.component("kst-item-active", ItemActive);
Vue.component("kst-item-status", ItemStatus);
Vue.component("kst-list", List);
Vue.component("kst-menu", Menu);
Vue.component("kst-modal", Modal);
Vue.component("kst-output", Output);
Vue.component("kst-page", Page);
Vue.component("kst-print", Print);
Vue.component("kst-print-table", PrintTable);
Vue.component("kst-router-back", RouterBack);
Vue.component("kst-router-forward", RouterForward);
Vue.component("kst-section", Section);
Vue.component("kst-skeleton", Skeleton);
Vue.component("kst-status", Status);
Vue.component("kst-subtitle", SubTitle);
Vue.component("kst-table", Table);
Vue.component("kst-table-empty", TableEmpty);
Vue.component("kst-title", Title);
Vue.component("kst-tooltip", Tooltip);
Vue.component("kst-value", Value);
Vue.component("kst-wait", Wait);
Vue.component("kst-warning", Warning);

/** formatter **/

// boolean
Vue.filter("activeInactiveFormat", KstApp.Format.getActiveInactive);
Vue.filter("booleanFormat", KstApp.Format.getBoolean);
Vue.filter("openClosedFormat", KstApp.Format.getOpenClosed);
Vue.filter("yesNoFormat", KstApp.Format.getYesNo);

// date
Vue.filter("shortDateFormat", KstApp.Format.getShortDate);
Vue.filter("rangeShortDate", KstApp.Format.getRangeShortDate);
Vue.filter("mediumDateFormat", KstApp.Format.getMediumDate);
Vue.filter("longDateFormat", KstApp.Format.getLongDate);

// number
Vue.filter("accountingFormat", KstApp.Format.getAccounting);
Vue.filter("accounting2DFormat", KstApp.Format.getAccounting2D);
Vue.filter("currencyFormat", KstApp.Format.getCurrency);
Vue.filter("decimalFormat", KstApp.Format.getDecimal);
Vue.filter("decimal2DFormat", KstApp.Format.getDecimal2D);
Vue.filter("integerFormat", KstApp.Format.getInteger);
Vue.filter("percentFormat", KstApp.Format.getPercent);

// converter
Vue.filter("arrayToString", KstApp.Convert.arrayToString);

/** router **/

import Authentication from "@/services/Auth/Authentication.js";
import RouterModel from "@/router/RouterModel.js";
import mgr from "@/services/Auth/AuthService.js";
import routes from "@/router/index.js";
import AppModule from "./services/App/AppModule";

const data = {
  isAuthenticated: false,
  user: "",
  mgr
};

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = Authentication.isAuthenticated(router, to);

  if (!isAuthenticated) {
    methods.authenticate(to.path).then(() => {
      const modulesObj = AppModule;
      const access = KstApp.Session.getMenu();

      for (const moduleKey in modulesObj) {
        const module = modulesObj[moduleKey];

        if (to.path.includes("/" + module.Path)) {
          if (access[module.Code] === false) {
            const defaultModuleCode = KstApp.Session.getDefaultMenu();

            for (const defaultModuleKey in modulesObj) {
              const defaultModule = modulesObj[defaultModuleKey];

              if (defaultModule.Code === defaultModuleCode) {
                next(defaultModule.Path);
                return;
              }
            }
          }
        }
      }

      next();
    });
  }
  else {
    const modulesObj = AppModule;
    const access = KstApp.Session.getMenu();

    if (access) {
      for (const moduleKey in modulesObj) {
        const module = modulesObj[moduleKey];

        if (to.path.includes("/" + module.Path)) {
          if (access[module.Code] === false) {
            const defaultModuleCode = KstApp.Session.getDefaultMenu();

            for (const defaultModuleKey in modulesObj) {
              const defaultModule = modulesObj[defaultModuleKey];

              if (defaultModule.Code === defaultModuleCode) {
                next(defaultModule.Path);
                return;
              }
            }
          }
        }
      }
    }

    next();
  }
});

router.afterEach((to) => {
  // update browser/history title
  Vue.nextTick(() => {
    document.title = RouterModel.getDocumentTitle(to);
  });
});

const methods = {
  async authenticate(returnPath) {
    let isAuthenticated = true;
    const user = await this.getUser();
    const accessID = KstApp.Session.getAccessID();

    if (!user) {
      isAuthenticated = false;
    }
    if (accessID === null) {
      isAuthenticated = false;
    }

    if (!isAuthenticated) {
      await this.signIn(returnPath);
    }
    else {
      data.isAuthenticated = true;
      data.user = user;
    }
  },
  
  async getUser() {
    try {
      let user = await mgr.getUser();
      return user;
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  },

  signIn(returnPath) {
    if (!returnPath) {
      return mgr.signinRedirect();
    }
    return mgr.signinRedirect({ state: returnPath });
  },

  signOut() {
    KstApp.Session.clear();
    return mgr.signoutRedirect({ state: "http://localhost:8080" });
  }
};

new Vue({
  render: h => h(App),
  data,
  methods,
  router
}).$mount("#app");