import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  type_id: {
    Label: "Komposisi Nomor",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "format"
  },
  format: {
    Label: "Komposisi Nomor",
    Type: InputType.Text,
    ReadOnly: true,
  },
  prefix: {
    Label: "Awalan",
    Type: InputType.Text,
    MaxLength: 100,
  },
  suffix: {
    Label: "Akhiran",
    Type: InputType.Text,
    MaxLength: 100,
  },
  year: {
    Label: "Tahun",
    Type: InputType.Integer,
    Output: OutputType.Integer.Text,
    ReadOnly: true
  },
  month: {
    Label: "Bulan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  counter: {
    Label: "Perulangan",
    Type: InputType.Integer,
    ReadOnly: true
  },

  // support
  autoBySystem: {
    Label: "",
    Type: InputType.Text,
  },
  previewValue: {
    Label: "Contoh Nomor Penjualan",
    Type: InputType.Text
  }
}