<template>
  <kst-container class="kst-item-edit">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :loading="isLoading"
        :title="title"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details
          :userData="userData"
          :userDispatches="userDispatches"
          :typeOptions="typeOptions"
        />
        <Packagings :userData="userData" :userPackagings="userPackagings"/>
        <Variants
          :idObj="idObj"
          :userData="userData"
          :userVariants="userVariants"
          :userDispatches="userDispatches"
        />
        <Dispatches :access="access" :userDispatches="userDispatches"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import Router from "../ItemRouter.js";
import Services from "../ItemServices.js";

import Details from "./ItemEdit_Details";
import Dispatches from "./ItemEdit_Dispatches";
import Packagings from "./ItemEdit_Packagings";
import Variants from "./ItemEdit_Variants";

export default {
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  components: {
    Details,
    Dispatches,
    Packagings,
    Variants
  },
  data: () => ({
    access: {},
    userData: {},
    userDispatches: [],
    userPackagings: [],
    userVariants: [],
    idObj: {},
    typeOptions: {},
    isLoading: false,
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    formWarnings: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.routeLeaveEnabled = false;
        this.formWarnings = [Info.Message.Edit_ParamIncomplete];
        return;
      }

      this.access = Model.createAccess();
      this.userData = Model.createDetails();
      this.userDispatches = [];
      this.userPackagings= [];
      this.userVariants = [];
      this.idObj = Model.createIdObject();

      this.typeOptions = Model.createTypeOptions();
      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadEditData(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadEditData(id) {
      const config = Services.getItemEditData(id);
      this.runAxios(config, null,
        this.loadEditData_Success,
        this.loadEditData_Error,
        this.loadEditData_Invalid
      );
    },
    loadEditData_Success(data) {
      this.access = data.Access;
      this.setDetails(data.Details);
      this.initLoad_End();
    },
    loadEditData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadEditData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(
        this.userData,
        this.userVariants,
        this.userPackagings,
        this.userDispatches
      );
      this.saveData(data);
    },

    setDetails(data) {
      Model.setDetailsByData(this.userData, data);
      Model.setPackagingsByData(this.userPackagings, data.Packaging);
      Model.setVariantsByData(this.userVariants, data.Variants, this.idObj);
      Model.setDispatchesByData(this.userDispatches, data.Variations);
      Model.updatePackagingsErrors(this.userData, this.userPackagings);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.putItem(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>