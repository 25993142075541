<template>
  <div>
    <VendorSearch ref="mdlVendorSearch" @submit="handleVendor_Submit"/>

    <kst-warning class="mb-5" :items="vendorWarning"/>

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="VendorName"
          :data="userData"
          :disabled="userData.VendorID !== null"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <div class="control">
          <template v-if="!userData.VendorID">
            <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button search-mode @click="handleSearch_Vendor"/>
            </kst-tooltip>
          </template>
          <template v-if="userData.VendorID && !userData.PurchasingID">
            <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button clear-mode @click="handleClear_Vendor"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ReceiveCatalog.js";
import Info from "../ReceiveInfo.js";
import Model from "../ReceiveModel.js";

import VendorSearch from "@/components/Vendor/Search/VendorSearch";

export default {
  components: {
    VendorSearch
  },
  props: {
    warehouseOptions: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.PO.Title;
    },
    vendorWarning() {
      return ["Tidak diperkenankan mengubah Vendor, saat menggunakan Pemesanan Pembelian."];
    }
  },
  methods: {
    handleSearch_Vendor() {
      this.$refs.mdlVendorSearch.show();
    },
    handleClear_Vendor() {
      Model.clearDetailsByVendor(this.userData);
    },
    handleVendor_Submit(row) {
      Model.setDetailsByVendor(this.userData, row);
    }
  }
}
</script>