<template>
  <ValidationProvider
    v-slot="{ errors }"
    :bails="provider.Bails"
    :class="provider.Class"
    :name="catalog[field].Label"
    :immediate="provider.Immediate"
    :rules="validationRules"
    :tag="provider.Tag"
    :vid="vid"
  >
    {{ updateErrors(errors) }}
    <b-field :type="{ 'is-danger': errors[0] }">
      <template v-if="!errorOff" #message>
        <kst-list hide-empty :items="getErrors(errors)"/>
      </template>

      <slot name="left" />
      <b-select expanded
        ref="myField"
        v-model="data[field]"
        :disabled="theDisabled"
        :id="vid"
        :placeholder="placeholder"
        @input="handleInput"
      >
        <option
          v-for="option in options[ROWS]"
          :key="option[options[VALUE]]"
          :value="option[options[VALUE]]"
        >
          {{ option[options[LABEL]] }}
        </option>
      </b-select>
      <slot name="right" />
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    errorOff: Boolean,
    loadOnFocus: Boolean,
    vid: String,
    validationRules: {
      type: Object
    },
    options: {
      /**
       * must contain keys:
       * {String} value - key that contains value attribue for options in select
       * {String} label - key that contains label attribue for options in select
       * [Array] rows - data for options in select
       **/
      type: Object,
      required: true
    },
    // catalog
    provider: Object
  },
  data: () => ({
    VALUE: "value",
    LABEL: "label",
    ROWS: "rows",
  }),
  computed: {
    placeholder() {
      return 'Pilih ' + this.catalog[this.field].Label;
    },
    theDisabled() {
      if (this.disabled === undefined) {
        return this.catalog[this.field].ReadOnly;
      }
      return this.disabled;
    }
  },
  mounted() {
    if (this.loadOnFocus) {
      this.focus();
    }
  },
  methods: {
    focus() {
      this.$refs.myField.focus();
    },

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
    updateErrors(errors) {
      this.$emit(this.$kst.Enum.Event.Error, errors);
    },

    getErrors(errors) {
      let result = [];

      for (const error of errors) {
        if (error !== this.$kst.Vee.ValidRule) {
          result.push(error);
        }
      }

      return result;
    }
  }
}
</script>