export default {
  /**
   * Search item of specified key in items (Array of Object)
   * (for Array of primitive-data-type, please use: Array.indexOf() function)
   * @param {Array} items - Array of Object
   * @param {String} key - key in Object
   * @param {any} value - value to search
   * @param {Object} options - options in search
   * - {Boolean} insensitive - true: lower case comparison applied
   *   WARNING!! when use this option, ensure the value and data are in String data-type.
   * @returns {Object | undefined}
   */
  searchItem(items, key, value, options) {
    // prevent error when user ignored options
    if (options === undefined) {
      options = {};
    }

    const { insensitive } = options;
    const newValue = (insensitive ? value.toLowerCase() : value);

    for (const item of items) {
      if (insensitive) {
        if (item[key].toLowerCase() === newValue) {
          return item;
        }
      }
      else {
        if (item[key] === newValue) {
          return item;
        }
      }
    }
  },

  /**
   * Search value of specified key in items (Array of Object)
   * (for Array of primitive-data-type, please use: Array.indexOf() function)
   * @param {Array} items - Array of Object
   * @param {String} key - key in Object
   * @param {any} value - value to search
   * @returns {any | undefined}
   */
  searchValue(items, itemKey, value, valueKey) {
    for (const item of items) {
      if (item[itemKey] === value) {
        return item[valueKey];
      }
    }
  },

  truncate(items) {
    items.splice(0, items.length);
  }
}