<template>
  <div class="columns is-mobile mt-2">
    <div class="column is-8 is-hidden-mobile"/>
    <div class="column is-narrow">
      <div class="title is-5 pt-4">
        {{ Catalog.HppTotal.Label }}
      </div>
    </div>
    <div class="column">
      <div class="has-text-right pt-4 pr-3 ks-border-t-black">
        <kst-value class="title is-5" field="HppTotal" :data="data" :catalog="Catalog"/>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../StockReductionCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>