<template>
  <div class="field has-addons mr-4">
    <div class="control">
      <kst-action button-mode new-icon class="pt-4">
        <kst-action-item>
          <kst-button action-mode full-label
            :module="Model.Item.Module"
            :moduleType="Model.Item.ModuleType.New"
          />
        </kst-action-item>
        <kst-action-item>
          <kst-button action-mode full-label
            :module="Model.Purchase.Module"
            :moduleType="Model.Purchase.ModuleType.New"
          />
        </kst-action-item>
        <kst-action-item>
          <kst-button action-mode full-label
            :module="Model.Receive.Module"
            :moduleType="Model.Receive.ModuleType.New"
          />
        </kst-action-item>
        <kst-action-item>
          <kst-button action-mode full-label
            :module="Model.Sales.Module"
            :moduleType="Model.Sales.ModuleType.New"
          />
        </kst-action-item>
        <kst-action-item>
          <kst-button action-mode full-label
            :module="Model.Invoice.Module"
            :moduleType="Model.Invoice.ModuleType.New"
          />
        </kst-action-item>
      </kst-action>
    </div>
  </div>
</template>

<script>
import Model from "./LayoutModel.js";

export default {
  data: () => ({
    Model: Model
  })
}
</script>