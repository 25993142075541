<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="StockTrfNumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>

    <kst-input field="SourceWarehouseID" :data="userData" :catalog="Catalog"
      :disabled="userItems.length > 0" :options="warehouseOptions"
    />
    <kst-input field="DestinationWarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../StockTrfCatalog.js";
import Info from "../StockTrfInfo.js";
import Model from "../StockTrfModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.New_Details];
    },
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  }
}
</script>