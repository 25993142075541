<template>
  <kst-tooltip
    v-if="row.SellPrice !== sellPrice"
    position="is-left"
    size="is-medium"
    :type="type"
  >
    <template #content>
      {{ text }}
    </template>

    <span class="icon" :class="iconClass">
      <i :class="icon"/>
    </span>
  </kst-tooltip>
</template>

<script>
import Info from "../InvoiceInfo.js";

export default {
  props: {
    row: Object,
    sellPrice: Number
  },
  computed: {
    sellPriceType() {
      return this.row.SellPrice > this.sellPrice ? "High" : "Low";
    },
    icon() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].Icon;
    },
    iconClass() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].IconClass;
    },
    type() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].TooltipClass;
    },
    text() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].TooltipText;
    }
  }
}
</script>