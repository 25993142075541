import AppModule from "./AppModule.js";

export default {
  ActivityType: {
    AddNewItem: {
      ID: 1,
      Module: AppModule.Item
    },
    Receive: {
      ID: 2,
      Module: AppModule.Receive
    },
    Invoice: {
      ID: 3,
      Module: AppModule.Invoice
    },
    StockTrf: {
      ID: 4,
      Module: AppModule.StockTrf
    },
    StockOpname: {
      ID: 5,
      Module: AppModule.StockOpname
    },
    ReturnDelivery: {
      ID: 6,
      Module: AppModule.RtnDelivery
    },
    RtnInvoice: {
      ID: 7,
      Module: AppModule.RtnInvoice
    },
    HppAdj: {
      ID: 8,
      Module: AppModule.HppAdj
    },
    StockReduction: {
      ID: 9,
      Module: AppModule.StockReduction
    }
  },

  Axios: {
    Method: {
      Get: "get",
      Patch: "patch",
      Post: "post",
      Put: "put"
    }
  },

  // breakline
  Breakline: {
    Html: "<br/>",
    Text: "\n"
  },

  // events name
  Event: {
    Cancel: "cancel",
    Callback: "callback",
    Click: "click",
    Delete: "delete",
    Edit: "edit",
    Error: "error",
    Focus: "focus",
    Input: "input",
    Load: "load",
    Menu: "menu", // Layout
    NewItem: "newitem", // DispatchSearch
    Reset: "reset",
    ResetFailure: "resetfailure",
    Sort: "sort", // buefy
    Submit: "submit",
    Success: "success",
    Clear: "clear"
  },

  // url param keys
  Param: {
    DispatchID: "dispatchid",
    ID: "id",
    InvoiceID: "invoiceid",
    Name: "name",
    PurchaseID: "purchaseid",
    PurchaseItemID: "purchaseitemid",
    SalesID: "salesid",
    Search: "search",
    WarehouseID: "warehouseid",
    StartDate: "startdate",
    EndDate: "enddate",
    Date: "date"
  },

  Status: {
    Active: {
      Class: "is-success is-light",
      Label: "Aktif"
    },
    Closed: {
      Class: "is-light",
      Label: "Closed"
    },
    Delivered: {
      Class: "is-success is-light",
      Label: "Delivered"
    },
    Draft: {
      Class: "is-light is-light",
      Label: "Draft"
    },
    InActive: {
      Class: "is-light",
      Label: "Non Aktif"
    },
    InDelivery: {
      Class: "is-warning is-light",
      Label: "In-Delivery"
    },
    Open: {
      Class: "is-success is-light",
      Label: "Open"
    },
    Returned: {
      Class: "is-danger is-light",
      Label: "Returned"
    }
  },

  Type: {
    Operational: {
      Label: "Operational"
    },
    Sellable: {
      Label: "Sellable Items"
    }
  }
}