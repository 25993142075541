import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.dispatch;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "item", "itemname", "type", "variation", "field", "sku"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/dispatch/list" + urlParams
    };
  },
  getDetails(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/dispatch/" + id
    };
  },
  /*
  patchDispatch(component, data) {
    return component.$http.patch(apiEndpoint + "/api/v1/items/dispatch", data);
  },
  */

  /* barcode */

  /*
  getBarcodeList(component) {
    const cid = App.Session.getCompanyID();
    return component.$http.get(apiEndpoint + "/api/v1/items/barcode/list/" + cid);
  }
  */
}