import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
// import Catalog from "./PaymentTypeCatalog.js";

export default {
  // required in model
  Module: AppModule.PaymentType,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    Search: AppModuleType.Search
  },

  Status: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    },
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    }
  },

  Search: {
    Fields: ["Name"]
  },

  createDetails() {
    return this.helper.createDetails(this.Status);
  },

  createOptions(items, optionAllText) {
    return this.helper.createOptions(items, optionAllText);
  },

  createStatusOptions(optionAllText) {
    return this.helper.createStatusOptions(optionAllText, this.Status);
  },

  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },

  populateData(data) {
    let result = this.helper.populateDetails(data, this.DiscType);
    return result;
  },

  helper: {
    createDetails(statusEnum) {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        Name: "",
        IsActive: App.In.getBoolean(statusEnum.Active.ID),
        Comment: "",
        // defined by system
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID()
      };
    },

    createOptions(items, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    createStatusOptions(optionAllText, statusEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: statusEnum.Active.ID, [rowLabel]: statusEnum.Active.Label },
        { [rowId]: statusEnum.InActive.ID, [rowLabel]: statusEnum.InActive.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.Name = App.In.getString(details.Name);
      data.IsActive = App.In.getBoolean(details.IsActive);
      data.Comment = App.In.getString(details.Comment);
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        Name: App.Out.getString(data.Name),
        IsActive: App.Out.getBoolean(data.IsActive),
        Comment: App.Out.getString(data.Comment),
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID,
      }
    }
  }
}