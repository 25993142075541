<template>
  <div>
    <div class="mb-2">Formatted Excel:</div>
    <kst-button action-mode
      class="is-primary"
      tag="button"
      :module="Model.Module"
      :moduleType="Model.ModuleType.Export"
      @click="handleExport"
    />
  </div>
</template>

<script>
import Model from "../TestModel.js";

export default {
  data: () => ({
    Model: Model
  }),
  methods: {
    handleExport() {
      let workbook = this.$xlsx.utils.book_new();
      /**
      let sheet = this.$xlsx.utils.json_to_sheet(
        [
          { S:1, h:2, e:3, e_1:4, t:5, J:6, S_1:7 },
          { S:2, h:3, e:4, e_1:5, t:6, J:7, S_1:8 }
        ],
        {
          header:["S","h","e","e_1","t","J","S_1"]
        }
      );

      let sheet = this.$xlsx.utils.json_to_sheet(
        [
          { "Nama ku": 1, "alamat dia": 2 }, { 3: 2, 4: 3 }, { A: 3, B: 4 }
        ],
        {
          skipHeader: true,
          origin: "A2"
        }
      );
      **/

      let rows = [];
      let firstRow = [
        { t: "n", v: 10000 },                   // <-- General format
        { t: "n", v: 15000 },                   // <-- General format
        { t: "n", v: 10000, z: "0%" },          // <-- Builtin format
        { t: "n", v: 10000, z: "\"T\" #0.00" }, // <-- Custom format
        { t: "n", v: 10000, F: "A1" },
        { t: "n", v: 10000, F: "A1+B1" },
        { t: "n", v: 25000, f: "A1+B1" },
        { t: "n", v: 0.14567, z: "0.00%" },
        { t: "n", v: 52100, z: "#,##0" },
        { t: "n", v: 999888777, z: "\"Rp\" #,##0" },
        { t: "n", v: 111222333.456789, z: "\"Rp\" #,##0.00" },
        { t: "d", v: new Date(), z: "dd/mm/yyyy" }
      ];
      rows.push(firstRow);
      let options = { skipHeader: true };
      let sheet = this.$xlsx.utils.json_to_sheet(rows, options);

      this.$xlsx.utils.book_append_sheet(workbook, sheet, "test");
      this.$xlsx.writeFile(workbook, "formatted excel.xlsx");
    }
  }
}
</script>