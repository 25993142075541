<template>
  <div>
    <kst-section class="mt-5" :title="sectionTitle">
      <template v-if="data.SOID === null">
        <kst-warning :items="detailWarning"/>
      </template>
      <template v-else>
        <kst-output field="ClientID" :data="data" :catalog="Catalog">
          <template #left>
            <kst-output addons floating-label
              field="ClientAlias" :data="data" :catalog="Catalog"
            />
          </template>
          <template #default>
            <kst-output addons expanded floating-label
              field="Client" :data="data" :catalog="Catalog"
            />
          </template>
        </kst-output>


        <kst-input load-on-focus field="IsAutoNumber" :data="data" :catalog="Catalog">
          <template #right>
            <kst-input addons expanded field-only
              v-show="!data.IsAutoNumber"
              field="LogisticsNumber" :data="data" :catalog="Catalog"
              :customValidations="numberValidations"
            />
          </template>
        </kst-input>
        <kst-input field="DepartureDate" :data="data" :catalog="Catalog"/>
        <kst-input field="VehicleIDNumber" :data="data" :catalog="Catalog"/>
        <kst-input field="Destination" :data="data" :catalog="Catalog"/>
        <kst-input field="ShipmentFee" :data="data" :catalog="Catalog"/>
        <kst-input field="Comment" :data="data" :catalog="Catalog"/>
      </template>
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../DeliveryCatalog.js";
import Info from "../DeliveryInfo.js";
import Model from "../DeliveryModel.js";

export default {
  props: {
    data: Object
  },
  computed: {
    detailWarning() {
      return [Info.Message.New_Details];
    },
    numberValidations() {
      return Model.validationNumber(this.data);
    },
    sectionTitle() {
      return Info.Section.Delivery.Title;
    }
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>