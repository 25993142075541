import AppEnum from "./App_Enum";

/**
 * Convert:
 * - from: value (source: user input)
 * - to: value (computed property)
 */
export default {
  getDiscPercent_Value(value, percent) {
    return parseInt(value * percent / 100);
  },
  getPackaging(packagingName, packagingValue) {
    return packagingName + " (" + packagingValue + ")";
  },
  getStatusClass(value, statusEnum) {
    for (const status in statusEnum) {
      if (statusEnum[status].ID === value) {
        return statusEnum[status].Class;
      }
    }
    return null;
  },
  getStatusLabel(value, statusEnum) {
    for (const status in statusEnum) {
      if (statusEnum[status].ID === value) {
        return statusEnum[status].Label;
      }
    }
    return null;
  },
  getQtyByPackaging(qty, packagingValue) {
    // i.e. stock 50pcs in dozen (12) = 4 dozen (12)
    let value = parseInt(qty) / packagingValue;
    return parseInt(value);
  },
  getTotal(qty, packagingValue, price) {
    let total = parseInt(qty) * packagingValue * parseInt(price);
    return (isNaN(total) ? 0 : total);
  },
  getTotalQty(qty, packagingValue) {
    let totalQty = parseInt(qty) * packagingValue;
    return (isNaN(totalQty) ? 0 : totalQty);
  },
  getTotalWeight(qty, packagingValue, weight) {
    let totalWeight = parseInt(qty) * packagingValue * weight;
    return (isNaN(totalWeight) ? 0 : totalWeight);
  },
  isTrue(value) {
    if (value === true || value === 1 || value === "1") {
      return true;
    }
    return false;
  },
  getActivityType(ActivityTypeID) {
    switch (ActivityTypeID) {
      case AppEnum.ActivityType.AddNewItem.ID:
        return AppEnum.ActivityType.AddNewItem.Module.FullName;
      case AppEnum.ActivityType.Receive.ID:
        return AppEnum.ActivityType.Receive.Module.FullName;
      case AppEnum.ActivityType.Invoice.ID:
        return AppEnum.ActivityType.Invoice.Module.FullName;
      case AppEnum.ActivityType.StockTrf.ID:
        return AppEnum.ActivityType.StockTrf.Module.FullName;
      case AppEnum.ActivityType.StockOpname.ID:
        return AppEnum.ActivityType.StockOpname.Module.FullName;
      case AppEnum.ActivityType.ReturnDelivery.ID:
        return AppEnum.ActivityType.RtnDelivery.Module.FullName;
      case AppEnum.ActivityType.RtnInvoice.ID:
        return AppEnum.ActivityType.RtnInvoice.Module.FullName;
      case AppEnum.ActivityType.HppAdj.ID:
        return AppEnum.ActivityType.HppAdj.Module.FullName ;
      case AppEnum.ActivityType.StockReduction.ID:
        return AppEnum.ActivityType.StockReduction.Module.FullName;
      default: 
        return "";
    }
  }
}