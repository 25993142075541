var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"paginated":"","view-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"sortable":"","field":"Date","label":_vm.Catalog.Date.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Type","label":_vm.Catalog.Type.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getType(row))+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","numeric":"","field":"Total","label":_vm.Catalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{class:_vm.getClass(row),attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }