import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.invoicesetting;
import App from "./App/App.js";

// DEPRECATED
export default {
  getAllNumberFormat() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/invoices/formats/types"
    };
  },
  getNumberFormat() {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/invoices/formats/" + cid
    };
  },
  patchNumberFormat(data) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Patch,
      url: apiEndpoint + "/api/v1/invoices/formats/" + cid,
      data: data
    };
  }
}