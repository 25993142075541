import AppModule from "@/services/App/AppModule.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.GlobalSearch,

  /*** property ***/

  SearchType: {
    // based on ActivityType with additional info
    // DEPRECATED ID: 1, 6, 8
    Receive: {
      ID: 2,
      Label: AppModule.Receive.FullName,
    },
    Invoice: {
      ID: 3,
      Label: AppModule.Invoice.FullName,
    },
    StockTrf: {
      ID: 4,
      Label: AppModule.StockTrf.FullName,
    },
    StockOpname: {
      ID: 5,
      Label: AppModule.StockOpname.FullName,
    },
    RtnInvoice: {
      ID: 7,
      Label: AppModule.RtnInvoice.FullName,
    },

    // additional
    Stock: {
      ID: 101,
      Label: AppModule.Stock.FullName,
    }
  },

  /*** method ***/

  getSearchName(data) {
    return this.helper.getSearchName(data, this.SearchType);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    getSearchName(data, searchTypeEnum) {
      switch(data.SearchTypeID) {
        case searchTypeEnum.Receive.ID:
          return searchTypeEnum.Receive.Label;
        case searchTypeEnum.Invoice.ID:
          return searchTypeEnum.Invoice.Label;
        case searchTypeEnum.StockTrf.ID:
          return searchTypeEnum.StockTrf.Label;
        case searchTypeEnum.StockOpname.ID:
          return searchTypeEnum.StockOpname.Label;
        case searchTypeEnum.RtnInvoice.ID:
          return searchTypeEnum.RtnInvoice.Label;
        case searchTypeEnum.Stock.ID:
          return searchTypeEnum.Stock.Label;
      }
    }
  }
}