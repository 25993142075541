<template>
  <span />
</template>

<script>
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../VendorCatalog.js";
import Info from "../VendorInfo.js";

export default {
  mixins: [XlsxMixin],
  props: {
    items: Array
  },
  methods: {
    exportExcel() {
      this.createBook();
      this.createSheet();

      this.addList();
      this.download(Info.Export.List.Excel.FileName);
    },

    addList() {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Alias);
      this.addColumnHeader(Catalog.Mobile);
      this.addColumnHeader(Catalog.Phone);
      this.addColumnHeader(Catalog.Email);

      // value
      for (const row of this.items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("Name", row, Catalog);
        this.addCellValue("Alias", row, Catalog);
        this.addCellValue("Mobile", row, Catalog);
        this.addCellValue("Phone", row, Catalog);
        this.addCellValue("Email", row, Catalog);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>