<template>
  <kst-table action paginated view-mode
    :data="items" 
    :loading="isListLoading"
    :failure="failureList"
    :selected.sync="priceUserData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="ReceiveNumber"
      :label="Catalog.ReceiveNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="VendorID"
      :label="Catalog.VendorID.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="PurchasingNumber"
      :label="Catalog.PurchasingNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      cell-class="ks-col-price has-text-weight-bold"
      field="BuyPrice"
      :label="Catalog.BuyPrice.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <PriceIcon :row="row" :buyPrice="buyPrice"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../ReceiveCatalog.js";

import PriceIcon from "./ReceivePriceSearch_List_PriceIcon";

export default {
  components: {
    PriceIcon
  },
  props: {
    buyPrice: Number,
    isListLoading: Boolean,
    items: Array,
    priceUserData: Object,
    failureList: String
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>