import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./SalesCatalog.js";
import SalesItemCatalog from "./SalesItemCatalog.js"
import Model from "./SalesModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          Invoice: AppModuleType.List.Name + " " + AppModule.Invoice.FullName
        },
        Section: {
          SalesItem: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          Invoice: AppModuleType.List.Name + " " + AppModule.Invoice.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name
        }
      }
    }
  },
  Dialog: {
    ItemSearch: {
      Title: AppModuleType.Search.Name + " " + Model.SalesItem.Module.FullName +
        " dari " + Model.Module.FullName
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ConfirmDeleteItem:
      "Yakin menghapus " + Model.SalesItem.Module.FullName + "?",
    Edit_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ".",
    Edit_EditClient:
      "Tidak diperkenankan mengubah " + Model.Client.Module.FullName + ", " +
      "saat sudah memiliki " + Model.Invoice.Module.FullName + ".",
    Edit_EditPackaging:
      "Tidak diperkenankan mengubah " + SalesItemCatalog.Packaging.Label + " " +
      "untuk barang terhapus atau sudah terpakai di " + Model.Invoice.Module.FullName + ".",
    Edit_DeleteItem:
      "Tidak diperkenankan menghapus " + Model.SalesItem.Module.FullName + ", " +
      "bila sudah terpakai di " + Model.Invoice.Module.FullName + ".",
    Edit_DisabledIsClosed:
      "Tidak diperkenankan mengubah " + SalesItemCatalog.IsClosed.Label + ", " +
      "bila " + SalesItemCatalog.Qty.Label + " lebih kecil atau sama dengan " +
      SalesItemCatalog.QtyInvoice.Label + ".",
    Edit_DisabledNamePrice:
      "Tidak diperkenankan mengubah " + SalesItemCatalog.DispatchName.Label +
      " dan " + SalesItemCatalog.SellPrice.Label + ", " +
      "bila " + SalesItemCatalog.IsClosed.Label + " adalah " + Model.Status.Closed.Label + ".",
    Edit_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.SalesItem.Module.FullName + ".",
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.SalesItem.Module.FullName + "?",
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.SalesItem.Module.FullName + ".",
    New_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.SalesItem.Module.FullName + "."
  },
  Section: {
    ItemSearch: {
      Title: AppCatalog.Section.SelectOne
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    InvoiceList: Model.Invoice.ModuleType.List.Name + " " + Model.Invoice.Module.FullName
  }
}