<template>
  <kst-container class="kst-item-export-list">
    <kst-form
      :failure="failureList"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :title="title"
      :warnings="formWarnings"
    >
      <Export
        ref="refExport"
        :access="access"
        :searchData="searchData"
        @error="handleError"
      />

      <kst-section export-mode>
        <kst-check disabled v-model="searchData.WithList" :label="labelOptList"/>
        <kst-check v-model="searchData.WithExpense" :label="labelOptExpense"/>
      </kst-section>
      
      <template #bottom-left>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          @click="handleExport"
        />
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../OmzetInfo.js";
import Model from "../OmzetModel.js";

import Export from "../Export/OmzetExport_List";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Export
  },
  data: () => ({
    Model: Model,
    searchData: {},
    access: {},
    isListLoading: true,
    // error
    failureInit: null,
    failureList: null,
    formWarnings: null
  }),
  computed: {
    labelOptList() {
      return Info.Export.List.Excel.Option.List;
    },
    labelOptExpense() {
      return Info.Export.List.Excel.Option.Expense;
    },
    title() {
      return Info.Export.List.Excel.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.formWarnings = null;
      this.access = Model.createAccess();
      this.initSearch();
    },
    initSearch() {
      this.searchData = {
        Date: [
          this.getUrlParam(this.$kst.Enum.Param.StartDate),
          this.getUrlParam(this.$kst.Enum.Param.EndDate)
        ],
        WarehouseID: this.getUrlParam(this.$kst.Enum.Param.WarehouseID),
        WithList: true,
        WithExpense: true
      }
    },

    handleError(message) {
      this.failureList = message;
    },
    handleExport() {
      this.failureList = null;
      this.$refs.refExport.exportExcel();
    }
  }
}
</script>