<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input field="ReturnNumber" :data="userData" :catalog="Catalog"/>
    <kst-output field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from '../RtnInvoiceCatalog.js';
import Info from "../RtnInvoiceInfo.js";

export default {
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.Edit_Date];
    }
  }
}
</script>