<template>
  <div class="kst-report-result">
    <div class="columns" v-if="isListLoading">
      <div class="column">
        <kst-skeleton :active="isListLoading"/>
      </div>
    </div>
    <div class="columns" v-if="!isListLoading">
      <div class="column kst-report-item">
        <Summary
          :data="data"
          :icon="Info.Omzet.Icon"
          :iconStyle="Info.Omzet.IconStyle"
          :iconBgStyle="Info.Omzet.IconBgStyle"
          :title="Info.Omzet.TitleSection"
        />
        <SalesSummary
          :data="data"
          :paymentTypeList="paymentTypeList"
        />
      </div>
      <div class="column kst-report-item">
        <ResultChart :chartData="chartData"/>
        <ResultChartRetur :chartData="chartDataRetur"/>
      </div>
    </div>

    <kst-section :title="Info.Tabs.Details.Label" v-if="!isListLoading">
      <template #top-right>
        <kst-button action-mode
          class="is-primary mr-4"
          :module="Model.Module"
          :moduleType="Model.ModuleType.Print"
          :urlParams="urlParams"

        />
        <kst-button action-mode
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          :urlParams="urlParams"
        />
      </template>
      <ResultDetails :items="detailsList"/>
    </kst-section>
  </div>
</template>

<script>
import 'moment/locale/id';
import Info from "../OmzetInfo";
import Model from "../OmzetModel";

import ResultChart from "./Omzet_Result_Chart";
import ResultChartRetur from "./Omzet_Result_Chart_Retur";
import Summary from "./Omzet_Result_Summary";
import SalesSummary from "./Omzet_Sales_Summary";
import ResultDetails from "./Omzet_Result_Details";

export default {
  components: {
    ResultChart,
    Summary,
    SalesSummary,
    ResultChartRetur,
    ResultDetails,
  },
  props: {
    data: Object,
    paymentTypeList: Array,
    searchData: Object,
    summaryList: Array,
    chartData: Object,
    chartDataRetur: Object,
    detailsList: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Info: Info,
    isDaily: false,
    isViewChart: true,
    Model:Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.WarehouseID]: this.$kst.Search.getValue(this.searchData.WarehouseID, ""),
        [this.$kst.Enum.Param.StartDate]: this.$kst.Out.getDate(this.searchData.Date[0]),
        [this.$kst.Enum.Param.EndDate]: this.$kst.Out.getDate(this.searchData.Date[1]),
      });
    }
  },
  methods: {
    handleExport() {
      this.$refs.refExport.exportExcel();
    },
    handlePrint() {
      this.$ref.refPrint.print()
    }
  }
}
</script>