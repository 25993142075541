var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{staticClass:"mt-5",attrs:{"items-mode":""}},[_c('kst-table',{attrs:{"input-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"field":"ItemID","label":_vm.InvoiceItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-tooltip',{attrs:{"info-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"info-mode":"","items":_vm.Model.getItemInfo_Details(row)}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"info-mode":""}})],1)]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"Packaging","label":_vm.InvoiceItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"RequestedQuantity","label":_vm.InvoiceItemCatalog.RequestedQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-price","field":"SellPrice","label":_vm.InvoiceItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),(_vm.data.SpecialPriceCount > 0)?[_c('b-table-column',{attrs:{"cell-class":"ks-col-price","field":"SpecialPriceName","label":_vm.InvoiceItemCatalog.SpecialPriceName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}],null,false,485945088)}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPriceNetto","label":_vm.InvoiceItemCatalog.SellPriceNetto.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}],null,false,3565924609)})]:_vm._e(),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Price","label":_vm.InvoiceItemCatalog.Price.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }