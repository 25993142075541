import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import PaymentTypeDetails from "./Details/PaymentTypeDetails";
import PaymentTypeEdit from "./Edit/PaymentTypeEdit";
import PaymentTypeList from "../PaymentType/List/PaymentTypeList";
import PaymentTypeNew from "./New/PaymentTypeNew";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.PaymentType, AppModuleType.List),
    New: RouterModel.getPath(AppModule.PaymentType, AppModuleType.New),
    Edit: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Edit),
    Details: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Details),
    // Print: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Details),
        name: RouterModel.getName(AppModule.PaymentType, AppModuleType.Details),
        component: PaymentTypeDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.PaymentType, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.PaymentType)
        }
      },
      {
        path: RouterModel.getPath(AppModule.PaymentType, AppModuleType.List),
        name: RouterModel.getName(AppModule.PaymentType, AppModuleType.List),
        component: PaymentTypeList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.PaymentType, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.PaymentType),
        }
      },
      {
        path: RouterModel.getPath(AppModule.PaymentType, AppModuleType.New),
        name: RouterModel.getName(AppModule.PaymentType, AppModuleType.New),
        component: PaymentTypeNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.PaymentType, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.PaymentType),
        }
      },
      {
        path: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.PaymentType, AppModuleType.Edit),
        component: PaymentTypeEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.PaymentType, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.PaymentType),
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}