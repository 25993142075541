import OutputTypeEnum from "@/services/Input/OutputTypeEnum.js"

export default {
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    }
  },
  computed: {
    theTooltipValue() {
      return this.$kst.Value.getValue(this.field, this.data, this.catalog, true);
    },
    theType() {
      return this.$kst.Value.getType(this.field, this.catalog);
    },
    theValue() {
      return this.$kst.Value.getValue(this.field, this.data, this.catalog);
    },
    theClass() {
      if (this.theType === OutputTypeEnum.Accounting || this.theType === OutputTypeEnum.Accounting2D) {
        if (this.data[this.field] < 0) {     
          return "has-text-danger ";
        }
      }
      return undefined;
    },
    theUseNullText() {
      return this.$kst.Value.isUseNullText(this.field, this.data, this.catalog);
    }
  }
}