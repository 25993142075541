<template>
  <div class="my-thermal-footer mt-5" v-if="isMutiaraBali">
    <div style="text-align: center">
      Terima kasih sudah berbelanja di toko kami.<br/>
      Tinggalkan ulasan positif di halaman Google kami utk mendapatkan discount 5%
    </div>
    <div class="my-thermal-image">
      <kst-image read-only thumbnail src="client_mb_site_3yNdmtYll.png"/>
    </div>
  </div>
</template>

<script>
import App from "@/services/App/App.js";

export default {
  computed: {
    isMutiaraBali() {
      return parseInt(App.Session.getCompanyID()) === 21;
    }
  }
}
</script>