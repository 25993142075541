import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./SpecialPriceCatalog.js";
import Model from "./SpecialPriceModel.js";

export default {
  Dialog: {
    Search: {
      Title: AppModuleType.Search.Name + " " + Model.Module.FullName
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_Name:
      "Tidak diperkenankan mengubah " + Catalog.Name.Label + ".",
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Section: {
    Search: {
      Title: AppCatalog.Section.SelectOne
    }
  }
}