import moment from "moment";
import AppCatalog from "./AppCatalog.js";

export default {
  /*** date ***/

  getShortDate(value) {
    if (value === undefined || value === null) {
      return null;
    }

    const newValue = new Date(value);
    return moment(newValue).format(AppCatalog.Format.ShortDate);
  },

  getShortDateTime(value) {
    if (value === undefined || value === null) {
      return null;
    }

    const newValue = new Date(value);
    return moment(newValue).format(AppCatalog.Format.ShortDateTime);
  },

  getMediumDateTime(value) {
    if (value === undefined || value === null) {
      return null;
    }

    const newValue = new Date(value);
    return moment(newValue).format(AppCatalog.Format.MediumDateTime);
  },

  getLongDateTime(value) {
    if (value === undefined || value === null) {
      return null;
    }

    const newValue = new Date(value);
    return moment(newValue).format(AppCatalog.Format.LongDateTime);
  },

  /*** number ***/

  getAccounting(value, digit) {
    if (value === undefined || value === null) {
      return null;
    }

    if (typeof value !== "number") {
      if (isNaN(value)) {
        return value;
      }
    }

    let newDigit = digit;
    if (newDigit === undefined) {
      newDigit = 0;
    }

    const newValue = Math.abs(value);
    var formatter = new Intl.NumberFormat(AppCatalog.IntlFormat.Number, {
      style: 'currency',
      currency: AppCatalog.IntlFormat.Currency,
      minimumFractionDigits: newDigit,
      maximumFractionDigits: newDigit
    });

    let formattedValue = formatter.format(newValue);
    if (value < 0) {
      formattedValue = '(' + formattedValue + ')';
    }
    return formattedValue;
  },

  getDecimal(value, digit, maxDigit) {
    if (value === undefined || value === null) {
      return null;
    }

    if (typeof value !== "number") {
      if (isNaN(value)) {
        return value;
      }
    }

    let newDigit = digit;
    if (newDigit === undefined) {
      newDigit = 0;
    }

    let newMaxDigit = maxDigit;
    if (newMaxDigit === undefined) {
      newMaxDigit = 5;
    }

    var formatter = new Intl.NumberFormat(AppCatalog.IntlFormat.Number, {
      minimumFractionDigits: newDigit,
      maximumFractionDigits: newMaxDigit
    });
    return formatter.format(value);
  }
}