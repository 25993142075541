import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Active: true
  },
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Valid: true
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 200,
    Required: true
  },
  Quantity: {
    Label: "Qty",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true,
    Valid: true
  },
  QuantityReceived: {
    Label: "Qty Terima",
    Type: InputType.Integer,
    ReadOnly: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Select,
    Active: true,
    Exist: true,
    Unique: true,
    Static: true,
    Required: true
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  ExpenseAmount: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  PackagingValue: {
    Label: "Jumlah Kemasan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  },
  IsClosed: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.OpenClosed
  }
}