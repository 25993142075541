<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <GlobalSearch ref="mdlGlobalSearch"/>

    <div class="navbar-brand kst-layout-navbar-height kst-layout-sidebar-width ks-border-r-light ks-border-b-light">
      <div class="navbar-item">
        <Brand />
      </div>

      <a
        aria-label="menu"
        aria-expanded="false"
        class="navbar-burger button is-primary p-0 mt-1"
        role="button"
        @click="handleMenu"
      >
        <div class="navbar-burger-button">
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
        </div>
        <div class="label navbar-burger-label has-text-centered">Menu</div>
      </a>
    </div>

    <div class="navbar-menu ks-border-b-light">
      <div class="navbar-start">
        <div class="navbar-item">
          <div class="pl-5">
            <kst-skeleton simple-mode :active="isLoading"/>
            <kst-failure init-mode simple-mode :message="failureInit"/>

            <template v-if="isSearchVisible">
              <template v-if="!isLoading">
                <template v-if="!failureInit">
                  <Search :userData="userData" @submit="handleSearch_Submit"/>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <!--
        <div class="navbar-item">
          <span class="icon ks-color-grey">
            <i class="fas fa-bell"/>
          </span>
        </div>
        -->
        <div class="navbar-item">
          <Action />
          <User />
          <Company />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Info from "./LayoutInfo.js";

import Action from "./Layout_Nav_Action";
import Brand from "./Layout_Nav_Brand";
import GlobalSearch from "@/components/GlobalSearch/GlobalSearch";
import Search from "./Layout_Nav_Search";
import User from "./Layout_Nav_User";
import Company from "./Layout_Nav_Company";

export default {
  components: {
    Action,
    Brand,
    GlobalSearch,
    Search,
    User,
    Company
  },
  props: {
    failureInit: String,
    isLoading: Boolean,
    userData: Object
  },
  data: () => ({
    Info: Info
  }),
  computed: {
    isSearchVisible() {
      return this.$kst.Session.getCompanyID() === 11;
    },
    title() {
      return this.$route.meta.navTitle;
    },
    subtitle() {
      return this.$route.meta.navSubtitle;
    },
    version() {
      return this.$kst.View.getVersion();
    }
  },
  methods: {
    handleMenu() {
      this.$emit(this.$kst.Enum.Event.Menu);
    },
    
    handleSearch_Submit() {
      this.$refs.mdlGlobalSearch.show(this.userData.Search);
    }
  }
}
</script>