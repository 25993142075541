<template>
  <kst-modal
    class="kst-specialprice-search"
    :width="600"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-subtitle>{{ subTitle }}</kst-subtitle>
    <List
      :failureList="failureList"
      :isListLoading="isListLoading"
      :items="items"
      :userData="userData"
    />
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../SpecialPriceInfo.js";
import Model from "../SpecialPriceModel.js";
import Services from "../SpecialPriceServices.js";

import List from "./SpecialPriceSearch_List";

export default {
  mixins: [AxiosMixin, SnackbarMixin],
  components: {
    List
  },
  data: () => ({
    userData: {},
    items: [],
    active: false,
    excludeIdList: [],
    showAllOption: false,
    isListLoading: true,
    // error
    failureList: null
  }),
  computed: {
    subTitle() {
      return Info.Section.Search.Title;
    },
    title() {
      return Info.Dialog.Search.Title;
    }
  },
  methods: {
    init(excludeIdList, showAllOption) {
      this.initReset();
      this.excludeIdList = excludeIdList;
      this.showAllOption = showAllOption;

      this.loadList();
    },
    initReset() {
      this.userData = {
        selected: null
      };
    },

    show(excludeIdList, showAllOption) {
      this.init(excludeIdList, showAllOption);
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    loadList() {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      const config = Services.getSpecialPriceList({ active: 1 });
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data) {
      this.items = Model.getListForSelection(data, this.excludeIdList, this.showAllOption);
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    }
  }
}
</script>