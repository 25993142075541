<template>
  <kst-section items-mode class="mt-5">
    <DispatchSearch new-item-enabled
      ref="mdlDispatchSearch"
      :newItemEnabled="false"
      @submit="handleItem_Submit"
    />
    <PriceSearch
      ref="mdlPriceSearch"
      :items="priceList"
      :userData="userData"
      @submit="handleReceive_Submit"
      @callback="handlePrice_Callback"
    />
    <ItemPOSearch ref="mdlItemPOSearch" :items="itemsPO" @submit="handleItemPO_Submit"/>

    <template #top-right>
      <template v-if="userData.PurchasingID">
      <kst-button action-mode
          class="is-primary mr-4"
          tag="button"
          :label="Model.Button.ReceivingItem.Title"
          :moduleType="Model.ReceiveItem.ModuleType.New"
          @click="handleAdd_ItemPO"
        />
      </template>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Model.ReceiveItem.Module.FullName"
        :moduleType="Model.ReceiveItem.ModuleType.New"
        @click="handleAdd_Item"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0" >
        <template v-if="userData.PurchasingID">
          <kst-button action-mode
            class="is-primary mr-4"
            tag="button"
            :label="Model.Button.ReceivingItem.Title"
            :moduleType="Model.ReceiveItem.ModuleType.New"
            @click="handleAdd_ItemPO"
          />
        </template>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.ReceiveItem.Module.FullName"
          :moduleType="Model.ReceiveItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <kst-warning class="mb-5" :items="itemWarning"/>

    <List 
      :failureList="failureList" 
      :userData="userData" 
      :userItems="userItems"
      :isListLoading="isListLoading"
      @submit="addItem_Callback"
      @callback="handleList_Callback" 
    />
    <Scanner 
      :searchData="searchData" 
      :userData="userData" 
      :userItems="userItems" 
      :itemScanner="itemScanner"
      @submit="handleScanner_Submit"
    />
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from '../ReceiveModel.js';
import Services from "../ReceiveServices.js";

import List from './ReceiveNew_Items_List';
import DispatchSearch from "@/components/Dispatch/Search/DispatchSearch";
import PriceSearch from "../PriceSearch/ReceivePriceSearch";
import ItemPOSearch from "./ReceiveNew_AddItemPO";
import Scanner from "./ReceiveNew_Items_Scanner";

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    DispatchSearch,
    PriceSearch,
    ItemPOSearch,
    Scanner
  },
  props: {
    userData: Object,
    userItems: Array,
    searchData: Object,
    itemScanner: Array
  },
  data:() => ({
    Model: Model,
    isListLoading: false,
    itemsPO: [],
    selectedQty: "",
    priceList: null,
    // error
    failureList: null,
    // support debounce
    loadPriceCounter: 0
  }),
  computed: {
    itemWarning() {
      return [
        "Tidak diperkenankan mengubah Nama Barang, yang berasal dari Pemesanan Pembelian.", 
        "Silahkan hapus barang yang tidak diterima."
      ];
    }
  },
  methods: {
    addItem_Callback(item) {
      if(item.PurchasingItemID !== null){
        this.itemsPO.push(item);
      }
    },

    handleAdd_ItemPO() {
      this.$refs.mdlItemPOSearch.show();
    },
    handleAdd_Item() {
      this.$refs.mdlDispatchSearch.show();
    },
    handleItem_Submit(row) {
      this.isListLoading = true;
      this.loadItemDetails(row.DispatchID);
    },
    handleScanner_Submit(row, qty) {
      this.selectedQty = qty
      this.loadItemDetails(row.DispatchID);
    },
    handleItemPO_Submit(row, index) {
      if(row.PurchasingItemID !== null){
        this.itemsPO.splice(index, 1);
      }
      this.userItems.push(row);
    },
    handlePrice_Callback(filter) {
      this.loadPriceCounter++;
      this.loadPrice(this.loadPriceCounter, filter);
    },

    loadItemDetails_End() {
      this.isListLoading = false;
    },
    loadItemDetails(dispatchId) {
      const config = Services.getDispatchDetails(dispatchId);
      this.runAxios(config, null, this.loadItemDetails_Success, this.loadItemDetails_Error);
    },
    loadItemDetails_Success(data) {
      let item = Model.createItem();

      Model.setItemBySelectedItem(item, data);
      item.Quantity = parseInt(this.selectedQty);
      this.userItems.push(item);
      this.loadItemDetails_End();
    },
    loadItemDetails_Error(message) {
      this.failureList = message;
      this.loadItemDetails_End();
    },

    loadPrice(counter, filter) {
      this.priceList = [];
      const item = this.userItems[this.rowIndex];

      let options = { item: item.DispatchID };

      if (filter) {
        options.name = this.$kst.Out.getString(filter.search);
        options.vendor = this.$kst.Out.getString(filter.vendor);
        options.startDate = this.$kst.Out.getDate(filter.date[0]);
        options.endDate = this.$kst.Out.getDate(filter.date[1]);
      }
      
      const config = Services.getPriceList(options);
      this.runAxios(config, counter, this.loadPrice_Success, this.loadPrice_Error);
    },

    loadPrice_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadPriceCounter) {
        return;
      }

      this.priceList = data;
    },
    loadPrice_Error(message) {
      this.failureList = message;
    },

    handleReceive_Submit(row) {
      Model.setItemByPrice(this.userItems[this.rowIndex], row);
    },
    handleList_Callback(params) {
      const { isPrice, index } = params;
      
      if (isPrice) {
        this.rowIndex = index;
        this.loadPrice(this.loadPriceCounter, { 
          search: "",
          vendor: this.userData.VendorName,
          date: this.$kst.In.getThreeMonthDay()
        });
        this.$refs.mdlPriceSearch.show(
          this.userItems[index].DispatchBuyPrice, this.userData.VendorName
        );
      }
    }
  }
}
</script>