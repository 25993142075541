<template>
  <kst-container class="kst-expense-details">
    <kst-form 
      :loading="isLoading" 
      :title="title"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :warnings="formWarnings"
    >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
        <b-tab-item :label="tabs.Details">
          <Details :data="data"/>
        </b-tab-item>
      </b-tabs>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Model from "../ExpenseModel.js";
import Info from "../ExpenseInfo.js";

import Action from "./ExpenseDetails_Action";
import Details from "./ExpenseDetails_Details";


import Services from "../ExpenseServices.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details
  },
  data: () => ({
    Model: Model,
    data: {},
    isLoading: false,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    },
    // error
    formWarnings: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }

      this.data = {};

      this.loadDetails(id);
    },
    
    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;
     
      const config = Services.getExpenseDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.data = data;
      this.isLoading = false;
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    handleExport() {
      this.$refs.refExport.exportExcel();
    },
  }
}
</script>