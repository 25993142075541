<template>
  <kst-modal
    class="kst-receive-price-search"
    :width="950"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        @submit="handleSearch_Submit"
        @reset="handleSearch_Reset"
      />
    </kst-section>

    <kst-section search-result-mode select-one-help>
      <List
        :failureList="failureList"
        :buyPrice="buyPrice"
        :isListLoading="isListLoading"
        :items="items"
        :priceUserData="priceUserData"
      />
    </kst-section>
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";

import Search from "./ReceivePriceSearch_Search";
import List from "./ReceivePriceSearch_List";

export default {
  mixins: [SnackbarMixin, AxiosMixin],
  components: {
    Search,
    List
  },
  props: {
    items: Array,
    userData: Object
  },
  data: () => ({
    priceUserData: {},
    searchData: {},
    userItems: [],
    active: false,
    buyPrice: 0,
    isListLoading: false,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return "Cari Harga Beli";
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.priceUserData = {
        selected: null
      };

      this.initSearch();
    },
    initSearch() {
      this.searchData = {
        Search: "",
        Vendor: "",
        Date: this.$kst.In.getThreeMonthDay(),
      };
    },

    show(buyPrice, vendorName) {
      this.init();
      this.buyPrice = parseInt(buyPrice);
      this.searchData.Vendor = vendorName;
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.priceUserData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.priceUserData.selected);
    },

    handleSearch_Submit() {
      const filter = { 
        search: this.searchData.Search,
        vendor: this.searchData.Vendor,
        date: this.searchData.Date
      };

      this.$emit(this.$kst.Enum.Event.Callback, filter);
    },
    handleSearch_Reset() {
      this.initSearch();
      const filter = { 
        search: this.searchData.Search,
        vendor: this.searchData.Vendor,
        date: this.searchData.Date
      };
      
      this.$emit(this.$kst.Enum.Event.Callback, filter);
    }
  }
}
</script>