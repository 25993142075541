<template>
  <b-tag
    class="kst-status"
    type="is-flex is-size-6 py-1 ks-is-fullwidth kst-status"
    :class="theClass"
  >
    {{ label }}
  </b-tag>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    statusEnum: {
      type: Object,
      required: true
    }
  },
  computed: {
    theClass() {
      return this.$kst.Data.getStatusClass(this.value, this.statusEnum);
    },
    label() {
      return this.$kst.Data.getStatusLabel(this.value, this.statusEnum);
    }
  }
}
</script>