import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import StockClosingList from "./List/StockClosingList";
import StockClosingNew from "./New/StockClosingNew";
import StockClosingPrintDetail from "./PrintDetail/StockClosingPrintDetail";
import StockClosingDetail from "./Details/StockClosingDetail";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List),
    Details: RouterModel.getPath(AppModule.StockClosing, AppModuleType.Details),
    PrintDetail: RouterModel.getPath(AppModule.StockClosing, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [{
      path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List),
      name: RouterModel.getName(AppModule.StockClosing, AppModuleType.List),
      component: StockClosingList,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.List),
        moduleName: RouterModel.getModule(AppModule.StockClosing)
      }
    },{
      path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.New),
      name: RouterModel.getName(AppModule.StockClosing, AppModuleType.New),
      component: StockClosingNew,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.New),
        moduleName: RouterModel.getModule(AppModule.StockClosing)
      }
    },{
      path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.Details),
      name: RouterModel.getName(AppModule.StockClosing, AppModuleType.Details),
      component: StockClosingDetail,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.Details),
        moduleName: RouterModel.getModule(AppModule.StockClosing)
      }
    }]
  },

  getRootRoutes() {
    return [{
      path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.PrintDetails),
      name: RouterModel.getName(AppModule.StockClosing, AppModuleType.PrintDetails),
      component: StockClosingPrintDetail,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.PrintDetails),
        moduleName: RouterModel.getModule(AppModule.StockClosing)
      }
    }];
  }
}