<template>
  <div class="columns my-0">
    <div class="column is-3">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="WarehouseID" :data="searchData" :catalog="SearchCatalog"
        :options="warehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="SearchCatalog"
          @input="handleInput"
        />
      </div>
      
    <div class="column is-narrow">
      <kst-button reset-mode @click="handleReset"/>
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./PurchaseSearchCatalog.js";
import Info from '../PurchaseInfo.js';
import Model from '../PurchaseModel.js';

const CALLBACK_ONSKIP = 'onSkip';

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog,
    userData: {},
    Info: Info,
    Model: Model
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSkipPo() {
      this.$emit(CALLBACK_ONSKIP);
    }
  }
}
</script>