// import AppModule from "@/services/App/AppModule.js";
// import AppModuleType from "@/services/App/AppModuleType.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import CompanySpvPassCatalog from "./CompanySpvPassCatalog.js";

export default {
  Page: {
    VerSpvPass: {
      Title: "Input " + CompanySpvPassCatalog.spv_passwd.Label
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
  },
}