<template>
  <div class="kst-image">
    <Dialog ref="dlgImage" :src="src" :isLocal="isLocal"/>

    <template v-if="isPublic">
      <ViewPublic :src="image"/>
    </template>
    <template v-else-if="!src">
      <template v-if="readOnly">
        <NoImage :borderOff="borderOff" :sizeAuto="sizeAuto"/>
      </template>
      <template v-else>
        <Input :accept="accept" :index="index" @input="handleInput" @error="handleError"/>
      </template>
    </template>
    <template v-else>
      <template v-if="isLocal">
        <ViewLocal :src="src" :readOnly="readOnly" @click="handleClick" @delete="handleDelete"/>
      </template>
      <template v-else>
        <ViewOnline
          :borderOff="borderOff"
          :sizeAuto="sizeAuto"
          :src="src"
          :readOnly="readOnly"
          :thumbnail="thumbnail"
          @click="handleClick"
          @delete="handleDelete"
        />
      </template>
    </template>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

import Dialog from "./Image_Dialog";
import Input from "./Image_Input";
import NoImage from "./Image_NoImage";
import ViewLocal from "./Image_ViewLocal";
import ViewOnline from "./Image_ViewOnline";
import ViewPublic from "./Image_ViewPublic";

export default {
  components: {
    Dialog,
    Input,
    NoImage,
    ViewLocal,
    ViewOnline,
    ViewPublic
  },
  props: {
    src: String,
    accept: {
      type: String,
      default: AppCatalog.Image.Accept
    },
    // source: ONLY select 1 (one) (else: online)
    isLocal: Boolean,   // in browser cache
    isPublic: Boolean,  // in public folder web server
    // image: ONLY select 1 (one)
    brandImg: Boolean,
    companyDefaultImg: Boolean,
    // mode: can be combined
    borderOff: Boolean,
    hidePreview: Boolean,
    readOnly: Boolean,
    sizeAuto: Boolean,
    thumbnail: Boolean,
    // support array
    index: Number
  },
  computed: {
    image() {
      return this.getImage();
    }
  },
  methods: {
    handleInput(imgSrc, imgText) {
      this.$emit(this.$kst.Enum.Event.Input, imgSrc, imgText, this.index);
    },
    handleError(error) {
      this.$emit(this.$kst.Enum.Event.Error, error, this.index);
    },

    handleClick() {
      if (!this.hidePreview) {
        this.$refs.dlgImage.show();
      }
    },
    handleDelete() {
      this.$emit(this.$kst.Enum.Event.Delete, this.index);
    },

    getImage() {
      if (this.brandImg) {
        return AppCatalog.Image.Source.Brand;
      }
      else if (this.companyDefaultImg) {
        return AppCatalog.Image.Source.CompanyDefault;
      }

      return AppCatalog.Image.Source.Invalid;
    }
  }
}
</script>