<template>
  <div class="kst-print">
    <div class="kst-print-container p-4">
      <kst-skeleton :active="loading"/>

      <template v-if="!error">
        <template v-if="!loading">
          <template v-if="headerVisible">
            <div class="kst-print-header">
              <slot name="header"/>
            </div>
          </template>

          <template v-if="bodyVisible">
            <div class="kst-print-body">
              <slot name="default"/>
            </div>
          </template>

          <template v-if="footerVisible">
            <div class="kst-print-footer">
              <slot name="footer"/>
            </div>
          </template>

          <div class="kst-print-end">
            <slot name="end">
              {{ timestamps }}, {{ username }}
            </slot>
          </div>
        </template>
      </template>
      <template v-else>
        <div>{{ errorInfo }}</div>
        <div>
          <span class="has-text-weight-bold">{{ errorHelp }}</span>
          {{ error }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  mixins: [SlotMixins],
  props: {
    error: String,
    loading: Boolean
  },
  computed: {
    bodyVisible() {
      return this.slotHasContent("default");
    },
    errorHelp() {
      return AppCatalog.Print.Error.Help;
    },
    errorInfo() {
      return AppCatalog.Print.Error.Info;
    },
    footerVisible() {
      return this.slotHasContent("footer");
    },
    headerVisible() {
      return this.slotHasContent("header");
    },
    timestamps() {
      return AppCatalog.Print.PrintedAt + this.$kst.View.getTimestamp();
    },
    username() {
      return AppCatalog.Print.PrintedBy + this.$kst.Session.getName();
    }
  }
}
</script>