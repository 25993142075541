<template>
  <kst-table paginated view-mode
    :data="items"
    :loading="isListLoading"
    :failure="failureList"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="VendorName"
      :label="Catalog.VendorName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      field="Alias"
      :label="Catalog.Alias.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      field="Address"
      :label="VendorAddressCatalog.Address.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="VendorAddressCatalog"/>
    </b-table-column>

    <b-table-column
      field="ContactMobile"
      :label="VendorAddressCatalog.ContactMobile.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="VendorAddressCatalog"/>
    </b-table-column>

    <b-table-column
      field="ContactPhone"
      :label="VendorAddressCatalog.ContactPhone.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="VendorAddressCatalog"/>
    </b-table-column>

    <b-table-column
      field="ContactEmail"
      :label="VendorAddressCatalog.ContactEmail.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="VendorAddressCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../VendorCatalog.js";
import VendorAddressCatalog from "../VendorAddressCatalog.js";

export default {
  props: {
    failureList: String,
    isListLoading: Boolean,
    items: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    VendorAddressCatalog: VendorAddressCatalog
  })
}
</script>