import AppCatalog from "./AppCatalog.js";
import App_Data from "./App_Data.js";
import App_Value_Body from "./App_Value_Body.js";
import App_Xlsx_Body from "./App_Xlsx_Body.js";

export default {
  getFileName(module, moduleType) {
    // similar to getName() in "@/router/RouterModel.js"
    if (moduleType) {
      return moduleType.Name + " " + module.FullName + AppCatalog.Xlsx.Default.Extension;
    }
    return module.FullName + AppCatalog.Xlsx.Default.Extension;
  },
  getStatus(value, statusEnum) {
    return { [AppCatalog.Xlsx.Key.Value]: App_Data.getStatusLabel(value, statusEnum) };
  },
  getValue(field, data, catalog) {
    // not supported: IsRange
    if (catalog[field].IsRange) {
      return { [AppCatalog.Xlsx.Key.Value]: data[field] };
    }

    const value = data[field];
    const datatype = App_Value_Body.getType(field, catalog);

    const xlsxFormat = App_Xlsx_Body.getFormat(datatype);
    const xlsxType = App_Xlsx_Body.getType(datatype);
    const xlsxValue = App_Xlsx_Body.getValue(datatype, value);

    let valueObj = {};

    if (xlsxFormat) {
      valueObj[AppCatalog.Xlsx.Key.Format] = xlsxFormat;
    }

    if (xlsxType) {
      valueObj[AppCatalog.Xlsx.Key.Type] = xlsxType;
    }

    valueObj[AppCatalog.Xlsx.Key.Value] = xlsxValue !== null ? xlsxValue : value;

    return valueObj;
  }
}