<template>
  <kst-container class="kst-report-item">
    <kst-form :loading="isLoading" :title="title">
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          :transOptions="transOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <Export
        ref="refExport"
        :items="items"
      />

      <template v-if="isListLoading">
        <kst-skeleton :active="isListLoading"/>
      </template>
      <template v-else>
        <kst-container class="px-5 py-5 kst-report-item">
          <div class="columns">
            <div class="column flex">
              <div class="has-text-weight-bold">
                {{ getTitle }}
              </div>
            </div>
            <div class="column has-text-right">
              <kst-button action-mode
                class="is-primary mr-4"
                :module="Model.Module"
                :moduleType="Model.ModuleType.Print"
              />
              <kst-button action-mode
                class="is-primary"
                tag="button"
                :module="Model.Module"
                :moduleType="Model.ModuleType.ExportList"
                @click="handleExport"
              />
            </div>
          </div>
        </kst-container>

        <ItemList :items="items" :failureList="failureList" :isListLoading="isListLoading"/>
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Catalog from "../TransactionCatalog.js";
import Model from "../TransactionModel.js";
import Services from "../TransactionServices.js";

import Export from "../Export/TransactionExport"
import ItemList from "./Transaction_Items";
import Search from "./Transaction_Search";

export default {
  mixins: [AxiosMixin],
  components: {
    Export,
    Search,
    ItemList
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    searchData: {},
    filter: {},
    warehouseOptions: {},
    transOptions: {},
    items: null,
    isLoading: true,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    getTitle() {
      return Model.Report.TitleTransaction;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.loadWarehouse();
    },
    initSearch() {
      // search
      this.searchData = {
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll,
        DispatchID: null,
        TransID: this.$kst.Search.OptionAll,
        itemName: ""
      };

      // filter
      this.filter = {
        dispatchId: this.searchData.DispatchID,
        startDate: this.searchData.Date[0],
        endDate: this.searchData.Date[1],
        warehouseId: this.searchData.WarehouseID,
        transId: this.searchData.TransID
      };

      this.transOptions = Model.createTransOptions(Catalog.TransID.Label);
    },

    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, Catalog.WarehouseID.Label
      );
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadReport(counter, searchData) {
      this.items = null;
      this.failureList = null;

      const filter = this.populateFilter(searchData);

      const config = Services.getTransaction(filter);
      this.runAxios(config, counter, this.loadReport_Success, this.loadReport_Error);
    },
    loadReport_End() {
      this.isListLoading = false;
    },
    loadReport_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data.Transaction;
      for (let row of this.items) {
        row.Name = this.$kst.Data.getActivityType(row.ActivityTypeID)
      }
      this.loadReport_End();
    },
    loadReport_Error(message) {
      this.failureList = message;
      this.loadReport_End();
    },

    populateFilter(searchData) {
      let warehouseId = searchData.WarehouseID;
      let transId = searchData.TransID;
      let dispatchId = searchData.DispatchID;

      if (warehouseId === this.$kst.Search.OptionAll) {
        warehouseId = '';
      }
      if (transId === this.$kst.Search.OptionAll) {
        transId = '';
      }
      if (dispatchId === null) {
        dispatchId = '';
      }

      return {
        dispatchId: dispatchId,
        startDate: searchData.Date[0],
        endDate: searchData.Date[1],
        warehouseId: warehouseId,
        transId: transId
      };
    },

    handleExport() {
      this.$refs.refExport.exportExcel();
    },

    handleSearchSubmit() {
      this.isListLoading = true;
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.isListLoading = true;
      this.init();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>