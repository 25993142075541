import DispatchServices from "@/services/DispatchServices.js";
import SalesServices from "@/services/SalesServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";

export default {
  getSalesList: SalesServices.getList,
  getSalesDetails: SalesServices.getDetails,
  postSales: SalesServices.postSales,
  putSales: SalesServices.putSales,
  // dispatch
  getDispatchDetails: DispatchServices.getDetails,
  // warehouse
  getWarehouseList: WarehouseServices.getList
}