<template>
  <kst-modal
    class="kst-globalsearch"
    :width="1000"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
  >
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        @submit="handleSearch_Submit"
        @reset="handleSearch_Reset"
      />
    </kst-section>

    <kst-section search-result-mode select-one-help>
      <List
        :failureList="failureList"
        :isListLoading="isListLoading"
        :items="items"
      />
    </kst-section>
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterModel from "@/router/RouterModel.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "./GlobalSearchModel.js";
import Sample from "./GlobalSearchSample.js";
//import Services from "./GlobalSearchServices.js";

import Search from "./GlobalSearch_Search";
import List from "./GlobalSearch_List";

export default {
  mixins: [AxiosMixin, SnackbarMixin],
  components: {
    Search,
    List
  },
  data: () => ({
    searchData: {},
    items: [],
    active: false,
    isListLoading: false,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return RouterModel.getNavSubTitle(Model.Module);
    }
  },
  methods: {
    init(search) {
      this.initSearch();
      this.searchData.Search = search;
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    initSearch() {
      this.searchData = {
        Search: ""
      };
    },

    show(search) {
      this.init(search);
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    loadList() {
      this.isListLoading = true;
      setTimeout(() => {
        this.loadList_Success(Sample.Rows); // response.data
      }, 500);
    },
    loadList_Success(data) {
      this.items = data;
      this.isListLoading = false;
    },
    /*
    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
      }

      const config = Services.getGlobalSearchList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.loadList_End();
      this.$emit(this.$kst.Enum.Event.Load, data);
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },
    */

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    }
  }
}
</script>