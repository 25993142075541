<template>
  <div>
    <div class="columns is-mobile">
      <div class="column is-8 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.Total.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5 pr-3" field="Total" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "./StockClosingPrintDetailCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>