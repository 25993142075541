<template>
  <kst-print-table
    :data="items" 
    :failure="failureList"
    :loading="isListLoading">

    <b-table-column
      v-slot="{ column, row }"
      field="FullName"
      :label="StockClosingItemCatalog.FullName.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="StockClosingItemCatalog.SellPrice.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="SpecialPriceName"
      :label="StockClosingItemCatalog.SpecialPriceName.Label"
    >
      <kst-value 
        :field="column.field" 
        :data="row"
        :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="DiscountedValue"
      :label="StockClosingItemCatalog.DiscountedValue.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="StockClosingItemCatalog.Quantity.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="StockClosingItemCatalog.Total.Label"
    >
      <kst-value 
        :field="column.field" 
        :data="row" 
        :catalog="StockClosingItemCatalog"
      />
    </b-table-column>
  </kst-print-table>
</template>

<script>
import StockClosingItemCatalog from "../StockClosingItemCatalog.js";

export default {
  props: {
    items: Array,
    isListLoading: Boolean,
    failureList: null
  },
  data: () => ({
    StockClosingItemCatalog: StockClosingItemCatalog
  })
}
</script>