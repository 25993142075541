import AppCatalog from "@/services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: AppCatalog.Field.DispatchID.Label,
    Type: AppCatalog.Field.DispatchID.Type,
    Required: true,
    RelatedValue: AppCatalog.Field.DispatchID.RelatedValue,
    RelatedModule: AppCatalog.Field.DispatchID.RelatedModule,
    RelatedIsActive: AppCatalog.Field.DispatchID.RelatedIsActive
  },
  DispatchName: {
    Label: AppCatalog.Field.DispatchName.Label,
    Type: AppCatalog.Field.DispatchName.Type,
    ReadOnly: true
  },
  Packaging: {
    Label: AppCatalog.Field.PackagingName.Label,
    Type: AppCatalog.Field.PackagingName.Type,
    ReadOnly: true
  },
  PackagingName: {
    Label: AppCatalog.Field.PackagingName.Label,
    Type: AppCatalog.Field.PackagingName.Type,
    ReadOnly: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: AppCatalog.Field.Qty.Required,
    Valid: true
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    PrintLabel: AppCatalog.Field.SellPrice.PrintLabel,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Required: true,
    Output: AppCatalog.Field.SellPrice.Output
  },
  SellPriceNetto: {
    Label: AppCatalog.Field.SellPriceNetto.Label,
    Type: AppCatalog.Field.SellPriceNetto.Type,
    ReadOnly: true,
    Output: AppCatalog.Field.SellPriceNetto.Output
  },
  SpecialPriceID: {
    Label: AppCatalog.Field.SpecialPriceID.Label,
    Type: AppCatalog.Field.SpecialPriceID.Type,
    RelatedValue: AppCatalog.Field.SpecialPriceID.RelatedValue,
    RelatedModule: AppCatalog.Field.SpecialPriceID.RelatedModule,
    NullText: "Tanpa " + AppCatalog.Field.SpecialPriceID.Label
  },
  Total: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },

  // support
  HppTotal: {
    Label: "HPP Total",
    Type: AppCatalog.Field.HppTotal.Type,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly,
    Output: AppCatalog.Field.HppTotal.Output
  },
  ItemID: {
    Label: AppCatalog.Field.ItemID.Label,
    Type: AppCatalog.Field.ItemID.Type,
    ReadOnly: AppCatalog.Field.ItemID.ReadOnly,
    RelatedValue: AppCatalog.Field.ItemID.RelatedValue,
    RelatedModule: AppCatalog.Field.ItemID.RelatedModule,
    RelatedIsActive: AppCatalog.Field.ItemID.RelatedIsActive
  },
  MissingQty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },
  Stock: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  },
  SpecialPriceQty: {
    Label: AppCatalog.Field.SpecialPriceQty.Label,
    Type: AppCatalog.Field.SpecialPriceQty.Type,
    ReadOnly: AppCatalog.Field.SpecialPriceQty.ReadOnly
  }
}