import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  ReductionNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppModule.StockReduction.FullName,
    PrintLabel: AppCatalog.Field.Number.Label,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: AppCatalog.Field.Number.Highlight
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  WarehouseID: {
    Label: AppCatalog.Field.WarehouseID.Label,
    Type: AppCatalog.Field.WarehouseID.Type,
    Input: AppCatalog.Field.WarehouseID.Input,
    Required: true,
    RelatedValue: AppCatalog.Field.WarehouseID.RelatedValue,
    RelatedModule: AppCatalog.Field.WarehouseID.RelatedModule,
    RelatedIsActive: AppCatalog.Field.WarehouseID.RelatedIsActive
  },

  // validation
  StockMissingQty: {
    Label: AppCatalog.Field.QtyStock.Label,
    Type: AppCatalog.Field.QtyStock.Type,
    MaxValue: 0
  },

  // support
  HppTotal: {
    Label: "HPP Total",
    Type: AppCatalog.Field.HppTotal.Type,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly,
    Output: AppCatalog.Field.HppTotal.Output
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppModule.StockReduction.FullName,
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}