import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Warehouse,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    Search: AppModuleType.Search
  },

  Status: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    },
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    }
  },

  Search: {
    Fields: ["Name", "Address"]
  },

  createDetails() {
    return this.helper.createDetails(this.Status);
  },
  createOptions(items, optionAllText) {
    return this.helper.createOptions(items, optionAllText);
  },

  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },

  helper: {
    createDetails(statusEnum) {
      return {
        CompanyID: App.Session.getCompanyID(),
        Name: "",
        PIC: "",
        Address: "",
        NoPIC: "",
        IsActive: App.In.getBoolean(statusEnum.Active.ID),
        Comment: ""
      };
    },
    createOptions(items, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.Name = App.In.getString(details.Name);
      data.PIC = App.In.getString(details.PIC);
      data.Address = App.In.getString(details.Address);
      data.NoPIC = App.In.getString(details.NoPIC);
      data.IsActive = App.In.getBoolean(details.IsActive);
      data.Comment = App.In.getString(details.Comment);
    }
  }
}