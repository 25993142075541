<template>
  <div>
    <kst-output field="TypeName" :data="data" :catalog="Catalog"/>
    <kst-output field="Name" :data="data" :catalog="Catalog"/>

    <kst-output control-off field="CreatedBy" :data="data" :catalog="Catalog">
      <template #default>
        <kst-output expanded floating-label
          class="pr-2"
          field="CreatedByID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #right>
        <kst-output addons expanded floating-label
          field="CreatedDate" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>
  </div>
</template>

<script>
import Catalog from "../ItemCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>