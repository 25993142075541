import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.expensesub;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = {};
    options = Object.assign(options, params);
    if(!Object.prototype.hasOwnProperty.call(options, "active")) {
      options.active = 1
    }
    const attributes = ["active"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/expensecat/list" + urlParams
    }
  }
}