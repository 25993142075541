import AppCatalog from "@/services/App/AppCatalog.js";
import Catalog from "./HppAdjCatalog.js"
import Model from "./HppAdjModel.js";

export default {
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.Item.Module.FullName + "?",
    New_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ".",
    New_Details:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.Item.Module.FullName + ".",
    New_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.Item.Module.FullName + ".",
    PrintDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}