<template>
  <kst-table paginated view-mode
    :data="items"
    :failure="failureList"
    :selected.sync="userData.selected"
    :loading="isListLoading"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Vendor"
      :label="Catalog.VendorID.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

  </kst-table>
</template>

<script>
import Catalog from "../PurchaseCatalog.js";
import Model from "../PurchaseModel.js";

export default {
  props: {
    failureList: String,
    items: Array,
    isListLoading: Boolean,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>