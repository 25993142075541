<template>
  <kst-container class="kst-stockreduction-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <Details
          :userData="userData"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
        />
        <Items
          :specialPriceObj="specialPriceObj"
          :userData="userData"
          :userItems="userItems"
        />
        <Total :data="userData"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../StockReductionModel.js";
import Router from "../StockReductionRouter.js";
import Services from "../StockReductionServices.js";

import Details from "./StockReductionNew_Details";
import Items from "./StockReductionNew_Items";
import Total from "./StockReductionNew_Total";

export default {
  components: {
    Details,
    Items,
    Total
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    userItems: [],
    isLoading: false,
    saveLoading: null,
    warehouseOptions: {},
    specialPriceObj: {},
    cancelUrl: Router.Path.List,
    // error
    errors: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initReset();
      this.initLoad();
    },
    initReset() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.specialPriceObj = {};
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadNewData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadNewData() {
      const config = Services.getStockReductionNewData();
      this.runAxios(config, null,
        this.loadNewData_Success,
        this.loadNewData_Error,
        this.loadNewData_Invalid
      );
    },
    loadNewData_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.updateDetailsByNewData(this.userData, data);

      this.initLoad_End();
    },
    loadNewData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadNewData_Invalid(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postStockReduction(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>