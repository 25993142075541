import InputType from "@/services/Input/InputType.js";
import Catalog from "../SalesRecapCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: Catalog.Date.Label,
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: InputType.ID,
    Input: InputType.Select
  },
  CashierID: {
    Label: Catalog.CashierID.Label,
    Type: InputType.Select
  }
}