var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"failure":_vm.failureList,"loading":_vm.isListLoading,"selected":_vm.userData.selected},on:{"update:selected":function($event){return _vm.$set(_vm.userData, "selected", $event)}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('PriceIcon',{attrs:{"row":row,"sellPrice":_vm.sellPrice}})]}}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-shortdate","field":"InvoiceDate","label":_vm.Catalog.InvoiceDate.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"SONumber","label":_vm.Catalog.SONumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ClientID","label":_vm.Catalog.ClientID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"PONumber","label":_vm.Catalog.PONumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price has-text-weight-bold","field":"SellPrice","label":_vm.InvoiceItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }