<template>
  <div class="kst-report-items">
    <div class="columns">
      <div class="column is-half kst-report-item">
        <kst-container class="px-5 py-5 kst-report-item">
          <div class="has-text-weight-bold">
            <kst-value class="title is-5" field="TitleItem" :data="Model.ItemReport" :catalog="Catalog"/>
          </div>
        </kst-container>

        <kst-input floating-label field-only
            field="Name"
            :data="items"
            :catalog="Catalog"
        />

        <template v-if="items.Quantities.length === 1">
          <kst-table view-mode
            :data="rows"
            :loading="isListLoading"
          >
            <b-table-column sortable
              v-slot="{ column, row }"
              field="WarehouseName"
              :label="Catalog.WarehouseName.Label"
            >
              <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
            </b-table-column>

            <b-table-column sortable
              v-slot="{ row }"
              field="Stock"
              :label="Catalog.Stock.Label"
            >
              {{ row.TotalQuantity.Stock }} {{ row.UnitName }}
            </b-table-column>

            <b-table-column sortable numeric
              v-slot="{ column, row }"
              field="TotalHpp"
              :label="Catalog.TotalHpp.Label"
            >
              <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
            </b-table-column>
          </kst-table>
        </template>

        <template v-if="items.Quantities.length > 1">
          <kst-table detailed view-mode
            :data="rows"
            :loading="isListLoading"
          >
            <b-table-column sortable
              v-slot="{ column }"
              field="WarehouseName"
              :label="Catalog.WarehouseName.Label"
            >
              <kst-value ellipsis :field="column.field" :data="Model.Warehouse" :catalog="Catalog"/>
            </b-table-column>

            <b-table-column sortable
              v-slot="{ row }"
              field="Stock"
              :label="Catalog.Stock.Label"
            >
              {{ row.TotalQuantity.Stock }} {{ row.UnitName }}
            </b-table-column>

            <b-table-column sortable numeric
              v-slot="{ column, row }"
              field="TotalHpp"
              :label="Catalog.TotalHpp.Label"
            >
              <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
            </b-table-column>

            <template #detail="{ row }">
              <ItemDetail :ItemId="row.DispatchID" :items="items"/>
            </template>
          </kst-table>
        </template>
      </div>

      <div class="column is-half kst-report-item">
        <kst-container class="px-5 py-5 kst-report-item">
          <div class="has-text-weight-bold">
            <kst-value class="title is-5" field="TitleReport" :data="Model.ItemReport" :catalog="Catalog"/>
          </div>
        </kst-container>
        <kst-input floating-label addons
            field="Date" :data="searchData" :catalog="Catalog"
        />
        <div class="has-text-weight-bold">
          <kst-value class="title is-5" field="TitleTransaction" :data="Model.ItemReport" :catalog="Catalog"/>
        </div>
        <div class="divider"></div>

        <div class="columns">
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-regular">
              Total Qty Jual
            </div>
          </div>
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-semibold has-text-right">
              {{ Math.abs(items.TotalQuantity.Invoice) }} {{ items.UnitName }}
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-regular">
              Total Qty Terima
            </div>
          </div>
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-semibold has-text-right">
              {{ items.TotalQuantity.Purchase }} {{ items.UnitName }}
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-regular">
              Total Qty {{ Model.StockOpname.Module.FullName }}
            </div>
          </div>
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-semibold has-text-right">
              {{ items.TotalQuantity.StockAdj }} {{ items.UnitName }}
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-regular">
              Total Qty {{ Model.StockReduction.Module.FullName }}
            </div>
          </div>
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-semibold has-text-right">
              {{ items.TotalQuantity.StockRed }} {{ items.UnitName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-centered">
      <div class="column is-fullwidth">
        <kst-container class="px-5 py-5 kst-report-item">
          <div class="has-text-weight-bold">
            <kst-value class="title is-5" field="TitleListTransaction" :data="Model.ItemReport" :catalog="Catalog"/>
          </div>
        </kst-container>

        <ItemListTransaction :items="items.GroupHistory" :UnitName="items.UnitName" />
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../RptStockCardDetailsCatalog.js";
import Model from "../RptStockCardModel.js";

import ItemDetail from "./RptStockCard_Details_Stock";
import ItemListTransaction from "./RptStockCard_Details_Transactions";

export default {
  components: {
    ItemDetail,
    ItemListTransaction
  },
  props: {
    searchData: Object,
    items: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    isListLoading: true,
    rows: []
  }),
  created() {
    this.processData();
  },
   methods: {
    processData() {
      let dataRow = {
        WarehouseName: null,
        DispatchID: this.searchData.DispatchID,
        TotalHpp: this.items.TotalHpp,
        UnitName: this.items.UnitName,
        TotalQuantity: {
          Stock: this.items.TotalQuantity.Stock,
          Purchase: this.items.TotalQuantity.Purchase,
          Invoice: this.items.TotalQuantity.Invoice,
          StockAdj: this.items.TotalQuantity.StockAdj,
        }
      }

      if (this.items.Quantities.length === 1) { 
        dataRow.WarehouseName = this.items.Quantities[0].WarehouseName
      } else {
        dataRow.WarehouseName = "Semua Gudang"
      }

      this.rows.push(dataRow);
      this.isListLoading = false;
    },
   }
}
</script>

<style scoped lang="css" src="./_layout.css"/>