import { render, staticRenderFns } from "./ReceiveRelated.vue?vue&type=template&id=6b8abacd&scoped=true&"
import script from "./ReceiveRelated.vue?vue&type=script&lang=js&"
export * from "./ReceiveRelated.vue?vue&type=script&lang=js&"
import style0 from "./_layout.css?vue&type=style&index=0&id=6b8abacd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8abacd",
  null
  
)

export default component.exports