import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.delivery;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "delivery", "warehouse", "status", "field",
      "invoice", "withitems", "startDate", "endDate"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/logistics/list" + urlParams
    };
  },
  getDetails(id) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/logistics/" + cid + "/" + id
    };
  },
  getNewData(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "invoice"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/logistics/newdata/" + urlParams
    };
  },
  postDelivery(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/logistics",
      data: data
    };
  },
  patchDelivery(data) {
    return {
      method: App.Enum.Axios.Method.Patch,
      url: apiEndpoint + "/api/v1/logistics",
      data: data
    };
  }
}