import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./DailyReportModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name + " " + AppModule.DailyReport.FullName
        },
        Section: {
          List: Model.ModuleType.List.Name + " " + AppModuleType.Details.Name + ":",
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.List.Name + " " +
          Model.Module.FullName,
        Option: {
          List: Model.ModuleType.List.Name + " " + AppModule.DailyReport.FullName,
        }
      }
    }
  },
}