<template>
  <div>
    <kst-input field="name" :data="userData" :catalog="Catalog"/>
    <kst-input field="address" :data="userData" :catalog="Catalog"/>
    <kst-input field="phone" :data="userData" :catalog="Catalog"/>
    <kst-input field="email" :data="userData" :catalog="Catalog"/>
    <kst-input field="owner" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../CompanyCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>