import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import SpecialPriceDetails from "./Details/SpecialPriceDetails";
import SpecialPriceEdit from "./Edit/SpecialPriceEdit";
import SpecialPriceList from "../SpecialPrice/List/SpecialPriceList";
import SpecialPriceNew from "./New/SpecialPriceNew";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.List),
    New: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.New),
    Edit: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.Edit),
    Details: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.Details),
    Print: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.Details),
        name: RouterModel.getName(AppModule.SpecialPrice, AppModuleType.Details),
        component: SpecialPriceDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPrice, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.SpecialPrice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.List),
        name: RouterModel.getName(AppModule.SpecialPrice, AppModuleType.List),
        component: SpecialPriceList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPrice, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.SpecialPrice),
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.New),
        name: RouterModel.getName(AppModule.SpecialPrice, AppModuleType.New),
        component: SpecialPriceNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPrice, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.SpecialPrice),
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPrice, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.SpecialPrice, AppModuleType.Edit),
        component: SpecialPriceEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPrice, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.SpecialPrice),
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}