import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.rtninvoice;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "invoice", "warehouse", "field",
      "startDate", "endDate", "clientName", "returnInvoiceNumber", "soNumber",
      "withoutitems"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/returninvoice/list" + urlParams
    };
  },
  getDetails(id, params) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
    };
    options = Object.assign(options, params);
    const attributes = ["cid", "id", "withinvoice"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/returninvoice/details/" + urlParams
    };
  },
  getEditData(id, params) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
      uid: App.Session.getUserID()
    };
    options = Object.assign(options, params);
    const attributes = ["cid", "id", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/returninvoice/editdata/" + urlParams
    };
  },
  postRtnInvoice(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/returninvoice",
      data: data
    };
  },
  putRtnInvoice(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/returninvoice",
      data: data
    };
  }

  /*
  getDetailsData(id, params) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
      uid: App.Session.getUserID()
    };
    options = Object.assign(options, params);
    const attributes = ["cid", "id", "uid", "withinvoice"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/returninvoice/detailsdata/" + urlParams
    };
  },
  */
}