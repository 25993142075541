<template>
  <kst-section :title="summarySectionTitle">
    <kst-table view-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="InvoiceItemCatalog.Name.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="InvoiceItemCatalog.Packaging.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="kst-invoice-details-summary-Quantity"
        field="RequestedQuantity"
        :label="InvoiceItemCatalog.QtyInvoice.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="kst-invoice-details-summary-DeliveryQuantity"
        field="DeliveryQuantity"
        :label="InvoiceItemCatalog.DeliveryQuantity.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../InvoiceInfo.js";
import InvoiceItemCatalog from "../InvoiceItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    InvoiceItemCatalog: InvoiceItemCatalog
  }),
  computed: {
    summarySectionTitle() {
      return Info.Section.Summary.Title;
    }
  },
  methods: {
    getPackaging(item) {
      return item.PackagingName +
        ' (' + this.$options.filters.numberDotFormat(item.PackagingValue) + ')';
    }
  }
}
</script>