<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import DispatchCatalog from "../DispatchCatalog.js";
import Info from "../ItemInfo.js";
import Services from "../ItemServices.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel() {
      this.loadLoading = this.openLoading();
      this.loadList(this.searchData);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(items) {
      this.createBook();
      this.createSheet();

      this.addList(items);
      this.download(Info.Export.List.Excel.FileName);
      this.saveData_End();
    },

    loadList(searchData) {
      let options = {};
      if (searchData) {
        options.itemname = this.$kst.Out.getString(searchData.Search);
      }

      const config = Services.getDispatchList(options);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addList(items) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(DispatchCatalog.FullName);
      this.addColumnHeader(DispatchCatalog.SKU);
      this.addColumnHeader(DispatchCatalog.SellPrice);

      if (this.access.Field.BuyPrice !== false) {
        this.addColumnHeader(DispatchCatalog.BuyPrice);
      }

      this.addColumnHeader(DispatchCatalog.Weight);

      if (this.access.Field.HppTotal !== false) {
        this.addColumnHeader(DispatchCatalog.HppTotal);
      }

      this.addColumnHeader(DispatchCatalog.Barcode);
      this.addColumnHeader(DispatchCatalog.Vendor);
      this.addColumnHeader(DispatchCatalog.Description);

      // value
      for (const row of items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("FullName", row, DispatchCatalog);
        this.addCellValue("SKU", row, DispatchCatalog);
        this.addCellValue("SellPrice", row, DispatchCatalog);

        if (this.access.Field.BuyPrice !== false) {
          this.addCellValue("BuyPrice", row, DispatchCatalog);
        }

        this.addCellValue("Weight", row, DispatchCatalog);

        if (this.access.Field.HppTotal !== false) {
          this.addCellValue("HppTotal", row, DispatchCatalog);
        }

        this.addCellValue("Barcode", row, DispatchCatalog);
        this.addCellValue("Vendor", row, DispatchCatalog);
        this.addCellValue("Description", row, DispatchCatalog);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>