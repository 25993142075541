<template>
  <kst-print class="kst-invoice-print" :error="error" :loading="isLoading">
    <template #header>
      <div class="columns is-mobile">
        <div class="column is-half">
          <Company :data="companyData"/>
        </div>
        <div class="column is-half">
          <div class="is-size-3">{{ title }}</div>
        </div>
      </div>

      <div class="columns is-mobile">
        <div class="column">
          <Invoice :data="data"/>
        </div>
        <div class="column">
          <Client :data="data"/>
        </div>
      </div>
    </template>

    <Items class="mt-4" :data="data" :items="data.Items"/>
    <Total class="mt-4" :data="data" :items="data.Items" />
  </kst-print>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";
import Services from "../InvoiceServices.js";

import Company from "./InvoicePrintDetails_Company";
import Invoice from "./InvoicePrintDetails_Invoice";
import Client from "./InvoicePrintDetails_Client";
import Items from "./InvoicePrintDetails_Items";
import Total from "./InvoicePrintDetails_Total";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Invoice,
    Client,
    Items,
    Total
  },
  data: () => ({
    data: {},
    companyData: {},
    isLoading: false,
    // error
    error: null
  }),
  computed: {
    title() {
      return Model.Module.FullName;
    }
  },
  created(){
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.error = null;

      if (!id) {
        this.error = Info.Message.PrintDetails_ParamIncomplete;
        return;
      }

      this.data = {};
      this.companyData = {};

      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.error = null;

      this.loadDetails(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails(id) {
      const config = Services.getInvoiceDetailsData(id, { withdelivery: 1, withdeliveryitems: 1 });
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error, this.loadDetails_Invalid);
    },
    loadDetails_Success(data) {
      this.data = data.Details;
      Model.setDetailsBySpecialPrice(this.data, data);
      this.setPayReturn(this.data);
      
      for (const item of this.data.Items) {
        Model.updateItemSpecialPrice(item);
      }

      this.loadCompanyDetails();
    },
    loadDetails_Error(message) {
      this.error = message;
      this.initLoad_End();
    },
    loadDetails_Invalid(message) {
      this.error = message;
      this.initLoad_End();
    },

    loadCompanyDetails() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompanyDetails_Success, this.loadCompanyDetails_Error, this.loadCompanyDetails_Invalid);
    },
    loadCompanyDetails_Success(data) {
      this.companyData = data.data;
      this.initLoad_End();
    },
    loadCompanyDetails_Error(message) {
      this.errors = message;
      this.initLoad_End();
    },
    loadCompanyDetails_Invalid(message) {
      this.errors = message;
      this.initLoad_End();
    },

    setPayReturn(data) {
      if (data.PaymentValue) {
        this.data.PayReturn = data.PaymentValue - data.Total;
      }
    }
  }
}
</script>