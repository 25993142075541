import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import DeliveryItemCatalog from "@/components/Delivery/DeliveryItemCatalog.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.Delivery,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Status: AppModuleType.Status,
    Export: AppModuleType.Export,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    PrintSJ: AppModuleType.PrintSJ
  },

  /*** related ***/

  Item: {
    Module: AppModule.Item
  },

  ItemInvoice: {
    Title: AppModule.Item.FullName +' '+ AppModule.Invoice.FullName
  },

  Invoice: {
    Module: AppModule.Invoice
  },

  /*** property ***/

  Search: {
    Fields: ["LogisticsNumber", "VehicleIDNumber", "Destination"]
  },

  Status: {
    Draft: {
      ID: 1,
      Class: App.Enum.Status.Draft.Class,
      Label: App.Enum.Status.Draft.Label,
    },
    InDelivery: {
      ID: 2,
      Class: App.Enum.Status.InDelivery.Class,
      Label: App.Enum.Status.InDelivery.Label,
    },
    Delivered: {
      ID: 3,
      Class: App.Enum.Status.Delivered.Class,
      Label: App.Enum.Status.Delivered.Label,
    },
    // DEPRECATED
    Returned: {
      ID: 4,
      Class: App.Enum.Status.Returned.Class,
      Label: App.Enum.Status.Returned.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },
  createItemBlank() {
    return this.helper.createItemBlank();
  },
  createStatusOptions(optionAllText, newOptions) {
    return this.helper.createStatusOptions(optionAllText, this.Status, newOptions);
  },

  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },
  getItemInfo_Edit(item) {
    return this.helper.getItemInfo_Edit(item);
  },

  updateItem(data) {
    this.helper.updateItem(data);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },

  setDetailsByData(details, detailsData) {
    this.helper.setDetailsByData(details, detailsData);
  },
  setDetailsByInvoice(data, invoiceData) {
    this.helper.setDetailsByInvoice(data, invoiceData);
  },
  setItemByData(item, ItemData) {
    this.helper.setItemByData(item, ItemData);
  },
  setItemByInvoice(data, invoiceItem) {
    this.helper.setItemByInvoice(data, invoiceItem);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },
  setDetailsByStatus(details) {
    this.helper.setDetailsByStatus(details, this.Status);
  },
  setStatusChanged(details, detailsData) {
    this.helper.setStatusChanged(details, detailsData, this.Status);
  },
  setDefaultStatus(data) {
    this.helper.setDefaultStatus(data);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Items = items;
    return result;
  },
  populateItems(items) {
    return this.helper.populateItems(items);
  },

  validateItem(item) {
    this.helper.validateItem(item);
  },
  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  updateStatus(data) {
    this.helper.updateStatus(data);
  },
  updateDetailsBySO(data, invoice) {
    this.helper.updateDetailsBySO(data, invoice);
  },
  updateInput(data) {
    this.helper.updateInput(data);
  },
  
  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails() {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),

        SOID: null,
        SONumber: "",

        WarehouseID: null,
        WarehouseName: "",
        WarehouseIsActive: null,
        LogisticsNumber: null,
        IsAutoNumber: true,
        VehicleIDNumber: null,
        Comment: "",
        Destination: "",
        CreatedDate: null,
        InvoiceDate: null,
        DepartureDate: App.In.getDateToday(),
        ArrivalDate: null,
        CompletedDate: null,
        ReturnedDate: null,
        ReturnReason: "",
        ClientID: null,
        ClientAlias: "",
        Client: "",
        ShipmentFee: null,
        Items: [],
        CreatedByID: App.Session.getUserID(),
        
        Status: null,
        StatusName: "",
        StatusChanged: null
      };
    },

    createItem() {
      return {
        LogisticsID: null,
        DispatchID: null,
        Name: "",
        Quantity: null,
        PackagingName: "",
        PackagingValue: null,
        TotalQuantity: null,
        Price: null,
        SKU: "",
        ImageSrc: null,
        Weight: null,
        IsActive: true,
        UnitWeight: 0,

        // UI validation
        Quantity_Valid: true,
        Quantity_AvailableQuantity: true,

        // API validation
        DispatchID_Active: true,

        Packaging: null,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    createItemBlank() {
      return {
        DispatchID: null,
        Name: '',
        Quantity: '', // user input
        PackagingName: '',
        PackagingValue: 1,
        TotalQuantity: 0,
        SKU: "",
        Weight: 0,
        ImageSrc: null,
        IsActive: true,
        // additional fields
        AvailableQuantity: 0,
        UnitWeight: 0,

        // UI validation
        Quantity_Valid: true,
        Quantity_AvailableQuantity: true,

        // API validation
        DispatchID_Active: true,

        Packaging: null,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    createStatusOptions(optionAllText, statusEnum, options) {
      const rowId = "ID";
      const rowLabel = "Label";

      let items;

      if(options === statusEnum.Draft.ID) {
         items = [
          { [rowId]: statusEnum.Draft.ID, [rowLabel]: statusEnum.Draft.Label },
          { [rowId]: statusEnum.InDelivery.ID, [rowLabel]: statusEnum.InDelivery.Label }
        ];
      }else if(options === statusEnum.InDelivery.ID){
        items = [
          { [rowId]: statusEnum.Delivered.ID, [rowLabel]: statusEnum.Delivered.Label },
          { [rowId]: statusEnum.Returned.ID, [rowLabel]: statusEnum.Returned.Label }
        ];
      }else if(options === statusEnum.Delivered.ID) {
        items = [
          { [rowId]: statusEnum.Returned.ID, [rowLabel]: statusEnum.Returned.Label }
        ];
      }else{
        items = [
          { [rowId]: statusEnum.Draft.ID, [rowLabel]: statusEnum.Draft.Label },
          { [rowId]: statusEnum.InDelivery.ID, [rowLabel]: statusEnum.InDelivery.Label },
          { [rowId]: statusEnum.Delivered.ID, [rowLabel]: statusEnum.Delivered.Label },
          { [rowId]: statusEnum.Returned.ID, [rowLabel]: statusEnum.Returned.Label }
        ];
      }

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.IsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(DeliveryItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, DeliveryItemCatalog)
      );

      return infoList;
    },
    getItemInfo_Edit(item) {
      let infoList = [];

      // SKU
      infoList.push(DeliveryItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, DeliveryItemCatalog)
      );

      return infoList;
    },

    updateItem(data) {
      data.TotalQuantity = App.Data.getTotalQty(data.Quantity, data.PackagingValue);
      data.Weight = App.Data.getTotalWeight(data.Quantity, data.PackagingValue, data.UnitWeight);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },

    setDetailsByData(details, detailsData) {
      details.ID = detailsData.ID;
      // user input
      details.SOID = detailsData.SOID;
      details.SONumber = App.In.getString(detailsData.SONumber);

      details.WarehouseID = detailsData.WarehouseID;
      details.WarehouseIsActive = detailsData.WarehouseIsActive;
      details.WarehouseName = App.In.getString(detailsData.WarehouseName);
      details.LogisticsNumber = App.In.getString(detailsData.LogisticsNumber);
      details.VehicleIDNumber = App.In.getString(detailsData.VehicleIDNumber);
      details.Comment = App.In.getString(detailsData.Comment);
      details.Destination = App.In.getString(detailsData.Destination);
      details.CreatedDate = App.In.getDate(detailsData.CreatedDate);
      details.DepartureDate = App.In.getDate(detailsData.DepartureDate);
      details.ArrivalDate = App.In.getDate(detailsData.ArrivalDate);
      details.CompletedDate = App.In.getDate(detailsData.CompletedDate);
      details.ReturnedDate = App.In.getDate(detailsData.ReturnedDate);
      details.ArrivalDates = App.In.getDate(detailsData.ArrivalDate);
      details.CompletedDates = App.In.getDate(detailsData.CompletedDate);
      details.ReturnedDates = App.In.getDate(detailsData.ReturnedDate);
      details.ReturnReason = App.In.getString(detailsData.ReturnReason);
      details.Client = App.In.getString(detailsData.Client);
      details.ShipmentFee = App.In.getInteger(detailsData.ShipmentFee);

      details.Status = App.In.getInteger(detailsData.Status);
      details.StatusName = App.In.getString(detailsData.StatusName);
    },
    setDetailsByInvoice(data, invoiceData) {
      data.SOID = invoiceData.ID;
      data.WarehouseID = invoiceData.WarehouseID;
      data.WarehouseName = invoiceData.WarehouseName;
      data.SONumber = invoiceData.SONumber;
      data.InvoiceDate = App.In.getDate(invoiceData.InvoiceDate);
      data.ClientID = invoiceData.ClientID;
      data.Client = invoiceData.Client;
      data.ClientAlias = invoiceData.ClientAlias;
      data.DepartureDate = invoiceData.DeliveryDate == null 
        ? App.In.getDateToday() : App.In.getDate(invoiceData.DeliveryDate);
    },
    setItemByData(item, ItemData) {
      item.LogisticsID = ItemData.LogisticsID;
      item.DispatchID = ItemData.DispatchID;
      item.Name = App.In.getString(ItemData.Name);
      item.Quantity = App.In.getInteger(ItemData.Quantity);
      item.PackagingName = App.In.getString(ItemData.PackagingName);
      item.PackagingValue = App.In.getInteger(ItemData.PackagingValue);
      item.TotalQuantity = App.In.getInteger(ItemData.TotalQuantity);
      item.Price = App.In.getInteger(ItemData.Price);
      item.Weight = App.In.getInteger(ItemData.Weight);
      item.IsActive = App.In.getInteger(ItemData.IsActive);
    },
    setItemByInvoice(data, invoiceItem) {
      data.DispatchID = invoiceItem.DispatchID;
      data.Name = invoiceItem.Name;
      data.SKU = invoiceItem.SKU;
      data.PackagingName = invoiceItem.PackagingName;
      data.PackagingValue = invoiceItem.PackagingValue;
      data.Price = invoiceItem.Price;
      // additional fields
      data.IsActive = App.In.getBoolean(invoiceItem.DispatchIsActive);
    },
    setDetailsByStatus(details, statusEnum) {
      for (const key in statusEnum) {
        const statusObj = statusEnum[key];

        if (statusObj.ID === details.Status) {
          details.StatusName = statusObj.Label;
          return;
        }
      }
    },
    setStatusChanged(details, statusChanged, statusEnum) {
      details.StatusChanged = statusChanged;
      if(parseInt(details.Status) === statusEnum.InDelivery.ID) {
        details.ArrivalDate = App.In.getDateToday();
        details.CompletedDate = App.In.getDateToday();
        details.ArrivalDates = App.In.getDateToday();
        details.CompletedDates = App.In.getDateToday();
      } else if (parseInt(details.Status) === statusEnum.Delivered.ID) {
        details.ReturnedDates = App.In.getDateToday();
        details.ReturnedDate = App.In.getDateToday();
      }
    },
    setDefaultStatus(data) {
      data.StatusChanged = 1;
      data.Status = 1;
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.Quantity_Valid) {
        if (!item.DispatchID_Active) {
          newErrors.push(DeliveryItemCatalog.DispatchID.Label + " tidak aktif");
        }

        if (!item.Quantity_AvailableQuantity) {
          newErrors.push("Maksimum nilai " + DeliveryItemCatalog.Quantity.Label + " adalah " +
            App.Value.getValue("AvailableQuantity", item, DeliveryItemCatalog) + " " +
            "(" + DeliveryItemCatalog.AvailableQuantity.Label + ")"
          );
        }
      }

      item.Errors = newErrors;
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: App.Session.getCompanyID(),
        WarehouseID: data.WarehouseID,
        LogisticsNumber: data.IsAutoNumber ? null : data.LogisticsNumber,
        VehicleIDNumber: data.VehicleIDNumber,
        Client: data.Client,
        Comment: data.Comment,
        Destination: data.Destination,
        DepartureDate: data.DepartureDate,
        ArrivalDate: data.ArrivalDate,
        CompletedDate: data.CompletedDate,
        ReturnedDate: data.ReturnedDate,
        ReturnReason: data.ReturnReason,
        ShipmentFee: parseInt(data.ShipmentFee),
        Status: data.StatusChanged,
        SONumber: data.SONumber,
        SOID: data.SOID,
        CreatedByID: data.CreatedByID
      };
    },

    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        if (item.Quantity.trim() !== '') {
          resultItems.push({
            DispatchID: item.DispatchID,
            Name: item.Name,
            Quantity: parseInt(item.Quantity),
            PackagingName: item.PackagingName,
            PackagingValue: item.PackagingValue,
            TotalQuantity: item.TotalQuantity,
            Weight: item.Weight
          });
        }
      }

      return resultItems;
    },

    validateItem(item) {
      item.Quantity_Valid = true;
      item.Quantity_AvailableQuantity = true;

      const qty = App.Out.getInteger(item.Quantity);

        if (qty > item.AvailableQuantity) {
          item.Quantity_Valid = false;
          item.Quantity_AvailableQuantity = false;
        }
    },

    updateStatus(data) {
      data.Status = data.StatusChanged;
    },
    updateDetailsBySO(data, invoice) {
      data.ClientID = invoice.ClientID;
      data.ClientAlias = invoice.ClientAlias;
      data.Client = invoice.Client;
    },
    updateInput(data) {
      data.ArrivalDate = data.ArrivalDates;
      data.CompletedDate = data.CompletedDates;
      data.ReturnedDate = data.ReturnedDates;
    }
  }
} 