<template>
  <div>
    <kst-input load-on-focus field="Type" :data="userData" :catalog="Catalog"
      :options="typeOptions"
    />
    <kst-input field="Name" :data="userData" :catalog="Catalog" @input="handleName"/>
  </div>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Model from "../ItemModel.js";

export default {
  props: {
    userData: Object,
    userDispatches: Array,
    typeOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleName() {
      Model.updateDispatchesName(this.userDispatches, this.userData.Name);
    }
  }
}
</script>