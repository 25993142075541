var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"field":"DispatchID","cell-class":"kst-report-dailyreport-dispatch","label":_vm.Catalog.DispatchID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyBeginning","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyBeginning.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyReceive","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyReceive.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyTrf","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyTrf.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyAdj","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyAdj.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyReduction","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyReduction.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtySales","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtySales.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyLast","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyLast.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","field":"QtyClosing","cell-class":"kst-report-dailyreport-qty","label":_vm.Catalog.QtyClosing.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }