<template>
  <div v-if="!isListLoading">
    <div class="divider"></div>
    <div class="columns is-mobile is-variable">
      <div class="column is-one-quarter">
        <div class="title is-5 has-text-centered">
          Total
        </div>
      </div>
      <div class="column is-1 pl-5 has-text-centered">
        <div>
          <kst-value class="title is-5" field="QtyBeginningTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1 pl-5 has-text-centered">
        <div>
          <kst-value class="title is-5" field="QtyReceiveTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1 has-text-centered">
        <div>
          <kst-value class="title is-5" field="QtyTrfTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1 has-text-centered">
        <div>
          <kst-value class="title is-5" field="QtyAdjTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1 has-text-centered">
        <div>
          <kst-value class="title is-5" field="QtyReductionTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1 pl-4 has-text-centered">
        <div>
          <kst-value class="title is-5" field="QtySalesTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1">
        <div>
          <kst-value class="title is-5" field="QtyLastTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
      <div class="column is-1">
        <div>
          <kst-value class="title is-5" field="QtyClosingTotal" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../DailyReportCatalog.js";
import Model from "../DailyReportModel.js";

export default {
  props: {
    data: Object,
    failureList: String,
    isListLoading: Boolean,
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>