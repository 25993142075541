import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./InvoiceCatalog.js";
import InvoiceItemCatalog from "./InvoiceItemCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import SalesModel from "@/components/Sales/SalesModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.Invoice,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList,
    PrintPicking: AppModuleType.PrintPicking,
    PrintThermal: AppModuleType.PrintThermal,
    Search: AppModuleType.Search
  },

  /*** related ***/

  InvoiceItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      Select: AppModuleType.Select
    }
  },

  Sales: {
    Module: AppModule.Sales,
    ModuleType: {
      List: AppModuleType.List,
      Select: AppModuleType.Select
    }
  },

  Delivery: {
    Module: AppModule.Delivery,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  RtnInvoice: {
    Module: AppModule.RtnInvoice,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  SalesRecap: {
    Module: AppModule.SalesRecap,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  /*** property ***/

  DiscType: {
    None: {
      ID: 0,
      Label: "Tanpa Diskon",
    },
    Value: {
      ID: 1,
      Label: "Diskon (Rp)",
    },
    Percent: {
      ID: 2,
      Label: "Diskon (%)",
    }
  },

  PaymentType: {
    Cash: {
      ID: 1,
      Label: "Tunai"
    },
    BankTransfer: {
      ID: 2,
      Label: "Bank Transfer"
    }
  },

  Search: {
    Fields: ["SONumber", "PONumber", "Client"]
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label,
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label,
    }
  },
  Invoice: {
    TitleSearch: "Rincian Nota Penjualan"
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails(this.DiscType);
  },
  createItem() {
    return this.helper.createItem();
  },
  createStatusOptions(optionAllText) {
    return this.helper.createStatusOptions(optionAllText, this.Status);
  },
  createDiscTypeOptions(optionAllText) {
    return this.helper.createDiscTypeOptions(optionAllText, this.DiscType);
  },

  setDetailsByData(details, detailsData) {
    this.helper.setDetailsByData(details, detailsData, this.DiscType);
  },
  setDetailsByProfit(details) {
    this.helper.setDetailsByProfit(details);
  },
  setDetailsByStatus(details) {
    this.helper.setDetailsByStatus(details, this.Status);
  },
  setDetailsByClient(details, clientData) {
    this.helper.setDetailsByClient(details, clientData);
  },
  setDetailsBySales(details, salesData) {
    this.helper.setDetailsBySales(details, salesData, this.DiscType);
  },
  setDetailsBySpecialPrice(details, data) {
    return this.helper.setDetailsBySpecialPrice(details, data);
  },

  normDetailsByDisc(data) {
    this.helper.normDetailsByDisc(data, this.DiscType);
  },

  setItemByData(item, itemData) {
    this.helper.setItemByData(item, itemData);
  },
  setItemByDispatchDetails(item, dispatchData) {
    this.helper.setItemByDispatchDetails(item, dispatchData);
  },
  setItemByPackaging(item) {
    this.helper.setItemByPackaging(item);
  },
  setItemByPrice(item, itemData) {
    this.helper.setItemByPrice(item, itemData);
  },
  setItemBySales(item, salesItem) {
    this.helper.setItemBySales(item, salesItem);
  },
  setItemByStock(item, stockData) {
    this.helper.setItemByStock(item, stockData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  setItemErrors_Edit(item, field, errors) {
    this.helper.setItemErrors_Edit(item, field, errors);
  },
  setItemsByData(items, rows, invoiceItemGroup) {
    this.helper.setItemsByData(items, rows, invoiceItemGroup);
  },
  setItemsBySales(items, rows) {
    this.helper.setItemsBySales(items, rows);
  },
  setItemsByStock(items, stockItems) {
    this.helper.setItemsByStock(items, stockItems);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },
  getItemInfo_Edit(item) {
    return this.helper.getItemInfo_Edit(item);
  },
  getItemInfo_Related(item) {
    return this.helper.getItemInfo_Related(item);
  },
  getItemsForSelection(items, salesItems) {
    return this.helper.getItemsForSelection(items, salesItems);
  },
  getItemsSummary(details, deliveryList) {
    return this.helper.getItemsSummary(details, deliveryList);
  },

  getInvalidItems(details) {
    return this.helper.getInvalidItems(details);
  },

  clearDetailsByClient(details) {
    this.helper.clearDetailsByClient(details);
  },

  updateDetails(data, items) {
    this.helper.updateDetails(data, items, this.DiscType);
  },
  updateDetailsByDelivery(data) {
    this.helper.updateDetailsByDelivery(data);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },
  updateItemSpecialPrice(item) {
    this.helper.updateItemSpecialPrice(item);
  },
  updateItemsByProfit(items) {
    this.helper.updateItemsByProfit(items);
  },
  updateItemsByStock(items, stockItems) {
    this.helper.updateItemsByStock(items, stockItems);
  },
  updateItemsByStock_Edit(items, stockItems, invoiceItemGroup) {
    this.helper.updateItemsByStock_Edit(items, stockItems, invoiceItemGroup);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data, this.DiscType);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validationDeliveryDate(data) {
    if (data.IsNeedDelivery) {
      return {
        min_date: {
          target: App.In.getDateToday(),
          label: AppCatalog.Info.Today
        }
      };
    }

    return undefined;
  },
  validationDiscType(data) {
    return { max_value: data.TotalBruto };
  },
  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },
  validateItem(item) {
    this.helper.validateItem(item);
  },
  validateItemsByStock(data, items) {
    this.helper.validateItemsByStock(data, items);
  },
  validateItemsByDelivery(data, items, deliveryItems) {
    this.helper.validateItemsByDelivery(data, items, deliveryItems);
  },

  /*** method - Delivery ***/

  getDeliveryItems(deliveries) {
    return this.helper.getDeliveryItems(deliveries);
  },

  /*** external ***/

  createAccess() {
    return AppCatalog.Access.getDefault();
  },
  updateSalesDetails(salesDetails) {
    for (const salesItem of salesDetails.Items) {
      SalesModel.updateItem(salesItem);
    }
  },
  updateSalesDetails_Edit(salesDetails, invoiceItems) {
    let invoiceItem;
    for (const salesItem of salesDetails.Items) {
      SalesModel.updateItem(salesItem);
      salesItem.SpecialPriceOptions = this.getSpecialPriceOptions(
        salesItem.DispatchID, InvoiceItemCatalog.SpecialPriceName.Label
      );
      invoiceItem = App.Array.searchItem(invoiceItems, "SalesDraftItemID", salesItem.ID);
      if (invoiceItem) {
        salesItem.QtyAvailable += invoiceItem.RequestedQuantity;
      }
    }
  },
  
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  getSpecialPriceOptions(DispatchID, optionNon) {
    this.helper.getSpecialPriceOptions(DispatchID, optionNon);
  },

  setSpecialPriceOptions(items, optionNon) {
    this.helper.setSpecialPriceOptions(items, optionNon)
  },

  setDispatchSpecialPrice(data) {
    this.helper.setDispatchSpecialPrice(data);
  },

  getDispatchSpecialPrice() {
    return this.helper.getDispatchSpecialPrice();
  },

  helper: {
    createDetails(discTypeEnum) {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        SalesDraftID: null,
        WarehouseID: null,
        SONumber: "",
        IsAutoNumber: true,
        PONumber: "",
        ClientID: null,
        Client: "",
        ClientAddress: "",
        DiscType: discTypeEnum.None.ID,
        DiscValue: "",
        DiscPercent: "",
        Comment: "",
        IsNeedDelivery: true,
        DeliveryDate: App.In.getDateToday(),
        // defined by system
        WarehouseName: null,
        InvoiceDate: App.In.getDateToday(),
        ClientAlias: "",
        DraftNumber: "",
        DraftDate: null,
        TotalQty: 0,
        TotalBruto: 0,
        TotalDisc: 0,
        Total: 0,
        InvoiceList: null,
        InvoiceCount: 0,
        SpecialPriceCount: 0,
        CreatedByID: App.Session.getUserID(),
        // UI validation
        InvalidItems_vsStock: null,
        StockMissingQty: 0,
        InvalidItems_vsDelivery: null,
        DeliveryMissingQty: 0
      };
    },
    createItem() {
      return {
        ID: null,
        SalesDraftItemID: null,
        SalesDraftItemQty: null,
        // user input
        DispatchID: null,
        RequestedQuantity: "",
        PackagingName: "",
        Name: "",
        SellPrice: "0",
        // defined by system
        UseSpvPassword: false,
        PackagingValue: 1,
        TotalRequestedQuantity: 0,
        Price: 0,
        SellPriceNetto: 0,
        QuantityLeft: 0,
        Stock: 0,
        SKU: "",
        Barcode: "",
        DispatchFullName: "",
        DispatchSellPrice: 0,
        DispatchIsActive: true,
        Packaging: "",
        PackagingOptions: null,
        DeliveryQuantity: 0,
        SpecialPriceOptions: {},
        SpecialPriceID: -2,
        SpecialPriceName: "",
        SpecialPriceQty: 0,
        DiscountedValue: 0,
        // error
        Errors: [],
        ErrorsColl: {},
        // UI validation
        DispatchID_Valid: true,
        RequestedQuantity_Valid: true
      }
    },
    createStatusOptions(optionAllText, statusEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: statusEnum.Open.ID, [rowLabel]: statusEnum.Open.Label },
        { [rowId]: statusEnum.Closed.ID, [rowLabel]: statusEnum.Closed.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
    createDiscTypeOptions(optionAllText, discTypeEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
        { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
        { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByData(details, detailsData, discTypeEnum) {
      let invoiceDate;
      if (detailsData.InvoiceDate) {
        invoiceDate = App.In.getDate(detailsData.InvoiceDate);
      }
      else {
        // handle blank InvoiceDate
        invoiceDate = App.In.getDate(detailsData.DeliveryDate);
      }

      details.ID = detailsData.ID;
      // user input
      details.SalesDraftID = detailsData.SalesDraftID;
      details.WarehouseID = detailsData.WarehouseID;
      details.PONumber = App.In.getString(detailsData.PONumber);
      details.ClientID = detailsData.ClientID;
      details.Client = App.In.getString(detailsData.Client);
      details.ClientAddress = App.In.getString(detailsData.ClientAddress);
      details.IsNeedDelivery = App.In.getBoolean(detailsData.IsNeedDelivery);
      details.DeliveryDate = App.In.getDate(detailsData.DeliveryDate);
      details.Comment = App.In.getString(detailsData.Comment);
      // defined by system
      details.WarehouseName = detailsData.WarehouseName;
      details.InvoiceDate = invoiceDate;
      details.ClientAlias = detailsData.ClientAlias;
      details.SONumber = detailsData.SONumber;
      details.DraftNumber = detailsData.DraftNumber;
      details.DraftDate = detailsData.DraftDate;
      details.InvoiceList = detailsData.InvoiceList;
      details.InvoiceCount = detailsData.InvoiceCount;

      if (detailsData.DiscValue !== null) {
        details.DiscType = discTypeEnum.Value.ID;
        details.DiscValue = App.In.getInteger(detailsData.DiscValue);
      }
      else if (detailsData.DiscPercent !== null) {
        details.DiscType = discTypeEnum.Percent.ID;
        details.DiscPercent = detailsData.DiscPercent;

        this.normDetailsByDisc(details, discTypeEnum);
      }
      else {
        details.DiscType = discTypeEnum.None.ID;
      }
    },
    setDetailsByProfit(details) {
      details.TotalNetSales = details.Total - details.TotalReturn;

      let totalHppReturn = details.ReturnInvoice.reduce((acc, curr) => {
        return acc + curr.HppTotal;
      }, 0);
      details.TotalHpp = details.HppTotal - totalHppReturn;

      details.TotalProfit = details.TotalNetSales - details.TotalHpp;
    },
    setDetailsByStatus(details, statusEnum) {
      for (const key in statusEnum) {
        const statusObj = statusEnum[key];

        if (statusObj.ID === details.Status) {
          details.StatusName = statusObj.Label;
          return;
        }
      }
    },
    setDetailsByClient(details, clientData) {
      // user input
      details.ClientID = clientData.ClientID;
      details.Client = App.In.getString(clientData.ClientName);
      details.ClientAddress = App.In.getString(clientData.Address);
      // defined by system
      details.ClientAlias = clientData.Alias;
    },
    setDetailsBySales(details, salesData, discTypeEnum) {
      // user input
      details.SalesDraftID = salesData.ID;
      details.WarehouseID = salesData.WarehouseID;
      details.PONumber = App.In.getString(salesData.PONumber);
      details.ClientID = salesData.ClientID;
      details.Client = App.In.getString(salesData.ClientName);
      details.ClientAddress = App.In.getString(salesData.ClientAddress);
      // defined by system
      details.ClientAlias = salesData.ClientAlias;
      details.DraftNumber = salesData.DraftNumber;
      details.DraftDate = salesData.Date;

      if (salesData.DiscValue !== null) {
        details.DiscType = discTypeEnum.Value.ID;
        details.DiscValue = salesData.DiscValue;
      }
      else if (salesData.DiscPercent !== null) {
        details.DiscType = discTypeEnum.Percent.ID;
        details.DiscPercent = salesData.DiscPercent;

        this.normDetailsByDisc(details, discTypeEnum);
      }
      else {
        details.DiscType = discTypeEnum.None.ID;
      }
    },
    setDetailsBySpecialPrice(details, data) {
      details.SpecialPriceCount = data.SpecialPriceCount;
    },

    normDetailsByDisc(data, discTypeEnum) {
      if (data.DiscType === discTypeEnum.Percent.ID) {
        data.DiscPercent = App.Value.getValue("DiscPercent", data, Catalog);
      }
    },

    setItemByData(item, itemData, invoiceItemGroup) {
      const DispatchSpecialPrice = this.getDispatchSpecialPrice(
        itemData.DispatchID, itemData.SpecialPriceID
      ) || {};
      item.SalesDraftItemID = itemData.SalesDraftItemID;
      item.SalesDraftItemQty = itemData.SalesDraftItemQty;
      // user input
      item.DispatchID = itemData.DispatchID;
      item.Name = App.In.getString(itemData.Name);
      item.RequestedQuantity = App.In.getInteger(itemData.RequestedQuantity);
      item.PackagingName = itemData.PackagingName;
      item.SellPrice = App.In.getInteger(itemData.SellPrice);
      // defined by system
      item.SKU = itemData.SKU;
      item.PackagingValue = itemData.PackagingValue;
      item.DispatchFullName = itemData.DispatchFullName;
      item.DispatchSellPrice = itemData.DispatchSellPrice;
      item.DispatchIsActive = App.In.getBoolean(itemData.DispatchIsActive);
      item.SpecialPriceOptions = this.getSpecialPriceOptions(
        itemData.DispatchID, InvoiceItemCatalog.SpecialPriceName.Label
      );
      item.SpecialPriceQty = DispatchSpecialPrice.Quantity || 0;
      item.SpecialPriceID = itemData.SpecialPriceID || -2;
      item.SpecialPriceName = itemData.SpecialPriceName;
      item.SellPriceNetto = itemData.SellPriceNetto;
      item.UseSpvPassword = App.In.getBoolean(itemData.UseSpvPassword);

      // item from sales order cannot change packaging
      if (item.SalesDraftItemID === null) {
        item.PackagingOptions = ItemModel.getPackagingOptions(itemData.Packaging);
      }

      // stock
      if (!item.DispatchIsActive) {
        // deleted item
        if (Object.prototype.hasOwnProperty.call(invoiceItemGroup, item.DispatchID)) {
          item.QuantityLeft += invoiceItemGroup[item.DispatchID];
        }
      }
      else {
        item.QuantityLeft = itemData.DispatchQty;

        // +qty
        if (Object.prototype.hasOwnProperty.call(invoiceItemGroup, item.DispatchID)) {
          item.QuantityLeft += invoiceItemGroup[item.DispatchID];
        }
      }
    },
    setItemByDispatchDetails(item, dispatchData) {
      item.Name = dispatchData.FullName;
      item.SKU = dispatchData.SKU;
      item.SellPrice = App.In.getInteger(dispatchData.SellPrice);
      item.DispatchSellPrice = dispatchData.SellPrice;
      item.PackagingOptions = ItemModel.getPackagingOptions(dispatchData.Packaging);
    },
    setItemByPackaging(item) {
      if (!item.PackagingName) {
        if (item.PackagingOptions.rows.length > 0) {
          const packaging = item.PackagingOptions.rows[0];
          item.PackagingName = packaging.ItemUnitName;
          item.PackagingValue = packaging.ItemUnitAmount;
        }
      }
    },
    setItemByPrice(item, itemData) {
      // user input
      item.SellPrice = App.In.getInteger(itemData.SellPrice);
    },
    setItemBySales(item, salesItem) {
      const qtyAvailable = salesItem.QtyAvailable;
      item.SalesDraftItemID = salesItem.ID;
      item.SalesDraftItemQty = qtyAvailable;
      // user input
      item.DispatchID = salesItem.DispatchID;
      item.Name = App.In.getString(salesItem.DispatchName);
      item.RequestedQuantity = App.In.getInteger(qtyAvailable);
      item.PackagingName = salesItem.PackagingName;
      item.SellPrice = App.In.getInteger(salesItem.SellPrice);
      // defined by system
      item.SKU = salesItem.SKU;
      item.PackagingValue = salesItem.PackagingValue;
      item.QuantityLeft = salesItem.DispatchQty;
      item.DispatchFullName = salesItem.FullName;
      item.DispatchSellPrice = salesItem.DispatchSellPrice;
      item.DispatchIsActive = App.In.getBoolean(salesItem.DispatchIsActive);
      item.SpecialPriceOptions = this.getSpecialPriceOptions(
        item.DispatchID, InvoiceItemCatalog.SpecialPriceName.Label
      );
    },
    setItemByStock(item, stockData) {
      item.DispatchID = stockData.DispatchID;
      item.QuantityLeft = stockData.Quantity;
      item.SpecialPriceOptions = this.getSpecialPriceOptions(
        item.DispatchID, InvoiceItemCatalog.SpecialPriceName.Label
      );
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.DispatchID_Valid) {
        if (!item.DispatchIsActive) {
          newErrors.push(InvoiceItemCatalog.DispatchID.Label + " tidak aktif");
        }
      }

      if (!item.RequestedQuantity_Valid) {
        if (!item.RequestedQuantity_vsSales) {
          newErrors.push("Maximum nilai " + InvoiceItemCatalog.RequestedQuantity.Label + " adalah " +
            App.Value.getValue("SalesDraftItemQty", item, InvoiceItemCatalog) + " " +
            "(" + InvoiceItemCatalog.SalesDraftItemQty.Label + ")"
          );
        }
      }

      item.Errors = newErrors;
    },
    setItemErrors_Edit(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.DispatchID_Valid) {
        if (!item.DispatchIsActive) {
          newErrors.push(InvoiceItemCatalog.DispatchID.Label + " tidak aktif");
        }
      }

      if (!item.RequestedQuantity_Valid) {
        if (!item.RequestedQuantity_vsSales) {
          newErrors.push("Maximum nilai " + InvoiceItemCatalog.RequestedQuantity.Label + " adalah " +
            App.Value.getValue("SalesDraftItemQty", item, InvoiceItemCatalog) + " " +
            "(" + InvoiceItemCatalog.SalesDraftItemQty.Label + ")"
          );
        }
      }

      item.Errors = newErrors;
    },

    setItemsByData(items, rows, invoiceItemGroup) {
      App.Array.truncate(items);

      for (const row of rows) {
        // exclude returned item (old data)
        if (row.RequestedQuantity > 0) {
          let item = this.createItem();
          this.setItemByData(item, row, invoiceItemGroup);
          this.updateItem(item);
          items.push(item);
        }
      }
    },
    setItemsBySales(items, rows) {
      App.Array.truncate(items);

      for (const row of rows) {
        if (row.IsClosed === 0) {
          let item = this.createItem();
          this.setItemBySales(item, row);
          this.updateItem(item);
          items.push(item);
        }
      }
    },
    setItemsByStock(items, stockItems) {
      if (stockItems === undefined || stockItems === null) {
        return;
      }
      if (stockItems.length === 0) {
        return;
      }

      const dispatchId = stockItems[0].DispatchID;
      const qty = stockItems[0].Quantity;

      // update stock in all items
      for (const item of items) {
        if (item.DispatchID === dispatchId) {
          item.QuantityLeft = qty;

          // normalize stock
          this.updateItem(item);
        }
      }
    },

    getItemInfo(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // Sales
      if (item.SalesDraftItemID) {
        infoList.push(AppCatalog.Message.SalesItem);

        // SalesDraftItemQty
        infoList.push(InvoiceItemCatalog.SalesDraftItemQty.Label + ": " +
          App.Value.getValue("SalesDraftItemQty", item, InvoiceItemCatalog)
        );
      }

      // SKU
      infoList.push(InvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvoiceItemCatalog)
      );

      // Stock
      infoList.push(InvoiceItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, InvoiceItemCatalog) + " " + item.PackagingName
      );

      if (item.SpecialPriceID !== -2) {
        // SpecialPriceQty
        infoList.push(InvoiceItemCatalog.SpecialPriceQty.Label + ": " +
          App.Value.getValue("StockSpecialPrice", item, InvoiceItemCatalog) + " " + item.PackagingName
        );
      }

      return infoList;
    },
    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // Sales
      if (item.SalesDraftItemID) {
        infoList.push(AppCatalog.Message.SalesItem);

        // SalesDraftItemQty
        infoList.push(InvoiceItemCatalog.SalesDraftItemQty.Label + ": " +
          App.Value.getValue("SalesDraftItemQty", item, InvoiceItemCatalog)
        );
      }

      // SKU
      infoList.push(InvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvoiceItemCatalog)
      );

      // Special Price
      if (item.UseSpvPassword) {
        infoList.push(InvoiceItemCatalog.DiscountedValue.Label + " Awal: " +
        App.Value.getValue("DiscountedValue", item, InvoiceItemCatalog));
      }

      return infoList;
    },
    getItemInfo_Edit(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // Sales
      if (item.SalesDraftItemID) {
        infoList.push(AppCatalog.Message.SalesItem);

        // SalesDraftItemQty
        infoList.push(InvoiceItemCatalog.SalesDraftItemQty.Label + ": " +
          App.Value.getValue("SalesDraftItemQty", item, InvoiceItemCatalog)
        );
      }

      // SKU
      infoList.push(InvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvoiceItemCatalog)
      );

      // Stock
      infoList.push(InvoiceItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, InvoiceItemCatalog) + " " + item.PackagingName
      );

      // Special Price
      if (item.UseSpvPassword) {
        infoList.push(InvoiceItemCatalog.DiscountedValue.Label + " Awal: " +
        App.Value.getValue("DiscountedValue", item, InvoiceItemCatalog));
      }

      return infoList;
    },
    getItemInfo_Related(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // Sales
      if (item.SalesDraftItemID) {
        infoList.push(AppCatalog.Message.SalesItem);
      }

      // SKU
      infoList.push(InvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvoiceItemCatalog)
      );

      return infoList;
    },
    getItemsForSelection(items, salesItems) {
      let resultItems = [];
      let item;

      for (const salesItem of salesItems) {
        item = App.Array.searchItem(items, "SalesDraftItemID", salesItem.ID);
        if (!item) {
          resultItems.push(salesItem);
        }
      }

      return resultItems;
    },
    getItemsSummary(details, deliveryList) {
      let deliveryItems = this.getItemsSummary_getDeliveryItems(deliveryList);
      let invoiceItems = this.getItemsSummary_getInvoiceItems(details.Items);
      return this.getItemsSummary_populateInvoiceItems(invoiceItems, deliveryItems);
    },
    getItemsSummary_getDeliveryItems(deliveryList) {
      let deliveryItems = [];

      for (const delivery of deliveryList) {
        for (const item of delivery.Items) {
          deliveryItems.push(item);
        }
      }

      return deliveryItems;
    },
    getItemsSummary_getInvoiceItems(items) {
      let invoiceItems = [];

      for (const item of items) {
        // exclude minus qty from Return Delivery Orders
        if (item.TotalRequestedQuantity > 0) {
          invoiceItems.push(item);
        }
      }

      return invoiceItems;
    },
    getItemsSummary_populateInvoiceItems(invoiceItems, deliveryItems) {
      const invoiceKeyList = ["DispatchID", "PackagingName"];
      const invoiceKeyData = "RequestedQuantity";
      const deliveryKeyList = ["DispatchID", "PackagingName"];
      const deliveryKeyData = "Quantity";

      const groupedItems = this.getItemsSummary_populateInvoiceItems_group2List(
        invoiceItems, invoiceKeyList, invoiceKeyData,
        deliveryItems, deliveryKeyList, deliveryKeyData
      );

      let resultItems = [];
      for (const resultKey in groupedItems) {
        const result = groupedItems[resultKey];

        const invoiceItem = result.base;
        const deliveryItem = result.source;

        const invoiceData = invoiceItem.data;
        let item = this.createItem();
        item.DispatchID = invoiceData.DispatchID;
        item.Name = invoiceData.Name;
        item.RequestedQuantity = invoiceItem.value;
        item.PackagingName = invoiceData.PackagingName;
        item.PackagingValue = invoiceData.PackagingValue;
        item.DeliveryQuantity = deliveryItem.value;
        this.updateItemByPackaging(item);

        this.updateItem(item);
        resultItems.push(item);
      }

      return resultItems;
    },
    getItemsSummary_populateInvoiceItems_group2List(
    baseItemList, baseKeyList, baseKeyData,
    sourceItemList, sourceKeyList,sourceKeyData) {
      let baseList = this.getItemsSummary_populateInvoiceItems_group2List_groupItems(
        baseItemList, baseKeyList, baseKeyData);
      let sourceList = this.getItemsSummary_populateInvoiceItems_group2List_groupItems(
        sourceItemList, sourceKeyList, sourceKeyData);

      let resultList = {};
      for (const baseKey in baseList) {
        const base = baseList[baseKey];
        let source = { value: 0, data: null };

        if (Object.prototype.hasOwnProperty.call(sourceList, baseKey)) {
          source = sourceList[baseKey];
        }

        resultList[baseKey] = { base: base, source: source };
      }

      return resultList;
    },
    getItemsSummary_populateInvoiceItems_group2List_groupItems(itemList, keyList, keyData) {
      let groupedItemList = {};

      for (const item of itemList) {
        const keySet =
          this.getItemsSummary_populateInvoiceItems_group2List_groupItems_getKeySet(item, keyList);

        if (Object.prototype.hasOwnProperty.call(groupedItemList, keySet)) {
          groupedItemList[keySet].value += item[keyData];
        }
        else {
          groupedItemList[keySet] = {
            value: item[keyData],
            data: item
          };
        }
      }
      return groupedItemList;
    },
    getItemsSummary_populateInvoiceItems_group2List_groupItems_getKeySet(item, keyList) {
      let keySet = "";

      for (const key of keyList) {
        if (keySet !== "") {
          keySet += "_";
        }
        keySet += item[key];
      }
      return keySet;
    },

    getInvalidItems(details) {
      let items = [];

      if (details.InvalidItems_vsStock) {
        for (const invalidItem of details.InvalidItems_vsStock) {
          items.push("Stok " + invalidItem.Name + " tidak mencukupi (hanya tersedia " +
            App.Value.getValue("Stock", invalidItem, InvoiceItemCatalog) + " " +
            App.Value.getValue("PackagingName", invalidItem, InvoiceItemCatalog) +  ")"
          );
        }
      }

      if (details.InvalidItems_vsDelivery) {
        for (const invalidItem of details.InvalidItems_vsDelivery) {
          items.push(invalidItem.Name + " memiliki kekurangan " +
            Catalog.DeliveryMissingQty.Label + ": " +
            App.Value.getValue("RequestedQuantity", invalidItem, InvoiceItemCatalog) + " " +
            invalidItem.Packaging
          );
        }
      }

      return items;
    },

    clearDetailsByClient(details) {
      details.ClientID = null;
      details.Client = "";
      details.ClientAlias = "";
      details.ClientAddress = "";
    },

    updateDetails(data, items, discTypeEnum) {
      let total = 0;

      // TotalBruto
      for (const item of items) {
        total += item.Price;
      }
      data.TotalBruto = total;

      // TotalDisc
      if (data.DiscType === discTypeEnum.Value.ID) {
        data.TotalDisc = App.JS.parseInt(data.DiscValue);
        total -= data.TotalDisc;
      }
      else if (data.DiscType === discTypeEnum.Percent.ID) {
        data.TotalDisc = App.Data.getDiscPercent_Value(total, data.DiscPercent);
        total -= data.TotalDisc;
      }

      // Total
      data.Total = total;
    },
    updateDetailsByDelivery(data) {
      if (data.IsNeedDelivery) {
        if (data.DeliveryDate === null) {
          data.DeliveryDate = App.In.getDateToday();
        }
      }
    },

    updateItem(item) {
      if (item.PackagingOptions) {
        let packagingValue = ItemModel.getPackagingValue(
          item.PackagingOptions.rows, item.PackagingName
        );

        if (packagingValue === undefined) {
          item.PackagingName = "";
          item.PackagingValue = 1;
        }
        else {
          item.PackagingValue = packagingValue;
        }
      }

      if (item.SpecialPriceID === -2) {
        item.SpecialPriceName = "";
      }
      else {
        item.SpecialPriceName =
          App.Array.searchValue(item.SpecialPriceOptions.rows, "ID", item.SpecialPriceID, "Name");
      }

      this.updateItemByPackaging(item);
      this.updateID(item);
      this.updateSpecialPriceQty(item);
      item.Stock = App.Data.getQtyByPackaging(item.QuantityLeft, item.PackagingValue);
      item.StockSpecialPrice = App.Data.getQtyByPackaging(item.SpecialPriceQty, item.PackagingValue);
      item.TotalRequestedQuantity = App.Data.getTotalQty(item.RequestedQuantity, item.PackagingValue);
      item.DiscountedValue = item.SellPrice -
        App.Data.getDiscPercent_Value(item.SellPrice, this.getDiscountPrice(item.SpecialPriceID));
      item.Price = App.Data.getTotal(
        item.RequestedQuantity,
        item.PackagingValue,
        (item.UseSpvPassword ? item.SellPriceNetto : item.DiscountedValue)
      );
      item.SellPriceNetto = (item.UseSpvPassword ? item.SellPriceNetto : item.DiscountedValue);
    },
    updateSpecialPriceQty(item) {
      const DispatchSpecialPrice =
        this.getDispatchSpecialPrice(item.DispatchID, item.SpecialPriceID) || {};
      item.SpecialPriceQty = DispatchSpecialPrice.Quantity || 0;
    },
    updateID(item) {
      const DispatchSpecialPrice =
        this.getDispatchSpecialPrice(item.DispatchID, item.SpecialPriceID) || {};
      const SpecialPriceItemID = (DispatchSpecialPrice.DispatchID || "") + "_" + item.SpecialPriceID;
      const ItemID = (item.SpecialPriceID == -2 ? SpecialPriceItemID : item.DispatchID);
      item.ID = ItemID;
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    updateItemSpecialPrice(item) {
      item.SpecialPriceID = item.SpecialPriceID || null;
      item.SpecialPriceName = item.SpecialPriceName ||
        App.Search.LabelNone + InvoiceItemCatalog.SpecialPriceName.Label;
      item.DiscountedValue = item.SellPrice -
        App.Data.getDiscPercent_Value(item.SellPrice, item.SpecialPriceDiscPercent);
    },
    updateItemsByProfit(items) {
      for (let item of items) {
        // move discount to invoice-level
        item.Discount = 0;
        item.TotalPrice = item.Price;
        item.Profit = item.Price - item.HppTotal;
      }
    },
    updateItemsByStock(items, stockItems) {
      // reupdate stock, isActive when user search for items
      for (const item of items) {
        for (const stock of stockItems) {
          if (stock.DispatchID === item.DispatchID) {
            item.DispatchIsActive = true;
            item.QuantityLeft = stock.Quantity;
            this.updateItem(item);
            break;
          }
        }
      }
    },
    updateItemsByStock_Edit(items, stockItems, invoiceItemGroup) {
      // reupdate stock, isActive when user search for items
      for (const item of items) {
        // +qty init
        if (!item.DispatchIsActive) {
          if (Object.prototype.hasOwnProperty.call(invoiceItemGroup, item.DispatchID)) {
            item.QuantityLeft += invoiceItemGroup[item.DispatchID];
          }
          continue;
        }

        for (const stock of stockItems) {
          if (stock.DispatchID === item.DispatchID) {
            item.DispatchIsActive = true;
            item.QuantityLeft = stock.Quantity;

            // +qty
            if (Object.prototype.hasOwnProperty.call(invoiceItemGroup, item.DispatchID)) {
              item.QuantityLeft += invoiceItemGroup[item.DispatchID];
            }

            break;
          }
        }

        this.updateItem(item);
      }
    },

    populateDetails(data, discTypeEnum) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        SalesDraftID: data.SalesDraftID,
        WarehouseID: data.WarehouseID,
        SONumber: App.Out.getString(data.SONumber),
        PONumber: App.Out.getString(data.PONumber),
        ClientID: data.ClientID,
        Client: App.Out.getString(data.Client),
        ClientAddress: App.Out.getString(data.ClientAddress),
        DiscValue: (data.DiscType === discTypeEnum.Value.ID ? parseInt(data.DiscValue) : null),
        DiscPercent: (data.DiscType === discTypeEnum.Percent.ID ? parseFloat(data.DiscPercent) : null),
        InvoiceDate: App.Out.getDate(data.InvoiceDate),
        Comment: App.Out.getString(data.Comment),
        IsNeedDelivery: App.Out.getBoolean(data.IsNeedDelivery),
        DeliveryDate: (data.IsNeedDelivery ? data.DeliveryDate : null),
        CreatedByID: data.CreatedByID
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          SalesDraftItemID: item.SalesDraftItemID,
          DispatchID: item.DispatchID,
          Name: App.Out.getString(item.Name),
          RequestedQuantity: App.Out.getInteger(item.RequestedQuantity),
          TotalRequestedQuantity: App.Out.getInteger(item.TotalRequestedQuantity),
          SpecialPriceID: item.SpecialPriceID == 0 ? null : item.SpecialPriceID,
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          SellPrice: App.Out.getInteger(item.SellPrice),
          DispatchSellPrice: App.Out.getInteger(item.DispatchSellPrice),
          SellPriceNetto: App.Out.getInteger(item.SellPriceNetto)
        });
      }

      return resultItems;
    },

    validateItem(item) {
      item.DispatchID_Valid = true;
      item.RequestedQuantity_Valid = true;
      item.RequestedQuantity_vsSales = true;

      if (!item.DispatchIsActive) {
        item.DispatchID_Valid = false;
      }

      if (item.SalesDraftItemID !== null) {
        const qty = App.Out.getInteger(item.RequestedQuantity);

        if (qty !== null) {
          if (qty > item.SalesDraftItemQty) {
            item.RequestedQuantity_Valid = false;
            item.RequestedQuantity_vsSales = false;
          }
        }
      }
    },
    validateItemsByStock(data, items) {
      let activeItems = [];

      // reset indicator
      data.StockMissingQty = 0;

      data.InvalidItems_vsStock = null;
      let invalidItems = [];

      // filter: deleted-item
      for (const item of items) {
        if (item.DispatchIsActive) {
          activeItems.push(item);
        }
      }
      
      this.validateItemsByStock_Stock(data, activeItems, invalidItems);
      this.validateItemsByStock_SpecialPrice(data, activeItems, invalidItems);

      if (invalidItems.length > 0) {
        data.InvalidItems_vsStock = invalidItems;
      }
    },
    validateItemsByStock_Stock(data, activeItems, invalidItems) {
      let validItems = activeItems;

      const fieldKeys = "DispatchID";
      const fieldValues = [
        { field: "TotalRequestedQuantity", type: App.Pivot.Type.SUM },
        { field: "QuantityLeft", type: App.Pivot.Type.FIRST },
        { field: "Stock", type: App.Pivot.Type.FIRST },
        { field: "Name", type: App.Pivot.Type.FIRST },
        { field: "PackagingName", type: App.Pivot.Type.FIRST }
      ];
      const pivotItems = App.Pivot.create(validItems, fieldKeys, fieldValues);

      let qtyLeft = 0;
      for (const pivotItem of pivotItems) {
        qtyLeft = pivotItem.QuantityLeft;
        if (pivotItem.TotalRequestedQuantity > qtyLeft) {
          data.StockMissingQty = 1;
          invalidItems.push({
            Name: pivotItem.Name,
            RequestedQuantity: (pivotItem.TotalRequestedQuantity - qtyLeft),
            Stock: pivotItem.Stock,
            PackagingName: pivotItem.PackagingName
          });
        }
      }
    },
    validateItemsByStock_SpecialPrice(data, activeItems, invalidItems) {
      let item, validItems = [];
      for (item of activeItems) {
        if (item.SpecialPriceID !== -2) {
          validItems.push(item);
        }
      }

      const fieldKeys = ["DispatchID", "SpecialPriceID"];
      const fieldValues = [
        { field: "TotalRequestedQuantity", type: App.Pivot.Type.SUM },
        { field: "SpecialPriceName", type: App.Pivot.Type.FIRST },
        { field: "Name", type: App.Pivot.Type.FIRST },
        { field: "SpecialPriceQty", type: App.Pivot.Type.FIRST },
        { field: "StockSpecialPrice", type: App.Pivot.Type.FIRST },
        { field: "PackagingName", type: App.Pivot.Type.FIRST }
      ];
      const pivotItems = App.Pivot.create(validItems, fieldKeys, fieldValues);

      let qtyLeft = 0;
      for (const pivotItem of pivotItems) {
        qtyLeft = pivotItem.SpecialPriceQty;
        if (pivotItem.TotalRequestedQuantity > qtyLeft) {
          data.StockMissingQty = 1;
          invalidItems.push({
            Name: pivotItem.Name + " (" + pivotItem.SpecialPriceName + ")",
            RequestedQuantity: (pivotItem.TotalRequestedQuantity - qtyLeft),
            Stock: pivotItem.StockSpecialPrice,
            PackagingName: pivotItem.PackagingName
          });
        }
      }
    },
    validateItemsByDelivery(data, items, deliveryItems) {
      // reset indicator
      data.InvalidItems_vsDelivery = null;
      data.DeliveryMissingQty = 0;

      for (const deliveryItem of deliveryItems) {
        deliveryItem.UserQuantity = 0;
      }

      // populate userItems into deliveryItems
      for (const item of items) {
        for (const deliveryItem of deliveryItems) {
          if (deliveryItem.DispatchID === item.DispatchID &&
          deliveryItem.PackagingName === item.PackagingName) {
            deliveryItem.UserQuantity += App.JS.parseInt(item.RequestedQuantity);
            break;
          }
        }
      }

      // validation
      let invalidItem, invalidItems = [];
      for (const deliveryItem of deliveryItems) {
        if (deliveryItem.Quantity > deliveryItem.UserQuantity) {
          invalidItem = {
            Name: deliveryItem.Name,
            PackagingName: deliveryItem.PackagingName,
            PackagingValue: deliveryItem.PackagingValue,
            Packaging: null,
            RequestedQuantity: (deliveryItem.Quantity - deliveryItem.UserQuantity)
          };
          this.updateItemByPackaging(invalidItem);

          invalidItems.push(invalidItem);
        }
      }

      if (invalidItems.length > 0) {
        data.InvalidItems_vsDelivery = invalidItems;
        data.DeliveryMissingQty = 1;
      }
    },
    
    /*** method - Delivery ***/

    getDeliveryItems(deliveries) {
      let resultList = [];

      for (const delivery of deliveries) {
        for (const item of delivery.Items) {
          let isFound = false;

          for (const result of resultList) {
            if (result.DispatchID === item.DispatchID &&
            result.PackagingName === item.PackagingName) {
              result.Quantity += item.Quantity;
              isFound = true;
              break;
            }
          }

          if (!isFound) {
            const resultItem = this.createDeliveryItem(item);
            resultList.push(resultItem);
          }
        }
      }

      return resultList;
    },
    createDeliveryItem(item) {
      return {
        DispatchID: item.DispatchID,
        Name: item.Name,
        PackagingName: item.PackagingName,
        PackagingValue: item.PackagingValue,
        Quantity: item.Quantity,
        // additional field
        UserQuantity: 0
      };
    },
    setSpecialPriceOptions(items) {
      this.Data.SpecialPriceOptions = items;
    },
    getSpecialPriceOptions(DispatchID, optionNon) {
      const rowId = "ID";
      const rowLabel = "Name";
      let listOptions = { id: rowId, label: rowLabel };
      let items = [];

      if (DispatchID) {
        const dispatchSpecialPrice = this.getDispatchSpecialPrice(DispatchID) || [];
        const listSpecialPrice = dispatchSpecialPrice.map(item => item.SpecialPriceID);
        items = this.Data.SpecialPriceOptions.filter(item => {
          return listSpecialPrice.includes(item.ID);
        });
      }
      if (optionNon) {
        listOptions.nonActive = true;
        listOptions.nonText = optionNon;
      }
      const selectItems = App.Search.createList(items, listOptions);
      const options = App.In.getSelectOptions(rowId, rowLabel, selectItems);
      return options;
    },
    getDiscountPrice(id) {
      const find = this.Data.SpecialPriceOptions.find(item => item.ID == id) || {}
      return find.DiscPercent || 0;
    },
    setDispatchSpecialPrice(data) {
      this.Data.DispatchSpecialPrice = data;
    },
    getDispatchSpecialPrice(DispatchID, specialPriceID) {
      if (DispatchID && specialPriceID) {
        return this.Data.DispatchSpecialPrice.find(item => {
          return item.DispatchID == DispatchID && item.SpecialPriceID == specialPriceID
        });
      } else if (DispatchID) {
        return this.Data.DispatchSpecialPrice.filter(item => item.DispatchID == DispatchID);
      } else {
        return this.Data.DispatchSpecialPrice;
      }
    },
    Data: {
      SpecialPriceOptions: [],
      DispatchSpecialPrice: []
    }
  }
}