import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Barcode: {
    Label: "Barcode [F1]",
    Type: InputType.Text,
    MaxLength: 50
  },
  Search: {
    Label: "Cari Nama, SKU atau Barcode [F3]",
    Type: InputType.Text,
    MaxLength: 50
  },
  InvoiceDate: {
    Label: "Tanggal Penjualan",
    Type: InputType.Date,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Required: true,
    Input: InputType.Select
  },
  Qty: {
    Label: "Qty [F2]",
    Type: InputType.Qty
  },
  TotalBruto: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalQty: {
    Label: "Jumlah Barang",
    Type: InputType.Integer
  },
  PaymentType: {
    Label: "Jenis Pembayaran",
    Type: InputType.ID,
    Required: true,
    Input: InputType.Select
  },
  DiscType: {
    Label: "Diskon",
    Type: InputType.Select
  },
  DiscValue: {
    Label: "Diskon",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  DiscPercent: {
    Label: "Diskon",
    Type: InputType.Percent,
    MinValue: 0,
    MaxValue: 100,
    Required: true
  },
  TotalDisc: {
    Label: "Total Diskon",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  PayValue: {
    Label: "Bayar",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency,
    MinValue: 0
  },
  PayReturn: {
    Label: "Kembalian",
    Type: InputType.Integer,
    Output: OutputType.Integer.Accounting
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.Text
  },
  ClientID: {
    Label: "Pelanggan",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  Client: {
    Label: "Pelanggan",
    Type: InputType.Text,
    MaxLength: 500,
    ReadOnly: true
  },
  ClientAlias: {
    Label: "Alias",
    Type: InputType.Text,
    MaxLength: 20,
    ReadOnly: true
  }
}