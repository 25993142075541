<template>
  <kst-table action view-mode
    :data="dataCategories"
    :failure="failure"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="CategoryName"
      :label="Catalog.CategoryName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog" />
    </b-table-column>
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog" />
    </b-table-column>

    <b-table-column sortable centered
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="IsActive"
      :label="Catalog.IsActive.Label"
    >
      <kst-status :value="row.IsActive" :statusEnum="Model.IsActive"/>
    </b-table-column>

    <b-table-column width="50" v-slot="{ row }">
      <kst-button action-mode is-round
        target=""
        class="is-primary"
        :module="Model.Module"
        :moduleType="Model.ModuleType.Edit"
        :urlParams="getParams(row)"
      />
    </b-table-column>

  </kst-table>
</template>
<script>
import Catalog from "../ExpenseSubCategoryCatalog";
import Model from "../ExpenseSubCategoryModel";
export default {
  props: {
    dataCategories: Array,
    failure: String
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  methods: {
    getParams(row) {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: row.ID
      });
    }
  }
}
</script>