<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
  >

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReceiveNumber"
      :label="Catalog.ReceiveNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VendorName"
      :label="Catalog.VendorName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PurchasingNumber"
      :label="Catalog.PurchasingNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Field.Total !== false">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-price has-text-weight-bold"
        field="Total"
        :label="Catalog.Total.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template #action="{ row }">
      <kst-tooltip search-mode hover-only v-if="row.ID">
        <kst-button search-mode is-round @click="handleSearchItem(row.ID)"/>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";
import Catalog from "../../Receive/ReceiveCatalog.js";
import ReceiveRouter from "../../Receive/ReceiveRouter.js";

export default {
  mixins: [RouterMixin],
  props: {
    access: Object,
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleSearchItem(id) {
      const urlParam = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.openTab(ReceiveRouter.Path.Details + urlParam);
    }
  }
}
</script>