<template>
  <kst-container class="px-5 py-5 kst-report-profit">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <nav class="level is-mobile">
            <div class="level-left">
              <div class="level-item mr-5 kst-dashboard-summary-icon" :class="iconBgStyle">
                <span class="icon is-medium">
                  <i class="kst-dashboard-summary-icon-content"
                    :class="icon + iconStyle"
                  />
                </span>
              </div>
              <div class="level-item kst-dashboard-summary-info">
                <div class="has-text-weight-bold kst-dashboard-summary-info-value">
                  <kst-value class="title is-5" field="TotalStockAdj" :data="data.Summary.All" :catalog="Catalog"/>
                </div>
                <div class="has-text-weight-semibold kst-dashboard-summary-info-title">
                  {{ title }}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </nav>
  </kst-container>
</template>

<script>
import Catalog from './StockAdjCatalog';

export default {
  props: {
    data: Object,
    icon: String,
    iconStyle: String,
    iconBgStyle: String,
    title: String
  },
  data: () => ({
    Catalog: Catalog
  }),
}
</script>