import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ID: {
    Label: "PO",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "PONumber"
  },
  PONumber: {
    Label: "Pembelian Stok (PO) ",
    Type: InputType.Text,
    ReadOnly: true
  },
  ReceiveNumber: {
    Label: "Nomor Penerimaan",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  PurchasingID: {
    Label: "Nomor PO",
    Type: InputType.ID,
    RelatedModule: AppModule.Purchase,
    RelatedValue: "PurchasingNumber",
    ReadOnly: true
  },
  PurchasingNumber: {
    Label: "Nomor PO",
    Type: InputType.Text,
    ReadOnly: true,
    Highlight: true
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Vendor.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Vendor, atau ketik manual.",
      "Vendor tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor
  },
  VendorName: {
    Label: "Vendor",
    Type: InputType.Text,
    MaxLength: 100,
    Required: true,
    Highlight: true
  },
  VendorAlias: {
    Label: "Alias",
    Type: InputType.Text,
    MaxLength: 20,
    ReadOnly: true
  },
  VendorAddress: {
    Label: "Alamat Vendor",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  PurchasingDate: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Unique: true,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  TotalExpenses: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  LastUpdatedBy: {
    Label: AppCatalog.Field.LastUpdatedBy.Label,
    Type: AppCatalog.Field.LastUpdatedBy.Type
  },
  LastUpdatedByID: {
    Label: AppCatalog.Field.LastUpdatedByID.Label,
    Type: AppCatalog.Field.LastUpdatedByID.Type,
    RelatedValue: AppCatalog.Field.LastUpdatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.LastUpdatedByID.RelatedModule
  },
  LastUpdatedDate: {
    Label: AppCatalog.Field.LastUpdatedDate.Label,
    Type: AppCatalog.Field.LastUpdatedDate.Type,
    ReadOnly: AppCatalog.Field.LastUpdatedDate.ReadOnly,
    Output: AppCatalog.Field.LastUpdatedDate.Output,
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Penerimaan",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}