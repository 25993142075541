<template>
  <kst-container class="kst-stockclosing-new">
    <ValidationObserver ref="formDetails">
      <kst-form
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
      >
        <template #top-right>
          <Action :data="data"/>
        </template>

        <Details :data="data"/>
        <Items
          :userItems="data.Items"
          :isListLoading="isListLoading"
          :failureList="failureList"
          />

        <Total :data="data" />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Details from "./StockClosingDetail_Details";
import Items from "./StockClosingDetail_Items";
import Total from "./StockClosingDetail_Total";
import Action from "./StockClosingDetail_Action";
import Router from "../StockClosingRouter.js";
import Services from "../StockClosingServices.js";
import Model from "../StockClosingModel.js";
export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Details,
    Items,
    Total,
    Action
  },
  data: () => ({
    data: {},
    dataTotal: { GrandTotal: 0 },
    isLoading: false,
    isListLoading: false,
    cancelUrl: Router.Path.List,
    errors: null,
    failure: null,
    failureInit: null,
    failureList: null
  }),
  created() {
    this.init();
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  methods: {
    init() {
      this.loadData();
    },
    loadData() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      const config = Services.getStockClosingDetail(id);
      this.runAxios(config, null, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data) {
      this.isListLoading = false;
      this.data = Model.mapDataDetail(data);
      this.data.Items = this.data.Items.map(item => Model.mapDataItemDetail(item));
    },
    loadData_Error(message) {
      this.isListLoading = false;
      this.failureList = message;
    }
  }
}
</script>