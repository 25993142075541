<template>
  <div class="kst-layout-navbar-info-avatar ml-4 mr-1">
    <template v-if="isDefault">
      <kst-image is-public company-default-img/>
    </template>
    <template v-else>
      <kst-image size-auto border-off hide-preview read-only thumbnail :src="companyImage"/>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    companyImage() {
      return this.$kst.Session.getCompanyImageSrc();
    },
    isDefault() {
      return this.$kst.Session.getCompanyImageSrc() === null;
    }
  }
}
</script>