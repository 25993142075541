<template>
  <div class="field is-relative is-horizontal kst-input" :class="theFieldClass">
    <template v-if="floatingLabel">
      <label class="field-label label has-text-left ks-cursor-pointer" :class="theLabelClass">
        {{ catalog[field].Label }}

        <template v-if="catalog[field].Required && !catalog[field].RequiredHide">
          <b-tag
            style="line-height: normal !important; height: auto !important;"
            :type="AppCatalog.Input.Required.Type"
          >
            {{ AppCatalog.Input.Required.Text }}
          </b-tag>
        </template>
      </label>
    </template>
    <template v-else>
      <div v-if="!fieldOnly" class="field-label is-normal">
        <label :for="vid" class="label has-text-left ks-cursor-pointer">
          {{ catalog[field].Label }}
          <b-tag
            v-if="catalog[field].Required && !catalog[field].RequiredHide"
            :type="AppCatalog.Input.Required.Type"
          >
            {{ AppCatalog.Input.Required.Text }}
          </b-tag>
        </label>
        <p v-if="helpIsVisible" class="help has-text-left">
          {{ getHelp() }}
        </p>
      </div>
    </template>

    <div class="field-body">
      <slot name="default">
        <template v-if="isBooleanType">
          <InputBoolean
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputBoolean>
        </template>
        <template v-else-if="isDateType">
          <InputDate
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputDate>
        </template>
        <template v-else-if="isDecimalType">
          <InputDecimal
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputDecimal>
        </template>
        <template v-else-if="isIntegerType">
          <InputInteger
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputInteger>
        </template>
        <template v-else-if="isPasswordType">
          <InputPassword
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @focus="handleFocus"
            @submit="handleSubmit"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputPassword>
        </template>
        <template v-else-if="isPercentType">
          <InputPercent
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputPercent>
        </template>
        <template v-else-if="isPhoneType">
          <InputPhone
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputPhone>
        </template>
        <template v-else-if="isSelectType">
          <InputSelect
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :options="options"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputSelect>
        </template>
        <template v-else-if="isTextType">
          <InputText
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :rounded="rounded"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @focus="handleFocus"
            @submit="handleSubmit"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputText>
        </template>
        <template v-else-if="isTextAreaType">
          <InputTextArea
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @input="handleInput"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputTextArea>
        </template>
        <template v-else-if="isQtyType">
          <InputQty
            ref="fldInput"
            :field="field"
            :data="data"
            :catalog="catalog"
            :disabled="disabled"
            :errorOff="errorOff"
            :loadOnFocus="loadOnFocus"
            :vid="vid"
            :validationRules="validationRules"
            :provider="provider"
            @submit="handleSubmit"
            @input="handleInput"
            @focus="handleFocus"
            @error="handleErrors"
          >
            <template #left>
              <slot name="left" />
            </template>
            <template #right>
              <slot name="right" />
            </template>
          </InputQty>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";

import InputBoolean from "./Input_Boolean";
import InputDate from "./Input_Date";
import InputDecimal from "./Input_Decimal";
import InputInteger from "./Input_Integer";
import InputPassword from "./Input_Password";
import InputPercent from "./Input_Percent";
import InputPhone from "./Input_Phone";
import InputSelect from "./Input_Select";
import InputText from "./Input_Text";
import InputTextArea from "./Input_TextArea";
import InputQty from "./Input_Qty";

export default {
  components: {
    InputBoolean,
    InputDate,
    InputDecimal,
    InputInteger,
    InputPassword,
    InputPercent,
    InputPhone,
    InputSelect,
    InputText,
    InputTextArea,
    InputQty
  },
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: undefined
    },
    addons: Boolean,
    expanded: Boolean,
    errorOff: Boolean,
    helpOff: Boolean,
    loadOnFocus: Boolean,
    fieldOnly: Boolean,
    floatingLabel: Boolean,
    rounded: Boolean,
    validationOff: Boolean,
    // validation provider
    customValidations: Object,
    index: Number,
    indexGroup: String,
    // select
    options: Object
  },
  data: () => ({
    dataTemp: "",
    AppCatalog: AppCatalog,
    InputType: InputType,
    // catalog
    provider: AppCatalog.VeeValidate.Provider
  }),
  computed: {
    isBooleanType() {
      return this.getIsBooleanType();
    },
    isDateType() {
      return this.getIsDateType();
    },
    isDecimalType() {
      return this.getIsDecimalType();
    },
    isIntegerType() {
      return this.getIsIntegerType();
    },
    isPasswordType() {
      return this.getIsPasswordType();
    },
    isPercentType() {
      return this.getIsPercentType();
    },
    isPhoneType() {
      return this.getIsPhoneType();
    },
    isSelectType() {
      return this.getIsSelectType();
    },
    isTextType() {
      return this.getIsTextType();
    },
    isTextAreaType() {
      return this.getIsTextAreaType();
    },
    isQtyType() {
      return this.getIsQtyType();
    },

    helpIsVisible() {
      return this.getHelpIsVisible();
    },

    theFieldClass() {
      return this.getFieldClass();
    },
    theLabelClass() {
      // if has icon
      if (this.catalog[this.field].Icon) {
        return "ml-5 pl-1 pr-1 ";
      }
      if (this.rounded) {
        return "ml-1 ";
      }
      return undefined;
    },

    validationRules() {
      return this.getValidationRules();
    },
    vid() {
      return this.$kst.Vee.getValidatorId(this.field, this.index, this.indexGroup);
    }
  },
  methods: {
    focus() {
      this.$refs.fldInput.focus();
    },

    getHelp() {
      return this.$kst.Convert.arrayToString(
        this.catalog[this.field].Help, this.$kst.Enum.Breakline.Text
      );
    },
    getValidationRules() {
      if (this.validationOff === true) {
        return undefined;
      }
      if (this.catalog[this.field].ReadOnly === true) {
        return undefined;
      }

      let rules = {};

      // required
      if (this.catalog[this.field].Required === true) {
        rules.required = true;
      }

      // data type
      if (this.catalog[this.field].Type === InputType.Email) {
        rules.email = true;
      }
      else if (this.catalog[this.field].Type === InputType.Integer) {
        rules.integer = true;
      }

      // value
      if (this.catalog[this.field].MinValue !== undefined) {
        rules.min_value = this.catalog[this.field].MinValue;
      }

      if (this.catalog[this.field].MaxValue !== undefined) {
        rules.max_value = this.catalog[this.field].MaxValue;
      }

      // length
      if (this.catalog[this.field].Length !== undefined) {
        rules.length = this.catalog[this.field].Length;
      }
      else {
        if (this.catalog[this.field].MinLength !== undefined) {
          rules.min = this.catalog[this.field].MinLength;
        }
        else if (this.catalog[this.field].MaxLength !== undefined) {
          rules.max = this.catalog[this.field].MaxLength;
        }
      }

      // api validation
      let rule = "Active";
      if (this.catalog[this.field][rule] !== undefined) {
        const fieldTarget = this.$kst.Vee.getFieldTarget(this.field, rule);
        rules.active = [this.data[fieldTarget]];
      }

      rule = "Exist";
      if (this.catalog[this.field][rule] !== undefined) {
        const fieldTarget = this.$kst.Vee.getFieldTarget(this.field, rule);
        rules.exist = [this.data[fieldTarget]];
      }

      rule = "Static";
      if (this.catalog[this.field][rule] !== undefined) {
        const fieldTarget = this.$kst.Vee.getFieldTarget(this.field, rule);
        rules.static = [this.data[fieldTarget]];
      }

      // custom
      rule = "UnequalTo";
      if (this.catalog[this.field][rule] !== undefined) {
        const fieldTarget = this.catalog[this.field][rule];
        rules.unequal = {
          target: this.data[fieldTarget],
          label: this.catalog[fieldTarget].Label
        };
      }

      rule = "Valid";
      if (this.catalog[this.field][rule] !== undefined) {
        const fieldTarget = this.$kst.Vee.getFieldTarget(this.field, rule);
        rules.valid = [this.data[fieldTarget]];
      }

      if (this.customValidations !== undefined) {
        for (rule in this.customValidations) {
          rules[rule] = this.customValidations[rule];
        }
      }

      return rules;
    },

    getType() {
      return this.catalog[this.field].Input ?
        this.catalog[this.field].Input : this.catalog[this.field].Type;
    },
    getIsBooleanType() {
      const dataType = this.getType();

      if (dataType === InputType.Boolean) {
        return true;
      }
      return false;
    },
    getIsDateType() {
      const dataType = this.getType();

      if (dataType === InputType.Date) {
        return true;
      }
      return false;
    },
    getIsDecimalType() {
      const dataType = this.getType();

      if (dataType === InputType.Decimal) {
        return true;
      }
      return false;
    },
    getIsIntegerType() {
      const dataType = this.getType();

      if (dataType === InputType.Integer) {
        return true;
      }
      return false;
    },
    getIsPasswordType() {
      const dataType = this.getType();

      if (dataType === InputType.Password) {
        return true;
      }
      return false;
    },
    getIsPercentType() {
      const dataType = this.getType();

      if (dataType === InputType.Percent) {
        return true;
      }
      return false;
    },
    getIsPhoneType() {
      const dataType = this.getType();

      if (dataType === InputType.Phone) {
        return true;
      }
      return false;
    },
    getIsSelectType() {
      const dataType = this.getType();

      if (dataType === InputType.Select) {
        return true;
      }
      return false;
    },
    getIsTextType() {
      const dataType = this.getType();

      if (dataType === InputType.Email) {
        return true;
      }
      else if (dataType === InputType.ID) {
        return true;
      }
      else if (dataType === InputType.Phone) {
        return true;
      }
      else if (dataType === InputType.Text) {
        return true;
      }
      else if (dataType === InputType.Url) {
        return true;
      }

      return false;
    },
    getIsTextAreaType() {
      const dataType = this.getType();
      
      if (dataType === InputType.TextArea) {
        return true;
      }

      return false;
    },

    getIsQtyType() {
      const dataType = this.getType();

      if(dataType === InputType.Qty) {
        return true;
      }

      return false;
    },

    getFieldClass() {
      let fieldClass = "";

      if (this.addons) {
        fieldClass += "control ";
      }
      if (this.expanded) {
        fieldClass += "is-expanded ";
      }
      if (this.floatingLabel) {
        fieldClass += "is-floating-label ";
      }

      return fieldClass;
    },

    getHelpIsVisible() {
      if (this.helpOff === true) {
        return false;
      }

      if (this.catalog[this.field].Help) {
        return true;
      }
      return false;
    },

    handleErrors(errors) {
      this.$emit(this.$kst.Enum.Event.Error, errors, this.field, this.index, this.indexGroup);
    },
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
    handleFocus(event) {
      this.$emit(this.$kst.Enum.Event.Focus, event);
    },
    handleSubmit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>