import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  spv_passwd: {
    Label: "Password SPV",
    Type: InputType.Password,
    MaxLength: 20,
    Required: true
  }
}