<template>
  <kst-section :title="sectionTitle" class="mt-5">
    <kst-table :data="items">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-vendor-details-Name"
        field="Name"
        :label="VendorAddressCatalog.Name.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="VendorAddressCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-vendor-details-Address"
        field="Address"
        :label="VendorAddressCatalog.Address.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="VendorAddressCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-vendor-details-ContactName"
        field="ContactName"
        :label="VendorAddressCatalog.ContactName.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="VendorAddressCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-vendor-details-ContactMobile"
        field="ContactMobile"
        :label="VendorAddressCatalog.ContactMobile.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="VendorAddressCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-vendor-details-ContactPhone"
        field="ContactPhone"
        :label="VendorAddressCatalog.ContactPhone.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="VendorAddressCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-vendor-details-ContactEmail"
        field="ContactEmail"
        :label="VendorAddressCatalog.ContactEmail.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="VendorAddressCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import VendorAddressCatalog from "../VendorAddressCatalog.js";
import Info from "../VendorInfo.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    VendorAddressCatalog: VendorAddressCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Address.Title;
    }
  }
}
</script>