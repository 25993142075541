var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"view-mode":"","data":_vm.items,"loading":_vm.isListLoading}},[_c('b-table-column',{attrs:{"cell-class":"ks-col-image","field":"ImageSrc","label":_vm.Catalog.ImageSrc.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-image',{attrs:{"read-only":"","thumbnail":"","src":row[column.field]}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-dispatch-related-FullName","field":"FullName","label":_vm.Catalog.FullName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-sku","field":"SKU","label":_vm.Catalog.SKU.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","field":"Barcode","label":_vm.Catalog.Barcode.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPrice","label":_vm.Catalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),(_vm.access.Field.HppPerPcs !== false)?[_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-hpp","field":"HppPerPcs","label":_vm.Catalog.HppPerPcs.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,false,2339996382)})]:_vm._e(),(_vm.access.Field.HppTotal !== false)?[_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-hpptotal","field":"HppTotal","label":_vm.Catalog.HppTotal.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,false,2339996382)})]:_vm._e(),_c('b-table-column',{attrs:{"field":"Description","label":_vm.Catalog.Description.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }