import InputType from "@/services/Input/InputType.js";
import Catalog from "../InvoiceCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.SONumber.Label + ", atau " +
      Catalog.PONumber.Label,
    Type: InputType.Text,
    MaxLength: 100
  },
  Client: {
    Label: "Pelanggan",
    Type: InputType.Text,
    MaxLength: 100
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
}