import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import StockOpnameItemCatalog from "./StockOpnameItemCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.StockOpname,
  ModuleType: {
    Details: AppModuleType.Details,
    New: AppModuleType.New,
    List: AppModuleType.List,
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Search: {
    Fields: ["AdjNumber"]
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },

  setItemByItemDetails(item, itemData) {
    this.helper.setItemByItemDetails(item, itemData);
  },
  setItemByStock(item, stockData) {
    this.helper.setItemByStock(item, stockData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },

  setDefaultPackaging(item) {
    this.helper.setDefaultPackaging(item);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validateItem(row, items) {
    let result = this.helper.validateItem(row, items);
    return result;
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  /*** external ***/

  WarehouseStatus: WarehouseModel.Status,

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails() {
      return {
        CompanyID: App.Session.getCompanyID(),
        // user input
        WarehouseID: null,
        AdjNumber: "",
        IsAutoNumber: true,
        Comment: "",
        // defined by system
        Date: App.In.getDateToday(),
        WarehouseName: "",
        CreatedByID: App.Session.getUserID(),
      };
    },
    createItem() {
      return {
        // user input
        DispatchID: null,
        Qty: "",
        PackagingName: "",
        // defined by system
        DispatchName: "",
        SKU: "",
        DispatchSellPrice: "",
        Stock: 0,
        TotalQty: 0,
        Packaging: "",
        PackagingValue: 1,
        PackagingOptions: undefined,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    setItemByItemDetails(item, itemData) {
      item.PackagingOptions = ItemModel.getPackagingOptions(itemData.Packaging);
    },
    setItemByStock(item, stockData) {
      // user input
      item.DispatchID = stockData.DispatchID;
      item.DispatchName = stockData.FullName;
      item.DispatchSellPrice = App.In.getInteger(stockData.SellPrice);
      // defined by system
      item.SKU = stockData.SKU;
      item.Stock = stockData.Quantity;
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        item.ErrorsColl[field].push(error);
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      item.Errors = newErrors;
    },

    getItemInfo(item) {
      let infoList = [];

      // SKU
      infoList.push(StockOpnameItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, StockOpnameItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(StockOpnameItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, StockOpnameItemCatalog)
      );

      // Stock
      infoList.push(StockOpnameItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, StockOpnameItemCatalog)
      );

      return infoList;
    },
    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(StockOpnameItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, StockOpnameItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(StockOpnameItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, StockOpnameItemCatalog)
      );

      return infoList;
    },

    updateItem(item) {
      if (item.PackagingOptions) {
        item.PackagingValue = ItemModel.getPackagingValue(
          item.PackagingOptions.rows, item.PackagingName
        );
      }

      this.updateItemByPackaging(item);
      item.TotalQty = App.Data.getTotalQty(item.Qty, item.PackagingValue);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },

    setDefaultPackaging(item) {
      const packaging = item.PackagingOptions.rows[0];
      item.PackagingName = packaging.ItemUnitName;
      item.PackagingValue = packaging.ItemUnitAmount;
    },

    populateDetails(data) {
      return {
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        AdjNumber: data.IsAutoNumber ? null : App.Out.getString(data.AdjNumber),
        Comment: data.Comment,
        Date: App.Out.getDate(data.Date),
        CreatedByID: data.CreatedByID,
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          DispatchID: item.DispatchID,
          DispatchName: item.DispatchName,
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          Qty: App.Out.getInteger(item.Qty)
        });
      }
      return resultItems;
    },

    validateItem(row, items) {
      let validate = true;
      items.map(item => {
        if (item.DispatchID === row.DispatchID) {
          validate = false;
        }
      });
      return validate;
    }
  }
}