import InputType from "@/services/Input/InputType.js";
import Catalog from "../SalesCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.DraftNumber.Label + ", atau " +
      Catalog.ClientID.Label + ", atau " +
      Catalog.PONumber.Label,
    Type: InputType.Text,
    MaxLength: 50
  },
  Date: {
    Label: Catalog.Date.Label,
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: InputType.ID,
    Input: InputType.Select
  },
  StatusID: {
    Label: Catalog.StatusID.Label,
    Type: InputType.Select
  }
}