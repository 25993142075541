import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ID: {
    Label: "PO",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "PONumber"
  },
  PONumber: {
    Label: "Pembelian Stok (PO) ",
    Type: InputType.Text,
    ReadOnly: true
  },
  ReceiveNumber: {
    Label: "Nomor Penerimaan",
    Type: InputType.Text
  },
  PurchasingID: {
    Label: "Nomor PO",
    Type: InputType.ID,
    RelatedModule: AppModule.Purchase,
    RelatedValue: "PurchasingNumber",
    ReadOnly: true
  },
  PurchasingNumber: {
    Label: "Nomor Pemesanan",
    Type: InputType.Text,
    ReadOnly: true
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Vendor.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Vendor, atau ketik manual.",
      "Vendor tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    RelatedValue: "Vendor",
    RelatedModule: AppModule.Vendor
  },
  Vendor: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 100
  },
  VendorName: {
    Label: "Vendor",
    Type: InputType.Text,
    MaxLength: 100
  },
  VendorAlias: {
    Label: "Alias",
    Type: InputType.Text,
    MaxLength: 20,
    ReadOnly: true
  },
  VendorAddress: {
    Label: "Alamat Vendor",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  PurchasingDate: {
    Label: "Tanggal Transaksi",
    Type: InputType.Date,
    ReadOnly: true
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Unique: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  TotalExpenses: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  },
}