<template>
  <kst-container class="kst-specialprice-list">
    <kst-form :title="title">
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>

      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :statusOptions="statusOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Export"
          />
        </template>

        <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../SpecialPriceModel.js";
import Services from "../SpecialPriceServices.js";
import SearchCatalog from "./SpecialPriceSearchCatalog.js";

import Search from "./SpecialPriceList_Search";
import List from "./SpecialPriceList_List";

export default {
  mixins: [AxiosMixin],
  components: {
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: null,
    statusOptions: {},
    items: [],
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    initSearch() {
      // status
      this.statusOptions = Model.createStatusOptions(SearchCatalog.IsActive.Label);

      // search
      this.searchData = {
        Search: "",
        IsActive: this.$kst.Search.OptionAll
      };
    },

    loadList_End() {
      this.isListLoading = false;
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
        options.active = this.$kst.Search.getValue(searchData.IsActive);
      }

      const config = Services.getSpecialPriceList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleSearchSubmit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    }
  }
}
</script>