import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.client;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "client", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/client/list" + urlParams
    };
  },
  getDetails(id) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/client/" + cid + "/" + id
    };
  },
  postClient(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/client",
      data: data
    };
  },
  putClient(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/client",
      data: data
    };
  },

  /* address */

  getAddressList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "client", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/client/address/list" + urlParams
    };
  }
}