<template>
  <kst-section :title="sectionTitle" class="mt-5">
    <template v-for="(item, index) in items">
      <div :key="index" class="columns">
        <div class="column">
          <div class="columns is-mobile">
            <div class="column is-narrow">
              <div class="k-control-py">{{ index + 1 }}</div>
            </div>
            <div class="column">
              <kst-output floating-label load-on-focus
                field="Name" :data="item" :catalog="VariantCatalog"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <VariantValues :items="item.Value"/>
        </div>
      </div>
    </template>

    <kst-table-empty v-if="items.length === 0"/>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import VariantCatalog from "../VariantCatalog.js";

import VariantValues from "./ItemDetails_Variants_Values";

export default {
  components: {
    VariantValues
  },
  props: {
    items: Array
  },
  data: () => ({
    VariantCatalog: VariantCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Variant.Title;
    }
  }
}
</script>