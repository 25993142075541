var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"input-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"field":"Name","label":_vm.ReceiveItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"Packaging","label":_vm.ReceiveItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-qty","field":"Quantity","label":_vm.ReceiveItemCatalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","field":"BuyPrice","label":_vm.ReceiveItemCatalog.BuyPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","field":"ExpenseAmount","label":_vm.ReceiveItemCatalog.ExpenseAmount.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }