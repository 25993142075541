import App from "@/services/App/App.js";

export default {
  FilterType: {
    Today: {
      ID: 1, 
      Label: 'Hari ini'
    },
    Yesterday: {
      ID: 2, 
      Label: 'Kemarin'
    },
    LastWeek: {
      ID: 3, 
      Label: '7 Hari Terakhir'
    },
    Last30Days: {
      ID: 4, 
      Label: '30 Hari Terakhir'
    },
    ThisMonth: {
      ID: 5, 
      Label: 'Bulan Ini'
    },
    LastMonth: {
      ID: 6, 
      Label: 'Bulan Kemarin'
    }
  },
  
  createFilterOptions(optionAllText) {
    return this.helper.createFilterOptions(optionAllText, this.FilterType);
  },
  
  helper: {
    createFilterOptions(optionAllText, FilterEnum) {
      let todayDate = App.In.getDateToday();
      const yesterday = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 1);
      const last7dDate = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 6);
      const last30dDate = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 29);
      const firstDayThisMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      const firstDayLastMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);
      const lastDayLastMonthDate = new Date(firstDayThisMonthDate.getFullYear(),
        firstDayThisMonthDate.getMonth(), firstDayThisMonthDate.getDate() - 1);
      const limitData = 5;


      const rowId = "ID";
      const rowLabel = "Label";
      const rowStart = "StartDate";
      const rowEnd = "EndDate";
      const rowLimit = "Limit";

      const items = [
        { [rowId]: FilterEnum.Today.ID, [rowLabel]: FilterEnum.Today.Label, [rowStart]: todayDate, [rowEnd]: todayDate, [rowLimit]: limitData },
        { [rowId]: FilterEnum.Yesterday.ID, [rowLabel]: FilterEnum.Yesterday.Label, [rowStart]: yesterday, [rowEnd]: yesterday, [rowLimit]: limitData },
        { [rowId]: FilterEnum.LastWeek.ID, [rowLabel]: FilterEnum.LastWeek.Label, [rowStart]: last7dDate, [rowEnd]: todayDate, [rowLimit]: limitData },
        { [rowId]: FilterEnum.Last30Days.ID, [rowLabel]: FilterEnum.Last30Days.Label, [rowStart]: last30dDate, [rowEnd]: todayDate, [rowLimit]: limitData },
        { [rowId]: FilterEnum.ThisMonth.ID, [rowLabel]: FilterEnum.ThisMonth.Label, [rowStart]: firstDayThisMonthDate, [rowEnd]: todayDate, [rowLimit]: limitData },
        { [rowId]: FilterEnum.LastMonth.ID, [rowLabel]: FilterEnum.LastMonth.Label, [rowStart]: firstDayLastMonthDate, [rowEnd]: lastDayLastMonthDate, [rowLimit]: limitData },
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel, startDate: rowStart, endDate: rowEnd, limit: rowLimit };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
  }
}