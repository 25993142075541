export default {
  numeric(value, fieldName, options, index) {
    const { isRequired, isInteger, minValue } = options;
    let qty = value.trim();

    const indexTips = (index === undefined ? "" : " (#" + index + ") ");

    // blank
    if (qty === "") {
      if (isRequired) {
        return indexTips + fieldName + " wajib diisi";
      }

      return null;
    }

    // numeric
    if (isNaN(qty)) {
      return indexTips + fieldName + " harus berisi angka";
    }

    qty = Number(qty);
    // integer
    if (isInteger) {
      if (!Number.isInteger(qty)) {
        return indexTips + fieldName + " tanpa desimal";
      }
    }

    // minimum value
    if (minValue !== undefined) {
      if (qty < minValue) {
        return indexTips + "Minimum " + fieldName + " adalah " + minValue;
      }
    }

    return null;
  }
}