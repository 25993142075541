import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import SalesDetails from "./Details/SalesDetails";
import SalesList from "./List/SalesList";
import SalesNew from "./New/SalesNew";
import SalesEdit from "./Edit/SalesEdit";
import SalesExportDetails from "./ExportDetails/SalesExportDetails"


export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Sales, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Sales, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Sales, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Sales, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Sales, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.Details),
        component: SalesDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Sales)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.Edit),
        component: SalesEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Sales)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.ExportDetails),
        component: SalesExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Sales)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.List),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.List),
        component: SalesList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Sales)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.New),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.New),
        component: SalesNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Sales)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}