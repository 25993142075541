import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DraftNumber: {
    Label: "Nomor SO",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true,
    RequiredHide: true,
    Help: "Tanggal transaksi"
  },
  ClientID: {
    Label: "Pelanggan",
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Client.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Pelanggan, atau ketik manual.",
      "Pelanggan tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client
  },
  ClientName: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 100,
    Required: true,
    Highlight: true
  },
  ClientAlias: {
    Label: "Alias",
    Type: InputType.Text,
    MaxLength: 20,
    ReadOnly: true
  },
  ClientAddress: {
    Label: "Alamat Pelanggan",
    Type: InputType.Text,
    MaxLength: 200
  },
  PONumber: {
    Label: "Nomor PO",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  },
  DiscType: {
    Label: "Pilihan Diskon",
    Type: InputType.Select
  },
  DiscValue: {
    Label: "Diskon",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  DiscPercent: {
    Label: "Diskon",
    Type: InputType.Percent,
    MinValue: 0,
    MaxValue: 100,
    Required: true
  },
  TotalBruto: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalDisc: {
    Label: "Total Diskon",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  StatusID: {
    Label: "Status",
    Type: InputType.Integer,
    ReadOnly: true
  },
  StatusName: {
    Label: "Status",
    Type: InputType.Text,
    ReadOnly: true
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  LastUpdatedBy: {
    Label: AppCatalog.Field.LastUpdatedBy.Label,
    Type: AppCatalog.Field.LastUpdatedBy.Type
  },
  LastUpdatedByID: {
    Label: AppCatalog.Field.LastUpdatedByID.Label,
    Type: AppCatalog.Field.LastUpdatedByID.Type,
    RelatedValue: AppCatalog.Field.LastUpdatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.LastUpdatedByID.RelatedModule
  },
  LastUpdatedDate: {
    Label: AppCatalog.Field.LastUpdatedDate.Label,
    Type: AppCatalog.Field.LastUpdatedDate.Type,
    ReadOnly: AppCatalog.Field.LastUpdatedDate.ReadOnly,
    Output: AppCatalog.Field.LastUpdatedDate.Output,
  },

  // support
  IsAutoNumber: {
    Label: "Nomor SO",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}