import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.sales;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "warehouse", "active", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/warehouse/list" + urlParams
    };
  },
  getDetails(id) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/warehouse/details/" + cid + "/" + id
    };
  },
  postWarehouse(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/warehouse",
      data: data
    };
  },
  putWarehouse(data) {
    return {
      method: App.Enum.Axios.Method.Patch,
      url: apiEndpoint + "/api/v1/warehouse",
      data: data
    };
  }
  /*
  patchWarehouse(component, data) {
    return component.$http.patch(apiEndpoint + "/api/v1/warehouse", data);
  }
  */
}