<template>
  <div class="kst-item-status">
    <kst-icon item-status-mode :value="data[field]"/>
    <kst-value :field="field" :data="data" :catalog="catalog"/>
  </div>
</template>

<script>
export default {
  props: {
    catalog: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    }
  }
}
</script>