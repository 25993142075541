<template>
  <b-modal
    aria-modal
    aria-role="dialog"
    class="kst-modal"
    has-modal-card
    trap-focus
    :active="isActive"
    :full-screen="fullScreen"
    @close="handleCancel"
  >
    <div class="modal-card ks-max-width-100p" :style="'width: ' + width + 'px'">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" @click="handleCancel"/>
      </header>
      <section class="modal-card-body">
        <kst-skeleton :active="loading"/>

        <template v-if="!loading">
          <slot />
        </template>
      </section>
      <footer class="modal-card-foot">
        <template v-if="!loading">
          <div class="container">
            <nav class="level">
              <div
                class="level-left"
                :class="slotHasContent('bottom-left') ? '' : 'ks-no-content'"
              >
                <div class="level-item">
                  <slot name="bottom-left"/>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <slot name="bottom-right">
                    <div class="buttons is-block">
                      <p v-if="error" class="help is-danger mb-3">{{ error }}</p>
                      <kst-button close-mode @click="handleCancel"/>
                      <kst-button confirm-mode @click="handleConfirm"/>
                    </div>
                  </slot>
                </div>
              </div>
            </nav>
          </div>
        </template>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";

export default {
  mixins: [SlotMixins],
  props: {
    title: {
      type: String,
      required: true
    },
    active: Boolean,
    fullScreen: Boolean,
    loading: Boolean,
    width: Number,
    error: String
  },
  data: () => ({
    isActive: true
  }),
  watch: {
    active() {
      this.setActive();
    }
  },
  created() {
    this.setActive();
  },
  methods: {
    handleCancel() {
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleConfirm() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },

    setActive() {
      this.isActive = this.active;
    }
  }
};
</script>