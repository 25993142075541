import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import ItemModel from "@/components/Item/ItemModel.js";
import StockModel from "@/components/Stock/StockModel.js";

export default {
  // required in model
  Module: AppModule.Stock,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    List: AppModuleType.List
  },

  /*** related ***/

  Item: {
    Module: ItemModel.Module,
    ModuleType: {
      Search: AppModuleType.Search
    }
  },

  /*** property ***/

  Search: {
    Fields: ["FullName", "SKU", "SpecialPriceName"]
  },
  Stock: StockModel.Stock,

  /*** method ***/

  createStockOptions(optionAllText) {
    return this.helper.createStockOptions(optionAllText, this.Stock);
  },

  /*** external ***/

  helper: {
    createStockOptions(optionAllText, stockEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: stockEnum.Available.ID, [rowLabel]: stockEnum.Available.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    }
  }
}