export default {
  isUnequal(value, target) {
    if (target === undefined || target === null) {
      return true;
    }

    return value === target ? false : true;
  },
  minDate(value, target) {
    let valueDate = new Date(value);
    let targetDate = new Date(target);

    return (valueDate >= targetDate);
  }
}