import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Vendor,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    Search: AppModuleType.Search
  },

  /*** related ***/

  VendorAddress: {
    Module: AppModule.VendorAddress,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },

  /*** property ***/

  Search: {
    Fields: ["Name", "Mobile", "Phone", "Email", "Alias"],
    FieldsAddress: ['VendorName', 'Address', 'ContactName', 'ContactMobile',
    'ContactPhone', 'ContactEmail']
  },

  SearchReceive: {
    Fields: ["ReceiveNumber", "PurchasingNumber"]
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createAddress() {
    return this.helper.createAddress();
  },

  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },
  setAddressByData(address, addressData) {
    this.helper.setAddressByData(address, addressData);
  },
  setAddressErrors(address, field, errors) {
    this.helper.setAddressErrors(address, field, errors);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Address = this.helper.populateAddress(items);
    return result;
  },

  mapDataReceive(data) {
    let amount = this.helper.populateTotalAmount(data);
    let result = Object.assign({}, amount);
    result.Items = data;
    return result;
  },

  helper: {
    createDetails() {
      return {
        ID: "",
        CompanyID: App.Session.getCompanyID(),
        // user input
        Name: "",
        Alias: "",
        Mobile: "",
        Phone: "",
        Email: "",
        Comment: ""
      };
    },
    createAddress() {
      return {
        ID: "",
        // user input
        Name: "",
        Address: "",
        ContactName: "",
        ContactMobile: "",
        ContactPhone: "",
        ContactEmail: "",
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    
    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.Name = App.In.getString(details.Name);
      data.Alias = App.In.getString(details.Alias);
      data.Mobile = App.In.getString(details.Mobile);
      data.Phone = App.In.getString(details.Phone);
      data.Email = App.In.getString(details.Email);
      data.Comment = App.In.getString(details.Comment);
    },
    setAddressByData(address, addressData) {
      address.ID = addressData.ID;
      // user input
      address.Name = App.In.getString(addressData.Name);
      address.Address = App.In.getString(addressData.Address);
      address.ContactName = App.In.getString(addressData.ContactName);
      address.ContactMobile = App.In.getString(addressData.ContactMobile);
      address.ContactPhone = App.In.getString(addressData.ContactPhone);
      address.ContactEmail = App.In.getString(addressData.ContactEmail);
    },
    setAddressErrors(address, field, errors) {
      // populate error in current field
      address.ErrorsColl[field] = [];

      for (const error of errors) {
        address.ErrorsColl[field].push(error);
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in address.ErrorsColl) {
        fieldErrors = address.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      address.Errors = newErrors;
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        Name: data.Name,
        Alias: data.Alias,
        Mobile: data.Mobile,
        Phone: data.Phone,
        Email: data.Email,
        Comment: data.Comment
      }
    },
    populateAddress(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          ID: item.ID,
          Name: item.Name,
          Address: item.Address,
          ContactName: item.ContactName,
          ContactMobile: item.ContactMobile,
          ContactPhone: item.ContactPhone,
          ContactEmail: item.ContactEmail
        });
      }

      return resultItems;
    },
    populateTotalAmount(data) {
      data = data || [];
      const result = {
        GrandTotal: this.getReceiveTotalSummary(data)
      }
      return result;
    },
    getReceiveTotalSummary(data) {
      let result = 0;
      data = data || [];
      if(data.length > 0) {
        result = data
          .map(item => parseInt(item.Total || 0))
          .reduce((next, prev) => next + prev)
      }
      return result;
    }
  }
}