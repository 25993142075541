<template>
  <b-tooltip multilined
    class="kst-tooltip"
    :position="thePosition"
    :size="theSize"
    :type="theType"
    :triggers="theTriggers"
  >
    <template #content>
      <template v-if="hasContent">
        <slot name="content"/>
      </template>
      <template v-else>
        {{ theContent }}
      </template>
    </template>

    <slot/>
  </b-tooltip>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  mixins: [SlotMixins],
  props: {
    content: String,
    position: String,
    size: String,
    type: String,
    // ONLY in: relatedMode
    module: Object,
    // mode: ONLY select 1 (one)
    clearMode: Boolean,
    deleteMode: Boolean,
    deleteImageMode: Boolean,
    detailsMode: Boolean,
    editMode: Boolean,
    errorMode: Boolean,
    infoMode: Boolean,
    mailMode: Boolean,
    newItemMode: Boolean,
    phoneMode: Boolean,
    relatedMode: Boolean,
    searchMode: Boolean,
    urlMode: Boolean,
    // triggers
    hoverOnly: Boolean
  },
  computed: {
    hasContent() {
      return this.getHasContent();
    },

    theContent() {
      return this.getContent();
    },
    thePosition() {
      return this.getPosition();
    },
    theSize() {
      return this.getSize();
    },
    theTriggers() {
      return this.getTriggers();
    },
    theType() {
      return this.getType();
    }
  },
  methods: {
    getHasContent() {
      return this.slotHasContent("content");
    },

    getContent() {
      // single state: with or without content
      if (this.deleteImageMode) {
        return AppCatalog.Tooltip.DeleteImage.Content;
      }
      else if (this.errorMode) {
        return AppCatalog.Tooltip.Error.Content;
      }
      else if (this.infoMode) {
        return AppCatalog.Tooltip.Info.Content;
      }
      else if (this.mailMode) {
        return AppCatalog.Tooltip.Mail.Content;
      }
      else if (this.urlMode) {
        return AppCatalog.Tooltip.Url.Content;
      }
      else if (this.newItemMode) {
        return AppCatalog.Tooltip.NewItem.Content;
      }
      else if (this.phoneMode) {
        return AppCatalog.Tooltip.Phone.Content;
      }
      else if (this.relatedMode) {
        return AppCatalog.Tooltip.Related.Content + this.module.FullName;
      }

      // multi state: with and without content
      const data = this.content === undefined ? "data" : this.content;

      if (this.clearMode) {
        return AppCatalog.Tooltip.Clear.Content + data;
      }
      else if (this.deleteMode) {
        return AppCatalog.Tooltip.Delete.Content + data;
      }
      else if (this.detailsMode) {
        return AppCatalog.Tooltip.Details.Content + data;
      }
      else if (this.editMode) {
        return AppCatalog.Tooltip.Edit.Content + data;
      }
      else if (this.searchMode) {
        return AppCatalog.Tooltip.Search.Content + data;
      }

      if (this.content !== undefined) {
        return this.content;
      }

      return undefined;
    },
    getPosition() {
      if (this.position !== undefined) {
        return this.position;
      }

      if (this.clearMode) {
        return AppCatalog.Tooltip.Clear.Position;
      }
      else if (this.deleteMode) {
        return AppCatalog.Tooltip.Delete.Position;
      }
      else if (this.deleteImageMode) {
        return AppCatalog.Tooltip.DeleteImage.Position;
      }
      else if (this.detailsMode) {
        return AppCatalog.Tooltip.Details.Position;
      }
      else if (this.editMode) {
        return AppCatalog.Tooltip.Edit.Position;
      }
      else if (this.errorMode) {
        return AppCatalog.Tooltip.Error.Position;
      }
      else if (this.infoMode) {
        return AppCatalog.Tooltip.Info.Position;
      }
      else if (this.mailMode) {
        return AppCatalog.Tooltip.Mail.Position;
      }
      else if (this.newItemMode) {
        return AppCatalog.Tooltip.NewItem.Position;
      }
      else if (this.phoneMode) {
        return AppCatalog.Tooltip.Phone.Position;
      }
      else if (this.relatedMode) {
        return AppCatalog.Tooltip.Related.Position;
      }
      else if (this.searchMode) {
        return AppCatalog.Tooltip.Search.Position;
      }
      else if (this.urlMode) {
        return AppCatalog.Tooltip.Url.Position;
      }

      return "is-bottom";
    },
    getSize() {
      if (this.size !== undefined) {
        return this.size;
      }

      if (this.clearMode) {
        return AppCatalog.Tooltip.Clear.Size;
      }
      else if (this.deleteMode) {
        return AppCatalog.Tooltip.Delete.Size;
      }
      else if (this.deleteImageMode) {
        return AppCatalog.Tooltip.DeleteImage.Size;
      }
      else if (this.detailsMode) {
        return AppCatalog.Tooltip.Details.Size;
      }
      else if (this.editMode) {
        return AppCatalog.Tooltip.Edit.Size;
      }
      else if (this.errorMode) {
        return AppCatalog.Tooltip.Error.Size;
      }
      else if (this.infoMode) {
        return AppCatalog.Tooltip.Info.Size;
      }
      else if (this.mailMode) {
        return AppCatalog.Tooltip.Mail.Size;
      }
      else if (this.newItemMode) {
        return AppCatalog.Tooltip.NewItem.Size;
      }
      else if (this.phoneMode) {
        return AppCatalog.Tooltip.Phone.Size;
      }
      else if (this.relatedMode) {
        return AppCatalog.Tooltip.Related.Size;
      }
      else if (this.searchMode) {
        return AppCatalog.Tooltip.Search.Size;
      }
      else if (this.urlMode) {
        return AppCatalog.Tooltip.Url.Size;
      }

      return undefined;
    },
    getTriggers() {
      if (this.hoverOnly) {
        return ['hover'];
      }

      return ['hover','click'];
    },
    getType() {
      if (this.type !== undefined) {
        return this.type;
      }

      if (this.clearMode) {
        return AppCatalog.Tooltip.Clear.Type;
      }
      else if (this.deleteMode) {
        return AppCatalog.Tooltip.Delete.Type;
      }
      else if (this.deleteImageMode) {
        return AppCatalog.Tooltip.DeleteImage.Type;
      }
      else if (this.detailsMode) {
        return AppCatalog.Tooltip.Details.Type;
      }
      else if (this.editMode) {
        return AppCatalog.Tooltip.Edit.Type;
      }
      else if (this.errorMode) {
        return AppCatalog.Tooltip.Error.Type;
      }
      else if (this.infoMode) {
        return AppCatalog.Tooltip.Info.Type;
      }
      else if (this.mailMode) {
        return AppCatalog.Tooltip.Mail.Type;
      }
      else if (this.newItemMode) {
        return AppCatalog.Tooltip.NewItem.Type;
      }
      else if (this.phoneMode) {
        return AppCatalog.Tooltip.Phone.Type;
      }
      else if (this.relatedMode) {
        return AppCatalog.Tooltip.Related.Type;
      }
      else if (this.searchMode) {
        return AppCatalog.Tooltip.Search.Type;
      }
      else if (this.urlMode) {
        return AppCatalog.Tooltip.Url.Type;
      }

      return "is-info";
    }
  }
}
</script>