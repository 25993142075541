import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./RptStockCardModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name + " " + AppModule.StockCard.FullName
        },
        Section: {
          Report: "Data" + " " + AppModule.Report.FullName + ":",
          List: "Data" + " " + AppModule.Item.FullName + ":",
          ListTransaction: Model.ModuleType.List.Name + " " + "Transaksi" + ":",
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.List.Name + " " +
          Model.Module.FullName,
        Option: {
          List: Model.ModuleType.List.Name + " " + AppModuleType.Details.Name,
          Report:  "Data" + " " + AppModule.Item.FullName,
          ListTransaction: AppModuleType.List.Name + " " + "Data" + " " + AppModule.Report.FullName
        }
      }
    }
  }
}