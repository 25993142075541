<template>
  <kst-section :title="sectionTitle">
    <InvoiceSearch show-status
      ref="mdlInvoiceSearch"
      :warehouseList="warehouseList"
      @submit="handleInvoice_Submit"
    />

    <kst-warning class="mb-4" :items="invoiceWarning"/>

    <kst-output control-off status-off
      field="SalesOrderID" :data="userData" :catalog="Catalog"
    >
      <template #right>
        <div class="control">
          <template v-if="userData.SalesOrderID === null">
            <kst-tooltip search-mode hover-only :content="Model.Invoice.Module.FullName">
              <kst-button search-mode @click="handleSearch_Invoice"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Model.Invoice.Module.FullName">
              <kst-button clear-mode @click="handleClear_Invoice"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>
    <kst-input class="is-hidden" field="SONumber" :data="userData" :catalog="Catalog"/>

    <template v-if="userData.SalesOrderID">
      <Details :data="userData"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../RtnInvoiceCatalog.js";
import Info from "../RtnInvoiceInfo.js";
import Model from "../RtnInvoiceModel.js";

import Details from "./RtnInvoiceNew_Invoice_Details";
import InvoiceSearch from "@/components/Invoice/Search/InvoiceSearch";

export default {
  components: {
    Details,
    InvoiceSearch
  },
  props: {
    userData: Object,
    warehouseList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Invoice.Title;
    },
    invoiceWarning() {
      return this.userData.SalesOrderID ?
        [
          Info.Message.New_Invoice,
          Info.Message.New_InvoiceFields
        ] :
        [
          Info.Message.New_Invoice,
          Info.Message.New_InvoiceBlank
        ]
      ;
    }
  },
  methods: {
    handleInvoice_Submit(row) {
      this.$emit(this.$kst.Enum.Event.Submit, row);
    },
    handleClear_Invoice() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch_Invoice() {
      this.$refs.mdlInvoiceSearch.show();
    }
  }
}
</script>