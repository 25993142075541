import App from "@/services/App/App.js";

export default {
  updateDispatches(dispatches, variantList, itemData) {
    let newDispatches = this.createNewDispatches(variantList, itemData);
    this.updateDispatches_Existing2New(dispatches, newDispatches);

    App.Array.truncate(dispatches);
    for (const newDispatch of newDispatches) {
      dispatches.push(newDispatch);
    }
  },

  createNewDispatches(variantList, itemData) {
    if (variantList.length === 0) {
      return [this.createBlankDispatch([], itemData)];
    }

    let dispatchList = [];

    // create running-index for each variant, start index: 0
    let variantIndexList = Array.from(Array(variantList.length)).map(() => 0);

    let isFinish = false;
    let dispatchVariant;
    let dispatchVariantList, valueIndex, variant;
    const variantIndexLength = variantIndexList.length;

    while (!isFinish) {
      dispatchVariantList = [];

      // populate Dispatches.Variants
      for (let index = 0; index < variantIndexLength; index++) {
        variant = variantList[index];
        valueIndex = variantIndexList[index];

        if (variant.Value.length > 0) {
          dispatchVariant = this.createDispatchVariant({
            VariantID: variant.ID,
            VariantName: variant.Name,
            VariantValue: variant.Value[valueIndex].ValueID,
            VariantValueName: variant.Value[valueIndex].Value
          });
          dispatchVariantList.push(dispatchVariant);
        }
      }

      dispatchList.push(
        this.createBlankDispatch(dispatchVariantList, itemData)
      );

      // reset index of Variants.Value
      for (let index = variantIndexLength - 1; index >= 0; index--) {
        if (variantList[index].Value.length === 0 ||
          variantIndexList[index] === (variantList[index].Value.length - 1)
        ) {
          if (index === 0) {
            isFinish = true;
          }
          else {
            variantIndexList[index] = 0;
          }
        }
        else {
          variantIndexList[index]++;
          break;
        }
      }
    }

    return dispatchList;
  },
  createBlankDispatch(variantList, itemData) {
    return this.createDispatch({
      DispatchID: -1,
      FullName: this.createDispatchName(itemData.Name, variantList),
      SKU: "",
      SellPrice: "",
      Weight: "",
      BuyPrice: "",
      Barcode: "",
      Vendor: "",
      Description: "",
      ImageSrc: "",
      Image64: "",
      ImageIsLocal: true,
      Variants: variantList,
      // error
      Errors: [],
      ErrorsColl: {}
    });
  },
  createDispatchName(itemName, variantList) {
    let name = itemName + (variantList.length === 0 ? '' : ' - ');
    for (const index in variantList) {
      name += (index > 0 ? ', ' : '') + variantList[index].VariantValueName;
    }
    return name;
  },
  createDispatch(data) {
    let variantList = this.createDispatchVariants(data.Variants);

    return {
      DispatchID: data.DispatchID,
      FullName: App.In.getString(data.FullName),
      SKU: App.In.getString(data.SKU),
      SellPrice: App.In.getInteger(data.SellPrice),
      Weight: App.In.getInteger(data.Weight),
      BuyPrice: App.In.getInteger(data.BuyPrice),
      Barcode: App.In.getString(data.Barcode),
      Vendor: App.In.getString(data.Vendor),
      Description: App.In.getString(data.Description),
      ImageSrc: "",
      Image64: "",
      ImageIsLocal: true,
      HppPerPcs: App.In.getInteger(data.HppPerPcs),
      HppTotal: App.In.getInteger(data.HppTotal),
      Quantity: App.In.getInteger(data.Quantity),
      Variants: variantList,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },
  createDispatchVariants(variantList) {
    // handle variant default
    if (variantList.length === 1) {
      if (variantList[0].VariantName === this.VARIANT_DEFAULT &&
      variantList[0].VariantValueName === this.VARIANT_DEFAULT) {
        return [];
      }
    }

    let result = [];
    for (const variant of variantList) {
      result.push(this.createDispatchVariant(variant));
    }
    return result;
  },
  createDispatchVariant(data) {
    return {
      VariantID: data.VariantID,        // Variants.ID
      VariantValue: data.VariantValue,  // Variants.Value.ValueID
      VariantName: data.VariantName,
      VariantValueName: data.VariantValueName
    }
  },

  updateDispatches_Existing2New(oldDispatches, newDispatches) {
    let oldVar;
    for (const newVar of newDispatches) {
      oldVar = this.getDispatchByVariants(oldDispatches, newVar.Variants);

      if (oldVar) {
        newVar.DispatchID = oldVar.DispatchID;
        newVar.FullName = oldVar.FullName;
        newVar.SKU = oldVar.SKU;
        newVar.SellPrice = oldVar.SellPrice;
        newVar.Weight = oldVar.Weight;
        newVar.BuyPrice = oldVar.BuyPrice;
        newVar.Barcode = oldVar.Barcode;
        newVar.Vendor = oldVar.Vendor;
        newVar.Description = oldVar.Description;
        newVar.ImageSrc = oldVar.ImageSrc;
        newVar.Image64 = oldVar.Image64;
        newVar.ImageIsLocal = oldVar.ImageIsLocal;
      }
    }
  },
  getDispatchByVariants(dispatchList, variantList) {
    let variantEqual;
    for (const dispatch of dispatchList) {
      if (dispatch.Variants.length !== variantList.length) {
        continue;
      }

      variantEqual = 0;
      for (const varVariant of dispatch.Variants) {
        for (const variant of variantList) {
          if (varVariant.VariantID === variant.VariantID &&
          varVariant.VariantValue === variant.VariantValue) {
            variantEqual++;
            break;
          }
        }
      }

      if (variantEqual === dispatch.Variants.length) {
        return dispatch;
      }
    }
    return null;
  }
}