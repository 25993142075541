import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  SearchTypeID: {
    Label: "Jenis Data",
    Type: InputType.Text,
    ReadOnly: true
  },
  TransactionName: {
    Label: "Nomor/Nama Data",
    Type: InputType.Text,
    ReadOnly: true
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Qty: {
    Label: "Qty",
    Type: InputType.Integer,
    ReadOnly: true
  }
}