<template>
  <div>
    <div class="mb-2">Mixin Excel:</div>
    <kst-button action-mode
      class="is-primary"
      tag="button"
      :module="Model.Module"
      :moduleType="Model.ModuleType.Export"
      @click="handleExport"
    />
  </div>
</template>

<script>
import Model from "../TestModel.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";

export default {
  mixins: [XlsxMixin],
  data: () => ({
    Model: Model
  }),
  methods: {
    handleExport() {
      this.createBook();
      this.createSheet();

      const valueA1 = 10000;
      const valueB1 = 17500;

      const cellA1 = this.addCell({ t: "n", v: valueA1 });
      const cellB1 = this.addCell({ t: "n", v: valueB1 });
      this.addCell({ t: "n", v: valueA1, z: "0%" });
      this.addCell({ t: "n", v: valueA1, z: "\"T\" #0.00" });
      this.addCell({ t: "n", v: valueA1, F: cellA1 });
      this.addCell({ t: "n", v: valueA1, F: cellA1 + "+" + cellB1 });
      this.addCell({ t: "n", v: valueA1 + valueB1, f: cellA1 + "+" + cellB1 });
      this.addCell({ t: "n", v: 0.14567, z: "0.00%" });
      this.addCell({ t: "n", v: 52100, z: "#,##0" });
      this.addCell({ t: "n", v: 999888777, z: "\"Rp\" #,##0" });
      this.addCell({ t: "n", v: 111222333.456789, z: "\"Rp\" #,##0.00" });
      this.addCell({ t: "d", v: new Date(), z: "dd/mm/yyyy" });

      this.addSheet("test 2");
      this.download("mixin excel.xlsx");
    }
  }
}
</script>