import DispatchServices from "@/services/DispatchServices.js";
import PurchaseServices from "@/services/PurchaseServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import ReceiveServices from "@/services/ReceiveServices.js";

export default {
  getPurchaseList: PurchaseServices.getList,
  getPurchaseDetails: PurchaseServices.getDetails,
  postPurchase: PurchaseServices.postPurchase,
  putPurchase: PurchaseServices.putPurchase,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // dispatch
  getDispatchDetails: DispatchServices.getDetails,
  // price list 
  getPriceList: ReceiveServices.getPriceList
}