import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchName: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true,
    Highlight: true
  },
  Barcode: {
    Label: "Barcode",
    Type: InputType.Text,
    ReadOnly: true
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Vendor: {
    Label: "Vendor",
    Type: InputType.Text,
    ReadOnly: true
  },
  Weight: {
    Label: "Berat (gram)",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Description: {
    Label: "Keterangan",
    Type: InputType.Text,
    ReadOnly: true
  },
  // support
  FullName: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true,
    Highlight: true
  },
  HppTotal: {
    Label: "Nilai Stok",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  HppPerPcs: {
    Label: "Hpp Per Pcs",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  Quantity: {
    Label: "Stok",
    Type: InputType.Integer,
    ReadOnly: true
  },
  ImageSrc: {
    Label: "Gambar",
    Type: InputType.Text,
    ReadOnly: true
  }
}