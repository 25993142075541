<template>
  <table>
    <tr>
      <td>&nbsp;</td>
    </tr>

    <tr>
      <td>No. {{ data.SONumber }}</td>
      <td class="my-thermal-date has-text-right">
        Tgl. <kst-value class="flex" field="InvoiceDate" :data="data" :catalog="Catalog"/>
      </td>
    </tr>
  </table>
</template>

<script>
import Catalog from "./PrintThermalCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>