import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "IsActive"
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  Quantity: {
    Label: "Qty Kirim",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true,
    Valid: true
  },
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  },
  Weight: {
    Label: "Berat (gram)",
    Type: InputType.Integer,
    ReadOnly: true
  },
  AvailableQuantity: {
    Label: "Qty Jual",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Price: {
    Label: "Harga Jual",
    Type: InputType.Decimal,
    Output: OutputType.Integer.Currency,
    ReadOnly: true
  },
  // support
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  ImageSrc: {
    Label: "Gambar",
    Type: InputType.Text,
    ReadOnly: true
  }
}