<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="DiscPercent"
      :label="Catalog.DiscPercent.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../SpecialPriceCatalog.js";

export default {
  props: {
    failureList: String,
    isListLoading: Boolean,
    items: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>