export default {
  Position: {
    Top: "is-top",
    TopLeft: "is-top-left",
    TopRight: "is-top-right",
    Bottom: "is-bottom",
    BottomLeft: "is-bottom-left",
    BottomRight: "is-bottom-right"
  },

  Table: {
    Sort: {
      Asc: "asc",
      Desc: "desc"
    }
  },

  Type: {
    Black: "is-black",
    Danger: "is-danger",
    Dark: "is-dark",
    Info: "is-info",
    Light: "is-light",
    Primary: "is-primary",
    Success: "is-success",
    Warning: "is-warning",
    White: "is-white"
  }
}