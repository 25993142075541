import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";
import ItemModel from "@/components/Item/ItemModel.js";
import ItemCatalog from "./SpecialPriceAdjItemCatalog.js";

export default {
  Module: AppModule.SpecialPriceAdj,
  ModuleType: {
    New           : AppModuleType.New,
    Edit          : AppModuleType.Edit,
    Details       : AppModuleType.Details,
    Export        : AppModuleType.Export,
    ExportDetails : AppModuleType.ExportDetails,
    Print         : AppModuleType.Print,
    PrintDetails  : AppModuleType.PrintDetails,
    List          : AppModuleType.List
  },

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New   : AppModuleType.New,
      List  : AppModuleType.List,
    }
  },

  Search: {
    Fields: ["AdjNumber"]
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  Data: {
    DefaultSpecialPrice   : { SpecialPriceValue: 0 },
    DefaultGrandTotal     : { GrandTotal: 0 },
    SpecialPriceOptions   : [],
    DefaultBarcodeSearch  : { Barcode: "", QtyScan: 1 }
  },
  
  getDefaultDetail() {
    const defaultDetail = {
      Date: App.In.getDateToday(),
      WarehouseID: null,
      AdjNumber: "",
      IsAutoNumber: true,
      Total: 0
    }
    return defaultDetail;
  },
  getDefaultSpecialPrice() {
    return this.Data.DefaultSpecialPrice;
  },
  getDefaultGrandTotal() {
    return this.Data.DefaultGrandTotal;
  },
  getDefaultBarcodeSearch() {
    return this.Data.DefaultBarcodeSearch;
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  getItemInfo(item) {
    let infoList = [];
    infoList.push(ItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", item, ItemCatalog)
    );
    infoList.push(ItemCatalog.AvailableQty.Label + ": " +
      App.Value.getValue("AvailableQty", item, ItemCatalog) + " pcs"
    );
    return infoList;
  },
  saveSpecialPriceOptions(items) {
    const rowId       = "ID";
    const rowLabel    = "Name";
    let listOptions   = { id: rowId, label: rowLabel }
    const selectItems = App.Search.createList(items, listOptions);
    const options     = App.In.getSelectOptions(rowId, rowLabel, selectItems);
    this.Data.SpecialPriceOptions = options;
    return options;
  },
  getGrandTotal(item) {
    let grandTotal = 0;
    if(item.length > 0) {
      grandTotal = item.map(row => row.Total).reduce((prev, next) => prev + next)
    }
    return grandTotal;
  },
  getOptionsData(item) {
    const Packaging     = item.Packaging.find(row => row.ItemUnitName == item.PackagingName) || {}
    const SpecialPrice  = item.SpecialPriceOptions.rows.find(row => row.ID == item.SpecialPriceID) || {}
    return {
      Packaging: Packaging,
      SpecialPrice: SpecialPrice
    }
  },
  populateSearchParam(param) {
    let options = {};
    if(param) {
      options.field     = this.Search.Fields,
      options.name      = App.Out.getString(param.Search),
      options.warehouse = App.Search.getValue(param.WarehouseID),
      options.startdate = App.Out.getDate(param.Date[0]);
      options.enddate   = App.Out.getDate(param.Date[1]);
    }
    return options;
  },
  mapDataPrintItemDetail(item) {
    const SellPrice = App.Out.getInteger(item.SellPrice);
    const DiscPercent = App.Out.getInteger(item.SpecialPriceDiscPercent || 0);
    item.DiscountedValue = SellPrice - App.Data.getDiscPercent_Value(SellPrice, DiscPercent);
    item.Qty = item.Qty + " " + item.PackagingName;
    return item;
  },
  mapDataItemDetail(data, item) {
    const Packaging           = data.Packaging.filter(item => item.IsActive == 1);
    const PackagingValue      = App.Out.getInteger(Packaging[0].ItemUnitAmount);
    const Quantity            = App.Out.getInteger(item.QtyScan || 1);
    const SellPrice           = App.Out.getInteger(data.SellPrice);
    const Subtotal            = (Quantity * PackagingValue) * SellPrice;
    item.Packaging            = Packaging;
    item.PackagingOptions     = ItemModel.getPackagingOptions(data.Packaging);
    item.SpecialPriceOptions  = this.Data.SpecialPriceOptions;
    item.PackagingName        = Packaging[0].ItemUnitName;
    item.PackagingValue       = PackagingValue;
    item.DispatchName         = data.FullName;
    item.SellPrice            = App.Out.getInteger(data.SellPrice);
    item.SpecialPriceID       = 0;
    item.DiscountedValue      = 0;
    item.AvailableQty         = App.Out.getInteger(item.Quantity);
    item.Qty                  = Quantity;
    item.Total                = Subtotal;
    item.SpecialPriceName     = "";
    item.Errors               = [];
    item.ErrorsColl           = {};
    item.Qty_Valid            = true;
    item.SpecialPriceQuota    = App.Out.getInteger(item.Quantity);
    item.SpecialPriceID_Valid = false;
    return item;
  },
  updateDataItemDetail(item) {
    const { Packaging, SpecialPrice } = this.getOptionsData(item);
    const DiscPercent         = App.Out.getInteger(SpecialPrice.DiscPercent || 100);
    const PackagingValue      = App.Out.getInteger(Packaging.ItemUnitAmount || 1);
    const Quantity            = App.Out.getInteger(item.Qty);
    const AvailableQty        = App.Out.getInteger(item.AvailableQty);
    const SellPrice           = App.Out.getInteger(item.SellPrice);
    const DiscountedValue     = SellPrice - App.Data.getDiscPercent_Value(SellPrice, DiscPercent);
    const Subtotal            = (Quantity * PackagingValue) * (DiscountedValue);
    item.DiscountedValue      = DiscountedValue;
    item.Total                = Subtotal;
    item.PackagingValue       = PackagingValue;
    item.SpecialPriceQuota    = AvailableQty;
    item.Qty_Valid            = this.validateItemQty(item);
    item.SpecialPriceID_Valid = this.validateSpecialID(item);
  },
  populatePostData(data, items) {
    const result            = {
      ID                    : data.ID,
      CreatedByID           : App.Session.getUserID(),
      CreatedByName         : App.Session.getName(),
      CompanyID             : App.Session.getCompanyID(),
      WarehouseID           : data.WarehouseID,
      AdjNumber             : data.IsAutoNumber ? null : App.Out.getString(data.AdjNumber),
      Date                  : App.Out.getDate(data.Date),
      Comment               : data.Comment || "",
      Items                 : items.map(item => {
        const { Packaging, SpecialPrice } = this.getOptionsData(item);
        const PackagingValue  = App.Out.getInteger(Packaging.ItemUnitAmount || 1);
        const Quantity        = App.Out.getInteger(item.Qty);
        return {
          DispatchID          : item.DispatchID,
          DispatchName        : item.DispatchName,
          PackagingName       : item.PackagingName,
          PackagingValue      : PackagingValue,
          Qty                 : Quantity,
          TotalQty            : (Quantity * PackagingValue),
          SellPrice           : item.SellPrice,
          Total               : item.Total,
          SpecialPriceID      : SpecialPrice.ID,
          SpecialPriceName    : SpecialPrice.Name,
          SpecialPriceDiscPercent  : SpecialPrice.DiscPercent
        }
      })
    }
    return result;
  },
  setItemErrors(item, field, errors) {
    item.ErrorsColl[field] = [];
    for (const error of errors) {
      if (error !== App.Vee.ValidRule) {
        item.ErrorsColl[field].push(error);
      }
    }
    let fieldErrors, newErrors = [];
    for (const fieldName in item.ErrorsColl) {
      fieldErrors = item.ErrorsColl[fieldName];
      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }
    const Quantity        = App.Out.getInteger(item.Qty || 0);
    const AvailableQty    = App.Out.getInteger(item.AvailableQty || 0);
    const PackagingValue  = App.Out.getInteger(item.PackagingValue || 1);
    if(!item.SpecialPriceID) {
      newErrors.push("Harga spesial tidak boleh kosong");
    }
    if((Quantity * PackagingValue) > AvailableQty) {
      newErrors.push("Maximum quantity adalah " + AvailableQty);
    }
    item.Errors = newErrors;
  },
  validateItemQty(item) {
    const Quantity        = App.Out.getInteger(item.Qty || 0);
    const AvailableQty    = App.Out.getInteger(item.AvailableQty || 0);
    const PackagingValue  = App.Out.getInteger(item.PackagingValue || 1);
    if((Quantity * PackagingValue) > AvailableQty) {
      return false;
    }
    return true;
  },
  validateSpecialID(item) {
    if(!item.SpecialPriceID) {
      return false;
    }
    return true;
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },

  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },
  updateItemSpecialPrice(item) {
    this.helper.updateItemSpecialPrice(item);
  },

  helper: {
    getItemInfo_Details(item) {
      let infoList = [];
      infoList.push(ItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, ItemCatalog)
      );
      return infoList;
    },

    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    updateItemSpecialPrice(item) {
      item.SpecialPriceID = item.SpecialPriceID || null;
      item.SpecialPriceName = item.SpecialPriceName || App.Search.LabelNone + ItemCatalog.SpecialPriceName.Label;
      item.DiscountedValue = item.SellPrice - App.Data.getDiscPercent_Value(item.SellPrice, item.SpecialPriceDiscPercent);
    }
  }
}