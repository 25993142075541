<template>
  <kst-container class="kst-dispatch-related">
    <kst-section :title="title">
      <List
        :access="access"
        :failureList="failureList"
        :isListLoading="isListLoading"
        :items="items"
      />
    </kst-section>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../DispatchModel.js";

import List from "./DispatchRelated_List";
import Services from "../DispatchServices.js";

export default {
  mixins: [AxiosMixin],
  components: {
    List
  },
  props: {
    access: Object,
    itemId: Number
  },
  data: () => ({
    items: [],
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return Model.ModuleType.List.Name + " " + Model.Module.FullName
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.itemId);
    },

    loadList(counter, itemId) {
      this.isListLoading = true;
      let options = {};
      options.item = itemId;

      this.failureInit = null;
      const config = Services.getDispatchList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.isLoading = false;
      this.isListLoading = false;
    },
    loadList_Error(message) {
      this.failureList = message;
      this.isLoading = false;
    },
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>