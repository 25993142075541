<template>
  <kst-section :title="sectionTitle">
    <div class="columns">
      <div class="column is-narrow kst-item-edit-dispatch-edit-SKU">
        <kst-input floating-label
          field="SKU" :data="editDispatch" :catalog="DispatchEditCatalog"
        />
      </div>

      <div class="column is-narrow kst-item-edit-dispatch-edit-Barcode">
        <kst-input floating-label
          field="Barcode" :data="editDispatch" :catalog="DispatchEditCatalog"
        />
      </div>

      <div class="column is-narrow kst-item-edit-dispatch-edit-SellPrice">
        <kst-input floating-label
          field="SellPrice" :data="editDispatch" :catalog="DispatchEditCatalog"
        />
      </div>

      <div class="column is-narrow kst-item-edit-dispatch-edit-Weight">
        <kst-input floating-label
          field="Weight" :data="editDispatch" :catalog="DispatchEditCatalog"
        />
      </div>

      <div class="column is-narrow kst-item-edit-dispatch-edit-Description">
        <kst-input floating-label
          field="Description" :data="editDispatch" :catalog="DispatchEditCatalog"
        />
      </div>

      <div class="column is-narrow">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.ItemDispatch.Module"
          :moduleType="Model.ItemDispatch.ModuleType.Edit"
          @click="handleEdit_Dispatch"
        />
      </div>
    </div>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import DispatchEditCatalog from "../DispatchEditCatalog.js";

export default {
  props: {
    access: Object,
    editDispatch: Object,
    userDispatches: Array
  },
  data: () => ({
    Model: Model,
    DispatchEditCatalog: DispatchEditCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.DispatchEdit.Title;
    }
  },
  methods: {
    handleEdit_Dispatch() {
      Model.updateDispatchesByEditData(this.userDispatches, this.editDispatch);
    }
  }
}
</script>