<template>
  <kst-container class="kst-sales-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <Details
          :userData="userData"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
        />
        <Items :userData="userData" :userItems="userItems"/>
        <Total :data="userData"/>
        <Details2
          class="mt-5"
          :userData="userData"
          :userItems="userItems"
          :discTypeOptions="discTypeOptions"
        />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../SalesModel.js";
import Router from "../SalesRouter.js";
import Services from "../SalesServices.js";

import Details from "./SalesNew_Details";
import Details2 from "./SalesNew_Details2";
import Items from "./SalesNew_Items";
import Total from "./SalesNew_Total";

export default {
  components: {
    Details,
    Details2,
    Items,
    Total
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    userItems: [],
    isLoading: false,
    saveLoading: null,
    discTypeOptions: {},
    warehouseOptions: {},
    cancelUrl: Router.Path.List,
    // error
    errors: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.discTypeOptions = Model.createDiscTypeOptions();

      this.initLoad();
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      let options = {};
      options.active = Model.IsActive.Active.ID;

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postSales(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>