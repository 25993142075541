import App_Output from "./App_Output.js";

export default {
  getCompanyID() {
    return App_Output.getInteger(localStorage.getItem("companyID"));
  },
  getCompanyName() {
    return App_Output.getString(localStorage.getItem("companyName"));
  },
  getCompanyImageSrc() {
    let imgSrc = localStorage.getItem("companyImageSrc");
    return imgSrc === "null" ? null : App_Output.getString(imgSrc);
  },
  getName() {
    return App_Output.getString(localStorage.getItem("name"));
  },
  getUserID() {
    return App_Output.getInteger(localStorage.getItem("userID"));
  },
  getAccessID() {
    return App_Output.getInteger(localStorage.getItem("accessID"));
  },
  getDefaultMenu() {
    return localStorage.getItem("defaultMenu");
  },
  getMenu() {
    const menu = localStorage.getItem("menu");
    if (menu === "undefined" || menu === undefined || menu === null) {
      return {};
    }
    return JSON.parse(localStorage.getItem("menu"));
  },
  getToken() {
    return localStorage.token;
  },

  setCompanyID(value) {
    localStorage.setItem("companyID", value);
  },
  setCompanyName(value) {
    localStorage.setItem("companyName", value);
  },
  setCompanyImageSrc(value) {
    localStorage.setItem("companyImageSrc", value);
  },
  setName(value) {
    localStorage.setItem("name", value);
  },
  setUserID(value) {
    localStorage.setItem("userID", value);
  },
  setAccessID(value) {
    localStorage.setItem("accessID", value);
  },
  setDefaultMenu(value) {
    localStorage.setItem("defaultMenu", value);
  },
  setMenu(value) {
    localStorage.setItem("menu", JSON.stringify(value));
  },

  clear() {
    localStorage.removeItem("companyID");
    localStorage.removeItem("name");
    localStorage.removeItem("userID");
    localStorage.removeItem("accessID");
    localStorage.removeItem("defaultMenu");
    localStorage.removeItem("menus");
  }
}