import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./OmzetModel.js";

export default {
  OmzetLabelDetail: {
      Label: "Omzet",
      BackgroundColor: "#42b983"
  },
  Omzet: {
    Title: "Omzet",
    TitleSection: "Omzet",
    Icon: "mdi mdi-36px mdi-poll ",
    IconStyle: "kst-dashboard-sales ",
    IconBgStyle: "kst-dashboard-sales-bg ",
    Field: "Total",
    Help: {
      Icon: "mdi mdi-calendar-clock",
      Title: "Last 24 Hours",
      Style: "kst-dashboard-sales-help "
    }
  },
  ReturLabelDetail: {
    Label: "Retur",
    BackgroundColor: "#f14668"
  },
  Retur: {
    Title: "Retur",
    TitleSection: "Retur",
    Icon: "mdi mdi-36px mdi-poll ",
    IconStyle: "kst-dashboard-sales ",
    IconBgStyle: "kst-dashboard-sales-bg ",
    Field: "Total",
    Help: {
      Icon: "mdi mdi-calendar-clock",
      Title: "Last 24 Hours",
      Style: "kst-dashboard-sales-help "
    }
  },
  Tabs: {
    Details: {
        Label: "Rincian"
    }
  },
  Sales: {
    Title: "Penjualan" 
  },
  Section: {
    PaymentType: {
      Title: "Berdasarkan Jenis Pembayaran"
    },
    SellingPrice: {
      Title: "Berdasarkan Harga Jual"
    }
  },
  Export: {
    List: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name + " " + AppModule.Omzet.FullName
        },
        Section: {
          List: Model.ModuleType.List.Name + " " + AppModuleType.Details.Name + ":",
          Expense: "Perhitungan" + " " + AppModule.Omzet.FullName + ":",
          ExpenseDetails: "Rincian Penjualan:"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.List.Name + " " +
          Model.Module.FullName,
        Option: {
          List: Model.ModuleType.List.Name + " " + AppModule.Omzet.FullName,
          Expense:"Perhitungan" + " " + AppModule.Omzet.Name
        }
      }
    }
  },
}