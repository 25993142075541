<template>
  <div class="kst-dashboard">
    <div class="columns">
      <div class="column kst-dashboard-item">
        <kst-container class="px-5 py-5 kst-dashboard-filter">
          <kst-section :title="sectionTitle">
            <kst-input floating-label
              field="PeriodId" :data="data" :catalog="SearchCatalog" :options="periodOptions"
              @input="handleInput"
            />
          </kst-section>
        </kst-container>
      </div>
    </div>
    <div class="columns" v-if="isLoading">
      <div class="column">
        <kst-skeleton :active="isLoading"/>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-dashboard-item" v-if="!isLoading">
        <Summary
          :icon="Info.Profit.Icon"
          :iconStyle="Info.Profit.IconStyle"
          :iconBgStyle="Info.Profit.IconBgStyle"
          :title="Info.Profit.Title"
          :helpStyle="Info.Profit.Help.Style"
          :helpIcon="Info.Profit.Help.Icon"
          :helpTitle="Info.Profit.Help.Title"
          :field="Info.Profit.Field"
          :titleButton="'Lihat Laporan'"
          :data="data"
          :url="Router.Path.ProfitLoss"
          :urlParams="urlParams"
        />

        <div class="columns is-mobile">
          <div class="column kst-dashboard-item" v-if="!isLoading">
            <Summary
              :icon="Info.Sales.Icon"
              :iconStyle="Info.Sales.IconStyle"
              :iconBgStyle="Info.Sales.IconBgStyle"
              :title="Info.Sales.Title"
              :helpStyle="Info.Sales.Help.Style"
              :helpIcon="Info.Sales.Help.Icon"
              :helpTitle="Info.Sales.Help.Title"
              :field="Info.Sales.Field"
              :titleButton="'Lihat Laporan'"
              :data="data"
              :url="Router.Path.Invoice"
              :urlParams="urlParams"
            />
          </div>
        </div>
      </div>

      <div class="column is-half kst-dashboard-item" v-if="!isLoading">
        <DashboardBestSelling :datePeriod="datePeriod" />
      </div>
    </div>
  </div>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Services from "./DashboardServices.js";
import SearchCatalog from './DashboardSearchCatalog.js';
import Model from './DashboardModel.js';
import Info from './DashboardInfo.js';
import Router from './DashboardRouter.js';

import Summary from "./Dashboard_Summary";
import DashboardBestSelling from "@/components/RptBestSellingItems/Dashboard/DashboardBestSelling";

export default {
  mixins: [AxiosMixin],
  components: {
    Summary,
    DashboardBestSelling
  },
  data: () => ({
    periodOptions: {},
    data: {},
    urlParams: "",
    SearchCatalog: SearchCatalog,
    Info: Info,
    Router: Router,
    isLoading: true,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Filter.Title;
    },
    datePeriod() {
      const item = this.getData(this.periodOptions, this.data.PeriodId);
      const options = [item.startDate, item.endDate];
      return options;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = {
        Profit: null,
        PeriodId: Model.FilterType.ThisMonth.ID,
        Total: null,
      };

      this.periodOptions = Model.createFilterOptions();

      this.loadCounter++;
      this.loadData(this.loadCounter);
    },

    loadData(counter) {
      this.isLoading = true;
      const item = this.getData(this.periodOptions, this.data.PeriodId);
      const options = {
        StartDate: item.startDate,
        EndDate: item.endDate
      }

      this.failureInit = null;
      const config = Services.getSalesInvoiceTotal(options);
      this.runAxios(config, counter, this.loadData_Success, this.loadData_Error);

      this.urlParams = "?startdate=" + item.startDate.toISOString() + 
        "&enddate=" + item.endDate.toISOString();
    },

    loadData_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }
      if (data.Total === null) {
        this.data.Total = 0
      } else {
        this.data.Total = data.Total;
      }
      this.loadCounter++;
      this.loadReport(this.loadCounter);
    },
    loadData_Error(message) {
      this.failureList = message;
      this.isLoading = false;
    },

    loadReport(counter) {
      const item = this.getData(this.periodOptions, this.data.PeriodId);

      this.failureInit = null;
      const config = Services.getProfitLossTotal(item);
      this.runAxios(config, counter, this.loadReport_Success, this.loadReport_Error);
    },

    loadReport_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      let expenseTotal = 0;

      for (const expense of data.Expenses) {
        expenseTotal += parseInt(expense.Total);
      }

      this.data.Profit = data.Summary.All.TotalProfit - expenseTotal;
      this.isLoading = false;
    },
    loadReport_Error(message) {
      this.failureList = message;
      this.isLoading = false;
    },

    handleInput() {
      this.loadCounter++;
      this.loadData(this.loadCounter);
    },

    getData(items, id) {
      for (const item of items.rows) {
        if (item.ID === id) {
          return {
            startDate: item.StartDate,
            endDate: item.EndDate,
            limit: item.Limit
          };
        }
      }
      return null;
    }

  }
}
</script>

<style>
@import "./_layout.css";
</style>