import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

export default {
  InvoiceDate: {
    Label: "Tanggal Invoice",
    Type: InputType.Date,
    ReadOnly: true,
    isRange: false,
    Output: OutputType.Date.ShortDate
  },
  Price: {
    Label: "Total",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  PaymentTypeID: {
    Label: "Jenis Pembayaran",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "PaymentTypeName",
    RelatedModule: AppModule.PaymentType,
  },
  PaymentTypeName: {
    Label: "Jenis Pembayaran",
    Type: InputType.Text,
    ReadOnly: true
  },
  PaymentValue: {
    Label: "Jumlah Bayar",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  PayReturn: {
    Label: "Jumlah Kembalian",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  Total: {
    Label: "Total",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  TotalDisc: {
    Label: "Total Diskon",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  TotalBruto: {
    Label: "Total Bruto",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  SpecialPriceID: {
    Label: "Diskon",
    Type: InputType.Select
  },
  SpecialPriceName: {
    Label: "Diskon",
    Type: InputType.Text,
    MaxLength: 50
  },
  DiscountedValue: {
    Label: "Harga Spesial",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  SavingPrice: {
    Label: "Hemat",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  BasicTotalPrice: {
    Label: "Total Harga",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  }
}