<template>
  <kst-section items-mode class="mt-5">
    <ItemInvoiceSearch
      ref="mdlItemInvoiceSearch"
      :items="itemInvoice" 
      @submit="handleItemInvoice_Submit"
    />

    <template #top-right >
      <template v-if="data.SOID !== null">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.ItemInvoice.Title"
          :moduleType="Model.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom >
      <template v-if="items.length > 0" >
        <template v-if="data.SOID !== null">
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.ItemInvoice.Title"
            :moduleType="Model.ModuleType.New"
            @click="handleAdd_Item"
          />
        </template>
      </template>
    </template>

    <template v-if="data.SOID === null">
      <kst-warning :items="detailWarning"/>
    </template>
    <template v-else>
      <kst-table action input-mode :data="items">
        <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
          <template #header>
            <kst-header-blank />
          </template>
          <template #default="{ row }">
            <kst-tooltip v-if="row.Errors.length > 0" error-mode>
              <template #content>
                <kst-list :items="row.Errors"/>
              </template>
              <kst-icon error-mode/>
            </kst-tooltip>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="{ column, row, index }"
          field="Name"
          :label="DeliveryItemCatalog.Name.Label"
        >
          <kst-output box-off field-only
            :index="index" :field="column.field" 
            :data="row" :catalog="DeliveryItemCatalog"
          />
        </b-table-column>

        <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
          <template #header>
            <kst-header-blank />
          </template>
          <template #default="{ row }">
            <kst-tooltip info-mode>
              <template #content>
                <kst-list info-mode :items="Model.getItemInfo_Edit(row)"/>
              </template>
              <kst-icon info-mode/>
            </kst-tooltip>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="{ column, row, index }"
          field="Packaging"
          :label="DeliveryItemCatalog.Packaging.Label"
        >
          <kst-output box-off field-only
            :index="index" :field="column.field" 
            :data="row" :catalog="DeliveryItemCatalog"
          />
        </b-table-column>

        <b-table-column
          v-slot="{ column, row, index }"
          field="AvailableQuantity"
          :label="DeliveryItemCatalog.AvailableQuantity.Label"
        >
          <kst-output box-off field-only
            :index="index" :field="column.field" 
            :data="row" :catalog="DeliveryItemCatalog"
          />
        </b-table-column>

        <b-table-column
          v-slot="{ column, row, index }"
          field="Quantity"
          :label="DeliveryItemCatalog.Quantity.Label"
        >
          <kst-input field-only
          :field="column.field" :data="row" :catalog="DeliveryItemCatalog"
          :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
        />
        </b-table-column>

        <b-table-column
          v-slot="{ column, row, index }"
          field="Weight"
          :label="DeliveryItemCatalog.Weight.Label"
        >
          <kst-output box-off field-only
            :index="index" :field="column.field" 
            :data="row" :catalog="DeliveryItemCatalog"
          />
        </b-table-column>

        <template #action="{ index }">
          <kst-tooltip delete-mode hover-only :content="Model.Item.Module.FullName">
            <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
          </kst-tooltip>
        </template>
      </kst-table>
    </template>
  </kst-section>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import DeliveryItemCatalog from "../DeliveryItemCatalog.js";
import Model from "../DeliveryModel.js";
import Info from "../DeliveryInfo.js";

import ItemInvoiceSearch from "./DeliveryNew_AddItemInvoice";

export default {
  components: {
    ItemInvoiceSearch
  },
  mixins: [DialogMixin],
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    DeliveryItemCatalog: DeliveryItemCatalog,
    Model: Model,
    selectedIndex: null,
    itemInvoice: []
  }),
  computed: {
    detailWarning() {
      return [Info.Message.New_Details];
    }
  },
  methods: {
    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.items[index], field, errors);
    },
    handleQuantity(item) {
      Model.validateItem(item);
      Model.updateItem(item);
    },
    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      const item = this.items[this.selectedIndex];
      item.Quantity = "";
      this.itemInvoice.push(item);

      this.items.splice(this.selectedIndex, 1);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    },
    handleItemInvoice_Submit(row, index) {
      this.itemInvoice.splice(index, 1);
      this.items.push(row);
    },
    handleAdd_Item() {
      this.$refs.mdlItemInvoiceSearch.show();
    }
  }
}
</script>