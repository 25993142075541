<template>
  <div class="kst-wait">
    {{ text }}
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  computed: {
    text() {
      return AppCatalog.Message.Wait;
    }
  }
}
</script>