<template>
  <div>
    <div class="mb-2">Simple Excel:</div>
    <kst-button action-mode
      class="is-primary"
      tag="button"
      :module="Model.Module"
      :moduleType="Model.ModuleType.Export"
      @click="handleExport"
    />
  </div>
</template>

<script>
import Model from "../TestModel.js";

export default {
  data: () => ({
    Model: Model
  }),
  methods: {
    handleExport() {
      let workbook = this.$xlsx.utils.book_new();
      let sheet = this.$xlsx.utils.aoa_to_sheet([
        ["a", "b", "c"],
        [1, 2, 3]
      ]);
      this.$xlsx.utils.book_append_sheet(workbook, sheet, "test");
      this.$xlsx.writeFile(workbook, "simple excel.xlsx");
    }
  }
}
</script>