import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./VendorModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
        },
        Section: {
          VendorAddress: AppModuleType.List.Name + " " + Model.VendorAddress.Module.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          VendorAddress: AppModuleType.List.Name + " " + Model.VendorAddress.Module.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name
        }
      }
    }
  },
  Message: {
    New_ConfirmDeleteAddress:
      "Yakin menghapus " + Model.VendorAddress.Module.FullName + "?",
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
  },
  Section: {
    Address: {
      Title: AppModuleType.List.Name + " " + AppModule.VendorAddress.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name,
    Receive: AppModule.Receive.Name
  }
}