var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"paginated":"","view-mode":"","data":_vm.items,"loading":_vm.isListLoading,"failure":_vm.failureList,"selected":_vm.userData.selected},on:{"update:selected":function($event){return _vm.$set(_vm.userData, "selected", $event)}}},[_c('b-table-column',{attrs:{"field":"VendorName","label":_vm.Catalog.VendorName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Alias","label":_vm.Catalog.Alias.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Address","label":_vm.VendorAddressCatalog.Address.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ContactMobile","label":_vm.VendorAddressCatalog.ContactMobile.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ContactPhone","label":_vm.VendorAddressCatalog.ContactPhone.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ContactEmail","label":_vm.VendorAddressCatalog.ContactEmail.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.VendorAddressCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }