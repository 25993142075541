<template>
  <kst-section items-mode class="mt-5">
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="ItemID"
        :label="StockTrfItemCatalog.DispatchName.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StockTrfItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.getItemInfo_Details(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="Packaging"
        :label="StockTrfItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StockTrfItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="Qty"
        :label="StockTrfItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StockTrfItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="HppTotal"
        :label="StockTrfItemCatalog.HppTotal.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StockTrfItemCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import StockTrfItemCatalog from "../StockTrfItemCatalog.js";
import Model from "../StockTrfModel.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    StockTrfItemCatalog: StockTrfItemCatalog,
    Model: Model
  })
}
</script>