<template>
  <kst-container class="kst-item-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details :userData="userData" :userDispatches="userDispatches" :typeOptions="typeOptions"/>
        <Packagings :userData="userData" :userPackagings="userPackagings"/>
        <Variants
          :idObj="idObj"
          :userData="userData"
          :userVariants="userVariants"
          :userDispatches="userDispatches"
        />
        <Dispatches :access="access" :userDispatches="userDispatches"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import Router from "../ItemRouter.js";
import Services from "../ItemServices.js";

import Details from "./ItemNew_Details";
import Dispatches from "./ItemNew_Dispatches";
import Packagings from "./ItemNew_Packagings";
import Variants from "./ItemNew_Variants";

export default {
  components: {
    Details,
    Dispatches,
    Packagings,
    Variants
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    access: {},
    userData: {},
    userDispatches: [],
    userPackagings: [],
    userVariants: [],
    purchaseData: {},
    idObj: {},
    typeOptions: {},
    isLoading: false,
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null,
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.formWarnings = null;

      this.access = Model.createAccess();
      this.userData = Model.createDetails();
      this.userDispatches = [];
      this.userPackagings = [];
      this.userVariants = [];
      Model.updateDispatches(this.userDispatches, [], this.userData);
      this.idObj = Model.createIdObject();
      this.init_defaultPackaging();
      this.typeOptions = Model.createTypeOptions();

      this.init_purchaseOrder();
      this.initLoad();
    },
    init_defaultPackaging() {
      const packaging = Model.createPackaging();
      Model.setPackagingByDefault(packaging);
      this.userPackagings.push(packaging);
    },
    init_purchaseOrder() {
      // support purchase order
      this.formWarnings = null;
      const purchaseItemId = this.getUrlParam(this.$kst.Enum.Param.PurchaseItemID);

      if (purchaseItemId) {
        const purchaseId = this.getUrlParam(this.$kst.Enum.Param.PurchaseID);
        const itemName = this.getUrlParam(this.$kst.Enum.Param.Name);
        
        if (!purchaseId || !itemName) {
          this.formWarnings = [Info.Message.Edit_ParamIncomplete];
          return;
        }

        this.purchaseData = {
          purchaseId: purchaseId,
          purchaseItemId: purchaseItemId,
          itemName: itemName
        };

        this.userData.Name = this.purchaseData.itemName;
      }
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadNewData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadNewData() {
      let options = {};
      if (this.purchaseData.purchaseItemId) {
        options.purchaseitem = this.purchaseData.purchaseItemId;
      }

      const config = Services.getItemNewData(options);
      this.runAxios(config, null,
        this.loadNewData_Success,
        this.loadNewData_Error,
        this.loadNewData_Invalid
      );
    },
    loadNewData_Success(data) {
      // validation
      if (this.purchaseData.purchaseItemId) {
        this.validatePurchaseItem(data.PurchaseItem);
      }

      this.access = data.Access;
      this.initLoad_End();
    },
    loadNewData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadNewData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(
        this.userData, this.userVariants, this.userPackagings, this.userDispatches
      );
      this.saveData(data);
    },

    validatePurchaseItem(data) {
      if (data.DispatchID !== Model.NewItem.DispatchID) {
        this.formWarnings = [Info.Message.New_RegisteredNewItem];
      }
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postItem(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.saveLoading = null;
      this.routeLeaveEnabled = false;

      if (this.purchaseData.purchaseItemId) {
        this.notifyFormSuccess(() => {
          const urlParams = this.$kst.Route.getParams({
            [this.$kst.Enum.Param.Name]: this.purchaseData.itemName,
            [this.$kst.Enum.Param.PurchaseID]: this.purchaseData.purchaseId,
            [this.$kst.Enum.Param.PurchaseItemID]: this.purchaseData.purchaseItemId
          });
          this.redirectTo(Router.Path.Select + urlParams);
        });
      } else {
        this.notifyFormSuccess(() => {
          const urlParams = this.$kst.Route.getParams({
            [this.$kst.Enum.Param.ID]: data.id
          });
          this.redirectTo(Router.Path.Details + urlParams);
        });
      }
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>