var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{staticClass:"mt-5",attrs:{"items-mode":""}},[_c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.items},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Action',{attrs:{"data":_vm.data,"row":row}})]}}])},[_c('b-table-column',{attrs:{"field":"ItemID","label":_vm.PurchaseItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","control-off":row.DispatchID === _vm.Model.NewItem.DispatchID,"field":column.field,"data":row,"catalog":_vm.PurchaseItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-tooltip',{attrs:{"info-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"info-mode":"","items":_vm.Model.getItemInfo_Edit(row)}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"info-mode":""}})],1)]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"Packaging","label":_vm.PurchaseItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","box-off":row.DispatchID === _vm.Model.NewItem.DispatchID,"field":column.field,"data":row,"catalog":_vm.PurchaseItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"Quantity","label":_vm.PurchaseItemCatalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.PurchaseItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-price","field":"BuyPrice","label":_vm.PurchaseItemCatalog.BuyPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.PurchaseItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"ExpenseAmount","label":_vm.PurchaseItemCatalog.ExpenseAmount.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.PurchaseItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-status","field":"IsClosed","label":_vm.PurchaseItemCatalog.IsClosed.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-item-status',{attrs:{"field":column.field,"data":row,"catalog":_vm.PurchaseItemCatalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }