import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "FullName",
  },
  itemName: {
    Label: "Barang",
    MaxLength: 100,
    Type: InputType.Text,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    RelatedValue: "WarehouseName"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  Search: {
    Label: "Cari Nama, atau SKU",
    Type: InputType.Text,
    MaxLength: 50
  }
}