import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./RtnInvoiceCatalog.js";
import InvoiceModel from "@/components/Invoice/InvoiceModel.js";
import RtnInvoiceItemCatalog from "./RtnInvoiceItemCatalog.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.RtnInvoice,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print
  },

  /*** related ***/

  RtnInvoiceItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  Item: {
    Module: AppModule.Item
  },

  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      List: AppModuleType.List,
      Select: AppModuleType.Select
    }
  },

  /*** property ***/

  DiscType: {
    None: {
      ID: 0,
      Label: "Tanpa Diskon",
    },
    Value: {
      ID: 1,
      Label: "Diskon (Rp)",
    },
    Percent: {
      ID: 2,
      Label: "Diskon (%)",
    }
  },

  Search: {
    Fields: ["SONumber", "ReturnNumber", "ClientName"]
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails(this.DiscType);
  },
  createItem() {
    return this.helper.createItem();
  },
  createDiscTypeOptions(optionAllText) {
    return this.helper.createDiscTypeOptions(optionAllText, this.DiscType);
  },

  setDetailsByData(details, detailsData) {
    this.helper.setDetailsByData(details, detailsData, this.DiscType);
  },
  setDetailsByInvoice(details, invoiceData) {
    this.helper.setDetailsByInvoice(details, invoiceData, this.DiscType);
  },
  setItemByInvoiceItem(item, invoiceItem) {
    this.helper.setItemByInvoiceItem(item, invoiceItem);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },
  setItemsByData(items, rows, invoiceItemGroup) {
    this.helper.setItemsByData(items, rows, invoiceItemGroup);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },
  getItemInfo_Related(item) {
    return this.helper.getItemInfo_Related(item);
  },

  normDetailsByDisc(data) {
    this.helper.normDetailsByDisc(data, this.DiscType);
  },

  updateDetails(data, item) {
    this.helper.updateDetails(data, item, this.DiscType);
  },
  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data, this.DiscType);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validationDiscType(data) {
    return { max_value: data.TotalBruto };
  },

  validationItem_Quantity(item) {
    return { max_value: item.InvoiceItemQty };
  },
  validateItem(item) {
    this.helper.validateItem(item);
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  /*** external ***/

  getInvoiceItems_ForSelection(items, invoiceItemsGrouped) {
    return this.helper.getInvoiceItems_ForSelection(items, invoiceItemsGrouped);
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  updateInvoiceItem(invoiceItem) {
    InvoiceModel.updateItemByPackaging(invoiceItem);
  },

  helper: {
    createDetails(discTypeEnum) {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        WarehouseID: null,
        ReturnNumber: "",
        SalesOrderID: null,
        DiscType: discTypeEnum.None.ID,
        DiscValue: "",
        DiscPercent: "",
        Comment: "",
        // defined by system
        SONumber: null,
        InvoiceDate: null,
        ClientID: null,
        ClientAlias: null,
        ClientName: null,
        ClientAddress: null,
        Date: App.In.getDateToday(),
        TotalBruto: 0,
        TotalDisc: 0,
        Total: 0,
        IsAutoNumber: true,
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID(),
      };
    },
    createItem() {
      return {
        ID: null,
        // user input
        DispatchID: null,
        PackagingName: "",
        Quantity: "",
        SellPrice: "",
        // defined by system
        DispatchName: null,
        PackagingValue: 1,
        TotalQuantity: 0,
        Total: 0,
        SKU: null,
        DispatchFullName: null,
        DispatchIsActive: true,
        Packaging: null,
        PackagingPcs: null,
        Index: 0,
        // error
        Errors: [],
        ErrorsColl: [],
        // UI validation
        DispatchID_Valid: true,
        InvoiceItemQty: 0
      }
    },
    createDiscTypeOptions(optionAllText, discTypeEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
        { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
        { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByData(details, detailsData, discTypeEnum) {
      details.ID = detailsData.ID;
      // user input
      details.WarehouseID = detailsData.WarehouseID;
      details.ReturnNumber = App.In.getString(detailsData.ReturnNumber);
      details.SalesOrderID = detailsData.SalesOrderID;
      details.Comment = App.In.getString(detailsData.Comment);
      // defined by system
      details.SONumber = detailsData.SONumber;
      details.InvoiceDate = detailsData.InvoiceDate;
      details.ClientID = detailsData.ClientID;
      details.ClientAlias = detailsData.ClientAlias;
      details.ClientName = detailsData.ClientName;
      details.ClientAddress = detailsData.ClientAddress;
      details.Date = App.In.getDate(detailsData.Date);

      if (detailsData.DiscValue !== null) {
        details.DiscType = discTypeEnum.Value.ID;
        details.DiscValue = App.In.getInteger(detailsData.DiscValue);
      }
      else if (detailsData.DiscPercent !== null) {
        details.DiscType = discTypeEnum.Percent.ID;
        details.DiscPercent = detailsData.DiscPercent;

        this.normDetailsByDisc(details, discTypeEnum);
      }
      else {
        details.DiscType = discTypeEnum.None.ID;
      }
    },
    setDetailsByInvoice(details, invoiceData, discTypeEnum) {
      // user input
      details.WarehouseID = invoiceData.WarehouseID;
      details.SalesOrderID = invoiceData.ID;
      // defined by system
      details.SONumber = invoiceData.SONumber;
      details.InvoiceDate = App.In.getDate(invoiceData.InvoiceDate);
      details.ClientID = invoiceData.ClientID;
      details.ClientAlias = invoiceData.ClientAlias;
      details.ClientName = invoiceData.Client;
      details.ClientAddress = invoiceData.ClientAddress;

      if (invoiceData.DiscValue !== null) {
        details.DiscType = discTypeEnum.Value.ID;
        details.DiscValue = invoiceData.DiscValue;
      }
      else if (invoiceData.DiscPercent !== null) {
        details.DiscType = discTypeEnum.Percent.ID;
        details.DiscPercent = invoiceData.DiscPercent;

        this.normDetailsByDisc(details, discTypeEnum);
      }
    },
    setItemByInvoiceItem(item, invoiceItem) {
      // user input
      item.DispatchID = invoiceItem.DispatchID;
      item.PackagingName = invoiceItem.PackagingName;
      item.Quantity = App.In.getInteger(invoiceItem.QtyAvailable);
      item.SellPrice = App.In.getInteger(invoiceItem.SellPrice);
      // defined by system
      item.DispatchName = invoiceItem.Name;
      item.PackagingValue = invoiceItem.PackagingValue;
      item.SKU = invoiceItem.SKU;
      item.DispatchIsActive = App.In.getBoolean(invoiceItem.DispatchIsActive);
      item.InvoiceItemQty = invoiceItem.QtyAvailable;
      item.Index = invoiceItem.Index;
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.DispatchID_Valid) {
        if (!item.DispatchIsActive) {
          newErrors.push(RtnInvoiceItemCatalog.DispatchID.Label + " tidak aktif");
        }
      }

      item.Errors = newErrors;
    },
    setItemsByData(items, returnItems) {
      App.Array.truncate(items);

      for (const returnItem of returnItems) {
        let item = this.createItem();
        this.setItemsByData_Item(item, returnItem);
        this.updateItem(item);
        items.push(item);
      }
    },
    setItemsByData_Item(item, returnItem) {
      item.ID = returnItem.ID;
      // user input
      item.DispatchID = returnItem.DispatchID;
      item.PackagingName = returnItem.PackagingName;
      item.Quantity = App.In.getInteger(returnItem.Quantity);
      item.SellPrice = App.In.getInteger(returnItem.SellPrice);
      // defined by system
      item.DispatchName = App.In.getString(returnItem.DispatchName);
      item.PackagingValue = returnItem.PackagingValue;
      item.SKU = returnItem.SKU;
      item.DispatchFullName = returnItem.DispatchFullName;
      item.DispatchIsActive = App.In.getBoolean(returnItem.DispatchIsActive);
      item.Index = returnItem.Index;
      // UI validation
      item.InvoiceItemQty = returnItem.InvoiceItemQty;
    },

    getItemInfo(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // InvoiceItemQty
      infoList.push(RtnInvoiceItemCatalog.InvoiceItemQty.Label + ": " +
        App.Value.getValue("InvoiceItemQty", item, RtnInvoiceItemCatalog) + " " +
        item.Packaging
      );

      // SKU
      infoList.push(RtnInvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RtnInvoiceItemCatalog)
      );

      return infoList;
    },
    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SalesOrderItemQty
      infoList.push(RtnInvoiceItemCatalog.SalesOrderItemQty.Label + ": " +
        App.Value.getValue("Quantity", item, RtnInvoiceItemCatalog) + " " +
        App.Data.getPackaging(item.PackagingName, item.PackagingValue)
      );

      // SKU
      infoList.push(RtnInvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RtnInvoiceItemCatalog)
      );

      return infoList;
    },
    getItemInfo_Related(item) {
      let infoList = [];

      // SKU
      infoList.push(RtnInvoiceItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RtnInvoiceItemCatalog)
      );

      return infoList;
    },

    normDetailsByDisc(data, discTypeEnum) {
      if (data.DiscType === discTypeEnum.Percent.ID) {
        data.DiscPercent = App.Value.getValue("DiscPercent", data, Catalog);
      }
    },

    updateDetails(data, items, discTypeEnum) {
      let total = 0;

      // TotalBruto
      for (const item of items) {
        total += item.Total;
      }
      data.TotalBruto = total;
      
      // TotalDisc
      if (data.DiscType === discTypeEnum.Value.ID) {
        data.TotalDisc = App.JS.parseInt(data.DiscValue);
        total -= data.TotalDisc;
      }
      else if (data.DiscType === discTypeEnum.Percent.ID) {
        data.TotalDisc = App.Data.getDiscPercent_Value(total, data.DiscPercent);
        total -= data.TotalDisc;
      }

      // Total
      data.Total = total;
    },

    updateItem(item) {
      this.updateItemByPackaging(item);
      item.TotalQuantity = App.Data.getTotalQty(item.Quantity, item.PackagingValue);
      item.Total = App.Data.getTotal(item.Quantity, item.PackagingValue, item.SellPrice);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    populateDetails(data, discTypeEnum) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        ReturnNumber: data.IsAutoNumber ? null : App.Out.getString(data.ReturnNumber),
        SalesOrderID: data.SalesOrderID,
        SONumber: App.Out.getString(data.SONumber),
        ClientID: data.ClientID,
        ClientName: data.ClientName,
        ClientAddress: data.ClientAddress,
        Date: App.Out.getDate(data.Date),
        DiscValue: (data.DiscType === discTypeEnum.Value.ID ? parseInt(data.DiscValue) : null),
        DiscPercent: (data.DiscType === discTypeEnum.Percent.ID ? parseFloat(data.DiscPercent) : null),
        Comment: App.Out.getString(data.Comment),
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID,
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          ID: item.ID,
          DispatchID: item.DispatchID,
          DispatchName: App.Out.getString(item.DispatchName),
          Quantity: App.Out.getInteger(item.Quantity),
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          SellPrice: App.Out.getInteger(item.SellPrice),
        });
      }

      return resultItems;
    },

    validateItem(item) {
      item.DispatchID_Valid = true;

      if (!item.DispatchIsActive) {
        item.DispatchID_Valid = false;
      }
    },

    /*** external ***/

    getInvoiceItems_ForSelection(items, invoiceItemsGrouped) {
      let resultItems = [];
      let item;

      for (const invoiceItem of invoiceItemsGrouped) {
        item = App.Array.searchItem(items, "Index", invoiceItem.Index);
        if (!item) {
          resultItems.push(invoiceItem);
        }
      }

      return resultItems;
    }
  }
}