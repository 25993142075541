import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.stock;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "warehouse", "field", "startdate", "enddate" ];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/specialpriceadj/list" + urlParams
    }
  },
  getDetails(id) {
    let options = { cid: App.Session.getCompanyID() }
    options = Object.assign(options, { id: id });
    const attributes = ["cid", "id"];
    const urlParams = App.Out.getParams(options, attributes);
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/specialpriceadj/details" + urlParams
    }
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/specialpriceadj",
      data: data
    }
  }
}