import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.InvoiceSettings,
  ModuleType: {
    // Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    // New: AppModuleType.New
  },

  InvoiceSetting: {
    Title: "Pengaturan Otomatisasi Nomor di Nota Penjualan"
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },

  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },

  createOptions(formatList, optionAllText) {
    return this.helper.createOptions(formatList, optionAllText);
  },

  populateData(data) {
    return this.helper.populateDetails(data);
  },

  previewValue(formatList, data) {
    return this.helper.previewValue(formatList, data);
  },

  helper: {
    createDetails() {
      return {
        id: App.Session.getCompanyID(),
        // user input
        type_id: null,
        format: "",
        prefix: "",
        suffix: "",
        // defined by system
        type: {},
        year: "",
        month: 0,
        counter: 0,
        previewValue: ""
      };
    },

    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.format = App.In.getString(details.type.format);
      data.prefix = App.In.getString(details.prefix);
      data.suffix = App.In.getString(details.suffix);
      data.type_id = details.type_id;
      data.type = details.type;
    },

    createOptions(items, optionAllText) {
      const rowId = "id";
      const rowLabel = "format";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
          listOptions.allActive = true;
          listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    populateDetails(data) {
      let result = {};
      result.type_id = data.type_id;

      if (data.type.format.includes('prefix')) {
        result.prefix = data.prefix;
      }
      
      if (data.type.format.includes('suffix')) {
        result.suffix = data.suffix;
      }

      return result;
    },

    previewValue(formatList, data) {
      let invoiceFormat = this.previewValue_getInvoiceFormat(formatList, data);
      if (invoiceFormat === '') {
        return '';
      }

      const formatKeys = invoiceFormat.substring(1, invoiceFormat.length - 1).split('][');
      let value = '';
      for (const key of formatKeys) {
        value += this.previewValue_getPreviewValue(key, data);
      }

      // return value;
      data.previewValue = value;
    },
    previewValue_getInvoiceFormat(formatList, data) {
      for (const item of formatList) {
        if (item.id === data.type_id) {
          return item.format;
        }
      }
      return '';
    },
    previewValue_getPreviewValue(key, data) {
      switch (key) {
        case 'prefix':
          return data.prefix;
        case 'suffix':
          return data.suffix;
        case 'year':
          return new Date().getFullYear();
        case 'month':
          return ("0" + (new Date().getMonth() + 1)).slice(-2);
        case 'counter':
          return '0001';
      }
      return '';
    }
  }
}