<template>
  <div>
    <Summary :data="data"/>

    <kst-section :title="invoiceItemsSectionTitle">
      <InvoiceProfit :items="data.Items"/>
    </kst-section>

    <kst-section :title="rtninvoiceSectionTitle">
      <RtnInvoiceProfit :items="data.ReturnInvoice"/>
    </kst-section>
  </div>
</template>

<script>
import Info from "../InvoiceInfo.js";
import InvoiceProfitCatalog from "../InvoiceProfitCatalog.js";

import InvoiceProfit from "../Profit/InvoiceProfit";
import RtnInvoiceProfit from "@/components/RtnInvoice/Profit/RtnInvoiceProfit";
import Summary from "./InvoiceDetails_Profit_Summary";

export default {
  components: {
    InvoiceProfit,
    RtnInvoiceProfit,
    Summary
  },
  props: {
    data: Object
  },
  data: () => ({
    InvoiceProfitCatalog: InvoiceProfitCatalog
  }),
  computed: {
    invoiceItemsSectionTitle() {
      return Info.Section.ProfitInvoice.Title;
    },
    rtninvoiceSectionTitle() {
      return Info.Section.ProfitRtnInvoice.Title;
    }
  }
}
</script>