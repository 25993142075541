<template>
  <div class="kst-pos-result">
    <div class="columns mb-0">
      <div class="column">
        <div class="has-text-weight-regular">
          {{ Catalog.TotalBruto.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
          <kst-value field="TotalBruto" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        <kst-input field-only field="DiscType" :data="userData" :catalog="Catalog"
          :options="discOptions" @input="handleDisc"
        />
        <template v-if="userData.DiscType === Model.DiscType.Percent.ID">
          <kst-input field-only
            field="DiscPercent" :data="userData" :catalog="Catalog"
            @input="handleDiscPercent"
          />
        </template>
        <template v-if="userData.DiscType === Model.DiscType.Value.ID">
          <kst-input field-only
            field="DiscValue" :data="userData" :catalog="Catalog"
            @input="handleDiscValue"
          />
        </template>
      </div>
      <div class="column flex-end">
        <template v-if="userData.DiscType === Model.DiscType.None.ID">
          <div class="has-text-weight-semibold has-text-right">
            0
          </div>
        </template>
        <template v-if="userData.DiscType === Model.DiscType.Percent.ID || userData.DiscType === Model.DiscType.Value.ID">
          <div class="has-text-weight-semibold has-text-right has-text-danger">
            <kst-value field="TotalDisc" :data="userData" :catalog="Catalog"/>
          </div>
        </template>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        <div class="has-text-weight-regular">
          {{ Catalog.Total.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right kst-pos-total-value">
          <kst-value class="is-30" field="Total" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column kst-pos-item">
        <div class="has-text-weight-regular kst-pos-total-item-title">
          {{ Catalog.TotalQty.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
           <kst-value field="TotalQty" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="divider mt-2 mb-2"></div>
    <div class="has-text-weight-semibold kst-pos-result-title">
      {{ Info.Payment.Title }}
    </div>
    <div class="columns mb-0">
      <div class="column flex-start">
        <div class="has-text-weight-regular">
          {{ Catalog.PaymentType.Label }}
        </div>
      </div>
      <div class="column">
        <kst-input field-only field="PaymentType" :data="userData" :catalog="Catalog"
          :options="paymentOptions"
        />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column flex-start">
        <div class="has-text-weight-regular">
          {{ Catalog.PayValue.Label }}
        </div>
      </div>
      <div class="column">
        <kst-input field-only field="PayValue" :data="userData" :catalog="Catalog" @input="handlePay"
        />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column flex-start">
        <div class="has-text-weight-regular">
          {{ Catalog.PayReturn.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
          <kst-value field="PayReturn" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column flex-start">
        <div class="has-text-weight-regular">
          {{ Catalog.Comment.Label }}
        </div>
      </div>
      <div class="column">
        <kst-input field-only field="Comment" :data="userData" :catalog="Catalog"
        />
      </div>
    </div>
    <div class="columns"> 
      <div class="column">
        <kst-button action-mode
          class="is-danger"
          tag="button"
          :label="titleBtnCancel"
          @click="handleCancel"
        />
      </div>
      <div class="column flex-end">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="titleBtnSave"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Info from "./PosInfo.js";
import Catalog from "./PosCatalog.js";
import Model from "./PosModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    searchData: Object,
    discOptions: Object,
    paymentOptions: Object
  },
  data: () => ({
    Info: Info,
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    titleBtnCancel() {
      return Info.Cancel.Title;
    },
    titleBtnSave() {
      return Info.Save.Title;
    },
  },
  methods: {
    handleDisc() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleDiscValue() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleDiscPercent() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handlePay() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleCancel() {
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleSubmit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>