import App_JavaScript from "./App_JavaScript.js";

/**
 * Convert:
 * - from: value (source: API), or nothing
 * - to: value (target: input box)
 */
export default {
  /*** date ***/

  getDate(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newDate = new Date(value);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  },
  getDateFirstDay() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  },
  getDateFirstDayLastMonth() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth() - 1, 1);
  },
  getDateLast30Day() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);
  },
  getDateLastDay() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
  },
  getDateLastDayLastMonth() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 0);
  },
  getDateLastWeek() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
  },
  getDateToday() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  },
  getDateYesterday() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  },

  getLastMonthDay() {
    let dateRange = [];

    // source: getDateLastWeek
    let today = new Date();
    //dateRange.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6));
    dateRange.push(new Date(today.getFullYear(), today.getMonth() - 1, 1));

    today.setHours(0, 0, 0, 0);
    dateRange.push(today);

    return dateRange;
  },

  getThreeMonthDay() {
    let dateRange = [];

    // source: getDateLastWeek
    let today = new Date();
    //dateRange.push(new Date(today.getFullYear(), today.getMonth() - 3), today.getDate());
    dateRange.push(new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()));

    today.setHours(0, 0, 0, 0);
    dateRange.push(today);

    return dateRange;
  },

  /*** default ***/

  getDefaultDateRange() {
    let dateRange = [];

    // source: getDateLastWeek
    let today = new Date();
    dateRange.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6));
    // dateRange.push(new Date(2020, 9, 1));

    today.setHours(0, 0, 0, 0);
    dateRange.push(today);

    return dateRange;
  },

  /*** others ***/

  getBoolean(value) {
    if (value === 1) {
      return true;
    }
    return false;
  },
  getDecimal(value) {
    if (value === undefined || value === null) {
      return "";
    }
    const newValue = App_JavaScript.parseFloat(value);
    return newValue.toString();
  },
  getInteger(value) {
    if (value === undefined || value === null) {
      return "";
    }
    const newValue = App_JavaScript.parseInt(value);
    return newValue.toString();
  },
  getSelectOptions(valueKey, labelKey, items) {
    return {
      value: valueKey,
      label: labelKey,
      rows: items
    };
  },
  getString(value) {
    if (value === undefined || value === null) {
      return "";
    }
    return value;
  }
}