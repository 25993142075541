<template>
  <kst-table view-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="ReturnNumber"
      :label="RtnInvoiceProfitCatalog.ReturnNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="RtnInvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="TotalBruto"
      :label="RtnInvoiceProfitCatalog.TotalBruto.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="RtnInvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="TotalDisc"
      :label="RtnInvoiceProfitCatalog.TotalDisc.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="RtnInvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="HppTotal"
      :label="RtnInvoiceProfitCatalog.HppTotal.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="RtnInvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="RtnInvoiceProfitCatalog.Total.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="RtnInvoiceProfitCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import RtnInvoiceProfitCatalog from "../RtnInvoiceProfitCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    RtnInvoiceProfitCatalog: RtnInvoiceProfitCatalog
  })
}
</script>