import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import SalesCatalog from "@/components/Sales/SalesCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  SONumber: {
    Label: "Nomor Penjualan",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  InvoiceDate: {
    Label: "Tanggal Penjualan",
    PrintLabel: "Tanggal Transaksi",
    Type: InputType.Date,
    ReadOnly: true
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Client.FullName,
    PrintLabel: "Nama " + AppModule.Client.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Pelanggan, atau ketik manual.",
      "Pelanggan tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    Highlight: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  Client: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 500,
    Required: true,
    Highlight: true
  },
  ClientAlias: {
    Label: "Alias",
    Type: InputType.Text,
    ReadOnly: true
  },
  ClientAddress: {
    Label: "Alamat " + AppModule.Client.FullName,
    PrintLabel: "Alamat",
    Type: InputType.Text,
    MaxLength: 200
  },
  PONumber: {
    Label: "Nomor PO",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  DiscType: {
    Label: "Pilihan Diskon",
    Type: InputType.Select
  },
  DiscValue: {
    Label: "Diskon",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  DiscPercent: {
    Label: "Diskon",
    Type: InputType.Percent,
    MinValue: 0,
    MaxValue: 100,
    Required: true
  },
  TotalBruto: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalDisc: {
    Label: "Total Diskon",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Status: {
    Label: "Status",
    Type: InputType.ID,
    ReadOnly: true
  },
  DraftNumber: {
    Label: "Nomor SO",
    Type: InputType.Text,
    ReadOnly: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 1000
  },
  IsNeedDelivery: {
    Label: "Perlu Pengiriman?",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.YesNo
  },
  DeliveryDate: {
    Label: "Tanggal Pengiriman",
    Type: InputType.Date,
    Required: true
  },
  PaymentTypeID: {
    Label: "Jenis Pembayaran",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "PaymentTypeName",
    RelatedModule: AppModule.PaymentType,
  },
  PaymentTypeName: {
    Label: "Jenis Pembayaran",
    Type: InputType.Text,
    ReadOnly: true
  },
  PaymentValue: {
    Label: "Jumlah Bayar",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  PayReturn: {
    Label: "Jumlah Kembalian",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },

  // support
  ID: {
    Label: AppCatalog.Info.ID,
    Type: InputType.Integer,
  },
  SalesDraftID: {
    Label: SalesCatalog.DraftNumber.Label,
    Type: InputType.ID,
    Input: InputType.Select,
    RelatedValue: "DraftNumber",
    RelatedModule: AppModule.Sales
  },
  DraftDate: {
    Label: SalesCatalog.Date.Label,
    Type: InputType.Date,
    ReadOnly: true
  },
  StatusName: {
    Label: "Status",
    Type: InputType.Text,
    ReadOnly: true
  },
  IsAutoNumber: {
    Label: "Nomor Penjualan",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.AutoManual
  },

  // validation
  StockMissingQty: {
    Label: "Qty Stok",
    Type: InputType.Integer,
    MaxValue: 0
  },
  DeliveryMissingQty: {
    Label: "Qty Pengiriman",
    Type: InputType.Integer,
    MaxValue: 0
  }
}