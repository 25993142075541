var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"data":_vm.items,"failure":_vm.failureList,"loading":_vm.isListLoading}},[_c('b-table-column',{attrs:{"field":"DispatchName","label":_vm.SpecialPriceAdjItemCatalog.DispatchName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SpecialPriceAdjItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price-button","field":"SellPrice","label":_vm.SpecialPriceAdjItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SpecialPriceAdjItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-price-button","field":"SpecialPriceName","label":_vm.SpecialPriceAdjItemCatalog.SpecialPriceName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SpecialPriceAdjItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price-button","field":"DiscountedValue","label":_vm.SpecialPriceAdjItemCatalog.DiscountedValue.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SpecialPriceAdjItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-qty","field":"Qty","label":_vm.SpecialPriceAdjItemCatalog.Qty.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SpecialPriceAdjItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Total","label":_vm.SpecialPriceAdjItemCatalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SpecialPriceAdjItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }