<template>
  <kst-container class="kst-client-details">
    <kst-form 
      :loading="isLoading" 
      :title="title"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :warnings="formWarnings"
      >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
        <b-tab-item :label="tabs.Details">
          <Details :data="data"/>
          <Addresses :data="data" :items="data.Address"/>
        </b-tab-item>
        <b-tab-item :label="tabs.Invoice">
          <Invoice 
            :data="data"
            :dataInvoice="dataInvoice"
            :warehouseOptions="warehouseOptions"
            :searchData="searchDataInvoice"
            :failureList="failureList"
            :isListLoading="isListLoading"
            @reset="handleInvoiceReset"
            @submit="handleInvoiceSubmit"
          />
        </b-tab-item>
      </b-tabs>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../ClientInfo.js";

import Action from "./ClientDetails_Action";
import Details from "./ClientDetails_Details";
import Addresses from "./ClientDetails_Addresses";
import Invoice from "./ClientDetails_Invoice";
import Router from "../ClientRouter.js";
import Services from "../ClientServices.js";
import Model from "../ClientModel.js";
import InvoiceModel from "../../Invoice/InvoiceModel.js";
import InvoiceSearchCatalog from "../../Invoice/List/InvoiceSearchCatalog.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details,
    Addresses,
    Invoice
  },
  data: () => ({
    data: {},
    dataInvoice: {},
    searchDataInvoice: {},
    warehouseOptions: {},
    isLoading: false,
    isListLoading: false,
    cancelUrl: Router.Path.List,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      Invoice: Info.Tabs.Invoice
    },
    // error
    formWarnings: null,
    failureInit: null,
    failureList: null,
    // support debounce
    loadCounter: 0
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }
      this.initDataInvoice();
      this.initSearchInvoice();
      this.loadDetails(id);
    },
    initDataInvoice() {
      this.dataInvoice = {
        TotalOpen: 0,
        TotalClosed: 0,
        GrandTotal: 0,
        Items: []
      }
    },
    initSearchInvoice() {
      this.searchDataInvoice = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll,
        StatusID: this.$kst.Search.OptionAll
      }
    },
    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;

      const config = Services.getClientDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.data = data;
      this.loadWarehouse();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadWarehouse() {
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = InvoiceModel.getWarehouseOptions(
        data, InvoiceSearchCatalog.WarehouseID.Label
      )
      this.isLoading = false;
      this.loadInvoice()
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadInvoice() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      const counter = this.loadCounter;
      const searchData = this.searchDataInvoice;
      this.failureList = null;
      this.isListLoading = true;
      this.initDataInvoice();
      let options = {};
      if (searchData) {
        options.client = id;
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.InvoiceSearch.Fields;
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.status = this.$kst.Search.getValue(searchData.StatusID);
        options.startInvoice = this.$kst.Out.getDate(searchData.Date[0]);
        options.endInvoice = this.$kst.Out.getDate(searchData.Date[1]);
      }
      const config = Services.getInvoiceList(options);
      this.runAxios(config, counter, this.loadInvoice_Success, this.loadInvoice_Error);
    },
    loadInvoice_Success(data, counter) {
      this.isListLoading = false;
      if (counter !== this.loadCounter) {
        return;
      }
      this.dataInvoice = Model.mapDataInvoice(data);
    },
    loadInvoice_Error(message) {
      this.isListLoading = false;
      this.failureList = message;
    },
    handleInvoiceReset() {
      this.initSearchInvoice();
      this.loadCounter++;
      this.loadInvoice();
    },
    handleInvoiceSubmit() {
      this.loadCounter++;
      this.loadInvoice();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>