import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  RecapNumber: {
    Label: "Nomor Rekap",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true,
  },
  Date: {
    Label: "Tanggal",
    PrintLabel: "Tanggal direkap",
    Type: InputType.Date,
    ReadOnly: true
  },
  InvoiceDate: {
    Label: "Tanggal Penjualan",
    Type: InputType.Date,
    Required: true,
    RequiredHide: true,
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  CashierID: {
    Label: "Kasir",
    PrintLabel: "Kasir",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "CashierName",
  },
  CashierName: {
    Label: "Kasir",
    Type: InputType.Text,
    ReadOnly: true
  },
  CreatedByID: {
    Label: "Pembuat",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RequiredHide: true,
    RelatedValue: "CreatedByName",
  },
  CreatedByName: {
    Label: "Pembuat",
    Type: InputType.Text,
    ReadOnly: true
  },
  PaymentTypeID: {
    Label: "Jenis Pembayaran",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "PaymentTypeName",
    RelatedModule: AppModule.PaymentType,
  },
  PaymentTypeName: {
    Label: "Jenis Pembayaran",
    Type: InputType.Text,
    ReadOnly: true
  },
  PaymentValue: {
    Label: "Jumlah Bayar",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  CashReceived: {
    Label: "Uang Diterima",
    Type: InputType.Integer,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  },

  // support
  IsAutoNumber: {
    Label: "Nomor Rekap",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}