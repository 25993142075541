import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari Jenis Pengeluaran",
    Type: InputType.Text,
    MaxLength: 100
  },
  CategoryID: {
    Label: "Kategori",
    Type: InputType.ID,
    Input: InputType.Select
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Select
  },
}