<template>
  <kst-section :title="sectionTitle">
    <kst-table view-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="Value"
        :label="VariantValueCatalog.Value.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="VariantValueCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import VariantValueCatalog from "../VariantValueCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    VariantValueCatalog: VariantValueCatalog,
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.VariantValue.Title;
    }
  }
}
</script>