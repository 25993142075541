<template>
  <div class="kst-settings">
    <CompanyProfile />
    <InvoiceSetting />
    <ExpenseSubCategory />
  </div>
</template>

<script>
import CompanyProfile from "@/components/Settings/Company/Profile/CompanyProfile";
import InvoiceSetting from "@/components/Settings/InvoiceSetting/Setting/InvoiceSetting";
// import ExpenseSubCategory from "@/components/Settings/InvoiceSetting/Setting/InvoiceSetting";
import ExpenseSubCategory from "@/components/Settings/ExpenseSubCategory/Setting/ExpenseSubCategorySetting";

export default {
  components: {
    CompanyProfile,
    InvoiceSetting,
    ExpenseSubCategory
  }
}
</script>