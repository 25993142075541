import DispatchSpecialPriceServices from "@/services/DispatchSpecialPriceServices.js";
import StockReductionServices from "@/services/StockReductionServices.js";

export default {
  getStockReductionList: StockReductionServices.getList,
  getStockReductionListData: StockReductionServices.getListData,
  getStockReductionNewData: StockReductionServices.getNewData,
  getStockReductionDetailsData: StockReductionServices.getDetailsData,
  postStockReduction: StockReductionServices.postStockReduction,
  // dispatch special price
  getDispatchSpecialPriceList: DispatchSpecialPriceServices.getList
}