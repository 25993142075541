import ClientServices from "@/services/ClientServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import InvoiceServices from "@/services/InvoiceServices.js";

export default {
  getClientList: ClientServices.getList,
  getClientDetails: ClientServices.getDetails,
  postClient: ClientServices.postClient,
  putClient: ClientServices.putClient,
  // client - address
  getClientAddressList: ClientServices.getAddressList,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // Invoice
  getInvoiceList: InvoiceServices.getList
}