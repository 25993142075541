<template>
  <div>
    <ClientSearch ref="mdlClientSearch" @submit="handleClient_Submit"/>

    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="SONumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>
    <kst-input field="InvoiceDate" :data="userData" :catalog="Catalog"/>

    <kst-output control-off status-off
      field="ClientID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="Client"
          :disabled="clientDisabled"
          :data="userData"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <template v-if="userData.SalesDraftID === null">
          <div class="control">
            <template v-if="userData.ClientID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button search-mode @click="handleSearch_Client"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button clear-mode @click="handleClear_Client"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="ClientAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :disabled="userItems.length > 0" :options="warehouseOptions"
      @input="handleInputWarehouse"
    />
    <kst-input field="PONumber" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";

import ClientSearch from "@/components/Client/Search/ClientSearch";

export default {
  components: {
    ClientSearch
  },
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    clientDisabled() {
      if (this.userData.ClientID !== null) {
        return true;
      }

      if (this.userData.SalesDraftID !== null) {
        return true;
      }

      return false;
    },
    detailsWarning() {
      return this.userData.SalesDraftID ?
        [
          Info.Message.New_SONumber,
          Info.Message.New_InvoiceDate,
          Info.Message.New_Client,
          Info.Message.New_Warehouse
        ] :
        [
          Info.Message.New_SONumber,
          Info.Message.New_InvoiceDate,
          Info.Message.New_Warehouse
        ]
      ;
    },
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  },
  methods: {
    handleSearch_Client() {
      this.$refs.mdlClientSearch.show();
    },
    handleClear_Client() {
      Model.clearDetailsByClient(this.userData);
    },
    handleClient_Submit(row) {
      Model.setDetailsByClient(this.userData, row);
    },
    handleInputWarehouse() {
      this.$emit(this.$kst.Enum.Event.Callback);
    }
  }
}
</script>