import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import DeliveryDetails from "./Details/DeliveryDetails";
import DeliveryEditStatus from "./EditStatus/DeliveryEditStatus";
import DeliveryList from "./List/DeliveryList";
import DeliveryNew from "./New/DeliveryNew";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Delivery, AppModuleType.Details),
    Status: RouterModel.getPath(AppModule.Delivery, AppModuleType.Status),
    List: RouterModel.getPath(AppModule.Delivery, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Delivery, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Delivery, AppModuleType.Print)
  },

  StockOpname: {
    Path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.New)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.Details),
        component: DeliveryDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Delivery)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.Status),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.Status),
        component: DeliveryEditStatus,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.Status),
          moduleName: RouterModel.getModule(AppModule.Delivery)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.List),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.List),
        component: DeliveryList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Delivery)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.New),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.New),
        component: DeliveryNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Delivery)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}