<template>
  <kst-section :title="scannerTitle" >
    <div class="columns">
      <div class="column is-left">
        <div class="columns">
          <div class="column is-3">
            <kst-input floating-label
              field="Barcode" ref="txtBarcode" :data="searchData" :catalog="ItemCatalog"
              @submit="handleEnterBarcode"
            />
          </div>
          <div class="column is-2">
            <kst-input floating-label
              field="Qty" ref="txtQty" :data="searchData" :catalog="ItemCatalog"
              @submit="handleEnterQty"
            />
          </div>
        </div>
      </div>
    </div>
  </kst-section>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import ItemCatalog from "../ReceiveItemCatalog.js";
import Model from "../ReceiveModel.js";
import Info from "../ReceiveInfo.js";

export default {
  mixins: [SearchMixin, SnackbarMixin],
  props: {
    itemScanner: Array,
    userData: Object,
    searchData: Object,
    userItems: Array
  },
  data: () => ({
    ItemCatalog: ItemCatalog,
    selectedItem: null
  }),
  mounted() {
    window.addEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  computed: {
    scannerTitle() {
      return Info.Scanner.Title;
    }
  },
  methods: {
    handleKeyDown(event) {
      switch(event[this.$kst.Key.KeyProp]) {
        case this.$kst.Key.Key.F1:
          this.setFocusBarcode();
          event.preventDefault();
          break;
        case this.$kst.Key.Key.F2:
          this.setFocusQuantity();
          event.preventDefault();
          break;
      }
    },
    setFocusQuantity() {
      this.$refs.txtQty.focus();
    },
    setFocusBarcode() {
      this.$refs.txtBarcode.focus();
    },
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    clearInput() {
      this.selectedItem= null;
      this.searchData.Qty = 1;
      this.searchData.Barcode = "";
    },
    showError(message) {
      this.notifyFormError('', message);
    },
    handleSnackbarClose() {
      this.setFocusBarcode();
    },
    validate() {
      const barcode = this.searchData.Barcode;

      if (barcode !== null) {
        const item = this.$kst.Array.searchItem(
          this.itemScanner, 'Barcode', barcode, { insensitive: true }
        );
        
        if (!item) {
          return Model.InvalidBarcode.Title;
        }
      }
      else {
        return Model.InvalidBarcode.Title;
      }
    },
    getItem() {
       const barcode = this.searchData.Barcode;

      if (barcode !== null) {
        const item = this.$kst.Array.searchItem(
          this.itemScanner, 'Barcode', barcode, { insensitive: true }
        );

        return item;
      }

      return null;
    },
    handleEnterBarcode() {
      this.selectedItem = this.getItem();
      const error = Model.InvalidBarcode.Title;

      if (!this.selectedItem) {
        this.showError(error);
        return;
      }

      this.setFocusQuantity();
    },
    handleEnterQty() {
      const error = Model.InvalidQty.Title;

      if (this.searchData.Qty < 1) {
        this.showError(error);
        return;
      }

      if (!this.selectedItem) {
        this.setFocusBarcode();
        return;
      }

      this.$emit(this.$kst.Enum.Event.Submit, this.selectedItem, this.searchData.Qty);
      this.clearInput();
      this.setFocusBarcode();
    },
  }
}
</script>