import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.expense;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    if (!Object.prototype.hasOwnProperty.call(options, "active")) {
      options.active = 1;
    }
    const attributes = ["cid", "category", "subcategory", "active", "startDate", "endDate"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/expense/list" + urlParams
    };
  },
  getDetails(id) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/expense/" + cid + "/" + id
    };
  },
  postExpense(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/expense",
      data: data
    };
  },
  putExpense(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/expense",
      data: data
    };
  }
}