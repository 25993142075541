<template>
  <kst-container class="kst-receive-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <Invoice
          :userData="userData"
          :warehouseList="warehouseList"
          @submit="handleInvoice_Submit"
          @reset="handleInvoice_Reset"
        />

        <template v-if="userData.SalesOrderID">
          <Details :userData="userData" :warehouseOptions="warehouseOptions"/>
          <Items
            :invoiceItemsGrouped="invoiceItemsGrouped"
            :userData="userData"
            :userItems="userItems"
          />
          <Total :userData="userData" />
          <Details2
            class="mt-5"
            :userData="userData"
            :userItems="userItems"
            :discTypeOptions="discTypeOptions"
          />
        </template>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../RtnInvoiceModel.js";
import Router from "../RtnInvoiceRouter.js";
import Services from "../RtnInvoiceServices.js";

import Details from "./RtnInvoiceNew_Details";
import Details2 from "./RtnInvoiceNew_Details2";
import Invoice from "./RtnInvoiceNew_Invoice";
import Items from "./RtnInvoiceNew_Items";
import Total from "./RtnInvoiceNew_Total";

export default {
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  components: {
    Details,
    Details2,
    Invoice,
    Items,
    Total
  },
  data: () => ({
    isLoading: false,
    userData: {},
    userItems: [],
    invoiceId: null,
    invoiceItemsGrouped: {},
    warehouseOptions: {},
    warehouseList: [],
    discTypeOptions: [],
    cancelUrl: Router.Path.List,
    loadingComponent: null,
    // error
    errors: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.invoiceId = this.getUrlParam(this.$kst.Enum.Param.InvoiceID);

      this.discTypeOptions = Model.createDiscTypeOptions();

      this.initReset();
      this.initLoad();
    },
    initReset() {
      this.userData = Model.createDetails();
      this.userItems = [];
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
    },
    initLoad_End() {
      this.invoiceId = null;
      this.isLoading = false;
    },

    loadWarehouse() {
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.warehouseList = data;

      if (this.invoiceId) {
        this.loadInvoiceDetails(this.invoiceId);
      }
      else {
        this.initLoad_End();
      }
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadInvoiceDetails(id) {
      this.failureSales = null;
      if (!this.invoiceId) {
        this.loadingComponent = this.openLoading();
      }

      const config = Services.getInvoiceDetails(id, { withstock: 1 });
      this.runAxios(config, null, this.loadInvoiceDetails_Success, this.loadInvoiceDetails_Error);
    },
    loadInvoiceDetails_End() {
      if (this.invoiceId) {
        this.initLoad_End();
      }
      else {
        this.closeLoading(this.loadingComponent);
      }
    },
    loadInvoiceDetails_Success(data) {
      this.updateByInvoice(data);
      this.loadInvoiceDetails_End();
    },
    loadInvoiceDetails_Error(message) {
      this.failureSales = message;
      this.loadInvoiceDetails_End();
    },

    handleInvoice_Reset() {
      this.initReset();
    },
    handleInvoice_Submit(row) {
      this.initReset();
      this.loadInvoiceDetails(row.ID);
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postRtnInvoice(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },

    updateByInvoice(data) {
      this.invoiceItemsGrouped = data.ItemsWithReturn;

      for (let invoiceItem of this.invoiceItemsGrouped) {
        Model.updateInvoiceItem(invoiceItem);
      }

      // userItems: user need to input items manually
      Model.setDetailsByInvoice(this.userData, data);
    }
  }
}
</script>