<template>
  <span />
</template>

<script>
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../PurchaseCatalog.js";
import Info from "../PurchaseInfo.js";

export default {
  mixins: [XlsxMixin],
  props: {
    items: Array
  },
  methods: {
    exportExcel() {
      this.createBook();
      this.createSheet();

      this.addList();
      this.download(Info.Export.List.Excel.FileName);
    },

    addList() {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAlias);
      this.addColumnHeader(Catalog.Vendor);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.StatusName);

      // value
      for (const row of this.items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue("WarehouseName", row, Catalog);
        this.addCellValue("VendorID", row, Catalog);
        this.addCellValue("VendorAlias", row, Catalog);
        this.addCellValue("Vendor", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellValue("StatusName", row, Catalog);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  },
}
</script>