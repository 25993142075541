<template>
  <span />
</template>

<script>
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    items: Array
  },
  methods: {
    exportExcel() {
      this.createBook();
      this.createSheet();

      this.addList();
      this.download(Info.Export.List.Excel.FileName);
    },

    addList() {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.InvoiceDate);
      this.addColumnHeader(Catalog.SONumber);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.Client);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Status);

      // value
      for (const row of this.items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("InvoiceDate", row, Catalog);
        this.addCellValue("SONumber", row, Catalog);
        this.addCellValue("WarehouseName", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellValue("Client", row, Catalog);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellStatus(row.Status, Model.Status);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>