import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import StockList from "./List/StockList";
import StockPrintList from "./PrintList/StockPrintList";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.Stock, AppModuleType.List),
    Print: RouterModel.getPath(AppModule.Stock, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Stock, AppModuleType.List),
        name: RouterModel.getName(AppModule.Stock, AppModuleType.List),
        component: StockList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Stock, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Stock)
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Stock, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Stock, AppModuleType.Print),
        component: StockPrintList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Stock, AppModuleType.Print),
          moduleName: RouterModel.getModule(AppModule.Stock)
        }
      }
    ];
  }
}