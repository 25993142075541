<template>
  <kst-container class="kst-HppAdj-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details
          :userData="userData"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
        />
        <Items :errorsAddItem="errorsAddItem" :userData="userData" :userItems="userItems"/>
        <template v-if="errorsAddItem !== null">
          <kst-warning :items="errorsAddItem" :closable="true" />
        </template>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import ItemRouter from "@/components/Item/ItemRouter.js";
import Model from "../HppAdjModel.js";
import Router from "../HppAdjRouter.js";

import Details from "./HppAdjNew_Details";
import Items from "./HppAdjNew_Items";

import Services from "../HppAdjServices.js";

export default {
  components: {
    Details,
    Items
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    userItems: [],
    isLoading: false,
    warehouseOptions: {},
    cancelUrl: ItemRouter.Path.List,
    saveLoading: null,
    // error
    errors: null,
    errorsAddItem: [],
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userData = Model.createDetails();
      this.userItems = [];

      this.loadWarehouse();
    },

    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let data = Model.populateData(this.userData, this.userItems);
          this.saveData(data);
        }
        else {
          this.notifyFormError();
        }
      });
    },
    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postHppAdj(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },
  }
}
</script>