<template>
  <div class="columns">
    <div class="column is-half">
      <kst-section class="is-half" :title="InvoiceProfitCatalog.TotalProfit.Label">
        <template #top-right>
          <kst-title>{{ totalProfit }}</kst-title>
        </template>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvoiceProfitCatalog.TotalBruto.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalBruto" :data="data" :catalog="InvoiceProfitCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvoiceProfitCatalog.TotalDisc.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalDisc" :data="data" :catalog="InvoiceProfitCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvoiceProfitCatalog.TotalReturn.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalReturn" :data="data" :catalog="InvoiceProfitCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvoiceProfitCatalog.TotalHpp.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalHpp" :data="data" :catalog="InvoiceProfitCatalog"/>
            </div>
          </div>
        </div>
      </kst-section>
    </div>
    <div class="column is-half is-hidden-mobile"/>
  </div>
</template>

<script>
import InvoiceProfitCatalog from "../InvoiceProfitCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    InvoiceProfitCatalog: InvoiceProfitCatalog
  }),
  computed: {
    totalProfit() {
      return this.$kst.Value.getValue("TotalProfit", this.data, InvoiceProfitCatalog);
    }
  }
}
</script>