<template>
  <kst-container class="kst-item-export-list">
    <kst-form
      :failure="failureList"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :title="title"
      :warnings="formWarnings"
    >
      <ExportItem
        ref="refExportItem"
        :access="access"
        :searchData="searchData"
        @error="handleError"
      />
      <ExportDispatch
        ref="refExportDispatch"
        :access="access"
        :searchData="searchData"
        @error="handleError"
      />

      <kst-section export-mode>
        <kst-check disabled v-model="searchData.WithItem" :label="labelOptItem"/>
        <kst-check v-model="searchData.WithDispatch" :label="labelOptDispatch"/>
      </kst-section>

      <template #bottom-left>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          @click="handleExport"
        />
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import Services from "../ItemServices.js";

import ExportDispatch from "./ItemExportList_Dispatch";
import ExportItem from "./ItemExportList_Item";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    ExportDispatch,
    ExportItem
  },
  data: () => ({
    Model: Model,
    searchData: {
      WithDispatch: true,
      WithItem: true,
      Search: ""
    },
    access: {},
    items: [],
    isListLoading: true,
    // error
    failureInit: null,
    failureList: null,
    formWarnings: null
  }),
  computed: {
    labelOptDispatch() {
      return Info.Export.List.Excel.Option.Dispatch;
    },
    labelOptItem() {
      return Info.Export.List.Excel.Option.Item;
    },
    title() {
      return Info.Export.List.Excel.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.formWarnings = null;
      this.access = Model.createAccess();
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      this.searchData.Search = this.getUrlParam(this.$kst.Enum.Param.Search);
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadListData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadListData() {
      const config = Services.getItemListData();
      this.runAxios(config, null,
        this.loadListData_Success,
        this.loadListData_Error,
        this.loadListData_Invalid
      );
    },
    loadListData_Success(data) {
      this.access = data.Access;
      this.initLoad_End();
    },
    loadListData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadListData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    handleError(message) {
      this.failureList = message;
    },
    handleExport() {
      this.failureList = null;

      if (this.searchData.WithDispatch) {
        this.$refs.refExportDispatch.exportExcel();
      }
      else {
        this.$refs.refExportItem.exportExcel();
      }
    }
  }
}
</script>