// vee-validate support functions

const SEPARATOR_FIELD = "_";

export default {
  ValidRule: "#valid",

  /**
   * Generate field target name
   * i.e. Name (field) is Unique (rule)
   * will return filed targe = Name_Unique
   * @param {*} field 
   * @param {*} rule 
   **/
  getFieldTarget(field, rule) {
    return field + SEPARATOR_FIELD + rule;
  },

  getRowErrors(item, field, errors) {
    // populate error in current field
    item.ErrorsColl[field] = [];

    for (const error of errors) {
      if (error !== this.ValidRule) {
        item.ErrorsColl[field].push(error);
      }
    }

    // populate error in all fields
    let fieldErrors, newErrors = [];
    for (const fieldName in item.ErrorsColl) {
      fieldErrors = item.ErrorsColl[fieldName];

      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }

    return newErrors;
  },

  /**
   * Generate vid for ValidationProvider-tag
   * @param {String} field - field name (required)
   * @param {Number} index - index of field, useful for collection (optional)
   * @param {String} group - group of field, useful for multiple collections (optional)
   */
  getValidatorId(field, index, group) {
    let vid = field;

    if (index !== undefined) {
      vid += "-" + index;
    }

    if (group !== undefined) {
      vid += "-" + this.group;
    }

    return vid;
  }
}