var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.userPackagings},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.ItemPackaging.Module.FullName}},[(!row.IsDefault)?[(row.UnitID === null)?[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})]:_vm._e()]:_vm._e()],2)]}}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"ItemUnitName","label":_vm.PackagingCatalog.ItemUnitName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.PackagingCatalog,"disabled":row.UnitID !== null,"index":index},on:{"input":_vm.handleName,"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"field":"ItemUnitAmount","label":_vm.PackagingCatalog.ItemUnitAmount.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.PackagingCatalog,"disabled":row.IsDefault || (row.UnitID !== null),"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-item-active","field":"IsActive","label":_vm.PackagingCatalog.IsActive.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.PackagingCatalog,"index":index},on:{"input":_vm.handleIsActive}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }