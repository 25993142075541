import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Active: true
  },
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive",
    Valid: true
  },
  DispatchName: {
    Label: "Nama Barang",
    Type: InputType.Text,
    MaxLength: 255,
    Required: true,
    ReadOnly: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Select,
    Required: true
  },
  PackagingValue: {
    Label: "Kemasan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Qty: {
    Label: "Qty",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    Required: true,
    Valid: true
  },
  AvailableQty: {
    Label: "Stok",
    Type: InputType.Integer,
    ReadOnly: true
  },
  TotalQty: {
    Label: "Qty",
    Type: InputType.Integer,
    ReadOnly: true
  },
  SpecialPriceQuota: {
    Label: "Kuota Harga Spesial",
    Type: InputType.Integer,
    ReadOnly: true
  },
  SellPrice: {
    Label: "Harga Normal",
    Type: InputType.Integer,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Sub Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  SpecialPriceID: {
    Label: "Diskon",
    Type: InputType.Select,
    MaxLength: 10,
    Required: true,
    Valid: true
  },
  SpecialPriceName: {
    Label: "Diskon",
    Type: InputType.Text,
    MaxLength: 50
  },
  SpecialPriceDiscPercent: {
    Label: "Diskon",
    Type: InputType.Integer
  },
  DiscountedValue: {
    Label: "Harga Spesial",
    Type: InputType.Decimal,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    ReadOnly: true,
    Output: OutputType.Decimal.Currency
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  Barcode: {
    Label: "Barcode [F1]",
    Type: InputType.Text
  },
  QtyScan: {
    Label: "Qty [F2]",
    Type: InputType.Qty,
    MinValue: 1
  },

  // support
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  }
}