<template>
  <kst-section :title="sectionTitle">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Model.ItemPackaging.Module.FullName"
        :moduleType="Model.ItemPackaging.ModuleType.New"
        @click="handleAdd_Packaging"
      />
    </template>

    <kst-warning class="mb-4" :items="packagingWarning"/>

    <List :userData="userData" :userPackagings="userPackagings"/>

    <kst-errors class="mt-4" :items="userData.PackagingsErrors"/>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

import List from "./ItemNew_Packagings_List";

export default {
  components: {
    List
  },
  props: {
    userData: Object,
    userPackagings: Array
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    packagingWarning() {
      return [
        Info.Message.New_DefaultPackaging,
        Info.Message.New_DeleteDefaultPackaging
      ]
    },
    sectionTitle() {
      return Info.Section.Packaging.Title;
    }
  },
  methods: {
    handleAdd_Packaging() {
      const packaging = Model.createPackaging();
      this.userPackagings.push(packaging);
    }
  }
}
</script>