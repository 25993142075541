var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"loading":_vm.isListLoading,"failure":_vm.failureList},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [(row.TransactionID)?_c('kst-tooltip',{attrs:{"search-mode":"","hover-only":"","content":_vm.Model.Report.TitleSearch}},[_c('kst-button',{attrs:{"search-mode":"","is-round":""},on:{"click":function($event){return _vm.handleSearchItem(row.TransactionID, row.ActivityTypeID)}}})],1):_vm._e()]}}])},[_c('b-table-column',{attrs:{"centered":"","sortable":"","cell-class":"ks-col-shortdate","field":"Date","label":_vm.Catalog.Date.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"DispatchName","label":_vm.Catalog.DispatchName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"WarehouseName","label":_vm.Catalog.WarehouseName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Changes","label":_vm.Catalog.Changes.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.Changes)+" "+_vm._s(row.DispatchUnitName)+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"QuantityAfter","label":_vm.Catalog.QuantityAfter.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.QuantityAfter)+" "+_vm._s(row.DispatchUnitName)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }