import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import ItemCatalog from "./PurchaseItemCatalog.js"
import Model from "./PurchaseModel.js";
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          ReceiveList: AppModuleType.List.Name + " " + AppModule.Receive.FullName
        },
        Section: {
          ReceiveItem: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          ReceiveList: AppModuleType.List.Name + " " + AppModule.Receive.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name
        }
      }
    }
  },
  Message: {
    Edit_ConfirmDeleteItem:
      "Yakin menghapus " + Model.PurchaseItem.Module.FullName + "?",
    Edit_EditVendor:
      "Tidak diperkenankan mengubah " + AppModule.Vendor.FullName + ", " +
      "saat sudah memiliki " + AppModule.Receive.FullName + ".",
    Edit_EditPackaging:
      "Tidak diperkenankan mengubah " + ItemCatalog.Packaging.Label + " " +
      "untuk barang terhapus atau sudah terpakai di " + AppModule.Receive.FullName + ".",
    Edit_DeleteItem:
      "Tidak diperkenankan menghapus " + AppModule.Item.FullName + ", " +
      "bila sudah terpakai di " + AppModule.Receive.FullName + ".",
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.PurchaseItem.Module.FullName + "?",
  },
  Section: {
    Delivery: {
      Title: "Info Pengiriman"
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name,
    ReceiveList: AppModuleType.List.Name + " " + AppModule.Receive.FullName
  },
  Button: {
    SkipPo: "Tanpa PO"
  }
}