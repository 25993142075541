<template>
  <div>
    <kst-output field="Name" :data="data" :catalog="Catalog"/>
    <kst-output field="PIC" :data="data" :catalog="Catalog"/>
    <kst-output field="Address" :data="data" :catalog="Catalog"/>
    <kst-output field="NoPIC" :data="data" :catalog="Catalog"/>
    <kst-output field="IsActive" :data="data" :catalog="Catalog"/>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../WarehouseCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>