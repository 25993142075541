import ExpenseSubServices from "@/services/ExpenseSubServices.js";
import ReportServices from "@/services/ReportServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import CompanyServices from "@/services/CompanyServices.js";
import PaymentTypeServices from "@/services/PaymentTypeServices.js";

export default {
  getSubCatList: ExpenseSubServices.getList,
  getTurnoverSales: ReportServices.getTurnoverSales,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // company
  getCompanyDetails: CompanyServices.getDetails,
  // paymenttype
  getPaymentTypeList: PaymentTypeServices.getList,
}