import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  Module: AppModule.BestSellingItems,
  ModuleType: {
    Export: AppModuleType.Export,
  },

  Dashboard: {
    Limit: 5
  },

  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },

  helper: {
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    }
  }
}