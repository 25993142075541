import InputType from "@/services/Input/InputType.js";
import Catalog from "../../SpecialPrice/SpecialPriceCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " + Catalog.Name.Label,
    Type: InputType.Text,
    MaxLength: 100
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Select
  },
}