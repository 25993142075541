import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Value: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 45,
    Required: true
  }
}