<template>
  <span/>
</template>

<script>
  import AxiosMixin from "@/mixins/AxiosMixin.js";
  import LoadingMixin from "@/mixins/LoadingMixin.js";
  import XlsxMixin from "@/mixins/XlsxMixin.js";
  import Catalog from "../ReceiveCatalog.js";
  import ItemCatalog from "../ReceiveItemCatalog.js";
  import Info from "../ReceiveInfo.js";
  import Services from "../ReceiveServices.js";

  export default {
    mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getReceiveDetails(id, {withpackagings: 1});
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);

      if (this.searchData.WithItem) {
        this.addDetails_Item(data.Items);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      this.addRow();
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAlias);
      this.addColumnHeader(Catalog.VendorName);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader(Catalog.PurchasingNumber);
      this.addColumnHeader(Catalog.PurchasingDate);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.ReceiveNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.Comment); 
      this.addColumnHeader(Catalog.Total);

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("VendorID", data, Catalog);
      this.addCellValue("VendorAlias", data, Catalog);
      this.addCellValue("VendorName", data, Catalog);
      this.addCellValue("VendorAddress", data, Catalog);
      this.addCellValue("PurchasingNumber", data, Catalog);
      this.addCellValue("PurchasingDate", data, Catalog);
      this.addCellValue("WarehouseName", data, Catalog);
      this.addCellValue("ReceiveNumber", data, Catalog);
      this.addCellValue("Date", data, Catalog);
      this.addCellValue("Comment", data, Catalog);
      this.addCellValue("Total", data, Catalog);
    },
    addDetails_Item(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ReceiveItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(ItemCatalog.Name);
      this.addColumnHeader(ItemCatalog.PurchasingItemQty);
      this.addColumnHeader(ItemCatalog.SKU);
      this.addColumnHeader(ItemCatalog.PackagingName);
      this.addColumnHeader(ItemCatalog.PackagingValue);
      this.addColumnHeader(ItemCatalog.Quantity);
      this.addColumnHeader(ItemCatalog.BuyPrice);
      this.addColumnHeader(ItemCatalog.ExpenseAmount);

      // value
      for ( const row of items ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("Name", row, ItemCatalog);
      this.addCellValue("PurchasingItemQty", row, ItemCatalog);
      this.addCellValue("SKU", row, ItemCatalog);
      this.addCellValue("PackagingName", row, ItemCatalog);
      this.addCellValue("PackagingValue", row, ItemCatalog);
      this.addCellValue("Quantity", row, ItemCatalog);
      this.addCellValue("BuyPrice", row, ItemCatalog);
      this.addCellValue("ExpenseAmount", row, ItemCatalog);
      }
    }
  }
}
</script>