/**
 * target: app routes/router
 * > for API, see "./App_Output.js"
 * > for keys in params, see Param in "./App_Enum.js"
 */
export default {
  getParams(params, withoutQueryMark) {
    let urlParams = "";
    for (const key in params) {
      if (urlParams !== "") {
        urlParams += "&";
      }
      urlParams += key + "=" + params[key];
    }

    return withoutQueryMark === true ? urlParams : "?" + urlParams;
  }
}