import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    MaxLength: 45
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0
  },
  BuyPrice: {
    Label: "Harga Beli",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0
  },
  Weight: {
    Label: "Berat (gram)",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0
  },
  Barcode: {
    Label: "Barcode",
    Type: InputType.Text,
    MaxLength: 50
  },
  Vendor: {
    Label: "Vendor",
    Type: InputType.Text,
    MaxLength: 50
  },
  Description: {
    Label: "Keterangan",
    Type: InputType.Text,
    MaxLength: 1000
  },
  HppPerPcs: {
    Label: "Hpp Per Pcs",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 0
  }
}