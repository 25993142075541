import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari Nama, atau SKU",
    Type: InputType.Text,
    MaxLength: 50
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select
  },
  Stock: {
    Label: "Stok",
    Type: InputType.Select
  }
}