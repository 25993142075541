<template>
  <div>
    <kst-input field="type_id" :options="formatOption" :data="userData" :catalog="Catalog" @input="handleInput_Type"/>
    <template v-if="isFieldVisible('prefix')">
      <kst-input field="prefix" :data="userData" :catalog="Catalog"/>
    </template>
    <template v-if="isFieldVisible('suffix')">
      <kst-input field="suffix" :data="userData" :catalog="Catalog"/>
    </template>
    <kst-output field="autoBySystem" :label="Catalog.year.Label" :data="Info.value" :catalog="Catalog"/>
    <kst-output field="autoBySystem" :label="Catalog.month.Label" :data="Info.value" :catalog="Catalog"/>
    <kst-output field="autoBySystem" :label="Catalog.counter.Label" :data="Info.value" :catalog="Catalog"/>
    <kst-output field="previewValue" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvoiceSettingCatalog.js";
import Model from "../InvoiceSettingModel.js";
import Info from "../InvoiceSettingInfo.js";

export default {
  props: {
    userData: Object,
    formatOption: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  }),
  methods: {
    handleInput_Type() {
      this.userData.type.format = '';
      const item = this.$kst.Array.searchItem(
        this.formatOption.rows, "id", this.userData.type_id
      )
      this.userData.type.id = item.id;
      this.userData.type.format = item.format;

      Model.previewValue(this.formatOption.rows, this.userData);
    },
    isFieldVisible(fieldName) {
      return (this.userData.type.format.includes(fieldName) ? true : false);
    }
  }
}
</script>