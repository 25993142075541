<template>
  <div>
    <template v-if="hasDisc">
      <div class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column pb-0 is-narrow">
          <div class="title is-5">
            {{ Catalog.TotalBruto.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right">
            <kst-value class="title is-5" field="TotalBruto" :data="data" :catalog="Catalog"/>
          </div>
        </div>
      </div>

      <div v-if="data.DiscValue !== null" class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5">
            {{ Catalog.DiscValue.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5" field="DiscValue" :data="data" :catalog="Catalog"/>
          </div>
        </div>
      </div>

      <div v-if="data.DiscPercent !== null" class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5 is-flex">
            {{ Catalog.DiscPercent.Label }} (
              <kst-value class="title is-5" field="DiscPercent" :data="data" :catalog="Catalog"/>
            )
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5" field="TotalDisc" :data="data" :catalog="Catalog"/>
          </div>
        </div>
      </div>
    </template>

    <div class="columns is-mobile">
      <div class="column is-8 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.Total.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5" field="Total" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../SalesCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    hasDisc() {
      return (this.data.DiscValue === null && this.data.DiscPercent === null) ? false : true;
    }
  }
}
</script>