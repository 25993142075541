<template>
  <div>
    <kst-output field="InvoiceDate" :data="data" :catalog="Catalog"/>

    <template v-if="data.ClientID">
      <kst-output control-off field="ClientID" :data="data" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="ClientAlias" :data="data" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="ClientName" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output control-off field="ClientID" :data="data" :catalog="Catalog">
        <kst-output addons expanded floating-label field="ClientName" :data="data" :catalog="Catalog"/>
      </kst-output>
    </template>

    <kst-output field="ClientAddress" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../RtnInvoiceCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>