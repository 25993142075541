import InputType from "@/services/Input/InputType.js";
// import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  name: {
    Label: "Nama Perusahaan",
    Type: InputType.Text,
    MaxLength: 80,
    Required: true
  },
  address: {
    Label: "Alamat",
    Type: InputType.Text,
    MaxLength: 150,
  },
  phone: {
    Label: "Nomor Telepon",
    Type: InputType.Phone,
    MaxLength: 15
  },
  email: {
    Label: "Email",
    Type: InputType.Email,
    MaxLength: 50
  },
  owner: {
    Label: "Nama Pemilik",
    Type: InputType.Text,
    MaxLength: 100,
  }
}
  // name: {
  //   required,
  //   maxLength: maxLength(80)
  // },
  // address: {
  //   required,
  //   maxLength: maxLength(100)
  // },
  // phone: {
  //   required,
  //   maxLength: maxLength(100)
  // },
  // email: {
  //   required,
  //   email,
  //   maxLength: maxLength(100)
  // },
  // owner: {
  //   required,
  //   maxLength: maxLength(100)
  // }