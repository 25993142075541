<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="IsActive"
      :label="Catalog.IsActive.Label"
    >
      <kst-status :value="row.IsActive" :statusEnum="Model.Status"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :row="row"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../PaymentTypeCatalog.js";
import Model from "../PaymentTypeModel.js";

import Action from "./PaymentTypeList_Action";

export default {
  components: {
    Action
  },
  props: {
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>