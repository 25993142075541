import AppCatalog from "@/services/App/AppCatalog.js";
import Catalog from "./StockReductionCatalog.js";
import Model from "./StockReductionModel.js";
import StockReductionItemCatalog from "./StockReductionItemCatalog.js";

export default {
  Button: {
    SpecialPrice: {
      Label: Model.SpecialPrice.Module.FullName
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.StockReductionItem.Module.FullName + "?",
    New_ConfirmDeleteItems:
      "Yakin menghapus semua " + Model.StockReductionItem.Module.FullName + "?",
    New_Date:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ".",
    New_DuplicatedDispatchSpecialPrice:
      Model.StockReductionItem.Module.FullName + " dan " +
      Model.SpecialPrice.Module.FullName + " telah dipergunakan.",
    New_DuplicatedItem:
      Model.StockReductionItem.Module.FullName + " telah dipergunakan.",
    New_InsertedItems: // i.e. 10 Barang berhasil ditambahkan.
      " " + Model.StockReductionItem.Module.FullName + " berhasil ditambahkan.",
    New_Item:
      "Tidak diperkenankan mengubah " + StockReductionItemCatalog.DispatchName.Label + " " +
      "dan " + StockReductionItemCatalog.PackagingName.Label + ".",
    New_Items:
      "Harap mengisi " + Catalog.WarehouseID.Label + ", " +
      "sebelum memilih " + Model.StockReductionItem.Module.FullName + ".",
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.StockReductionItem.Module.FullName + ".",
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    HppDetails: "Perhitungan " + AppCatalog.Info.HPP
  }
}