import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  PeriodId: {
    Label: "Periode",
    Type: InputType.Select,
  }
}