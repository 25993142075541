
<template>
  <kst-table action detailed paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="kst-item-list-Name"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="SellPrice"
      :label="Catalog.SellPrice.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Field.HppPerPcs !== false">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        field="HppPerPcs"
        :label="Catalog.HppPerPcs.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-stock"
      field="TotalQuantity"
      :label="Catalog.TotalQuantity.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Field.HppTotal !== false">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        field="HppTotal"
        :label="Catalog.HppTotal.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template #action="{ row }">
      <Action :data="row"/>
    </template>

    <template #detail="{ row }">
      <DispatchRelated :access="access" :itemId="row.ID"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../ItemCatalog.js";

import Action from "./ItemList_List_Action";
import DispatchRelated from "@/components/Dispatch/Related/DispatchRelated";

export default {
  components: {
    Action,
    DispatchRelated
  },
  props: {
    access: Object,
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>