<template>
  <div class="field has-addons">
    <div class="control">
      <kst-button action-mode
        class="is-primary"
        target=""
        :module="Model.Module"
        :moduleType="Model.ModuleType.Edit"
        :urlParams="urlParams"
      />
    </div>
    <div class="control">
      <kst-action normal-mode>
        <kst-action-item>
          <kst-button action-mode
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
            :urlParams="urlParams"
          />
        </kst-action-item>
        <kst-action-item>
          <kst-button action-mode
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportDetails"
            :urlParams="urlParams"
          />
        </kst-action-item>
      </kst-action>
    </div>
  </div>
</template>

<script>
import Model from "../ReceiveModel.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: this.data.ID
      });
    }
  }
}
</script>