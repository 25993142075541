<template>
  <kst-container class="kst-Receive-export-details">
    <kst-form
      :failure="failureList"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :title="title"
      :warnings="formWarnings"
    >
      <Export
        ref="refExport"
        :access="access"
        :searchData="searchData"
        @error="handleError"
      />

      <kst-section export-mode>
        <kst-check disabled v-model="searchData.WithDetails" :label="labelOptDetails"/>
        <kst-check v-model="searchData.WithInvoice" :label="labelOptInvoice"/>
      </kst-section>

      <template #bottom-left>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          @click="handleExport"
        />
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../SalesInfo.js";
import Model from "../SalesModel.js";

import Export from "./SalesExportDetails_Details";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Export
  },
  data: () => ({
    Model: Model,
    searchData: {
      WithDetails: true,
      WithInvoice: true,
    },
    access: {},
    id: null,
    items: [],
    isListLoading: true,
    // error
    failureList: null,
    formWarnings: null
  }),
  computed: {
    labelOptDetails() {
      return Info.Export.Details.Excel.Option.Details;
    },
    labelOptInvoice() {
      return Info.Export.Details.Excel.Option.Invoice;
    },
    title() {
      return Info.Export.Details.Excel.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!this.id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }
    },

    handleError(message) {
      this.failureList = message;
    },
    handleExport() {
      this.failureList = null;
      this.$refs.refExport.exportExcel(this.id);
    }
  }
}
</script>