<template>
  <div>
    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="RecapNumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>

    <kst-input field="Date" :data="userData" :catalog="Catalog" disabled/>
  </div>
</template>

<script>
import Catalog from "../SalesRecapCatalog.js";
import Model from "../SalesRecapModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object,
    cashierOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  }
}
</script>