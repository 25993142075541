import AppEnum from "./App_Enum.js";

export default {
  Array: {
    toString(value, separator) {
      let result = "";
      if (!separator) {
        separator = AppEnum.Breakline.Html;
      }

      if (Array.isArray(value)) {
        for (const singleValue of value) {
          if (result !== "") {
            result += separator;
          }
          result += singleValue;
        }
      }
      else {
        result = value;
      }

      return result;
    }
  }
}