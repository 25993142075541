import VendorServices from "@/services/VendorServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import ReceiveServices from "@/services/ReceiveServices.js";

export default {
  getVendorList: VendorServices.getList,
  getAddressList: VendorServices.getAddressList,
  getVendorDetails: VendorServices.getDetails,
  postVendor: VendorServices.postVendor,
  putVendor: VendorServices.putVendor,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // receive
  getReceiveList: ReceiveServices.getList,
  getReceiveListData: ReceiveServices.getListData
}