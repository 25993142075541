<template>
  <kst-section class="mt-5" :title="deliverySectionTitle">
    <kst-warning class="mb-4" :items="deliveryWarning"/>

    <kst-input field="IsNeedDelivery" :data="userData" :catalog="Catalog"
      :disabled="deliveryList.length > 0" @input="handleDelivery_Change"
    />
    <template v-if="userData.IsNeedDelivery">
      <kst-input field="DeliveryDate" :data="userData" :catalog="Catalog"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";

export default {
  props: {
    userData: Object,
    deliveryList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    deliverySectionTitle() {
      return Info.Section.Delivery.Title;
    },
    deliveryWarning() {
      return [
        Info.Message.Edit_Delivery,
        Info.Message.Edit_IsNeedDelivery,
        Info.Message.Edit_InvoiceStatus,
      ];
    }
  },
  methods: {
    handleDelivery_Change() {
      Model.updateDetailsByDelivery(this.userData);
    }
  }
}
</script>