var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"title":_vm.title,"titleButton":'Lihat Laporan',"url":_vm.Router.Path.Main,"urlParams":_vm.urlParams}},[_c('kst-table',{attrs:{"view-mode":"","data":_vm.data,"failure":_vm.failureList,"loading":_vm.isListLoading}},[_c('b-table-column',{attrs:{"sortable":"","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"RequestedQuantity","label":_vm.Catalog.RequestedQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Packaging","label":_vm.Catalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }