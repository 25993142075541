import StockServices from "@/services/StockServices.js";
import StockClosingServices from "@/services/StockClosingServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import SpecialPriceServices from "@/services/SpecialPriceServices.js";
import CompanyServices from "@/services/CompanyServices.js";

export default {
  getStockList: StockServices.getList,
  getStockClosingList: StockClosingServices.getList,
  getStockClosingDetail: StockClosingServices.getDetails,
  getWarehouseList: WarehouseServices.getList,
  getSpecialPriceList: SpecialPriceServices.getList,
  postStockClosing: StockClosingServices.postStockClosing,
  getCompanyDetails: CompanyServices.getDetails
}