var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"input-mode":"","data":_vm.userDispatches}},[_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"Variants","label":_vm.DispatchCatalog.Variants.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"k-control-py"},[_vm._v(_vm._s(_vm.getVariants(row)))])]}}])}),_c('b-table-column',{attrs:{"field":"ImageSrc","label":_vm.DispatchCatalog.ImageSrc.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-image',{attrs:{"src":row[column.field],"isLocal":row.ImageIsLocal,"index":index},on:{"input":_vm.handleImageInput,"delete":_vm.handleImageDelete}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-new-dispatch-list-FullName","field":"FullName","label":_vm.DispatchCatalog.FullName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-new-dispatch-list-SKU","field":"SKU","label":_vm.DispatchCatalog.SKU.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-new-dispatch-list-Barcode","field":"Barcode","label":_vm.DispatchCatalog.Barcode.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-new-dispatch-list-SellPrice","field":"SellPrice","label":_vm.DispatchCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-new-dispatch-list-Weight","field":"Weight","label":_vm.DispatchCatalog.Weight.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"kst-item-new-dispatch-list-Description","field":"Description","label":_vm.DispatchCatalog.Description.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }