import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  LogisticsNumber: {
    Label: "Nomor Pengiriman",
    Type: InputType.Text,
    MaxLength: 100
  },
  VehicleIDNumber: {
    Label: "Nomor Kendaraan",
    Type: InputType.Text,
    MaxLength: 20
  },
  Destination: {
    Label: "Tujuan",
    Type: InputType.Text,
    MaxLength: 100,
    Required: true
  },
  InvoiceDate: {
    Label: "Tanggal Penjualan",
    Type: InputType.Date,
    Range: false,
    ReadOnly: true
  },
  DepartureDate: {
    Label: "Tanggal Kirim",
    Type: InputType.Date,
    Required: true
  },
  ArrivalDate: {
    Label: "Tanggal Terkirim",
    Type: InputType.Date
  },
  CompletedDate: {
    Label: "Tanggal Selesai",
    Type: InputType.Date
  },
  ReturnedDate: {
    Label: "Tanggal Kembali",
    Type: InputType.Date
  },
  ArrivalDates: {
    Label: "Tanggal Terkirim",
    Type: InputType.Date,
    Required: true
  },
  CompletedDates: {
    Label: "Tanggal Selesai",
    Type: InputType.Date,
    Required: true
  },
  ReturnedDates: {
    Label: "Tanggal Kembali",
    Type: InputType.Date,
    Required: true
  },
  TotalWeight: {
    Label: "Berat (gram)",
    Type: InputType.Integer
  },
  Status: {
    Label: "Status",
    Type: InputType.Integer
  },
  StatusName: {
    Label: "Status",
    Type: InputType.Text
  },
  ShipmentFee: {
    Label: "Biaya Pengiriman",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D
  },
  SOID: {
    Label: "Nomor Penjualan",
    Type: InputType.ID,
    RelatedValue: "SONumber",
    RelatedModule: AppModule.Invoice
  },
  SONumber: {
    Label: "Nomor Penjualan",
    Type: InputType.Text,
    Required: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Exist: true,
    Unique: true,
    Static: true,
    RelatedIsActive: "WarehouseIsActive",
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
  },
  ClientID: {
    Label: "Pelanggan",
    Type: InputType.ID,
    RelatedModule: AppModule.Client,
    RelatedValue: "Client"
  },
  Client: {
    Label: "Nama",
    Type: InputType.Text,
  },
  ClientAlias: {
    Label: "Alias",
    Type: InputType.Text,
  },
  Comment: {
    Label: "Catatan",
    Input: InputType.TextArea
  },
  ReturnReason: {
    Label: "Alasan Kembali",
    Type: InputType.Text
  },
  StatusChanged: {
    Label: "Perubahan Status",
    Type: InputType.ID,
    Input: InputType.Select
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },

  // qty
  Quantity: {
    Label: "Qty Kirim",
    Type: InputType.Integer,
    MinValue: 1,
    MaxLength: 11,
    ReadOnly: true
  },

  // support
  IsAutoNumber: {
    Label: "Nomor Penjualan",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.AutoManual
  }
}