import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import ProfitLoss from "./Profitloss/Profitloss";
import ProfitlossExportList from "./Export/ProfitlossExport";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.ProfitLoss)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.ProfitLoss),
        name: RouterModel.getName(AppModule.ProfitLoss),
        component: ProfitLoss,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ProfitLoss),
          moduleName: RouterModel.getModule(AppModule.ProfitLoss)
        }
      },
      {
        path: RouterModel.getPath(AppModule.ProfitLoss, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.ProfitLoss, AppModuleType.ExportList),
        component: ProfitlossExportList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ProfitLoss, AppModuleType.ExportList),
          moduleName: RouterModel.getModule(AppModule.ProfitLoss)
        }
      },
    ];
  }
}