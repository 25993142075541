import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import ReceiveItemCatalog from "./ReceiveItemCatalog.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";
import ItemModel from "@/components/Item/ItemModel.js";
import PurchaseModel from "@/components/Purchase/PurchaseModel.js";

export default {
  // required in model
  Module: AppModule.Receive,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print
  },

  ReceiveItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },

  /***  property  ***/

  Button: {
    ReceivingItem: {
      Title:  AppModule.Item.FullName + ' ' + AppModule.Purchase.FullName
    }
  },

  Search: {
    Fields: ["ReceiveNumber", "PurchasingNumber"]
  },

  NewItem: {
    DispatchID: 0,
    PackagingName: "pcs"
  },

  InvalidBarcode: {
    Title: "Barcode tidak sesuai"
  },

  InvalidQty: {
    Title: "Minimum nilai Qty terima adalah 1"
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** methods ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },
  createItemsByPurchasing(purchaseItems) {
    return this.helper.createItemsByPurchasing(purchaseItems);
  },

  clearDetailsByVendor(details) {
    this.helper.clearDetailsByVendor(details);
  },

  setDetailsByData(data, receiveData) {
    this.helper.setDetailsByData(data, receiveData);
  },
  setDetailsByPurchasing(data, purchaseData) {
    this.helper.setDetailsByPurchasing(data, purchaseData);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },

  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },
  updateDetails(data, items) {
    this.helper.updateDetails(data, items);
  },
  updateItem(data) {
    this.helper.updateItem(data);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  setDefaultPackaging(item) {
    this.helper.setDefaultPackaging(item);
  },
  setDetailsByVendor(details, vendorData) {
    this.helper.setDetailsByVendor(details, vendorData);
  },
  setItemByData(data, receiveItem) {
    this.helper.setItemByData(data, receiveItem);
  },
  setItemByDispatch(data, receiveItem) {
    this.helper.setItemByDispatch(data, receiveItem);
  },
  setItemByPrice(item, itemData) {
    this.helper.setItemByPrice(item, itemData);
  },
  setItemBySelectedItem(item, selectedItem) {
    this.helper.setItemBySelectedItem(item, selectedItem);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  validateItem(item) {
    this.helper.validateItem(item);
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  /*** external ***/

  createAccess() {
    return AppCatalog.Access.getDefault();
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails() {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        PurchasingID: null,
        WarehouseID: null,
        ReceiveNumber: '',
        IsAutoNumber: true,
        Date: App.In.getDateToday(),
        VendorID: null,
        VendorName: '',
        Comment: '',
        // defined by system
        PONumber: null,
        VendorAddress: '',
        VendorAlias: '',
        VendorMobile: '',
        VendorPhone: '',
        VendorEmail: '',
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID(),
        // computed fields
        Total: 0,
        // additional field
        PurchasingNumber: '',
        PurchasingDate: null,
        WarehouseName: ''
      };
    },
    createItem() {
      return {
        ID: null,
        PurchasingItemID: null,
        // user input
        DispatchID: null,
        Name: '',
        Quantity: '',
        PackagingName: '',
        PackagingValue: 1,
        BuyPrice: 0,
        // computed fields
        TotalQuantity: 0,
        ExpenseAmount: 0,
        // additional fields
        SKU: '',
        imageSrc: null,
        DispatchBuyPrice: 0,
        DispatchIsActive: true,
        PurchasingItemQty: 0,
        Packaging: '',
        PackagingOptions: null,

        // UI validation
        Quantity_Valid: true,
        Quantity_vsReceive: true,

        // API validation
        DispatchID_Active: true,
        PackagingName_Active: true,
        PackagingName_Exist: true,
        PackagingName_Unique: true,
        PackagingName_Static: true,

        Errors: [],
        ErrorsColl: {}
      };
    },
    createItemsByPurchasing(purchaseItems) {
      let items = [];
      let newItem, isValid, qtyAvail;

      for (const purchaseItem of purchaseItems) {
        isValid = true;

        // new-item
        if (purchaseItem.DispatchID === PurchaseModel.NewItem.DispatchID) {
          isValid = false;
        }
        // closed item
        else if (purchaseItem.IsClosed === 1) {
          isValid = false;
        }
        // qty available
        else {
          qtyAvail = purchaseItem.Quantity - purchaseItem.QuantityReceived;

          if (qtyAvail <= 0) {
            isValid = false;
          }
        }

        if (isValid) {
          newItem = this.createItem();
          this.setItemByPurchasing(newItem, purchaseItem);
          this.updateItem(newItem);
          items.push(newItem);
        }
      }

      return items;
    },

    clearDetailsByVendor(details) {
      details.VendorID = null;
      details.Vendor = "";
      details.VendorName = "";
      details.VendorAlias = "";
      details.VendorAddress = "";
      details.VendorMobile = "";
      details.VendorPhone = "";
      details.VendorEmail = "";
    },

    setDetailsByData(data, receiveData) {
      data.ID = receiveData.ID;
      data.PurchasingID = receiveData.PurchasingID;
      data.WarehouseID = receiveData.WarehouseID;
      data.ReceiveNumber = App.In.getString(receiveData.ReceiveNumber);
      data.Date = App.In.getDate(receiveData.Date);
      data.VendorID = receiveData.VendorID;
      data.VendorName = App.In.getString(receiveData.VendorName);
      data.Comment = App.In.getString(receiveData.Comment);
      data.VendorAddress = receiveData.VendorAddress;
      data.VendorMobile = receiveData.VendorMobile;
      data.VendorAlias = receiveData.VendorAlias;
      data.VendorPhone = receiveData.VendorPhone;
      data.VendorEmail = receiveData.VendorEmail;
      data.PurchasingNumber = receiveData.PurchasingNumber;
      data.PurchasingDate = receiveData.PurchasingDate;
      data.WarehouseName = receiveData.WarehouseName;
    },
    setDetailsByPurchasing(data, purchaseData) {
      if (purchaseData.WarehouseIsActive === 1) {
        data.WarehouseID = purchaseData.WarehouseID;
        data.WarehouseName = purchaseData.WarehouseName;
      }

      data.PONumber = purchaseData.PONumber;
      data.PurchasingID = purchaseData.ID;
      data.PurchasingNumber = purchaseData.PONumber;
      data.PurchasingDate = purchaseData.Date;
      data.VendorID = purchaseData.VendorID;
      data.VendorAlias = purchaseData.VendorAlias;
      data.VendorName = purchaseData.Vendor;
      data.VendorAlias = purchaseData.VendorAlias;
      data.VendorAddress = purchaseData.VendorAddress;
      data.VendorMobile = purchaseData.VendorMobile;
      data.VendorPhone = purchaseData.VendorPhone;
      data.VendorEmail = purchaseData.VendorEmail;
    },
    setDefaultPackaging(item) {
      const packaging = item.PackagingOptions.rows[0];
      item.PackagingName = packaging.ItemUnitName;
      item.PackagingValue = packaging.ItemUnitAmount;
    },
    setDetailsByVendor(details, vendorData) {
      // user input
      details.VendorID = vendorData.VendorID;
      details.VendorName = App.In.getString(vendorData.VendorName);
      details.VendorAddress = App.In.getString(vendorData.Address);
      // defined by system
      details.VendorAlias = vendorData.Alias;
      details.VendorMobile = vendorData.ContactMobile;
      details.VendorPhone = vendorData.ContactPhone;
      details.VendorEmail = vendorData.ContactEmail;
    },
    setItemByPurchasing(data, purchaseItem) {
      data.PurchasingItemID = purchaseItem.ID;
      data.imageSrc = purchaseItem.imageSrc;
      data.DispatchID = purchaseItem.DispatchID;
      data.Name = purchaseItem.Name;
      data.BuyPrice = purchaseItem.BuyPrice;
      data.SKU = purchaseItem.SKU;
      data.DispatchBuyPrice = purchaseItem.DispatchBuyPrice;
      data.DispatchIsActive = App.In.getBoolean(purchaseItem.DispatchIsActive);
      data.PurchasingItemQty = purchaseItem.Quantity - purchaseItem.QuantityReceived;

      if (purchaseItem.PackagingIsActive === 1) {
        data.PackagingName = purchaseItem.PackagingName;
        data.PackagingValue = purchaseItem.PackagingValue;
      }
    },
    setItemByData(data, receiveItem) {
      data.ID = receiveItem.ID;
      data.PurchasingItemID = receiveItem.PurchasingItemID;
      data.DispatchID = receiveItem.DispatchID;
      data.Name = App.In.getString(receiveItem.Name);

      data.Quantity =  App.In.getInteger(receiveItem.Quantity);
      data.BuyPrice =  App.In.getInteger(receiveItem.BuyPrice);

      data.SKU = receiveItem.SKU;

      data.DispatchBuyPrice = App.In.getInteger(receiveItem.DispatchBuyPrice);
      data.DispatchIsActive =  App.In.getBoolean(receiveItem.DispatchIsActive);
      data.PurchasingItemQty = receiveItem.PurchasingItemQty;

      data.PackagingOptions = ItemModel.getPackagingOptions(receiveItem.PackagingList);

      if (receiveItem.PackagingIsActive === 1) {
        data.PackagingName = receiveItem.PackagingName;
        data.PackagingValue = receiveItem.PackagingValue;
      }

    },
    setItemByDispatch(data, receiveItem) {
      data.PurchasingItemID = receiveItem.PurchasingItemID;
      data.DispatchID = receiveItem.DispatchID;
      data.Name = App.In.getString(receiveItem.FullName);

      data.BuyPrice =  App.In.getInteger(receiveItem.BuyPrice);

      data.SKU = receiveItem.SKU;

      data.DispatchBuyPrice = App.In.getInteger(receiveItem.DispatchBuyPrice);
      data.DispatchIsActive =  App.In.getBoolean(receiveItem.DispatchIsActive);
      data.PurchasingItemQty = receiveItem.PurchasingItemQty;

      data.PackagingOptions = ItemModel.getPackagingOptions(receiveItem.Packaging);

      if (receiveItem.PackagingIsActive === 1) {
        data.PackagingName = receiveItem.PackagingName;
        data.PackagingValue = receiveItem.PackagingValue;
      }else{
        this.setDefaultPackaging(data);
      }

    },
    setItemBySelectedItem(item, selectedItem) {
      // handle sourceData.BuyPrice as null
      let buyPrice =  parseInt(selectedItem.BuyPrice);
      if (isNaN(buyPrice)) {
        buyPrice = 0;
      }

      item.DispatchID = selectedItem.DispatchID;
      item.Name = App.In.getString(selectedItem.FullName);
      item.SKU = selectedItem.SKU;
      item.BuyPrice = (buyPrice === 0 ? '' : App.In.getInteger(buyPrice));
      item.DispatchBuyPrice = buyPrice;

      item.PackagingOptions = ItemModel.getPackagingOptions(selectedItem.Packaging);

      if (selectedItem.PackagingIsActive === 1) {
        item.PackagingName = selectedItem.PackagingName;
        item.PackagingValue = selectedItem.PackagingValue;
      }else{
        this.setDefaultPackaging(item);
      }
    },
    setItemByPrice(item, itemData) {
      // user input
      item.BuyPrice = App.In.getInteger(itemData.BuyPrice);
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        item.ErrorsColl[field].push(error);
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.Quantity_Valid) {
        if (!item.DispatchID_Active) {
          newErrors.push(ReceiveItemCatalog.DispatchID.Label + " tidak aktif");
        }
        
        if (!item.Quantity_Valid) {
          if (!item.Quantity_vsReceive) {
            newErrors.push("Maksimum nilai " + ReceiveItemCatalog.Quantity.Label + " adalah " +
              App.Value.getValue("PurchasingItemQty", item, ReceiveItemCatalog) + " " +
              "(" + ReceiveItemCatalog.PurchasingItemQty.Label + ")"
            );
          }
        }
      }

      item.Errors = newErrors;
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        PurchasingID: data.PurchasingID,
        WarehouseID: data.WarehouseID,
        ReceiveNumber: data.IsAutoNumber ? null : App.Out.getString(data.ReceiveNumber),
        Date: App.In.getDate(data.Date),
        VendorID: data.VendorID,
        VendorName: App.In.getString(data.VendorName),
        VendorAddress: App.In.getString(data.VendorAddress),
        VendorMobile: App.In.getString(data.VendorMobile),
        VendorPhone: App.In.getString(data.VendorPhone),
        VendorEmail: App.In.getString(data.VendorEmail),
        Comment: App.In.getString(data.Comment),
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          ID: item.ID,
          PurchasingItemID: item.PurchasingItemID,
          DispatchID: item.DispatchID,
          Name: App.In.getString(item.Name),
          Quantity: App.In.getInteger(item.Quantity),
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          BuyPrice: App.In.getInteger(item.BuyPrice)
        });
      }

      return resultItems;
    },

    getItemInfo(item) {
      let infoList = [];

      // Purchase
      if (item.PurchasingItemID) {
        infoList.push(AppCatalog.Message.PurchaseItem);
        if(item.PurchasingItemQty){
          infoList.push(ReceiveItemCatalog.PurchasingItemQty.Label + ": " +
            App.Value.getValue("PurchasingItemQty", item, ReceiveItemCatalog)
          );
        }
      }

      // SKU
      infoList.push(ReceiveItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, ReceiveItemCatalog)
      );

      return infoList;
    },

    validateItem(item) {
      item.Quantity_Valid = true;
      item.Quantity_vsReceive = true;

      const qty = App.Out.getInteger(item.Quantity);

      if(item.PurchasingItemID) {
        if (qty > item.PurchasingItemQty) {
          item.Quantity_Valid = false;
          item.Quantity_vsReceive = false;
        }
      }
    },

    updateItem(data) {
      if (data.PackagingOptions) {
        data.PackagingValue = ItemModel.getPackagingValue(
          data.PackagingOptions.rows, data.PackagingName
        );
      }
      this.updateItemByPackaging(data);
      data.TotalQuantity = App.Data.getTotalQty(data.Quantity, data.PackagingValue);
      data.ExpenseAmount = App.Data.getTotal(data.Quantity, data.PackagingValue, data.BuyPrice);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    updateDetails(data, items) {
      let total = 0;

      // TotalBruto
      for (const item of items) {
        total += item.ExpenseAmount;
      }
      data.Total = total;
    }
  }
}