<template>
  <kst-table paginated action input-mode 
    :data="userItems" 
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="FullName"
      :label="StockClosingItemCatalog.FullName.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="StockClosingItemCatalog.SellPrice.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="SpecialPrice"
      :label="StockClosingItemCatalog.SpecialPrice.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" 
        :options="row.SpecialPriceOptions"
        :catalog="StockClosingItemCatalog"
        :index="index"
        @input="handleSpecialPrice(row)"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="DiscountedValue"
      :label="StockClosingItemCatalog.DiscountedValue.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <b-table-column 
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="StockClosingItemCatalog.Quantity.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="StockClosingItemCatalog.Total.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StockClosingItemCatalog"
      />
    </b-table-column>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import Model from "../StockClosingModel.js";
import StockClosingItemCatalog from "../StockClosingItemCatalog.js";

export default {
  props: {
    userItems: Array,
    isListLoading: Boolean,
    failureList: null
  },
  data: () => ({
    ID: 0,
    StockClosingItemCatalog: StockClosingItemCatalog
  }),
  methods: {
    handleQuantity(item) {
      this.ID = item.DispatchID;
      item.Quantity_Valid = Model.validateItemQty(item);
      item.Total = Model.getSubtotalItems(item);
      const grandTotal = Model.getGrandTotal(this.userItems);
      this.$emit(this.$kst.Enum.Event.Callback, grandTotal);
    },
    handleRowErrors(errors, field) {
      const index = this.userItems.findIndex(item => item.DispatchID == this.ID)
      if (index >= 0) {
        Model.setItemErrors(this.userItems[index], field, errors);
      }
    },
    handleSpecialPrice(item) {
      Model.mapDataItem(item)
      const grandTotal = Model.getGrandTotal(this.userItems);
      this.$emit(this.$kst.Enum.Event.Callback, grandTotal);
    }
  }
}
</script>