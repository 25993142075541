import ExpenseSubServices from "../../../services/ExpenseSubServices.js";
import ExpenseCatServices from "../../../services/ExpenseCategoryServices.js";

export default {
  getExpenseSubList: ExpenseSubServices.getList,
  getExpenseSubListAll: ExpenseSubServices.getAllList,
  getExpenseCatList: ExpenseCatServices.getList,
  getExpenseCatDetail: ExpenseSubServices.getDetail,
  postExpenseCategory: ExpenseSubServices.postCategory,
  putExpenseCategory: ExpenseSubServices.putCategory
}