/**
 * WARNING !!!
 * Please kindly DO NOT use this directly, instead use "OutputType.js"
 **/
export default {
  // general
  Text: "text",
  // boolean
  ActiveInactive: "active", // output: Aktif, Non Aktif
  AutoManual: "auto", // output: Auto, Manual
  Boolean: "boolean", // output: true, false
  OpenClosed: "open", // output: Open, Closed
  YesNo: "yesno", // output: Ya, Tidak.
  // credential
  Password: "password",
  // date & time
  ShortDate: "sdate",
  MediumDate: "mdate",
  LongDate: "ldate",
  ShortMonthly: "smonthly",
  LongMonthly: "lmonthly",
  ShortDateTime: "sdatetime",
  MediumDateTime: "mdatetime",
  LongDateTime: "ldatetime",
  // email
  Email: "email",
  // id
  ID: "id",
  // number
  Accounting: "acc",
  Accounting2D: "acc2",
  Currency: "curr",
  Decimal: "dec",
  Decimal2D: "dec2",
  Integer: "int",
  Percent: "percent",
  // phone
  Phone: "tel",
  // text
  TextArea: "textarea",
  // url
  Url: "url"
}