<template>
  <kst-section items-mode class="mt-5">
    <template #top-right>
      <div class="column is-narrow">
        <kst-input 
          field="SpecialPriceValue" 
          :data="specialPriceData" 
          :catalog="Catalog" 
          :options="specialPriceOptions"
          @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-tooltip info-mode position="is-left">
          <template #content>
            {{ Info.Message.ApplyDiscountMessage }}
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </div>
    </template>

    <List 
      :userItems="userItems" 
      :isListLoading="isListLoading"
      @callback="handleCallbackItems"
    />
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import DialogMixin from "@/mixins/DialogMixin.js";
import Model from "../StockClosingModel.js";
import Catalog from "../StockClosingItemCatalog.js";
import Info from "../StockClosingInfo.js";

import List from "./StockClosingNew_Items_List";

export default {
  mixins: [AxiosMixin, DialogMixin],
  components: {
    List
  },
  props: {
    specialPriceData: Object,
    userItems: Array,
    specialPriceOptions: Object,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  }),
  methods: {
    handleInput() {
      const specialPriceData = this.specialPriceData;
      this.userItems.map(item => { return Model.mapDataItem(item, specialPriceData) });
      const grandTotal = Model.getGrandTotal(this.userItems);
      this.$emit(this.$kst.Enum.Event.Callback, grandTotal);
    },
    handleCallbackItems(grandTotal) {
      this.$emit(this.$kst.Enum.Event.Callback, grandTotal);
    }
  }
}
</script>