<template>
  <div class="kst-report-result" :loading="isListLoading">
    <div class="columns" v-if="isListLoading">
      <div class="column">
        <kst-skeleton :active="isListLoading"/>
      </div>
    </div>
    <div class="columns" v-if="!isListLoading">
      <div class="column kst-report-item">
        <Profit
          :data="data"
          :icon="Info.Profit.Icon"
          :iconStyle="Info.Profit.IconStyle"
          :iconBgStyle="Info.Profit.IconBgStyle"
          :title="Info.Profit.TitleSection"
        />
        <ProfitSummary :data="data"/>
      </div>
      <div class="column kst-report-item">
        <NetProfit :data="data"/>
        <ResultChart :chartData="chartData"/>
      </div>
    </div>

    <kst-section :title="Info.Tabs.Details.Label" v-if="!isListLoading">
      <template #top-right>
        <kst-button action-mode
          class="is-primary mr-4"
          :module="Model.Module"
          :moduleType="Model.ModuleType.Print"
        />
        <kst-button action-mode
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          :urlParams="urlParams"
        />
      </template>

      <ResultDetails :items="detailsList"/>
    </kst-section>
  </div>
</template>

<script>
import 'moment/locale/id';
import Info from "../ProfitlossInfo.js";
import Model from "../ProfitlossModel.js";

import ResultChart from "./Profitloss_Result_Chart";
import ResultDetails from "./Profitloss_Result_Details";
import Profit from "./Profitloss_Result_Profit";
import NetProfit from "./Profitloss_Result_NetProfit";
import ProfitSummary from "./Profitloss_Result_ProfitSummary";

export default {
  components: {
    ResultChart,
    ResultDetails,
    Profit,
    NetProfit,
    ProfitSummary,
  },
  props: {
    data: Object,
    searchData: Object,
    summaryList: Array,
    chartData: Object,
    detailsList: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Info: Info,
    isDaily: false,
    isViewChart: true,
    Model: Model
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.WarehouseID]: this.$kst.Search.getValue(this.searchData.WarehouseID, ""),
        [this.$kst.Enum.Param.StartDate]: this.$kst.Out.getDate(this.searchData.Date[0]),
        [this.$kst.Enum.Param.EndDate]: this.$kst.Out.getDate(this.searchData.Date[1])
      });
    }
  }
}
</script>