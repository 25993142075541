<template>
  <kst-section :title="salesSectionTitle">
    <SalesSearch
      ref="mdlSalesSearch"
      :warehouseList="warehouseList"
      @submit="handleSales_Submit"
    />

    <kst-warning class="mb-4" :items="salesWarning"/>
    <kst-failure class="mb-4" data-mode :message="failureSales"/>

    <kst-output control-off status-off
      field="SalesDraftID" :data="userData" :catalog="Catalog"
    >
      <template #right>
        <div class="control">
          <template v-if="userData.SalesDraftID === null">
            <kst-tooltip search-mode hover-only :content="Model.Sales.Module.FullName">
              <kst-button search-mode @click="handleSearch_Sales"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Model.Sales.Module.FullName">
              <kst-button clear-mode @click="handleClear_Sales"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>
    <kst-output field="DraftDate" :data="userData" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";

import SalesSearch from "@/components/Sales/Search/SalesSearch";

export default {
  components: {
    SalesSearch
  },
  props: {
    failureSales: String,
    userData: Object,
    warehouseList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    salesSectionTitle() {
      return Info.Section.Sales.Title;
    },
    salesWarning() {
      return [Info.Message.New_Sales];
    }
  },
  methods: {
    handleSearch_Sales() {
      this.$refs.mdlSalesSearch.show();
      this.$emit(this.$kst.Enum.Event.ResetFailure);
    },
    handleClear_Sales() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSales_Submit(row) {
      this.$emit(this.$kst.Enum.Event.Submit, row);
    }
  }
}
</script>