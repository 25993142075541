<template>
  <kst-container class="kst-vendor-details">
    <kst-form 
      :loading="isLoading" 
      :title="title"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :warnings="formWarnings"
    >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
        <b-tab-item :label="tabs.Details">
          <Details :data="data"/>
          <Addresses :data="data" :items="data.Address"/>
        </b-tab-item>
        <b-tab-item :label="tabs.Receive">
          <Receive
            :dataReceive="dataReceive"
            :warehouseOptions="warehouseOptions"
            :searchData="searchDataReceive"
            :accessData="accessData"
            :failureList="failureList"
            :isListLoading="isListLoading"
            @reset="handleReceiveReset"
            @submit="handleReceiveSubmit"
          />
        </b-tab-item>
      </b-tabs>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../VendorInfo.js";

import Action from "./VendorDetails_Action";
import Details from "./VendorDetails_Details";
import Addresses from "./VendorDetails_Addresses";
import Receive from "./VendorDetails_Receive";
import Services from "../VendorServices.js";
import Model from "../VendorModel.js";
import ReceiveModel from "../../Receive/ReceiveModel.js";
import ReceiveSearchCatalog from "./VendorDetailsVendorSearchCatalog.js";
import InvoiceModel from '../../Invoice/InvoiceModel.js';

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details,
    Addresses,
    Receive
  },
  data: () => ({
    data: {},
    dataReceive: {},
    searchDataReceive: {},
    accessData: {},
    warehouseOptions: {},
    isListLoading: false,
    isLoading: false,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      Receive: Info.Tabs.Receive
    },
    // error
    formWarnings: null,
    failureInit: null,
    failureList: null,
    // support debounce
    loadCounter: 0
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }
      this.initDataReceive();
      this.initSearchReceive();
      this.loadDetails(id);
    },
    initDataReceive() {
      this.dataReceive = {
        GrandTotal: 0,
        Items: []
      }
      this.accessData = ReceiveModel.createAccess();
    },
    initSearchReceive() {
      this.searchDataReceive = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      }
    },
    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;

      const config = Services.getVendorDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.data = data;
      this.loadListData();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadListData() {
      const config = Services.getReceiveListData();
      this.runAxios(config, null,
        this.loadListData_Success,
        this.loadListData_Error,
        this.loadListData_Invalid
      )
    },
    loadListData_Success(data) {
      this.accessData = data.Access;
      this.warehouseOptions = InvoiceModel.getWarehouseOptions(
        data.WarehouseList, ReceiveSearchCatalog.WarehouseID.Label
      )
      this.loadCounter++;
      this.isLoading = false;
      this.loadReceive();
    },
    loadListData_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadListData_Invalid(message) {
      this.formWarnings = [message];
      this.isLoading = false;
    },
    loadReceive() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      const counter = this.loadCounter;
      const searchData = this.searchDataReceive
      this.failureList = null;
      this.isListLoading = true;
      this.initDataReceive();
      let options = {}
      if(searchData) {
        options.vendor = id;
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.SearchReceive.Fields;
        options.startDate = this.$kst.Out.getDate(searchData.Date[0]);
        options.endDate = this.$kst.Out.getDate(searchData.Date[1]);
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
      }
      const config = Services.getReceiveList(options);
      this.runAxios(config, counter, this.loadReceive_Success, this.loadReceive_Error);
    },
    loadReceive_Success(data, counter) {
      this.isListLoading = false;
      if (counter !== this.loadCounter) {
        return;
      }
      this.dataReceive = Model.mapDataReceive(data);
    },
    loadReceive_Error(message) {
      this.isListLoading = false;
      this.failureList = message;
    },
    handleReceiveReset() {
      this.initSearchReceive();
      this.loadCounter++;
      this.loadReceive();
    },
    handleReceiveSubmit() {
      this.loadCounter++;
      this.loadReceive();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>