import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  Section: {
    Filter: {
      Title: "Filter"
    }
  },

  Sales: {
    Title: "Penjualan (Invoice)",
    Icon: "mdi mdi-36px mdi-poll ",
    IconStyle: "kst-dashboard-sales ",
    IconBgStyle: "kst-dashboard-sales-bg ",
    Field: "Total",
    Help: {
      Icon: "mdi mdi-calendar-clock",
      Title: "Last 24 Hours",
      Style: "kst-dashboard-sales-help "
    }
  },

  Profit: {
    Title: AppCatalog.Info.Profit,
    Icon: "mdi mdi-36px mdi-poll ",
    IconStyle: "kst-dashboard-sales ",
    IconBgStyle: "kst-dashboard-sales-bg ",
    Field: "Profit",
    Help: {
      Icon: "mdi mdi-calendar-clock",
      Title: "Last 24 Hours",
      Style: "kst-dashboard-sales-help "
    }
  },
}