import AppCatalog from "./AppCatalog.js";
import App_Data from "./App_Data.js";
import App_Formatter_Body from "./App_Formatter_Body.js";

export default {
  /*** boolean ***/

  getActiveInactive(value) {
    return App_Data.isTrue(value) ? "Aktif" : "Non Aktif";
  },

  getAutoManual(value) {
    return App_Data.isTrue(value) ? "Otomatis" : "Manual";
  },

  getBoolean(value) {
    return App_Data.isTrue(value) ? "True" : "False";
  },

  getOpenClosed(value) {
    return App_Data.isTrue(value) ? "Closed" : "Open";
  },

  getYesNo(value) {
    return App_Data.isTrue(value) ? "Ya" : "Tidak";
  },

  /*** date ***/

  getShortDate(value) {
    return App_Formatter_Body.getShortDate(value);
  },

  getRangeShortDate(value) {
    if (value === undefined || value === null) {
      return null;
    }
    if (!Array.isArray(value)) {
      return null;
    }
    if (value.length === 0) {
      return null;
    }

    if (value.length === 1) {
      return App_Formatter_Body.getShortDate(value[0]);
    }
    else {
      return App_Formatter_Body.getShortDate(value[0]) +
        " " + AppCatalog.Format.DateRangeSeparator + " " +
        App_Formatter_Body.getShortDate(value[1]);
    }
  },

  getMediumDate(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = new Date(value);

    let day = '0' + newValue.getDate();
    day = day.slice(-2);

    const monthIndex = newValue.getMonth();
    const monthName = AppCatalog.MonthNames[monthIndex];

    return day + " " + monthName + " " + newValue.getFullYear();
  },

  getLongDate(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = new Date(value);

    let day = '0' + newValue.getDate();
    day = day.slice(-2);

    const monthIndex = newValue.getMonth();
    const monthName = AppCatalog.MonthLongNames[monthIndex];

    return day + " " + monthName + " " + newValue.getFullYear();
  },

  getShortMonthly(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = new Date(value);

    const monthIndex = newValue.getMonth();
    const monthName = AppCatalog.MonthNames[monthIndex];

    return monthName + " " + newValue.getFullYear();
  },

  getLongMonthly(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = new Date(value);

    const monthIndex = newValue.getMonth();
    const monthName = AppCatalog.MonthLongNames[monthIndex];

    return monthName + " " + newValue.getFullYear();
  },

  getShortDateTime(value) {
    return App_Formatter_Body.getShortDateTime(value);
  },

  getMediumDateTime(value) {
    return App_Formatter_Body.getMediumDateTime(value);
  },

  getLongDateTime(value) {
    return App_Formatter_Body.getLongDateTime(value);
  },

  /*** number ***/

  getAccounting(value) {
    return App_Formatter_Body.getAccounting(value, 0);
  },
  getAccounting2D(value) {
    return App_Formatter_Body.getAccounting(value, 2);
  },

  getCurrency(value) {
    if (value === undefined || value === null) {
      return null;
    }

    if (typeof value !== "number") {
      if (isNaN(value)) {
        return value;
      }
    }

    var formatter = new Intl.NumberFormat(AppCatalog.IntlFormat.Number, {
      style: 'currency',
      currency: AppCatalog.IntlFormat.Currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return formatter.format(value);
  },

  getDecimal(value, maxDigit) {
    return App_Formatter_Body.getDecimal(value, 0, maxDigit);
  },

  getDecimal2D(value) {
    return App_Formatter_Body.getDecimal(value, 2, 2);
  },

  getInteger(value) {
    if (value === undefined || value === null) {
      return null;
    }

    if (typeof value !== "number") {
      if (isNaN(value)) {
        return value;
      }
    }

    var formatter = new Intl.NumberFormat(AppCatalog.IntlFormat.Number, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return formatter.format(value);
  },

  getPercent(value) {
    return App_Formatter_Body.getDecimal(value, 0, 2);
  },

  /*** url ***/

  getExternalUrl(value) {
    if (value === undefined || value === null) {
      return "";
    }

    if (value.trim() === "") {
      return "";
    }

    return value.indexOf("http") >= 0 ? value : "http://" + value;
  }
}