import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import PurchaseItemCatalog from "./PurchaseItemCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.Purchase,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print
  },

  /*** related ***/

  PurchaseItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      Select: AppModuleType.Select
    }
  },

  /*** property ***/

  Purchase: {
    PO: {
      Title: "Cari Pembelian Stok (PO)"
    }
  },

  Search: {
    Fields: ["PONumber", "Vendor"]
  },

  NewItem: {
    DispatchID: 0,
    PackagingName: "pcs"
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label,
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label,
    }
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails(this.Status);
  },
  createItem() {
    return this.helper.createItem();
  },
  createStatusOptions(optionAllText) {
    return this.helper.createStatusOptions(optionAllText, this.Status);
  },

  setDetailsByAPI(details, errors) {
    this.helper.setDetailsByAPI(details, errors);
  },
  setDetailsByData(details, detailsData) {
    this.helper.setDetailsByData(details, detailsData);
  },
  setDetailsByStatus(details) {
    this.helper.setDetailsByStatus(details, this.Status);
  },
  setDetailsByVendor(details, vendorData) {
    this.helper.setDetailsByVendor(details, vendorData);
  },
  setDetailsByWarehouse(details, warehouse) {
    this.helper.setDetailsByWarehouse(details, warehouse);
  },

  setItemByData(item, itemData) {
    this.helper.setItemByData(item, itemData);
  },
  setItemByItem(item, itemData) {
    this.helper.setItemByItem(item, itemData);
  },
  setItemByItemDetails(item, itemData) {
    this.helper.setItemByItemDetails(item, itemData);
  },
  setItemByNew(item, itemName) {
    this.helper.setItemByNew(item, itemName, this.NewItem);
  },
  setItemByPrice(item, itemData) {
    this.helper.setItemByPrice(item, itemData);
  },
  setItemByAPI(item, itemData) {
    this.helper.setItemByAPI(item, itemData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  setItemsByData(items, rows) {
    this.helper.setItemsByData(items, rows);
  },
  
  setItemsByAPI(items, errors) {
    this.helper.setItemsByAPI(items, errors);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item, this.NewItem);
  },
  getItemInfo_Edit(item) {
    return this.helper.getItemInfo_Edit(item, this.NewItem);
  },

  clearDetailsByVendor(details) {
    this.helper.clearDetailsByVendor(details);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },
  updateDetails(data, items) {
    this.helper.updateDetails(data, items);
  },

  setDefaultPackaging(item) {
    this.helper.setDefaultPackaging(item);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validateItem(item) {
    this.helper.validateItem(item);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails(statusEnum) {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        PONumber: "",
        IsAutoNumber: true,
        Date: App.In.getDateToday(),
        VendorID: null,
        Vendor: "",
        VendorAddress: "",
        WarehouseID: null,
        Comment: "",
        DeliveryAddress: "",
        DeliveryDate: App.In.getDateToday(),
        // defined by system
        VendorAlias: "",
        VendorMobile: "",
        VendorPhone: "",
        VendorEmail: "",
        ReceivingCount: 0,
        Status: statusEnum.Open.ID,
        StatusName: statusEnum.Open.Label,
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID(),
        // computed field
        Total: 0,
        // API validation
        PONumber_Unique: true,
        PONumber_Static: true,
        WarehouseID_Active: true,
        WarehouseID_Exist: true,
        WarehouseID_Unique: true,
        WarehouseID_Static: true
      };
    },
    createItem() {
      return {
        ID: null,
        // user input
        DispatchID: null,
        Name: "",
        Quantity: "",
        PackagingName: "",
        BuyPrice: "",
        IsClosed: false,
        // defined by system
        SKU: "",
        QuantityReceived: 0,
        PackagingValue: 1,
        DispatchBuyPrice: 0,
        DispatchIsActive: true,
        PackagingOptions: null,
        // error
        Errors: [],
        ErrorsColl: {},
        // computed field
        Packaging: "",
        TotalQuantity: 0,
        ExpenseAmount: 0,
        // UI validation
        Quantity_Valid: true,
        Quantity_vsReceive: true,
        // API validation
        DispatchID_Active: true,
        PackagingName_Active: true,
        PackagingName_Exist: true,
        PackagingName_Unique: true,
        PackagingName_Static: true
      }
    },
    createStatusOptions(optionAllText, statusEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: statusEnum.Open.ID, [rowLabel]: statusEnum.Open.Label },
        { [rowId]: statusEnum.Closed.ID, [rowLabel]: statusEnum.Closed.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByAPI(details, errors) {
      for (const error of errors) {
        if (error.coll === null) {
          const fieldTarget = App.Vee.getFieldTarget(error.field, error.rule);
          details[fieldTarget] = false;
        }
      }
    },
    setDetailsByData(details, detailsData) {
      details.ID = detailsData.ID;
      // user input
      details.PONumber = App.In.getString(detailsData.PONumber);
      details.Date = App.In.getDate(detailsData.Date);
      details.VendorID = detailsData.VendorID;
      details.Vendor = App.In.getString(detailsData.Vendor);
      details.VendorAddress = App.In.getString(detailsData.VendorAddress);
      details.Comment = App.In.getString(detailsData.Comment);
      details.DeliveryAddress = App.In.getString(detailsData.DeliveryAddress);
      details.DeliveryDate = App.In.getDate(detailsData.DeliveryDate);
      // defined by system
      details.VendorAlias = detailsData.VendorAlias;
      details.VendorMobile = detailsData.VendorMobile;
      details.VendorPhone = detailsData.VendorPhone;
      details.VendorEmail = detailsData.VendorEmail;
      details.ReceivingCount = detailsData.ReceivingCount;

      if (detailsData.WarehouseIsActive === 1) {
        details.WarehouseID = detailsData.WarehouseID;
        details.WarehouseName = detailsData.WarehouseName;
      }
    },
    setDetailsByStatus(details, statusEnum) {
      for (const key in statusEnum) {
        const statusObj = statusEnum[key];

        if (statusObj.ID === details.Status) {
          details.StatusName = statusObj.Label;
          return;
        }
      }
    },
    setDetailsByVendor(details, vendorData) {
      // user input
      details.VendorID = vendorData.VendorID;
      details.Vendor = App.In.getString(vendorData.VendorName);
      details.VendorEmail = App.In.getString(vendorData.ContactEmail);
      details.VendorPhone = App.In.getString(vendorData.ContactPhone);
      details.VendorAddress = App.In.getString(vendorData.Address);
      // defined by system
      details.VendorAlias = vendorData.Alias;
    },
    setDetailsByWarehouse(details, warehouse) {
      details.DeliveryAddress = App.Array.searchValue(
        warehouse.rows, 'ID', details.WarehouseID, 'Address'
      );
    },

    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.Quantity_Valid) {
        if (!item.DispatchID_Active) {
          newErrors.push(PurchaseItemCatalog.DispatchID.Label + " tidak aktif");
        }

        if (!item.Quantity_vsReceive) {
          newErrors.push("Minimum nilai " + PurchaseItemCatalog.Quantity.Label + " adalah " +
            App.Value.getValue("QuantityReceived", item, PurchaseItemCatalog) + " " +
            "(" + PurchaseItemCatalog.QuantityReceived.Label + ")"
          );
        }
      }

      item.Errors = newErrors;
    },

    setItemsByData(items, rows) {
      App.Array.truncate(items);

      for (const row of rows) {
        let item = this.createItem();
        this.setItemByData(item, row);
        this.updateItem(item);
        items.push(item);
      }
    },

    setItemByAPI(item, itemData) {
      // user input
      item.DispatchID = itemData.DispatchID;
      item.Name = App.In.getString(itemData.FullName);
      // item.Quantity = App.In.getInteger(itemData.Quantity);
      item.PackagingName = itemData.PackagingName;
      item.BuyPrice = App.In.getInteger(itemData.BuyPrice);
      // defined by system
      item.SKU = itemData.SKU;
      item.PackagingValue = itemData.PackagingValue;
      item.DispatchBuyPrice = itemData.BuyPrice;
      item.PackagingOptions = ItemModel.getPackagingOptions(itemData.Packaging);
      // computed field
      item.Packaging = itemData.Packaging;
      item.ExpenseAmount = itemData.ExpenseAmount;

      this.setDefaultPackaging(item);
    },
    setItemByData(item, itemData) {
      item.ID = itemData.ID;
      item.DispatchID = itemData.DispatchID;
      // user input
      item.Name = App.In.getString(itemData.Name);
      item.Quantity = App.In.getInteger(itemData.Quantity);
      item.BuyPrice = App.In.getInteger(itemData.BuyPrice);
      item.IsClosed = App.In.getBoolean(itemData.IsClosed);  
      // defined by system
      item.SKU = itemData.SKU;
      item.QuantityReceived = itemData.QuantityReceived;
      item.DispatchBuyPrice = itemData.DispatchBuyPrice;
      item.DispatchIsActive = itemData.DispatchIsActive;
      if(itemData.PackagingList) {
        item.PackagingOptions = ItemModel.getPackagingOptions(itemData.PackagingList);
      }

      if (itemData.PackagingIsActive === 1) {
        item.PackagingName = itemData.PackagingName;
        item.PackagingValue = itemData.PackagingValue;
      }else{
        this.setDefaultPackaging(item);
      }
    },
    setItemByItem(item, itemData) {
      // handle sourceData.BuyPrice as null
      let buyPrice = parseInt(itemData.BuyPrice);
      if (isNaN(buyPrice)) {
        buyPrice = 0;
      }

      // user input
      item.DispatchID = itemData.DispatchID;
      item.Name = App.In.getString(itemData.FullName);
      item.BuyPrice = (buyPrice === 0 ? '' : App.In.getInteger(buyPrice));
      // defined by system
      item.SKU = itemData.SKU;
      item.DispatchBuyPrice = buyPrice;
    },
    setItemByItemDetails(item, itemData) {
      item.PackagingOptions = ItemModel.getPackagingOptions(itemData.Packaging);
    },
    setItemByNew(item, itemName, newItemDefault) {
      // user input
      item.DispatchID = newItemDefault.DispatchID;
      item.Name = App.In.getString(itemName);
      item.PackagingName = newItemDefault.PackagingName;
    },
    setItemByPrice(item, itemData) {
      // user input
      item.BuyPrice = App.In.getInteger(itemData.BuyPrice);
    },

    setDefaultPackaging(item) {
      const packaging = item.PackagingOptions.rows[0];
      item.PackagingName = packaging.ItemUnitName;
      item.PackagingValue = packaging.ItemUnitAmount;
    },

    setItemsByAPI(items, errors) {
      const collection = "Items";

      for (const error of errors) {
        if (error.coll === collection) {
          const fieldTarget = App.Vee.getFieldTarget(error.field, error.rule);
          items[error.index][fieldTarget] = false;
        }
      }
    },

    getItemInfo(item, newItemDefault) {
      let infoList = [];

      // new-item
      if (item.DispatchID === newItemDefault.DispatchID) {
        infoList.push(AppCatalog.Info.NewItem);
      }
      else {
        // SKU
        infoList.push(PurchaseItemCatalog.SKU.Label + ": " +
          App.Value.getValue("SKU", item, PurchaseItemCatalog)
        );
      }

      return infoList;
    },
    getItemInfo_Edit(item, newItemDefault) {
      let infoList = [];

      // new-item
      if (item.DispatchID === newItemDefault.DispatchID) {
        infoList.push(AppCatalog.Info.NewItem);
      }
      else {
        // deleted item
        if (!item.DispatchIsActive) {
          infoList.push(AppCatalog.Info.DeletedItem);
        }

        // SKU
        infoList.push(PurchaseItemCatalog.SKU.Label + ": " +
          App.Value.getValue("SKU", item, PurchaseItemCatalog)
        );

        // QuantityReceived
        infoList.push(PurchaseItemCatalog.QuantityReceived.Label + ": " +
          App.Value.getValue("QuantityReceived", item, PurchaseItemCatalog)
        );
      }

      return infoList;
    },

    clearDetailsByVendor(details) {
      details.VendorID = null;
      details.Vendor = "";
      details.VendorAlias = "";
      details.VendorAddress = "";
    },

    updateItem(item) {
      if (item.PackagingOptions) {
        item.PackagingValue = ItemModel.getPackagingValue(
          item.PackagingOptions.rows, item.PackagingName
        );
      }

      this.updateItemByPackaging(item);
      item.TotalQuantity = App.Data.getTotalQty(item.Quantity, item.PackagingValue);
      item.ExpenseAmount = App.Data.getTotal(item.Quantity, item.PackagingValue, item.BuyPrice);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    updateDetails(data, items) {
      let total = 0;
      for (const item of items) {
        total += item.ExpenseAmount;
      }

      // Total
      data.Total = total;
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        PONumber: data.IsAutoNumber ? null : App.Out.getString(data.PONumber),
        VendorID: data.VendorID,
        Vendor: App.In.getString(data.Vendor),
        VendorAlias: App.In.getString(data.VendorAlias),
        VendorAddress: App.In.getString(data.VendorAddress),
        VendorMobile: App.In.getString(data.VendorMobile),
        VendorPhone: App.In.getString(data.VendorPhone),
        VendorEmail: App.In.getString(data.VendorEmail),
        Date: App.In.getDate(data.Date),
        DeliveryAddress: App.In.getString(data.DeliveryAddress),
        DeliveryDate: App.In.getDate(data.DeliveryDate),
        Comment: App.In.getString(data.Comment),
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          ID: item.ID,
          DispatchID: item.DispatchID,
          Name: App.In.getString(item.Name),
          Quantity: App.In.getInteger(item.Quantity),
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          BuyPrice: App.In.getInteger(item.BuyPrice),
          IsClosed: App.Out.getBoolean(item.IsClosed)
        });
      }

      return resultItems;
    },

    validateItem(item) {
      item.Quantity_Valid = true;
      item.Quantity_vsReceive = true;

      if (!item.IsClosed) {
        const qty = App.Out.getInteger(item.Quantity);

        if (qty !== null) {
          if (qty < item.QuantityReceived) {
            item.Quantity_Valid = false;
            item.Quantity_vsReceive = false;
          }
        }
      }
    }
  },
  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  }
}