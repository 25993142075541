import SalesRecapServices from "@/services/SalesRecapServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import UserServices from "@/services/UserServices.js";
import InvoiceServices from "@/services/InvoiceServices.js";
import CompanyServices from "@/services/CompanyServices.js";

export default {
  getSalesRecapList: SalesRecapServices.getList,
  getSalesRecapDetails: SalesRecapServices.getDetails,
  postSalesRecap: SalesRecapServices.postSalesRecap,
  // invoice
  getInvoiceList: InvoiceServices.getList,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // cashier
  getCashierList: UserServices.getList,
  // company
  getCompanyDetails: CompanyServices.getDetails
}