<template>
  <kst-container class="kst-report-item">
    <kst-form :loading="isLoading" :title="title">
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
          @clear="handleSearchClear"
        />
      </kst-section>

      <kst-section v-if="items">
        <template #top-right>
          <!--
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          -->
          <kst-button action-mode
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Export"
            :urlParams="urlParams"
          />
        </template>
        <template v-if="items">
          <template v-if="isListLoading">
            <kst-skeleton :active="isListLoading"/>
          </template>
          <template v-else>
            <Details :items="items" :searchData="searchData"/>
          </template>
        </template>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../RptStockCardModel.js";
import Catalog from "../RptStockCardCatalog.js";
import Services from "../RptStockCardServices.js";

import Details from "./RptStockCard_Details";
import Search from "./RptStockCard_Search";

export default {
  mixins: [AxiosMixin],
  components: {
    Details,
    Search
  },
  data: () => ({
    Model: Model,
    searchData: {},
    filter: {},
    warehouseOptions: {},
    items: null,
    isLoading: true,
    isListLoading: false,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.WarehouseID]: this.$kst.Search.getValue(this.searchData.WarehouseID, ""),
        [this.$kst.Enum.Param.DispatchID]: this.$kst.Search.getValue(this.searchData.DispatchID, ""),
        [this.$kst.Enum.Param.StartDate]: this.$kst.Out.getDate(this.searchData.Date[0]),
        [this.$kst.Enum.Param.EndDate]: this.$kst.Out.getDate(this.searchData.Date[1]),
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
    },
    initSearch() {
      this.items = null;
      
      // search
      this.searchData = {
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll,
        DispatchID: null,
        itemName: ""
      };

      // filter
      this.filter = {
        dispatchId: this.searchData.DispatchID,
        startDate: this.searchData.Date[0],
        endDate: this.searchData.Date[1],
        warehouseId: this.searchData.WarehouseID
      }

      this.loadWarehouse();
    },

    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, Catalog.WarehouseID.Label
      );
      
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadReport(counter, searchData) {
      this.items = null;
      this.failureList = null;

      const filter = this.populateFilter(searchData);
      let { dispatchId, startDate, endDate, warehouseId } = filter;

      const config = Services.getItemData(dispatchId, startDate, endDate, warehouseId);
      this.runAxios(config, counter, this.loadReport_Success, this.loadReport_Error);
    },
    loadReport_End() {
      this.isListLoading = false;
    },
    loadReport_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.groupTransaction();
      this.loadReport_End();
    },
    loadReport_Error(message) {
      this.failureList = message;
      this.loadReport_End();
    },

    groupTransaction() {
      let groupedTrx = {}, warehouseList = [];
      let warehouseId;

      for (const item of this.items.History)
      {
        warehouseId = item.WarehouseID;

        if (!warehouseList.includes(warehouseId)) {
          // new warehouse: create group
          warehouseList.push(warehouseId);
          groupedTrx[warehouseId] = [];
        }

        groupedTrx[warehouseId].push(item);
      }

      let result = [];
      for (const index in groupedTrx) {
        const records = groupedTrx[index];
        const firstRecord = records[0];
        let resultItems = [];

        result.push({
          WarehouseID: firstRecord.WarehouseID,
          WarehouseName: firstRecord.WarehouseName,
          Items: resultItems
        });

        for (const item of records) {
          resultItems.push(item);
        }
      }

      this.items.GroupHistory = result;
    },

    populateFilter(searchData) {
      let warehouseId = searchData.WarehouseID;
      if (warehouseId === this.$kst.Search.OptionAll) {
        warehouseId = '';
      }

      return {
        dispatchId: searchData.DispatchID,
        startDate: searchData.Date[0],
        endDate: searchData.Date[1],
        warehouseId: warehouseId
      };
    },

    handleSearchSubmit() {
      this.isListLoading = true;
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
    },
    handleSearchClear() {
      this.items = null;

      this.searchData = {
        Date: this.searchData.Date,
        WarehouseID: this.searchData.WarehouseID,
        DispatchID: null,
        itemName: ""
      };
    }
  }
}
</script>