<template>
  <kst-container class="px-5 py-5 kst-dashboard-summary">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <nav class="level is-mobile">
            <div class="level-left">
              <div class="level-item mr-5 kst-dashboard-summary-icon" :class="iconBgStyle">
                <span class="icon is-medium">
                  <i class="kst-dashboard-summary-icon-content"
                    :class="icon + iconStyle"
                  />
                </span>
              </div>
              <div class="level-item kst-dashboard-summary-info">
                <div class="has-text-weight-bold kst-dashboard-summary-info-value">
                  <div>
                    <kst-value class="title is-5" :field="field" :data="data" :catalog="Catalog" />
                  </div>
                </div>
                <div class="has-text-weight-semibold kst-dashboard-summary-info-title">
                  {{ title }}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="level-right">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="titleButton"
          @click="handleSubmit"
        />
      </div>
    </nav>
    
  </kst-container>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";
import Catalog from './DashboardCatalog.js';

export default {
  mixins: [RouterMixin],
  props: {
    icon: String,
    iconStyle: String,
    iconBgStyle: String,
    title: String,
    helpStyle: String,
    helpIcon: String,
    helpTitle: String,
    data: Object,
    field: String,
    titleButton: String,
    url: String,
    urlParams: String
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleSubmit() {
      this.redirectTo(this.url + this.urlParams);
    }
  }
  

}
</script>