<template>
  <kst-table paginated view-mode
    :data="items"
    :loading="isListLoading"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="ItemCatalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="ItemCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="SKU"
      :label="ItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="Packaging"
      :label="ItemCatalog.Packaging.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="AvailableQuantity"
      :label="ItemCatalog.AvailableQuantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="Price"
      :label="ItemCatalog.Price.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="ItemCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import ItemCatalog from "../DeliveryItemCatalog.js";
import Model from "../DeliveryModel.js";

export default {
  props: {
    isListLoading: Boolean,
    items: Array,
    userData: Object
  },
  data: () => ({
    ItemCatalog: ItemCatalog,
    Model: Model
  }),
  methods: {
    handleAdd_NewItem() {
      this.$emit(this.$kst.Enum.Event.NewItem);
    }
  }
}
</script>