<template>
  <div>
    <kst-output field="PONumber" :data="data" :catalog="Catalog"/>
    <kst-output field="Date" :data="data" :catalog="Catalog"/>

    <template v-if="data.VendorID">
      <kst-output field="VendorID" :data="data" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="VendorAlias" :data="data" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="Vendor" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output field="Vendor" :data="data" :catalog="Catalog"/>
    </template>

    <kst-output field="VendorAddress" :data="data" :catalog="Catalog"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-output field="StatusName" :data="data" :catalog="Catalog"/>
    <kst-output control-off field="CreatedBy" :data="data" :catalog="Catalog">
      <template #default>
        <kst-output expanded floating-label
          class="pr-2"
          field="CreatedByID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #right>
        <kst-output addons expanded floating-label
          field="CreatedDate" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>
    <kst-output control-off field="LastUpdatedBy" :data="data" :catalog="Catalog">
      <template #default>
        <kst-output expanded floating-label
          class="pr-2"
          field="LastUpdatedByID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #right>
        <kst-output addons expanded floating-label
          field="LastUpdatedDate" :data="data" :catalog="Catalog"
        />
      </template>
    </kst-output>

    <kst-section :title="sectionTitle" class="mt-5">
      <kst-output field="DeliveryAddress" :data="data" :catalog="Catalog"/>
      <kst-output field="DeliveryDate" :data="data" :catalog="Catalog"/>
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../PurchaseCatalog.js";
import Info from "../PurchaseInfo.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Delivery.Title;
    }
  }
}
</script>