var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-container',{staticClass:"kst-item-detail-report-related"},[_c('kst-section',[_c('kst-table',{attrs:{"view-mode":"","data":_vm.items.Quantities,"loading":_vm.isListLoading}},[_c('b-table-column',{attrs:{"sortable":"","field":"WarehouseID","label":_vm.Catalog.WarehouseID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"Quantity","label":_vm.Catalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.Quantity)+" "+_vm._s(_vm.items.UnitName)+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","numeric":"","field":"HppTotal","label":_vm.Catalog.HppTotal.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }