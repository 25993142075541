export default {
  Details: {
    Name: "Rincian", Code:"Details", Path: "details", Target:"_blank", Icon: "mdi mdi-magnify mdi-18px"
  },
  Edit: {
    Name: "Edit", Code:"Edit", Path: "edit", Target:"_blank", Icon: "mdi mdi-pencil mdi-18px"
  },
  Export: {
    Name: "Export", Code:"Export", Path: "export", Target:"_blank", Icon: "mdi mdi-download mdi-18px"
  },
  ExportDetails: {
    Name: "Export", Code:"ExportDetails", Path: "export-details", Target:"_blank", Icon: "mdi mdi-download mdi-18px"
  },
  ExportList: {
    Name: "Export", Code:"ExportList", Path: "export-list", Target:"_blank", Icon: "mdi mdi-download mdi-18px"
  },
  List: {
    Name: "Daftar", Code:"List", Path: null, Target:"_blank", Icon: "mdi mdi-format-list-bulleted mdi-18px"
  },
  New: {
    Name: "Tambah", Code:"New", Path: "addnew", Target:"_blank", Icon: "mdi mdi-plus-thick mdi-18px"
  },
  Print: {
    Name: "Print", Code:"Print", Path: "print", Target:"_blank", Icon: "mdi mdi-printer mdi-18px"
  },
  PrintDetails: {
    Name: "Print", Code:"PrintDetails", Path: "print-details", Target:"_blank", Icon: "mdi mdi-printer mdi-18px"
  },
  PrintList: {
    Name: "Print", Code:"PrintList", Path: "print-list", Target:"_blank", Icon: "mdi mdi-printer mdi-18px"
  },
  PrintPicking: {
    Name: "Print Pengambilan Barang", Code:"PrintPicking", Path: "printpicking", Target:"_blank", Icon: "mdi mdi-printer mdi-18px"
  },
  PrintSJ: {
    Name: "Print Surat Jalan", Code:"PrintSJ", Path: "printsj", Target:"_blank", Icon: "mdi mdi-printer mdi-18px"
  },
  PrintThermal: {
    Name: "Print Thermal", Code:"PrintThermal", Path: "printthermal", Target:"_blank", Icon: "mdi mdi-printer mdi-18px"
  },
  Search: {
    Name: "Cari", Code:"Search", Path: null, Icon: "mdi mdi-magnify mdi-18px"
  },
  Select: {
    Name: "Pilih", Code:"Select", Path: "select", Target:"_blank", Icon: "mdi mdi-gesture-double-tap mdi-18px"
  },
  Status: {
    Name: "Ubah Status", Code:"Status", Path: "updatestatus", Target:"_blank", Icon: "mdi mdi-pencil mdi-18px"
  }
}