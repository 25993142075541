import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true
  },
  DispatchName: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  DispatchSellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Qty: {
    Label: "Qty",
    Type: InputType.Integer,
    MinValue: 0,
    MaxLength: 11,
    Required: true
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Select,
    Required: true
  },
  ItemID: {
    Label: "Barang",
    Type: InputType.ID,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text,
    ReadOnly: true
  },
  Price: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Stock: {
    Label: "Stok",
    Type: InputType.Integer,
    ReadOnly: true
  },
  HppPerPcs: {
    Label: "Harga Pokok/Pcs",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D
  },
  HppTotal: {
    Label: "Harga Pokok Total",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D
  },
  PackagingValue: {
    Label: "Jumlah Kemasan",
    Type: InputType.Integer,
    ReadOnly: true
  },
  TotalQty: {
    Label: "Total Qty",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Packaging: {
    Label: "Kemasan",
    Type: InputType.Text,
    ReadOnly: true
  },
}