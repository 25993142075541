import ItemServices from "@/services/ItemServices";
import StockTrfServices from "@/services/StockTrfServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";

export default {
  getStockTrfDetails: StockTrfServices.getDetails,
  getStockTrfDetailsData: StockTrfServices.getDetailsData,
  getStockTrfList: StockTrfServices.getList,
  getStockTrfListData: StockTrfServices.getListData,
  postStockTrf: StockTrfServices.postStockTrf,
  // item
  getPackagingList: ItemServices.loadPackaging,
  // warehouse
  getWarehouseList: WarehouseServices.getList
}