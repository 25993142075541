<template>
  <kst-section :title="sectionTitle">
    <Edit
      :access="access"
      :editDispatch="editDispatch"
      :userDispatches="userDispatches"
    />
    <List
      :access="access"
      :userDispatches="userDispatches"
    />
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import DispatchCatalog from "../DispatchCatalog.js";
import Model from "../ItemModel.js";

import Edit from "./ItemNew_Dispatches_Edit";
import List from "./ItemNew_Dispatches_List";

export default {
  components: {
    Edit,
    List
  },
  props: {
    access: Object,
    userDispatches: Array
  },
  data: () => ({
    Model: Model,
    DispatchCatalog: DispatchCatalog,
    editDispatch: {}
  }),
  created() {
    this.init();
  },
  computed: {
    sectionTitle() {
      return Info.Section.Dispatch.Title;
    }
  },
  methods: {
    init() {
      this.editDispatch = Model.createDispatch();
    }
  }
}
</script>