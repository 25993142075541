<template>
  <kst-container class="kst-report-omzet">
    <kst-form :loading="isLoading" :title="title">
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-skeleton :active="isListLoading"/>
      <Result v-if="data" :isListLoading="isListLoading" :data="data" :searchData="searchData"
        :chartData="chartData" :chartDataRetur="chartDataRetur" :summaryList="summaryList" 
        :detailsList="detailsList" :paymentTypeList="paymentTypeList"
       />
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Moment from 'moment';
import Catalog from "../OmzetCatalog.js";
import Model from "../OmzetModel";
import Info from "../OmzetInfo";

import Search from "./Omzet_Search";
import Result from "./Omzet_Result";
import Services from "../OmzetServices.js";

const PARAM_StartDate = "startdate";
const PARAM_EndDate = "enddate";


export default {
  mixins: [AxiosMixin],
  components: {
    Search,
    Result
  },
  data: () => ({
    Model: Model,
    searchData: {},
    chartData: null,
    chartDataRetur: null,
    filter: {},
    warehouseOptions: {},
    subCategoryList: [],
    labelList: null,
    detailsList: [],
    summaryList: [],
    paymentTypeList: [],
    data: null,
    isLoading: true,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
    },
    initSearch() {
      const start = this.$route.query[PARAM_StartDate];
      const end = this.$route.query[PARAM_EndDate];

      if(start){
        let dateRange = [];
        dateRange.push(new Date(start));
        dateRange.push(new Date(end));
        
        this.searchData = {
          Date: dateRange,
          WarehouseID: this.$kst.Search.OptionAll
        }

        this.filter = {
          startDate: new Date(start),
          endDate: new Date(end),
          byMonth: 0
        }
      }else{
        this.searchData = {
          Date: this.$kst.In.getDefaultDateRange(),
          WarehouseID: this.$kst.Search.OptionAll
        }

        this.filter = {
          startDate: this.searchData.Date[0],
          endDate: this.searchData.Date[1],
          byMonth: 0
        }
      }
      
      this.loadWarehouse();
    },

    loadWarehouse() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },

    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, Catalog.WarehouseID.Label
      );
      this.loadCounter++;
      this.loadPaymentType();
      this.isLoading = false;
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    loadPaymentType() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getPaymentTypeList();
      this.runAxios(config, null, this.loadPaymentType_Success, this.loadPaymentType_Error);
    },

    loadPaymentType_Success(data) {
      this.paymentTypeList = data;
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
      this.isLoading = false;
    },
    loadPaymentType_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    loadReport(counter, searchData) {
      this.data = null;

      let options = {};
      if (searchData) {
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.startDate = this.$kst.Out.getDate(searchData.Date[0]);
        options.endDate = this.$kst.Out.getDate(searchData.Date[1]);
        options.withreturn = 1;
      }

      const config = Services.getTurnoverSales(options);
      this.runAxios(config, counter, this.loadReport_Success, this.loadReport_Error);

      this.loadCategory();
    },

    loadReport_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.processData(data);
      Model.getPaymentType(this.data.Details, this.paymentTypeList);
      this.isListLoading = false;
    },
    loadReport_Error(message) {
      this.failureList = message;
      this.isLoading = false;
    },

    loadCategory() {
      const config = Services.getSubCatList();
      this.runAxios(config, null, this.loadCategory_Success, this.loadCategory_Error);
    },

    loadCategory_Success(data) {
      this.subCategoryList = data;
    },
    loadCategory_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    processData(data) {
      let omzetTotal = 0;
      let totalBruto = 0;
      let totalDisc = 0;
      let totalRetur = 0;
      let normalPrice = 0;
      let specialPrice = 0;
      
      let omzet = {};
      let items = [];

      for(let item of data.InvoiceList){
        if (item.PaymentTypeID === null) {
          item.PaymentTypeID = 1
        }
        totalBruto += item.TotalBruto;
        totalDisc += item.TotalDisc;

        for (let item2 of this.paymentTypeList) {
          if (item.PaymentTypeID == item2.ID) {
            item.PaymentTypeName = item2.Name;
            item.IsActive = item2.IsActive
          }
        }

        const itemInvoice = {
          Total: item.Total,
          TotalBruto: item.TotalBruto,
          Date: item.InvoiceDate,
          Type: "Invoice",
          PaymentTypeID: item.PaymentTypeID,
        }
        items.push(itemInvoice);
      }

      if(data.ReturnList){
        for(let item of data.ReturnList) {
          totalRetur += item.Total;
          const itemRetur = {
            Date: item.Date,
            Type: "Retur",
            Total: item.Total
          }
          items.push(itemRetur);
        }
      }

      for(let item of data.InvoiceItemList) {
        if (item.SpecialPriceID === null) {
          normalPrice += (item.SellPrice * item.TotalRequestedQuantity);
        } else {
          specialPrice += item.Price;
        }
      }

      items.sort((a,b) => { return new Date(b.Date) - new Date(a.Date); });

      omzetTotal = totalBruto - totalDisc - totalRetur;

      omzet.Details = items;
      omzet.Total = omzetTotal;
      omzet.TotalBruto = totalBruto;
      omzet.TotalDisc = totalDisc;
      omzet.TotalRetur = totalRetur;
      omzet.NormalPrice = normalPrice;
      omzet.SpecialPrice = specialPrice;

      this.data = omzet;
      this.detailsList = items;

      this.updateData();
    },

    updateData() {
      this.chartData = null;
      this.chartDataRetur = null;

      this.summaryList = this.data.Details.map(item => {
        item.FormattedDate = this.filter.byMonth === 1 ? 
        Moment(item.Date).locale("id").format('MMMM YYYY') : 
        this.$kst.Value.getValue("Date", item, Catalog);
        return item;
      });

      this.labelList = null;
      this.labelList = this.createChartLabel();

      this.chartData = this.processChartData();
      this.chartDataRetur = this.processChartDataRetur();

      this.isLoading = false;
      this.isListLoading = false;
    },

    processChartData() {
      const dataOmzet = this.labelList.map(item => {
        let totalOmzet = 0;

        this.summaryList.map(unit => {
          if(unit.Type === "Invoice" && unit.FormattedDate === item){
            totalOmzet += unit.Total;
          }
        });
        const summaryIdx = this.summaryList.findIndex(unit => unit.FormattedDate === item && unit.Type === "Invoice")
        const omset = summaryIdx === -1 
          ? 0 : totalOmzet;
        return omset;
      });

      return this.createChart(dataOmzet);
    },

    processChartDataRetur() {
      const dataRetur = this.labelList.map(item => {
        let totalRetur = 0;

        this.summaryList.map(unit => {
          if(unit.Type === "Retur" && unit.FormattedDate === item){
            totalRetur += unit.Total;
          }
        });
        const summaryIdx = this.summaryList.findIndex(unit => unit.FormattedDate === item)
        const retur = summaryIdx === -1 
          ? 0 : totalRetur;
        return retur;
      });

      return this.createChartRetur(dataRetur);
    },

    createChart(dataOmzet) {
      return {
        type: 'bar',
        data: {
          labels: this.labelList,
          datasets: [
            {
              label: Info.OmzetLabelDetail.Label,
              type: 'bar',
              data: dataOmzet,
              backgroundColor: Info.OmzetLabelDetail.BackgroundColor,
              borderColor: Info.OmzetLabelDetail.BackgroundColor,
              borderWidth: 3
            }
          ]
        },
      }
    },

    createChartRetur(dataRetur) {
      return {
        type: 'bar',
        data: {
          labels: this.labelList,
          datasets: [
            {
              label: Info.ReturLabelDetail.Label,
              type: 'bar',
              data: dataRetur,
              backgroundColor: Info.ReturLabelDetail.BackgroundColor,
              borderColor: Info.ReturLabelDetail.BackgroundColor,
              borderWidth: 3
            }
          ]
        },
      }
    },

    createChartLabel() {
      let labelList = [];
      if(this.filter.byMonth === 1){
        // months
        let diff = Moment(this.searchData.Date[1]).diff(this.searchData.Date[0], 'months');
        let now = Moment(this.searchData.Date[0])
        for(let i = 0; i <= diff; i++) {          
          if(i === 0) {           
            labelList.push(now.locale("id").format('MMMM YYYY'));
          } else {
            let next = Moment(now).add(1, 'month');
            labelList.push(next.locale("id").format('MMMM YYYY'));
            now = next;
          }          
        }
      }else{
        // days
        let diff = Moment(this.searchData.Date[1]).diff(this.searchData.Date[0], 'days');
        let now = Moment(this.searchData.Date[0])
        for(let i = 0; i <= diff; i++) {          
          if(i === 0) {           
            labelList.push(now.locale("id").format('DD/MM/YYYY'));
          } else {
            let next = Moment(now).add(1, 'day');
            labelList.push(next.locale("id").format('DD/MM/YYYY'));
            now = next;
          }          
        }
      }
      return labelList;
    },

    handleSearchSubmit(filter) {
      this.isLoading = true;
      this.filter = filter;
      this.loadCounter++;
      this.loadReport(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
    }
  }
}
</script>

<style lang="css" src="./_layout.css"/>