<template>
  <ValidationProvider
    v-slot="{ errors }"
    :bails="provider.Bails"
    :class="provider.Class"
    :immediate="provider.Immediate"
    :name="catalog[field].Label"
    :rules="validationRules"
    :tag="provider.Tag"
    :vid="vid"
  >
    {{ updateErrors(errors) }}
    <b-field :type="{ 'is-danger': errors[0] }">
      <template v-if="!errorOff" #message>
        <kst-list hide-empty :items="getErrors(errors)"/>
      </template>

      <b-numberinput controls-position="compact" controls-rounded
        ref="myField"
        v-model="data[field]"
        :disabled="theDisabled"
        :id="vid"
        :maxlength="maxlength"
        @keydown.native.enter="handleEnter"
        @input="handleInput"
        @focus.native="handleFocus"
      ></b-numberinput>
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    errorOff: Boolean,
    loadOnFocus: Boolean,
    vid: String,
    validationRules: {
      type: Object
    },
    // catalog
    provider: Object
  },
  computed: {
    maxlength() {
      if (this.catalog[this.field].Length !== undefined) {
        return this.catalog[this.field].Length;
      }
      else if (this.catalog[this.field].MaxLength !== undefined) {
        return this.catalog[this.field].MaxLength;
      }

      return undefined;
    },
    theDisabled() {
      if (this.disabled === undefined) {
        return this.catalog[this.field].ReadOnly;
      }
      return this.disabled;
    }
  },
  mounted() {
    if (this.loadOnFocus) {
      this.focus();
    }
  },
  methods: {
    focus() {
      this.$refs.myField.focus();
    },

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
    updateErrors(errors) {
      this.$emit(this.$kst.Enum.Event.Error, errors);
    },
    handleFocus(event) {
      this.$emit(this.$kst.Enum.Event.Focus, event);
    },
    handleEnter() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },

    getErrors(errors) {
      let result = [];

      for (const error of errors) {
        if (error !== this.$kst.Vee.ValidRule) {
          result.push(error);
        }
      }

      return result;
    }
  }
}
</script>