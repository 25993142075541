<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Client"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-status :value="row.Status" :statusEnum="InvoiceModel.Status"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-tooltip search-mode hover-only v-if="row.ID">
        <kst-button search-mode is-round @click="handleSearchItem(row.ID)"/>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";
import Catalog from "../../Invoice/InvoiceCatalog.js";
import InvoiceModel from "../../Invoice/InvoiceModel.js";
import InvoiceRouter from "../../Invoice/InvoiceRouter";

export default {
  mixins: [RouterMixin],
  props: {
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    InvoiceModel: InvoiceModel
  }),
  methods: {
    handleSearchItem(id) {
      const urlParam = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.openTab(InvoiceRouter.Path.Details + urlParam);
    }
  }
}
</script>