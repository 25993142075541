<template>
  <kst-table action input-mode 
    :data="userItems" 
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="DispatchName"
      :label="SpecialPriceAdjItemCatalog.DispatchName.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        :index="index"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="SpecialPriceAdjItemCatalog.PackagingName.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        :index="index" :options="row.PackagingOptions"
        @input="handlePackaging(row)"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="SpecialPriceAdjItemCatalog.SellPrice.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        :index="index"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="SpecialPriceID"
      :label="SpecialPriceAdjItemCatalog.SpecialPriceID.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" 
        :options="row.SpecialPriceOptions"
        :catalog="SpecialPriceAdjItemCatalog"
        :index="index"
        @input="handleSpecialPrice(row)"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="DiscountedValue"
      :label="SpecialPriceAdjItemCatalog.DiscountedValue.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <b-table-column 
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="SpecialPriceAdjItemCatalog.Qty.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="SpecialPriceAdjItemCatalog.Total.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.Item.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import SpecialPriceAdjItemCatalog from "../SpecialPriceAdjItemCatalog.js";
import Model from "../SpecialPriceAdjModel.js";
import Info from "../SpecialPriceAdjInfo.js";

export default {
  mixins: [DialogMixin],
  props: {
    userItems: Array,
    isListLoading: Boolean,
    failureList: null
  },
  data: () => ({
    ID: 0,
    Model: Model,
    selectedIndex: null,
    SpecialPriceAdjItemCatalog: SpecialPriceAdjItemCatalog
  }),
  methods: {
    handleQuantity(item) {
      this.updateDataItem(item);
    },
    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    },
    handleSpecialPrice(item) {
      this.updateDataItem(item);
    },
    handlePackaging(item) {
      this.updateDataItem(item);
    },
    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userItems.splice(this.selectedIndex, 1);
      this.$emit(this.$kst.Enum.Event.Callback);
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    },
    updateDataItem(item) {
      Model.updateDataItemDetail(item);
      this.$emit(this.$kst.Enum.Event.Callback);
    }
  }
}
</script>