<template>
  <kst-section items-mode class="mt-5">
    <SalesItemSearch
      ref="mdlSalesItemSearch"
      :items="salesItems"
      @submit="handleSalesItem_Submit"
    />
    <StockSearch
      ref="mdlStockSearch"
      @load="handleItem_Load"
      @submit="handleItem_Submit"
    />
    <PriceSearch ref="mdlPriceSearch" @submit="handleInvoice_Submit"/>

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <template v-if="userData.SalesDraftID !== null">
          <kst-button action-mode
            class="is-primary mr-4"
            tag="button"
            :label="Info.Button.SalesItem.Label"
            :moduleType="Model.ModuleType.New"
            @click="handleAdd_SalesItem"
          />
        </template>

        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.InvoiceItem.Module.FullName"
          :moduleType="Model.InvoiceItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <template v-if="userData.SalesDraftID !== null">
            <kst-button action-mode
              class="is-primary mr-4"
              tag="button"
              :label="Info.Button.SalesItem.Label"
              :moduleType="Model.ModuleType.New"
              @click="handleAdd_SalesItem"
            />
          </template>

          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.InvoiceItem.Module.FullName"
            :moduleType="Model.InvoiceItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID === null">
      <kst-warning :items="itemsWarning"/>
    </template>
    <template v-else>
      <kst-failure class="mb-4" data-mode :message="failureItems"/>
      <List
        :userData="userData"
        :userItems="userItems"
        :deliveryList="deliveryList"
        @callback="handleList_Callback"
      />

      <kst-input class="is-hidden"
        field="DeliveryMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="mt-4" :items="invalidItems"/>
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";
import Services from "../InvoiceServices.js";

import SalesItemSearch from "@/components/Sales/ItemSearch/SalesItemSearch";
import StockSearch from "@/components/Stock/Search/StockSearch";
import PriceSearch from "@/components/Invoice/PriceSearch/InvoicePriceSearch";
import List from "./InvoiceEdit_Items_List";

export default {
  mixins: [AxiosMixin],
  components: {
    SalesItemSearch,
    StockSearch,
    PriceSearch,
    List
  },
  props: {
    salesData: Object,
    userData: Object,
    userItems: Array,
    deliveryList: Array,
    invoiceItemGroup: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model,
    failureItems: null,
    rowIndex: null,
    salesItems: []
  }),
  computed: {
    invalidItems() {
      return Model.getInvalidItems(this.userData);
    },
    itemsWarning() {
      return [Info.Message.Edit_Items];
    }
  },
  methods: {
    loadDispatchDetails(dispatchId, item) {
      this.failureItems = null;
      const config = Services.getDispatchDetails(dispatchId);
      this.runAxios(config, item, this.loadDispatchDetails_Success, this.loadDispatchDetails_Error);
    },
    loadDispatchDetails_Success(data, item) {
      Model.setItemByDispatchDetails(item, data);
      Model.setItemByPackaging(item);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    loadDispatchDetails_Error(message) {
      this.failureItems = message;
    },

    loadStock(dispatchId, item) {
      this.failureItems = null;
      const options = {
        dispatch: dispatchId,
        warehouse: this.userData.WarehouseID
      };
      const config = Services.getStockList(options);
      this.runAxios(config, item, this.loadStock_Success, this.loadStock_Error);
    },
    loadStock_Success(data, item) {
      this.userItems.push(item);
      Model.setItemsByStock(this.userItems, data);
      Model.updateDetails(this.userData, this.userItems);
    },
    loadStock_Error(message) {
      this.failureItems = message;
    },

    handleAdd_Item() {
      this.failureItems = null;
      this.$refs.mdlStockSearch.show(this.userData.WarehouseID);
    },
    handleItem_Load(rows) {
      Model.updateItemsByStock_Edit(this.userItems, rows, this.invoiceItemGroup);
      Model.validateItemsByStock(this.userData, this.userItems);
    },
    handleItem_Submit(row) {
      let item = Model.createItem();
      Model.setItemByStock(item, row);
      this.loadDispatchDetails(row.DispatchID, item);
    },

    handleAdd_SalesItem() {
      this.failureItems = null;
      this.salesItems = Model.getItemsForSelection(this.userItems, this.salesData.Items);
      this.$refs.mdlSalesItemSearch.show();
    },
    handleSalesItem_Submit(row) {
      let item = Model.createItem();
      Model.setItemBySales(item, row);
      this.loadStock(row.DispatchID, item);
    },

    handleInvoice_Submit(row) {
      Model.setItemByPrice(this.userItems[this.rowIndex], row);
    },
    handleList_Callback(params) {
      const { isPrice, index } = params;

      if (isPrice) {
        this.rowIndex = index;
        const item = this.userItems[index];
        this.$refs.mdlPriceSearch.show(
          // sellPrice, clientId, clientName, dispatchId, invoiceId
          item.DispatchSellPrice,
          this.userData.ClientID,
          this.userData.Client,
          item.DispatchID,
          this.userData.ID
        );
      }
    }
  }
}
</script>