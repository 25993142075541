import AppCatalog from "./AppCatalog.js";
import AppFormatter from "./App_Formatter.js";
import OutputType from "../Input/OutputType.js";
import OutputTypeEnum from "../Input/OutputTypeEnum.js";

export default {
  // returns {OutputTypeEnum}
  getType(field, catalog) {
    if (catalog[field].Output !== undefined) {
      return catalog[field].Output;
    }

    if (catalog[field].Type !== undefined) {
      const fieldType = catalog[field].Type;
      return OutputType[fieldType].Default;
    }

    return OutputTypeEnum.Text;
  },
  getValue_Single(value, field, data, catalog) {
    let relatedValue;
    const datatype = this.getType(field, catalog);

    switch (datatype) {
      // boolean
      case OutputTypeEnum.ActiveInactive:
        return AppFormatter.getActiveInactive(value);
      case OutputTypeEnum.Boolean:
        return AppFormatter.getBoolean(value);
      case OutputTypeEnum.OpenClosed:
        return AppFormatter.getOpenClosed(value);
      case OutputTypeEnum.YesNo:
        return AppFormatter.getYesNo(value);

      // credential
      case OutputTypeEnum.Password:
        return AppCatalog.Format.PasswordMask;

      // date & time
      case OutputTypeEnum.ShortDate:
        return AppFormatter.getShortDate(value);
      case OutputTypeEnum.MediumDate:
        return AppFormatter.getMediumDate(value);
      case OutputTypeEnum.LongDate:
        return AppFormatter.getLongDate(value);
      case OutputTypeEnum.ShortMonthly:
        return AppFormatter.getShortMonthly(value);
      case OutputTypeEnum.LongMonthly:
        return AppFormatter.getLongMonthly(value);
      case OutputTypeEnum.ShortDateTime:
        return AppFormatter.getShortDateTime(value);
      case OutputTypeEnum.MediumDateTime:
        return AppFormatter.getMediumDateTime(value);
      case OutputTypeEnum.LongDateTime:
        return AppFormatter.getLongDateTime(value);

      // id
      case OutputTypeEnum.ID:
        relatedValue = catalog[field].RelatedValue;
        return data[relatedValue];

      // number
      case OutputTypeEnum.Accounting:
        return AppFormatter.getAccounting(value);
      case OutputTypeEnum.Accounting2D:
        return AppFormatter.getAccounting2D(value);
      case OutputTypeEnum.Currency:
        return AppFormatter.getCurrency(value);
      case OutputTypeEnum.Decimal:
        return AppFormatter.getDecimal(value);
      case OutputTypeEnum.Decimal2D:
        return AppFormatter.getDecimal2D(value);
      case OutputTypeEnum.Integer:
        return AppFormatter.getInteger(value);
      case OutputTypeEnum.Percent:
        return AppFormatter.getPercent(value);

      // url
      case OutputTypeEnum.Url:
        return AppFormatter.getExternalUrl(value);
    }

    // default: OutputTypeEnum.Text, OutputTypeEnum.Email, OutputTypeEnum.Phone
    return value;
  }
}