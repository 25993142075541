<template>
  <div>
    <kst-output print-mode field="RecapNumber" :data="data" :catalog="SalesRecapPrintCatalog"/>
    <kst-output print-mode field="Date" :data="data" :catalog="SalesRecapPrintCatalog"/>
  </div>
</template>

<script>
import SalesRecapPrintCatalog from "./SalesRecapPrintDetailsCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    SalesRecapPrintCatalog: SalesRecapPrintCatalog
  })
}
</script>