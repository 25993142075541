<template>
  <kst-modal
    class="kst-dispatchspecialprice-search"
    :width="1000"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        :stockOptions="stockOptions"
        @submit="handleSearch_Submit"
        @reset="handleSearch_Reset"
      />
    </kst-section>

    <kst-section search-result-mode select-one-help>
      <List
        :failureList="failureList"
        :isListLoading="isListLoading"
        :items="items"
        :userData="userData"
      />
    </kst-section>
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterModel from "@/router/RouterModel.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../DSPModel.js";
import SearchCatalog from "./DSPSearchCatalog.js";
import Services from "../DSPServices.js";

import List from "./DSPSearch_List";
import Search from "./DSPSearch_Search";

export default {
  mixins: [AxiosMixin, SnackbarMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    userData: {},
    searchData: {},
    stockOptions: {},
    items: [],
    active: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return RouterModel.getNavSubTitle(Model.Item.Module, Model.Item.ModuleType.Search);
    }
  },
  methods: {
    init(warehouseId) {
      this.initReset();

      if (warehouseId) {
        this.searchData.WarehouseID = warehouseId;
      }
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    initReset() {
      this.userData = {
        selected: null
      };

      this.searchData = {
        Search: "",
        StockID: Model.Stock.Available.ID,
        // hidden
        WarehouseID: null
      };

      this.stockOptions = Model.createStockOptions(SearchCatalog.StockID.Label);
    },

    show(warehouseId) {
      this.init(warehouseId);
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = {
        warehouse: searchData.WarehouseID,
        withstock: 1,
        availstock: this.$kst.Search.getValue(searchData.StockID)
      };
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
      }

      const config = Services.getDispatchSpecialPrice(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.loadList_End();
      this.$emit(this.$kst.Enum.Event.Load, data);
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.init();
    }
  }
}
</script>