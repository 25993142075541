import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Expense,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    Search: AppModuleType.Search
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createOptions(categoryList, optionAllText) {
    return this.helper.createOptions(categoryList, optionAllText);
  },

  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },

  populateData(data) {
    return this.helper.populateDetails(data);
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  helper: {
    createDetails() {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        ExpenseNumber: "",
        IsAutoNumber: true,
        Date: App.In.getDateToday(),
        SubCategoryID: null,
        Total: "",
        Comment: "",
        CategoryName: "",
        // defined by system
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID(),
      };
    },

    createOptions(items, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
          listOptions.allActive = true;
          listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
    
    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.Date = App.In.getDate(details.Date);
      data.ExpenseNumber = App.In.getString(details.ExpenseNumber);
      data.SubCategoryID = App.In.getInteger(details.SubCategoryID);
      data.SubCategoryName = App.In.getString(details.SubCategoryName);
      data.Total = App.In.getInteger(details.Total);
      data.Comment = App.In.getString(details.Comment);
    },

    populateDetails(data) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        ExpenseNumber: data.IsAutoNumber ? null : App.Out.getString(data.ExpenseNumber),
        Date: App.Out.getDate(data.Date),
        SubCategoryID: data.SubCategoryID,
        Total: App.Out.getInteger(data.Total),
        Comment: App.Out.getString(data.Comment),
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID
      }
    }
  }
}