import InputType from "@/services/Input/InputType";
import OutputType from "../../../services/Input/OutputType";

export default {
  ID: {
    Label: "ID",
    Type: InputType.ID,
    Required: true
  },
  CategoryID: {
    Label: "Kategori",
    Type: InputType.ID,    
    Input: InputType.Select,
    Required: true,
    RelatedValue: "CategoryName"
  },
  CategoryName: {
    Label: "Kategori",
    Type: InputType.Text,
    ReadOnly: true
  },
  Name: {
    Label: "Jenis Pengeluaran",
    Type: InputType.Text,
    Required: true,
    MaxLength: 100
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.ActiveInactive
  },
  Status: {
    Label: "Status",
    Type: InputType.ID,
    ReadOnly: true
  },
}