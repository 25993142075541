import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ModuleName: {
    Label: "Transaksi",
    Type: InputType.Text,
    ReadOnly: true
  },
  AutoNumberTypeID: {
    Label: "Format",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "AutoNumberTypeFormat"
  },
  PrefixSuffix: {
    Label: "Awalan/Akhiran",
    Type: InputType.Text,
    ReadOnly: true
  },
  Year: {
    Label: "Tahun",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Text
  },
  Month: {
    Label: "Bulan",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Text
  },
  Counter: {
    Label: "Perulangan",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Text
  },
  CounterLength: {
    Label: "Panjang Perulangan",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Text
  }
}