<template>
  <kst-section items-mode class="mt-5">
    <StockSearch show-hpp
      ref="mdlStockSearch"
      @submit="handleItem_Submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.Item.Module.FullName"
          :moduleType="Model.Item.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.Item.Module.FullName"
            :moduleType="Model.Item.ModuleType.New"
            @click="handleAdd_Item"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID === null">
      <kst-warning :items="itemsWarning"/>
    </template>
    <template v-else>
      <List :userItems="userItems"/>
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Info from "../HppAdjInfo.js";
import Model from "../HppAdjModel.js";

import StockSearch from "@/components/Stock/Search/StockSearch";
import List from "./HppAdjNew_Items_List";

import Services from "../HppAdjServices.js";

export default {
  mixins: [AxiosMixin],
  components: {
    StockSearch,
    List
  },
  props: {
    userData: Object,
    userItems: Array,
    errorsAddItem: Array
  },
  data: () => ({
    Model: Model,
    rowIndex: null,
    item: {},
    failureInit: null
  }),
  computed: {
    itemsWarning() {
      return [Info.Message.New_Items];
    }
  },
  methods: {
    LoadPackaging(itemId) {
      const config = Services.getPackagingList(itemId);
      this.runAxios(config, null, this.LoadPackaging_Success, this.LoadPackaging_Error);
    },
    LoadPackaging_Success(data) {
      let packagingList = { "Packaging": [] };
      for (const packaging of data) {
        if (packaging.IsActive === 1) {
          packagingList.Packaging.push(packaging);
        }
      }

      Model.setItemByItemDetails(this.item, packagingList);
      Model.setDefaultPackaging(this.item);
      this.userItems.push(this.item);
    },
    LoadPackaging_Error(message) {
      this.failureInit = message;
    },

    handleAdd_Item() {
      this.$refs.mdlStockSearch.show(this.userData.WarehouseID);
    },

    handleItem_Submit(row) {
      let validate = Model.validateItem(row, this.userItems);
      if(validate){
        let newItem = Model.createItem();
        Model.setItemByStock(newItem, row);
        this.item = {};
        this.item = newItem;
        this.LoadPackaging(row.ItemID);
      }else{
        let error = "Barang " + row.FullName + " telah di pilih.";
        this.errorsAddItem.push(error);
      }
    }
  }
}
</script>