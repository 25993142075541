<template>
  <kst-container class="kst-stockopname-list">
    <kst-form
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :loading="isLoading"
      :title="title"
      :warnings="formWarnings"
    >
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>

      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          :statusOptions="statusOptions"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          <!--
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.PrintList"
          />
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
            @click="handleExport"
          />
          -->
        </template>

        <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import SearchCatalog from "./StockOpnameSearchCatalog.js";
import Model from "../StockOpnameModel.js";
import Services from "../StockOpnameServices.js";

import List from "./StockOpnameList_List";
import Search from "./StockOpnameList_Search";

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    searchData: {},
    warehouseOptions: {},
    statusOptions: {},
    items: [],
    isLoading: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.formWarnings = null;
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      // search
      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      };
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadListData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadListData() {
      const config = Services.getStockOpnameListData();
      this.runAxios(config, null,
        this.loadListData_Success,
        this.loadListData_Error,
        this.loadListData_Invalid
      );
    },
    loadListData_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, SearchCatalog.WarehouseID.Label
      );
      this.loadList(this.loadCounter, this.searchData);
      this.initLoad_End();
    },
    loadListData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadListData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = {};
      if (searchData) {
        options.search = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.startdate = this.$kst.Out.getDate(this.searchData.Date[0]);
        options.enddate = this.$kst.Out.getDate(this.searchData.Date[1]);
      }

      const config = Services.getStockOpnameList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    }
  }
}
</script>