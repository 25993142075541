<template>
  <kst-container class="kst-invoice-details">
    <kst-form
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :loading="isLoading"
      :title="title"
      :warnings="formWarnings"
    >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
        <b-tab-item :label="tabs.Details">
          <Details :data="data"/>
          <Items :data="data" :items="data.Items"/>
          <Total :data="data"/>
        </b-tab-item>
        <b-tab-item :label="tabs.DeliveryList">
          <template v-if="data.IsNeedDelivery === 1">
            <ItemsSummary :items="itemsSummary"/>
            <DeliveryRelated
              :hide-action="actionDeliveryHidden"
              :invoiceId="this.data.ID"
              :items="deliveryList"
            />
          </template>
          <template v-else>
            <kst-warning :items="deliveryWarning"/>
          </template>
        </b-tab-item>
        <b-tab-item :label="tabs.RtnInvoiceList">
          <RtnInvoiceRelated
            :invoiceId="this.data.ID"
            :items="data.ReturnInvoice"
          />
        </b-tab-item>
        <b-tab-item v-if="access.UI.Profit !== false" :label="tabs.Profit">
          <Profit :data="data"/>
        </b-tab-item>
        <b-tab-item :label="tabs.SalesRecap">
          <template v-if="this.data.SalesRecapID !== null">
            <SalesRecapRelated
            :data="data.SalesRecapDetails"
          />
          </template>
          <template v-else>
            <kst-table-empty>
            </kst-table-empty>
          </template>
        </b-tab-item>
      </b-tabs>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";
import Services from "../InvoiceServices.js";

import Action from "./InvoiceDetails_Action";
import Details from "./InvoiceDetails_Details";
import Items from "./InvoiceDetails_Items";
import ItemsSummary from "./InvoiceDetails_ItemsSummary";
import Profit from "./InvoiceDetails_Profit";
import Total from "./InvoiceDetails_Total";
import DeliveryRelated from "@/components/Delivery/Related/DeliveryRelated";
import RtnInvoiceRelated from "@/components/RtnInvoice/Related/RtnInvoiceRelated";
import SalesRecapRelated from "@/components/SalesRecap/Related/SalesRecapRelated";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details,
    Items,
    ItemsSummary,
    Profit,
    Total,
    DeliveryRelated,
    RtnInvoiceRelated,
    SalesRecapRelated
  },
  data: () => ({
    access: {},
    data: {},
    itemsSummary: [],
    deliveryList: [],
    isLoading: false,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      DeliveryList: Info.Tabs.DeliveryList,
      RtnInvoiceList: Info.Tabs.RtnInvoiceList,
      Profit: Info.Tabs.Profit,
      SalesRecap: Info.Tabs.SalesRecapList,
    },
    // error
    formWarnings: null,
    failureInit: null
  }),
  computed: {
    actionDeliveryHidden() {
      return this.data.Status === Model.Status.Closed.ID;
    },
    deliveryWarning() {
      return [Info.Message.Details_NoDelivery];
    },
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }

      this.access = Model.createAccess();
      this.data = {};
      this.itemsSummary = [];

      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetails(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails(id) {
      const config = Services.getInvoiceDetailsData(id, { withdelivery: 1, withdeliveryitems: 1, withrecap: 1 });
      this.runAxios(config, null,
        this.loadDetails_Success,
        this.loadDetails_Error,
        this.loadDetails_Invalid
      );
    },
    loadDetails_Success(data) {
      this.access = data.Access;
      this.setDetails(data.Details);
      Model.setDetailsBySpecialPrice(this.data, data);
      this.initLoad_End();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadDetails_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    setDetails(data) {
      this.data = data;
      Model.setDetailsByStatus(this.data);
      Model.setDetailsByProfit(this.data);
      Model.updateItemsByProfit(this.data.Items);

      for (const item of this.data.Items) {
        Model.updateItemByPackaging(item);
        Model.updateItemSpecialPrice(item);
      }

      if (data.IsNeedDelivery) {
        this.deliveryList = data.DeliveryList;
        this.itemsSummary = Model.getItemsSummary(this.data, this.deliveryList);
      }
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>