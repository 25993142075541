var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-print-table',{attrs:{"data":_vm.items}},[_c('b-table-column',{attrs:{"field":"InvoiceDate","label":_vm.SalesRecapPrintCatalog.InvoiceDate.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SalesRecapPrintCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"SONumber","label":_vm.SalesRecapPrintCatalog.SONumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SalesRecapPrintCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ClientID","label":_vm.SalesRecapPrintCatalog.ClientID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SalesRecapPrintCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"PaymentTypeID","label":_vm.SalesRecapPrintCatalog.PaymentTypeID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SalesRecapPrintCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price has-text-weight-bold","field":"Total","label":_vm.SalesRecapPrintCatalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.SalesRecapPrintCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }