import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import SpecialPriceAdjDetails from "./Details/SpecialPriceAdjDetails";
import SpecialPriceAdjList from "./List/SpecialPriceAdjList";
import SpecialPriceAdjPrintDetail from "./PrintDetail/SpecialPriceAdjPrintDetail";
import SpecialPriceAdjNew from "./New/SpecialPriceAdjNew";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.Details),
    List: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List),
    New: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.New),
    PrintDetail: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.Details),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.Details),
        component: SpecialPriceAdjDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.SpecialPriceAdj)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.List),
        component: SpecialPriceAdjList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.SpecialPriceAdj)
        }
      },{
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.New),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.New),
        component: SpecialPriceAdjNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.SpecialPriceAdj)
        }
      }
    ];
  },

  getRootRoutes() {
    return [{
      path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
      name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
      component: SpecialPriceAdjPrintDetail,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
        moduleName: RouterModel.getModule(AppModule.SpecialPriceAdj)
      }
    }];
  }
}