var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{staticClass:"mt-5",attrs:{"items-mode":""},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [(_vm.data.SOID !== null)?[_c('kst-button',{staticClass:"is-primary",attrs:{"action-mode":"","tag":"button","label":_vm.Model.ItemInvoice.Title,"moduleType":_vm.Model.ModuleType.New},on:{"click":_vm.handleAdd_Item}})]:_vm._e()]},proxy:true},{key:"bottom",fn:function(){return [(_vm.items.length > 0)?[(_vm.data.SOID !== null)?[_c('kst-button',{staticClass:"is-primary",attrs:{"action-mode":"","tag":"button","label":_vm.Model.ItemInvoice.Title,"moduleType":_vm.Model.ModuleType.New},on:{"click":_vm.handleAdd_Item}})]:_vm._e()]:_vm._e()]},proxy:true}])},[_c('ItemInvoiceSearch',{ref:"mdlItemInvoiceSearch",attrs:{"items":_vm.itemInvoice},on:{"submit":_vm.handleItemInvoice_Submit}}),(_vm.data.SOID === null)?[_c('kst-warning',{attrs:{"items":_vm.detailWarning}})]:[_c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.items},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.Item.Module.FullName}},[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})],1)]}}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"Name","label":_vm.DeliveryItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","index":index,"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-tooltip',{attrs:{"info-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"info-mode":"","items":_vm.Model.getItemInfo_Edit(row)}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"info-mode":""}})],1)]}}])}),_c('b-table-column',{attrs:{"field":"Packaging","label":_vm.DeliveryItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","index":index,"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"AvailableQuantity","label":_vm.DeliveryItemCatalog.AvailableQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","index":index,"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Quantity","label":_vm.DeliveryItemCatalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog,"index":index},on:{"input":function($event){return _vm.handleQuantity(row)},"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"field":"Weight","label":_vm.DeliveryItemCatalog.Weight.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","index":index,"field":column.field,"data":row,"catalog":_vm.DeliveryItemCatalog}})]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }