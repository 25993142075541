import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import StockTrfDetails from "./Details/StockTrfDetails";
import StockTrfList from "./List/StockTrfList";
import StockTrfNew from "./New/StockTrfNew";
import StockTrfPrintDetails from "./PrintDetails/StockTrfPrintDetails";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockTrf, AppModuleType.Details),
    List: RouterModel.getPath(AppModule.StockTrf, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockTrf, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.StockTrf, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.Details),
        component: StockTrfDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.StockTrf)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.List),
        component: StockTrfList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.StockTrf)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.New),
        component: StockTrfNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.StockTrf)
        }
      },
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.PrintDetails),
        component: StockTrfPrintDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.PrintDetails),
          moduleName: RouterModel.getModule(AppModule.StockTrf)
        }
      }
    ];
  }
}