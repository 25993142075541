import AppCatalog from "@/services/App/AppCatalog.js";
import Catalog from "./PaymentTypeCatalog.js";

export default {
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_Name:
      "Tidak diperkenankan mengubah " + Catalog.Name.Label + ".",
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  }
}