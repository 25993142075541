<template>
  <kst-container class="kst-invoice-edit">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Sales
          :userData="userData"
        />
        <Details
          :userData="userData"
          :userItems="userItems"
        />
        <Delivery :userData="userData" :deliveryList="deliveryList"/>
        <Items
          :salesData="salesData"
          :userData="userData"
          :userItems="userItems"
          :deliveryList="deliveryList"
          :invoiceItemGroup="invoiceItemGroup"
        />
        <Total :data="userData"/>
        <Details2
          class="mt-5"
          :userData="userData"
          :userItems="userItems"
          :discTypeOptions="discTypeOptions"
        />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";
import Router from "../InvoiceRouter.js";
import Services from "../InvoiceServices.js";

import Delivery from "./InvoiceEdit_Delivery";
import Details from "./InvoiceEdit_Details";
import Details2 from "./InvoiceEdit_Details2";
import Items from "./InvoiceEdit_Items";
import Sales from "./InvoiceEdit_Sales";
import Total from "./InvoiceEdit_Total";

export default {
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  components: {
    Delivery,
    Details,
    Details2,
    Items,
    Sales,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    salesData: {},
    isLoading: false,
    saveLoading: null,
    discTypeOptions: {},
    warehouseList: [],
    invoiceItemGroup: {},
    deliveryList: [],
    totalItemInProgress: 0,
    cancelUrl: Router.Path.List,
    loadingComponent: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    failureSales: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.routeLeaveEnabled = false;
        this.formWarnings = [Info.Message.Edit_ParamIncomplete];
        return;
      }

      this.userData = Model.createDetails();
      this.userItems = [];
      this.salesData = null;
      this.discTypeOptions = Model.createDiscTypeOptions();

      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;
      this.loadSpecialPrice(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadEditData(id) {
      const config = Services.getInvoiceEditData(id);
      this.runAxios(config, null, this.loadEditData_Success, this.loadEditData_Error);
    },
    loadEditData_Success(data) {
      this.loadDispatchSpecialPrice(data);
    },
    loadEditData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadSpecialPrice(id) {
      const options = { active: 1 }
      const config = Services.getSpecialPriceList(options);
      this.runAxios(config, id, this.loadSpecialPrice_Success, this.loadSpecialPrice_Error);
    },
    loadSpecialPrice_Success(data, id) {
      Model.setSpecialPriceOptions(data);
      this.loadEditData(id);
    },
    loadSpecialPrice_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadDispatchSpecialPrice(userData) {
      const options = {
        warehouse: userData.Details.WarehouseID,
        availstock: 1
      };
      const config = Services.getDispatchSpecialPrice(options);
      this.runAxios(config, userData,
        this.loadDispatchSpecialPrice_Success,
        this.loadDispatchSpecialPrice_Error
      );
    },
    loadDispatchSpecialPrice_Success(data, userData) {
      Model.setDispatchSpecialPrice(data);

      const invoiceDetails = userData.Details;

      // validation
      if (invoiceDetails.Status === Model.Status.Closed.ID) {
        this.formWarnings = [Info.Message.Edit_StatusClosed];
        this.initLoad_End();
        return;
      }

      if (invoiceDetails.IsRecap === 1) {
        this.formWarnings = [Info.Message.Edit_IsRecap];
        this.initLoad_End();
        return;
      }

      // invoice
      this.invoiceItemGroup = this.groupInvoiceItem(invoiceDetails.Items);
      Model.setItemsByData(this.userItems, invoiceDetails.Items, this.invoiceItemGroup);
      Model.setDetailsByData(this.userData, invoiceDetails);
      Model.setDetailsBySpecialPrice(this.userData, userData);
      Model.updateDetails(this.userData, this.userItems);

      // delivery
      this.deliveryList = Model.getDeliveryItems(userData.DeliveryList);

      // sales
      const SalesDetails = userData.SalesDetails;
      const Items = userData.Details.Items;
      if(this.userData.SalesDraftID !== null) {
        this.updateBySales(SalesDetails, Items);
      }

      this.initLoad_End();
    },
    loadDispatchSpecialPrice_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    groupInvoiceItem(items) {
      let result = {};
      for (const item of items) {
        if (Object.prototype.hasOwnProperty.call(result, item.DispatchID)) {
          result[item.DispatchID] += item.TotalRequestedQuantity;
        }
        else {
          result[item.DispatchID] = item.TotalRequestedQuantity;
        }
      }

      return result;
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.putInvoice(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },

    updateBySales(salesDetails, invoiceItems) {
      this.salesData = salesDetails;
      Model.updateSalesDetails_Edit(this.salesData, invoiceItems);
    }
  }
}
</script>