<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="OrderDate"
      :label="Catalog.OrderDate.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ClientName"
      :label="Catalog.ClientName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchName"
      :label="Catalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="has-text-weight-bold"
      field="QtyDiff"
      width="150"
      :label="Catalog.QtyDiff.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column cell-class="ks-col-qty" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <template #action="{ row }">
      <kst-tooltip search-mode hover-only :content="Model.Report.TitleSearch" v-if="row.SOID">
        <kst-button search-mode is-round @click="handleSearchItem(row.SOID)"/>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import Model from "../StockAvailabilityModel.js";
import Catalog from "../StockAvailabilityCatalog.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SalesRouter from "@/components/Sales/SalesRouter.js";

export default {
  mixins: [RouterMixin],
  props: {
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  methods: {
    handleSearchItem(transactionId) {
      const urlParam = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: transactionId
      });

      this.openTab(SalesRouter.Path.Details + urlParam);
    }
  }
}
</script>