<template>
  <kst-modal
    class="kst-sales-search"
    :width="900"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @submit="handleSearch_Submit"
        @reset="handleSearch_Reset"
      />
    </kst-section>

    <kst-section search-result-mode select-one-help>
      <List
        :failureList="failureList"
        :isListLoading="isListLoading"
        :items="items"
        :userData="userData"
      />
    </kst-section>
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterModel from "@/router/RouterModel.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../SalesModel.js";
import SalesSearchCatalog from "./SalesSearchCatalog.js";
import Services from "../SalesServices.js";

import Search from "./SalesSearch_Search";
import List from "./SalesSearch_List";

export default {
  mixins: [AxiosMixin, SnackbarMixin],
  components: {
    Search,
    List
  },
  props: {
    warehouseList: Array
  },
  data: () => ({
    userData: {},
    searchData: {},
    warehouseOptions: {},
    items: [],
    active: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return RouterModel.getNavSubTitle(Model.Module, Model.ModuleType.Search);
    }
  },
  methods: {
    init() {
      this.userData = {
        selected: null
      };

      this.initSearch();
      this.loadList(this.loadCounter, this.searchData);
    },
    initSearch() {
      this.warehouseOptions = Model.getWarehouseOptions(
        this.warehouseList, SalesSearchCatalog.WarehouseID.Label
      );

      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      };
    },

    show() {
      this.init();
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = { status: Model.Status.Open.ID };
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.startDate = this.$kst.Out.getDate(this.searchData.Date[0]);
        options.endDate = this.$kst.Out.getDate(this.searchData.Date[1]);
      }

      const config = Services.getSalesList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    }
  }
}
</script>