var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.userItems},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.StockReductionItem.Module.FullName}},[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})],1)]}},{key:"empty-body",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"userItems","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kst-error',{attrs:{"array-blank-mode":!!errors}})]}}])})]},proxy:true}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e(),_c('kst-input',{staticClass:"is-hidden",attrs:{"box-off":"","field-only":"","field":"DispatchID","data":row,"catalog":_vm.StockReductionItemCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"field":"DispatchName","label":_vm.StockReductionItemCatalog.DispatchName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-tooltip',{attrs:{"info-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"info-mode":"","items":_vm.Model.getItemInfo(row)}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"info-mode":""}})],1)]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"Packaging","label":_vm.StockReductionItemCatalog.PackagingName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"Qty","label":_vm.StockReductionItemCatalog.Qty.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog,"index":index},on:{"input":function($event){return _vm.handleQuantity(row)},"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-price","field":"SellPrice","label":_vm.StockReductionItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])}),(_vm.userData.SpecialPriceCount > 0)?[_c('b-table-column',{attrs:{"cell-class":"ks-col-specialprice","field":"SpecialPriceID","label":_vm.StockReductionItemCatalog.SpecialPriceID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}],null,false,4029692617)}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"SellPriceNetto","label":_vm.StockReductionItemCatalog.SellPriceNetto.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}],null,false,4029692617)})]:_vm._e(),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Total","label":_vm.StockReductionItemCatalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockReductionItemCatalog}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }