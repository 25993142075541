<template>
  <kst-table action paginated view-mode
    :data="items"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="DispatchImageSrc"
      :label="SalesItemCatalog.DispatchImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="SalesItemCatalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="SalesItemCatalog.SKU.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="QtyAvailable"
      :label="SalesItemCatalog.QtyAvailable.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="SalesItemCatalog.Packaging.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="SalesItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import SalesItemCatalog from "../SalesItemCatalog.js";

export default {
  props: {
    items: Array,
    userData: Object
  },
  data: () => ({
    SalesItemCatalog: SalesItemCatalog
  })
}
</script>