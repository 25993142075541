<template>
  <kst-form v-if="salesId" no-bezel class="pb-5">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
        :urlParams="urlParams"
      />
    </template>
  </kst-form>
</template>

<script>
import Model from "../InvoiceModel.js";

export default {
  props: {
    salesId: Number
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.SalesID]: this.salesId
      });
    }
  },
}
</script>