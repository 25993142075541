import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import WarehouseNew from "./New/WarehouseNew";
import WarehouseDetails from "./Details/WarehouseDetails";
import WarehouseList from "./List/WarehouseList";
import WarehouseEdit from "./Edit/WarehouseEdit";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.Warehouse, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Warehouse, AppModuleType.New),
    Edit: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Edit),
    Details: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Details),
    Print: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.Details),
        component: WarehouseDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Warehouse)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.List),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.List),
        component: WarehouseList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Warehouse),
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.New),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.New),
        component: WarehouseNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Warehouse),
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.Edit),
        component: WarehouseEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Warehouse),
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}