import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ExpenseNumber: {
    Label: "Nomor Pengeluaran",
    Type: InputType.Text,
    MaxLength: 50,
    Unique: true,
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true
  },
  SubCategoryID: {
    Label: "Jenis Pengeluaran",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "SubCategoryName"
  },
  SubCategoryName: {
    Label: "Jenis Pengeluaran",
    Type: InputType.Text,
    ReadOnly: true,
    Required: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    Required: true,
    MaxLength: 11,
    MinValue: 1,
    Output: OutputType.Integer.Currency
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  LastUpdatedBy: {
    Label: AppCatalog.Field.LastUpdatedBy.Label,
    Type: AppCatalog.Field.LastUpdatedBy.Type
  },
  LastUpdatedByID: {
    Label: AppCatalog.Field.LastUpdatedByID.Label,
    Type: AppCatalog.Field.LastUpdatedByID.Type,
    RelatedValue: AppCatalog.Field.LastUpdatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.LastUpdatedByID.RelatedModule
  },
  LastUpdatedDate: {
    Label: AppCatalog.Field.LastUpdatedDate.Label,
    Type: AppCatalog.Field.LastUpdatedDate.Type,
    ReadOnly: AppCatalog.Field.LastUpdatedDate.ReadOnly,
    Output: AppCatalog.Field.LastUpdatedDate.Output,
  },

  // support
  IsAutoNumber: {
    Label: "Nomor Pengeluaran",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}