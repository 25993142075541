<template>
  <kst-section items-mode class="mt-5">
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="ItemID"
        :label="SpecialPriceAdjItemCatalog.DispatchName.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.getItemInfo_Details(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-specialpriceadj-details-Packaging"
        field="Packaging"
        :label="SpecialPriceAdjItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-specialpriceadj-details-SellPrice"
        field="SellPrice"
        :label="SpecialPriceAdjItemCatalog.SellPrice.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>
    
      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-specialpriceadj-details-SpecialPriceName"
        field="SpecialPriceName"
        :label="SpecialPriceAdjItemCatalog.SpecialPriceName.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="kst-specialpriceadj-details-DiscountedValue"
        field="DiscountedValue"
        :label="SpecialPriceAdjItemCatalog.DiscountedValue.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Qty"
        :label="SpecialPriceAdjItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="kst-specialpriceadj-details-Total"
        field="Total"
        :label="SpecialPriceAdjItemCatalog.Total.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="SpecialPriceAdjItemCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import SpecialPriceAdjItemCatalog from "../SpecialPriceAdjItemCatalog.js";
import Model from "../SpecialPriceAdjModel.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    SpecialPriceAdjItemCatalog: SpecialPriceAdjItemCatalog,
    Model: Model
  })
}
</script>