import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import SalesRecapDetails from "./Details/SalesRecapDetails";
import SalesRecapList from "./List/SalesRecapList";
import SalesRecapNew from "./New/SalesRecapNew";
import SalesRecapPrintDetails from "./PrintDetails/SalesRecapPrintDetails";
import SalesRecapExportDetails from "./ExportDetails/SalesRecapExportDetails"


export default {
  Path: {
    Details: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.Details),
    ExportDetails: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.List),
    New: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.PrintDetails),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.Details),
        name: RouterModel.getName(AppModule.SalesRecap, AppModuleType.Details),
        component: SalesRecapDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesRecap, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.SalesRecap)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.SalesRecap, AppModuleType.ExportDetails),
        component: SalesRecapExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesRecap, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.SalesRecap)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.List),
        name: RouterModel.getName(AppModule.SalesRecap, AppModuleType.List),
        component: SalesRecapList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesRecap, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.SalesRecap)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.New),
        name: RouterModel.getName(AppModule.SalesRecap, AppModuleType.New),
        component: SalesRecapNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesRecap, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.SalesRecap)
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.SalesRecap, AppModuleType.PrintDetails),
        component: SalesRecapPrintDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesRecap, AppModuleType.PrintDetails),
          moduleName: RouterModel.getModule(AppModule.SalesRecap)
        }
      }
    ];
  }
}