<template>
  <kst-table input-mode :data="userDispatches">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ row }"
      field="Variants"
      :label="DispatchCatalog.Variants.Label"
    >
      <div class="k-control-py">{{ getVariants(row) }}</div>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="ImageSrc"
      :label="DispatchCatalog.ImageSrc.Label"
    >
      <kst-image :src="row[column.field]" :isLocal="row.ImageIsLocal" :index="index"
        @input="handleImageInput" @delete="handleImageDelete"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kst-item-new-dispatch-list-FullName"
      field="FullName"
      :label="DispatchCatalog.FullName.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DispatchCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kst-item-new-dispatch-list-SKU"
      field="SKU"
      :label="DispatchCatalog.SKU.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DispatchCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kst-item-new-dispatch-list-Barcode"
      field="Barcode"
      :label="DispatchCatalog.Barcode.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DispatchCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kst-item-new-dispatch-list-SellPrice"
      field="SellPrice"
      :label="DispatchCatalog.SellPrice.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DispatchCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kst-item-new-dispatch-list-Weight"
      field="Weight"
      :label="DispatchCatalog.Weight.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DispatchCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kst-item-new-dispatch-list-Description"
      field="Description"
      :label="DispatchCatalog.Description.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DispatchCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import DispatchCatalog from "../DispatchCatalog.js";
import Model from "../ItemModel.js";

export default {
  props: {
    access: Object,
    userDispatches: Array
  },
  data: () => ({
    Model: Model,
    DispatchCatalog: DispatchCatalog
  }),
  methods: {
    handleImageInput(imgSrc, imgText, index) {
      this.userDispatches[index].ImageSrc = imgSrc;
      this.userDispatches[index].Image64 = imgText;
      this.userDispatches[index].ImageIsLocal = true;
    },
    handleImageDelete(index) {
      this.userDispatches[index].ImageSrc = "";
      this.userDispatches[index].Image64 = "";
      this.userDispatches[index].ImageIsLocal = true;
    },
    handleRowErrors(errors, field, index) {
      Model.setDispatchErrors(this.userDispatches[index], field, errors);
    },

    getVariants(row) {
      return Model.getDispatchVariants(row);
    }
  }
}
</script>