<template>
  <hr class="kst-divider" :class="theClass"/>
</template>

<script>
export default {
  props: {
    // mode: ONLY select 1 (one)
    columnsMode: Boolean,
    failureMode: Boolean
  },
  computed: {
    theClass() {
      if (this.columnsMode) {
        return "kst-divider-columns has-background-grey-lighter mt-0 mb-4";
      }
      if (this.failureMode) {
        return "kst-divider-failure has-background-warning-dark my-4";
      }

      return undefined;
    }
  }
}
</script>