import TestExcel from "./Excel/TestExcel";

export default {
  Path: {
    Excel: "/test/excel"
  },

  getRoutes() {
    return [
      {
        path: "/test/excel",
        name: "Excel (Export)",
        component: TestExcel,
        meta: {
          title: "Excel (Export)",
          moduleName: "Excel"
        }
      }
    ];
  }
}