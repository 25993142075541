import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./SalesRecapCatalog.js";
import Model from "./SalesRecapModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.Details),
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          Invoice: AppModuleType.List.Name + " " + AppModule.Invoice.FullName
        },
        // Section: {
        //   SalesItem: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        // },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          Invoice: AppModuleType.List.Name + " " + AppModule.Invoice.FullName,
        }
      }
    },
    List: {}
  },
  Message: {
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.Invoice.Module.FullName + ".",
    New_Cashier:
      "Tidak diperkenankan mengubah " + Catalog.CashierID.Label + ", " +
      "saat sudah memiliki " + Model.Invoice.Module.FullName + ".",
    New_Invoices:
      "Harap mengisi " + Catalog.WarehouseID.Label + " dan " + 
      Catalog.CashierID.Label + ", " + "untuk menampilkan daftar " + 
      Model.Invoice.Module.FullName + ".",
    PrintDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    InvoiceList: Model.Invoice.ModuleType.List.Name + " " + Model.Invoice.Module.FullName
  }
}