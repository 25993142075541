<template>
  <div class="kst-invoice-related">
    <kst-warning :items="formWarnings"/>
    <kst-failure list-mode :message="failureList"/>

    <template v-if="!failureList">
      <template v-if="!formWarnings">
        <Action :purchaseId="purchaseId"/>
        <Row :items="dataList" :isLoading="isLoading"/>
      </template>
    </template>
  </div>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
// import Info from "../ReceiveInfo.js";
import Model from "../ReceiveModel.js";
import Services from "../ReceiveServices.js";

import Action from "./ReceiveRelated_Action";
import Row from "./ReceiveRelated_Row";

export default {
  mixins: [AxiosMixin],
  components: {
    Action,
    Row
  },
  props: {
    purchaseId: {
      type: Number,
      required: true
    },
    items: Array
  },
  data: () => ({
    Model: Model,
    dataList: [],
    isLoading: false,
    // initial error
    formWarnings: null,
    failureList: null
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadList(this.purchaseId);
    },

    loadList(id) {
      this.failureList = null;
      this.isLoading = true;

      const config = Services.getReceivePurchase(id);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isLoading = false;
    },
    loadList_Success(data) {
      this.dataList = data;

      for (const data of this.dataList) {
        for (const item of data.Items) {
          Model.updateItemByPackaging(item);
        }
      }
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>