<template>
  <div class="kst-rtninvoice-related">
    <kst-warning :items="formWarnings"/>
    <kst-failure list-mode :message="failureList"/>

    <template v-if="!failureList">
      <template v-if="!formWarnings">
        <Action :invoiceId="invoiceId"/>
        <Row :items="dataList" :isLoading="isLoading"/>
      </template>
    </template>
  </div>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Info from "../RtnInvoiceInfo.js";
import Model from "../RtnInvoiceModel.js";
import Services from "../RtnInvoiceServices.js";

import Action from "./RtnInvoiceRelated_Action";
import Row from "./RtnInvoiceRelated_Row";

export default {
  mixins: [AxiosMixin],
  components: {
    Action,
    Row
  },
  props: {
    invoiceId: Number,
    items: Array
  },
  data: () => ({
    Model: Model,
    dataList: [],
    isLoading: false,
    // initial error
    formWarnings: null,
    failureList: null
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dataList = [];
      this.formWarnings = null;

      if (this.items) {
        this.dataList = this.items;
        this.updateList(this.dataList);
      }
      else if (this.invoiceId) {
        this.initLoad(this.invoiceId);
      }
      else {
        this.formWarnings = [Info.Message.Related_ParamIncomplete];
      }
    },
    initLoad(invoiceId) {
      this.failureList = null;
      this.isLoading = true;
      this.dataList = [];

      this.loadList(invoiceId);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadList(invoiceId) {
      const options = { invoice: invoiceId };
      const config = Services.getRtnInvoiceList(options);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      this.dataList = data;
      this.updateList(this.dataList);
      this.initLoad_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.initLoad_End();
    },

    updateList(dataList) {
      for (const data of dataList) {
        for (const item of data.Items) {
          Model.updateItemByPackaging(item);
        }
      }
    }
  }
}
</script>