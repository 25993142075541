<template>
  <kst-container class="kst-vendor-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :failure="failure"
        :failureInit="failureInit"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details :userData="userData"/>
        <Addresses :userAddresses="userAddresses"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";

import Router from "../VendorRouter.js";
import Model from "../VendorModel.js";

import Details from "./VendorNew_Details";
import Addresses from "./VendorNew_Addresses";

import Services from '../VendorServices.js';

export default {
  components: {
    Details,
    Addresses
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, SnackbarMixin, RouterMixin],
  data: () => ({
    userData: {},
    userAddresses: [],
    isLoading: false,
    warehouseOptions: {},
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userData = Model.createDetails();
    },

    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let data = Model.populateData(this.userData, this.userAddresses);
          this.saveData(data);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData_End() {
      this.closeLoading(this.saveLoading);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postVendor(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },

    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;
      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
        
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>