<template>
  <kst-container class="kst-stocktrf-details">
    <kst-form
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :loading="isLoading"
      :title="title"
      :warnings="formWarnings"
    >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
        <b-tab-item :label="tabs.Details">
          <Details :data="data"/>
          <Items :data="data" :items="data.Items"/>
          <Total :data="data"/>
        </b-tab-item>
      </b-tabs>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../StockTrfInfo.js";
import Model from "../StockTrfModel.js";
import Services from "../StockTrfServices.js";

import Action from "./StockTrfDetails_Action";
import Details from "./StockTrfDetails_Details";
import Items from "./StockTrfDetails_Items";
import Total from "./StockTrfDetails_Total";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details,
    Items,
    Total
  },
  data: () => ({
    data: {},
    itemsSummary: [],
    isLoading: false,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    },
    // error
    formWarnings: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }

      this.data = {};
      this.itemsSummary = [];

      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetailsData(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetailsData(id) {
      const config = Services.getStockTrfDetailsData(id);
      this.runAxios(config, null,
        this.loadDetailsData_Success,
        this.loadDetailsData_Error,
        this.loadDetailsData_Invalid
      );
    },
    loadDetailsData_Success(data) {
      this.setDetails(data.Details);
      this.initLoad_End();
    },
    loadDetailsData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadDetailsData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    setDetails(data) {
      this.data = data;

      for (const item of this.data.Items) {
        Model.updateItemByPackaging(item);
      }
    }
  }
}
</script>