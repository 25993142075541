export default {
  createDetails() {
    return this.helper.createDetails();
  },

  helper: {
    createDetails() {
      return {
        ID: null,
        Name: ""
      };
    }
  }
}