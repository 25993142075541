var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{attrs:{"title":_vm.summarySectionTitle}},[_c('kst-table',{attrs:{"view-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"field":"DispatchID","label":_vm.InvoiceItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"Packaging","label":_vm.InvoiceItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"kst-invoice-details-summary-Quantity","field":"RequestedQuantity","label":_vm.InvoiceItemCatalog.QtyInvoice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"kst-invoice-details-summary-DeliveryQuantity","field":"DeliveryQuantity","label":_vm.InvoiceItemCatalog.DeliveryQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }