<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="InvoiceItemCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="RequestedQuantity"
      :label="InvoiceItemCatalog.RequestedQuantity.Label"
    >
      <kst-value multi-value :field="column.field" :data="row" :catalog="InvoiceItemCatalog">
        <kst-value multi-value field="PackagingName" :data="row" :catalog="InvoiceItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="InvoiceItemCatalog.SellPrice.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
    </b-table-column>

    <template v-if="data.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SpecialPriceName"
        :label="InvoiceItemCatalog.SpecialPriceName.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="DiscountedValue"
        :label="InvoiceItemCatalog.DiscountedValue.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="InvoiceItemCatalog"
        />
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Price"
      :label="InvoiceItemCatalog.Price.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import InvoiceItemCatalog from "../InvoiceItemCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    InvoiceItemCatalog: InvoiceItemCatalog
  })
}
</script>