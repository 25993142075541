<template>
  <kst-container class="px-5 py-5 kst-report-netprofit">
    <Expense ref="cmpExpense" :data="data.ExpenseSummary" />

    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
          Laba Kotor
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalProfit" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
          Pengeluaran Biaya
          <kst-tooltip hover-only :content="Model.Tooltip.Label">
            <kst-button class="kst-btn-report" search-mode is-round @click="handleOnExpense"/>
          </kst-tooltip>
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalExpense" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>

    <div class="divider"></div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title">
          Laba Bersih
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
          <kst-value class="is-5" field="TotalNetProfit" :data="data.Summary.All" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    </kst-container>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Info from "../ProfitlossInfo.js";
import Model from "../ProfitlossModel.js";
import Expense from "./Profitloss_Result_Expense";
import Catalog from "../ProfitlossNetProfitCatalog.js";

export default {
  mixins: [DialogMixin],
  components: {
    Expense
  },
  props: {
    data: Object
  },
  data: () => ({
    Info: Info,
    Model: Model,
    Catalog: Catalog,
  }),
  methods: {
    handleOnExpense() {
      this.$refs.cmpExpense.show();
    }
  }
}
</script>