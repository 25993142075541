<template>
  <div class="columns my-0">
    <div class="column is-4">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="WarehouseID" :data="searchData" :catalog="SearchCatalog"
        :options="warehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="StatusID" :data="searchData" :catalog="SearchCatalog"
        :options="statusOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="Date" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>

    <div class="column is-narrow">
      <kst-button reset-mode @click="handleReset"/>
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./DeliverySearchCatalog.js";

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object,
    statusOptions: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    }
  }
}
</script>