<template>
  <kst-section :title="sectionTitle">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :disabled="userVariants.length === Model.Variant.Max"
        :label="Model.ItemVariant.Module.FullName"
        :moduleType="Model.ItemVariant.ModuleType.New"
        @click="handleAdd_Variant"
      />
    </template>

    <template v-if="userVariants.length > 0">
      <kst-warning class="mb-4" :items="variantWarning"/>
    </template>

    <List :idObj="idObj"
      :userData="userData"
      :userDispatches="userDispatches"
      :userVariants="userVariants"
    />
    <kst-errors class="mt-4" :items="userData.VariantsErrors"/>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

import List from "./ItemNew_Variants_List";

export default {
  components: {
    List
  },
  props: {
    idObj: Object,
    userData: Object,
    userDispatches: Array,
    userVariants: Array
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Variant.Title;
    },
    variantWarning() {
      return [
        Info.Message.New_MaxVariant
      ];
    }
  },
  methods: {
    handleAdd_Variant() {
      const variant = Model.createVariant(this.idObj);
      this.userVariants.push(variant);
    }
  }
}
</script>