import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import StockTrfItemCatalog from "./StockTrfItemCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.StockTrf,
  ModuleType: {
    Details: AppModuleType.Details,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/

  StockTrfItem: {
    Module: ItemModel.Module,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Search: {
    Fields: ["StockTrfNumber"]
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },

  setItemByItemDetails(item, itemData) {
    this.helper.setItemByItemDetails(item, itemData);
  },
  setItemByStock(item, stockData) {
    this.helper.setItemByStock(item, stockData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },
  setDefaultPackaging(item) {
    this.helper.setDefaultPackaging(item);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },
  updateErrors(data, items) {
    this.helper.updateErrors(data, items);
  },

  populateData(data, items) {
    let itemToBeSubmitted = items.map(item => ({
      DispatchID: item.DispatchID,
      DispatchName: item.Name,
      PackagingName: item.PackagingName,
      PackagingValue: item.PackagingValue,
      Quantity: item.Quantity,
    }));

    data.StockTrfNumber = data.IsAutoNumber ? null : App.Out.getString(data.StockTrfNumber),
    data.Items = itemToBeSubmitted;
    return data;
  },

  validateItemsByStock(data, items) {
    this.helper.validateItemsByStock(data, items);
  },

  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails() {
      return {
        CompanyID: App.Session.getCompanyID(),
        // user input
        SourceWarehouseID: null,
        DestinationWarehouseID: null,
        StockTrfNumber: "",
        IsAutoNumber: true,
        Comment: "",
        // defined by system
        WarehouseName: "",
        CreatedByID: App.Session.getUserID(),
        // error
        Errors: [],
        // UI Validation
        StockMissingQty: 0
      };
    },
    createItem() {
      return {
        // user input
        DispatchID: null,
        Quantity: "",
        PackagingName: "",
        // defined by system
        Name: "",
        SKU: "",
        Price: "",
        Stock: 0,
        HppPerPcs: 0,
        HppTotal: 0,
        TotalQuantity: 0,
        Total: 0,
        Packaging: "",
        PackagingValue: 1,
        DispatchIsActive: true,
        PackagingOptions: undefined,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    setItemByItemDetails(item, itemData) {
      item.PackagingOptions = ItemModel.getPackagingOptions(itemData.Packaging);
    },
    setItemByStock(item, stockData) {
      // user input
      item.DispatchID = stockData.DispatchID;
      item.Name = stockData.FullName;
      item.Price = App.In.getInteger(stockData.SellPrice);
      // defined by system
      item.SKU = stockData.SKU;
      item.Stock = stockData.Quantity;
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        item.ErrorsColl[field].push(error);
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      item.Errors = newErrors;
    },
    setDefaultPackaging(item) {
      const packaging = item.PackagingOptions.rows[0];
      item.PackagingName = packaging.ItemUnitName;
      item.PackagingValue = packaging.ItemUnitAmount;
    },

    getItemInfo(item) {
      let infoList = [];

      // SKU
      infoList.push(StockTrfItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, StockTrfItemCatalog)
      );

      // Price
      infoList.push(StockTrfItemCatalog.Price.Label + ": " +
        App.Value.getValue("Price", item, StockTrfItemCatalog)
      );

      // Stock
      infoList.push(StockTrfItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, StockTrfItemCatalog)
      );

      return infoList;
    },
    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(StockTrfItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, StockTrfItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(StockTrfItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, StockTrfItemCatalog)
      );

      return infoList;
    },

    updateItem(item) {
      if (item.PackagingOptions) {
        item.PackagingValue = ItemModel.getPackagingValue(
          item.PackagingOptions.rows, item.PackagingName
        );

        if (item.PackagingValue === undefined) {
          item.PackagingValue = 1;
        }
      }

      this.updateItemByPackaging(item);
      item.TotalQuantity = App.Data.getTotalQty(item.Quantity, item.PackagingValue);
      item.Total = App.Data.getTotal(item.Quantity, item.PackagingValue, item.BuyPrice);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    updateErrors(data, items) {
      let errors = [];

      const pivotItems = App.Pivot.create(items, "DispatchID", [
        { field: "Name", type: App.Pivot.Type.FIRST },
        { field: "Stock", type: App.Pivot.Type.MAX },
        { field: "TotalQuantity", type: App.Pivot.Type.SUM }
      ]);

      for (const item of pivotItems) {
        if (item.TotalQuantity > item.Stock) {
          errors.push("Maximum total " + StockTrfItemCatalog.Quantity.Label + " " +
            "untuk " + item.Name + " adalah " +
            App.Value.getValue("Stock", item, StockTrfItemCatalog) + " " +
            "(" + StockTrfItemCatalog.Stock.Label + "), " +
            "sedangkan total " + StockTrfItemCatalog.Quantity.Label + ": " +
            App.Value.getValue("TotalQuantity", item, StockTrfItemCatalog)
          );
        }
      }

      data.Errors = errors;
    },
    validateItemsByStock(data, items) {
      let validItems = [];

      // reset indicator
      data.StockMissingQty = 0;

      let invalidItems = [];

      // filter: deleted-item
      for (const item of items) {
        if (item.DispatchIsActive) {
          validItems.push(item);
        }
      }

      const pivotItems = App.Pivot.create(validItems, "DispatchID", [
        { field: "Quantity", type: App.Pivot.Type.SUM },
        { field: "Stock", type: App.Pivot.Type.FIRST },
        { field: "Name", type: App.Pivot.Type.FIRST },
      ]);

      for (const pivotItem of pivotItems) {
        if (pivotItem.Quantity > pivotItem.Stock) {
          data.StockMissingQty = 1;

          invalidItems.push({
            Name: pivotItem.Name,
            Quantity: (pivotItem.Quantity - pivotItem.Stock)
          });
        }
      }
    }
  }
}