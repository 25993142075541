<template>
  <div>
    <template v-for="(item, index) in userVariants">
      <div :key="index" class="columns">
        <div class="column">
          <div class="columns is-mobile">
            <div class="column is-narrow">
              <div class="k-control-py">{{ index + 1 }}</div>
            </div>
            <div class="column">
              <kst-input floating-label load-on-focus
                field="Name"
                :data="item"
                :catalog="VariantCatalog"
                :index="index"
                @input="handleName"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <VariantValues
            :idObj="idObj"
            :userData="userData"
            :userDispatches="userDispatches"
            :userVariants="userVariants"
            :userVariantValues="item.Value"
            :variantIndex="index"
          />
        </div>
        <div class="column is-narrow has-text-right">
          <kst-tooltip delete-mode hover-only :content="Model.ItemVariant.Module.FullName">
            <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
          </kst-tooltip>
        </div>
      </div>
    </template>

    <kst-table-empty v-if="userVariants.length === 0"/>
  </div>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import VariantCatalog from "../VariantCatalog.js";

import VariantValues from "./ItemNew_VariantValues";

export default {
  components: {
    VariantValues
  },
  mixins: [DialogMixin],
  props: {
    idObj: Object,
    userData: Object,
    userDispatches: Array,
    userVariants: Array
  },
  data: () => ({
    Model: Model,
    VariantCatalog: VariantCatalog,
    variants: [],
    selectedIndex: null
  }),
  methods: {
    handleName() {
      Model.updateVariantsErrors(this.userData, this.userVariants);
    },
    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_ConfirmDeleteVariant,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userVariants.splice(this.selectedIndex, 1);
      Model.updateDispatches(this.userDispatches, this.userVariants, this.userData);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    }
  }
}
</script>