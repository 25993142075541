import CompanyServices from "@/services/CompanyServices.js";
import DispatchServices from "@/services/DispatchServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import SpecialPriceAdjServices from "@/services/SpecialPriceAdjServices.js";
import SpecialPriceServices from "@/services/SpecialPriceServices.js";
import StockServices from "@/services/StockServices.js";

export default {
  getDispatchDetails: DispatchServices.getDetails,
  getSpecialPriceAdjList: SpecialPriceAdjServices.getList,
  getSpecialPriceAdjDetail: SpecialPriceAdjServices.getDetails,
  getSpecialPriceList: SpecialPriceServices.getList,
  getWarehouseList: WarehouseServices.getList,
  getCompanyDetails: CompanyServices.getDetails,
  getStockList: StockServices.getList,
  postSpecialPriceAdj: SpecialPriceAdjServices.postData
}