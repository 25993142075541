var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"loading":_vm.isListLoading,"failure":_vm.failureList,"selected":_vm.priceUserData.selected},on:{"update:selected":function($event){return _vm.$set(_vm.priceUserData, "selected", $event)}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('PriceIcon',{attrs:{"row":row,"buyPrice":_vm.buyPrice}})]}}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-shortdate","field":"Date","label":_vm.Catalog.Date.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"ReceiveNumber","label":_vm.Catalog.ReceiveNumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"VendorID","label":_vm.Catalog.VendorID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"PurchasingNumber","label":_vm.Catalog.PurchasingNumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price has-text-weight-bold","field":"BuyPrice","label":_vm.Catalog.BuyPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }