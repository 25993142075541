var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.userItems},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.InvoiceItem.Module.FullName}},[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})],1)]}},{key:"empty-body",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"userItems","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kst-error',{attrs:{"array-blank-mode":!!errors}})]}}])})]},proxy:true}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e(),_c('kst-input',{staticClass:"is-hidden",attrs:{"box-off":"","field-only":"","field":"DispatchID","data":row,"catalog":_vm.InvoiceItemCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"field":"Name","label":_vm.InvoiceItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog,"index":index},on:{"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-tooltip',{attrs:{"info-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"info-mode":"","items":_vm.Model.getItemInfo(row)}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"info-mode":""}})],1)]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-packaging","field":"PackagingName","label":_vm.InvoiceItemCatalog.PackagingName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [(row.SalesDraftItemID === null)?[_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog,"index":index,"options":row.PackagingOptions},on:{"input":function($event){return _vm.handlePackaging(row)},"error":_vm.handleRowErrors}})]:[_c('kst-output',{attrs:{"box-off":"","field-only":"","field":"Packaging","data":row,"catalog":_vm.InvoiceItemCatalog}})]]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"RequestedQuantity","label":_vm.InvoiceItemCatalog.RequestedQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog,"index":index},on:{"input":function($event){return _vm.handleQuantity(row)},"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-price-button","field":"SellPrice","label":_vm.InvoiceItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog,"index":index},on:{"input":function($event){return _vm.handleSellPrice(row)},"error":_vm.handleRowErrors},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"control"},[_c('kst-tooltip',{attrs:{"search-mode":"","hover-only":"","position":"is-bottom","content":_vm.InvoiceItemCatalog.SellPrice.Label}},[_c('kst-button',{attrs:{"search-mode":""},on:{"click":function($event){return _vm.handleSearch_Price(index)}}})],1)],1)]},proxy:true}],null,true)})]}}])}),(_vm.userData.SpecialPriceCount > 0)?[_c('b-table-column',{attrs:{"cell-class":"ks-col-price-button","field":"SpecialPriceID","label":_vm.InvoiceItemCatalog.SpecialPriceID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"options":row.SpecialPriceOptions,"catalog":_vm.InvoiceItemCatalog,"index":index},on:{"input":function($event){return _vm.handleSpecialPrice(row)}}})]}}],null,false,412549672)}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"DiscountedValue","label":_vm.InvoiceItemCatalog.DiscountedValue.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}],null,false,3565924609)})]:_vm._e(),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Price","label":_vm.InvoiceItemCatalog.Price.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.InvoiceItemCatalog}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }