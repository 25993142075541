import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ItemUnitName: {
    Label: "Nama Kemasan",
    Type: InputType.Text,
    MaxLength: 45,
    Required: true,
    Valid: true
  },
  ItemUnitAmount: {
    Label: "Jumlah Per Kemasan",
    Type: InputType.Integer,
    MaxLength: 11,
    MinValue: 1,
    Required: true
  },
  IsActive: {
    Label: "Status",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.ActiveInactive
  }
}