import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import Transaction from "./Transaction/Transaction";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.Transaction)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Transaction),
        name: RouterModel.getName(AppModule.Transaction),
        component: Transaction,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Transaction),
          moduleName: RouterModel.getModule(AppModule.Transaction)
        }
      }
    ];
  }
}