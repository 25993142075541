<template>
  <span/>
</template>

<script>
  import AxiosMixin from "@/mixins/AxiosMixin.js";
  import LoadingMixin from "@/mixins/LoadingMixin.js";
  import XlsxMixin from "@/mixins/XlsxMixin.js";
  import Catalog from "../ExpenseCatalog.js";
  import Info from "../ExpenseInfo.js";
  import Services from "../ExpenseServices.js";

  export default {
    mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getExpenseDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.SubCategoryName);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Comment);

      // value
      this.addRow();

      this.addSequenceCell();
      this.addCellValue("Date", data, Catalog);
      this.addCellValue("SubCategoryName", data, Catalog);
      this.addCellValue("Total", data, Catalog);
      this.addCellValue("Comment", data, Catalog);

    }
  }
}
</script>