import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

export default {
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    RelatedValue: "WarehouseName"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text
  },
  Name: {
    Label: "Jenis Transaksi",
    Type: InputType.Text
  },
  DispatchName: {
    Label: "Nama Barang",
    Type: InputType.Text
  },
  Changes: {
    Label: "Stok Masuk/Keluar",
    Type: InputType.Integer
  },
  QuantityAfter: {
    Label: "Stok Akhir",
    Type: InputType.Integer
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true,
    RequiredHide: true,
    ReadOnly: true
  },
  HppChanges: {
    Label: "Nilai Stok Masuk/Keluar",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  HppAfter: {
    Label: "Nilai Stok Akhir",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
}