<template>
  <kst-table action paginated view-mode
    :data="items"
    :loading="isListLoading"
    :failure="failureList"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="FullName"
      :label="Catalog.FullName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="Catalog.SellPrice.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Field.HppPerPcs !== false">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-hpp"
        field="HppPerPcs"
        :label="Catalog.HppPerPcs.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty has-text-weight-bold"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Field.HppTotal !== false">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="Catalog.HppTotal.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template #action="{ row }">
      <Action :data="row"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../StockCatalog.js";

import Action from "./StockList_List_Action";

export default {
  components: {
    Action
  },
  props: {
    access: Object,
    failureList: String,
    items: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>