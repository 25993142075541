<template>
  <kst-container class="kst-company">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :title="title"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details :data="dataCategory" :categoryOption="categoryOption" />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Details from "./ExpenseSubCategoryNew_Details";
import Router from "../ExpenseSubCategoryRouter";
import Model from "../ExpenseSubCategoryModel";
import Services from "../ExpenseSubCategoryServices";

export default {
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  components: {
    Details
  },
  data: () => ({
    dataCategory: {},
    categoryOption: {},
    isLoading: true,
    cancelUrl: Router.Path.Main,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dataCategory = Model.createDetails();
      this.loadCategory();
    },
    loadCategory() {
      const config = Services.getExpenseCatList();
      this.runAxios(config, null, this.loadCategorySuccess, this.loadCategoryError)
    },
    loadCategorySuccess(data) {
      this.isLoading = false;
      this.categoryOption = Model.createOptions(data);
    },
    loadCategoryError() {
      this.isLoading = false;
    },
    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if(isValid) {
          this.saveData(this.dataCategory);
        } else {
          this.notifyFormError();
        }
      })
    },
    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();
      const config = Services.postExpenseCategory(data);
      this.runAxios(config, null, this.saveDataSuccess, this.saveDataError, this.saveDataInvalid)
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveDataSuccess() {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        this.redirectTo(Router.Path.Main);
      })
    },
    saveDataError(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveDataInvalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>
