<template>
  <canvas id="report-chart-retur" />
</template>
<script>
import Chart from 'chart.js';

export default {
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    options: {
    responsive: false,
    lineTension: 1,
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          callback: function (value) {
            var formatter = new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0
            });
            return formatter.format(value);
          },
          beginAtZero: true,
          padding: 25,
        }
      }]
    }
  }
  }),
  mounted () {
    this.createChart(this.chartData);
  },
  methods: {
  createChart(chartData) {
    const ctx = document.getElementById('report-chart-retur');
     new Chart(ctx, {
      type: 'bar',
      data: chartData.data,
      options: this.options,
    });
  }
}
}
</script>