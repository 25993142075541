<template>
  <div>
    <label
      :for="theId"
      class="kst-image-container kst-image-size kst-image-border ks-cursor-pointer ks-flex-center"
    >
      <div class="pt-2 has-text-centered">
        <kst-icon :icon="theIcon"/>
        <div class="px-4">{{ theAddText }}</div>
      </div>
    </label>
    <input
      type="file"
      style="display: none"
      :id="theId"
      :accept=accept
      @change="handleChange"
    >
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";
import Compressor from 'compressorjs';

export default {
  props: {
    accept: {
      type: String,
      required: true
    },
    index: Number
  },
  computed: {
    theAddText() {
      return AppCatalog.Image.AddText;
    },
    theIcon() {
      return AppCatalog.Image.Icon;
    },
    theId() {
      return "file" + (this.index !== undefined ? this.index : "");
    }
  },
  methods: {
    handleChange(event) {
      // accept single file only
      this.compressImage(event.target.files[0]);
    },

    compressImage(file) {
      const _this = this;
      new Compressor(file, {
        maxWidth: AppCatalog.Compressorjs.MaxWidth,
        success(result) {
          _this.convertImage(result);
        },
        error(err) {
          // eslint-disable-next-line
          console.log(err.message);
        },
      });
    },
    convertImage(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imgSrc = URL.createObjectURL(file);
        const imgText = reader.result;

        this.$emit(this.$kst.Enum.Event.Input, imgSrc, imgText);
      }
      reader.onerror = function (error) {
        // eslint-disable-next-line
        console.log(error);

        this.$emit(this.$kst.Enum.Event.Error, error);
      }
    }
  }
}
</script>