import AppModule from "@/services/App/AppModule.js";
import Model from "./RouterModel.js";

// general
import NotFound from "@/components/NotFound/NotFound";
import Layout from "@/components/Layout/Layout";
// modules
import AutoNumberRouter from "@/components/AutoNumber/AutoNumberRouter.js";
import CallbackRouter from "@/components/Callback/CallbackRouter.js";
import ClientRouter from "@/components/Client/ClientRouter.js";
import DashboardRouter from "@/components/Dashboard/DashboardRouter.js";
import DeliveryRouter from "@/components/Delivery/DeliveryRouter.js";
import ExpenseRouter from "@/components/Expense/ExpenseRouter.js";
import HppAdjRouter from "@/components/HppAdj/HppAdjRouter.js";
import InvoiceRouter from "@/components/Invoice/InvoiceRouter.js";
import ItemRouter from "@/components/Item/ItemRouter.js";
import PaymentTypeRouter from "@/components/Settings/PaymentType/PaymentTypeRouter.js";
import PosRouter from "@/components/Pos/PosRouter.js";
import PrintThermalRouter from "@/components/Thermal/PrintThermalRouter.js";
import ProfitlossRouter from "@/components/RptProfitloss/ProfitlossRouter.js";
import PurchaseRouter from "@/components/Purchase/PurchaseRouter.js";
import ReceiveRouter from "@/components/Receive/ReceiveRouter.js";
import OmzetRouter from "@/components/RptOmzet/OmzetRouter.js";
import ReportStockAdj from "@/components/Report/StockAdj/StockAdjRouter.js";
import RptStockCardRouter from "@/components/RptStockCard/RptStockCardRouter.js";
import RtnInvoiceRouter from "@/components/RtnInvoice/RtnInvoiceRouter.js";
import SalesRecapRouter from "@/components/SalesRecap/SalesRecapRouter.js";
import SalesRouter from "@/components/Sales/SalesRouter.js";
import SettingsRouter from "@/components/Settings/SettingsRouter.js";
import SpecialPriceAdjRouter from "@/components/SpecialPriceAdj/SpecialPriceAdjRouter.js";
import SpecialPriceRouter from "@/components/SpecialPrice/SpecialPriceRouter.js";
import StockOpnameRouter from "@/components/StockOpname/StockOpnameRouter.js";
import StockAvailabilityRouter from "@/components/StockAvailability/StockAvailabilityRouter.js";
import StockClosingRouter from "@/components/StockClosing/StockClosingRouter.js";
import StockReductionRouter from "@/components/StockReduction/StockReductionRouter.js";
import StockRouter from "@/components/Stock/StockRouter.js";
import StockTrfRouter from "@/components/StockTrf/StockTrfRouter.js";
import TransactionRouter from "@/components/RptTransaction/TransactionRouter.js";
import VendorRouter from "@/components/Vendor/VendorRouter.js";
import VersionRouter from "@/components/Version/VersionRouter.js";
import WarehouseRouter from "@/components/Warehouse/WarehouseRouter.js";
import DailyReportRouter from "@/components/RptDailyReport/DailyReportRouter.js";
// temporary
import TestRouter from "@/components/Test/TestRouter.js";

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Layout",
        redirect: Model.getPath(AppModule.Dashboard),
      },
      ...AutoNumberRouter.getRoutes(),
      ...ClientRouter.getRoutes(),
      ...DashboardRouter.getRoutes(),
      ...DeliveryRouter.getRoutes(),
      ...ExpenseRouter.getRoutes(),
      ...HppAdjRouter.getRoutes(),
      ...InvoiceRouter.getRoutes(),
      ...ItemRouter.getRoutes(),
      ...PaymentTypeRouter.getRoutes(),
      ...ProfitlossRouter.getRoutes(),
      ...PurchaseRouter.getRoutes(),
      ...ReceiveRouter.getRoutes(),
      ...OmzetRouter.getRoutes(),
      ...ReportStockAdj.getRoutes(),
      ...RptStockCardRouter.getRoutes(),
      ...RtnInvoiceRouter.getRoutes(),
      ...SalesRecapRouter.getRoutes(),
      ...SalesRouter.getRoutes(),
      ...SettingsRouter.getRoutes(),
      ...SpecialPriceAdjRouter.getRoutes(),
      ...SpecialPriceRouter.getRoutes(),
      ...StockOpnameRouter.getRoutes(),
      ...StockAvailabilityRouter.getRoutes(),
      ...StockClosingRouter.getRoutes(),
      ...StockReductionRouter.getRoutes(),
      ...StockRouter.getRoutes(),
      ...StockTrfRouter.getRoutes(),
      ...TransactionRouter.getRoutes(),
      ...VendorRouter.getRoutes(),
      ...VersionRouter.getRoutes(),
      ...WarehouseRouter.getRoutes(),
      ...DailyReportRouter.getRoutes(),
      // temporary
      ...TestRouter.getRoutes()
    ],
    meta: {
      requiresAuth: true
    }
  },
  ...CallbackRouter.getRootRoutes(),
  ...HppAdjRouter.getRootRoutes(),
  ...InvoiceRouter.getRootRoutes(),
  ...ItemRouter.getRootRoutes(),
  ...PosRouter.getRootRoutes(),
  ...PrintThermalRouter.getRootRoutes(),
  ...PurchaseRouter.getRootRoutes(),
  ...SalesRouter.getRootRoutes(),
  ...StockRouter.getRootRoutes(),
  ...ReceiveRouter.getRootRoutes(),
  ...SalesRecapRouter.getRootRoutes(),
  ...StockOpnameRouter.getRootRoutes(),
  ...StockClosingRouter.getRootRoutes(),
  ...StockReductionRouter.getRootRoutes(),
  ...StockTrfRouter.getRootRoutes(),
  ...SpecialPriceAdjRouter.getRootRoutes(),
  ...OmzetRouter.getRootRoutes(),
  ...DailyReportRouter.getRootRoutes(),
  {
    path: "*",
    name: "Not Found Root",
    component: NotFound
  }
];

export default routes;