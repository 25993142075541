<template>
  <kst-table action input-mode :data="userPackagings">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="ItemUnitName"
      :label="PackagingCatalog.ItemUnitName.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog"
        :disabled="row.UnitID !== null" :index="index"
        @input="handleName" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="ItemUnitAmount"
      :label="PackagingCatalog.ItemUnitAmount.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog"
        :disabled="row.IsDefault || (row.UnitID !== null)"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-item-active"
      field="IsActive"
      :label="PackagingCatalog.IsActive.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog" :index="index"
        @input="handleIsActive"
      />
    </b-table-column>

    <template #action="{ row, index }">
      <kst-tooltip delete-mode hover-only :content="Model.ItemPackaging.Module.FullName">
        <template v-if="!row.IsDefault">
          <template v-if="row.UnitID === null">
            <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
          </template>
        </template>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Info from "../ItemInfo.js";
import PackagingCatalog from "../PackagingCatalog.js";
import Model from "../ItemModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userPackagings: Array
  },
  data: () => ({
    PackagingCatalog: PackagingCatalog,
    Model: Model,
    selectedIndex: null
  }),
  methods: {
    handleName() {
      Model.updatePackagingsErrors(this.userData, this.userPackagings);
    },
    handleIsActive() {
      Model.updatePackagingsErrors(this.userData, this.userPackagings);
    },
    handleRowDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.Edit_ConfirmDeletePackaging,
        this.handleRowDelete_Confirm,
        this.handleRowDelete_Cancel
      );
    },
    handleRowDelete_Confirm() {
      this.userPackagings.splice(this.selectedIndex, 1);
      Model.updatePackagingsErrors(this.userData, this.userPackagings);

      this.selectedIndex = null;
    },
    handleRowDelete_Cancel() {
      this.selectedIndex = null;
    },
    handleRowErrors(errors, field, index) {
      Model.setPackagingErrors(this.userPackagings[index], field, errors);
    }
  }
}
</script>