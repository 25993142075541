<template>
  <div class="field has-addons">
    <div class="control">
      <kst-button action-mode fullLabel
        class="is-primary"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
      />
    </div>
  </div>
</template>

<script>
import Model from "../ExpenseSubCategoryModel"

export default {
  data: () => ({
    Model: Model
  })
}
</script>