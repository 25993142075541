<template>
  <kst-container class="kst-specialpriceadj-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <Details
          :data="data"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
          @submit="handleSearchSubmit"
        />
        <Items
          :data="data"
          :dataStock="dataStock"
          :userItems="userItems"
          :isListLoading="isListLoading"
          :failureList="failureList"
          @callback="handleCallbackItems"
          @submit="handleSubmitItems"
          />
        <template v-if="dataStock.length > 0">
          <Scanner :dataStock="dataStock" @callback="handleCallbackScanner" />
          <Total :data="data" />
        </template>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import Details from './SpecialPriceAdjNew_Details';
import Items from './SpecialPriceAdjNew_Items';
import Total from './SpecialPriceAdjNew_Total';
import Scanner from './SpecialPriceAdjNew_Scanner';
import Router from '../SpecialPriceAdjRouter.js';
import Model from '../SpecialPriceAdjModel.js';
import Services from '../SpecialPriceAdjServices.js';

export default {
  mixins: [AxiosMixin, RouterMixin, SnackbarMixin, LoadingMixin],
  components: {
    Details,
    Items,
    Total,
    Scanner
  },
  data: () => ({
    userItems: [],
    data: {},
    dataStock: [],
    isLoading: false,
    isListLoading: false,
    loading: null,
    warehouseOptions: {},
    packagingOptions: {},
    cancelUrl: Router.Path.List,
    errors: null,
    failure: null,
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initDetail();
      this.initLoad();
    },
    initDetail() {
      this.data = Model.getDefaultDetail();
    },
    initLoad() {
      this.loadDataWarehouse();
    },
    loadDataWarehouse() {
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadDataWarehouse_Success, this.loadDataWarehouse_Error);
    },
    loadDataWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.loadSpecialPrice();
    },
    loadDataWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadSpecialPrice() {
      const options = { active: 1 }
      const config = Services.getSpecialPriceList(options);
      this.runAxios(config, null, this.loadSpecialPrice_Success, this.loadSpecialPrice_Error);
    },
    loadSpecialPrice_Success(data) {
      Model.saveSpecialPriceOptions(data);
      this.isLoading = false;
    },
    loadSpecialPrice_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          const data = Model.populatePostData(this.data, this.userItems);
          this.saveData(data);
        } else {
          this.notifyFormError();
        }
      });
    },
    saveData(data) {
      this.failure = null
      this.loading = this.openLoading();
      const config = Services.postSpecialPriceAdj(data);
      this.runAxios(config, null, 
        this.saveData_Success, 
        this.saveData_Error,
        this.saveData_Invalid
      )
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnable = false;
      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      })
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },
    saveData_End() {
      this.closeLoading(this.loading);
      this.loading = null;
    },
    handleCallbackItems(id, item) {
      this.loadDispatch(id, item);
    },
    loadDispatch(id, item) {
      this.failureItems = null;
      const config = Services.getDispatchDetails(id);
      this.runAxios(config, item, this.loadDispatch_Success, this.loadDispatch_Error);
    },
    loadDispatch_Success(data, item) {
      const items = Model.mapDataItemDetail(data, item);
      this.userItems.push(items);
      this.data.Total = Model.getGrandTotal(this.userItems);
    },
    loadDispatch_Error(message) {
      this.failureItems = message;
    },
    handleSearchSubmit() {
      this.loadDataStock();
    },
    loadDataStock() {
      this.loading = this.openLoading();
      const options = { warehouse: this.data.WarehouseID, availstock: 1 }
      const config = Services.getStockList(options);
      this.runAxios(config, null, this.loadDataStock_Success, this.loadDataStock_Error);
    },
    loadDataStock_Success(data) {
      this.closeLoading(this.loading);
      this.dataStock = data;
    },
    loadDataStock_Error() {
      this.closeLoading(this.loading);
    },
    handleCallbackScanner(data) {
      this.loadDispatch(data.DispatchID, data);
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    },
    handleSubmitItems() {
      this.data.Total = Model.getGrandTotal(this.userItems);
    }
  }
}
</script>