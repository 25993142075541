import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

export default {
  AdjNumber: {
    Label: "Nomor Penyesuaian",
    Type: InputType.Text,
    MaxLength: 50
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.Integer
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    MaxLength: 50
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  }
}