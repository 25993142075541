import InputType from "@/services/Input/InputType.js";
import AppCatalog from "@/services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari di " + AppCatalog.Title,
    Type: InputType.Text,
    Icon: "mdi mdi-magnify",
    Required: true
  }
}