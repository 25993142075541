import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./DeliveryCatalog.js"
import Model from "./DeliveryModel.js";
import InvoiceCatalog from "@/components/Invoice/InvoiceCatalog.js";
import InvoiceModel from "@/components/Invoice/InvoiceModel.js";

export default {
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.Item.Module.FullName + "?",
    New_Details:
      "Harap memilih " + Catalog.SONumber.Label + " terlebih dahulu.",
    Related_NewDelivery:
      "Tidak diperkenankan menambah " + Model.Module.FullName + ", " +
      "saat " + InvoiceCatalog.Status.Label + " dari " + Model.Invoice.Module.FullName +
      " adalah " + InvoiceModel.Status.Closed.Label + ".",
    Related_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
  },
  Section: {
    Delivery: {
      Title: "Info Pengiriman"
    },
    Status: {
      Title: "Rincian Perubahan Status",
      TitleNew: "Rincian Status"
    },
    Invoice: {
      Title: "Info Nota Penjualan",
      AddItem: {
        Title: "Tambah Barang dari Nota Penjualan"
      }
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  }
}