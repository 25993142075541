<template>
  <kst-section :title="sectionTitle">
    <Add
      ref="mdlAdd"
      :existingVariantValues="variantValues"
      @submit="handleAdd_Submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Model.ItemVariantValue.Module.FullName"
        :moduleType="Model.ItemVariantValue.ModuleType.New"
        @click="handleAdd_VariantValue"
      />
    </template>

    <List
      :userData="userData"
      :userVariantValues="userVariantValues"
      :userVariants="userVariants"
      :userDispatches="userDispatches"
    />
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import VariantValueCatalog from "../VariantValueCatalog.js";

import Add from "./ItemEdit_VariantValues_Add";
import List from "./ItemEdit_VariantValues_List";

export default {
  components: {
    Add,
    List
  },
  props: {
    idObj: Object,
    userData: Object,
    userDispatches: Array,
    userVariants: Array,
    userVariantValues: Array,
    variantIndex: Number
  },
  data: () => ({
    Model: Model,
    VariantValueCatalog: VariantValueCatalog,
    variantValues: []
  }),
  computed: {
    sectionTitle() {
      return Info.Section.VariantValue.Title;
    }
  },
  methods: {
    handleAdd_VariantValue() {
      this.variantValues = [];
      for (const variantValue of this.userVariantValues) {
        this.variantValues.push(variantValue.Value);
      }

      this.$refs.mdlAdd.show();
    },
    handleAdd_Submit(data) {
      let newValue = Model.createVariantValue(this.idObj);
      Model.setVariantValueByValue(newValue, data);
      this.userVariantValues.push(newValue);

      Model.updateDispatches(this.userDispatches, this.userVariants, this.userData);
    }
  }
}
</script>