<template>
  <kst-section :title="sectionTitle">
    <kst-warning class="mb-4" :items="invoiceWarning"/>

    <kst-output control-off status-off
      field="SalesOrderID" :data="userData" :catalog="Catalog"
    />
    <kst-input class="is-hidden" field="SONumber" :data="userData" :catalog="Catalog"/>

    <template v-if="userData.SalesOrderID">
      <Details :data="userData"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../RtnInvoiceCatalog.js";
import Info from "../RtnInvoiceInfo.js";

import Details from "./RtnInvoiceEdit_Invoice_Details";

export default {
  components: {
    Details
  },
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Invoice.Title;
    },
    invoiceWarning() {
      return [Info.Message.Edit_InvoiceFields];
    }
  }
}
</script>