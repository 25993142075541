export default {
  methods: {
    showConfirm(message, confirmCallback, cancelCallback) {
      let strMessage = this.$kst.Convert.arrayToString(message);

      this.$buefy.dialog.confirm({
        message: strMessage,
        onConfirm: () => {
          if (confirmCallback) {
            confirmCallback();
          }
        },
        onCancel: () => {
          if (cancelCallback) {
            cancelCallback();
          }
        }
      });
    }
  }
}