import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import ReceiveList from "./List/ReceiveList";
import ReceiveNew from "./New/ReceiveNew";
import ReceiveDetails from "./Details/ReceiveDetails";
import ReceiveEdit from "./Edit/ReceiveEdit";
import ReceiveExportDetails from "./ExportDetails/ReceiveExportDetails";
import ReceivePrintDetails from "./PrintDetails/ReceivePrintDetails";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Receive, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Receive, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Receive, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Receive, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.Details),
        component: ReceiveDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Receive)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.Edit),
        component: ReceiveEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Receive)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.ExportDetails),
        component: ReceiveExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Receive)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.List),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.List),
        component: ReceiveList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Receive)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.New),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.New),
        component: ReceiveNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Receive)
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.Print),
        component: ReceivePrintDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.Print),
          moduleName: RouterModel.getModule(AppModule.Receive)
        }
      }
    ];
  }
}