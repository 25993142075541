<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="ReductionNumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>
    <kst-input field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :disabled="userItems.length > 0" :options="warehouseOptions"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../StockReductionCatalog.js";
import Info from "../StockReductionInfo.js";
import Model from "../StockReductionModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [
        Info.Message.New_Date,
        Info.Message.New_Warehouse
      ];
    },
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  }
}
</script>