/**
 * Name - used in menu (route)
 * FullName - used in label, such as title
 */
export default {
  Dashboard: {
    Name: "Dashboard", FullName: "Dashboard", Code: "Dashboard", Path: "dashboard"
  },

  // report
  Report: {
    Name: "Laporan", FullName: "Laporan"
  },
  BestSellingItems: {
    Name: "Barang Terlaris", FullName: "Barang Terlaris", Code: "BestSellingItems", Path: "report/bestsellingitems"
  },
  StockCard: {
    Name: "Kartu Stok", FullName: "Laporan Kartu Stok", Code: "StockCard", Path: "report/stockcard"
  },
  ProfitLoss: {
    Name: "Laba (Rugi)", FullName: "Laporan Laba Rugi", Code: "ProfitLoss", Path: "report/profitloss"
  },
  ReportStockAdj: {
    Name: "Pergerakan Stok", FullName: "Laporan Pergerakan Stok", Code: "ReportStockAdj", Path: "report/stockadj"
  },
  Omzet: {
    Name: "Omzet", FullName: "Laporan Omzet", Code: "Omzet", Path: "report/omzet"
  },
  Transaction: {
    Name: "Daftar Transaksi", FullName: "Daftar Transaksi", Code: "Transaction", Path: "report/transaction"
  },
  StockAvailability: {
    Name: "Persediaan Stok SO", FullName: "Persediaan Stok SO", Code: "StockAvailability", Path: "stockavailability"
  },
  DailyReport: {
    Name: "Laporan Harian", FullName: "Laporan Harian", Code: "DailyReport", Path: "report/dailyreport"
  },

  Pos: {
    Name: "POS", FullName: "POS", Path: "pos"
  },

  PrintThermal: {
    Name: "Cetak Thermal", FullName: "Cetak Thermal", Path: "printthermal"
  },

  // inventaris
  Inventaris: {
    Name: "Inventaris", FullName: "Inventaris"
  },
  Item: {
    Name: "Daftar Barang", FullName: "Barang", Code: "Item", Path: "item"
  },
  StockOpname: {
    Name: "Stok Opname", FullName: "Stok Opname", Code: "StockOpname", Path: "stockopname"
  },
  StockReduction: {
    Name: "Pengeluaran Stok", FullName: "Pengeluaran Stok", Code: "StockReduction", Path: "stockreduction"
  },
  StockTrf: {
    Name: "Transfer Barang", FullName: "Transfer Barang", Code: "StockTrf", Path: "stocktrf"
  },
  StockClosing: {
    Name: "Penutupan Stok", FullName: "Penutupan Stok", Code: "StockClosing", Path: "stockclosing"
  },
  HppAdj: {
    Name: "Penyesuaian HPP", FullName: "Penyesuaian HPP", Code: "HppAdj", Path: "hppadj"
  },
  SpecialPriceAdj: {
    Name: "Penyesuaian Harga Spesial", FullName: "Penyesuaian Harga Spesial", Code: "SpecialPriceAdj", Path: "specialspriceadj"
  },

  // transaction - buy
  Buy: {
    Name: "Pembelian", FullName: "Pembelian"
  },
  Vendor: {
    Name: "Vendor", FullName: "Vendor", Code: "Vendor", Path: "vendor"
  },
  Purchase: {
    Name: "Pembelian Stok (PO)", FullName: "Pembelian Stok (PO)", Code: "Purchase", Path: "purchase"
  },
  Receive: {
    Name: "Penerimaan Stok", FullName: "Penerimaan Stok", Code: "Receive", Path: "receive"
  },

  // transaction - sell
  Sell: {
    Name: "Penjualan dan Pengiriman", FullName: "Penjualan dan Pengiriman"
  },
  Sales: {
    Name: "Penjualan Stok (SO)", FullName: "Penjualan Stok (SO)", Code: "Sales", Path: "salesorder"
  },
  Invoice: {
    Name: "Nota Penjualan", FullName: "Nota Penjualan", Code: "Invoice", Path: "invoice"
  },
  RtnInvoice: {
    Name: "Retur Penjualan", FullName: "Retur Penjualan", Code: "RtnInvoice", Path: "rtninvoice"
  },
  Delivery: {
    Name: "Pengiriman", FullName: "Pengiriman", Code: "Delivery", Path: "delivery"
  },
  SalesRecap: {
    Name: "Rekap Penjualan", FullName: "Rekap Penjualan", Code: "SalesRecap", Path: "salesrecap"
  },

  // contact
  Contact: {
    Name: "Manajemen Kontak", FullName: "Manajemen Kontak"
  },
  Client: {
    Name: "Pelanggan", FullName: "Pelanggan", Code: "Client", Path: "client"
  },
  Warehouse: {
    Name: "Gudang", FullName: "Gudang", Code: "Warehouse", Path: "warehouse"
  },

  // finance
  Finance: {
    Name: "Finansial", FullName: "Finansial"
  },
  Expense: {
    Name: "Pengeluaran", FullName: "Pengeluaran", Code: "Expenses", Path: "expenses"
  },

  // settings
  Settings: {
    Name: "Pengaturan", FullName: "Pengaturan", Code: "Settings", Path: "settings"
  },
  CompanyProfile: {
    Name: "Profil Perusahaan", FullName: "Profil Perusahaan", Code: "CompanyProfile", Path: "settings/company"
  },
  AutoNumber: {
    Name: "Penomoran Otomatis", FullName: "Penomoran Otomatis",  Code: "AutoNumber", Path: "settings/autonumber"
  },
  ExpenseCategory: {
    Name: "Klasifikasi Pengeluaran", FullName: "Jenis Pengeluaran", Code: "ExpenseCategory", Path: "settings/expensecategory"
  },
  SpecialPrice: {
    Name: "Harga Spesial", FullName: "Harga Spesial", Code: "SpecialPrice", Path: "specialprice"
  },
  PaymentType: {
    Name: "Jenis Pembayaran", FullName: "Jenis Pembayaran", Code: "PaymentType", Path: "paymenttype"
  },

  // additional
  Version: {
    Name: "Version", FullName: "Version", Code: "Version", Path: "version"
  },

  // support
  Callback: {
    Name: "Callback", FullName: "Callback", Path: "callback"
  },
  ClientAddress: {
    Name: "Alamat", FullName: "Alamat", Path: "clientaddress"
  },
  Company: {
    Name: "Perusahaan", FullName: "Perusahaan", Path: "company"
  },
  Dispatch: {
    Name: "Produk", FullName: "Produk", Path: "dispatch"
  },
  GlobalSearch: {
    Name: "Global Search", FullName: "Global Search", Path: "globalsearch"
  },
  Image: {
    Name: "Gambar", FullName: "Gambar", Path: "image"
  },
  ItemUnit: {
    Name: "Kemasan", FullName: "Kemasan", Path: "itemunit"
  },
  Packaging: {
    Name: "Kemasan", FullName: "Kemasan", Path: "packaging"
  },
  Stock: {
    Name: "Stok Gudang", FullName: "Stok Gudang", Path: "stock"
  },
  Variant: {
    Name: "Variant", FullName: "Variant", Path: "variant"
  },
  VariantValue: {
    Name: "Pilihan Variant", FullName: "Pilihan Variant", Path: "variantvalue"
  },
  VendorAddress: {
    Name: "Alamat", FullName: "Alamat", Path: "vendoraddress"
  },
  RtnDelivery: {
    Name: "Return Pengiriman", FullName: "Return Pengiriman", Path: "rtndelivery"
  },
  User: {
    Name: "User", FullName: "User", Path: "user"
  },

  // DEPRECATED
  InvoiceSettings: {
    Name: "Penomoran Otomatis", FullName: "Penomoran Otomatis",  Code: "InvoiceSettings", Path: "settings/inv"
  }
}