<template>
  <div class="content kst-list">
    <template v-if="theVisible">
      <ul>
        <li
          v-for="(message, index) of items"
          :key="index"
          class="has-text-left"
          v-html="message"
        />
      </ul>
    </template>
    <template v-else>
      <template v-if="!hideEmpty">
        {{ theEmptyList }}
      </template>
    </template>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    items: Array,
    hideEmpty: Boolean,
    // mode: ONLY select 1 (one)
    infoMode: Boolean
  },
  computed: {
    theEmptyList() {
      return this.getEmptyList();
    },
    theVisible() {
      return this.getVisible();
    }
  },
  methods: {
    getEmptyList() {
      if (this.infoMode) {
        return AppCatalog.Message.InfoEmpty;
      }

      return AppCatalog.Message.ListEmpty;
    },
    getVisible() {
      if (this.items === undefined || this.items === null) {
        return false;
      }
      else if (!Array.isArray(this.items)) {
        return false;
      }
      else if (this.items.length === 0) {
        return false;
      }

      return true;
    }
  }
}
</script>

<style>

</style>