<template>
  <div class="mb-4">
    <div class="my-thermal-header">
      {{ companyData.name }}
    </div>
    <div class="my-thermal-subheader">
      {{ companyData.address }}
    </div>
    <div class="my-thermal-subheader">
      HP: {{ companyData.phone }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyData: Object
  }
}
</script>