import HppAdjServices from "@/services/HppAdjServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import ItemServices from "@/services/ItemServices";

export default {
  getHppAdjDetailsData: HppAdjServices.getDetailsData,
  getHppAdjList: HppAdjServices.getList,
  getHppAdjListData: HppAdjServices.getListData,
  postHppAdj: HppAdjServices.postHppAdj,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // load packaging
  getPackagingList: ItemServices.loadPackaging,
}