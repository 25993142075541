import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

export default {
  ClosingNumber: {
    Label: "Nomor Penutupan",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.Select,
    Required: true
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    MaxLength: 50
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 100
  },

  // support
  IsAutoNumber: {
    Label: "Nomor Penutupan",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}