<template>
  <kst-modal
    class="kst-stock-search"
    :width="800"
    :active="active"
    :title="Model.Expense.Title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Cancel"
  >
    <kst-section class="pb-0">
      <kst-table action paginated view-mode
        :data="data.Items"
        :loading="isListLoading"
      >
        <b-table-column sortable
          v-slot="{ column, row }"
          cell-class="ks-col-category"
          field="Name"
          :label="Catalog.Name.Label"
        >
          <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
        </b-table-column>

        <b-table-column numeric sortable
          v-slot="{ column, row }"
          cell-class="ks-col-total"
          field="Total"
          :label="Catalog.Total.Label"
        >
          <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
        </b-table-column>

        <b-table-column sortable centered
          v-slot="{ row }"
          cell-class="ks-col-status"
          field="IsActive"
          :label="Catalog.IsActive.Label"
        >
          <kst-status :value="row.IsActive" :statusEnum="Model.IsActive"/>
        </b-table-column>
      </kst-table>
    </kst-section>

    <div>
      <template v-if="data.TotalActive > 0 && data.TotalNotActive > 0">
        <div class="columns is-mobile">
          <div class="column is-8 is-hidden-mobile"/>
          <div class="column pb-0 is-narrow">
            <div class="title is-6">
              {{ Catalog.TotalActive.Label }}
            </div>
          </div>
          <div class="column pb-0">
            <div class="has-text-right">
              <kst-value class="title is-6" field="TotalActive" :data="data" :catalog="Catalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-8 is-hidden-mobile"/>
          <div class="column is-narrow">
            <div class="title is-6 is-flex">
              {{ Catalog.TotalNotActive.Label }}
            </div>
          </div>
          <div class="column pb-0">
            <div class="has-text-right pb-3 ks-border-b-black">
              <kst-value class="title is-6" field="TotalNotActive" :data="data" :catalog="Catalog"/>
            </div>
          </div>
        </div>
      </template>

      <div class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-6">
            {{ Catalog.GrandTotal.Label }}
          </div>
        </div>
        <div class="column">
          <div class="has-text-right">
            <kst-value class="title is-6" field="GrandTotal" :data="data" :catalog="Catalog"/>
          </div>
        </div>
      </div>
    </div>
  </kst-modal>
</template>

<script>
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Catalog from "../ProfitlossExpenseCatalog.js";
import Model from "../ProfitlossModel.js";

export default {
  mixins: [SnackbarMixin],
  props: {
    data: Object,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    active: false
  }),
  methods: {
    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    show() {
      this.active = true;
    },
    hide() {
      this.active = false;
    }
  }
}
</script>