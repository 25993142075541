<template>
    <kst-container class="px-5 py-5 kst-report-netprofit">
    <Expense ref="cmpExpense" :data="data" :paymentTypeList="paymentTypeList" />
    <div class="pb-4 has-text-weight-semibold kst-dashboard-list-title">
      {{ Info.Sales.Title }}
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
            Penjualan
            <kst-tooltip hover-only :content="Model.Tooltip.Label">
              <kst-button class="kst-btn-report" search-mode is-round @click="handleOnExpense()"/>
            </kst-tooltip>
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
            <kst-value class="is-5" field="TotalBruto" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
            Diskon Penjualan
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
            <kst-value class="is-5" field="TotalDisc" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-regular kst-report-list-title">
            Retur
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
            <kst-value class="is-5" field="TotalRetur" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="columns">
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title">
            Penjualan Bersih
        </div>
      </div>
      <div class="column is-half kst-report-item">
        <div class="has-text-weight-semibold kst-report-list-title has-text-right">
            <kst-value class="is-5" field="Total" :data="data" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    </kst-container>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Info from '../OmzetInfo.js';
import Model from '../OmzetModel.js';
import Catalog from '../OmzetCatalog.js';

import Expense from './Omzet_Result_Expense';

export default {
  mixins: [DialogMixin],
  props: {
    data: Object,
    paymentTypeList: Array,
  },
  components: {
    Expense
  },
  data: () => ({
    Info: Info,
    Model: Model,
    Catalog: Catalog
  }),
  methods: {
    handleOnExpense() {
      this.$refs.cmpExpense.show();
    }
  }
}
</script>