<template>
  <kst-section items-mode class="mt-5">
    <StockSearch show-hpp
      ref="mdlStockSearch"
      @load="handleItem_Load"
      @submit="handleItem_Submit"
    />

    <template #top-right>
      <template v-if="userData.SourceWarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.StockTrfItem.Module.FullName"
          :moduleType="Model.StockTrfItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0" >
        <template v-if="userData.SourceWarehouseID !== null">
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.StockTrfItem.Module.FullName"
            :moduleType="Model.StockTrfItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.SourceWarehouseID === null">
      <kst-warning :items="itemsWarning"/>
    </template>
    <template v-else>
      <List :userData="userData" :userItems="userItems"/>

      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />

      <kst-errors class="mt-4" :items="userData.Errors"/>
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Catalog from "../StockTrfCatalog.js";
import Info from "../StockTrfInfo.js";
import Model from "../StockTrfModel.js";

import StockSearch from "@/components/Stock/Search/StockSearch";
import List from "./StockTrfNew_Items_List";

import Services from "../StockTrfServices.js";

export default {
  mixins: [AxiosMixin],
  components: {
    StockSearch,
    List
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    rowIndex: null,
    item: {},
    failureInit: null
  }),
  computed: {
    itemsWarning() {
      return [Info.Message.New_Items];
    }
  },
  methods: {
    LoadPackaging(itemId) {
      const config = Services.getPackagingList(itemId);
      this.runAxios(config, null, this.LoadPackaging_Success, this.LoadPackaging_Error);
    },
    LoadPackaging_Success(data) {
      let packagingList = { "Packaging": [] };
      for (const packaging of data) {
        if (packaging.IsActive === 1) {
          packagingList.Packaging.push(packaging);
        }
      }

      Model.setItemByItemDetails(this.item, packagingList);
      Model.setDefaultPackaging(this.item);
      this.userItems.push(this.item);
      Model.updateErrors(this.userData, this.userItems);
    },
    LoadPackaging_Error(message) {
      this.failureInit = message;
    },

    handleAdd_Item() {
      this.$refs.mdlStockSearch.show(this.userData.SourceWarehouseID);
    },

    handleItem_Load() {
      Model.validateItemsByStock(this.userData, this.userItems);
    },

    handleItem_Submit(row) {
      let newItem = Model.createItem();
      Model.setItemByStock(newItem, row);
      this.item = {};
      this.item = newItem;
      this.LoadPackaging(row.ItemID);
    }
  }
}
</script>