<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="ItemID"
      :label="StockOpnameItemCatalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockOpnameItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="StockOpnameItemCatalog.Qty.Label"
    >
      <kst-value multi-value :field="column.field" :data="row" :catalog="StockOpnameItemCatalog">
        <kst-value multi-value field="PackagingName" :data="row" :catalog="StockOpnameItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-hpptotal"
      field="HppTotal"
      :label="StockOpnameItemCatalog.HppTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockOpnameItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import StockOpnameItemCatalog from "../StockOpnameItemCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    StockOpnameItemCatalog: StockOpnameItemCatalog
  })
}
</script>