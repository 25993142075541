import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  PONumber: {
    Label: "Nomor PO",
    Type: InputType.Text,
    MaxLength: 50,
    Unique: true,
    Static: true,
    Help: "Nomor saja"
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true,
    RequiredHide: true,
    Help: "Tanggal transaksi"
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Vendor.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Vendor, atau ketik manual.",
      "Vendor tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    RelatedValue: "Vendor",
    RelatedModule: AppModule.Vendor
  },
  Vendor: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 200,
    Required: true
  },
  VendorAlias: {
    Label: "Alias",
    Type: InputType.Text,
    MaxLength: 20,
    ReadOnly: true
  },
  VendorAddress: {
    Label: "Alamat Vendor",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Active: true,
    Exist: true,
    Unique: true,
    Static: true,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  DeliveryAddress: {
    Label: "Dikirim Ke",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  DeliveryDate: {
    Label: "Tanggal Pengiriman",
    Type: InputType.Date
  },
  Status: {
    Label: "Status",
    Type: InputType.Integer,
    ReadOnly: true
  },
  StatusName: {
    Label: "Status",
    Type: InputType.Text,
    ReadOnly: true
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  LastUpdatedBy: {
    Label: AppCatalog.Field.LastUpdatedBy.Label,
    Type: AppCatalog.Field.LastUpdatedBy.Type
  },
  LastUpdatedByID: {
    Label: AppCatalog.Field.LastUpdatedByID.Label,
    Type: AppCatalog.Field.LastUpdatedByID.Type,
    RelatedValue: AppCatalog.Field.LastUpdatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.LastUpdatedByID.RelatedModule
  },
  LastUpdatedDate: {
    Label: AppCatalog.Field.LastUpdatedDate.Label,
    Type: AppCatalog.Field.LastUpdatedDate.Type,
    ReadOnly: AppCatalog.Field.LastUpdatedDate.ReadOnly,
    Output: AppCatalog.Field.LastUpdatedDate.Output,
  },

  // support
  IsAutoNumber: {
    Label: "Nomor PO",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}