<template>
  <kst-container class="kst-item-export-list">
    <kst-form
      :failure="failureList"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :title="title"
      :warnings="formWarnings"
    >
      <Export
        ref="refExport"
        :access="access"
        :searchData="searchData"
        @error="handleError"
      />

      <kst-section export-mode>
        <kst-check disabled v-model="searchData.WithList" :label="labelOptList"/>
        <kst-check v-model="searchData.WithProfit" :label="labelOptProfit"/>
      </kst-section>
      
      <template #bottom-left>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          @click="handleExport"
        />
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../ProfitlossInfo.js";
import Model from "../ProfitlossModel.js";
import Services from "../ProfitlossServices.js";

import Export from "../Export/ProfitlossExport_List";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Export
  },
  data: () => ({
    Model: Model,
    searchData: {},
    access: {},
    items: [],
    isListLoading: true,
    // error
    failureInit: null,
    failureList: null,
    formWarnings: null
  }),
  computed: {
    labelOptList() {
      return Info.Export.List.Excel.Option.List;
    },
    labelOptProfit() {
      return Info.Export.List.Excel.Option.Profit;
    },
    title() {
      return Info.Export.List.Excel.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.formWarnings = null;
      this.access = Model.createAccess();
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      this.searchData = {
        Date: [
          this.getUrlParam(this.$kst.Enum.Param.StartDate),
          this.getUrlParam(this.$kst.Enum.Param.EndDate)
        ],
        WarehouseID: this.getUrlParam(this.$kst.Enum.Param.WarehouseID),
        WithList: true,
        WithProfit: true
      }
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadListData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadListData(searchData) {
      let options = {};
      if (searchData) {
        options.startDate = this.searchData.Date[0],
        options.endDate = this.searchData.Date[1],
        options.warehouseId = this.searchData.WarehouseID
      }

      const config = Services.getProfitLoss(options);
      this.runAxios(config, null,
        this.loadListData_Success,
        this.loadListData_Error,
        this.loadListData_Invalid
      );
    },
    loadListData_Success(data) {
      this.access = data.Access;
      this.initLoad_End();
    },
    loadListData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadListData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    handleError(message) {
      this.failureList = message;
    },
    handleExport() {
      this.failureList = null;
      this.$refs.refExport.exportExcel();
    }
  }
}
</script>