<template>
  <span/>
</template>

<script>
  import AxiosMixin from "@/mixins/AxiosMixin.js";
  import LoadingMixin from "@/mixins/LoadingMixin.js";
  import XlsxMixin from "@/mixins/XlsxMixin.js";
  import Catalog from "../SalesCatalog.js";
  import ItemCatalog from "../SalesItemCatalog.js";
  import Info from "../SalesInfo.js";
  import InvoiceCatalog from "@/components/Invoice/InvoiceCatalog.js";
  import Services from "../SalesServices.js";

  export default {
    mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      if (this.searchData.WithInvoice) {
        this.createSheet();
        this.addInvoice(data);
      }

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getSalesDetails(id, {withinvoice: 1});
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);
      this.addDetails_Item(data.Items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.DraftNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAlias);
      this.addColumnHeader(Catalog.ClientName);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.StatusName);
      this.addColumnHeader(Catalog.Total);

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("DraftNumber", data, Catalog);
      this.addCellValue("Date", data, Catalog);
      this.addCellValue("ClientID", data, Catalog);
      this.addCellValue("ClientAlias", data, Catalog);
      this.addCellValue("ClientName", data, Catalog);
      this.addCellValue("ClientAddress", data, Catalog);
      this.addCellValue("WarehouseName", data, Catalog);
      this.addCellValue("PONumber", data, Catalog);

      if (data.DiscValue) {
        this.addCellValue("DiscValue", data, Catalog);
      }
      else {
        this.addCellValue("DiscPercent", data, Catalog);
      }

      this.addCellValue("Comment", data, Catalog);
      this.addCellValue("StatusName", data, Catalog);
      this.addCellValue("Total", data, Catalog);
    },
    addDetails_Item(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.SalesItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(ItemCatalog.DispatchName);
      this.addColumnHeader(ItemCatalog.SKU);
      this.addColumnHeader(ItemCatalog.QtyInvoice);
      this.addColumnHeader(ItemCatalog.Packaging);
      this.addColumnHeader(ItemCatalog.Qty);
      this.addColumnHeader(ItemCatalog.SellPrice);
      this.addColumnHeader(ItemCatalog.Total);
      this.addColumnHeader(ItemCatalog.IsClosed);

      // value
      for ( const row of items ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("DispatchName", row, ItemCatalog);
      this.addCellValue("SKU", row, ItemCatalog);
      this.addCellValue("QtyInvoice", row, ItemCatalog);
      this.addCellValue("Packaging", row, ItemCatalog);
      this.addCellValue("Qty", row, ItemCatalog);
      this.addCellValue("SellPrice", row, ItemCatalog);
      this.addCellValue("Total", row, ItemCatalog);
      this.addCellValue("IsClosed", row, ItemCatalog);
      }
    },
    addInvoice(data) {
      this.addInvoice_Details(data)

      this.addSheet(Info.Export.Details.Excel.SheetName.Invoice);
    },
    addInvoice_Details(data) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(InvoiceCatalog.SONumber);
      this.addColumnHeader(InvoiceCatalog.WarehouseName);
      this.addColumnHeader(InvoiceCatalog.InvoiceDate);
      this.addColumnHeader(InvoiceCatalog.Total);
      this.addColumnHeader(Catalog.StatusName);

      // value
      for ( const row of data.InvoiceList ) {
        console.log(data)
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("SONumber", row, InvoiceCatalog);
      this.addCellValue("WarehouseName", row, InvoiceCatalog);
      this.addCellValue("InvoiceDate", row, InvoiceCatalog);
      this.addCellValue("Total", row, InvoiceCatalog);
      }
      this.addCellValue("StatusName", data, Catalog);
    }
  }
}
</script>