<template>
  <kst-modal
    class="kst-sales-item-search"
    :width="800"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-subtitle>{{ subTitle }}</kst-subtitle>
    <List
      :items="items"
      :userData="userData"
    />
  </kst-modal>
</template>

<script>
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../SalesInfo.js";

import List from "./SalesItemSearch_List";

export default {
  props: {
    items: Array,
  },
  mixins: [SnackbarMixin],
  components: {
    List
  },
  data: () => ({
    userData: {},
    active: false
  }),
  computed: {
    subTitle() {
      return Info.Section.ItemSearch.Title;
    },
    title() {
      return Info.Dialog.ItemSearch.Title;
    }
  },
  methods: {
    init() {
      this.userData = {
        selected: null
      };
    },

    show() {
      this.init();
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    }
  }
}
</script>