<template>
  <kst-container class="kst-sales-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <Details
          :userData="userData"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
          :cashierOptions="cashierOptions"
        />
        <Invoices 
          :userData="userData" 
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
          :cashierOptions="cashierOptions"
          :isListLoading="isListLoading"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        />
        <Total :userData="userData"/>
        <Details2
          class="mt-5"
          :userData="userData"
          :userItems="userItems"
        />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../SalesRecapModel.js";
import Router from "../SalesRecapRouter.js";
import Services from "../SalesRecapServices.js";

import Details from "./SalesRecapNew_Details";
import Details2 from "./SalesRecapNew_Details2";
import Invoices from "./SalesRecapNew_Invoices";
import Total from "./SalesRecapNew_Total";

export default {
  components: {
    Details,
    Details2,
    Invoices,
    Total
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    userItems: [],
    isLoading: false,
    isListLoading: true,
    saveLoading: null,
    warehouseOptions: {},
    cashierOptions: {},
    cancelUrl: Router.Path.List,
    // support debounce
    loadCounter: 0,
    // error
    errors: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userData = Model.createDetails();
      this.userItems = [];

      this.initLoad();
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      let options = {}
      options.active = Model.WarehouseStatus.Active.ID;

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.loadCashier();
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadCashier() {
      const config = Services.getCashierList();
      this.runAxios(config, null, this.loadCashier_Success, this.loadCashier_Error);
    },
    loadCashier_Success(data) {
      this.cashierOptions = Model.getCashierOptions(data);
      this.loadList(this.loadCounter, this.userData);
      this.initLoad_End();
    },
    loadCashier_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadList(counter, searchData) {
      if (searchData.WarehouseID === null || searchData.CashierID === null) {
        return;
      }

      this.failureList = null;
      this.isListLoading = true;

      let options = {};
      if (searchData) {
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
        options.cashier = this.$kst.Search.getValue(searchData.CashierID);
        options.startInvoice = this.$kst.Out.getDate(searchData.InvoiceDate);
        options.endInvoice = options.startInvoice;
        options.isrecap = 0;
      }

      const config = Services.getInvoiceList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }
      this.userItems = data;
      // Model.setInvoiceList(this.userItems, data);
      Model.updateDetails(this.userData, this.userItems);
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.userData);
    },
    handleSearch_Reset() {
      Model.updateDetailsByReset(this.userData, this.userItems);
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postSalesRecap(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>