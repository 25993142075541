import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Catalog from "./SalesCatalog.js";
import SalesItemCatalog from "./SalesItemCatalog.js";
import ItemModel from "@/components/Item/ItemModel.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.Sales,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    Export: AppModuleType.Export,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    Print: AppModuleType.Print,
    Search: AppModuleType.Search
  },

  /*** related ***/

  SalesItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List,
    }
  },

  Client: {
    Module: AppModule.Client,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      List: AppModuleType.List,
      New: AppModuleType.New
    }
  },

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      Select: AppModuleType.Select
    }
  },

  /*** property ***/

  DiscType: {
    None: {
      ID: 0,
      Label: "Tanpa Diskon",
    },
    Value: {
      ID: 1,
      Label: "Diskon (Rp)",
    },
    Percent: {
      ID: 2,
      Label: "Diskon (%)",
    }
  },

  Search: {
    Fields: ["DraftNumber", "PONumber", "ClientName"]
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label,
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label,
    }
  },

  IsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.Class,
      Label: App.Enum.Status.InActive.Label,
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails(this.DiscType);
  },
  createItem() {
    return this.helper.createItem();
  },
  createStatusOptions(optionAllText) {
    return this.helper.createStatusOptions(optionAllText, this.Status);
  },
  createDiscTypeOptions(optionAllText) {
    return this.helper.createDiscTypeOptions(optionAllText, this.DiscType);
  },

  setDetailsByData(details, detailsData) {
    this.helper.setDetailsByData(details, detailsData, this.DiscType);
  },
  setDetailsByClient(details, clientData) {
    this.helper.setDetailsByClient(details, clientData);
  },
  setDetailsByStatus(details) {
    this.helper.setDetailsByStatus(details, this.Status);
  },

  normDetailsByDisc(data) {
    this.helper.normDetailsByDisc(data, this.DiscType);
  },

  setItemByData(item, itemData) {
    this.helper.setItemByData(item, itemData);
  },
  setItemByPackaging(item) {
    this.helper.setItemByPackaging(item);
  },
  setItemByPrice(item, itemData) {
    this.helper.setItemByPrice(item, itemData);
  },
  setItemErrors(item, field, errors) {
    this.helper.setItemErrors(item, field, errors);
  },

  setItemsByData(items, rows) {
    this.helper.setItemsByData(items, rows);
  },
  setItemByDispatchDetails(item, dispatchData) {
    this.helper.setItemByDispatchDetails(item, dispatchData);
  },
  setItemByStock(item, stockData) {
    this.helper.setItemByStock(item, stockData);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getItemInfo_Details(item) {
    return this.helper.getItemInfo_Details(item);
  },
  getItemInfo_Edit(item) {
    return this.helper.getItemInfo_Edit(item);
  },

  clearDetailsByClient(details) {
    this.helper.clearDetailsByClient(details);
  },

  updateItem(item) {
    this.helper.updateItem(item);
  },
  updateItemByPackaging(item) {
    this.helper.updateItemByPackaging(item);
  },
  updateDetails(data, items) {
    this.helper.updateDetails(data, items, this.DiscType);
  },

  populateData(data, items) {
    let result = this.helper.populateDetails(data, this.DiscType);
    result.Items = this.helper.populateItems(items);
    return result;
  },

  validationDiscType(data) {
    return { max_value: data.TotalBruto };
  },
  validateItem_Edit(item) {
    this.helper.validateItem_Edit(item);
  },
  validationNumber(data) {
    return data.IsAutoNumber ? {} : { required: true };
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createDetails(discTypeEnum) {
      return {
        ID: null,
        CompanyID: App.Session.getCompanyID(),
        // user input
        DraftNumber: "",
        IsAutoNumber: true,
        Date: App.In.getDateToday(),
        WarehouseID: null,
        PONumber: "",
        DiscType: discTypeEnum.None.ID,
        DiscValue: "",
        DiscPercent: "",
        ClientID: null,
        ClientAlias: "",
        ClientName: "",
        ClientAddress: "",
        Comment: "",
        // defined by system
        WarehouseName: null,
        StatusID: null,
        TotalBruto: 0,
        TotalDisc: 0,
        Total: 0,
        InvoiceCount: 0,
        CreatedByID: App.Session.getUserID(),
        LastUpdatedByID: App.Session.getUserID()
      };
    },
    createItem() {
      return {
        ID: null,
        // user input
        DispatchID: null,
        DispatchName: "",
        PackagingName: "",
        Qty: "",
        SellPrice: "",
        IsClosed: false,
        // defined by system
        SKU: "",
        Packaging: "",
        PackagingValue: 1,
        QtyInvoice: 0,
        QtyAvailable: 0,
        QuantityLeft: 0,
        Stock: 0,
        TotalQty: 0,
        Total: 0,
        DispatchIsActive: true,
        DispatchSellPrice: 0,
        PackagingOptions: null,
        // error
        Errors: [],
        ErrorsColl: {},
        // UI validation
        Qty_Valid: true,
        Qty_vsDeleted: true,
        Qty_vsInvoice: true
      }
    },
    createStatusOptions(optionAllText, statusEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: statusEnum.Open.ID, [rowLabel]: statusEnum.Open.Label },
        { [rowId]: statusEnum.Closed.ID, [rowLabel]: statusEnum.Closed.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
    createDiscTypeOptions(optionAllText, discTypeEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
        { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
        { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setDetailsByData(details, detailsData, discTypeEnum) {
      details.ID = detailsData.ID;
      // user input
      details.DraftNumber = App.In.getString(detailsData.DraftNumber);
      details.Date = App.In.getDate(detailsData.Date);
      details.WarehouseID = detailsData.WarehouseID;
      details.PONumber = App.In.getString(detailsData.PONumber);
      details.ClientID = detailsData.ClientID;
      details.ClientAlias = detailsData.ClientAlias;
      details.ClientName = App.In.getString(detailsData.ClientName);
      details.ClientAddress = App.In.getString(detailsData.ClientAddress);
      details.Comment = App.In.getString(detailsData.Comment);
      // defined by system
      details.WarehouseName = detailsData.WarehouseName;
      details.StatusID = detailsData.StatusID;
      details.TotalBruto = detailsData.TotalBruto;
      details.TotalDisc = detailsData.TotalDisc;
      details.Total = detailsData.Total;
      details.InvoiceCount = detailsData.InvoiceCount;

      if (detailsData.DiscValue !== null) {
        details.DiscType = discTypeEnum.Value.ID;
        details.DiscValue = App.In.getString(detailsData.DiscValue);
      }
      else if (detailsData.DiscPercent !== null) {
        details.DiscType = discTypeEnum.Percent.ID;
        details.DiscPercent = App.In.getString(detailsData.DiscPercent);

        this.normDetailsByDisc(details, discTypeEnum);
      }
      else {
        details.DiscType = discTypeEnum.None.ID;
      }
    },
    setDetailsByClient(details, clientData) {
      // user input
      details.ClientID = clientData.ClientID;
      details.ClientName = App.In.getString(clientData.ClientName);
      details.ClientAddress = App.In.getString(clientData.Address);
      // defined by system
      details.ClientAlias = clientData.Alias;
    },
    setDetailsByStatus(details, statusEnum) {
      for (const key in statusEnum) {
        const statusObj = statusEnum[key];

        if (statusObj.ID === details.StatusID) {
          details.StatusName = statusObj.Label;
          return;
        }
      }
    },

    normDetailsByDisc(data, discTypeEnum) {
      if (data.DiscType === discTypeEnum.Percent.ID) {
        data.DiscPercent = App.Value.getValue("DiscPercent", data, Catalog);
      }
    },

    setItemByData(item, itemData) {
      item.ID = itemData.ID;
      // user input
      item.DispatchID = itemData.DispatchID;
      item.DispatchName = App.In.getString(itemData.DispatchName);
      item.Qty = App.In.getInteger(itemData.Qty);
      item.SellPrice = App.In.getInteger(itemData.SellPrice);
      item.IsClosed = App.In.getBoolean(itemData.IsClosed);
      // defined by system
      item.SKU = itemData.SKU;
      item.QtyInvoice = itemData.QtyInvoice;
      item.QuantityLeft = itemData.DispatchQty;
      item.DispatchIsActive = App.In.getBoolean(itemData.DispatchIsActive);
      item.DispatchSellPrice = itemData.DispatchSellPrice;
      item.PackagingOptions = ItemModel.getPackagingOptions(itemData.PackagingList);

      if (itemData.PackagingIsActive === 1 || itemData.QtyInvoice > 0) {
        item.PackagingName = itemData.PackagingName;
        item.PackagingValue = itemData.PackagingValue;
      }
    },
    setItemByPackaging(item) {
      if (!item.PackagingName) {
        if (item.PackagingOptions.rows.length > 0) {
          const packaging = item.PackagingOptions.rows[0];
          item.PackagingName = packaging.ItemUnitName;
          item.PackagingValue = packaging.ItemUnitAmount;
        }
      }
    },
    setItemByPrice(item, itemData) {
      // user input
      item.SellPrice = App.In.getInteger(itemData.SellPrice);
    },
    setItemErrors(item, field, errors) {
      // populate error in current field
      item.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          item.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in item.ErrorsColl) {
        fieldErrors = item.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!item.Qty_Valid) {
        if (!item.Qty_vsDeleted) {
          newErrors.push(SalesItemCatalog.DispatchID.Label + " tidak aktif, mohon dihapus");
        }

        if (!item.Qty_vsInvoice) {
          newErrors.push("Minimum nilai " + SalesItemCatalog.Qty.Label + " adalah " +
            App.Value.getValue("QtyInvoice", item, SalesItemCatalog) + " " +
            "(" + SalesItemCatalog.QtyInvoice.Label + ")"
          );
        }
      }

      item.Errors = newErrors;
    },

    setItemsByData(items, rows) {
      App.Array.truncate(items);

      for (const row of rows) {
        let item = this.createItem();
        this.setItemByData(item, row);
        this.updateItem(item);
        items.push(item);
      }
    },
    setItemByDispatchDetails(item, dispatchData) {
      item.DispatchName = dispatchData.FullName;
      item.SKU = dispatchData.SKU;
      item.SellPrice = App.In.getInteger(dispatchData.SellPrice);
      item.DispatchSellPrice = dispatchData.SellPrice;
      item.PackagingOptions = ItemModel.getPackagingOptions(dispatchData.Packaging);
    },
    setItemByStock(item, stockData) {
      item.DispatchID = stockData.DispatchID;
      item.QuantityLeft = stockData.Quantity;
    },

    getItemInfo(item) {
      let infoList = [];

      // SKU
      infoList.push(SalesItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, SalesItemCatalog)
      );

      // Stock
      infoList.push(SalesItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, SalesItemCatalog) + " " + item.PackagingName
      );

      return infoList;
    },
    getItemInfo_Details(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(SalesItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, SalesItemCatalog)
      );

      // QtyInvoice
      infoList.push(SalesItemCatalog.QtyInvoice.Label + ": " +
        App.Value.getValue("QtyInvoice", item, SalesItemCatalog)
      );

      return infoList;
    },
    getItemInfo_Edit(item) {
      let infoList = [];

      // deleted item
      if (!item.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }

      // SKU
      infoList.push(SalesItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, SalesItemCatalog)
      );

      // Stock
      infoList.push(SalesItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, SalesItemCatalog) + " " + item.PackagingName
      );

      // QtyInvoice
      infoList.push(SalesItemCatalog.QtyInvoice.Label + ": " +
        App.Value.getValue("QtyInvoice", item, SalesItemCatalog)
      );

      return infoList;
    },

    clearDetailsByClient(details) {
      details.ClientID = null;
      details.ClientName = "";
      details.ClientAlias = "";
      details.ClientAddress = "";
    },

    updateItem(item) {
      if (item.PackagingOptions) {
        let packagingValue = ItemModel.getPackagingValue(
          item.PackagingOptions.rows, item.PackagingName
        );

        if (packagingValue !== undefined) {
          item.PackagingValue = packagingValue;
        }
      }

      this.updateItemByPackaging(item);
      item.QtyAvailable = item.Qty - item.QtyInvoice;
      item.Stock = App.Data.getQtyByPackaging(item.QuantityLeft, item.PackagingValue);
      item.TotalQty = App.Data.getTotalQty(item.Qty, item.PackagingValue);
      item.Total = App.Data.getTotal(item.Qty, item.PackagingValue, item.SellPrice);
    },
    updateItemByPackaging(item) {
      item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    },
    updateDetails(data, items, discTypeEnum) {
      let total = 0;

      // TotalBruto
      for (const item of items) {
        total += item.Total;
      }
      data.TotalBruto = total;

      // TotalDisc
      if (data.DiscType === discTypeEnum.Value.ID) {
        data.TotalDisc = App.JS.parseInt(data.DiscValue);
        total -= data.TotalDisc;
      }
      else if (data.DiscType === discTypeEnum.Percent.ID) {
        data.TotalDisc = App.Data.getDiscPercent_Value(total, data.DiscPercent);
        total -= data.TotalDisc;
      }

      // Total
      data.Total = total;
    },

    populateDetails(data, discTypeEnum) {
      return {
        ID: data.ID,
        CompanyID: data.CompanyID,
        WarehouseID: data.WarehouseID,
        DraftNumber: data.IsAutoNumber ? null : App.Out.getString(data.DraftNumber),
        Date: App.Out.getDate(data.Date),
        PONumber: App.Out.getString(data.PONumber),
        ClientID: data.ClientID,
        ClientName: App.Out.getString(data.ClientName),
        ClientAddress: App.Out.getString(data.ClientAddress),
        DiscValue: (data.DiscType === discTypeEnum.Value.ID ? parseInt(data.DiscValue) : null),
        DiscPercent: (data.DiscType === discTypeEnum.Percent.ID ? parseFloat(data.DiscPercent) : null),
        Comment: App.Out.getString(data.Comment),
        CreatedByID: data.CreatedByID,
        LastUpdatedByID: data.LastUpdatedByID,
      }
    },
    populateItems(items) {
      let resultItems = [];

      for (const item of items) {
        resultItems.push({
          ID: item.ID,
          DispatchID: item.DispatchID,
          DispatchName: App.Out.getString(item.DispatchName),
          Qty: App.Out.getInteger(item.Qty),
          PackagingName: item.PackagingName,
          PackagingValue: item.PackagingValue,
          SellPrice: App.Out.getInteger(item.SellPrice),
          IsClosed: App.Out.getBoolean(item.IsClosed)
        });
      }

      return resultItems;
    },

    validateItem_Edit(item) {
      item.Qty_Valid = true;
      item.Qty_vsInvoice = true;
      item.Qty_vsDeleted = true;

      // validate: deleted item
      if (!item.DispatchIsActive) {
        if (!item.IsClosed) {
          if (item.QtyInvoice === 0) {
            item.Qty_Valid = false;
            item.Qty_vsDeleted = false;
          }
        }
      }

      const qty = App.Out.getInteger(item.Qty);
      if (qty !== null) {
        if (qty < item.QtyInvoice) {
          item.Qty_Valid = false;
          item.Qty_vsInvoice = false;
        }
      }
    }
  }
}