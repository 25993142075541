<template>
  <kst-section items-mode class="mt-5">
    <kst-table view-mode :data="items">

      <b-table-column sortable
        v-slot="{ column, row }"
        field="Name"
        :label="DeliveryItemCatalog.Name.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DeliveryItemCatalog"/>
      </b-table-column>

      <b-table-column sortable
        v-slot="{ column, row }"
        field="Packaging"
        :label="DeliveryItemCatalog.Packaging.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DeliveryItemCatalog"/>
      </b-table-column>

      <b-table-column sortable
        v-slot="{ column, row }"
        field="Quantity"
        :label="DeliveryCatalog.Quantity.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DeliveryCatalog"/>
      </b-table-column>

      <b-table-column sortable
        v-slot="{ column, row }"
        field="Weight"
        :label="DeliveryItemCatalog.Weight.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DeliveryItemCatalog"/>
      </b-table-column>

    </kst-table>
  </kst-section>
</template>

<script>
import DeliveryItemCatalog from "../DeliveryItemCatalog.js";
import DeliveryCatalog from "../DeliveryCatalog.js";
import Model from "../DeliveryModel.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    DeliveryItemCatalog: DeliveryItemCatalog,
    DeliveryCatalog: DeliveryCatalog,
    Model: Model
  })
}
</script>