import Model from "./PosModel.js";
import PosItemCatalog from "./PosItemCatalog.js";

export default {
  Result: {
    Title: "Ringkasan"
  },
  Total: {
    Title: "Total Harga"
  },
  Payment: {
    Title: "Pembayaran"
  },
  Save: {
    Title: "Simpan"
  },
  Cancel: {
    Title: "Batal"
  },
  Tabs: {
    Scanner: "Scanner",
    Search: "Cari Barang"
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.Item.Module.FullName + "?",
  },
  Dialog: {
    EditSellPrice: {
      Title: "Input " + PosItemCatalog.SellPrice.Label
    }
  }
}