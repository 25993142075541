// default output, see: "@/services/Input/OutputType.js"
export default {
  Boolean: "Boolean",
  Date: "Date",
  DateTime: "DateTime", // coming soon
  Decimal: "Decimal",
  Email: "Email",
  ID: "ID",
  Integer: "Integer",
  Password: "Password",
  Percent: "Percent",
  Phone: "Phone",
  Qty: "Qty",
  Select: "Select",
  Text: "Text",
  TextArea: "TextArea",
  Url: "Url"
}