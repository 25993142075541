<template>
  <kst-modal
    :width="800"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <ValidationObserver ref="formDetails">
      <kst-input load-on-focus field="SellPrice" :data="userData" :catalog="PosItemCatalog"/>
    </ValidationObserver>
  </kst-modal>
</template>

<script>
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import PosItemCatalog from "./PosItemCatalog.js";
import Info from "./PosInfo.js";

export default {
  mixins: [LoadingMixin, SnackbarMixin],
  data: () => ({
    PosItemCatalog: PosItemCatalog,
    userData: {
      SellPrice: ""
    },
    active: false
  }),
  computed: {
    title() {
      return Info.Dialog.EditSellPrice.Title;
    }
  },
  methods: {
    show() {
      this.userData.SellPrice = "";
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    handleModal_Cancel() {
      this.hide();
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      this.hide();
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.SellPrice);
    }
  }
}
</script>