import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.invoice;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "client", "invoice", "warehouse",
      "sales", "status", "field", "isneeddelivery", "withitems", "isrecap",
      "cashier", "salesrecap", "startInvoice", "endInvoice"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesorder/list" + urlParams
    };
  },
  getDetails(id, params) {
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["withdelivery", "withdeliveryitems", "withdeliverycount",
      "withpackaging", "withsales", "withstock", "withrecap"];
    const urlParams = App.Out.getParams(options, attributes);

    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesorder/" + cid + "/" + id + urlParams
    };
  },
  getDetailsData(id, params) {
    // options: equals to getDetails()
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["withdelivery", "withdeliveryitems", "withdeliverycount", "withoutdetails", "withrecap"];
    const urlParams = App.Out.getParams(options, attributes);

    const cid = App.Session.getCompanyID();
    const uid = App.Session.getUserID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesorder/details/" + cid + "/" + uid + "/" + id + urlParams
    };
  },
  getNewData(params) {
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["sales"];
    const urlParams = App.Out.getParams(options, attributes);

    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesorder/newdata/" + cid + urlParams
    };
  },
  getEditData(id) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesorder/editdata/" + cid + "/" + id
    };
  },
  postInvoice(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/salesorder",
      data: data
    };
  },
  postInvoiceNew(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/salesordernew",
      data: data
    };
  },
  putInvoice(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/salesorder",
      data: data
    };
  },
  /*
  patchInvoice(component, data) {
    return component.$http.patch(apiEndpoint + "/api/v1/salesorder/", data);
  },
  */

  /* sell price */

  getPriceList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "item", "clientid", "client", "name", "excludeid", 
      "startInvoice", "endInvoice"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/salesorder/price/" + urlParams
    };
  }
}