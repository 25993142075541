import AppCatalog from "@/services/App/AppCatalog.js";
import Catalog from "../DSPCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.FullName.Label + ", atau " +
      Catalog.SKU.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  },
  StockID: {
    Label: AppCatalog.Field.StockID.Label,
    Type: AppCatalog.Field.StockID.Type
  }
}