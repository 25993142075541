<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";
import Services from "../InvoiceServices.js";
import Catalog from "../InvoiceCatalog.js";
import DeliveryCatalog from "@/components/Delivery/DeliveryCatalog.js";
import DeliveryModel from "@/components/Delivery/DeliveryModel.js";
import InvoiceItemCatalog from "../InvoiceItemCatalog.js";
import InvoiceProfitCatalog from "../InvoiceProfitCatalog.js";
import RtnInvoiceCatalog from "@/components/RtnInvoice/RtnInvoiceCatalog.js";
import RtnInvoiceProfitCatalog from "@/components/RtnInvoice/RtnInvoiceProfitCatalog.js";
import SalesRecapCatalog from "@/components/SalesRecap/SalesRecapCatalog.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      if (this.searchData.WithDelivery) {
        if (data.IsNeedDelivery) {
          this.createSheet();
          this.addDelivery(data);
        }
      }

      if (this.searchData.WithRtnInvoice) {
        this.createSheet();
        this.addRtnInvoice(data);
      }

      if (this.access.UI.Profit !== false) {
        if (this.searchData.WithProfit) {
          this.createSheet();
          this.addProfit(data);
        }
      }

      if (this.searchData.WithSalesRecap) {
        this.createSheet();
        this.addSalesRecap(data);
      }

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getInvoiceDetails(id, { withdelivery: 1, withdeliveryitems: 1, withrecap: 1 });
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      for (const item of data.Items) {
        Model.updateItemSpecialPrice(item);
      }

      if (data.SalesRecapID === null) {
        data.SalesRecapDetails = [];
      }

      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);
      this.addDetails_Items(data.Items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addColumnHeader(Catalog.DraftNumber);
      this.addColumnHeader(Catalog.DraftDate);

      this.addColumnHeader(Catalog.SONumber);
      this.addColumnHeader(Catalog.InvoiceDate);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAlias);
      this.addColumnHeader(Catalog.Client);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.Status);

      this.addColumnHeader(Catalog.IsNeedDelivery);
      this.addColumnHeader(Catalog.DeliveryDate);

      this.addColumnHeader(Catalog.TotalBruto);
      this.addColumnHeader(Catalog.TotalDisc);
      this.addColumnHeader(Catalog.Total);

      // value
      this.addRow();
      this.addCellValue("DraftNumber", data, Catalog);
      this.addCellValue("DraftDate", data, Catalog);

      this.addCellValue("SONumber", data, Catalog);
      this.addCellValue("InvoiceDate", data, Catalog);
      this.addCellValue("ClientID", data, Catalog);
      this.addCellValue("ClientAlias", data, Catalog);
      this.addCellValue("Client", data, Catalog);
      this.addCellValue("ClientAddress", data, Catalog);
      this.addCellValue("WarehouseName", data, Catalog);
      this.addCellValue("PONumber", data, Catalog);

      if (data.DiscValue) {
        this.addCellValue("DiscValue", data, Catalog);
      }
      else {
        this.addCellValue("DiscPercent", data, Catalog);
      }

      this.addCellValue("Comment", data, Catalog);
      this.addCellStatus(data.Status, Model.Status);

      this.addCellValue("IsNeedDelivery", data, Catalog);
      this.addCellValue("DeliveryDate", data, Catalog);

      this.addCellValue("TotalBruto", data, Catalog);
      this.addCellValue("TotalDisc", data, Catalog);
      this.addCellValue("Total", data, Catalog);
    },
    addDetails_Items(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.Items);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(InvoiceItemCatalog.Name);
      this.addColumnHeader(InvoiceItemCatalog.SKU);
      this.addColumnHeader(InvoiceItemCatalog.PackagingName);
      this.addColumnHeader(InvoiceItemCatalog.PackagingValue);
      this.addColumnHeader(InvoiceItemCatalog.RequestedQuantity);
      this.addColumnHeader(InvoiceItemCatalog.SellPrice);

      if (this.searchData.SpecialPriceCount > 0) {
        this.addColumnHeader(InvoiceItemCatalog.SpecialPriceName);
        this.addColumnHeader(InvoiceItemCatalog.DiscountedValue);
      }

      this.addColumnHeader(InvoiceItemCatalog.Price);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("Name", row, InvoiceItemCatalog);
        this.addCellValue("SKU", row, InvoiceItemCatalog);
        this.addCellValue("PackagingName", row, InvoiceItemCatalog);
        this.addCellValue("PackagingValue", row, InvoiceItemCatalog);
        this.addCellValue("RequestedQuantity", row, InvoiceItemCatalog);
        this.addCellValue("SellPrice", row, InvoiceItemCatalog);

        if (this.searchData.SpecialPriceCount > 0) {
          this.addCellValue("SpecialPriceName", row, InvoiceItemCatalog);
          this.addCellValue("DiscountedValue", row, InvoiceItemCatalog);
        }

        this.addCellValue("Price", row, InvoiceItemCatalog);
      }
    },

    addDelivery(data) {
      const itemsSummary = Model.getItemsSummary(data, data.DeliveryList);

      this.addDelivery_Summary(itemsSummary);
      this.addDelivery_List(data.DeliveryList);

      this.addSheet(Info.Export.Details.Excel.SheetName.Delivery);
    },
    addDelivery_Summary(items) {
      this.addCellSection(Info.Export.Details.Excel.Section.Summary);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(InvoiceItemCatalog.Name);
      this.addColumnHeader(InvoiceItemCatalog.PackagingName);
      this.addColumnHeader(InvoiceItemCatalog.PackagingValue);
      this.addColumnHeader(InvoiceItemCatalog.QtyInvoice);
      this.addColumnHeader(InvoiceItemCatalog.DeliveryQuantity);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("Name", row, InvoiceItemCatalog);
        this.addCellValue("PackagingName", row, InvoiceItemCatalog);
        this.addCellValue("PackagingValue", row, InvoiceItemCatalog);
        this.addCellValue("RequestedQuantity", row, InvoiceItemCatalog);
        this.addCellValue("DeliveryQuantity", row, InvoiceItemCatalog);
      }
    },
    addDelivery_List(items) {
      this.addRow();
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(DeliveryCatalog.LogisticsNumber);
      this.addColumnHeader(DeliveryCatalog.VehicleIDNumber);
      this.addColumnHeader(DeliveryCatalog.Destination);
      this.addColumnHeader(DeliveryCatalog.TotalWeight);
      this.addColumnHeader(DeliveryCatalog.Status);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("LogisticsNumber", row, DeliveryCatalog);
        this.addCellValue("VehicleIDNumber", row, DeliveryCatalog);
        this.addCellValue("Destination", row, DeliveryCatalog);
        this.addCellValue("TotalWeight", row, DeliveryCatalog);
        this.addCellStatus(row.Status, DeliveryModel.Status);
      }
    },

    addRtnInvoice(data) {
      this.addRtnInvoice_List(data.ReturnInvoice);

      this.addSheet(Info.Export.Details.Excel.SheetName.RtnInvoice);
    },
    addRtnInvoice_List(items) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(RtnInvoiceCatalog.ReturnNumber);
      this.addColumnHeader(RtnInvoiceCatalog.Date);
      this.addColumnHeader(RtnInvoiceCatalog.WarehouseName);
      this.addColumnHeader(RtnInvoiceCatalog.Total);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("ReturnNumber", row, RtnInvoiceCatalog);
        this.addCellValue("Date", row, RtnInvoiceCatalog);
        this.addCellValue("WarehouseName", row, RtnInvoiceCatalog);
        this.addCellValue("Total", row, RtnInvoiceCatalog);
      }
    },

    addProfit(data) {
      Model.setDetailsByProfit(data);
      Model.updateItemsByProfit(data.Items);

      this.addProfit_Summary(data);
      this.addProfit_Invoice(data.Items);
      this.addProfit_RtnInvoice(data.ReturnInvoice);

      this.addSheet(Info.Export.Details.Excel.SheetName.Profit);
    },
    addProfit_Summary(data) {
      this.addColumnHeader(InvoiceProfitCatalog.TotalProfit);
      this.addCellValue("TotalProfit", data, InvoiceProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvoiceProfitCatalog.TotalBruto);
      this.addCellValue("TotalBruto", data, InvoiceProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvoiceProfitCatalog.TotalDisc);
      this.addCellValue("TotalDisc", data, InvoiceProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvoiceProfitCatalog.TotalReturn);
      this.addCellValue("TotalReturn", data, InvoiceProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvoiceProfitCatalog.TotalHpp);
      this.addCellValue("TotalHpp", data, InvoiceProfitCatalog);
    },
    addProfit_Invoice(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ProfitInvoice);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(InvoiceProfitCatalog.Name);
      this.addColumnHeader(InvoiceProfitCatalog.Price);
      this.addColumnHeader(InvoiceProfitCatalog.HppTotal);
      this.addColumnHeader(InvoiceProfitCatalog.Profit);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("Name", row, InvoiceProfitCatalog);
        this.addCellValue("Price", row, InvoiceProfitCatalog);
        this.addCellValue("HppTotal", row, InvoiceProfitCatalog);
        this.addCellValue("Profit", row, InvoiceProfitCatalog);
      }
    },
    addProfit_RtnInvoice(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ProfitRtnInvoice);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(RtnInvoiceProfitCatalog.ReturnNumber);
      this.addColumnHeader(RtnInvoiceProfitCatalog.TotalBruto);
      this.addColumnHeader(RtnInvoiceProfitCatalog.TotalDisc);
      this.addColumnHeader(RtnInvoiceProfitCatalog.Total);
      this.addColumnHeader(RtnInvoiceProfitCatalog.HppTotal);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("ReturnNumber", row, RtnInvoiceProfitCatalog);
        this.addCellValue("TotalBruto", row, RtnInvoiceProfitCatalog);
        this.addCellValue("TotalDisc", row, RtnInvoiceProfitCatalog);
        this.addCellValue("Total", row, RtnInvoiceProfitCatalog);
        this.addCellValue("HppTotal", row, RtnInvoiceProfitCatalog);
      }
    },
    addSalesRecap(data) {
      if (data.SalesRecapDetails.length !== 0) {   
        this.addSalesRecap_Details(data.SalesRecapDetails);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.SalesRecap);
    },
    addSalesRecap_Details(data) {
      this.addColumnHeader(SalesRecapCatalog.RecapNumber);
      this.addCellValue("RecapNumber", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.Date);
      this.addCellValue("Date", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.WarehouseName);
      this.addCellValue("WarehouseName", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.CashierName);
      this.addCellValue("CashierName", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.TotalCash);
      this.addCellValue("TotalCash", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.TotalTransfer);
      this.addCellValue("TotalTransfer", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.CashReceived);
      this.addCellValue("CashReceived", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.Comment);
      this.addCellValue("Comment", data, SalesRecapCatalog);

      this.addRow();
      this.addColumnHeader(SalesRecapCatalog.CreatedByName);
      this.addCellValue("CreatedByName", data, SalesRecapCatalog);
    }
  }
}
</script>