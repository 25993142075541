<template>
  <kst-modal
    class="kst-add-item-invoice-search"
    :width="800"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >

    <kst-section select-one-help>
      <List
        :isListLoading="isListLoading"
        :items="userItems"
        :userData="userData"
      />
    </kst-section>
  </kst-modal>
</template>

<script>
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Info from "../ReceiveInfo.js";

import List from "./ReceiveNew_AddItemPO_List";

export default {
  components: {
    List
  },
  props: {
    items: Array
  },
  mixins: [SnackbarMixin],
  data: () => ({
    userData: {},
    searchData: {},
    userItems: [],
    active: false,
    isListLoading: true
  }),
  computed: {
    title() {
      return Info.Section.PO.AddItem.Title;
    }
  },
  methods: {
    show() {
      this.init();
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    init() {
      this.userData = {
        selected: null
      };

      this.initSearch();
      this.loadList();
    },
    initSearch() {
      this.searchData = {
        Search: ""
      };
    },

    loadList() {
      this.isListLoading = true;
      this.loadList_Success();
    },
    loadList_Success() {
      const keyword = this.searchData.Search;

      if (keyword !== "") {
        this.items.filter(item => 
          item["Name"].includes(keyword.toLowerCase())
        )
      }

      this.userItems = this.items;

      this.isListLoading = false;
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    },

    handleSearch_Submit() {
      this.loadList();
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadList();
    }
  }
}
</script>