<template>
  <kst-container class="kst-vendor-list">
    <kst-form :loading="isLoading" :title="title">
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>

      <Export
        ref="refExport"
        :items="items"
      />

      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
            @click="handleExport"
          />
        </template>

        <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../VendorModel.js";

import Export from "./VendorList_Export";
import Search from "./VendorList_Search";
import List from "./VendorList_List";

import Services from "../VendorServices.js";

export default {
  mixins: [AxiosMixin],
  components: {
    Export,
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: null,
    items: [],
    isLoading: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    },
    initSearch() {
      this.searchData = {
        Search: ""
      };
    },

    loadData(counter, searchData) {
      this.isListLoading = true;
      let options = { active: '' };
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
      }

      this.failureInit = null;
      const config = Services.getVendorList(options);
      this.runAxios(config, counter, this.loadData_Success, this.loadData_Error);
    },
    loadData_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }
      this.items = data;
      this.isLoading = false;
      this.isListLoading = false;
    },
    loadData_Error(message) {
      this.failureList = message;
      this.isLoading = false;
    },

    handleExport() {
      this.$refs.refExport.exportExcel();
    },

    handleSearchSubmit() {
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    }
  }
}
</script>