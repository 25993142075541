<template>
  <span />
</template>

<script>
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../StockCatalog.js";
import Info from "../StockInfo.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    items: Array
  },
  methods: {
    exportExcel() {
      this.createBook();
      this.createSheet();

      this.addList();
      this.download(Info.Excel.List.FileName);
    },

    addList() {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.FullName);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.SKU);
      this.addColumnHeader(Catalog.SellPrice);

      if (this.access.Field.HppPerPcs !== false) {
        this.addColumnHeader(Catalog.HppPerPcs);
      }

      this.addColumnHeader(Catalog.Quantity);

      if (this.access.Field.HppTotal !== false) {
        this.addColumnHeader(Catalog.HppTotal);
      }

      // value
      for (const row of this.items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("FullName", row, Catalog);
        this.addCellValue("WarehouseName", row, Catalog);
        this.addCellValue("SKU", row, Catalog);
        this.addCellValue("SellPrice", row, Catalog);

        if (this.access.Field.HppPerPcs !== false) {
          this.addCellValue("HppPerPcs", row, Catalog);
        }

        this.addCellValue("Quantity", row, Catalog);

        if (this.access.Field.HppTotal !== false) {
          this.addCellValue("HppTotal", row, Catalog);
        }
      }

      this.addSheet(Info.Excel.List.SheetName.List);
    }
  }
}
</script>