import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama",
    Type: InputType.Text
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text
  },
  Barcode: {
    Label: "Barcode",
    Type: InputType.Text
  },
  RequestedQuantity: {
    Label: "Qty",
    Type: InputType.Qty
  },
  PackagingName: {
    Label: "Kemasan",
    Type: InputType.Text
  },
  SellPrice: {
    Label: "Harga Jual",
    Type: InputType.Integer,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  Price: {
    Label: "Sub Total",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  OriginalPrice: {
    Label: "Harga Jual",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  SpecialPrice: {
    Label: "Harga Spesial",
    Type: InputType.Integer,
    MinValue: 0,
    MaxLength: 11,
    Required: true,
    Output: OutputType.Integer.Currency
  },
  SpecialPriceName: {
    Label: "Diskon",
    Type: InputType.Text
  },
  SpecialPriceID: {
    Label: "Harga Spesial",
    Type: InputType.Integer
  },
  DiscPercent: {
    Label: "Diskon",
    Type: InputType.Integer,
    Output: InputType.Integer.Accounting2D
  },
  Stock: {
    Label: "Stok",
    Type: InputType.Integer
  },
  MaxQuantity: {
    Label: "Stok",
    Type: InputType.Integer
  },
  // support
  FullName: {
    Label: "Nama",
    Type: InputType.Text
  }
}