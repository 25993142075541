import AppCatalog from "@/services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  FullName: {
    Label: AppCatalog.Field.FullName.Label,
    Type: AppCatalog.Field.FullName.Type,
    ReadOnly: AppCatalog.Field.FullName.ReadOnly,
    Highlight: AppCatalog.Field.FullName.Highlight
  },
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  Quantity: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    ReadOnly: true,
    Output: AppCatalog.Field.SellPrice.Output
  },
  SellPriceNetto: {
    Label: AppCatalog.Field.SellPriceNetto.Label,
    Type: AppCatalog.Field.SellPriceNetto.Type,
    ReadOnly: true,
    Output: AppCatalog.Field.SellPriceNetto.Output
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true,
    Highlight: AppCatalog.Field.SKU.Highlight
  },
  SpecialPriceID: {
    Label: AppCatalog.Field.SpecialPriceID.Label,
    Type: AppCatalog.Field.SpecialPriceID.Type,
    RelatedValue: AppCatalog.Field.SpecialPriceID.RelatedValue,
    RelatedModule: AppCatalog.Field.SpecialPriceID.RelatedModule
  },
  Weight: {
    Label: AppCatalog.Field.Weight.Label,
    Type: AppCatalog.Field.Weight.Type,
    ReadOnly: true
  }
}