import RouterModel from "../../../router/RouterModel";
import AppModule from "../../../services/App/AppModule";
import AppModuleType from "../../../services/App/AppModuleType";
import ExpenseSubCategoryNew from "./New/ExpenseSubCategoryNew";
import ExpenseSubCategoryEdit from "./Edit/ExpenseSubCategoryEdit";
import ExpenseSubCategory from "./Setting/ExpenseSubCategorySetting";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.ExpenseCategory)
  },

  getRoutes() {
    return [{
      path: RouterModel.getPath(AppModule.ExpenseCategory),
      name: RouterModel.getName(AppModule.ExpenseCategory),
      component: ExpenseSubCategory,
      meta: {
          navTitle: RouterModel.getName(AppModule.Settings),
          moduleName: RouterModel.getModule(AppModule.Settings)
      }
    },{
      path: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.New),
      name: RouterModel.getName(AppModule.ExpenseCategory, AppModuleType.New),
      component: ExpenseSubCategoryNew,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.ExpenseCategory, AppModuleType.New),
        moduleName: RouterModel.getModule(AppModule.ExpenseCategory)
      }
    },{
      path: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.Edit),
      name: RouterModel.getName(AppModule.ExpenseCategory, AppModuleType.Edit),
      component: ExpenseSubCategoryEdit,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.ExpenseCategory, AppModuleType.Edit),
        moduleName: RouterModel.getModule(AppModule.ExpenseCategory)
      }
    }]
  }
}