var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","detailed":"","paginated":"","view-mode":"","data":_vm.items,"failure":_vm.failureList,"loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Action',{attrs:{"data":row}})]}},{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('DispatchRelated',{attrs:{"access":_vm.access,"itemId":row.ID}})]}}])},[_c('b-table-column',{attrs:{"sortable":"","cell-class":"kst-item-list-Name","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","sortable":"","field":"SellPrice","label":_vm.Catalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),(_vm.access.Field.HppPerPcs !== false)?[_c('b-table-column',{attrs:{"numeric":"","sortable":"","field":"HppPerPcs","label":_vm.Catalog.HppPerPcs.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,false,2339996382)})]:_vm._e(),_c('b-table-column',{attrs:{"numeric":"","sortable":"","cell-class":"ks-col-stock","field":"TotalQuantity","label":_vm.Catalog.TotalQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),(_vm.access.Field.HppTotal !== false)?[_c('b-table-column',{attrs:{"numeric":"","sortable":"","field":"HppTotal","label":_vm.Catalog.HppTotal.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}],null,false,2339996382)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }