<template>
  <div class="kst-dialog-confirm"/>
</template>

<script>
export default {
  props: {
    message: {
      type: [String, Array]
    }
  },
  methods: {
    /*** public ***/

    show(message) {
      const strMessage = this.getMessage(message);

      this.$buefy.dialog.confirm({
        message: strMessage,
        onConfirm: () => this.handleConfirm(),
        onCancel: () => this.handleCancel()
      });
    },

    /*** private ***/

    handleConfirm() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleCancel() {
      this.$emit(this.$kst.Enum.Event.Cancel);
    },

    getMessage(message) {
      // prioritize parameter
      let value = message ? message : this.message;
      return this.$kst.Convert.arrayToString(value);
    }
  }
}
</script>