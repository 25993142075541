import App from "../../services/App/App";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";
import Catalog from "./StockAvailabilityCatalog";

export default {
  Module: AppModule.StockAvailability,
  ModuleType: {
    List: AppModuleType.List
  },

  Report: {
    TitleTransaction: "Daftar Transaksi",
    TitleSearch: "Rincian"
  },

  Search: {
    Fields: ["SONumber", "ClientName"]
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  populateSearchData(data) {
    const result = {};
    result.field = this.Search.Fields;
    result.name = App.Out.getString(data.Search);
    result.datefrom = App.Out.getDate(data.Date[0]);
    result.dateto = App.Out.getDate(data.Date[1]);
    result.warehouseid = App.Search.getValue(data.WarehouseID);
    return result;
  },

  getItemInfo(item) {
    let infoList = [];

    infoList.push(Catalog.PackagingName.Label + ": " +
      App.Value.getValue("PackagingName", item, Catalog)
    );
    infoList.push(Catalog.QtySO.Label + ": " +
      App.Value.getValue("QtySO", item, Catalog)
    );
    infoList.push(Catalog.Qty.Label + ": " +
      App.Value.getValue("Qty", item, Catalog)
    );
    return infoList;
  }
}