<template>
  <kst-tooltip search-mode hover-only :content="Model.Invoice.TitleSearch" v-if="row.ID">
      <kst-button search-mode is-round @click="handleSearchInvoice(row.ID)"/>
  </kst-tooltip>
</template>

<script>
import Model from "../InvoiceModel.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InvoiceRouter from "@/components/Invoice/InvoiceRouter.js";

export default {
  mixins: [RouterMixin],
  props: {
    row: Object
  },
  data: () => ({
    Model: Model
  }),
  methods: {
    handleSearchInvoice(invoiceId) {
      const urlParam = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: invoiceId
      });
      this.openTab(InvoiceRouter.Path.Details + urlParam);
    }
  }
}
</script>