<template>
  <kst-container class="kst-purchase-list">
    <kst-form 
      :loading="isLoading" 
      :title="title"
      :failureInit="failureInit"
    >
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>

      <Export
        ref="refExport"
        :items="items"
      />

      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          :statusOptions="statusOptions"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
            @click="handleExport"
          />
        </template>

        <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import SearchCatalog from "./PurchaseSearchCatalog.js";
import Model from "../PurchaseModel.js";

import Export from "./PurchaseList_Export";
import Search from "./PurchaseList_Search";
import List from "./PurchaseList_List";

import Services from "../PurchaseServices.js";

export default {
  mixins: [AxiosMixin],
  components: {
    Export,
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: {},
    warehouseOptions: {},
    statusOptions: {},
    items: [],
    isLoading: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      // status
      this.statusOptions = Model.createStatusOptions(SearchCatalog.StatusID.Label);

      // search
      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll,
        StatusID: this.$kst.Search.OptionAll
      };
    },

    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data, SearchCatalog.WarehouseID.Label
      );

      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.status = this.$kst.Search.getValue(searchData.StatusID);
        options.field = Model.Search.Fields;
        options.startDate = this.$kst.Out.getDate(searchData.Date[0]);
        options.endDate = this.$kst.Out.getDate(searchData.Date[1]);
        options.warehouse = this.$kst.Search.getValue(searchData.WarehouseID);
      }

      const config = Services.getPurchaseList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
     // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },

    handleExport() {
      this.$refs.refExport.exportExcel();
    },

    handleSearchSubmit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.init();
    }
  }
}
</script>