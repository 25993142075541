import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import Model from "./ProfitlossModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
        SheetName: {
          List: Model.ModuleType.List.Name + " " + AppModule.ProfitLoss.FullName
        },
        Section: {
          List: Model.ModuleType.List.Name + " " + AppModuleType.Details.Name + ":",
          Profit: "Perhitungan" + " " + AppModule.ProfitLoss.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.List.Name + " " +
          Model.Module.FullName,
        Option: {
          List: Model.ModuleType.List.Name + " " + AppModule.ProfitLoss.FullName,
          Profit: AppModuleType.List.Name + " " + "Perhitungan" + " " + AppModule.ProfitLoss.Name
        }
      }
    }
  },
  ProfitLabelDetail: {
      Label: AppCatalog.Info.Profit + " Penjualan",
      BackgroundColor: "#42b983"
  },
  Tooltip: {
      Label: "Lihat Pengeluaran"
  },
  Sales: {
      Title: "Penjualan"
  },
  Profit: {
      Title: "Perhitungan " + AppCatalog.Info.Profit,
      TitleSection: AppCatalog.Info.NettProfit,
      Icon: "mdi mdi-36px mdi-poll ",
      IconStyle: "kst-dashboard-sales ",
      IconBgStyle: "kst-dashboard-sales-bg ",
      Field: "Total",
      Help: {
        Icon: "mdi mdi-calendar-clock",
        Title: "Last 24 Hours",
        Style: "kst-dashboard-sales-help "
      }
  },
  Tabs: {
    Summary: {
        Label: "Ringkasan"
    },
    Details: {
        Label: "Rincian"
    }
  },
}