<template>
  <kst-section :title="salesSectionTitle">
    <kst-warning :items="salesWarning"/>

    <template v-if="userData.SalesDraftID !== null">
      <kst-output control-off class="mt-4"
        field="SalesDraftID" :data="userData" :catalog="Catalog"
      />
      <kst-output field="DraftDate" :data="userData" :catalog="Catalog"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    salesSectionTitle() {
      return Info.Section.Sales.Title;
    },
    salesWarning() {
      if (this.userData.SalesDraftID === null) {
        return [
          Info.Message.Edit_Sales,
          Info.Message.Edit_NoSales
        ];
      }
      else {
        return [Info.Message.Edit_Sales];
      }
    }
  }
}
</script>