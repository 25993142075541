<template>
  <form novalidate @submit.prevent="debounceSearch">
    <div class="columns my-0">
      <div class="column is-3">
        <kst-input floating-label load-on-focus
          field="Search" :data="searchData" :catalog="SearchCatalog"
          @input="handleInput"
        />
      </div>

      <div class="column is-narrow">
        <kst-button reset-mode @click="handleReset"/>
        <input type="submit" value="Cari" class="is-hidden">
      </div>
    </div>
  </form>
</template>

<script>
import SearchCatalog from "./ItemSelectSearchCatalog.js";
import SearchMixin from "@/mixins/SearchMixin.js";

export default {
  mixins: [SearchMixin],
  props: {
    searchData: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    }
  }
}
</script>