<template>
  <div>
    <kst-input field="CategoryID" :data="data" :label="Catalog.CategoryID.Label" :catalog="Catalog" :options="categoryOption" />
    <kst-input field="Name" :data="data" :label="Catalog.Name.Label" :catalog="Catalog" />
    <kst-input field="IsActive" :data="data" :label="Catalog.IsActive.Label" :catalog="Catalog" />
  </div>
</template>
<script>
import Catalog from "../ExpenseSubCategoryCatalog.js";

export default {
  props: {
    data: Object,
    categoryOption: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>