<template>
  <div v-if="!isLoading" class="my-thermal">
    <div class="my-thermal-button">
      <kst-button action-mode
        class="is-primary mr-4"
        tag="button"
        :module="Model.Module"
        :moduleType="Model.ModuleType.Print"
        @click="handlePrint"
      />
    </div>

    <div class="my-thermal-content">
      <Header :companyData="companyData"/>
      <Details :data="data"/>
      <Items :items="data.Items"/>
      <Total :data="data" :items="data.Items"/>

      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Footer />

      <div>&nbsp;</div>
      <div class="mb-5">&nbsp;</div>
      {{ timestamps }}
    </div>
  </div>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Model from "./PrintThermalModel.js";
import Services from "./PrintThermalServices.js";

import Details from "./PrintThermal_Details";
import Total from "./PrintThermal_Total";
import Header from "./PrintThermal_Header";
import Items from "./PrintThermal_Items";
import Footer from "./PrintThermal_Footer";

export default {
  mixins: [AxiosMixin, RouterMixin],
  props: {
    id: Number
  },
  components: {
    Details,
    Footer,
    Header,
    Items,
    Total
  },
  data: () => ({
    Model: Model,
    data: {},
    companyData: {},
    isLoading: true,
    // error
    failureInit: null
  }),
  created(){
    this.loadData();
  },
  computed: {
    timestamps() {
      return "Dicetak pada: " + this.$kst.View.getTimestamp();
    }
  },
  methods: {
    loadData() {
      let id;

      if (this.id) {
        id = this.id;
      }
      else {
        id = this.$route.query[this.$kst.Enum.Param.ID];
      }

      if (!id) {
        return;
      }

      this.loadDetails(id);
    },

    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;

      const config = Services.getInvoiceDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.data = data;
      Model.updateData(this.data);

      for (const item of this.data.Items) {
        Model.updateItemSpecialPrice(item);
      }
      
      this.loadCompanyDetails();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadCompanyDetails() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadCompanyDetails_Success, this.loadCompanyDetails_Error);
    },
    loadCompanyDetails_Success(data) {
      this.companyData = data.data;
      this.initLoad_End();
    },
    loadCompanyDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handlePrint() {
      window.print();
    }
  }
}
</script>

<style lang="css" src="./_layout.css"/>