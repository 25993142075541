var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","detailed":"","view-mode":"","data":_vm.items,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Action',{attrs:{"row":row}})]}},{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('kst-container',{staticClass:"p-2"},[_c('Items',{attrs:{"items":row.Items}}),_c('Total',{attrs:{"data":row}})],1)]}}])},[_c('b-table-column',{attrs:{"field":"LogisticsNumber","label":_vm.Catalog.LogisticsNumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"VehicleIDNumber","label":_vm.Catalog.VehicleIDNumber.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Destination","label":_vm.Catalog.Destination.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"TotalWeight","label":_vm.Catalog.TotalWeight.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{staticClass:"has-text-weight-bold",attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"centered":"","sortable":"","cell-class":"ks-col-status","field":"Status","label":_vm.Catalog.Status.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-status',{attrs:{"value":row.Status,"statusEnum":_vm.Model.Status}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }