import DeliveryServices from "@/services/DeliveryServices.js";
import DispatchServices from "@/services/DispatchServices.js";
import InvoiceServices from "@/services/InvoiceServices.js";
import SalesServices from "@/services/SalesServices.js";
import StockServices from "@/services/StockServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import CompanyServices from "@/services/CompanyServices.js";
import SpecialPriceServices from "@/services/SpecialPriceServices.js";
import DispatchSpecialPriceServices from "@/services/DispatchSpecialPriceServices.js";

export default {
  getInvoiceDetails: InvoiceServices.getDetails,
  getInvoiceDetailsData: InvoiceServices.getDetailsData,
  getInvoiceEditData: InvoiceServices.getEditData,
  getInvoiceList: InvoiceServices.getList,
  getInvoiceNewData: InvoiceServices.getNewData,
  getInvoicePriceList: InvoiceServices.getPriceList,
  postInvoice: InvoiceServices.postInvoice,
  putInvoice: InvoiceServices.putInvoice,
  // delivery
  getDeliveryList: DeliveryServices.getList,
  // dispatch
  getDispatchDetails: DispatchServices.getDetails,
  // sales
  getSalesDetails: SalesServices.getDetails,
  // stock
  getStockList: StockServices.getList,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // company
  getCompanyDetails: CompanyServices.getDetails,
  // special price
  getSpecialPriceList: SpecialPriceServices.getList,
  getDispatchSpecialPrice: DispatchSpecialPriceServices.getList
}