<template>
  <kst-container class="kst-version">
    <kst-form :title="title">
      <div class="columns my-0">
        <div class="column is-narrow is-3">
          <kst-input label-on-border
            field="ID" :data="userData" :catalog="Catalog"
            :options="versionOptions" @input="handleInput"
          />
        </div>
        <div class="column is-narrow is-9">
          <div class="content">
            <template v-if="isLoading">
              <kst-wait class="pl-5" />
            </template>
            <template v-else>
              <ul class="mt-0">Daftar Perubahan di {{ userData.Name }}:
                <li class="mt-2">Selalu ada perubahan di versi {{ userData.Name }} ini.</li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </kst-form>
  </kst-container>
</template>

<script>
import LoadingMixin from '@/mixins/LoadingMixin.js';
import Catalog from './VersionCatalog.js';
import Model from './VersionModel.js';
import Sample from './VersionSample.js';

export default {
  mixins: [LoadingMixin],
  data: () => ({
    userData: null,
    versionOptions: null,
    isLoading: false,
    Catalog: Catalog
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userData = Model.createDetails();

      this.versionOptions = {
        value: "ID",
        label: "Name",
        rows: Sample.Rows
      };

      this.userData.ID = Sample.Rows[0].ID;
      this.userData.Name = Sample.Rows[0].Name;
    },

    handleInput() {
      this.isLoading = true;
      this.openFullLoading(true, () => {
        this.isLoading = false;
      });

      for (const item of Sample.Rows) {
        if (item.ID === this.userData.ID) {
          this.userData.Name = item.Name;
        }
      }
    }
  }
}
</script>