import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import ClientDetails from "./Details/ClientDetails";
import ClientEdit from "./Edit/ClientEdit";
import ClientList from "./List/ClientList";
import ClientNew from "./New/ClientNew";
import ClientExportDetails from "./ExportDetails/ClientExportDetails"

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Client, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Client, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Client, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Client, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Client, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Client, AppModuleType.Details),
        component: ClientDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Client)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Client, AppModuleType.Edit),
        component: ClientEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Client)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Client, AppModuleType.ExportDetails),
        component: ClientExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Client)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.List),
        name: RouterModel.getName(AppModule.Client, AppModuleType.List),
        component: ClientList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Client)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.New),
        name: RouterModel.getName(AppModule.Client, AppModuleType.New),
        component: ClientNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Client)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}