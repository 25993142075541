<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../ItemCatalog.js";
import DispatchCatalog from "../DispatchCatalog.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import PackagingCatalog from "../PackagingCatalog.js";
import Services from "../ItemServices.js";
import StockCatalog from "../StockCatalog.js";
import VariantCatalog from "../VariantCatalog.js";
import VariantValueCatalog from "../VariantValueCatalog.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      if (this.searchData.WithStock) {
        this.createSheet();
        this.addStock(data);
      }

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getItemDetails(id, { withstock: 1 });
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);
      this.addDetails_Packaging(data.Packaging);
      this.addDetails_Variant(data.Variants);

      if (this.searchData.WithDispatch) {
        this.addDetails_Dispatch(data.Variations);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addColumnHeader(Catalog.Type);
      this.addColumnHeader(Catalog.Name);

      // value
      this.addRow();
      this.addCellStatus(data.Type, Model.Type);
      this.addCellValue("Name", data, Catalog);
    },
    addDetails_Packaging(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.Packaging);
      this.addRow();

      // label
      this.addColumnHeader(PackagingCatalog.ItemUnitName);
      this.addColumnHeader(PackagingCatalog.ItemUnitAmount);

      // value
      for (const row of items) {
        this.addRow();
        this.addCellValue("ItemUnitName", row, PackagingCatalog);
        this.addCellValue("ItemUnitAmount", row, PackagingCatalog);
      }
    },
    addDetails_Variant(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.Variant);
      this.addRow();

      // label
      this.addColumnHeader(VariantCatalog.Name);
      this.addColumnHeader(VariantValueCatalog.Value);

      // value
      for (const row of items) {
        for (const rowValue of row.Value) {
          this.addRow();
          this.addCellValue("Name", row, VariantCatalog);
          this.addCellValue("Value", rowValue, VariantValueCatalog);
        }
      }
    },
    addDetails_Dispatch(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.Dispatch);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(DispatchCatalog.FullName);
      this.addColumnHeader(DispatchCatalog.SKU);
      this.addColumnHeader(DispatchCatalog.SellPrice);

      if (this.access.Field.BuyPrice !== false) {
        this.addColumnHeader(DispatchCatalog.BuyPrice);
      }

      this.addColumnHeader(DispatchCatalog.Weight);

      this.addColumnHeader(DispatchCatalog.Barcode);
      this.addColumnHeader(DispatchCatalog.Vendor);
      this.addColumnHeader(DispatchCatalog.Description);

      // value
      for (const row of items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("FullName", row, DispatchCatalog);
        this.addCellValue("SKU", row, DispatchCatalog);
        this.addCellValue("SellPrice", row, DispatchCatalog);

        if (this.access.Field.BuyPrice !== false) {
          this.addCellValue("BuyPrice", row, DispatchCatalog);
        }

        this.addCellValue("Weight", row, DispatchCatalog);

        this.addCellValue("Barcode", row, DispatchCatalog);
        this.addCellValue("Vendor", row, DispatchCatalog);
        this.addCellValue("Description", row, DispatchCatalog);
      }
    },

    addStock(data) {
      this.addStock_List(data.StockList);
      this.addSheet(Info.Export.Details.Excel.SheetName.Stock);
    },
    addStock_List(items) {
      // source: addList() in "@/components/Stock/List/StockList.vue"

      // label
      this.addSequenceHeader();
      this.addColumnHeader(StockCatalog.FullName);
      this.addColumnHeader(StockCatalog.WarehouseName);
      this.addColumnHeader(StockCatalog.SKU);
      this.addColumnHeader(StockCatalog.SellPrice);

      if (this.access.Field.BuyPrice !== false) {
        this.addColumnHeader(StockCatalog.BuyPrice);
      }

      this.addColumnHeader(StockCatalog.Weight);
      this.addColumnHeader(StockCatalog.Quantity);

      if (this.access.Field.HppTotal !== false) {
        this.addColumnHeader(StockCatalog.HppTotal);
        this.addColumnHeader(StockCatalog.HppPerPcs);
      }

      // value
      for (const row of items) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("FullName", row, StockCatalog);
        this.addCellValue("WarehouseName", row, StockCatalog);
        this.addCellValue("SKU", row, StockCatalog);
        this.addCellValue("SellPrice", row, StockCatalog);

        if (this.access.Field.BuyPrice !== false) {
          this.addCellValue("BuyPrice", row, StockCatalog);
        }

        this.addCellValue("Weight", row, StockCatalog);
        this.addCellValue("Quantity", row, StockCatalog);

        if (this.access.Field.HppTotal !== false) {
          this.addCellValue("HppTotal", row, StockCatalog);
          this.addCellValue("HppPerPcs", row, StockCatalog);
        }
      }
    }
  }
}
</script>