var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-section',{attrs:{"items-mode":""}},[_c('kst-table',{attrs:{"view-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"cell-class":"kst-receive-related-Name","field":"Name","label":_vm.ReceiveItemCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-tooltip',{attrs:{"info-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"info-mode":"","items":_vm.Model.getItemInfo(row)}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"info-mode":""}})],1)]}}])}),_c('b-table-column',{attrs:{"numeric":"","field":"Quantity","label":_vm.ReceiveItemCatalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"field":"Packaging","label":_vm.ReceiveItemCatalog.Packaging.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"BuyPrice","label":_vm.ReceiveItemCatalog.BuyPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"ExpenseAmount","label":_vm.ReceiveItemCatalog.ExpenseAmount.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.ReceiveItemCatalog}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }