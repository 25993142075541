import AppCatalog from "./AppCatalog.js";
import AppFormatter from "./App_Formatter.js";
import OutputTypeEnum from "../Input/OutputTypeEnum.js";

export default {
  getFormat(datatype) {
    switch (datatype) {
      // general
      case OutputTypeEnum.Text:
        break;

      // boolean
      case OutputTypeEnum.ActiveInactive:
      case OutputTypeEnum.Boolean:
      case OutputTypeEnum.OpenClosed:
      case OutputTypeEnum.YesNo:
        break;

      // credential
      case OutputTypeEnum.Password:
        break;

      // date & time
      case OutputTypeEnum.ShortDate:
        return AppCatalog.Xlsx.Format.ShortDate;
      case OutputTypeEnum.MediumDate:
        return AppCatalog.Xlsx.Format.MediumDate;
      case OutputTypeEnum.LongDate:
        return AppCatalog.Xlsx.Format.LongDate;
      case OutputTypeEnum.ShortMonthly:
        return AppCatalog.Xlsx.Format.ShortMonthly;
      case OutputTypeEnum.LongMonthly:
        return AppCatalog.Xlsx.Format.LongMonthly;

      // email
      case OutputTypeEnum.Email:
        break;

      // id
      case OutputTypeEnum.ID:
        break;

      // number
      case OutputTypeEnum.Accounting:
        return AppCatalog.Xlsx.Format.Accounting;
      case OutputTypeEnum.Accounting2D:
        return AppCatalog.Xlsx.Format.Accounting2D;
      case OutputTypeEnum.Currency:
        return AppCatalog.Xlsx.Format.Currency;
      case OutputTypeEnum.Decimal2D:
        return AppCatalog.Xlsx.Format.Decimal2D;
      case OutputTypeEnum.Integer:
        return AppCatalog.Xlsx.Format.Integer;
      case OutputTypeEnum.Percent:
        return AppCatalog.Xlsx.Format.Percent;
      case OutputTypeEnum.Decimal:
        break;

      // phone
      case OutputTypeEnum.Phone:
        break;

      // text
      case OutputTypeEnum.TextArea:
        break;

      // url
      case OutputTypeEnum.Url:
        break;
    }

    return null;
  },
  getType(datatype) {
    switch (datatype) {
      // general
      case OutputTypeEnum.Text:
        return AppCatalog.Xlsx.Type.Text;

      // boolean
      case OutputTypeEnum.ActiveInactive:
      case OutputTypeEnum.Boolean:
      case OutputTypeEnum.OpenClosed:
      case OutputTypeEnum.YesNo:
        break;

      // credential
      case OutputTypeEnum.Password:
        break;

      // date & time
      case OutputTypeEnum.ShortDate:
      case OutputTypeEnum.MediumDate:
      case OutputTypeEnum.LongDate:
      case OutputTypeEnum.ShortMonthly:
      case OutputTypeEnum.LongMonthly:
        return AppCatalog.Xlsx.Type.Date;

      // email
      case OutputTypeEnum.Email:
        break;

      // id
      case OutputTypeEnum.ID:
        break;

      // number
      case OutputTypeEnum.Accounting:
      case OutputTypeEnum.Accounting2D:
      case OutputTypeEnum.Currency:
      case OutputTypeEnum.Decimal2D:
      case OutputTypeEnum.Integer:
      case OutputTypeEnum.Percent:
        return AppCatalog.Xlsx.Type.Number;
      case OutputTypeEnum.Decimal:
        break;

      // phone
      case OutputTypeEnum.Phone:
        return AppCatalog.Xlsx.Type.Text;

      // text
      case OutputTypeEnum.TextArea:
        break;

      // url
      case OutputTypeEnum.Url:
        break;
    }

    return null;
  },
  getValue(datatype, value) {
    if (value === undefined || value === null) {
      switch (datatype) {
        // date & time
        case OutputTypeEnum.ShortDate:
        case OutputTypeEnum.MediumDate:
        case OutputTypeEnum.LongDate:
          return undefined;
      }

      return "";
    }

    switch (datatype) {
      // general
      case OutputTypeEnum.Text:
        break;

      // boolean
      case OutputTypeEnum.ActiveInactive:
        return AppFormatter.getActiveInactive(value);
      case OutputTypeEnum.Boolean:
        return AppFormatter.getBoolean(value);
      case OutputTypeEnum.OpenClosed:
        return AppFormatter.getOpenClosed(value);
      case OutputTypeEnum.YesNo:
        return AppFormatter.getYesNo(value);

      // credential
      case OutputTypeEnum.Password:
        return AppCatalog.Xlsx.Default.Password;

      // date & time
      case OutputTypeEnum.ShortDate:
      case OutputTypeEnum.MediumDate:
      case OutputTypeEnum.LongDate:
      case OutputTypeEnum.ShortMonthly:
      case OutputTypeEnum.LongMonthly:
        break;

      // email
      case OutputTypeEnum.Email:
        break;

      // id
      case OutputTypeEnum.ID:
        break;

      // number
      case OutputTypeEnum.Accounting:
      case OutputTypeEnum.Accounting2D:
      case OutputTypeEnum.Currency:
      case OutputTypeEnum.Decimal:
      case OutputTypeEnum.Decimal2D:
      case OutputTypeEnum.Integer:
        break;
      case OutputTypeEnum.Percent:
        return value / 100;

      // phone
      case OutputTypeEnum.Phone:
        break;

      // text
      case OutputTypeEnum.TextArea:
        break;

      // url
      case OutputTypeEnum.Url:
        break;
    }

    return null;
  }
}