<template>
  <kst-container class="px-5 py-5 kst-dashboard-list">
    <nav class="level">
      <div class="level-left">
        <div class="has-text-weight-bold kst-dashboard-list-title">
          {{ title }}
        </div>
        <!-- <div class="mb-4 kst-dashboard-list-subtitle">
          {{ subtitle }}
        </div> -->
      </div>
      <div class="level-right">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="titleButton"
          @click="handleSubmit"
        />
      </div>
    </nav>
    <slot />
  </kst-container>
</template>

<script>
import RouterMixin from "@/mixins/RouterMixin.js";

export default {
  mixins: [RouterMixin],
  props: {
    title: String,
    titleButton: String,
    // subtitle: String,
    url: String,
    urlParams: String
  },
  methods: {
    handleSubmit() {
      this.redirectTo(this.url + this.urlParams);
    }
  }
}
</script>