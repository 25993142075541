<template>
  <kst-section items-mode class="mt-5">
    <kst-table action input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="ItemID"
        :label="PurchaseItemCatalog.Name.Label"
      >
        <kst-output field-only
          :control-off="row.DispatchID === Model.NewItem.DispatchID"
          :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.getItemInfo_Edit(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="PurchaseItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :box-off="row.DispatchID === Model.NewItem.DispatchID"
          :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Quantity"
        :label="PurchaseItemCatalog.Quantity.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="BuyPrice"
        :label="PurchaseItemCatalog.BuyPrice.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="ExpenseAmount"
        :label="PurchaseItemCatalog.ExpenseAmount.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="PurchaseItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-status"
        field="IsClosed"
        :label="PurchaseItemCatalog.IsClosed.Label"
      >
        <kst-item-status :field="column.field" :data="row" :catalog="PurchaseItemCatalog"/>
      </b-table-column>

      <template #action="{ row }">
        <Action :data="data" :row="row"/>
      </template>
    </kst-table>
  </kst-section>
</template>

<script>
import PurchaseItemCatalog from "../PurchaseItemCatalog.js";
import Model from "../PurchaseModel.js";

import Action from "./PurchaseDetails_Items_Action";

export default {
  components: {
    Action
  },
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    PurchaseItemCatalog: PurchaseItemCatalog,
    Model: Model
  })
}
</script>