import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import VendorDetails from "./Details/VendorDetails";
import VendorEdit from "./Edit/VendorEdit";
import VendorList from "./List/VendorList";
import VendorNew from "./New/VendorNew";
import VendorExportDetails from "./ExportDetails/VendorExportDetails"

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Vendor, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Vendor, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Vendor, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Vendor, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Vendor, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.Details),
        component: VendorDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Vendor)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.Edit),
        component: VendorEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Vendor)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.ExportDetails),
        component: VendorExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Vendor)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.List),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.List),
        component: VendorList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Vendor)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.New),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.New),
        component: VendorNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Vendor)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}