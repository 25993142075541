import DispatchServices from "@/services/DispatchServices.js";
import ItemServices from "@/services/ItemServices.js";
import PurchaseServices from "@/services/PurchaseServices.js";
import StockServices from "@/services/StockServices.js";

export default {
  getItemDetails: ItemServices.getDetails,
  getItemDetailsData: ItemServices.getDetailsData,
  getItemEditData: ItemServices.getEditData,
  getItemList: ItemServices.getList,
  getItemListData: ItemServices.getListData,
  getItemNewData: ItemServices.getNewData,
  postItem: ItemServices.postItem,
  putItem: ItemServices.putItem,
  // dispatch
  getDispatchList: DispatchServices.getList,
  // purchase
  getPurchaseItemDetails: PurchaseServices.getPurchaseItemDetails,
  postPurchaseItem: PurchaseServices.postPurchaseItem,
  // stock
  getStockList: StockServices.getList
}