import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari di " + AppCatalog.Title,
    Type: InputType.Text
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  }
}