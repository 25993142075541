import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  TotalProfit: {
    Label: AppCatalog.Info.GrossProfit,
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalExpense: {
    Label: "Total Pengeluaran",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalNetProfit: {
    Label: AppCatalog.Info.NettProfit,
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  }
}