var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","input-mode":"","data":_vm.userItems,"failure":_vm.failureList},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"delete-mode":"","hover-only":"","content":_vm.Model.Item.Module.FullName}},[_c('kst-button',{attrs:{"delete-mode":"","is-round":""},on:{"click":function($event){return _vm.handleRowDelete(index)}}})],1)]}}])},[_c('b-table-column',{attrs:{"field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","box-off":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-qty kst-pos-result-item-qty","field":"RequestedQuantity","label":_vm.Catalog.RequestedQuantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-input',{attrs:{"field-only":"","field":column.field,"data":row,"catalog":_vm.Catalog},on:{"input":function($event){return _vm.handleQty(row)}}})]}}])}),_c('b-table-column',{attrs:{"field":"PackagingName","label":_vm.Catalog.PackagingName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","box-off":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","field":"SellPrice","label":_vm.Catalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(row.SpecialPrice > 0)?[_c('div',{staticClass:"has-text-danger"},[_c('kst-output',{attrs:{"field-only":"","box-off":"","field":"SpecialPrice","data":row,"catalog":_vm.Catalog}})],1),_c('div',{staticClass:"has-text-grey ks-text-line-through"},[_c('kst-value',{staticClass:"is-size-7",attrs:{"field":"SellPrice","data":row,"catalog":_vm.Catalog}})],1)]:[_c('kst-output',{attrs:{"field-only":"","box-off":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var index = ref.index;
return [_c('kst-tooltip',{attrs:{"edit-mode":"","hover-only":"","content":_vm.Catalog.SellPrice.Label}},[_c('kst-button',{attrs:{"edit-mode":"","is-round":""},on:{"click":function($event){return _vm.handleEdit_SellPrice(index)}}})],1)]}}])}),_c('b-table-column',{attrs:{"numeric":"","field":"Price","label":_vm.Catalog.Price.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"field-only":"","box-off":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }