import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.vendor;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "vendor", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/vendor/list" + urlParams
    };
  },
  getDetails(id) {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/vendor/" + cid + "/" + id
    };
  },
  postVendor(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/vendor",
      data: data
    };
  },
  putVendor(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/vendor",
      data: data
    };
  },

  /* address */

  
  getAddressList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "vendor", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/vendor/address/list" + urlParams
    };
  }

}