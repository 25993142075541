import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Tipe Variant",
    Type: InputType.Text,
    MaxLength: 45,
    Required: true,
    Valid: true
  }
}