var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"paginated":"","view-mode":"","data":_vm.items,"selected":_vm.userData.selected,"failure":_vm.failureList,"loading":_vm.isListLoading},on:{"update:selected":function($event){return _vm.$set(_vm.userData, "selected", $event)}}},[_c('b-table-column',{attrs:{"sortable":"","field":"FullName","label":_vm.DispatchCatalog.FullName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","cell-class":"ks-col-typename","field":"TypeName","label":_vm.Catalog.TypeName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","cell-class":"ks-col-sku","field":"SKU","label":_vm.DispatchCatalog.SKU.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","sortable":"","cell-class":"ks-col-price","field":"SellPrice","label":_vm.DispatchCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","cell-class":"ks-col-weight-full","field":"Weight","label":_vm.DispatchCatalog.Weight.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.DispatchCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }