<template>
  <kst-modal
    :width="650"
    :active="active"
    :title="Model.Expense.Title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Cancel"
  >
    <kst-container class="px-2 py-2">
      <kst-section :title="sellingPriceTitle">
        <div class="columns">
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-regular kst-report-list-title">
              Harga Jual Normal
            </div>
          </div>
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-semibold kst-report-list-title has-text-right">
                <kst-value class="is-5" field="NormalPrice" :data="data" :catalog="Catalog"/>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-regular kst-report-list-title">
              Harga Jual Spesial
            </div>
          </div>
          <div class="column is-half kst-report-item">
            <div class="has-text-weight-semibold kst-report-list-title has-text-right">
                <kst-value class="is-5" field="SpecialPrice" :data="data" :catalog="Catalog"/>
            </div>
          </div>
        </div>
      </kst-section>
      <kst-section :title="paymentTypeTitle">
        <kst-table action view-mode
          :data="paymentTypeList"
        >
          <b-table-column sortable
          v-slot="{ column, row }"
          cell-class="ks-col-category"
          field="Name"
          :label="Catalog.Name.Label"
          >
            <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
          </b-table-column>

          <b-table-column sortable
          v-slot="{ column, row }"
          cell-class="ks-col-total"
          field="Total"
          :label="Catalog.Total.Label"
          >
            <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
          </b-table-column>

          <b-table-column sortable centered
            v-slot="{ row }"
            cell-class="ks-col-status"
            field="IsActive"
            :label="Catalog.IsActive.Label"
          >
            <kst-status :value="row.IsActive" :statusEnum="Model.IsActive"/>
          </b-table-column>
        </kst-table>
      </kst-section>
    </kst-container>
  </kst-modal>
</template>

<script>
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Catalog from "../OmzetCatalog.js";
import Model from "../OmzetModel.js";
import Info from "../OmzetInfo.js";

export default {
  mixins: [SnackbarMixin],
  props: {
    data: Object,
    paymentTypeList: Array  
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    active: false
  }),
  computed: {
    paymentTypeTitle() {
      return Info.Section.PaymentType.Title;
    },
    sellingPriceTitle() {
      return Info.Section.SellingPrice.Title;
    }
  },
  methods: {
    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    show() {
      this.active = true;
    },
    hide() {
      this.active = false;
    },
  }
}
</script>