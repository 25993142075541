import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

//import AutoNumberEdit from "./Edit/AutoNumberEdit";
import AutoNumberList from "./List/AutoNumberList";

export default {
  Path: {
    Edit: RouterModel.getPath(AppModule.AutoNumber, AppModuleType.Edit),
    List: RouterModel.getPath(AppModule.AutoNumber, AppModuleType.List)
  },

  getRoutes() {
    return [
      /*
      {
        path: RouterModel.getPath(AppModule.AutoNumber, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.AutoNumber, AppModuleType.Edit),
        component: AutoNumberEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.AutoNumber, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.AutoNumber)
        }
      },
      */
      {
        path: RouterModel.getPath(AppModule.AutoNumber, AppModuleType.List),
        name: RouterModel.getName(AppModule.AutoNumber, AppModuleType.List),
        component: AutoNumberList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.AutoNumber, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.AutoNumber)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}