<template>
  <div class="columns is-mobile">
    <div class="column is-8 is-hidden-mobile"/>
    <div class="column is-narrow">
      <div class="title is-5">
        {{ Catalog.GrandTotal.Label }}
      </div>
    </div>
    <div class="column">
      <div class="has-text-right">
        <kst-value class="title is-5" field="GrandTotal" :data="data" :catalog="Catalog"/>
      </div>
    </div>
  </div>
</template>
<script>
import Catalog from "../StockClosingItemCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>
