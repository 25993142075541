<template>
  <b-button
    class="kst-button"
    :class="AppCatalog.Router.Forward.Class"
    :icon-left="AppCatalog.Router.Forward.Icon"
    :label="AppCatalog.Router.Forward.Label"
    @click="handleClick"
  />
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";
import RouterMixin from "@/mixins/RouterMixin.js";

export default {
  mixins: [RouterMixin],
  data: () => ({
    AppCatalog: AppCatalog
  }),
  methods: {
    handleClick() {
      this.goForward();
    }
  }
}
</script>