<template>
  <div>
    <form novalidate @submit.prevent="debounceSearch">
      <div class="columns mb-0">
        <div class="column pb-0">
          <kst-input floating-label
            field="Search" ref="txtSearch" :data="searchData" :catalog="Catalog"
            @input="handleInput"
          />
        </div>
      </div>
      <div class="column is-narrow">
        <input type="submit" value="Cari" class="is-hidden">
      </div>
    </form>

    <div class="list-pos-items">
      <kst-skeleton :active="isListLoading"/>

      <template v-if="!isListLoading">
        <div class="columns is-multiline">
          <div class="column is-half" v-for="(item, index) in items" :key="item.DispatchID">
            <div class="card-pos-item" v-on:click="handleItem_Click(item, index)">
              <div class="card-info">
                <kst-tooltip info-mode size="is-small" :position="getPosition(index)">
                  <template #content>
                    <kst-list info-mode :items="Model.getItemInfo(item)"/>
                  </template>
                  <kst-icon info-mode/>
                </kst-tooltip>
              </div>

              <template v-if="item.SpecialPriceName !== undefined">
                <div class="ribbon-wrapper-red">
                  <div class="ribbon-red">
                    <kst-value field="SpecialPriceName" :data="item" :catalog="ItemCatalog" />
                  </div>
                </div>
              </template>

              <kst-image hide-preview read-only thumbnail :src="item['ImageSrc']"/>
              <h5><kst-value field="FullName" :data="item" :catalog="ItemCatalog"/></h5>
              <div class="divider" />

              <template v-if="item.SpecialPriceName !== undefined">
                <div class="columns mb-0">
                  <div class="column pr-0">
                    <div class="is-12">
                      {{ ItemCatalog.MaxQuantity.Label }}
                    </div>
                  </div>
                  <div class="column pl-0">
                    <div class="has-text-weight-semibold is-12">
                      <kst-value field="MaxQuantity" :data="item" :catalog="ItemCatalog"/>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="columns mb-0">
                  <div class="column pr-0">
                    <div class="is-12">
                      {{ ItemCatalog.Stock.Label }}
                    </div>
                  </div>
                  <div class="column pl-0">
                    <div class="has-text-weight-semibold is-12">
                      <kst-value field="Stock" :data="item" :catalog="ItemCatalog"/>
                    </div>
                  </div>
                </div>
              </template>
              
              <div class="columns">
                <div class="column pr-0">
                  <div class="is-12">
                    {{ ItemCatalog.SellPrice.Label }}
                  </div>
                </div>

                <template v-if="item.SpecialPrice > 0">
                  <div class="column pl-0">
                    <div class="has-text-weight-semibold is-12 has-text-danger">
                      <kst-value field="SpecialPrice" :data="item" :catalog="ItemCatalog"/>
                    </div>
                    <div class="has-text-grey ks-text-line-through">
                      <kst-value class="is-size-7" field="SellPrice" :data="item" :catalog="ItemCatalog"/>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="column pl-0">
                    <div class="has-text-weight-semibold is-12">
                      <kst-value field="SellPrice" :data="item" :catalog="ItemCatalog"/>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Catalog from "./PosCatalog.js";
import ItemCatalog from "./PosItemCatalog.js";
import Model from "./PosModel.js";

export default {
  mixins: [SearchMixin, SnackbarMixin],
  props: {
    isListLoading: Boolean,
    items: Array,
    searchData: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    ItemCatalog: ItemCatalog,
    Model: Model,
    // tabs
    activeTab: 0,
  }),
  mounted() {
    window.addEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
    this.setFocusSearch();
  },
  destroyed() {
    window.removeEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    handleKeyDown(event) {
      switch(event[this.$kst.Key.KeyProp]) {
        case this.$kst.Key.Key.F3:
          this.setFocusSearch();
          event.preventDefault();
          break;
      }
    },
    setFocusSearch() {
      this.activeTab = 1;
      this.$refs.txtSearch.focus();
    },
    handleSnackbarClose() {
      this.setFocusSearch();
    },
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleItem_Click(item, index) {
      let userItemIndex = this.addItem(item);

      this.$nextTick(() => {
        this.$emit(this.$kst.Enum.Event.Input, this.$jquery(".card-pos-item").eq(index), userItemIndex);
      });
    },
    addItem(data) {
      let itemIdx = this.userItems.findIndex(unit => unit.DispatchID === data.DispatchID);

      if (itemIdx !== -1) {
        let item = this.userItems[itemIdx];
        item.RequestedQuantity = item.RequestedQuantity + 1;
        Model.updateItem(item);
      }
      else {
        let item = Model.createItem();
        item.RequestedQuantity = 1;
        Model.setItemByData(item, data);
        Model.updateItem(item);
        this.userItems.push(item);
        itemIdx = this.userItems.length - 1;
      }

      Model.updateDetails(this.userData, this.userItems);
      
      return itemIdx;
    },
    getPosition(index) {
      return (index % 2 === 0) ? "is-right" : "is-bottom";
    }
  }
}
</script>