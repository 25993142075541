import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.company;
import App from "./App/App.js";


export default {
  getDetails() {
    const cid = App.Session.getCompanyID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/companies/" + cid
    };
  },
  verifySpvPassword(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/company/verify/passwd",
      data: data
    };
  },
  patchDetails(data) {
    return {
      method: App.Enum.Axios.Method.Patch,
      url: apiEndpoint + "/api/v1/company",
      data: data
    }
  }
  /*
  postSpvPassword(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/company/set/passwd",
      data: data
    };
  }
  */
}