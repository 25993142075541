<template>
  <div>
    <kst-output field="name" :data="data" :catalog="Catalog"/>
    <kst-output field="address" :data="data" :catalog="Catalog"/>
    <kst-output field="phone" :data="data" :catalog="Catalog"/>
    <kst-output field="email" :data="data" :catalog="Catalog"/>
    <kst-output field="owner" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../CompanyCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>