<template>
    <kst-section :title="title" class="mt-5">
    <kst-input field="StatusChanged" @input="handleStatus" :options="statusOptions" :data="data" :catalog="Catalog"/>
      <template v-if="parseInt(data.Status) === Model.Status.Draft.ID || parseInt(data.StatusChanged) === Model.Status.InDelivery.ID">
        <kst-input field="DepartureDate" :data="data" :catalog="Catalog"/>
      </template>
      <template v-if="parseInt(data.StatusChanged) === Model.Status.Delivered.ID">
        <kst-input field="ArrivalDates" :data="data" :catalog="Catalog" @input="handleInput"/>
        <kst-input field="CompletedDates" :data="data" :catalog="Catalog" @input="handleInput"/>
      </template>
      <template v-if="parseInt(data.StatusChanged) === Model.Status.Returned.ID">
        <template v-for="(msg,index) in returnMessage">
          <li :key="index"> {{ msg }} </li>
        </template>
        <kst-button action-mode
          class="is-primary mt-4"
          tag="button"
          :label="titleButton"
          @click="handleSubmit"
        />
      </template>
    </kst-section>
</template>
<script>
import RouterMixin from "@/mixins/RouterMixin.js";

import Catalog from "../DeliveryCatalog.js";
import Model from "../DeliveryModel.js";

import Router from "../DeliveryRouter.js";

export default {
  mixins: [RouterMixin],
  props: {
    data: Object,
    title: String,
    statusOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    returnMessage: [
      "Returned Pengiriman sudah tidak dapat dipergunakan kembali",
      "Untuk sementara waktu, silahkan pergunakan Stok Opname"
    ]
  }),
  computed: {
    titleButton() {
      return "Stok Opname";
    }
  },
  methods: {
    handleStatus() {
      Model.updateStatus(this.data);
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleInput() {
      Model.updateInput(this.data);
    },
    handleSubmit() {
      this.redirectTo(Router.StockOpname.Path);
    }
  }
}
</script>