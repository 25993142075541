<template>
  <div>
    <div class="mb-2">Excel with Data Catalog (i.e. Invoice List Page):</div>
    <kst-button action-mode
      class="is-primary"
      tag="button"
      :module="Model.Module"
      :moduleType="Model.ModuleType.Export"
      @click="handleExport"
    />
  </div>
</template>

<script>
import Model from "../TestModel.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Sample from "./TestExcelSample.js";
import InvoiceCatalog from "@/components/Invoice/InvoiceCatalog.js";
import InvoiceModel from "@/components/Invoice/InvoiceModel.js";

export default {
  mixins: [XlsxMixin],
  data: () => ({
    Model: Model
  }),
  methods: {
    handleExport() {
      this.createBook();
      this.createSheet();

      this.addList();
      this.download("list catalog excel.xlsx");
    },

    addList() {
      this.addSequenceHeader();
      this.addColumnHeader(InvoiceCatalog.ID.Label);
      this.addColumnHeader(InvoiceCatalog.InvoiceDate.Label);
      this.addColumnHeader(InvoiceCatalog.SONumber.Label);
      this.addColumnHeader(InvoiceCatalog.WarehouseName.Label);
      this.addColumnHeader(InvoiceCatalog.Client.Label);
      this.addColumnHeader(InvoiceCatalog.PONumber.Label);
      this.addColumnHeader(InvoiceCatalog.Total.Label);
      this.addColumnHeader(InvoiceCatalog.Status.Label);

      for (const row of Sample.Rows) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("ID", row, InvoiceCatalog);
        this.addCellValue("InvoiceDate", row, InvoiceCatalog);
        this.addCellValue("SONumber", row, InvoiceCatalog);
        this.addCellValue("WarehouseName", row, InvoiceCatalog);
        this.addCellValue("Client", row, InvoiceCatalog);
        this.addCellValue("PONumber", row, InvoiceCatalog);
        this.addCellValue("Total", row, InvoiceCatalog);
        this.addCellStatus(row.Status, InvoiceModel.Status);
      }

      this.addSheet("List");
    }
  }
}
</script>