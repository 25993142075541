<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="StockReductionItemCatalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="StockReductionItemCatalog.Qty.Label"
    >
      <kst-value multi-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog">
        <kst-value multi-value field="PackagingName" :data="row" :catalog="StockReductionItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="StockReductionItemCatalog.SellPrice.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog"/>
    </b-table-column>

    <template v-if="data.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SpecialPriceID"
        :label="StockReductionItemCatalog.SpecialPriceID.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPriceNetto"
        :label="StockReductionItemCatalog.SellPriceNetto.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog"
        />
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="StockReductionItemCatalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockReductionItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import StockReductionItemCatalog from "../StockReductionItemCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    StockReductionItemCatalog: StockReductionItemCatalog
  })
}
</script>