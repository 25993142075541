<template>
  <kst-section :title="sectionTitle" class="mt-5">
    <kst-table :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="ItemUnitName"
        :label="PackagingCatalog.ItemUnitName.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="PackagingCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="ItemUnitAmount"
        :label="PackagingCatalog.ItemUnitAmount.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="PackagingCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-item-active"
        field="IsActive"
        :label="PackagingCatalog.IsActive.Label"
      >
        <kst-item-active :field="column.field" :data="row" :catalog="PackagingCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import PackagingCatalog from "../PackagingCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    PackagingCatalog: PackagingCatalog,
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Packaging.Title;
    }
  }
}
</script>