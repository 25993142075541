<template>
  <div class="kst-image-noimage kst-image-container ks-cursor-not-allowed ks-flex-center has-text-centered" :class="theClass">
    <div class="px-4">{{ theNoText }}</div>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    borderOff: Boolean,
    sizeAuto: Boolean,
  },
  computed: {
    theClass() {
      let className = "";

      if (!this.borderOff) {
        className += "kst-image-border ";
      }
      if (!this.sizeAuto) {
        className += "kst-image-size ";
      }

      return className;
    },
    theNoText() {
      return AppCatalog.Image.NoText;
    }
  }
}
</script>