<template>
  <kst-container class="kst-purchase-list">
    <kst-form :loading="isLoading" :title="title">
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>

      <Export
        ref="refExport"
        :items="items"
      />

      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :categoryOption="categoryOption"
          @reset="handleSearcReset"
          @submit="handleSearchSubmit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
            @click="handleExport"
          />
        </template>

        <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../ExpenseModel.js";
import SearchCatalog from "./ExpenseSearchCatalog";
import App from "@/services/App/App.js";

import Export from "./ExpenseList_Export";
import Search from './ExpenseList_Search';
import List from './ExpenseList_List';

import Services from '../ExpenseServices.js';

export default {
  mixins: [AxiosMixin],
  components: {
    Export,
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: {},
    categoryOption: {},
    items: [],
    isLoading: false,
    isListLoading: true,
    // support debounce
    loadCounter: 0,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.loadCategory();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    initSearch() {
      this.searchData = {
        Date: this.$kst.In.getDefaultDateRange(),
        SubCategoryID: this.$kst.Search.OptionAll
      };
    },

    loadCategory() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getExpenseSubList();
      this.runAxios(config, null, this.loadCategory_Success, this.loadCategory_Error);
    },

    loadCategory_Success(data) {
      this.categoryOption = Model.createOptions(data, SearchCatalog.SubCategoryID.Label);
      this.isLoading = false;
    },
    loadCategory_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    loadList(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;
      let options = {};

      if (searchData) {
        options.startDate = this.$kst.Out.getDate(this.searchData.Date[0]);
        options.endDate = this.$kst.Out.getDate(this.searchData.Date[1]);
        options.subcategory = App.Search.getValue(this.searchData.SubCategoryID);
      }

      const config = Services.getExpenseList(options);
      this.runAxios(config, counter, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data;
      this.isListLoading = false;
    },
    loadList_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },

    handleExport() {
      this.$refs.refExport.exportExcel();
    },

    handleSearchSubmit() {
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    },
    handleSearcReset() {
      this.initSearch();
      this.loadCounter++;
      this.loadList(this.loadCounter, this.searchData);
    }
  }
}
</script>