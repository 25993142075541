import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
// import AppModuleType from "@/services/App/AppModuleType.js";
import CompanyRouter from "./Company/CompanyRouter.js";
import InvoiceSettingRouter from "./InvoiceSetting/InvoiceSettingRouter.js";

import AllSettings from "./AllSettings/AllSettings";
import ExpenseSubCategory from "./ExpenseSubCategory/ExpenseSubCategoryRouter.js";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.Settings)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Settings),
        name: RouterModel.getName(AppModule.Settings),
        component: AllSettings,
        meta: {
            navTitle: RouterModel.getName(AppModule.Settings),
            moduleName: RouterModel.getModule(AppModule.Settings)
        }
      },
      ...CompanyRouter.getRoutes(),
      ...InvoiceSettingRouter.getRoutes(),
      ...ExpenseSubCategory.getRoutes()
    ];
  },

  getRootRoutes() {
    return [];
  }
}