export default {
  methods: {
    /**
     * run http requests (promise based)
     * @param {Object} config - request metadata
     * - keys:
     *   {String} method (required)
     *   {any} url (required)
     *   {any} params (optional) - to be returned in all callbacks 
     *   {Object} data (required for post/put method)
     * - method enumeration, see: Axios.Method in "@/services/App/App_Enum.js"
     * @callback
     * - successCallback
     *   {Object} data
     *   {any} params
     *   {Object} response
     * - errorCallback
     *   {String} message
     *   {any} params
     *   {Object} response or error
     * - invalidCallback
     *   {String} message
     *   {any} params
     *   {Object} response
     * - callback
     *   {any} params
     */
    runAxios(config, params, successCallback, errorCallback, invalidCallback, callback) {
      this.axios({
        method: config.method,
        url: config.url,
        data: config.data
      })
      .then(function (response) {
        const data = response.data;

        if (data.invalid) {
          // handle data invalid (API validation)
          // validation error message (API), i.e. Name is required
          if (invalidCallback) {
            invalidCallback(data.invalid, params, response);
          }
          return;
        }
        else if (data.error) {
          // handle error (API)
          // system generated error (API), i.e. connect ETIMEDOUT
          if (errorCallback) {
            errorCallback(data.error, params, response);
          }
          return;
        }
        else {
          // handle success
          if (successCallback) {
            successCallback(response.data, params, response);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (errorCallback) {
          errorCallback(error.message, params, error);
        }
      })
      .then(function () {
        // always executed
        if (callback) {
          callback(params);
        }
      });      
    }
  }
}