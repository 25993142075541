var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"action":"","paginated":"","view-mode":"","data":_vm.items,"failure":_vm.failureList,"loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [(_vm.hasAction(row))?[_c('kst-tooltip',{attrs:{"details-mode":"","hover-only":""}},[_c('kst-button',{attrs:{"search-mode":"","is-round":""},on:{"click":function($event){return _vm.handleItem_Details(row)}}})],1)]:_vm._e()]}}])},[_c('b-table-column',{attrs:{"field":"SearchTypeID","label":_vm.Catalog.SearchTypeID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getSearchName(row))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"TransactionName","label":_vm.Catalog.TransactionName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-shortdate","field":"Date","label":_vm.Catalog.Date.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-warehouse","field":"WarehouseID","label":_vm.Catalog.WarehouseID.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal has-text-weight-bold","field":"Total","label":_vm.Catalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-qty has-text-weight-bold","field":"Qty","label":_vm.Catalog.Qty.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }