import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  RecapNumber: {
    Label: "Nomor Rekap",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  Date: {
    Label: "Tanggal",
    PrintLabel: "Tanggal direkap",
    Type: InputType.Date,
    ReadOnly: true
  },
  InvoiceDate: {
    Label: "Tanggal Penjualan",
    Type: InputType.Date,
    RequiredHide: true,
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  CashierID: {
    Label: "Kasir",
    PrintLabel: "Kasir",
    Type: InputType.ID,
    Input: InputType.Select,
    RelatedValue: "CashierName",
  },
  CashierName: {
    Label: "Kasir",
    Type: InputType.Text,
    ReadOnly: true
  },
  PaymentTypeID: {
    Label: "Jenis Pembayaran",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "PaymentTypeName",
    RelatedModule: AppModule.PaymentType,
  },
  PaymentTypeName: {
    Label: "Jenis Pembayaran",
    Type: InputType.Text,
    ReadOnly: true
  },
  PaymentValue: {
    Label: "Jumlah Bayar",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting
  },
  CashReceived: {
    Label: "Uang Diterima",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  SONumber: {
    Label: "Nomor Penjualan",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true,
    ReadOnly: true
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Info.ID + " " + AppModule.Client.FullName,
    PrintLabel: "Nama " + AppModule.Client.FullName,
    Type: InputType.ID,
    Help: [
      "Pilih dari master Pelanggan, atau ketik manual.",
      "Pelanggan tersusun atas Alias dan Nama."
    ],
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  Client: {
    Label: "Nama",
    Type: InputType.Text,
    MaxLength: 500,
    Required: true,
    Highlight: true
  },
}