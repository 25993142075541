export default {
  Rows: [
    {
      "ID": 1812,
      "WarehouseID": 64,
      "CompanyID": 11,
      "SONumber": "2021030099-ABC123",
      "ClientID": null,
      "Client": "POS",
      "ClientAddress": null,
      "InvoiceDate": "2021-03-09T17:00:00.000Z",
      "CreatedDate": "2021-03-10T00:10:35.000Z",
      "Comment": null,
      "PONumber": null,
      "DiscValue": 100,
      "DiscPercent": null,
      "Status": 1,
      "StatusName": "Pending",
      "TotalBruto": 2100,
      "TotalDisc": 100,
      "Total": 2000,
      "SalesDraftID": null,
      "DraftNumber": null,
      "IsNeedDelivery": 0,
      "DeliveryDate": null,
      "WarehouseName": "Gudang  South Bandung"
    },
    {
      "ID": 1789,
      "WarehouseID": 64,
      "CompanyID": 11,
      "SONumber": "2021030065-ABC123",
      "ClientID": 173,
      "Client": "Eon 23",
      "ClientAddress": "Bandng",
      "InvoiceDate": "2021-03-08T17:00:00.000Z",
      "CreatedDate": "2021-03-08T20:22:38.000Z",
      "Comment": null,
      "PONumber": null,
      "DiscValue": null,
      "DiscPercent": "10.00",
      "Status": 1,
      "StatusName": "Pending",
      "TotalBruto": 1200000,
      "TotalDisc": 120000,
      "Total": 1080000,
      "SalesDraftID": 238,
      "DraftNumber": "SO 88",
      "IsNeedDelivery": 1,
      "DeliveryDate": "2021-03-08T17:00:00.000Z",
      "WarehouseName": "Gudang  South Bandung"
    },
    {
      "ID": 1729,
      "WarehouseID": 7,
      "CompanyID": 11,
      "SONumber": "2021030048-ABC123",
      "ClientID": 173,
      "Client": "Eon 23",
      "ClientAddress": "Bandng",
      "InvoiceDate": "2021-03-04T17:00:00.000Z",
      "CreatedDate": "2021-03-05T00:10:07.000Z",
      "Comment": null,
      "PONumber": "POOOO",
      "DiscValue": null,
      "DiscPercent": null,
      "Status": 1,
      "StatusName": "Pending",
      "TotalBruto": 80000,
      "TotalDisc": 0,
      "Total": 80000,
      "SalesDraftID": 221,
      "DraftNumber": "SO11",
      "IsNeedDelivery": 1,
      "DeliveryDate": "2021-03-04T17:00:00.000Z",
      "WarehouseName": "KST Warehouse 1"
    },
    {
      "ID": 1718,
      "WarehouseID": 64,
      "CompanyID": 11,
      "SONumber": "2021030037-ABC123",
      "ClientID": null,
      "Client": "Yanuar",
      "ClientAddress": "Jl Sukakarya IV No.6",
      "InvoiceDate": "2021-03-03T17:00:00.000Z",
      "CreatedDate": "2021-03-04T00:54:59.000Z",
      "Comment": null,
      "PONumber": null,
      "DiscValue": null,
      "DiscPercent": null,
      "Status": 1,
      "StatusName": "Pending",
      "TotalBruto": 400000,
      "TotalDisc": 0,
      "Total": 400000,
      "SalesDraftID": 219,
      "DraftNumber": "SO-002",
      "IsNeedDelivery": 1,
      "DeliveryDate": "2021-03-04T17:00:00.000Z",
      "WarehouseName": "Gudang  South Bandung"
    },
    {
      "ID": 1715,
      "WarehouseID": 64,
      "CompanyID": 11,
      "SONumber": "2021030032-ABC123",
      "ClientID": null,
      "Client": "Yanuar",
      "ClientAddress": "Jl Sukakarya IV. No.6.",
      "InvoiceDate": "2021-03-03T17:00:00.000Z",
      "CreatedDate": "2021-03-03T23:21:15.000Z",
      "Comment": null,
      "PONumber": null,
      "DiscValue": null,
      "DiscPercent": null,
      "Status": 1,
      "StatusName": "Pending",
      "TotalBruto": 250000,
      "TotalDisc": 0,
      "Total": 250000,
      "SalesDraftID": 218,
      "DraftNumber": "SO-001",
      "IsNeedDelivery": 1,
      "DeliveryDate": "2021-03-03T17:00:00.000Z",
      "WarehouseName": "Gudang  South Bandung"
    }
  ]
}