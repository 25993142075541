<template>
  <kst-print-table
    :data="items" 
    :failure="failureList"
    :loading="isListLoading">

    <b-table-column
      v-slot="{ column, row }"
      field="DispatchName"
      :label="SpecialPriceAdjItemCatalog.DispatchName.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="SpecialPriceAdjItemCatalog.SellPrice.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="SpecialPriceName"
      :label="SpecialPriceAdjItemCatalog.SpecialPriceName.Label"
    >
      <kst-value 
        :field="column.field" 
        :data="row"
        :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price-button"
      field="DiscountedValue"
      :label="SpecialPriceAdjItemCatalog.DiscountedValue.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="SpecialPriceAdjItemCatalog.Qty.Label"
    >
      <kst-value
        :field="column.field" 
        :data="row" 
        :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="SpecialPriceAdjItemCatalog.Total.Label"
    >
      <kst-value 
        :field="column.field" 
        :data="row" 
        :catalog="SpecialPriceAdjItemCatalog"
      />
    </b-table-column>
  </kst-print-table>
</template>

<script>
import SpecialPriceAdjItemCatalog from "../SpecialPriceAdjItemCatalog.js";

export default {
  props: {
    items: Array,
    isListLoading: Boolean,
    failureList: null
  },
  data: () => ({
    SpecialPriceAdjItemCatalog: SpecialPriceAdjItemCatalog
  })
}
</script>