<template>
  <kst-table view-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="InvoiceProfitCatalog.Name.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="Price"
      :label="InvoiceProfitCatalog.Price.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>

    <!--
    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="Discount"
      :label="InvoiceProfitCatalog.Discount.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="TotalPrice"
      :label="InvoiceProfitCatalog.TotalPrice.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>
    -->

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="HppTotal"
      :label="InvoiceProfitCatalog.HppTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Profit"
      :label="InvoiceProfitCatalog.Profit.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>

    <!--
    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-percent"
      field="ProfitMarginPercentage"
      :label="InvoiceProfitCatalog.ProfitMarginPercentage.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceProfitCatalog"/>
    </b-table-column>
    -->
  </kst-table>
</template>

<script>
import InvoiceProfitCatalog from "../InvoiceProfitCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    InvoiceProfitCatalog: InvoiceProfitCatalog
  })
}
</script>