<template>
  <kst-container class="kst-expense-subcategory">
    <kst-form :loading="isLoading" :title="title">
      <template #top-right>
        <Action  />
      </template>
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :categoryOptions="categoryOptions"
          :statusOptions="statusOptions"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        />
      </kst-section>

      <kst-section search-result-mode>
        <template #top-right>
          
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.PrintList"
          />
          <kst-button action-mode
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.ExportList"
          />
         
        </template>
        <List :dataCategories="dataCategories" :failure="failureList" :isListLoading="isListLoading"/>
      </kst-section>
    </kst-form>
  </kst-container>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../ExpenseSubCategoryModel.js";
import Services from "../ExpenseSubCategoryServices.js";
import SearchCatalog from "./ExpenseSubCategorySettingSearchCatalog.js";

import Search from "./ExpenseSubCategorySetting_Search";
import List from "./ExpenseSubCategorySetting_List";
import Action from "./ExpenseSubCategorySetting_List_Action";

export default {
  mixins: [AxiosMixin],
  components: {
    Search,
    Action,
    List
  },
  data: () => ({
    Model: Model,
    dataCategories: [],
    searchData: {},
    categoryOptions: {},
    statusOptions: {},
    // support debounce
    loadCounter: 0,
    // error
    isLoading: false,
    isListLoading: true,
    failureList: null
  }),
  computed: {
    title() {
      return Model.ExpenseSubCategory.TitleExpenseSubCategory
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.loadCategory();
      this.loadData(this.loadCounter, this.searchData);
    },
    initSearch() {
      // status
      this.statusOptions = Model.createStatusOptions(SearchCatalog.IsActive.Label);

      // search
      this.searchData = {
        Search: "",
        CategoryID: this.$kst.Search.OptionAll,
        IsActive: this.$kst.Search.OptionAll
      };
    },
    loadCategory() {
      const config = Services.getExpenseCatList();
      this.runAxios(config, null, this.loadCategorySuccess, this.loadCategoryError)
    },
    loadCategorySuccess(data) {
      this.isLoading = false;
      this.categoryOptions = Model.createCategoryOptions(data, SearchCatalog.CategoryID.Label);
    },
    loadCategoryError() {
      this.isLoading = false;
    },
    loadData(counter, searchData) {
      this.failureList = null;
      this.isListLoading = true;

      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
        options.field = Model.Search.Fields;
        options.category = this.$kst.Search.getValue(searchData.CategoryID);
        options.active = this.$kst.Search.getValue(searchData.IsActive);
      }
      const config = Services.getExpenseSubListAll(options);
      this.runAxios(config, counter, this.loadDataSuccess, this.loadDataError)
    },
    loadDataSuccess(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.dataCategories = data;
      this.isLoading = false
      this.isListLoading = false;
    },
    loadDataError(message) {
      this.failureList = message;
      this.isLoading = false;
      this.isListLoading = false;
    },

    handleSearch_Submit() {
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    }
  }
}
</script>
