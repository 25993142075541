import App from "@/services/App/App.js";
import Model from "./StockModel.js";

export default {
  Excel: {
    List: {
      FileName: App.Xlsx.getFileName(Model.Module, Model.ModuleType.List),
      SheetName: {
        List: Model.ModuleType.List.Name
      }
    }
  }
}