import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import PrintThermal from "./PrintThermal";

export default {
  Path: {
    PrintThermal: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintThermal)
  },

  getRoutes() {
    return [];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintThermal),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.PrintThermal),
        component: PrintThermal,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.PrintThermal),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      }
    ];
  }
}