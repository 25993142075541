<template>
  <span/>
</template>

<script>
  import AxiosMixin from "@/mixins/AxiosMixin.js";
  import LoadingMixin from "@/mixins/LoadingMixin.js";
  import XlsxMixin from "@/mixins/XlsxMixin.js";
  import Catalog from "../PurchaseCatalog.js";
  import ItemCatalog from "../PurchaseItemCatalog.js";
  import Info from "../PurchaseInfo.js";
  import ReceiveCatalog from "@/components/Receive/ReceiveCatalog.js";
  import Services from "../PurchaseServices.js";
  import Model from "../PurchaseModel.js";

  export default {
    mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel(id) {
      this.loadLoading = this.openLoading();
      this.loadDetails(id);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();
      this.addDetails(data);

      if (this.searchData.WithReceiveList) {
        this.createSheet();
        this.addReceiveList(data.ReceivingList);
      }

      this.download(Info.Export.Details.Excel.FileName);
      this.saveData_End();
    },

    loadDetails(id) {
      const config = Services.getPurchaseDetails(id, {withreceiving: 1});
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      for (const item of data.Items) {
        Model.updateItemByPackaging(item);
      }
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadDetails_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addDetails(data) {
      this.addDetails_Details(data);
      this.addDetails_Item(data.Items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(data) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAlias);
      this.addColumnHeader(Catalog.Vendor);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.StatusName);
      this.addColumnHeader(Catalog.DeliveryAddress);
      this.addColumnHeader(Catalog.DeliveryDate);
      this.addColumnHeader(Catalog.Total);

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("PONumber", data, Catalog);
      this.addCellValue("Date", data, Catalog);
      this.addCellValue("VendorID", data, Catalog);
      this.addCellValue("VendorAlias", data, Catalog);
      this.addCellValue("Vendor", data, Catalog);
      this.addCellValue("VendorAddress", data, Catalog);
      this.addCellValue("WarehouseName", data, Catalog);
      this.addCellValue("Comment", data, Catalog);
      this.addCellValue("StatusName", data, Catalog);
      this.addCellValue("DeliveryAddress", data, Catalog);
      this.addCellValue("DeliveryDate", data, Catalog);
      this.addCellValue("Total", data, Catalog);
    },
    addDetails_Item(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ReceiveItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(ItemCatalog.Name);
      this.addColumnHeader(ItemCatalog.SKU);
      this.addColumnHeader(ItemCatalog.QuantityReceived);
      this.addColumnHeader(ItemCatalog.Packaging);
      this.addColumnHeader(ItemCatalog.Quantity);
      this.addColumnHeader(ItemCatalog.BuyPrice);
      this.addColumnHeader(ItemCatalog.ExpenseAmount);

      // value
      for ( const row of items ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("Name", row, ItemCatalog);
      this.addCellValue("SKU", row, ItemCatalog);
      this.addCellValue("QuantityReceived", row, ItemCatalog);
      this.addCellValue("Packaging", row, ItemCatalog);
      this.addCellValue("Quantity", row, ItemCatalog);
      this.addCellValue("BuyPrice", row, ItemCatalog);
      this.addCellValue("ExpenseAmount", row, ItemCatalog);
      }
    },
    addReceiveList(data) {
      this.addReceiveList_Details(data)

      this.addSheet(Info.Export.Details.Excel.SheetName.ReceiveList);
    },
    addReceiveList_Details(items) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(ReceiveCatalog.ReceiveNumber);
      this.addColumnHeader(ReceiveCatalog.WarehouseName);
      this.addColumnHeader(ReceiveCatalog.Date);
      this.addColumnHeader(ReceiveCatalog.TotalExpenses);

      // value
      for ( const row of items ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("ReceiveNumber", row, ReceiveCatalog);
      this.addCellValue("WarehouseName", row, ReceiveCatalog);
      this.addCellValue("Date", row, ReceiveCatalog);
      this.addCellValue("TotalExpenses", row, ReceiveCatalog);
      }
    }
  }
}
</script>