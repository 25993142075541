<template>
  <div>
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        :statusOptions="statusOptions"
        @reset="handleSearch_Reset"
        @submit="handleSearch_Submit"
      />
    </kst-section>

    <kst-section search-result-mode>
      <List :failureList="failureList" :items="dataInvoice.Items" :isListLoading="isListLoading"/>
    </kst-section>
    <Total :data="dataInvoice" />
  </div>
</template>

<script>
import Catalog from "../ClientCatalog.js";
import List from "./ClientDetails_Invoice_List";
import Search from "./ClientDetails_Invoice_Search";
import Total from "./ClientDetails_Invoice_Total";
import InvoiceModel from "../../Invoice/InvoiceModel.js";
import InvoiceSearchCatalog from "../../Invoice/List/InvoiceSearchCatalog.js";

export default {
  components: {
    List: List,
    Search: Search,
    Total: Total
  },
  props: {
    data: Object,
    dataInvoice: Object,
    warehouseOptions: Object,
    searchData: Object,
    failureList: String,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    statusOptions: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.statusOptions = InvoiceModel.createStatusOptions(InvoiceSearchCatalog.StatusID.Label);
    },
    handleSearch_Reset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch_Submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>