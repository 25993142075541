<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../ProfitlossCatalog.js";
import ProfitCatalog from "../ProfitlossDetailsCatalog.js";
import ProfitlossNetCatalog from "../ProfitlossNetProfitCatalog.js";
import Info from "../ProfitlossInfo.js";
import Services from "../ProfitlossServices.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object,
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel() {
      this.loadLoading = this.openLoading();
      this.loadList(this.searchData);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(items) {
      this.summarizeExpense(items);

      this.createBook();
      this.createSheet();

      if (this.searchData.WithProfit) {
        this.addProfit(items);
      }

      this.addList(items);

      this.addSheet(Info.Export.List.Excel.SheetName.List);

      this.download(Info.Export.List.Excel.FileName);
      this.saveData_End();
    },

    loadList(searchData) {
      let options = {};
      if (searchData) {
        options.startDate = this.searchData.Date[0],
        options.endDate = this.searchData.Date[1],
        options.warehouseId = this.searchData.WarehouseID
      }

      const config = Services.getProfitLoss(options);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addList(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.List);
      this.addRow();
      
      // label
      this.addSequenceHeader();
      this.addColumnHeader(ProfitCatalog.Date);
      this.addColumnHeader(ProfitCatalog.Profit);

      // value
      for (const row of items.Details) {
        if(row.TransactionName == "Invoice"){
          this.addRow();

          this.addSequenceCell();
          this.addCellValue("Date", row, ProfitCatalog);
          this.addCellValue("Profit", row, ProfitCatalog);
        }
      }
    },
    addProfit(data) {
      // label
      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.Profit);
      this.addRow();
      this.addColumnHeader(Catalog.TotalNetSales);
      this.addCellValue("TotalNetSales", data.Summary.All, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.TotalHpp);
      this.addCellValue("TotalHpp", data.Summary.All, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.TotalStockAdj);
      this.addCellValue("TotalStockAdj", data.Summary.All, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.TotalProfit);
      this.addCellValue("TotalProfit", data.Summary.All, Catalog);
      this.addRow();
      this.addColumnHeader(ProfitlossNetCatalog.TotalExpense);
      this.addCellValue("TotalExpense", data.Summary.All, ProfitlossNetCatalog);
      this.addRow();
      this.addColumnHeader(ProfitlossNetCatalog.TotalNetProfit);
      this.addCellValue("TotalNetProfit", data.Summary.All, ProfitlossNetCatalog);
    },
    summarizeExpense(data) {
      let expenseTotal = 0;
      for (const expense of data.Expenses) {
        expenseTotal += this.$kst.Out.getInteger(expense.Total);
      }

      data.Summary.All.TotalExpense = expenseTotal;
      data.Summary.All.TotalNetProfit = data.Summary.All.TotalProfit - expenseTotal;
    },
  }
}
</script>