<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../OmzetCatalog.js";
import DetailsCatalog from "../OmzetDetailsCatalog.js";
import Info from "../OmzetInfo.js";
import Services from "../OmzetServices.js";
import Model from "../OmzetModel.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object,
  },
  data: () => ({
    loadLoading: null,
    paymentTypeList: []
  }),
  methods: {
    exportExcel() {
      this.loadLoading = this.openLoading();

      this.loadList(this.searchData);
      this.loadPaymentList();
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      const result = this.getProcessData(data);
      Model.getPaymentType(result.Details, this.paymentTypeList);

      this.createBook();
      this.createSheet();

      if (this.searchData.WithExpense) {
        this.addExpense(result, this.paymentTypeList);
      }

      this.addList(result);

      this.addSheet(Info.Export.List.Excel.SheetName.List);

      this.download(Info.Export.List.Excel.FileName);
      this.saveData_End();
    },

    loadList(searchData) {
      let options = {};
      if (searchData) {
        options.warehouse = searchData.WarehouseID;
        options.startDate = searchData.Date[0];
        options.endDate = searchData.Date[1];
        options.withreturn = 1;
      }

      const config = Services.getTurnoverSales(options);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    loadPaymentList() {
      const config = Services.getPaymentTypeList();
      this.runAxios(config, null, this.loadPaymentList_Success, this.loadPaymentList_Error);
    },

    loadPaymentList_Success(data) {
      this.paymentTypeList = data;
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadPaymentList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addList(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.List);
      this.addRow();
      
      // label
      this.addSequenceHeader();
      this.addColumnHeader(DetailsCatalog.Date);
      this.addColumnHeader(DetailsCatalog.Type);
      this.addColumnHeader(DetailsCatalog.Total);

      // value
      for (const row of items.Details) {
        if (row.Type === "Invoice") {
          row.Type = "Penjualan";
        } else {
          row.Type = "Retur";
        }
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("Date", row, DetailsCatalog);
        this.addCellValue("Type", row, DetailsCatalog);
        this.addCellValue("Total", row, DetailsCatalog);
      }
    },
    addExpense(data, paymentList) {
      // label
      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.Expense);
      this.addRow();
      this.addColumnHeader(Catalog.TotalBruto);
      this.addCellValue("TotalBruto", data, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.TotalDisc);
      this.addCellValue("TotalDisc", data, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.TotalRetur);
      this.addCellValue("TotalRetur", data, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.Total);
      this.addCellValue("Total", data, Catalog);
      this.addRow();

      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.ExpenseDetails);
      this.addRow();
      this.addColumnHeader(Catalog.NormalPrice);
      this.addCellValue("NormalPrice", data, Catalog);
      this.addRow();
      this.addColumnHeader(Catalog.SpecialPrice);
      this.addCellValue("SpecialPrice", data, Catalog);
      this.addRow();
      for (let item of paymentList) {
        this.addColumnHeader(item.Name);
        this.addCellValue("Total", item, Catalog);
        this.addRow()
      }
    },

    getProcessData(data) {
      let omzetTotal = 0;
      let totalBruto = 0;
      let totalDisc = 0;
      let totalRetur = 0;
      let totalTransfer = 0;
      let totalCash = 0;
      let normalPrice = 0;
      let specialPrice = 0;
      
      let omzet = {};
      let items = [];

      for(let item of data.InvoiceList){
        if (item.PaymentTypeID === null) {
          item.PaymentTypeID = 1
        }
        totalBruto += item.TotalBruto;
        totalDisc += item.TotalDisc;

        for (let item2 of this.paymentTypeList) {
          if (item.PaymentTypeID == item2.ID) {
            item.PaymentTypeName = item2.Name;
            item.IsActive = item2.IsActive
          }
        }

        const itemInvoice = {
          Total: item.Total,
          TotalBruto: item.TotalBruto,
          Date: item.InvoiceDate,
          Type: "Invoice",
          PaymentTypeID: item.PaymentTypeID,
        }
        items.push(itemInvoice);
      }

      if(data.ReturnList){
        for(let item of data.ReturnList) {
          totalRetur += item.Total;
          const itemRetur = {
            Date: item.Date,
            Type: "Retur",
            Total: item.Total
          }
          items.push(itemRetur);
        }
      }

      for(let item of data.InvoiceItemList) {
        if (item.SpecialPriceID === null) {
          normalPrice += (item.SellPrice * item.TotalRequestedQuantity);
        } else {
          specialPrice += item.Price;
        }
      }

      items.sort((a,b) => { return new Date(b.Date) - new Date(a.Date); });

      omzetTotal = totalBruto - totalDisc - totalRetur;

      omzet.Details = items;
      omzet.Total = omzetTotal;
      omzet.TotalBruto = totalBruto;
      omzet.TotalDisc = totalDisc;
      omzet.TotalRetur = totalRetur;
      omzet.TotalTransfer = totalTransfer;
      omzet.TotalCash = totalCash;
      omzet.NormalPrice = normalPrice;
      omzet.SpecialPrice = specialPrice;

      return omzet;
    },

  }
}
</script>