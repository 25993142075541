import { render, staticRenderFns } from "./ItemEdit.vue?vue&type=template&id=0fde7d0e&scoped=true&"
import script from "./ItemEdit.vue?vue&type=script&lang=js&"
export * from "./ItemEdit.vue?vue&type=script&lang=js&"
import style0 from "./_layout.css?vue&type=style&index=0&id=0fde7d0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fde7d0e",
  null
  
)

export default component.exports