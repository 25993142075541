import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";
import AppModule from "@/services/App/AppModule.js";

export default {
  ID: {
    Label: "ID",
    Type: InputType.ID,
    ReadOnly: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  AdjNumber: {
    Label: "Nomor Penyesuaian",
    Type: InputType.Text,
    MaxLength: 50,
    Highlight: true
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    Required: true,
    ReadOnly: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },

  // support
  IsAutoNumber: {
    Label: "Nomor Penyesuaian",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}