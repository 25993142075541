<template>
  <kst-container class="kst-stockclosing-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <template #top-right>
          <kst-button reset-mode @click="handleReset"/>
        </template>
        
        <Details
          :data="data"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
          @submit="handleSearchSubmit"
        />
        <Items
          :userItems="userItems" 
          :specialPriceData="specialPriceData"
          :specialPriceOptions="specialPriceOptions"
          :isListLoading="isListLoading"
          :failureList="failureList"
          @callback="handleCallbackItems"
          />
        <Total :data="dataTotal" />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import DialogMixin from "@/mixins/DialogMixin.js";
import Router from "../StockClosingRouter.js";
import Model from "../StockClosingModel.js";
import Services from "../StockClosingServices.js";
import ItemCatalog from "../StockClosingItemCatalog.js";
import Info from "../StockClosingInfo.js";

import Details from "./StockClosingNew_Details";
import Items from "./StockClosingNew_Items";
import Total from "./StockClosingNew_Total";

export default {
  mixins: [AxiosMixin, RouterMixin, SnackbarMixin, LoadingMixin, DialogMixin],
  components: {
    Details,
    Items,
    Total
  },
  data: () => ({
    userItems: [],
    data: {},
    dataTotal: {},
    isLoading: false,
    isListLoading: false,
    loading: null,
    warehouseOptions: {},
    specialPriceOptions: {},
    specialPriceData: {},
    cancelUrl: Router.Path.List,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initDetail();
      this.loadWarehouse();
    },
    initDetail() {
      this.userItems = [];
      this.data = Model.getDefaultDetail();
      this.dataTotal = Model.getDefaultGrandTotal();
      this.specialPriceData = Model.getDefaultSpecialPrice();
    },

    loadWarehouse() {
      this.isLoading = true;
      const config = Services.getWarehouseList();
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.loadSpecialPrice();
    },
    loadWarehouse_Error(message) {
      this.isLoading = false;
      this.failureInit = message;
    },

    loadSpecialPrice() {
      const options = { active: 1 }
      const config = Services.getSpecialPriceList(options);
      this.runAxios(config, null, this.loadSpecialPrice_Success, this.loadSpecialPrice_Error);
    },
    loadSpecialPrice_Success(data) {
      this.specialPriceOptions = Model.getSpecialPriceOptions(data, ItemCatalog.SpecialPriceName.Label);
      this.isLoading = false;
    },
    loadSpecialPrice_Error(message) {
      this.isLoading = false;
      this.failureInit = message;
    },

    loadDataItem() {
      this.loading = this.openLoading();
      this.isListLoading = true;
      this.failureList = null;
      const options = {
        warehouse: this.data.WarehouseID,
        availstock: 1
      }
      const config = Services.getStockList(options);
      this.runAxios(config, null, this.loadDataItem_Success, this.loadDataItem_Error)
    },
    loadDataItem_Success(data) {
      this.isListLoading = false;
      this.userItems = Model.populateDataItem(data, this.specialPriceData);
      this.closeLoading(this.loading);
    },
    loadDataItem_Error(message) {
      this.isListLoading = false;
      this.failureList = message;
      this.closeLoading(this.loading);
    },

    handleSearchSubmit() {
      this.loadDataItem();
    },
    handleFormSubmit() {
      this.loading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          const params = Model.populatePostData(this.data, this.userItems);
          this.saveData(params);
        } else {
          this.notifyFormError();
        }
      });
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.loading = this.openLoading();
      const config = Services.postStockClosing(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      )
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;
      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },
    saveData_End() {
      this.closeLoading(this.loading);
      this.loading = null;
    },

    handleReset() {
      this.showConfirm(
        Info.Message.New_ConfirmResetItems,
        this.handleReset_Confirm
      );
    },
    handleReset_Confirm() {
      this.initDetail();
    },
    handleCallbackItems(grandTotal) {
      this.dataTotal.GrandTotal = grandTotal;
    }
  }
}
</script>