import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: "Nama",
    Type: InputType.Text,
    ReadOnly: true
  },
  Price: {
    Label: "Penjualan",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency,
    ReadOnly: true
  },
  Discount: {
    Label: "Diskon",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency,
    ReadOnly: true
  },
  TotalPrice: {
    Label: "Total",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency,
    ReadOnly: true
  },
  HppTotal: {
    Label: "HPP",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D,
    ReadOnly: true
  },
  Profit: {
    Label: AppCatalog.Info.Profit,
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D,
    ReadOnly: true
  },
  ProfitMarginPercentage: {
    Label: AppCatalog.Info.ProfitMargin,
    Type: InputType.Percent,
    ReadOnly: true
  },
  TotalBruto: {
    Label: "Penjualan",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalDisc: {
    Label: "Diskon Penjualan",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalHpp: {
    Label: "Harga Pokok Penjualan",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalNetSales: {
    Label: "Penjualan Bersih",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalProfit: {
    Label: "Total " + AppCatalog.Info.Profit,
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  TotalReturn: {
    Label: "Retur Penjualan",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  }
}