var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-modal',{attrs:{"width":650,"active":_vm.active,"title":_vm.Model.Expense.Title},on:{"cancel":_vm.handleModal_Cancel,"submit":_vm.handleModal_Cancel}},[_c('kst-container',{staticClass:"px-2 py-2"},[_c('kst-section',{attrs:{"title":_vm.sellingPriceTitle}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half kst-report-item"},[_c('div',{staticClass:"has-text-weight-regular kst-report-list-title"},[_vm._v(" Harga Jual Normal ")])]),_c('div',{staticClass:"column is-half kst-report-item"},[_c('div',{staticClass:"has-text-weight-semibold kst-report-list-title has-text-right"},[_c('kst-value',{staticClass:"is-5",attrs:{"field":"NormalPrice","data":_vm.data,"catalog":_vm.Catalog}})],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half kst-report-item"},[_c('div',{staticClass:"has-text-weight-regular kst-report-list-title"},[_vm._v(" Harga Jual Spesial ")])]),_c('div',{staticClass:"column is-half kst-report-item"},[_c('div',{staticClass:"has-text-weight-semibold kst-report-list-title has-text-right"},[_c('kst-value',{staticClass:"is-5",attrs:{"field":"SpecialPrice","data":_vm.data,"catalog":_vm.Catalog}})],1)])])]),_c('kst-section',{attrs:{"title":_vm.paymentTypeTitle}},[_c('kst-table',{attrs:{"action":"","view-mode":"","data":_vm.paymentTypeList}},[_c('b-table-column',{attrs:{"sortable":"","cell-class":"ks-col-category","field":"Name","label":_vm.Catalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","cell-class":"ks-col-total","field":"Total","label":_vm.Catalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.Catalog}})]}}])}),_c('b-table-column',{attrs:{"sortable":"","centered":"","cell-class":"ks-col-status","field":"IsActive","label":_vm.Catalog.IsActive.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('kst-status',{attrs:{"value":row.IsActive,"statusEnum":_vm.Model.IsActive}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }