<template>
  <kst-section :title="sectionTitle" class="mt-5">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Model.ClientAddress.Module.FullName"
        :moduleType="Model.ClientAddress.ModuleType.New"
        @click="handleAddress_Item"
      />
    </template>

    <List :userAddresses="userAddresses"/>
  </kst-section>
</template>

<script>
import Info from "../ClientInfo.js";
import Model from "../ClientModel.js";

import List from "./ClientEdit_Addresses_List";

export default {
  components: {
    List
  },
  props: {
    userAddresses: Array
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Address.Title;
    }
  },
  methods: {
    handleAddress_Item() {
      const newAddress = Model.createAddress();
      this.userAddresses.push(newAddress);
    }
  }
}
</script>