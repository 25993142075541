import DeliveryServices from "@/services/DeliveryServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import InvoiceServices from "@/services/InvoiceServices.js";
import DispatchServices from "@/services/DispatchServices.js";

export default {
  getDeliveryList: DeliveryServices.getList,
  getDeliveryDetails: DeliveryServices.getDetails,
  getDeliveryNewData: DeliveryServices.getNewData,
  postDelivery: DeliveryServices.postDelivery,
  patchDelivery: DeliveryServices.patchDelivery,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // invoice  ["withdelivery", "withdeliveryitems", "withdeliverycount"]
  getInvoiceDetails: InvoiceServices.getDetails,
  // dispatch
  getDispatchList: DispatchServices.getList
}