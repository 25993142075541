<template>
  <kst-container class="kst-company">
    <kst-form :loading="isLoading" :title="title">
      <template #top-right>
        <Action :data="data.data"/>
      </template>

      <Details :data="data.data"/>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Model from "../CompanyModel.js";
import Services from "../CompanyServices.js";

import Action from "./CompanyProfile_Action";
import Details from "./CompanyProfile_Details";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details
  },
  data: () => ({
    data: {},
    isLoading: false,
     // error
    formWarnings: null,
    failureInit: null
  }),
  computed: {
    title() {
      return Model.Company.TitleCompany;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initLoad();
    },

    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetails();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails() {
      const config = Services.getCompanyDetails();
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.data = data;
      this.initLoad_End();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    }
  }
}
</script>