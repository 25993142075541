import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.CompanyProfile,
  ModuleType: {
    Edit: AppModuleType.Edit
  },

  Company: {
    TitleCompany: "Profil Perusahaan"
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },

  populateVerifyData(data) {
    let result = this.helper.populateVerifyData(data);
    return result;
  },

  setDetailsByData(data, details) {
    this.helper.setDetailsByData(data, details);
  },

  populateData(data) {
    let result = this.helper.populateDetails(data);
    return result;
  },

  helper: {
    createDetails() {
      return {
        id: App.Session.getCompanyID(),
        // user input
        name: "",
        address: "",
        phone: "",
        email: "",
        owner: "",
        spv_passwd: ""
      };
    },

    populateVerifyData(data) {
      return {
        CompanyID: data.id,
        Pass: data.spv_passwd
      };
    },

    setDetailsByData(data, details) {
      data.ID = details.ID;
      // user input
      data.name = App.In.getString(details.name);
      data.address = App.In.getString(details.address);
      data.phone = App.In.getString(details.phone);
      data.email = App.In.getString(details.email);
      data.owner = App.In.getString(details.owner);
    },

    populateDetails(data) {
      return {
        id: data.id,
        name: App.Out.getString(data.name),
        address: App.Out.getString(data.address),
        phone: App.Out.getString(data.phone),
        email: App.Out.getString(data.email),
        owner: App.Out.getString(data.owner)
      }
    }
  }
}