import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import StockReductionNew from "./New/StockReductionNew";
import StockReductionDetails from "./Details/StockReductionDetails";
import StockReductionList from "./List/StockReductionList";
import StockReductionPrintDetails from "./PrintDetails/StockReductionPrintDetails";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Details),
    List: RouterModel.getPath(AppModule.StockReduction, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockReduction, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Print),
    PrintDetails: RouterModel.getPath(AppModule.StockReduction, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.Details),
        component: StockReductionDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.StockReduction)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.List),
        component: StockReductionList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.StockReduction)
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.New),
        component: StockReductionNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.StockReduction)
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.PrintDetails),
        component: StockReductionPrintDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.PrintDetails),
          moduleName: RouterModel.getModule(AppModule.StockReduction)
        }
      }
    ];
  }
}