<template>
  <kst-modal
    class="kst-company-verspvpass"
    :width="800"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <ValidationObserver ref="formDetails">
      <kst-form no-bezel :errors="errors" :failure="failure">
        <kst-input load-on-focus field="spv_passwd" :data="userData" :catalog="CompanySpvPassCatalog"/>
      </kst-form>
    </ValidationObserver>
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import CompanySpvPassCatalog from "../CompanySpvPassCatalog.js";
import Info from "../CompanyInfo.js";
import Model from "../CompanyModel.js";
import Services from "../CompanyServices.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    CompanySpvPassCatalog: CompanySpvPassCatalog,
    userData: {},
    active: false,
    saveLoading: null,
    // error
    errors: null,
    failure: null
  }),
  computed: {
    title() {
      return Info.Page.VerSpvPass.Title;
    }
  },
  methods: {
    init() {
      this.userData = Model.createDetails();
    },

    show() {
      this.init();
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleModal_Submit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleModal_Submit_Success() {
      let data = Model.populateVerifyData(this.userData);
      this.verifySpvPass(data);
    },

    verifySpvPass(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.verifyCompanySpvPassword(data);
      this.runAxios(config, null,
        this.verifySpvPass_Success,
        this.verifySpvPass_Error,
        this.verifySpvPass_Invalid
      );
    },
    verifySpvPass_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    verifySpvPass_Success() {
      this.verifySpvPass_End();
      this.routeLeaveEnabled = false;

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    verifySpvPass_Error(message) {
      this.failure = message;
      this.verifySpvPass_End();
    },
    verifySpvPass_Invalid(message) {
      this.errors = [message];
      this.verifySpvPass_End();
      this.notifyFormError();
    }
  }
}
</script>