<template>
  <kst-container class="kst-autonumber-list">
    <kst-form :title="title">
      <List :failureList="failureList" :items="items" :isListLoading="isListLoading"/>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../AutoNumberModel.js";
import Services from "../AutoNumberServices.js";

import List from './AutoNumberList_List';

export default {
  mixins: [AxiosMixin],
  components: {
    List
  },
  data: () => ({
    items: [],
    isListLoading: true,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadList();
    },

    loadList() {
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      const config = Services.getAutoNumberList({ active: 1 });
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data) {
      this.updateList(data);
      this.items = data;
      this.loadList_End();
    },
    loadList_Error(message) {
      this.failureList = message;
      this.loadList_End();
    },

    updateList(dataList) {
      for (let data of dataList) {
        Model.updateDetails(data);
      }
    }
  }
}
</script>