// import App from "@/services/App/App.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
  // required in model
  Module: AppModule.DailyReport,
  ModuleType: {
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    Print: AppModuleType.Print
  },

  /*** related ***/

  /*** property ***/

  /*** method ***/

  /*** external ***/
  createAccess() {
    return AppCatalog.Access.getDefault();
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  getProcessData(data) {
    return this.helper.getProcessData(data)
  },

  helper: {
    getProcessData(data) {
      let qtyBeginningTotal = 0, qtyReceiveTotal = 0, qtyTrfTotal = 0,
        qtyAdjTotal = 0, qtySalesTotal = 0, qtyLastTotal = 0, qtyClosingTotal = 0, qtyReductionTotal = 0;

      for (let item of data.dispatchStockList) {
        qtyBeginningTotal += item.QtyBeginning;
        qtyReceiveTotal += item.QtyReceive;
        qtyTrfTotal += item.QtyTrf;
        qtyAdjTotal += item.QtyAdj;
        qtySalesTotal += item.QtySales;
        qtyLastTotal += item.QtyLast;
        qtyClosingTotal += item.QtyClosing;
        qtyReductionTotal += item.QtyReduction;
      }
      data.QtyBeginningTotal = qtyBeginningTotal;
      data.QtyReceiveTotal = qtyReceiveTotal;
      data.QtyTrfTotal = qtyTrfTotal;
      data.QtyAdjTotal = qtyAdjTotal;
      data.QtySalesTotal = qtySalesTotal;
      data.QtyLastTotal = qtyLastTotal;
      data.QtyClosingTotal = qtyClosingTotal;
      data.QtyReductionTotal = qtyReductionTotal;

      return data
    }
  }
}