<template>
  <kst-container class="px-4">
    <div class="columns mt-0">
      <ClientSearch ref="mdlClientSearch" @submit="handleClient_Submit"/>

      <div class="column pt-1 pb-0">
        <LayoutBrand />
      </div>
      <div class="column is-narrow mt-2">
        <kst-input floating-label
          field="InvoiceDate" :data="userData" :catalog="Catalog" 
        />
      </div>
      <div class="column is-narrow mt-2">
        <kst-input floating-label
          field="WarehouseID" :data="userData" :catalog="Catalog"
          :options="warehouseOptions" @input="handleSearch_Warehouse"
        />
      </div>
      <div class="column is-narrow mt-2">
        <kst-output control-off status-off field-only
          field="ClientID" :data="userData" :catalog="Catalog"
        >
          <template #default>
            <kst-output addons expanded floating-label
              field="Client"
              :data="userData"
              :catalog="Catalog"
            />
          </template>

          <template #right>
            <div class="control">
              <template v-if="!userData.ClientID">
                <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
                  <kst-button search-mode @click="handleSearch_Client"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
                  <kst-button clear-mode @click="handleClear_Client"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column">
        <div class="is-flex is-justify-content-flex-end">
          <User />
          <LayoutCompany />
        </div>
      </div>
    </div>
  </kst-container>
</template>

<script>
import Catalog from "./PosCatalog.js";
import Model from "./PosModel.js";

import ClientSearch from "@/components/Client/Search/ClientSearch";
import LayoutBrand from "@/components/Layout/Layout_Nav_Brand";
import LayoutCompany from "@/components/Layout/Layout_Nav_Company";
import User from "@/components/Layout/Layout_Nav_User";

export default {
  components: {
    ClientSearch,
    LayoutBrand,
    LayoutCompany,
    User
  },
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleSearch_Client() {
      this.$refs.mdlClientSearch.show();
    },
    handleClear_Client() {
      Model.clearDetailsByClient(this.userData);
    },
    handleClient_Submit(row) {
      Model.setDetailsByClient(this.userData, row);
    },
    handleSearch_Warehouse() {
      this.$emit(this.$kst.Enum.Event.Callback)
    }
  }
}
</script>