import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import RtnInvoiceList from "./List/RtnInvoiceList";
import RtnInvoiceNew from "./New/RtnInvoiceNew";
import RtnInvoiceDetails from "./Details/RtnInvoiceDetails";
import RtnInvoiceEdit from "./Edit/RtnInvoiceEdit";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.Edit),
    List: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.List),
    New: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.Details),
        name: RouterModel.getName(AppModule.RtnInvoice, AppModuleType.Details),
        component: RtnInvoiceDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnInvoice, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.RtnInvoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.RtnInvoice, AppModuleType.Edit),
        component: RtnInvoiceEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnInvoice, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.RtnInvoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.List),
        name: RouterModel.getName(AppModule.RtnInvoice, AppModuleType.List),
        component: RtnInvoiceList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnInvoice, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.RtnInvoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnInvoice, AppModuleType.New),
        name: RouterModel.getName(AppModule.RtnInvoice, AppModuleType.New),
        component: RtnInvoiceNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnInvoice, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.RtnInvoice)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}