<template>
  <kst-section items-mode class="mt-5">
    <kst-failure class="mb-4" data-mode :message="failureItems"/>
    <List 
      :userItems="userItems" 
      :isListLoading="isListLoading"
    />
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import DialogMixin from "@/mixins/DialogMixin.js";
import Catalog from "../StockClosingItemCatalog.js";
import List from "./StockClosingDetail_Items_List";

export default {
  mixins: [AxiosMixin,DialogMixin],
  components: {
    List
  },
  props: {
    userItems: Array,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    failureItems: null
  })
}
</script>