import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.item;
import App from "./App/App.js";

export default {
  getDetails(id, params) {
    const cid = App.Session.getCompanyID();
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["withstock"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/details/" + cid + "/" + id + urlParams
    };
  },
  getDetailsData(id, params) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
      uid: App.Session.getUserID()
    };
    options = Object.assign(options, params);
    const attributes = ["cid", "id", "uid", "withoutdetails"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/detailsdata" + urlParams
    };
  },
  getEditData(id) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
      uid: App.Session.getUserID()
    };
    const attributes = ["cid", "id", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/editdata" + urlParams
    };
  },
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "item", "type", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/list" + urlParams
    };
  },
  getListData() {
    let options = { uid: App.Session.getUserID() };
    const attributes = ["uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/listdata" + urlParams
    };
  },
  getNewData(params) {
    let options = { uid: App.Session.getUserID() };
    options = Object.assign(options, params);
    const attributes = ["purchaseitem", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/newdata" + urlParams
    };
  },
  postItem(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/items",
      data: data
    };
  },
  putItem(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/api/v1/items",
      data: data
    };
  },

  loadPackaging(itemId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/packaging/" + itemId
    };
  },

  getBarcodeList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "warehouse", "name", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/items/barcode/list2/" + urlParams
    };
  }

  /* packaging */

  /*
  getPackagingDetails(component, itemId) {
    return component.$http.get(apiEndpoint + "/api/v1/items/packaging/" + itemId);
  },
  */

  /* variant */

  /*
  getVariantList(component, itemId) {
    const cid = App.Session.getCompanyID();
    return component.$http.get(apiEndpoint + "/api/v1/items/variant/" + cid + "/" + itemId);
  }
  */
}