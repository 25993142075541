import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ReturnNumber: {
    Label: "Nomor Retur",
    Type: InputType.Text,
    ReadOnly: true
  },
  TotalBruto: {
    Label: "Penjualan",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  TotalDisc: {
    Label: "Diskon",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  Total: {
    Label: "Total",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Integer.Currency
  },
  HppTotal: {
    Label: "HPP",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D,
    ReadOnly: true
  }
}