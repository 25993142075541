import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.stock;
import App from "./App/App.js";

export default {
  getList(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "name", "warehouse", "item", "dispatch",
      "availstock", "field", "sku"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/stock/list" + urlParams
    };
  },
  getListData() {
    let options = {
      cid: App.Session.getCompanyID(),
      uid: App.Session.getUserID()
    };
    const attributes = ["cid", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/stock/listdata" + urlParams
    };
  }
}