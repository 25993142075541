<template>
  <div>
    <kst-input field="Name" load-on-focus :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../PaymentTypeCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>