<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="ClientID"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      cell-class="ks-col-price has-text-weight-bold"
      field="SellPrice"
      :label="InvoiceItemCatalog.SellPrice.Label"
      v-slot="{ column, row }"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="InvoiceItemCatalog"/>
    </b-table-column>

    <template #action="{ row }">
      <PriceIcon :row="row" :sellPrice="sellPrice"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import InvoiceItemCatalog from "../InvoiceItemCatalog.js";

import PriceIcon from "./InvoicePriceSearch_List_PriceIcon";

export default {
  components: {
    PriceIcon
  },
  props: {
    failureList: String,
    isListLoading: Boolean,
    items: Array,
    sellPrice: Number,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    InvoiceItemCatalog: InvoiceItemCatalog
  })
}
</script>