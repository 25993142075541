import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import InvoiceDetails from "./Details/InvoiceDetails";
import InvoiceEdit from "./Edit/InvoiceEdit";
import InvoiceExportDetails from "./ExportDetails/InvoiceExportDetails";
import InvoiceList from "./List/InvoiceList";
import InvoiceNew from "./New/InvoiceNew";
import InvoicePrintDetails from "./PrintDetails/InvoicePrintDetails";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Invoice, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Invoice, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Invoice, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintDetails),
    PrintThermal: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintThermal)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.Details),
        component: InvoiceDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.Edit),
        component: InvoiceEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.ExportDetails),
        component: InvoiceExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.List),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.List),
        component: InvoiceList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.New),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.New),
        component: InvoiceNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.PrintDetails),
        component: InvoicePrintDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.PrintDetails),
          moduleName: RouterModel.getModule(AppModule.Invoice)
        }
      }
    ];
  }
}