import InputType from "@/services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ID: {
    Label: "Version",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RequiredHide: true
  }
}