<template>
  <span />
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../RptStockCardCatalog.js";
import RptStockCardCatalog from "../RptStockCardDetailsCatalog.js";
import RptStockCardTransaction from "../RptStockCardTransactionCatalog.js";
import Info from "../RptStockCardInfo.js";
import Services from "../RptStockCardServices.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel() {
      this.loadLoading = this.openLoading();
      this.loadList(this.searchData);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(data) {
      this.createBook();
      this.createSheet();

      this.addList(data);

      if (this.searchData.WithReport) {
        this.addReport(data.TotalQuantity);
      }

      if (this.searchData.WithListTransaction) {
        this.addListTransaction(data.History);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);

      this.download(Info.Export.List.Excel.FileName);
      this.saveData_End();
    },

    loadList(searchData) {
      const config = Services.getItemData(
        searchData.DispatchID, 
        searchData.Date[0], 
        searchData.Date[1], 
        searchData.WarehouseID
      );
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      for (let row of data.History) {
        row.Name = this.$kst.Data.getActivityType(row.ActivityTypeID);
      }
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
      this.saveData_End();
    },

    addList(data) {
      // label
      this.addCellSection(Info.Export.List.Excel.Section.List);
      this.addRow();
      this.addRow();
      this.addColumnHeader(RptStockCardCatalog.Name)
      this.addRow();
      this.addCellValue("Name", data, RptStockCardCatalog)
      this.addRow();

      this.addSequenceHeader();
      this.addColumnHeader(Catalog.WarehouseName);
      this.addColumnHeader(Catalog.Quantity);
      this.addColumnHeader(RptStockCardCatalog.HppTotal);

      // value
      for (const row of data.Quantities) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("WarehouseName", row, Catalog);
        this.addCellValue("Quantity", row, Catalog);
        this.addCellValue("HppTotal", row, RptStockCardCatalog);
      }
    },

    addReport(data) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.Report);
      this.addRow();
      this.addRow();
      this.addColumnHeader(RptStockCardCatalog.Invoice.ExportLabel);
      this.addCellValue("Invoice", data, RptStockCardCatalog);
      this.addRow();
      this.addColumnHeader(RptStockCardCatalog.Purchase.ExportLabel);
      this.addCellValue("Purchase", data, RptStockCardCatalog);
      this.addRow();
      this.addColumnHeader(RptStockCardCatalog.StockAdj.ExportLabel);
      this.addCellValue("StockAdj", data, RptStockCardCatalog);
      this.addRow();
      this.addColumnHeader(RptStockCardCatalog.StockRed.ExportLabel);
      this.addCellValue("StockRed", data, RptStockCardCatalog);
    },

    addListTransaction(items) {
      let warehouseId;
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.List.Excel.Section.ListTransaction);
      this.addRow();

      for (const row of items) {
        if (row.WarehouseID !== warehouseId) {
          // warehouseName
          this.addRow();
          this.addColumnHeader(RptStockCardTransaction.WarehouseName);
          this.addCellValue("WarehouseName", row, RptStockCardTransaction);
          warehouseId = row.WarehouseID;
          // header
          this.addRow();

          this.addSequenceHeader();
          this.addColumnHeader(RptStockCardTransaction.Date);
          this.addColumnHeader(RptStockCardTransaction.Name);
          this.addColumnHeader(RptStockCardTransaction.Changes);
          this.addColumnHeader(RptStockCardTransaction.HppChanges);
          this.addColumnHeader(RptStockCardTransaction.QuantityAfter);
          this.addColumnHeader(RptStockCardTransaction.HppAfter);
        }
        // value
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("Date", row, RptStockCardTransaction);
        this.addCellValue("Name", row, RptStockCardTransaction);
        this.addCellValue("Changes", row, RptStockCardTransaction);
        this.addCellValue("HppChanges", row, RptStockCardTransaction);
        this.addCellValue("QuantityAfter", row, RptStockCardTransaction);
        this.addCellValue("HppAfter", row, RptStockCardTransaction);
      }
    }
  }
}
</script>