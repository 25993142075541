var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"view-mode":"","data":_vm.items}},[_c('b-table-column',{attrs:{"field":"Name","label":_vm.InvoiceProfitCatalog.Name.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceProfitCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price","field":"Price","label":_vm.InvoiceProfitCatalog.Price.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"ellipsis":"","field":column.field,"data":row,"catalog":_vm.InvoiceProfitCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"HppTotal","label":_vm.InvoiceProfitCatalog.HppTotal.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceProfitCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Profit","label":_vm.InvoiceProfitCatalog.Profit.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-value',{attrs:{"field":column.field,"data":row,"catalog":_vm.InvoiceProfitCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }