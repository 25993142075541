import ExpenseServices from "@/services/ExpenseServices.js";
import ExpenseSubServices from "@/services/ExpenseSubServices.js";


export default {
    getExpenseList: ExpenseServices.getList,
    getExpenseDetails: ExpenseServices.getDetails,
    postExpenseAdd: ExpenseServices.postExpense,
    putExpenseEdit: ExpenseServices.putExpense,
    // sub category
    getExpenseSubList: ExpenseSubServices.getList
}