import WarehouseServices from "@/services/WarehouseServices.js";
import ItemServices from "@/services/ItemServices.js";
import InvoiceServices from "@/services/InvoiceServices.js";
import DispatchServices from "@/services/DispatchServices.js";
import DispatchSpecialPriceServices from "@/services/DispatchSpecialPriceServices.js";
import PaymentTypeServices from "@/services/PaymentTypeServices.js";

export default {
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // items
  getItemList: ItemServices.getDetails,
  getBarcodeList: ItemServices.getBarcodeList,
  getDispatchList: DispatchServices.getList,
  getSpecialPriceDispatchList: DispatchSpecialPriceServices.getList,
  // post
  postInvoice: InvoiceServices.postInvoiceNew,
  // payment type
  getPaymentTypeList: PaymentTypeServices.getList
}