<template>
  <kst-container class="kst-invoice-export-details">
    <kst-form
      :failure="failureList"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :loading="isLoading"
      :title="title"
      :warnings="formWarnings"
    >
      <Export
        ref="refExport"
        :access="access"
        :searchData="searchData"
        @error="handleError"
      />

      <kst-section export-mode>
        <kst-check disabled v-model="searchData.WithDetails" :label="labelOptDetails"/>
        <kst-check v-model="searchData.WithDelivery" :label="labelOptDelivery"/>
        <kst-check v-model="searchData.WithRtnInvoice" :label="labelOptRtnInvoice"/>

        <template v-if="access.UI.Profit !== false">
          <kst-check v-model="searchData.WithProfit" :label="labelOptWithProfit"/>
        </template>

        <kst-check v-model="searchData.WithSalesRecap" :label="labelOptSalesRecap"/>
      </kst-section>

      <template #bottom-left>
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :module="Model.Module"
          :moduleType="Model.ModuleType.ExportList"
          @click="handleExport"
        />
      </template>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../InvoiceInfo.js";
import Model from "../InvoiceModel.js";
import Services from "../InvoiceServices";

import Export from "./InvoiceExportDetails_Details";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Export
  },
  data: () => ({
    Model: Model,
    searchData: {
      WithDelivery: true,
      WithDetails: true,
      WithProfit: true,
      WithRtnInvoice: true,
      WithSalesRecap: true,
      SpecialPriceCount: 0
    },
    access: {},
    id: null,
    items: [],
    isListLoading: true,
    // error
    failureInit: null,
    failureList: null,
    formWarnings: null
  }),
  computed: {
    labelOptDelivery() {
      return Info.Export.Details.Excel.Option.Delivery;
    },
    labelOptDetails() {
      return Info.Export.Details.Excel.Option.Details;
    },
    labelOptRtnInvoice() {
      return Info.Export.Details.Excel.Option.RtnInvoice;
    },
    labelOptWithProfit() {
      return Info.Export.Details.Excel.Option.Profit;
    },
    labelOptSalesRecap() {
      return Info.Export.Details.Excel.Option.SalesRecap;
    },
    title() {
      return Info.Export.Details.Excel.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!this.id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }

      this.access = Model.createAccess();
      this.initLoad();
    },
    initLoad() {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetailsData();
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetailsData() {
      const config = Services.getInvoiceDetailsData(this.id, { withoutdetails: 1 });
      this.runAxios(config, null,
        this.loadDetailsData_Success,
        this.loadDetailsData_Error,
        this.loadDetailsData_Invalid
      );
    },
    loadDetailsData_Success(data) {
      this.access = data.Access;
      this.searchData.SpecialPriceCount = data.SpecialPriceCount;

      if (this.access.UI.Profit === false) {
        this.searchData.WithProfit = false;
      }

      this.initLoad_End();
    },
    loadDetailsData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadDetailsData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    },

    handleError(message) {
      this.failureList = message;
    },
    handleExport() {
      this.failureList = null;
      this.$refs.refExport.exportExcel(this.id);
    }
  }
}
</script>