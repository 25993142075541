<template>
  <div class="my-thermal-items">
    <hr class="my-thermal-separator">

    <table>
      <template v-for="(item, index) in items">
        <tr :key="index">
          <td class="my-thermal-sequence">
            {{ index + 1 }}
          </td>
          <td colspan="100" class="my-thermal-name">
            <div>{{ item.Name }}</div>
          </td>
        </tr>
        <tr :key="index+'a'">
          <td/>
          <td class="my-thermal-qty-price">
            {{ item.TotalRequestedQuantity }} x <kst-value class="flex" field="SellPrice" :data="item" :catalog="Catalog"/>
          </td>
          
          <td class="my-thermal-equal">
            =
          </td>
          <td class="my-thermal-subtotal has-text-right">
            <kst-value field="BasicTotalPrice" :data="item" :catalog="Catalog"/>
          </td>
          
        </tr>

        <template v-if="item.SpecialPriceID !== null" >
        <tr :key="index+'b'">
          <td colspan="100" class="has-text-right">
            HEMAT - <kst-value class="flex" field="DiscountedValue" :data="item" :catalog="Catalog"/>
          </td>
        </tr>
        <tr :key="index+'c'">
          <td colspan="3">
          <td>
            <hr class="thermal-separator-total"/>
          </td>
        </tr>
        <tr :key="index+'d'">
          <td colspan="3">
          <td class="has-text-right">
            <kst-value field="SavingPrice" :data="item" :catalog="Catalog"/>
          </td>
        </tr>
        </template>

        <template v-if="index !== items.length - 1">
          <tr :key="index+'e'">
            <td>&nbsp;</td>
          </tr>
        </template>
      </template>
    </table>

    <hr class="my-thermal-separator">
  </div>
</template>

<script>
import Catalog from "./PrintThermalCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>