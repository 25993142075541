<template>
  <kst-table paginated view-mode
    :data="items"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable numeric
      v-slot="{ column, row }"
      field="Profit"
      :label="Catalog.Profit.Label"
    >
      <kst-value ellipsis :class="getClass(row)" :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "./StockAdjDetailsCatalog";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    getClass(item) {
      return (parseFloat(item.Profit) >= 0 ? '' : 'has-text-danger');
    }
  }
}
</script>