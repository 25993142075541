<template>
  <kst-table  
    :data="userItems"
    :failure="failureList"
    :loading="isListLoading"
  >

    <b-table-column centered sortable
      v-slot="{ column, row }"
      field="InvoiceDate"
      :label="SalesRecapInvoiceCatalog.InvoiceDate.Label"
    >
      <kst-value ellipsis
        :field="column.field" :data="row" :catalog="SalesRecapInvoiceCatalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row}"
      field="SONumber"
      :label="SalesRecapInvoiceCatalog.SONumber.Label"
    >
      <kst-value ellipsis
        :field="column.field" :data="row" :catalog="SalesRecapInvoiceCatalog"
      />
    </b-table-column>

    <b-table-column numeric centered sortable
      v-slot="{ column, row }"
      field="PaymentTypeID"
      :label="SalesRecapInvoiceCatalog.PaymentTypeID.Label"
    >
      <kst-value ellipsis
        :field="column.field" :data="row" :catalog="SalesRecapInvoiceCatalog"
      />
    </b-table-column>

    <b-table-column numeric centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="SalesRecapInvoiceCatalog.Total.Label"
    >
      <kst-value ellipsis
        :field="column.field" :data="row" :catalog="SalesRecapInvoiceCatalog"
      />
    </b-table-column>

  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Catalog from "../SalesRecapCatalog.js";
// import Info from "../SalesRecapInfo.js";
import SalesRecapInvoiceCatalog from "../SalesRecapInvoiceCatalog.js";
import Model from "../SalesRecapModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userItems: Array,
    failureList: String,
    isListLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    SalesRecapInvoiceCatalog: SalesRecapInvoiceCatalog,
  })
}
</script>