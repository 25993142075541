<template>
  <kst-container class="kst-client-edit">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :loading="isLoading"
        :title="title"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <Details :loading="isLoading" :userData="userData"/>
        <Addresses :loading="isLoading" :userAddresses="userAddresses"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";

import Router from "../ClientRouter.js";
import Model from "../ClientModel.js";
import Info from "../ClientInfo.js";

import Details from "./ClientEdit_Details";
import Addresses from "./ClientEdit_Addresses";

import Services from "../ClientServices.js";

export default {
  components: {
    Details,
    Addresses
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, SnackbarMixin, RouterMixin],
  data: () => ({
    userData: {},
    userAddresses: [],
    isLoading: false,
    saveLoading: null,
    cancelUrl: Router.Path.List,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }
      this.userData = {};

      this.loadDetails(id);
    },

    loadDetails(id) {
      this.failureInit = null;
      this.isLoading = true;

      const config = Services.getClientDetails(id);
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      this.userData = Model.createDetails();
      for (const row of data.Address) {
        const address = Model.createAddress();
        Model.setAddressByData(address, row);
        this.userAddresses.push(address);
      }
      Model.setDetailsByData(this.userData, data);
      this.isLoading = false;
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let data = Model.populateData(this.userData, this.userAddresses);
          this.saveData(data);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData_End() {
      this.closeLoading(this.saveLoading);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.putClient(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },

    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;
      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>