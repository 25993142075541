export default {
  Rows: [
    {
      "TransactionID": 2154,
      "TransactionName": "2021050092-ABC321",
      "WarehouseID": 7,
      "WarehouseName": "KST Warehouse 1",
      "Date": "2021-05-24T17:00:00.000Z",
      "SearchTypeID": 3,
      "StatusID": 1,
      "Total": 68400
    },
    {
      "TransactionID": 757,
      "TransactionName": "RCV245-2",
      "WarehouseID": 8,
      "WarehouseName": "KST Warehouse 2",
      "Date": "2021-05-23T17:00:00.000Z",
      "SearchTypeID": 2,
      "StatusID": 1,
      "Total_Qty": 580000
    },
    {
      "TransactionID": 4,
      "TransactionName": null,
      "WarehouseID": 7,
      "WarehouseName": "KST Warehouse 2",
      "Date": "2021-05-21T17:00:00.000Z",
      "SearchTypeID": 4,
      "Qty": -2
    },
    {
      "TransactionID": 4,
      "TransactionName": null,
      "WarehouseID": 8,
      "WarehouseName": "KST Warehouse 2",
      "Date": "2021-05-21T17:00:00.000Z",
      "SearchTypeID": 4,
      "Qty": 2
    },
    {
      "TransactionID": 5,
      "TransactionName": null,
      "WarehouseID": 7,
      "WarehouseName": "KST Warehouse 1",
      "Date": "2021-05-20T17:00:00.000Z",
      "SearchTypeID": 5,
      "Qty": 15
    },
    {
      "TransactionID": 98,
      "TransactionName": "RTN INV HPP DEC",
      "WarehouseID": 7,
      "WarehouseName": "KST Warehouse 1",
      "Date": "2021-05-19T17:00:00.000Z",
      "SearchTypeID": 7,
      "Total": 20000
    },
    {
      "TransactionID": 293,
      "TransactionName": "Air Mineral - 200 ml",
      "WarehouseID": 51,
      "WarehouseName": "KST Warehouse 3",
      "Date": null,
      "SearchTypeID": 101,
      "Qty": 82
    }
  ]
}