<template>
  <kst-section :title="scannerTitle" >
    <div class="columns">
      <div class="column is-left">
        <div class="columns">
          <div class="column is-3">
            <kst-input floating-label
              field="Barcode" ref="txtBarcode" :data="searchData" :catalog="ItemCatalog"
              @submit="handleEnterBarcode"
            />
          </div>
          <div class="column is-2">
            <kst-input floating-label
              field="QtyScan" ref="txtQty" :data="searchData" :catalog="ItemCatalog"
              @submit="handleEnterBarcode"
            />
          </div>
        </div>
      </div>
    </div>
  </kst-section>
</template>
<script>
import SearchMixin from "@/mixins/SearchMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import ItemCatalog from "../SpecialPriceAdjItemCatalog.js";
import Model from "../SpecialPriceAdjModel.js";
import Info from "../SpecialPriceAdjInfo.js";

export default {
  mixins: [SearchMixin, SnackbarMixin],
  props: {
    dataStock: Array
  },
  data: () => ({
    ItemCatalog: ItemCatalog,
    searchData: {}
  }),
  mounted() {
    window.addEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  created() {
    this.initSearch();
  },
  computed: {
    scannerTitle() {
      return Info.Scanner.Title
    }
  },
  methods: {
    initSearch() {
      this.searchData = Model.getDefaultBarcodeSearch();
    },
    handleKeyDown(event) {
      switch(event[this.$kst.Key.KeyProp]) {
        case this.$kst.Key.Key.F1:
          this.setFocusBarcode();
          event.preventDefault();
          break;
        case this.$kst.Key.Key.F2:
          this.setFocusQuantity();
          event.preventDefault();
          break;
      }
    },
    setFocusQuantity() {
      this.$refs.txtQty.focus();
    },
    setFocusBarcode() {
      this.$refs.txtBarcode.focus();
    },
    handleEnterBarcode() {
      const dataItems = this.findBarcode();
      if(dataItems) {
        this.resetData();
        this.$emit(this.$kst.Enum.Event.Callback, dataItems);
      }
    },
    findBarcode() {
      const barcode = this.searchData.Barcode.toLowerCase();
      let find = this.dataStock.find(item => (item.Barcode || "").toLowerCase() === barcode);
      if(!find) {
        this.notifyFormError('', Info.Message.Invalid_Barcode);
        return false;
      }
      const data = Object.assign({ QtyScan: this.searchData.QtyScan}, find);
      return data;
    },
    resetData() {
      this.searchData.Barcode = "";
      this.searchData.QtyScan = 1;
    }
  }
}
</script>