<template>
  <kst-container class="kst-item-details">
    <kst-form 
      :loading="isLoading" 
      :title="title"
      :failureInit="failureInit"
      :hide-body="!!formWarnings"
      :hide-footer="!!formWarnings"
      :warnings="formWarnings"  
    >
      <template #top-right>
        <Action :data="data"/>
      </template>

      <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
        <b-tab-item :label="tabs.Details">
          <Details :data="data"/>
          <Packagings :items="data.Packaging"/>
          <Variants :items="data.Variants"/>
          <Dispatches :access="access" :items="data.Variations"/>
        </b-tab-item>
      </b-tabs>
    </kst-form>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

import Action from "./ItemDetails_Action";
import Details from "./ItemDetails_Details";
import Dispatches from "./ItemDetails_Dispatches";
import Packagings from "./ItemDetails_Packagings";
import Variants from "./ItemDetails_Variants";

import Services from "../ItemServices.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Action,
    Details,
    Dispatches,
    Packagings,
    Variants
  },
  data: () => ({
    access: {},
    data: {},
    isLoading: false,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    },
     // error
    formWarnings: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Details_ParamIncomplete];
        return;
      }

      this.access = Model.createAccess();
      this.data = {};
      this.initLoad(id);
    },
    initLoad(id) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadDetailsData(id);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadDetailsData(id) {
      const config = Services.getItemDetailsData(id);
      this.runAxios(config, null,
        this.loadDetailsData_Success,
        this.loadDetailsData_Error,
        this.loadDetailsData_Invalid
      );
    },
    loadDetailsData_Success(data) {
      this.access = data.Access;
      this.data = data.Details;
      this.initLoad_End();
    },
    loadDetailsData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },
    loadDetailsData_Invalid(message) {
      this.formWarnings = [message];
      this.initLoad_End();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>