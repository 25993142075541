import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import WarehouseModel from "@/components/Warehouse/WarehouseModel.js";

export default {
    Module: AppModule.Transaction,
    ModuleType: {
      Print: AppModuleType.Print,
      Export: AppModuleType.Export,
      ExportList: AppModuleType.ExportList,
      List: AppModuleType.List,
    },

    DispatchID: {
        Label: 'Barang'
    },

    Warehouse: {
      WarehouseName: 'Semua Gudang'
    },

    Report: {
      TitleTransaction: "Daftar Transaksi",
      TitleSearch: "Rincian"
    },

    TransList: {
      Receive: {
        ID: App.Enum.ActivityType.Receive.ID,
        Name: App.Enum.ActivityType.Receive.Module.FullName
      },
      Invoice: {
        ID: App.Enum.ActivityType.Invoice.ID,
        Name: App.Enum.ActivityType.Invoice.Module.FullName
      },
      TransferStock: {
        ID: App.Enum.ActivityType.StockTrf.ID,
        Name: App.Enum.ActivityType.StockTrf.Module.FullName
      },
      StockOpname: {
        ID: App.Enum.ActivityType.StockOpname.ID,
        Name: App.Enum.ActivityType.StockOpname.Module.FullName
      },
      Retur: {
        ID: App.Enum.ActivityType.RtnInvoice.ID,
        Name: App.Enum.ActivityType.RtnInvoice.Module.FullName
      },
      HppAdj: {
        ID: App.Enum.ActivityType.HppAdj.ID,
        Name: App.Enum.ActivityType.HppAdj.Module.FullName
      }
    },

    createTransOptions(optionAllText) {
      return this.helper.createTransOptions(this.TransList, optionAllText);
    },

    getWarehouseOptions(warehouseList, optionAllText) {
      return WarehouseModel.createOptions(warehouseList, optionAllText);
    },

    helper: {
      createTransOptions(transList, optionAllText) {
        const rowId = "ID";
        const rowLabel = "Name";
  
        const items = [
          { [rowId]: transList.Receive.ID, [rowLabel]: transList.Receive.Name },
          { [rowId]: transList.Invoice.ID, [rowLabel]: transList.Invoice.Name },
          { [rowId]: transList.TransferStock.ID, [rowLabel]: transList.TransferStock.Name },
          { [rowId]: transList.StockOpname.ID, [rowLabel]: transList.StockOpname.Name },
          { [rowId]: transList.Retur.ID, [rowLabel]: transList.Retur.Name },
          { [rowId]: transList.HppAdj.ID, [rowLabel]: transList.HppAdj.Name }
        ];
  
        // set: list options
        let listOptions = { id: rowId, label: rowLabel };
  
        if (optionAllText) {
          listOptions.allActive = true;
          listOptions.allText = optionAllText;
        }
  
        // create: select items
        const selectItems = App.Search.createList(items, listOptions);
  
        // create: select options
        return App.In.getSelectOptions(rowId, rowLabel, selectItems);
      },
    }

}