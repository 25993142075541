<template>
  <kst-container class="kst-receive-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >
        <kst-section :title="sectionTitle" class="mt-5" >
          <PurchaseSearch ref="mdlPurchaseSelect" @submit="handleSubmit_PO"/>

          <kst-warning class="mb-5" :items="detailsWarning"/>

          <kst-output control-off status-off
            field="PurchasingID" :data="userData" :catalog="Catalog"
          >
            <template #right>
              <div class="control">
                <template v-if="!userData.PONumber">
                  <kst-tooltip search-mode hover-only :content="Catalog.PONumber.Label">
                    <kst-button search-mode @click="handleSearch_PO()"/>
                  </kst-tooltip>
                </template>
                <template v-else>
                  <kst-tooltip clear-mode hover-only :content="Catalog.PONumber.Label">
                    <kst-button clear-mode @click="handleRowDelete()"/>
                  </kst-tooltip>
                </template>
              </div>
            </template>
          </kst-output>
          <kst-output field="PurchasingDate" :data="userData" :catalog="Catalog"/>
        </kst-section>

        <Details :userData="userData" :userItems="userItems" :warehouseOptions="warehouseOptions" />
        <Received :userData="userData" :warehouseOptions="warehouseOptions" />
        <Items :userData="userData" :userItems="userItems" :searchData="searchData" :itemScanner="itemScanner" />
        <Total :data="userData" />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Catalog from '../ReceiveCatalog.js';
import Info from "../ReceiveInfo.js";
import Model from "../ReceiveModel.js";
import Router from "../ReceiveRouter.js";
import Services from '../ReceiveServices.js';
import PurchaseModel from "@/components/Purchase/PurchaseModel.js";

import Details from './ReceiveNew_Details';
import Items from './ReceiveNew_Items';
import Total from './ReceiveNew_Total';
import Received from './ReceiveNew_Received';
import PurchaseSearch from "@/components/Purchase/Search/PurchaseSearch";

export default {
  components: {
    Details,
    Items,
    Total,
    Received,
    PurchaseSearch
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    isLoading: false,
    Catalog: Catalog,
    warehouseOptions: {},
    userData: {},
    userItems: [],
    itemScanner: [],
    searchData: {},
    cancelUrl: Router.Path.List,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    sectionTitle() {
      return Info.Section.PO.Title;
    },
    detailsWarning() {
      return [Info.Message.New_Details];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const purchaseId = this.getUrlParam(this.$kst.Enum.Param.PurchaseID);
      this.warehouseOptions = null;
      this.userData = Model.createDetails();
      this.initLoad(purchaseId);
      this.loadItems(this.loadCounter, this.searchData);
      this.initSearch()
    },

    initSearch() {
      this.searchData = {
        Search: "",
        Qty: 1,
        Barcode: null
      }
    },

    initLoad(purchaseId) {
      this.isLoading = true;
      this.failureInit = null;

      if (purchaseId !== null) {
        this.loadPurchasing(purchaseId);
      } else {
        this.loadWarehouse();
      }
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    handleRowDelete() {
      this.userItems = [];
      this.userData = Model.createDetails();
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postReceive(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },

    handleSearch_PO() {
      this.$refs.mdlPurchaseSelect.show();
    },
    handleSubmit_PO(row) {
      if (row.ID === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.formWarnings = null;

      if (row.Status === PurchaseModel.Status.Closed.ID) {
        this.formWarnings = [Info.Message.New_StatusClosed];
        return;
      }

      this.isLoading = true;

      // load detail purchasing
      this.loadPurchasing(row.ID);
      this.$emit(this.$kst.Enum.Event.Submit, row);
    },

    loadPurchasing(id) {
      const options = { withpackagings: 1 };
      const config = Services.getPurchaseDetails(id, options);
      this.runAxios(config, null, this.loadPurchasing_Success, this.loadPurchasing_Error);
    },
    loadPurchasing_Success(data) {
      // items
      this.userItems = Model.createItemsByPurchasing(data.Items);

      // details
      Model.setDetailsByPurchasing(this.userData, data);
      Model.updateDetails(this.userData, this.userItems);

      if (this.warehouseOptions === null) {
        this.loadWarehouse();
      } else {
        this.initLoad_End();
      }
    },
    loadPurchasing_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadItems(counter, searchData) {
      this.isListLoading = true;

      let options = {};
      options.name = this.$kst.Out.getString(searchData.Search);
      options.field = Model.Search.Fields;

      const config = Services.getBarcodeList(options);
      this.runAxios(config, counter, this.loadItems_Success, this.loadItems_Error);
    },
    loadItems_End() {
      this.isListLoading = false;
    },
    loadItems_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.itemScanner = data;

      this.initLoad_End();
      this.loadItems_End();
    },
    loadItems_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
      this.loadItems_End();
    },
  }
}
</script>