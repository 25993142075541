import StockServices from "@/services/StockServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import CompanyServices from "@/services/CompanyServices.js";

export default {
  getStockList: StockServices.getList,
  getStockListData: StockServices.getListData,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // company
  getCompanyDetails: CompanyServices.getDetails
}