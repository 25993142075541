<template>
  <div>
    <kst-input load-on-focus field="ExpenseNumber" :data="userData" :catalog="Catalog"/>
    <kst-input load-on-focus field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="SubCategoryID" :options="categoryOption" :data="userData" :catalog="Catalog"/>
    <kst-input field="Total" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ExpenseCatalog.js";

export default {
  props: {
    userData: Object,
    categoryOption: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>