import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.report;
import App from "./App/App.js";


export default {
  getBestSellingItems(filter) {
    const companyId = App.Session.getCompanyID();
    const urlParam =
      '?cid=' + companyId +
      '&startdate=' + filter.StartDate +
      '&enddate=' + filter.EndDate +
      '&limit=' + filter.Limit

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/report/sales/bestsellingitems" + urlParam
    };
  },
  getSalesInvoiceTotal(filter) {
    const companyId = App.Session.getCompanyID();
    const urlParam =
      '?cid=' + companyId +
      '&startDate=' + filter.StartDate +
      '&endDate=' + filter.EndDate;

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/sales/total" + urlParam
    };
  },
  getItemData(dispatchId, startDate, endDate, warehouseId) {
    const companyId = App.Session.getCompanyID();
    const urlParam =
      '?cid=' + companyId +
      '&item=' + dispatchId +
      '&startDate=' + startDate +
      '&endDate=' + endDate +
      '&warehouse=' + warehouseId;
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/items" + urlParam
    };
  },
  getProfitLoss(filter) {
    const companyId = App.Session.getCompanyID();
    const urlParam =
      '?cid=' + companyId +
      '&startDate=' + filter.startDate +
      '&endDate=' + filter.endDate +
      '&wid=' + filter.warehouseId +
      '&byMonth=' + filter.byMonth +
      '&startdate=' + App.Out.getDate(filter.startDate) +
      '&enddate=' + App.Out.getDate(filter.endDate);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/profitloss" + urlParam
    };
  },
  getProfitLossTotal(filter) {
    const companyId = App.Session.getCompanyID();
    const urlParam =
      '?cid=' + companyId +
      '&startDate=' + filter.startDate +
      '&endDate=' + filter.endDate;
    
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/profitloss/total" + urlParam
    };
  },
  getTurnoverSales(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "warehouse", "startDate", "endDate", "withreturn"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/report/sales/turnover" + urlParams
    };
  },
  getTransaction(filter) {
    const companyId = App.Session.getCompanyID();
    const urlParams =
      '?cid=' + companyId +
      '&item=' + filter.dispatchId +
      '&startDate=' + filter.startDate +
      '&endDate=' + filter.endDate +
      '&activitytype=' + filter.transId +
      '&warehouse=' + filter.warehouseId;

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/transaction" + urlParams
    };
  },
  getStockAvailability(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = [
      "cid", "name", "warehouseid", "datefrom", "dateto", "field"
    ];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/stockavailability" + urlParams
    }
  },
  getDailyReport(params) {
    let options = { cid: App.Session.getCompanyID() };
    options = Object.assign(options, params);
    const attributes = [
      "cid", "warehouse", "date"
    ];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/reports/dailyreport" + urlParams
    }
  }
}