<template>
  <kst-section :title="sectionTilte" class="mt-5">
    <kst-section search-mode class="pb-0">
      <form novalidate @submit.prevent="debounceSearch">
        <div class="columns my-0">
          <div class="column is-narrow">
            <kst-input floating-label
              field="WarehouseID" :data="userData" :catalog="Catalog"
              :options="warehouseOptions" @input="handleInput"
            />
          </div>
          <div class="column is-narrow">
            <kst-input floating-label
              field="CashierID" :data="userData" :catalog="Catalog"
              :options="cashierOptions" @input="handleInput"
            />
          </div>
          <div class="column is-narrow">
            <kst-input floating-label
              field="InvoiceDate" :data="userData" :catalog="Catalog"
              @input="handleInput"
            />
          </div>

          <div class="column is-narrow">
            <kst-button reset-mode @click="handleReset"/>
            <input type="submit" value="Cari" class="is-hidden">
          </div>
        </div>
      </form>
    </kst-section>
    <kst-warning class="mb-4" :items="itemsWarning"/>

    <template v-if="this.userData.WarehouseID !== null && this.userData.CashierID !== null" >
    <kst-failure class="mb-4" data-mode :message="failureItems"/>
      <List :userData="userData" :userItems="userItems" :isListLoading="isListLoading" />
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Catalog from "../SalesRecapCatalog.js";
import SearchMixin from "@/mixins/SearchMixin.js";
import Info from "../SalesRecapInfo.js";
import Model from "../SalesRecapModel.js";
// import Services from "../SalesRecapServices.js";

import List from "./SalesRecapNew_Invoices_List";

export default {
  mixins: [AxiosMixin, SearchMixin],
  components: {
    List
  },
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object,
    cashierOptions: Object,
    isListLoading: Boolean
  },
  data: () => ({
    Model: Model,
    Catalog: Catalog,
    failureItems: null,
    // error
    failureList: null
  }),
  created() {
    this.initSearch(this.userData, this.submit);
  },
  computed: {
    itemsWarning() {
      return this.userData.WarehouseID === null || this.userData.CashierID === null ? [
        Info.Message.New_Invoices] : null;
    },
    sectionTilte() {
      return "Daftar Nota Penjualan"
    },
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    }
  }
}
</script>