<template>
  <kst-section items-mode>
    <kst-table view-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="kst-receive-related-Name"
        field="Name"
        :label="ReceiveItemCatalog.Name.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="ReceiveItemCatalog"/>
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="Quantity"
        :label="ReceiveItemCatalog.Quantity.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="ReceiveItemCatalog"/>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="Packaging"
        :label="ReceiveItemCatalog.Packaging.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="ReceiveItemCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="BuyPrice"
        :label="ReceiveItemCatalog.BuyPrice.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="ReceiveItemCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="ExpenseAmount"
        :label="ReceiveItemCatalog.ExpenseAmount.Label"
      >
        <kst-value ellipsis :field="column.field" :data="row" :catalog="ReceiveItemCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import ReceiveItemCatalog from "../ReceiveItemCatalog.js";
import Model from "../ReceiveModel.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    ReceiveItemCatalog: ReceiveItemCatalog,
    Model: Model
  })
}
</script>