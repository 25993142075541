<template>
  <kst-container class="kst-receive-new">
    <InvoiceSearch
      ref="mdlInvoiceSearch"
      :warehouseList="warehouseList"
      @submit="handleInvoice_Submit"
    />

    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :loading="isLoading"
        :title="title"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        @submit="handleFormSubmit"
      >
        <kst-section :title="sectionTitle">
          <kst-input addons
            field="SONumber"
            :data="userData"
            :disabled="true"
            :catalog="Catalog"
          >
            <template #right>
              <div class="control">
                <template v-if="userData.SOID === null">
                  <kst-tooltip search-mode hover-only :content="Catalog.SOID.Label">
                    <kst-button search-mode @click="handleSearch_SO"/>
                  </kst-tooltip>
                </template>
                <template v-else>
                  <kst-tooltip clear-mode hover-only :content="Catalog.SOID.Label">
                    <kst-button clear-mode @click="handleClear_SO"/>
                  </kst-tooltip>
                </template>
              </div>
            </template>
          </kst-input>

          <template v-if="userData.SOID !== null">
            <kst-input 
              field="InvoiceDate"
              :data="userData"
              :catalog="Catalog"
            />
          </template>
        </kst-section>

        <Details :data="userData"/>

        <kst-section class="mt-5" :title="titleStatus">
          <template v-if="userData.SOID === null">
            <kst-warning :items="detailWarning"/>
          </template>
          <template v-else>
            <kst-input fieldOnly
              field="StatusChanged"
              :options="statusOptions"
              :data="userData" 
              :catalog="Catalog"
              @input="handleStatus"
            />
          </template>
        </kst-section>

        <Items :data="userData" :items="userItems"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";

import Router from "../DeliveryRouter.js";
import Model from "../DeliveryModel.js";
import Catalog from "../DeliveryCatalog.js";
import Info from "../DeliveryInfo.js";
import Services from "../DeliveryServices.js";

import Details from "./DeliveryNew_Details";
import Items from "./DeliveryNew_Items";
import InvoiceSearch from "@/components/Invoice/Search/InvoiceSearch";

export default {
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  components: {
    Details,
    InvoiceSearch,
    Items
  },
  data: () => ({
    Catalog: Catalog,
    isLoading: true,
    userData: {},
    warehouseList: [],
    tempUserItems: null,
    userItems: [],
    deliveryItemList: [],
    dispatchList: null,
    invoiceItems: [],
    errMessage: null,
    cancelUrl: Router.Path.List,
    statusOptions: null,
    saveLoading: null,
    // error
    failureList: null,
    errors: null,
    failure: null,
    failureInit: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    },
    sectionTitle() {
      return Info.Section.Invoice.Title;
    },
    titleStatus() {
      return Info.Section.Status.TitleNew;
    },
    detailWarning() {
      return [Info.Message.New_Details];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const invoiceId = this.getUrlParam(this.$kst.Enum.Param.InvoiceID);

      this.initReset();
      this.initLoad(invoiceId);
    },
    initReset() {
      this.userData = Model.createDetails();
      this.userItems = [];

      // status
      this.statusOptions = Model.createStatusOptions('', Model.Status.Draft.ID);
      Model.setDefaultStatus(this.userData);
    },
    initLoad(invoiceId) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadNewData(invoiceId);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadNewData(invoiceId) {
      const config = Services.getDeliveryNewData({ invoice: invoiceId });
      this.runAxios(config, invoiceId, this.loadNewData_Success, this.loadNewData_Error);
    },
    loadNewData_Success(data, invoiceId) {
      this.warehouseList = data.WarehouseList;

      if (invoiceId) {
        this.loadInvoice_Success(data.InvoiceDetails);
      }
      else {
        this.initLoad_End();
      }
    },
    loadNewData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleStatus() {
      Model.updateStatus(this.userData);
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let summaryListItem = Model.populateItems(this.userItems);
          let data = Model.populateData(this.userData, summaryListItem);
          this.saveData(data);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postDelivery(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },

    handleSearch_SO() {
      this.$refs.mdlInvoiceSearch.show();
    },
    handleClear_SO() {
      this.init();
    },

    handleInvoice_Submit(row) {
      this.userItems = [];
      this.invoiceItems = [];
      this.tempUserItems = [];
      this.deliveryItemList = [];
      this.dispatchList = null;

      if (row === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.loadInvoice(row);
      this.active = false;
    },

    loadInvoice(row){
      this.isLoading = true;

      const config = Services.getInvoiceDetails(row.ID);
      this.runAxios(config, null, this.loadInvoice_Success, this.loadInvoice_Error);
    },
    loadInvoice_Success(data) {
      // items invoice
      for (const invoiceItem of data.Items) {
        if (invoiceItem.TotalRequestedQuantity > 0) {
          this.invoiceItems.push(invoiceItem);
        }
      }

      // details
      Model.setDetailsByInvoice(this.userData, data);
      this.loadDeliveryList(data.ID);
    },
    loadInvoice_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadDeliveryList(invoiceId) {
      let options = { invoice: invoiceId, withitems: 1 };
      const config = Services.getDeliveryList(options);
      this.runAxios(config, null, this.loadDeliveryList_Success, this.loadDeliveryList_Error);
    },
    loadDeliveryList_Success(data) {
      // item delivery
      for (const delivery of data) {
        for (const item of delivery.Items) {
          this.deliveryItemList.push(item);
        }
      }

      this.loadItemList();
    },

    loadItemList() {
      if (this.dispatchList !== null) {
        this.populateInvoiceItemList();
        return;
      }

      this.dispatchList = [];

      // dispatch list
      const config = Services.getDispatchList();
      this.runAxios(config, null, this.loadItemList_Success, this.loadItemList_Error);
    },
    loadItemList_Success(data) {
      this.dispatchList = data;
      this.populateInvoiceItemList();
      this.initLoad_End();
    },
    loadItemList_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },

    populateInvoiceItemList() {
      const invoiceKeyList = ['DispatchID', 'PackagingName'];
      const invoiceKeyData = 'RequestedQuantity';
      const deliveryKeyList = ['DispatchID', 'PackagingName'];
      const deliveryKeyData = 'Quantity';

      const resultItems = this.group2List(
        this.invoiceItems, invoiceKeyList, invoiceKeyData,
        this.deliveryItemList, deliveryKeyList, deliveryKeyData
      );

      let deliveryItemList = [];
      for (const resultKey in resultItems) {
        const result = resultItems[resultKey];

        const invoiceItem = result.base;
        const deliveryItem = result.source;
        const invoiceData = invoiceItem.data;

        // skip deleted items
        if (invoiceData.IsActive === 0) {
          continue;
        }

        let isAddItem = false;
        if (invoiceItem.value > deliveryItem.value) {
          // skip fulfilled item
          isAddItem = true;
        }

        // support refresh button: populate user input
        let qty = '';
        if (this.tempUserItems) {
          for (const tempItem of this.tempUserItems) {
            if (tempItem.DispatchID === invoiceData.DispatchID &&
            tempItem.PackagingName === invoiceData.PackagingName) {
              qty = tempItem.Quantity;
              isAddItem = true;
              break;
            }
          }
        }

        if (isAddItem) {
          let item = Model.createItemBlank();
          Model.setItemByInvoice(item, invoiceData);
          item.Quantity = qty;
          item.AvailableQuantity = (invoiceItem.value - deliveryItem.value);

          // populate key: UnitWeight
          const dispatchData = this.searchData(this.dispatchList, 'DispatchID',
            invoiceData.DispatchID
          );
          if (dispatchData) {
            item.UnitWeight = dispatchData.Weight;
            item.ImageSrc = dispatchData.ImageSrc;
          }

          // update computed fields
          Model.updateItem(item);
          Model.updateItemByPackaging(item);
          deliveryItemList.push(item);
        }
      }

      this.userItems = deliveryItemList;
    },
    searchData(itemList, keyData, searchValue) {
      for (const item of itemList) {
        if (item[keyData] === searchValue) {
          return item;
        }
      }

      return null;
    },
    group2List(baseItemList, baseKeyList, baseKeyData, sourceItemList, sourceKeyList,
    sourceKeyData) {
      let baseList = this.groupItems(baseItemList, baseKeyList, baseKeyData);
      let sourceList = this.groupItems(sourceItemList, sourceKeyList, sourceKeyData);

      let resultList = {};
      for (const baseKey in baseList) {
        const base = baseList[baseKey];
        let source = { value: 0, data: null };
        const basesKey = Object.prototype.hasOwnProperty.call(sourceList, baseKey);
        if (basesKey) {
          source = sourceList[baseKey];
        }

        resultList[baseKey] = { base: base, source: source };
      }

      return resultList;
    },
    groupItems(itemList, keyList, keyData) {
      let groupedItemList = {};

      for (const item of itemList) {
        const keySet = this.getKeySet(item, keyList);
        const keySets = Object.prototype.hasOwnProperty.call(groupedItemList, keySet);
        if (keySets) {
          groupedItemList[keySet].value += item[keyData];
        }
        else {
          groupedItemList[keySet] = {
            value: item[keyData],
            data: item
          };
        }
      }
      return groupedItemList;
    },
    getKeySet(item, keyList) {
      let keySet = '';

      for (const key of keyList) {
        if (keySet !== '') {
          keySet += '_';
        }
        keySet += item[key];
      }
      return keySet;
    }
  }
}
</script>