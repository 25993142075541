import AppCatalog from "@/services/App/AppCatalog.js";

/**
 * Excel mixin.
 * - index: start with 0.
 */
export default {
  data: () => ({
    activeBook: null,
    activeIndexRow: null,
    activeIndexSeq: 1,
    activeRow: null,
    activeSheet: null
  }),
  methods: {
    download(fileName) {
      this.$xlsx.writeFile(this.activeBook, fileName);

      // reset
      this.activeBook = null;
      this.activeSheet = null;
      this.activeRow = null;
      this.activeIndexRow = null;
    },
    resetSequence() {
      this.activeIndexSeq = 1;
    },

    /** book functions **/

    createBook() {
      this.activeBook = this.$xlsx.utils.book_new();
      return this.activeBook;
    },
    getBook() {
      return this.activeBook;
    },

    /** sheet functions **/

    // create blank-sheet, not append to book
    createSheet() {
      this.activeRow = [];
      this.activeIndexRow = null;
      this.activeSheet = [this.activeRow];
      return this.activeSheet;
    },
    // append active-sheet to active-book
    addSheet(title) {
      let options = { skipHeader: true };
      let sheet = this.$xlsx.utils.json_to_sheet(this.activeSheet, options);
      this.$xlsx.utils.book_append_sheet(this.activeBook, sheet, title);

      // reset
      this.activeSheet = null;
      this.activeRow = null;
      this.activeIndexRow = null;
    },
    getSheet() {
      return this.activeSheet;
    },
    getSheets() {
      if (this.activeBook === null) {
        return null;
      }
      else {
        return this.activeBook.Sheets;
      }
    },

    /** row functions **/

    addRow() {
      if (this.activeSheet === null) {
        this.createSheet();
      }

      this.activeRow = [];
      this.activeSheet.push(this.activeRow);
      this.activeIndexRow++;
    },
    setActiveRow(index) {
      this.activeIndexRow = (index >= this.activeSheet.length) ?
        this.activeSheet.length - 1 : index;
      this.activeRow = this.activeSheet[this.activeIndexRow];
    },
    getRow() {
      return this.activeRow;
    },

    /** cell functions **/

    addCell(cell) {
      if (this.activeRow === null) {
        this.addRow();
      }

      this.activeRow.push(cell);
      return this.getCellCode(this.activeRow.length - 1, this.activeIndexRow);
    },
    addCellValue(field, data, catalog) {
      this.addCell(this.$kst.Xlsx.getValue(field, data, catalog));
    },
    addCellSection(title) {
      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: title
      });
    },
    addCellStatus(value, statusEnum) {
      this.addCell(this.$kst.Xlsx.getStatus(value, statusEnum));
    },
    addColumnHeader(value) {
      let title = "";

      if (this.$kst.Type.isString(value)) {
        // custom title
        title = value;
      }
      else {
        // use data catalog
        title = value.ExportLabel ? value.ExportLabel : value.Label;
      }

      this.addCell({ [AppCatalog.Xlsx.Key.Value]: title });
    },
    addSequenceCell() {
      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: this.activeIndexSeq
      });
      this.activeIndexSeq++;
    },
    addSequenceHeader() {
      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: AppCatalog.Xlsx.Default.Sequence
      });
      this.resetSequence();
    },

    /** support functions **/

    getColumnCode(indexColumn) {
      return this.$xlsx.utils.encode_col(indexColumn);
    },
    getRowCode(indexRow) {
      return this.$xlsx.utils.encode_row(indexRow);
    },
    getCellCode(indexColumn, indexRow) {
      return this.getColumnCode(indexColumn) + this.getRowCode(indexRow);
    }
  }
}