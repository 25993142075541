<template>
  <kst-container class="kst-invoice-new">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :loading="isLoading"
        :title="title"
        @submit="handleFormSubmit"
      >
        <Sales
          :failureSales="failureSales"
          :userData="userData"
          :warehouseList="warehouseList"
          @submit="handleSales_Submit"
          @reset="handleSales_Reset"
          @resetfailure="handleSales_ResetFailure"
        />
        <Details
          :userData="userData"
          :userItems="userItems"
          :warehouseOptions="warehouseOptions"
          @callback="handleCallbackDetail"
        />
        <Delivery :userData="userData"/>
        <Items :salesData="salesData" :userData="userData" :userItems="userItems"/>
        <Total :data="userData"/>
        <Details2
          class="mt-5"
          :userData="userData"
          :userItems="userItems"
          :discTypeOptions="discTypeOptions"
        />
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../InvoiceModel.js";
import Router from "../InvoiceRouter.js";
import Services from "../InvoiceServices.js";

import Delivery from "./InvoiceNew_Delivery";
import Details from "./InvoiceNew_Details";
import Details2 from "./InvoiceNew_Details2";
import Items from "./InvoiceNew_Items";
import Sales from "./InvoiceNew_Sales";
import Total from "./InvoiceNew_Total";

export default {
  components: {
    Delivery,
    Details,
    Details2,
    Items,
    Sales,
    Total
  },
  mixins: [AxiosMixin, InputPageMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    userItems: [],
    salesData: {},
    isLoading: false,
    saveLoading: null,
    discTypeOptions: {},
    warehouseOptions: {},
    warehouseList: [],
    cancelUrl: Router.Path.List,
    loadingComponent: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    failureSales: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const salesId = this.getUrlParam(this.$kst.Enum.Param.SalesID);

      this.discTypeOptions = Model.createDiscTypeOptions();

      this.initReset();
      this.initLoad(salesId);
    },
    initReset() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.salesData = null;
    },
    initLoad(salesId) {
      this.isLoading = true;
      this.failureInit = null;

      this.loadWarehouse();
      this.loadNewData(salesId);
    },
    initLoad_End() {
      this.isLoading = false;
    },

    loadWarehouse() {
      this.failureInit = null;
      let options = {}
      options.active = Model.IsActive.Active.ID

      const config = Services.getWarehouseList(options);
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data);
      this.initLoad_End();
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadNewData(salesId) {
      const config = Services.getInvoiceNewData({ sales: salesId });
      this.runAxios(config, salesId, this.loadNewData_Success, this.loadNewData_Error);
    },
    loadNewData_Success(data, salesId) {
      this.warehouseList = data.WarehouseList;
      Model.setDetailsBySpecialPrice(this.userData, data);

      if (salesId) {
        this.updateBySales(data.SalesDetails);
      }

      this.loadSpecialPrice();
    },
    loadNewData_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadSalesDetails(id) {
      this.failureSales = null;
      this.loadingComponent = this.openLoading();
      const config = Services.getSalesDetails(id, { withstock: 1 });
      this.runAxios(config, null, this.loadSalesDetails_Success, this.loadSalesDetails_Error);
    },
    loadSalesDetails_End() {
      this.closeLoading(this.loadingComponent);
    },
    loadSalesDetails_Success(data) {
      this.loadDispatchSpecialPrice(data);
    },
    loadSalesDetails_Error(message) {
      this.failureSales = message;
      this.loadSalesDetails_End();
    },

    loadSpecialPrice() {
      const options = { active: 1 }
      const config = Services.getSpecialPriceList(options);
      this.runAxios(config, null, this.loadSpecialPrice_Success, this.loadSpecialPrice_Error);
    },
    loadSpecialPrice_Success(data) {
      Model.setSpecialPriceOptions(data);
      this.initLoad_End();
    },
    loadSpecialPrice_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadDispatchSpecialPrice(userData) {
      const options = {
        warehouse: this.userData.WarehouseID || userData.WarehouseID,
        availstock: 1
      }
      const config = Services.getDispatchSpecialPrice(options);
      this.runAxios(config, userData, this.loadDispatchSpecialPrice_Success, this.loadDispatchSpecialPrice_Error);
    },
    loadDispatchSpecialPrice_Success(data, userData) {
      Model.setDispatchSpecialPrice(data);
      this.loadSalesDetails_End();
      if(userData) {
        this.updateBySales(userData);
      }
    },
    loadDispatchSpecialPrice_Error(message) {
      this.failureInit = message;
      this.loadSalesDetails_End();
    },
    handleCallbackDetail() {
      this.loadingComponent = this.openLoading();
      this.loadDispatchSpecialPrice();
    },

    handleSales_Reset() {
      this.initReset();
    },
    handleSales_ResetFailure() {
      this.failureSales = null;
    },
    handleSales_Submit(row) {
      this.initReset();
      this.loadSalesDetails(row.ID);
    },

    handleFormSubmit() {
      this.saveLoading = null;
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.handleFormSubmit_Success();
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleFormSubmit_Success() {
      let data = Model.populateData(this.userData, this.userItems);
      this.saveData(data);
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.postInvoice(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data) {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        const urlParams = this.$kst.Route.getParams({
          [this.$kst.Enum.Param.ID]: data.id
        });
        this.redirectTo(Router.Path.Details + urlParams);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    },

    updateBySales(salesDetails) {
      this.salesData = salesDetails;
      Model.updateSalesDetails(this.salesData);

      Model.setItemsBySales(this.userItems, salesDetails.Items);
      Model.setDetailsBySales(this.userData, salesDetails);
    }
  }
}
</script>