var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kst-table',{attrs:{"paginated":"","action":"","input-mode":"","data":_vm.userItems,"failure":_vm.failureList,"loading":_vm.isListLoading},scopedSlots:_vm._u([{key:"empty-body",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"userItems","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('kst-error',{attrs:{"array-blank-mode":!!errors}})]}}])})]},proxy:true}])},[_c('b-table-column',{attrs:{"cell-class":"ks-col-nopad","header-class":"p-0","width":"1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('kst-header-blank')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.Errors.length > 0)?_c('kst-tooltip',{attrs:{"error-mode":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('kst-list',{attrs:{"items":row.Errors}})]},proxy:true}],null,true)},[_c('kst-icon',{attrs:{"error-mode":""}})],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"FullName","label":_vm.StockClosingItemCatalog.FullName.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockClosingItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price-button","field":"SellPrice","label":_vm.StockClosingItemCatalog.SellPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockClosingItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-price-button","field":"SpecialPrice","label":_vm.StockClosingItemCatalog.SpecialPrice.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"options":row.SpecialPriceOptions,"catalog":_vm.StockClosingItemCatalog,"index":index},on:{"input":function($event){return _vm.handleSpecialPrice(row)}}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-price-button","field":"DiscountedValue","label":_vm.StockClosingItemCatalog.DiscountedValue.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockClosingItemCatalog}})]}}])}),_c('b-table-column',{attrs:{"cell-class":"ks-col-qty","field":"Quantity","label":_vm.StockClosingItemCatalog.Quantity.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var index = ref.index;
return [_c('kst-input',{attrs:{"error-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockClosingItemCatalog,"index":index},on:{"input":function($event){return _vm.handleQuantity(row)},"error":_vm.handleRowErrors}})]}}])}),_c('b-table-column',{attrs:{"numeric":"","cell-class":"ks-col-subtotal","field":"Total","label":_vm.StockClosingItemCatalog.Total.Label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('kst-output',{attrs:{"box-off":"","field-only":"","field":column.field,"data":row,"catalog":_vm.StockClosingItemCatalog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }