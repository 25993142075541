import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  AdjNumber: {
    Label: "Nomor Opname",
    PrintLabel: "Nomor",
    Type: InputType.Text,
    MaxLength: 45,
    Highlight: true
  },
  Comment: {
    Label: "Catatan",
    Type: InputType.TextArea,
    MaxLength: 200
  },
  CreatedBy: {
    Label: AppCatalog.Field.CreatedBy.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output,
  },
  Date: {
    Label: "Tanggal",
    Type: InputType.Date,
    ReadOnly: true
  },
  HppTotal: {
    Label: "Harga Pokok Total",
    Type: InputType.Decimal,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  IsAutoNumber: {
    Label: "Nomor Opname",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  }
}