<template>
  <span/>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import XlsxMixin from "@/mixins/XlsxMixin.js";
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import Services from "../ItemServices.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, XlsxMixin],
  props: {
    access: Object,
    searchData: Object
  },
  data: () => ({
    loadLoading: null
  }),
  methods: {
    exportExcel() {
      this.loadLoading = this.openLoading();
      this.loadList(this.searchData);
    },
    saveData_End() {
      this.closeLoading(this.loadLoading);
      this.loadLoading = null;
    },
    exportExcel_Success(items) {
      this.createBook();
      this.createSheet();

      this.addList(items);
      this.download(Info.Export.List.Excel.FileName);
      this.saveData_End();
    },

    loadList(searchData) {
      let options = {};
      if (searchData) {
        options.name = this.$kst.Out.getString(searchData.Search);
      }

      const config = Services.getItemList(options);
      this.runAxios(config, null, this.loadList_Success, this.loadList_Error);
    },
    loadList_Success(data) {
      this.exportExcel_Success(data);
      this.$emit(this.$kst.Enum.Event.Success);
    },
    loadList_Error(message) {
      this.$emit(this.$kst.Enum.Event.Error, message);
    },

    addList(items) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.SellPriceMin);
      this.addColumnHeader(Catalog.SellPriceMax);

      if (this.access.Field.BuyPrice !== false) {
        this.addColumnHeader(Catalog.BuyPriceMin);
        this.addColumnHeader(Catalog.BuyPriceMax);
      }

      this.addColumnHeader(Catalog.TotalQuantity);

      if (this.access.Field.HppTotal !== false) {
        this.addColumnHeader(Catalog.HppTotal);
      }

      // value
      for (const row of items) {
        this.addRow();
        this.updateRow(row);

        this.addSequenceCell();
        this.addCellValue("Name", row, Catalog);
        this.addCellValue("SellPriceMin", row, Catalog);
        this.addCellValue("SellPriceMax", row, Catalog);

        if (this.access.Field.BuyPrice !== false) {
          this.addCellValue("BuyPriceMin", row, Catalog);
          this.addCellValue("BuyPriceMax", row, Catalog);
        }

        this.addCellValue("TotalQuantity", row, Catalog);

        if (this.access.Field.HppTotal !== false) {
          this.addCellValue("HppTotal", row, Catalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    },
    updateRow(row) {
      row.SellPriceMin = row.SellPrice.min;
      row.SellPriceMax = row.SellPrice.max;
      row.BuyPriceMin = row.BuyPrice.min;
      row.BuyPriceMax = row.BuyPrice.max;
    }
  }
}
</script>