<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input
      field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
    <kst-input load-on-focus field="IsAutoNumber" :data="userData" :catalog="Catalog">
      <template #right>
        <kst-input addons expanded field-only
          v-show="!userData.IsAutoNumber"
          field="ReceiveNumber" :data="userData" :catalog="Catalog"
          :customValidations="numberValidations"
        />
      </template>
    </kst-input>
    <kst-input field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ReceiveCatalog.js";
import Info from "../ReceiveInfo.js";
import Model from "../ReceiveModel.js";

export default {
  props: {
    warehouseOptions: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.New_Date];
    },
    numberValidations() {
      return Model.validationNumber(this.userData);
    }
  }
}
</script>