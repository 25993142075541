<template>
  <kst-table paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
    :selected.sync="userData.selected"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Client"
      :label="Catalog.ClientID.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="showStatus">
      <b-table-column centered sortable
        v-slot="{ row }"
        cell-class="ks-col-status"
        field="Status"
        :label="Catalog.Status.Label"
      >
        <kst-status :value="row.Status" :statusEnum="Model.Status"/>
      </b-table-column>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../InvoiceCatalog.js";
import Model from "../InvoiceModel.js";

export default {
  props: {
    failureList: String,
    isListLoading: Boolean,
    items: Array,
    userData: Object,
    showStatus: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model:Model
  })
}
</script>