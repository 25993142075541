export default {
  data: () => ({
    lastRouterPath: null
  }),
  methods: {
    getUrlParam(paramName) {
      const value = this.$route.query[paramName];
      switch(paramName) {
        case this.$kst.Enum.Param.ID:
        case this.$kst.Enum.Param.InvoiceID:
        case this.$kst.Enum.Param.PurchaseID:
        case this.$kst.Enum.Param.PurchaseItemID:
        case this.$kst.Enum.Param.SalesID:
          return this.$kst.Out.getInteger(value);
        default:
          return this.$kst.Out.getString(value);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goForward() {
      this.$router.go(1);
    },
    redirectTo(path) {
      if (path === this.lastRouterPath) {
        return;
      }

      this.lastRouterPath = path;
      this.$router.push(path);
    },
    openTab(path) {
      if (path === this.lastRouterPath) {
        return;
      }

      this.lastRouterPath = path;
      window.open(path);
    }
  }
}