<template>
  <div>
    <VendorSearch ref="mdlVendorSearch" @submit="handleVendor_Submit"/>

    <kst-section :title="sectionTitle" class="mt-5">
      <kst-warning v-if="!userData.PurchasingID" :items="editDetailsNoPO"/>
      <kst-output v-if="userData.PurchasingID" field="PurchasingID" :data="userData" :catalog="Catalog"/>
      <kst-output v-if="userData.PurchasingID" field="PurchasingDate" :data="userData" :catalog="Catalog"/>
    </kst-section>

    <kst-warning class="mb-5" :items="vendorWarning"/>

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="VendorName"
          disabled
          :data="userData"
          :catalog="ReceiveItemCatalog"
        />
      </template>
      <template #right>
        <template v-if="userData.PurchasingID === null">
          <div class="control">
            <template v-if="userData.VendorID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button search-mode @click="handleSearch_Vendor"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button clear-mode @click="handleClear_Vendor"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ReceiveCatalog.js";
import ReceiveItemCatalog from "../ReceiveItemCatalog.js";
import Model from "../ReceiveModel.js";
import Info from "../ReceiveInfo.js";

import VendorSearch from "@/components/Vendor/Search/VendorSearch";

export default {
  components: {
    VendorSearch
  },
  props: {
    warehouseOptions: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    ReceiveItemCatalog: ReceiveItemCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.PO.Title;
    },
    editDetailsNoPO() {
      return ["Penerimaan Stok ini tidak menggunakan Pembelian Stok (PO)"];
    },
    vendorWarning() {
      return ["Tidak diperkenankan mengubah Vendor, saat menggunakan Pemesanan Pembelian."];
    }
  },
  methods: {
    handleSearch_Vendor() {
      this.$refs.mdlVendorSearch.show();
    },
    handleClear_Vendor() {
      Model.clearDetailsByVendor(this.userData);
    },
    handleVendor_Submit(row) {
      Model.setDetailsByVendor(this.userData, row);
    }
  }
}
</script>