<template>
  <div v-if="theVisible" class="kst-error">
    <div class="has-text-danger ks-flex-center mt-4">
      <kst-icon class="ks-display-inline mr-2" error-mode/>
      {{ theLabel }}
    </div>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    label: String,
    // mode: ONLY select 1 (one)
    arrayBlankMode: Boolean
  },
  computed: {
    theLabel() {
      return this.getLabel();
    },
    theVisible() {
      return this.getVisible();
    }
  },
  methods: {
    getLabel() {
      if (this.arrayBlankMode) {
        return AppCatalog.Message.Error_ArrayBlank;
      }

      return this.label;
    },
    getVisible() {
      if (this.arrayBlankMode) {
        return true;
      }
      else if (this.label) {
        return true;
      }

      return false;
    }
  }
}
</script>