<template>
  <kst-container class="kst-invoicesetting-edit">
    <ValidationObserver ref="formDetails">
      <kst-form submit
        :cancelUrl="cancelUrl"
        :errors="errors"
        :failure="failure"
        :failureInit="failureInit"
        :hide-body="!!formWarnings"
        :hide-footer="!!formWarnings"
        :loading="isLoading"
        :title="title"
        :warnings="formWarnings"
        @submit="handleFormSubmit"
      >

        <Details :userData="userData" :formatOption="formatOption"/>
      </kst-form>
    </ValidationObserver>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";
import InputPageMixin from "@/mixins/InputPageMixin.js";
import LoadingMixin from "@/mixins/LoadingMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../InvoiceSettingModel.js";
import Services from "../InvoiceSettingServices.js";
import InvoiceRouter from "../InvoiceSettingRouter.js";

import Details from "./InvoiceSettingEdit_Details";
import Info from "../InvoiceSettingInfo.js";

export default {
  components: {
    Details
  },
  mixins: [AxiosMixin, RouterMixin, InputPageMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    userData: {},
    formatOption: {},
    isLoading: true,
    cancelUrl: InvoiceRouter.Path.Main,
    saveLoading: null,
    // error
    errors: null,
    failure: null,
    failureInit: null,
    formWarnings: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.getUrlParam(this.$kst.Enum.Param.ID);
      this.formWarnings = null;

      if (!id) {
        this.formWarnings = [Info.Message.Edit_ParamIncomplete];
        return;
      }

      this.userData = Model.createDetails();
      this.loadFormat();
    },

    initLoad_End() {
      this.isLoading = false;
    },

    loadDetails() {
      const config = Services.getInvoiceSettingNumberFormat();
      this.runAxios(config, null, this.loadDetails_Success, this.loadDetails_Error);
    },
    loadDetails_Success(data) {
      Model.setDetailsByData(this.userData, data.data);
      Model.previewValue(this.formatOption.rows, this.userData);
      this.initLoad_End();
    },
    loadDetails_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    loadFormat() {
      this.failureInit = null;
      this.isLoading = true;
      const config = Services.getInvoiceSettingAllNumberFormat();
      this.runAxios(config, null, this.loadFormat_Success, this.loadFormat_Error);
    },

    loadFormat_Success(data) {
      this.formatOption = Model.createOptions(data.data);
      this.loadDetails();
    },
    loadFormat_Error(message) {
      this.failureInit = message;
      this.initLoad_End();
    },

    handleFormSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          let data = Model.populateData(this.userData)
          this.saveData(data);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData(data) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = Services.patchInvoiceSettingNumberFormat(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
    },
    saveData_Success() {
      this.saveData_End();
      this.routeLeaveEnabled = false;

      this.notifyFormSuccess(() => {
        this.redirectTo(InvoiceRouter.Path.Main);
      });
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = [message];
      this.saveData_End();
      this.notifyFormError();
    }
  }
}
</script>