<template>
  <div class="has-text-grey-light">
    <slot>{{ text }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>