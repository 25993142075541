import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.access;
import App from "./App/App.js";

export default {
  getUserAccess(component, params) {
    let options = {};
    options = Object.assign(options, params);
    const attributes = ["withmodules"];
    const urlParams = App.Out.getParams(options, attributes);

    const uid = App.Session.getUserID();
    return component.$http.get(apiEndpoint + "/api/v1/access/user/" + uid + urlParams);
  },
  /*
  getUserMenu() {
    const uid = App.Session.getUserID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/access/menu/" + uid
    };
  },
  getUserModule(moduleId) {
    const uid = App.Session.getUserID();
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/access/module/" + uid + "/" + moduleId
    };
  }
  */
}