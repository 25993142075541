import AppCatalog from "@/services/App/AppCatalog.js";
import InputType from "@/services/Input/InputType.js";
import OutputType from "@/services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Profit: {
    Label: AppCatalog.Info.Profit,
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
  Total: {
    Label: "Total Sales",
    Type: InputType.Integer,
    ReadOnly: true,
    Output: OutputType.Decimal.Accounting2D
  },
}