import ReportServices from "@/services/ReportServices.js";
import WarehouseServices from "@/services/WarehouseServices.js";
import CompanyServices from "@/services/CompanyServices.js";

export default {
  getDailyReport: ReportServices.getDailyReport,
  // warehouse
  getWarehouseList: WarehouseServices.getList,
  // company
  getCompanyDetails: CompanyServices.getDetails,
}