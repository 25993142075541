<template>
  <kst-container class="kst-stockclosing-list">
    <kst-form
      :failureInit="failureInit"
      :loading="isLoading"
      :title="title"
    >
      <template #top-right>
        <kst-button action-mode full-label
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.New"
        />
      </template>
      
      <kst-section search-mode class="pb-0">
        <Search
          :searchData="searchData"
          :warehouseOptions="warehouseOptions"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        />
      </kst-section>

      <kst-section :loading="isListLoading" search-result-mode>
        <template #top-right>
          <kst-button action-mode
            class="is-primary mr-4"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Print"
          />
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Export"
            @click="handleExport"
          />
        </template>

        <List
          :failureList="failureList"
          :items="items"
          :isListLoading="isListLoading"
        />
      </kst-section>
    </kst-form>
  </kst-container>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import Model from "../StockClosingModel.js";
import List from './StockClosingList_List';
import Search from './StockClosingList_Search';
import Services from '../StockClosingServices';
import SearchCatalog from './StockClosingSearchCatalog.js';

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    searchData: {},
    warehouseOptions: {},
    statusOptions: {},
    items: [],
    isLoading: false,
    isListLoading: false,
    loadCounter: 0,
    failureInit: null,
    failureList: null
  }),
  computed: {
    title() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initSearch();
      this.initLoad();
    },
    initSearch() {
      this.searchData = {
        Search: "",
        Date: this.$kst.In.getDefaultDateRange(),
        WarehouseID: this.$kst.Search.OptionAll
      }
    },
    initLoad() {
      this.loadWarehouse();
    },
    loadWarehouse() {
      const config = Services.getWarehouseList({ active: null });
      this.runAxios(config, null, this.loadWarehouse_Success, this.loadWarehouse_Error);
    },
    loadWarehouse_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data, SearchCatalog.WarehouseID.Label)
      this.isLoading = false;
      this.loadData(this.loadCounter, this.searchData);
    },
    loadWarehouse_Error(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    loadData(counter, searchData) {
      this.isListLoading = false;
      this.failureList = null;
      const params = Model.populateSearchParam(searchData);
      const config = Services.getStockClosingList(params);
      this.runAxios(config, counter, this.loadData_Success, this.loadData_Error)
    },
    loadData_Success(data) {
      this.items = data;
      this.isListLoading = false;
    },
    loadData_Error(message) {
      this.failureList = message;
      this.isListLoading = false;
    },
    handleSearch_Submit() {
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    },
    handleSearch_Reset() {
      this.initSearch();
      this.loadCounter++;
      this.loadData(this.loadCounter, this.searchData);
    },
    handleExport() {
      // do something
    }
  }
}

</script>