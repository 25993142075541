<template>
  <div>
    <template v-for="(warehouse, index) in items">
      <div :key="warehouse.WarehouseID" class="has-text-weight-semibold px-3 py-3">
        Gudang: {{ warehouse.WarehouseName }}
      </div>
      <kst-table action view-mode
        :key="warehouse.WarehouseID + '-' + index"
        :data="warehouse.Items"
        :loading="isListLoading"
      >
        <b-table-column sortable 
          v-slot="{ column, row }"
          field="Date"
          :label="Catalog.Date.Label"
        >
          <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
        </b-table-column>

        <b-table-column sortable 
          v-slot="{ row }"
          field="Name"
          :label="Catalog.Name.Label"
        >
          {{ getType(row.ActivityTypeID) }}
        </b-table-column>

        <b-table-column sortable 
          v-slot="{ row }"
          field="Changes"
          :label="Catalog.Changes.Label"
        >
          <template v-if="row.Changes === 0">
            -
          </template>
          <template v-else>
            {{ row.Changes }} {{ UnitName }}
          </template>
        </b-table-column>

        <b-table-column sortable numeric
          v-slot="{ column, row }"
          field="HppChanges"
          :label="Catalog.HppChanges.Label"
        >
          <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
        </b-table-column>

        <b-table-column sortable 
          v-slot="{ row }"
          field="QuantityAfter"
          :label="Catalog.QuantityAfter.Label"
        >
          <template v-if="row.QuantityAfter === 0">
            -
          </template>
          <template v-else>
            {{ row.QuantityAfter }} {{ UnitName }}
          </template>
        </b-table-column>

        <b-table-column sortable numeric
          v-slot="{ column, row }"
          field="HppAfter"
          :label="Catalog.HppAfter.Label"
        >
          <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
        </b-table-column>

        <template #action="{ row }">
          <kst-tooltip search-mode hover-only :content="Model.ItemReport.TitleSearch" v-if="row.TransactionID">
            <kst-button search-mode is-round @click="handleSearchItem(row.TransactionID, row.ActivityTypeID)"/>
          </kst-tooltip>
        </template>
      </kst-table>
    </template>
  </div>
</template>

<script>
import Catalog from "../RptStockCardTransactionCatalog.js";
import Model from "../RptStockCardModel.js";
import InvoiceRouter from "@/components/Invoice/InvoiceRouter.js";
import ReceiveRouter from "@/components/Receive/ReceiveRouter.js";
import RtnInvoiceRouter from "@/components/RtnInvoice/RtnInvoiceRouter.js";
import StockOpnameRouter from "@/components/StockOpname/StockOpnameRouter.js";
import StockReductionRouter from "@/components/StockReduction/StockReductionRouter.js";
import HppAdjRouter from "@/components/HppAdj/HppAdjRouter.js";

export default {
  props: {
    items: Array,
    UnitName: String,
  },
  data: () => ({
    isListLoading: true,
    Catalog: Catalog,
    Model: Model
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadList_Success();
    },
    loadList_Success() {
      this.isListLoading = false;
    },

    handleSearchItem(transactionId,activityTypeId) {
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: transactionId
      });

      switch(activityTypeId) {
        case this.$kst.Enum.ActivityType.Receive.ID: 
          window.open(ReceiveRouter.Path.Details + urlParams);
          break;
        case this.$kst.Enum.ActivityType.Invoice.ID:
          window.open(InvoiceRouter.Path.Details + urlParams);
          break;
        case this.$kst.Enum.ActivityType.StockOpname.ID:
          window.open(StockOpnameRouter.Path.Details + urlParams);
          break;
        case this.$kst.Enum.ActivityType.RtnInvoice.ID:
          window.open(RtnInvoiceRouter.Path.Details + urlParams);
          break;
        case this.$kst.Enum.ActivityType.HppAdj.ID:
          window.open(HppAdjRouter.Path.Details + urlParams);
          break;
        case this.$kst.Enum.ActivityType.StockReduction.ID:
          window.open(StockReductionRouter.Path.Details + urlParams);
          break;
      }
    },
    getType(activityID) {
      return this.$kst.Data.getActivityType(activityID);
    }
  }
}
</script>