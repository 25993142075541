<template>
  <b-menu class="kst-menu">
    <b-menu-list>
      <template #label>
        <div class="pt-6 pl-4 ks-text-transform">Menu</div>
      </template>
      <template v-for="menu in items">
        <template v-if="menu.Items">
          <template v-if="hasChild(menu)">
            <b-menu-item
              class="kst-menu-item"
              :icon="menu.Icon"
              :active="getIsExpanded(menu)"
              :expanded="getIsExpanded(menu)"
              :key="menu.ID"
            >
              <template slot="label" slot-scope="props">
                <span class="ml-4">{{ menu.Label }}</span>
                <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-down' : 'menu-up'"/>
              </template>
              <template v-for="child in menu.Items">
                <template v-if="access[child.Code] !== false">
                  <b-menu-item
                    class="kst-menu-item"
                    :icon="child.Icon"
                    :active="getIsActive(child.Path)"
                    :key="child.ID"
                    tag="router-link"
                    :to="child.Path"
                    @click.native="handleMenu_Click(child)"
                  >
                    <template slot="label">
                      <span class="ml-4">{{ child.Label }}</span>
                    </template>
                  </b-menu-item>
                </template>
              </template>
            </b-menu-item>
          </template>
        </template>
        <template v-else>
          <template v-if="access[menu.Code] !== false">
            <b-menu-item
              class="kst-menu-item"
              :icon="menu.Icon"
              :active="getIsActive(menu.Path)"
              :key="menu.ID"
              tag="router-link"
              :to="menu.Path"
              :target="getTarget(menu.Path)"
              @click.native="handleMenu_Click(menu)"
            >
              <template slot="label">
                <span class="ml-4">{{ menu.Label }}</span>
              </template>
            </b-menu-item>
          </template>
        </template>
      </template>
    </b-menu-list>
  </b-menu>
</template>

<script>
import AppModule from "@/services/App/AppModule.js";
import RouterModel from "@/router/RouterModel.js";

export default {
  props: {
    /**
     * keys:
     *  *ID: Number, String
     *  *Label: String
     *  IsActive: Boolean
     *  Items: Array
     **/
    items: Array
  },
  computed: {
    access() {
      return this.$kst.Session.getMenu();
    }
  },
  methods: {
    handleMenu_Click(menu) {
      this.$emit(this.$kst.Enum.Event.Submit, menu);
    },

    getTarget(menuPath) {
      if (menuPath === RouterModel.getPath(AppModule.Pos)) {
        return "_blank";
      }

      return undefined;
    },

    getIsActive(path) {
      return this.$route.path.includes(path);
    },
    getIsExpanded(menu) {
      for (const child of menu.Items) {
        if (this.$route.path.includes(child.Path)) {
          return true;
        }
      }

      return false;
    },
    hasChild(menu) {
      let count = 0;

      for (const child of menu.Items) {
        if (this.access[child.Code] !== false) {
          count++;
        }
      }

      return count > 0;
    }
  }
}
</script>