import InputType from "@/services/Input/InputType.js";
import AppModule from "@/services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: "Gudang",
    Type: InputType.Text,
    ReadOnly: true
  },
  ItemID: {
    Label: "ID Barang",
    Type: InputType.Integer,
    Required: true
  },
  FullName: {
    Label: "Nama Barang",
    Type: InputType.Text,
    ReadOnly: true
  },
  Quantity: {
    Label: "Jumlah",
    Type: InputType.Text,
    Required: true
  },
  SKU: {
    Label: "SKU",
    Type: InputType.Text
  }
}