import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

import ExpenseDetails from "./Details/ExpenseDetails";
import ExpenseEdit from "./Edit/ExpenseEdit";
import ExpenseList from "./List/ExpenseList";
import ExpenseNew from "./New/ExpenseNew";
import ExpenseExportDetails from "./ExportDetails/ExpenseExportDetails"


export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Expense, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Expense, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.Expense, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Expense, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.Expense, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.Details),
        component: ExpenseDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.Details),
          moduleName: RouterModel.getModule(AppModule.Expense)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.Edit),
        component: ExpenseEdit,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.Edit),
          moduleName: RouterModel.getModule(AppModule.Expense)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.ExportDetails),
        component: ExpenseExportDetails,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.ExportDetails),
          moduleName: RouterModel.getModule(AppModule.Expense)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.List),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.List),
        component: ExpenseList,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.List),
          moduleName: RouterModel.getModule(AppModule.Expense)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.New),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.New),
        component: ExpenseNew,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.New),
          moduleName: RouterModel.getModule(AppModule.Expense)
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}