<template>
  <kst-table action detailed view-mode
    :data="items"
    :loading="isLoading"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="ReturnNumber"
      :label="Catalog.ReturnNumber.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value ellipsis
        class="has-text-weight-bold" :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <template #action="{ row }">
      <Action :row="row"/>
    </template>

    <template #detail="{ row }">
      <kst-container class="p-2">
        <Items :items="row.Items"/>
        <Total :data="row"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../RtnInvoiceCatalog.js";

import Action from "./RtnInvoiceRelated_Row_Action";
import Items from "./RtnInvoiceRelated_Row_Items";
import Total from "./RtnInvoiceRelated_Row_Total";

export default {
  components: {
    Action,
    Items,
    Total
  },
  props: {
    items: Array,
    isLoading: Boolean
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>