import apiConfig from "./ApiURL.json";
var apiEndpoint = process.env.VUE_APP_TOKO_API || apiConfig.hppadj;
import App from "./App/App.js";

export default {
  getDetails(id, params) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
      uid: App.Session.getUserID()
    };
    options = Object.assign(options, params);
    const attributes = ["cid", "id", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/hppadj/details/" + urlParams
    };
  },
  getDetailsData(id, params) {
    let options = {
      cid: App.Session.getCompanyID(),
      id: id,
      uid: App.Session.getUserID()
    };
    options = Object.assign(options, params);
    const attributes = ["cid", "id", "uid", "withcompany"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/hppadj/detailsdata" + urlParams
    };
  },
  getList(params) {
    let options = { cid: App.Session.getCompanyID(), uid: App.Session.getUserID() };
    options = Object.assign(options, params);
    const attributes = ["cid", "uid", "search", "warehouse", "field",
      "startdate", "enddate"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/hppadj/list" + urlParams
    };
  },
  getListData() {
    let options = { cid: App.Session.getCompanyID(), uid: App.Session.getUserID() };
    const attributes = ["cid", "uid"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/api/v1/hppadj/listdata" + urlParams
    };
  },
  postHppAdj(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/api/v1/warehouse/items/hppAdj",
      data: data
    };
  }
}